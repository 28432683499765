import { Pipe, PipeTransform } from '@angular/core';

import { StoreInfoFeatureState } from '../../../ecomm/store/features/store-info';

@Pipe({
  name: 'shouldShowStoreInfoLoading'
})
export class ShouldShowStoreInfoLoadingPipe implements PipeTransform {
  transform(state: StoreInfoFeatureState | null): boolean {
    if (!state || (state.isLoading && !state.storeInfo)) {
      return true;
    } else {
      return false;
    }
  }
}
