import { Pipe, PipeTransform } from '@angular/core';

import { Order } from '../../../ecomm/types/order.types';
import { LocationResponseData } from '../../../ecomm/types/search-location.types';
import { getLaterTime } from '../../../ecomm/utils/moment';
import moment from 'moment-timezone';

export type ReadyTimes = {
  maxMinutes: number;
  minMinutes: number;
};

@Pipe({
  name: 'calFulfillmentTimePipe'
})
export class CalFulfillmentTimePipe implements PipeTransform {
  transform(
    val: (Order & { locationDetails: LocationResponseData | null }) | null
  ): string[] {
    const prepTimeText: string[] = [];
    if (val?.cart.asap) {
      prepTimeText.push(getTitle(val.cart?.readyTimes, val?.cart.handoffMode));
      prepTimeText.push(
        getReadyTimeRange(
          val.cart?.readyTimes,
          val.createdTimestamp,
          val.locationDetails?.location?.timeZone ?? ''
        )
      );
    } else {
      const ftLater = ftForLater(
        val?.cart.fulfillmentTime ?? '',
        val?.locationDetails?.location?.timeZone ?? '',
        val?.cart.asap ?? false
      );

      prepTimeText.push(
        ftLater === ''
          ? 'Select a time'
          : 'Scheduled for ' + val?.cart.handoffMode + ' '
      );

      prepTimeText.push(ftLater === '' ? '' : ftLater);
    }
    return prepTimeText;
  }
}

const getTitle = (readyTimes: ReadyTimes | null, type: string) => {
  const action = type === 'carryout' ? 'Ready for carryout' : 'Delivery';

  if (!readyTimes || readyTimes.maxMinutes === readyTimes.minMinutes) {
    return `${action} at `;
  }

  return `${action} in about `;
};

const getFormattedTime = (
  createdTimestamp: string,
  timeZone: string,
  minToadd: number
) => {
  return moment
    .tz(createdTimestamp, timeZone)
    .add(minToadd, 'minutes')
    .format('h:mm A');
};

const getReadyTimeRange = (
  readyTimes: ReadyTimes | null,
  createdTimestamp: string,
  timeZone: string
) => {
  if (!readyTimes) {
    const currentFormattedTime = moment
      .tz(createdTimestamp, timeZone)
      .format('h:mm A');
    return currentFormattedTime;
  }

  const { minMinutes = 0, maxMinutes = 0 } = readyTimes || {};

  if (minMinutes == maxMinutes) {
    return getFormattedTime(createdTimestamp, timeZone, minMinutes);
  }

  const minTime = getFormattedTime(createdTimestamp, timeZone, minMinutes);
  const maxTime = getFormattedTime(createdTimestamp, timeZone, maxMinutes);
  return `${minTime} - ${maxTime}`;
};

const ftForLater = (
  cartFulfillmentTime: string,
  timeZone: string,
  cartInAsapMode: boolean
) => {
  return !cartInAsapMode ? getLaterTime(cartFulfillmentTime, timeZone) : '';
};
