<div
  class="wri-menu-recommendation-wrapper"
  [class.loading]="loading"
  *ngIf="sliderData?.length"
>
  <div class="wri-title">Our recommendations</div>
  <ngx-slick-carousel
    class="limited-time-offer-slider carousel"
    [config]="slideConfig"
    (init)="handleInit()"
    #slickModal="slick-carousel"
    (afterChange)="afterChange()"
  >
    <div
      ngxSlickItem
      *ngFor="let data of sliderData; trackBy: trackByItem; let first = first"
      class="slide"
    >
      <a
        wriFocusOutline
        tabindex="0"
        (click)="getProductItemDetailsUrl(data)"
        class="tabfocus"
      >
        <wri-additional-recommendation-card
          [first]="first"
          [image]="data.item.images.length > 0 ? data.item.images[0].uri : null"
          [imageDescription]="data.item?.description | prepend : 'image-for'"
          [name]="data.item.name"
          [description]="data.item.shortDescription || data.item.description"
          [item]="data.item"
          [locationSlug]="storeInfoState?.storeInfo?.storeDetails?.slug"
          [categorySlug]="data.categorySlug"
          [productSlug]="data.productSlug"
          [itemSlug]="data.itemSlug"
          [showCaret]="true"
          [recommendationType]="'menu-recommendations'"
        ></wri-additional-recommendation-card>
      </a>
    </div>
  </ngx-slick-carousel>
</div>
