import { Component, Input } from '@angular/core';
import DOMPurify from 'dompurify';
import { pipe } from 'lodash/fp';
import { marked } from 'marked';
@Component({
  selector: 'wri-markdown',
  template: `<section
    data-testid="markdown-content"
    [innerHTML]="html"
  ></section>`
})
export class MarkdownComponent {
  public html = '';

  @Input() set raw(input: string | undefined | null) {
    this.html = pipe(
      this.trimZeroWidthCharacters.bind(this),
      this.toHTMLFromMarkDown.bind(this),
      this.sanitizeHTML.bind(this)
    )(input) as string;
  }

  private trimZeroWidthCharacters(input: string | undefined | null): string {
    return (input ?? '').replace(
      /^([\u200B][\u200C][\u200D][\u200E][\u200F][\uFEFF])+/,
      ''
    );
  }

  private toHTMLFromMarkDown(input: string): string | Promise<string> {
    return marked.parse(input);
  }

  private sanitizeHTML(input: string): string {
    return DOMPurify.sanitize(input);
  }
}
