import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';

import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { MenuItemWithSlug } from '../../../common';

@Component({
  selector: 'wri-home-page-product-carousel',
  templateUrl: './home-page-product-carousel.component.html',
  styleUrls: ['./home-page-product-carousel.component.scss']
})
export class HomePageProductCarouselComponent implements OnInit {
  @Input() sliderData: MenuItemWithSlug[] | undefined;
  private subscription = new Subscription();
  public storeInfoState: StoreInfoFeatureState | null = null;
  loading = true;

  // Slide config
  public slideConfig = {
    slidesToScroll: 1,
    variableWidth: false,
    infinite: false,
    slidesToShow: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          arrows: true
        }
      }
    ],
    dots: true
  };

  constructor(private store: Store) {}

  ngOnInit() {
    const stateInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      stateInfo$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  trackByItem(index: number, data: MenuItemWithSlug) {
    return `${index}-${data?.item?.id}`;
  }

  handleInit() {
    this.loading = false;
  }
}
