import * as t from 'io-ts';

import { ecommApiResponse, Optional, optional } from './common.types';

/* #region CustomerAddress */
export const CustomerAddressDto = t.intersection(
  [
    t.type({}, 'CustomerAddressDtoRequired'),
    t.partial(
      {
        streetAddress: optional(t.string),
        locality: optional(t.string),
        region: optional(t.string),
        postalCode: optional(t.string),
        countryCode: optional(t.string)
      },
      'CustomerAddressDtoOptional'
    )
  ],
  'CustomerAddressDto'
);

export type CustomerAddressDto = t.TypeOf<typeof CustomerAddressDto>;
export type CustomerAddress = CustomerAddressDto;
/* #endregion */

/* #region CustomerFavoriteLocations */
export const CustomerFavoriteLocationsDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        number: t.string,
        legacyStoreNumber: t.number,
        streetAddress: t.string,
        locality: t.string,
        region: t.string,
        postalCode: t.string,
        countryCode: t.string,
        latitude: t.number,
        longitude: t.number,
        phoneNumber: t.string,
        currencyType: t.string,
        timeZone: t.string
      },
      'CustomerFavoriteLocationsDtoRequired'
    ),
    t.partial(
      {
        secondaryAddress: optional(t.string)
      },
      'CustomerFavoriteLocationsDtoOptional'
    )
  ],
  'CustomerFavoriteLocationsDto'
);

export type CustomerFavoriteLocationsDto = t.TypeOf<
  typeof CustomerFavoriteLocationsDto
>;
export type CustomerFavoriteLocations = CustomerFavoriteLocationsDto;
/* #endregion */

/* #region CustomerDeliveryAddress */
export const CustomerDeliveryAddressDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        streetAddress: t.string,
        locality: t.string,
        region: t.string,
        postalCode: t.string,
        countryCode: t.string
      },
      'CustomerDeliveryAddressDtoRequired'
    ),
    t.partial(
      {
        secondaryAddress: optional(t.string),
        secondaryStreetAddress: optional(t.string),
        deliveryInstructions: optional(t.string),
        longitude: optional(t.number),
        latitude: optional(t.number),
        locationStatus: t.string
      },
      'CustomerDeliveryAddressDtoOptional'
    )
  ],
  'CustomerDeliveryAddressDto'
);

export type CustomerDeliveryAddressDto = t.TypeOf<
  typeof CustomerDeliveryAddressDto
>;
export type CustomerDeliveryAddress = CustomerDeliveryAddressDto;
/* #endregion */

/* #region Customer */
export const CustomerDto = t.intersection(
  [
    t.type({}, 'CustomerDtoRequired'),
    t.partial(
      {
        customerId: optional(t.string),
        userName: optional(t.string),
        email: optional(t.string),
        primaryPhone: optional(t.string),
        dateOfBirth: optional(t.string),
        address: optional(CustomerAddressDto),
        deliveryAddresses: optional(t.array(CustomerDeliveryAddressDto)),
        favoriteWingstopLocations: optional(
          t.array(CustomerFavoriteLocationsDto)
        ),
        emailPreference: optional(t.unknown),
        firstName: optional(t.string),
        lastName: optional(t.string),
        eTag: optional(t.string)
      },
      'CustomerDtoOptional'
    )
  ],
  'CustomerDto'
);

export type CustomerDto = t.TypeOf<typeof CustomerDto>;
export type Customer = Omit<CustomerDto, 'address'> & {
  address: Optional<CustomerAddress>;
};
/* #endregion */

/* #region CustomerResponse */
export const CustomerResponse = ecommApiResponse(CustomerDto);

export type CustomerResponse = t.TypeOf<typeof CustomerResponse>;
/* #endregion */

/* #region CustomerPutRequest Request */
export const CustomerPutRequest = t.intersection(
  [
    t.type(
      {
        username: t.string,
        email: t.string,
        firstName: t.string,
        lastName: t.string
      },
      'CustomerPutRequestRequired'
    ),
    t.partial(
      {
        primaryPhone: optional(t.string),
        dateOfBirth: t.string,
        address: optional(CustomerAddressDto),
        emailPreference: optional(t.boolean)
      },
      'CustomerPutRequestOptional'
    )
  ],
  'CustomerPutRequest'
);

export type CustomerPutRequest = t.TypeOf<typeof CustomerPutRequest>;

/* #endregion */

/* #region PastOrderLocation */
export const PastOrderLocationDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        number: t.string,
        streetAddress: t.string,
        locality: t.string,
        region: t.string,
        postalCode: t.string,
        countryCode: t.string,
        phoneNumber: t.string,
        latitude: t.number,
        longitude: t.number,
        timeZone: t.string
      },
      'PastOrderLocationDtoRequired'
    ),
    t.partial(
      {
        county: optional(t.string),
        secondaryAddress: optional(t.string)
      },
      'PastOrderLocationDtoOptional'
    )
  ],
  'PastOrderLocationDto'
);

export type PastOrderLocationDto = t.TypeOf<typeof PastOrderLocationDto>;
export type PastOrderLocation = PastOrderLocationDto;
/* #endregion PastOrderLocation */

/* #region PastOrderDeliveryAddress */
export const PastOrderDeliveryAddressDto = t.intersection(
  [
    t.type({}, 'PastOrderDeliveryAddressDtoRequired'),
    t.partial(
      {
        streetAddress: optional(t.string),
        secondaryAddress: optional(t.string),
        locality: optional(t.string),
        region: optional(t.string),
        postalCode: optional(t.string),
        countryCode: optional(t.string)
      },
      'PastOrderDeliveryAddressDtoOptional'
    )
  ],
  'PastOrderDeliveryAddressDto'
);

export type PastOrderDeliveryAddressDto = t.TypeOf<
  typeof PastOrderDeliveryAddressDto
>;
export type PastOrderDeliveryAddress = PastOrderDeliveryAddressDto;
/* #endregion PastOrderDeliveryAddress */

/** #region PastOrderCartItem */
export const PastOrderCartItemDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        productId: t.string,
        productName: t.string,
        quantity: t.number,
        validatedUnitPrice: t.number,
        estimatedUnitPrice: t.number,
        estimatedTaxes: t.array(
          t.intersection([
            t.type(
              { description: t.string, amount: t.number },
              'PastOrderCartItemEstimatedTaxesDtoRequired'
            ),
            t.partial({}, 'PastOrderCartItemEstimatedTaxesDtoOptional')
          ])
        )
      },
      'PastOrderCartItemDtoOptional'
    ),
    t.partial(
      {
        modifierGroups: optional(
          t.array(
            t.intersection([
              t.type(
                {
                  modifierGroupId: t.string,
                  name: t.string,
                  modifiers: t.array(
                    t.intersection([
                      t.type(
                        {
                          id: t.string,
                          modifierId: t.string,
                          name: t.string,
                          quantity: t.number,
                          validatedUnitPrice: t.number,
                          estimatedUnitPrice: t.number
                        },
                        'PastOrderCartItemModifiersDtoRequired'
                      ),
                      t.partial({}, 'PastOrderCartItemModifiersDtoOptional')
                    ])
                  )
                },
                'PastOrderCartItemModifierGroupsDtoRequired'
              ),
              t.partial({}, 'PastOrderCartItemModifierGroupsDtoOptional')
            ])
          )
        )
      },
      'PastOrderCartItemDtoOptional'
    )
  ],
  'PastOrderCartItemDto'
);

export type PastOrderCartItemDto = t.TypeOf<typeof PastOrderCartItemDto>;
export type PastOrderCartItem = PastOrderCartItemDto;
/* #endregion */

/* #region PastOrderCart */
export const PastOrderCartDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        items: t.array(PastOrderCartItemDto)
      },
      'PastOrderCartDtoRequired'
    ),
    t.partial({}, 'PastOrderCartDtoOptional')
  ],
  'PastOrderCartDto'
);

export type PastOrderCartDto = t.TypeOf<typeof PastOrderCartDto>;
export type PastOrderCart = PastOrderCartDto;
/* #endregion */

/* #region CustomerOrders */
export const CustomerOrdersDto = t.array(
  t.intersection(
    [
      t.type(
        {
          id: t.string
        },
        'CustomerOrdersDtoRequired'
      ),
      t.partial(
        {
          status: optional(t.string),
          orderDate: optional(t.string),
          displayNumber: optional(t.string),
          location: optional(PastOrderLocationDto),
          handoffMode: optional(t.string),
          cart: optional(PastOrderCartDto),
          validatedTaxes: optional(
            t.array(
              t.intersection([
                t.type(
                  {
                    description: optional(t.string),
                    amount: optional(t.number)
                  },
                  'PastOrderValidatedTaxesDtoRequired'
                ),
                t.partial({}, 'PastOrderValidatedTaxesDtoOptional')
              ])
            )
          ),
          deliveryFee: optional(t.number),
          tip: optional(t.number),
          subtotal: optional(t.number),
          totalTax: optional(t.number),
          total: optional(t.number),
          estimatedDeliveryTime: optional(t.string),
          orderDeliveryAddress: optional(PastOrderDeliveryAddressDto),
          fees: optional(
            t.array(
              t.intersection([
                t.type(
                  {
                    name: optional(t.string),
                    description: optional(t.string),
                    amount: optional(t.number),
                    tax: optional(t.number)
                  },
                  'PastOrderFeesDtoRequired'
                ),
                t.partial({}, 'PastOrderFeesDtoOptional')
              ])
            )
          )
        },
        'CustomerOrdersDtoOptional'
      )
    ],
    'CustomerOrdersDto'
  )
);

export type CustomerOrdersDto = t.TypeOf<typeof CustomerOrdersDto>;
export type CustomerOrders = CustomerOrdersDto;
/* #endregion */

/* #region CustomerOrdersResponse */
export const CustomerOrdersResponse = ecommApiResponse(CustomerOrdersDto);

export type CustomerOrdersResponse = t.TypeOf<typeof CustomerOrdersResponse>;
/* #endregion */

/* #region DeleteCustomerFavoriteLocationsResponse */
export const DeleteCustomerFavoriteLocationsResponse = ecommApiResponse(
  t.boolean
);

export type DeleteCustomerFavoriteLocationsResponse = t.TypeOf<
  typeof DeleteCustomerFavoriteLocationsResponse
>;
/* #endregion */

/* #region DeleteAccount */
export const DeleteAccountResponse = ecommApiResponse(t.boolean);

export type DeleteAccountResponse = t.TypeOf<typeof DeleteAccountResponse>;
/* #endregion */

/* #region FavoriteLocationResponse */
export const FavoriteLocationResponse = ecommApiResponse(t.boolean);

export type FavoriteLocationResponse = t.TypeOf<
  typeof FavoriteLocationResponse
>;
/* #endregion */

/* #region DeliveryAddressPostRequestRequest */
export const DeliveryAddressPostRequest = t.intersection(
  [
    t.type(
      {
        streetAddress: t.string,
        locality: t.string,
        region: t.string,
        postalCode: t.string,
        countryCode: t.string,
        longitude: t.number,
        latitude: t.number
      },
      'DeliveryAddressPostRequestRequired'
    ),
    t.partial(
      {
        secondaryStreetAddress: optional(t.string),
        deliveryInstructions: optional(t.string)
      },
      'DeliveryAddressPostRequestOptional'
    )
  ],
  'DeliveryAddressPostRequest'
);

export type DeliveryAddressPostRequest = t.TypeOf<
  typeof DeliveryAddressPostRequest
>;

/* #endregion */

/* #region FavoriteLocationResponse */
export const DeliveryAddressPostResponse = ecommApiResponse(t.boolean);

export type DeliveryAddressPostResponse = t.TypeOf<
  typeof DeliveryAddressPostResponse
>;
/* #endregion */

/* #region CustomerVaultedCardsResponse */
export const DeleteCustomerAddressResponse = ecommApiResponse(t.boolean);

export type DeleteCustomerAddressResponse = t.TypeOf<
  typeof DeleteCustomerAddressResponse
>;
/* #endregion */

/* #region ReOrderPostRequest */
export const reOrderPostRequest = t.intersection(
  [
    t.type(
      {
        orderId: t.string
      },
      'ReOrderPostRequestRequired'
    ),
    t.partial({}, 'ReOrderPostRequestOptional')
  ],
  'ReOrderPostRequest'
);

export type ReOrderPostRequest = t.TypeOf<typeof reOrderPostRequest>;
/* #endregion */

/* #region SignupCustomer Request */
export const SignupCustomerRequest = t.intersection([
  t.type(
    {
      email: t.string,
      firstName: t.string,
      lastName: t.string,
      password: t.string,
      source: t.intersection([t.type({ platform: t.string }), t.partial({})])
    },
    'SignupCustomerRequestRequired'
  ),
  t.partial(
    {
      primaryPhone: optional(t.string),
      dateOfBirth: optional(t.string),
      address: optional(
        t.intersection([
          t.type({}),
          t.partial({ postalCode: optional(t.string) })
        ])
      )
    },
    'SignupCustomerRequestOptional'
  )
]);

export type SignupCustomerRequest = t.TypeOf<typeof SignupCustomerRequest>;

/* #endregion */

/* #region CustomerOrders */
export const SignupCustomerDto = t.intersection([
  t.type(
    {
      accessToken: t.string,
      idToken: t.string,
      refreshToken: t.string
    },
    'SignupCustomerDtoRequired'
  ),
  t.partial({}, 'SignupCustomerDtoOptional')
]);

export type SignupCustomerDto = t.TypeOf<typeof SignupCustomerDto>;
export type SignupCustomer = SignupCustomerDto;
/* #endregion */

/* #region SignupCustomer Response */
export const SignupCustomerResponse = ecommApiResponse(SignupCustomerDto);

export type SignupCustomerResponse = t.TypeOf<typeof SignupCustomerResponse>;
/* #endregion */
