import { Component } from '@angular/core';
import {
  faFacebookF,
  faInstagram,
  faSpotify,
  faTiktok,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'wri-social-media-marketing-section',
  template: `
    <section>
      <h3>check us out on social media</h3>
      <ul>
        <li>
          <a
            href="https://www.facebook.com/wingstop/"
            target="_blank"
            rel="”noopener”"
            aria-label="Facebook will open on new tab"
          >
            <fa-icon [icon]="icons.faFacebookF" size="lg"></fa-icon>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/wingstop"
            target="_blank"
            rel="”noopener”"
            aria-label="Instagram will open on new tab"
          >
            <fa-icon [icon]="icons.faInstagram" size="lg"></fa-icon>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/wingstop/"
            target="_blank"
            rel="”noopener”"
            aria-label="Twitter will open on new tab"
          >
            <fa-icon [icon]="icons.faXTwitter" size="lg"></fa-icon>
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/user/r1cb3fd7wt2v3nqjbl5p7p5wx"
            target="_blank"
            rel="”noopener”"
            aria-label="Spotify will open on new tab"
          >
            <fa-icon [icon]="icons.faSpotify" size="lg"></fa-icon>
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@wingstop"
            target="_blank"
            rel="”noopener”"
            aria-label="Tiktok will open on new tab"
          >
            <fa-icon [icon]="icons.faTiktok" size="lg"></fa-icon>
          </a>
        </li>
      </ul>
    </section>
  `,
  styleUrls: ['./social-media-marketing-section.component.scss']
})
export class SocialMediaMarketingSectionComponent {
  icons = {
    faFacebookF,
    faInstagram,
    faXTwitter,
    faSpotify,
    faTiktok
  };
}
