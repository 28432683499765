import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BreadCrumbType, ModGroups } from '../../../common';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  Subscription
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformLocation } from '@angular/common';
import {
  MenuItem,
  MenuModifier,
  MenuModifierGroup,
  MenuModifierGroupItem,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';
import { find } from 'lodash';
import { Cart } from '../../../../ecomm/types/cart.types';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { checkIsVisible } from '../../../../ecomm/utils/pdp-utils';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import {
  LocationDetailsFeature,
  LocationDetailsFeatureState
} from '../../../../ecomm/store/features/location-details';
import { HighSodiumDisplay } from '../../../../ecomm/types/search-location.types';

@Component({
  selector: 'wri-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  private static readonly MIN_VALUE: number = 1;
  private static readonly MAX_VALUE: number = 9;
  private static readonly MAXIMUM_QUANTITY_ERROR_MSG =
    'This item has reached the maximum quantity.';
  @Input() public selectedItem!: MenuItem;
  @Input() breadcrumbs: Array<BreadCrumbType> | undefined;
  @Input() cart: Cart | null = null;
  @Input() storeInfo: StoreInfo | undefined;
  @Input() isCartStateLoading: boolean | undefined;
  @Input() categorySlug?: string | null = null;
  @Input() categoryName?: string | null;
  isModelOpened = false;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  public totalItemPrice$ = new BehaviorSubject(0);
  public lineItemIdQP: string | null = null;
  public isAddOrUpdateButtonClicked = false;
  public failedModifierGroups: MenuModifierGroup[] = [];
  public startPDPValidations = false;
  public quantity = ItemDetailsComponent.MIN_VALUE;
  public previousQuantity = ItemDetailsComponent.MIN_VALUE;
  public itemPrice = 0;
  private selectedModifierGroups$ = new BehaviorSubject<ModGroups[]>([]);
  private subscription = new Subscription();
  public highSodiumDisplay: HighSodiumDisplay | null | undefined = null;

  constructor(
    private analyticsService: AnalyticsService,
    private store: Store,
    private router: Router,
    private cartService: CartWorkflowService,
    platformLocation: PlatformLocation,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    @Inject(WINDOW) private _window: Window,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService
  ) {
    // closes modal when back button is clicked
    platformLocation.onPopState(() => this.modalService.dismissAll());
  }

  private _outOfStock!: boolean;

  get outOfStock(): boolean {
    return this._outOfStock;
  }

  @Input() set outOfStock(value: boolean) {
    this._outOfStock = value;
  }

  private _availableInSchedule!: boolean;

  get availableInSchedule(): boolean {
    return this._availableInSchedule;
  }

  @Input() set availableInSchedule(value: boolean) {
    this._availableInSchedule = value;
  }

  ngOnInit(): void {
    addSpaceToBodyWithStickyFooter('pdp');
    this.lineItemIdQP = this.route.snapshot.queryParamMap.get('lineItemId');
    this.quantity = this.getSelectedLineItem()?.quantity || 1;
    this.totalItemPrice$.next(this.selectedItem?.price);

    this.subscribeToLocationDetailsState();

    this.subscription.add(
      combineLatest([
        this.selectedModifierGroups$,
        this.totalItemPrice$
      ]).subscribe(([modifierGroups, price]) => {
        this.store.dispatch(
          CartFeature.actions.setAddToCartOnWs({
            item: {
              quantity: this.quantity,
              product: {
                itemId: this.selectedItem?.id,
                price,
                modifierGroups
              }
            }
          })
        );
      })
    );
  }

  ngAfterViewInit() {
    this.verifyAndOpenPopup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['isCartStateLoading']) {
      if (!changes['isCartStateLoading'].currentValue && !this.selectedItem) {
        this.openModal();
      }
    }

    if (changes && changes['outOfStock']?.currentValue) {
      this.verifyAndOpenPopup();
    } else if (
      changes &&
      changes['availableInSchedule']?.currentValue === false
    ) {
      this.verifyAndOpenPopup();
    }
    this.itemPrice = this.getTotalItemPrice();
  }

  @HostListener('window:scroll')
  showSecondaryBtn() {
    const primaryBtnContainer = document.getElementById(
      'primary-btn-container'
    );
    const secondaryBtnContainer = document.getElementById(
      'secondary-btn-container'
    );
    if (!checkIsVisible(primaryBtnContainer)) {
      secondaryBtnContainer?.classList.add('display-secondary-btn');
    } else {
      secondaryBtnContainer?.classList.remove('display-secondary-btn');
    }
  }

  verifyAndOpenPopup() {
    if (
      this.selectedItem &&
      (this.selectedItem.outOfStock || !this.selectedItem.availableInSchedule)
    ) {
      this.openModal();
    }
  }

  openModal() {
    if (this.commonModal && !this.isModelOpened) {
      this.isModelOpened = true;
      this.modalService.open(this.commonModal, {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  public getModalMessages() {
    if (!this.selectedItem) {
      return "Sorry, we couldn't find this item.";
    }

    if (!this.selectedItem.availableInSchedule) {
      return 'Sorry, this item is unavailable';
    }

    if (this.outOfStock) {
      return 'Sorry, this item is temporarily unavailable';
    }
    return '';
  }

  public unavailableItemClick() {
    this.lineItemIdQP ? this.goToBag() : this.goToMenu();
  }

  public getUnavailableItemText() {
    return this.lineItemIdQP ? 'Go to Bag' : 'Go to Menu';
  }

  public goToBag = () => {
    this.modalService.dismissAll();
    this.router.navigate(['/order/my-bag']);
  };

  public goToMenu = () => {
    this.modalService.dismissAll();
    const url = `/location/${this.storeInfo?.storeDetails?.slug}/menu`;
    this.router.navigate([url]);
  };

  public filterModGroupsByAvailability(
    modGroups: MenuModifierGroup[]
  ): MenuModifierGroup[] {
    return [...modGroups.filter((modGroup) => modGroup.availableInSchedule)];
  }

  public filterUpsells() {
    if (this.selectedItem.upsells && this.selectedItem.upsells.upsellItems)
      return this.selectedItem.upsells?.upsellItems.filter(
        (item) => !item?.outOfStock && item?.availableInSchedule
      );
    return [];
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  addItemToCart = (itemId: string) => {
    this.cartService
      .addToCart(this.cart?.cartId || '', this.quantity, {
        itemId,
        modifierGroups: this.selectedModifierGroups$.value
      })
      .then(() => {
        this.isAddOrUpdateButtonClicked = false;
      });
    this.logGaEvent();
    this.navigateToAddToBag();
  };

  handleProductWithModifiersData(
    values: { selectedModifierGroups: ModGroups[]; totalPrice: number },
    item: MenuItem
  ): void {
    const { selectedModifierGroups, totalPrice } = values;
    this.selectedModifierGroups$.next(selectedModifierGroups);
    this.totalItemPrice$.next(item.price + totalPrice);
  }

  getItemPrice() {
    let latestValue: number | null = null;
    this.totalItemPrice$.subscribe((value) => {
      latestValue = value;
    });
    return latestValue || this.selectedItem?.price;
  }

  getTotalItemPrice() {
    return this.getItemPrice() * (this.quantity || 1);
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  public addOrUpdateItem() {
    return this.lineItemIdQP ? 'Update Item' : 'Add to Bag';
  }

  public addOrUpdateCartAPI(itemId: string) {
    this.startPDPValidations = true;

    if (this.failedModifierGroups?.length === 0) {
      this.isAddOrUpdateButtonClicked = true;
      this.lineItemIdQP ? this.updateItem(itemId) : this.addItemToCart(itemId);
    } else {
      this.scrollToRequiredModGroup();
    }
  }

  resetPDPValidations() {
    this.failedModifierGroups = [];
    this.startPDPValidations = false;
  }

  handleFailedModifierGroups(val: MenuModifierGroup) {
    if (!this.failedModifierGroups.some((s) => val.id == s.id)) {
      this.failedModifierGroups?.push(val);
    }
  }

  scrollToRequiredModGroup() {
    this.sortBySortOrder(this.failedModifierGroups);
    const elId = 'mod-group-section-' + this.failedModifierGroups[0].id;
    const el = document.getElementById(elId);
    this._window.scrollTo({ top: el?.offsetTop, behavior: 'smooth' });
  }

  public updateItem = (itemId: string) => {
    if (this.lineItemIdQP) {
      this.cartService
        .updateCart(
          this.cart?.cartId || '',
          this.lineItemIdQP || '',
          this.quantity,
          {
            itemId,
            modifierGroups: this.selectedModifierGroups$.value
          }
        )
        .then((res) => {
          if (res) {
            this.router.navigate(['/order/my-bag']);
          }
          this.isAddOrUpdateButtonClicked = false;
        });
      this.logGaEvent();
    }
  };

  public getSelectedLineItem() {
    if (this.lineItemIdQP) {
      return find(this.cart?.items, ['lineItemId', this.lineItemIdQP]);
    }
    return;
  }

  changeItemQuantity(change: number, changeType: 'add' | 'minus' | 'manual') {
    this.quantity = Number(this.quantity);
    this.quantity += change;
    this.previousQuantity = this.quantity;
    this.modifyQuantity(this.quantity);
    this.logProductQuantityTapEvent(changeType);
  }

  modifyQuantity(input: number) {
    const parseInput = Number(input);
    this.cdr.detectChanges();
    if (!Number.isInteger(parseInput)) {
      this.quantity = this.previousQuantity;
      return;
    }
    if (
      parseInput < ItemDetailsComponent.MIN_VALUE ||
      parseInput === undefined ||
      isNaN(parseInput)
    ) {
      this.quantity = ItemDetailsComponent.MIN_VALUE;
      this.previousQuantity = ItemDetailsComponent.MIN_VALUE;
    } else if (parseInput > ItemDetailsComponent.MAX_VALUE) {
      this.quantity = ItemDetailsComponent.MAX_VALUE;
      this.previousQuantity = ItemDetailsComponent.MAX_VALUE;
      this.notificationService.showError(
        ItemDetailsComponent.MAXIMUM_QUANTITY_ERROR_MSG
      );
    } else {
      this.quantity = parseInput;
      this.previousQuantity = parseInput;
    }
    this.logProductQuantityTapEvent('manual');
    this.itemPrice = this.getTotalItemPrice();
  }

  logProductQuantityTapEvent(changeType: 'add' | 'minus' | 'manual') {
    this.analyticsService.logGaEvent({
      event: 'product_quantity_tap',
      item_id: this.selectedItem.id,
      item_name: this.selectedItem.name,
      change_type: changeType,
      quantity: this.quantity
    });
  }

  private sortBySortOrder(modGroups: MenuModifierGroupItem[]) {
    modGroups?.sort((x, y) => {
      return x.sortOrder - y.sortOrder;
    });
  }

  private navigateToAddToBag() {
    this.subscription.add(
      this.store
        .select(CartFeature.selectCartState)
        .pipe(
          distinctUntilChanged(
            (
              previousState: CartFeatureState,
              currentState: CartFeatureState
            ) => {
              const prevCartDetails = previousState?.cart;
              const currCartDetails = currentState?.cart;
              if (currCartDetails && prevCartDetails) {
                if (
                  currCartDetails?.items?.length >
                  prevCartDetails?.items?.length
                ) {
                  this.router.navigate(['/order/my-bag']);
                }
              }
              return previousState === currentState;
            }
          )
        )
        .subscribe(CartFeature.selectCartState)
    );
  }

  private logGaEvent() {
    if (!this.selectedItem) return;
    try {
      this.analyticsService.logGaEvent({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_id: this.selectedItem.id || '',
              item_name: this.selectedItem.name,
              item_category: this.categoryName ?? '',
              price: this.selectedItem.price,
              quantity: this.quantity
            }
          ]
        },
        product_name: this.selectedItem.name
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }
  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
      .select(LocationDetailsFeature.selectLocationDetailsState)
      .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
      locationDetailsState$.subscribe((state) => {
        this.highSodiumDisplay =
          state.locationDetails?.location.highSodiumDisplay;
      })
    );
  }
}
