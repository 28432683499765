import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'expiryData'
})
export class ExpiryDataPipe implements PipeTransform {
  transform(input: { month: string; year: string }): boolean {
    const currentDate = new Date();
    const { month, year } = input;
    const getLastTwoDigitsOfYear = Number(moment(currentDate).format("YY"))
    
    if (
      getLastTwoDigitsOfYear > Number(year) ||
      (getLastTwoDigitsOfYear === Number(year) &&
        currentDate.getMonth() + 1 > Number(month))
    ) {
      return true;
    }
    return false;
  }
}
