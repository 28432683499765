import { Inject, Injectable } from '@angular/core';
import { from, map, of, switchMap, tap } from 'rxjs';

import { PaymentService } from '../../../ecomm/services/payment/payment.service';
import {
  CustomerVaultedCards,
  GiftCardDetailsRequest
} from '../../../ecomm/types/payment.types';

import * as Workflow from '../../../ecomm/types/workflow';
import { MaybeResponse } from '../../../ecomm/types/maybe-response';
import { FeatureFlagService } from '../../../ecomm/utils/feature-flag/feature-flag.service';
import { ReCaptchaService } from '../../../ecomm/utils/recaptcha/recaptcha.service';
import {
  CONFIG,
  Config
} from '../../../ecomm/providers/config/config.provider';
import { WINDOW } from '../../../ecomm/providers/window/window.provider';
import { NotificationService } from '../../../ecomm/utils/notification/notification.service';
import { AnalyticsService } from '../../providers/legacy-providers/analytics.service';

@Injectable()
export class PaymentWorkflowService {
  constructor(
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private reCaptchaService: ReCaptchaService,
    private featureFlagService: FeatureFlagService,
    private analyticsService: AnalyticsService,
    @Inject(CONFIG) private config: Config,
    @Inject(WINDOW) private window: Window
  ) {}

  private reportErrors = <T>() =>
    Workflow.onError<T>(
      tap((res) => this.notificationService.showError(res.error))
    );

  private allowVaultedCreditCards = (vaultedCreditCardView = true) =>
    Workflow.onData<CustomerVaultedCards>(
      map((res) => ({
        ...res,
        data: {
          ...res.data,
          vaultedCreditCard: vaultedCreditCardView
            ? res.data.vaultedCreditCard
            : []
        }
      }))
    );

  private allowVaultedGiftCards = (vaultedGiftCardView = true) =>
    Workflow.onData<CustomerVaultedCards>(
      map((res) => ({
        ...res,
        data: {
          ...res.data,
          vaultedGiftCard: vaultedGiftCardView ? res.data.vaultedGiftCard : []
        }
      }))
    );

  public addGiftCard = Workflow.createWorkflow(
    undefined,
    (giftCardData: GiftCardDetailsRequest) =>
      from(this.reCaptchaService.execute('gift_card_balance')).pipe(
        switchMap((recaptchaToken) =>
          this.paymentService.getGiftCardDetails(giftCardData, recaptchaToken)
        )
      ),
    this.reportErrors(),
    Workflow.onError(
      tap(() => {
        this.analyticsService.logGaEvent({
          event: 'checkout_error',
          error_reason: 'add_gift_card_failed'
        });
      })
    )
  );

  public getPaymentSessionDetails = Workflow.createWorkflow(
    undefined,
    (customErrorMessage: boolean) =>
      this.paymentService.getPaymentSessionDetails(customErrorMessage),
    this.reportErrors()
  );

  public validateMerchantForApplePay = Workflow.createWorkflow(
    undefined,
    (validationUrl: string) =>
      of(validationUrl).pipe(
        map((validationUrl) => ({
          validationUrl: validationUrl,
          merchantIdentifier: this.config.applePay.merchantId,
          displayName: 'Wingstop' as const,
          initiative: 'web' as const,
          initiativeContext: this.window.location.host
        })),
        switchMap((request) =>
          this.paymentService.validateMerchantForApplePay(request)
        )
      ),
    this.reportErrors()
  );

  public getVaultedGiftAndCreditCards = (
    vaultedCreditCardView = true,
    vaultedGiftCardView = true
  ) =>
    Workflow.createWorkflow<[], CustomerVaultedCards>(
      undefined,
      () =>
        vaultedCreditCardView || vaultedGiftCardView
          ? this.paymentService.getVaultedCards()
          : of({
              data: {
                vaultedCreditCard: [],
                vaultedGiftCard: []
              }
            } as MaybeResponse<CustomerVaultedCards>),
      this.reportErrors(),
      this.allowVaultedCreditCards(vaultedCreditCardView),
      this.allowVaultedGiftCards(vaultedGiftCardView)
    )();
  public deleteVaultedCard = Workflow.createWorkflow(
    undefined,
    (vaultedAccountId: string, deleteCreditCard: boolean) =>
      this.paymentService.deleteVaultedCard(vaultedAccountId, deleteCreditCard),
    this.reportErrors()
  );
}
