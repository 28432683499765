 <ng-container
 *ngIf="
   modifiers?.length > 0
 "
>
 <wri-flavor-spice-heading
   [heading]="flavorHeading"
   [iconColor]="flavorColor"
   [type]="flavorType"
   [isMoreInfoNeeded]="false"
 ></wri-flavor-spice-heading>
 <div
   *ngFor="
     let modifier of modifiers
     index as i;
     let last = last
   "
 >
   <wri-flavor-modal-item
     [id]="modifier.id"
     [name]="modifier?.metadata['group-name'] || modifier.name"
     [images]="modifier.images"
     [heatScale]="modifier | heatScale"
     [description]="modifier.description"
     [rubType]="modifier.metadata['rub-type']"
     [isNew]="modifier.isNew"
     [isPopular]="modifier.isPopular"
     [showDivider]="!last"
     [calorieRange]="returnCalorieRange(modifier)"
   ></wri-flavor-modal-item>
 </div>
</ng-container>
