import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ECOMM_API_CONFIG,
  EcommAPIConfig
} from '../../config/ecomm-config.provider';
import { MaybeResponse } from '../../types/maybe-response';
import {
  GeocodePlacesDto,
  GeocodePlacesResponse,
  LocationResponseDto,
  LocationsResponseDto,
  RegionResponseDto,
  SearchByDeliveryAddressRequest,
  SearchByIdRequest,
  SearchByIdsRequest,
  SearchByLocalityRequest,
  SearchByCarryoutRequest,
  SearchBySlugRequest,
  DeliveryLocationResponseDto,
} from '../../types/search-location.types';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';
import { handleAPIResponse } from '../../utils/handle-api-response';

@Injectable({ providedIn: 'root' })
export class SearchLocationRepository {
  constructor(
    private http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService
  ) {}

  public getGeocodePlaces(
    searchText: string,
    serviceMode: string
  ): Observable<MaybeResponse<GeocodePlacesDto>> {
    const request = {
      searchText,
      serviceMode
    };
    const url = `${this.config.baseUrl}/geocoding-worker`;
    return this.http
      .post<GeocodePlacesResponse>(url, request)
      .pipe(
        handleAPIResponse(
          GeocodePlacesResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getLocationsByIds(body: SearchByIdsRequest) {
    const url = `${this.config.baseUrl}/location-worker`;
    return this.http
      .post<LocationsResponseDto>(url, body, { params: { type: 'ids' } })
      .pipe(
        handleAPIResponse(
          LocationsResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getLocationById(body: SearchByIdRequest) {
    const url = `${this.config.baseUrl}/location-worker`;
    return this.http
      .post<LocationResponseDto>(url, body, {
        params: { type: 'id' }
      })
      .pipe(
        handleAPIResponse(
          LocationResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getLocationBySlug(body: SearchBySlugRequest) {
    const url = `${this.config.baseUrl}/location-worker`;
    return this.http
      .post<LocationResponseDto>(url, body, {
        params: { type: 'slug' }
      })
      .pipe(
        handleAPIResponse(
          LocationResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getLocationsByLocality(body: SearchByLocalityRequest) {
    const url = `${this.config.baseUrl}/location-worker`;
    return this.http
      .post<LocationsResponseDto>(url, body, {
        params: { type: 'locality' }
      })
      .pipe(
        handleAPIResponse(
          LocationsResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getRegionSummary() {
    const url = `${this.config.baseUrl}/api/locations/states`;
    return this.http
      .get<RegionResponseDto>(url)
      .pipe(
        handleAPIResponse(
          RegionResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getLocationsByCarryout(body: SearchByCarryoutRequest) {
    const url = `${this.config.baseUrl}/location-worker`;
    return this.http
      .post<LocationsResponseDto>(url, body, {
        params: { type: 'carryout' }
      })
      .pipe(
        handleAPIResponse(
          LocationsResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getLocationByDeliveryAddress(body: SearchByDeliveryAddressRequest) {
    const url = `${this.config.baseUrl}/location-worker`;

    return this.http
      .post<DeliveryLocationResponseDto>(url, body, {
        params: { type: 'delivery', version : 3 }
      })
      .pipe(
        handleAPIResponse(
          DeliveryLocationResponseDto,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }
}
