import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageResolution'
})
export class ImageResolutionPipe implements PipeTransform {
  transform(
    input: string | null,
    srcType: string,
    pageType: string,
    fallback: string
  ): string {
    if (srcType === 'srcset')
      return input ? this.srcset(pageType, input) : fallback;
    else return input ? this.src(pageType, input) : fallback;
  }

  private srcset(page: string, input: string | null) {
    switch (page) {
      case 'standard-menu': {
        return `${input}&width=450, ${input}&width=675 1.5x, ${input}&width=900 2x`;
      }
      case 'condensed-menu': {
        return `${input}&width=80, ${input}&width=120 1.5x, ${input}&width=160 2x`;
      }
      case 'menu-recommendations': {
        return `${input}&width=450, ${input}&width=675 1.5x, ${input}&width=900 2x`;
      }
      case 'cart-recommendations': {
        return `${input}&width=400, ${input}&width=600 1.5x, ${input}&width=800 2x`;
      }
      case 'pdp-item': {
        return `${input}&width=450, ${input}&width=675 1.5x, ${input}&width=900 2x`;
      }
      case 'pdp-upsells': {
        return `${input}&width=100, ${input}&width=150 1.5x, ${input}&width=200 2x`;
      }
      case 'flavors-page': {
        return `${input}&width=120, ${input}&width=180 1.5x, ${input}&width=240 2x`;
      }
      case 'pdp-modifiers': {
        return `${input}&width=80, ${input}&width=120 1.5x, ${input}&width=160 2x`;
      }
      case 'pdp-addons': {
        return `${input}&width=400, ${input}&width=600 1.5x, ${input}&width=800 2x`;
      }
      case 'home-recommendations': {
        return `${input}&width=400, ${input}&width=600 1.5x, ${input}&width=800 2x`;
      }
      case 'wing-calculator-products': {
        return `${input}&width=400, ${input}&width=600 1.5x, ${input}&width=800 2x`;
      }
      case 'charity-image': {
        return `${input}&width=48, ${input}&width=96 2x, ${input}&width=144 3x`
      }
      default: {
        return `${input}`;
      }
    }
  }

  private src(page: string, input: string | null) {
    switch (page) {
      case 'standard-menu': {
        return `${input}&width=450`;
      }
      case 'condensed-menu': {
        return `${input}&width=80`;
      }
      case 'menu-recommendations': {
        return `${input}&width=450`;
      }
      case 'cart-recommendations': {
        return `${input}&width=400`;
      }
      case 'pdp-item': {
        return `${input}&width=450`;
      }
      case 'pdp-upsells': {
        return `${input}&width=100`;
      }
      case 'pdp-modifiers': {
        return `${input}&width=80`;
      }
      case 'pdp-addons': {
        return `${input}&width=400`;
      }
      default: {
        return `${input}`;
      }
    }
  }
}
