import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wri-custom-tip',
  templateUrl: './custom-tip.component.html',
  styleUrls: ['./custom-tip.component.scss']
})
export class CustomTipComponent {
  @Input() customTipAmount: number | string | null = null;
  @Input() showCustomTipInput: boolean | undefined;
  @Output() valuesChanged = new EventEmitter<{
    tipAmount: number | null;
  }>();

  @Output()
  tipInputValuesChanged = new EventEmitter<{
    customTipValue: number;
  }>();

  setTwoNumberDecimal(event: { target: { value: number } }) {
    const inputValue = event.target.value;
    this.tipInputValuesChanged.emit({ customTipValue: inputValue });
  }

  onCustomInputSubmit() {
    this.valuesChanged.emit({
      tipAmount: (this.customTipAmount === "" || this.customTipAmount === null)
        ? null : Number(this.customTipAmount)
    });
  }
}
