import { FactoryProvider, InjectionToken, Injector } from '@angular/core';

import { CONFIG } from '../config/config.provider';
import { SCRIPT_LOADER } from '../script-loader/script-loader.provider';
import { WINDOW } from '../window/window.provider';
import { UcomSDK } from './ucom-sdk';

export type UcomSDKProviderType = Promise<UcomSDK | undefined>;

export const UCOM_SDK_PROP_KEY = 'ucomSDK';

export const UCOM_SDK = new InjectionToken<UcomSDKProviderType>(
  UCOM_SDK_PROP_KEY
);

export const ucomSDKProvider = async (
  injector: Injector
): Promise<UcomSDKProviderType> => {
  const _window = injector.get(WINDOW) as unknown as Record<string, unknown>;
  const _scriptLoader = injector.get(SCRIPT_LOADER);
  const _config = injector.get(CONFIG);
  return _scriptLoader(_config.fiserv.sdkUri)
    .then(() => {
      return _window[UCOM_SDK_PROP_KEY] as UcomSDKProviderType;
    })
    .catch(() => {
      return undefined;
    });
};

export class UcomSDKProvider {
  public static get = (): FactoryProvider => ({
    provide: UCOM_SDK,
    deps: [Injector],
    useFactory: ucomSDKProvider
  });
}
