import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Input
} from '@angular/core';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';

import {
  MenuCategory,
  MenuItem
} from '../../../../ecomm/types/store-info.types';

@Component({
  selector: 'wri-category-navigation',
  templateUrl: './category-navigation.component.html',
  styleUrls: ['./category-navigation.component.scss']
})
export class CategoryNavigationComponent implements AfterViewInit {
  @Input()
  public categories!: MenuCategory[];
  lastSelectedSlug: string | null = '';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private _window: Window,
    private analyticsService: AnalyticsService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.onScroll();
    }, 500);
  }

  @HostListener('window:resize')
  onResize() {
    this.onScroll();
  }

  @HostListener('window:scroll')
  public scrollWindow() {
    const scrollContainer = this.getElementByClass('focus-outline active');
    if (scrollContainer?.hasAttribute('log-data')) {
      if (scrollContainer?.getAttribute('log-data') != this.lastSelectedSlug) {
        this.lastSelectedSlug = scrollContainer?.getAttribute('log-data');
        const selectedCategoryItems = this.getSelectedCategoryItems(
          this.lastSelectedSlug != null ? this.lastSelectedSlug : ''
        );
        try {
          this.analyticsService.logGaEvent({
            event: 'view_item_list',
            ecommerce: {
              items:
                selectedCategoryItems.map((item) => ({
                  item_id: item.id,
                  item_name: item.name,
                  item_category:
                    this.getSelectedCategoryName(this.lastSelectedSlug ?? '') ??
                    '',
                  price: item.price,
                  quantity: 1
                })) || []
            }
          });
        } catch (ignore) {
          // if GA cannot log event (ie due to an ad-blocker), catch error and continue
        }
      }
    }
  }

  public onScroll() {
    const element = document.getElementById('scrollWrapper');
    const scrollContainer = this.getElementByClass(
      'wri-category-nav-container'
    );

    if (scrollContainer?.scrollLeft > 0) {
      element?.classList.add('scrolled-left');
    } else {
      element?.classList.remove('scrolled-left');
    }

    if (
      scrollContainer?.scrollLeft + scrollContainer?.clientWidth <
        scrollContainer?.scrollWidth ||
      (scrollContainer?.scrollLeft === 0 &&
        scrollContainer.clientWidth !== scrollContainer?.scrollWidth)
    ) {
      element?.classList.add('scrolled-right');
    } else {
      element?.classList.remove('scrolled-right');
    }
  }

  scrollToSelectedElement(categorySlug: string) {
    const element = this._document.getElementById(categorySlug);
    if (element) {
      const headerHeight = this._document.getElementsByClassName(
        'desktop nav-container'
      )?.[0].clientHeight;
      const noHeaderExistsHeightOffset = -140;
      const headerHeightOffset = 60;
      const yOffset =
        headerHeight == 0
          ? noHeaderExistsHeightOffset
          : -(headerHeight + headerHeightOffset);
      const y =
        element.getBoundingClientRect().top +
        this._window.pageYOffset +
        yOffset;
      this._window.scrollTo({ top: y, behavior: 'smooth' });
      element.focus();
    }
  }

  scrollToSelectedCategory(event: Event, categorySlug: string) {
    event.preventDefault();
    this.scrollToSelectedElement(categorySlug);
  }

  focusOnCat(categorySlug: string) {
    const element = this._document.getElementById(`cat-${categorySlug}`);
    if (element) {
      const eLeft = element.getBoundingClientRect().left;
      const eRight = element.getBoundingClientRect().right;
      let container: Element;

      container = element.parentNode as Element;

      while (
        container &&
        !/auto|scroll/.test(
          /* eslint-disable @typescript-eslint/no-explicit-any */
          (window.getComputedStyle(container) as any)['overflow-x']
        )
      ) {
        container = container.parentNode as Element;
      }

      if (container) {
        const cWidth = container.clientWidth;
        if (eRight > cWidth) {
          container.scrollLeft += eRight - cWidth + 30;
        } else if (eLeft < 150) {
          container.scrollLeft += eLeft - 150;
        }
      }
    }
  }

  private getElementByClass(className: string) {
    return this._document.getElementsByClassName(className)?.[0];
  }

  private getSelectedCategoryName(categorySlug: string): string | undefined {
    return this.categories.find((a) => a.slug === categorySlug)?.name;
  }

  private getSelectedCategoryItems(categorySlug: string) {
    const selectedCategoryItems: MenuItem[] = [];
    this.categories
      .filter((a) => a.slug == categorySlug)
      .forEach((category) => {
        category.products.forEach((product) => {
          if (
            product.item !== null &&
            !product.item?.outOfStock &&
            product.item?.availableInSchedule
          )
            selectedCategoryItems.push(product.item);

          if (product.itemGroup !== null) {
            product.itemGroup?.items.forEach((item) => {
              if (
                !(item as MenuItem | undefined)?.outOfStock &&
                (item as MenuItem | undefined)?.availableInSchedule
              )
                selectedCategoryItems.push(item);
            });
          }
        });
      });
    return selectedCategoryItems;
  }
}
