import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

import { Optional } from '../../../../ecomm/types/common.types';
import { CustomerDeliveryAddress } from '../../../../ecomm/types/customer.types';
import { Location } from '../../../../ecomm/types/search-location.types';
import { LocationSearchNavigateEvent } from '../location-search-card/location-search-card.component';

type View =
  | 'searching'
  | 'no-locations'
  | 'locations'
  | 'saved-delivery-addresses'
  | 'empty'
  | 'noLogin';

@Component({
  selector: 'wri-location-search-list',
  template: `
    <ng-container [ngSwitch]="view">
      <ng-template [ngSwitchCase]="VIEWS['searching']">
        <div class="searching">
          <wri-spinner></wri-spinner>
          <p>{{ loadingMessage }}</p>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="VIEWS['noLogin']">
        <div class="no-locations-found">
          <div class="icon-round">
            <wri-icon icon="wri-location"></wri-icon>
          </div>
          <div>{{ noLocationsTitle }}</div>
          <p>
            Get the most flavor for your click when you
            <a
              [type]="type"
              wriFocusOutline
              role="button"
              [attr.aria-label]="'log in'"
              (click)="login.emit()"
              class="btn-login"
            >
              log in.</a
            >
            {{ noLoginMessage }}
          </p>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="VIEWS['noLocations']">
        <div class="no-locations-found">
          <div class="icon-round">
            <wri-icon icon="wri-location"></wri-icon>
          </div>
          <div>{{ noLocationsTitle }}</div>
          <p>{{ noLocationsMessage }}</p>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="VIEWS['locations']">
        <wri-location-search-card
          *ngFor="let location of locations"
          [locationData]="location"
          [selectedHandoffMode]="selectedHandoffMode"
          [showFocus]="location.id === selectedLocationMarkerId"
          (navigate)="navigate.emit($event)"
          (isLocationUnFavorited)="isLocationUnFavorited.emit($event)"
          [selectedTab]="tab"
        ></wri-location-search-card>
      </ng-template>

      <ng-template [ngSwitchCase]="VIEWS['savedDeliveryAddresses']">
        <wri-saved-delivery-address
          *ngFor="let address of savedDeliveryAddresses"
          [address]="address"
          (selected)="selectSavedDeliveryAdress.emit($event)"
        ></wri-saved-delivery-address>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./location-search-list.component.scss']
})
export class LocationSearchListComponent {
  @Input() locations?: Optional<Location[]>;
  @Input() savedDeliveryAddresses?: Optional<CustomerDeliveryAddress[]>;
  @Input() selectedLocationMarkerId?: Optional<string>;
  @Input() selectedHandoffMode?: Optional<string>;
  @Input() searching?: Optional<boolean>;
  @Input() tab = '';
  @Input() isUserAuthenticated = false;

  @Output() navigate = new EventEmitter<LocationSearchNavigateEvent>();
  @Output() login = new EventEmitter();
  @Output() selectSavedDeliveryAdress =
    new EventEmitter<CustomerDeliveryAddress>();

  @Output() isLocationUnFavorited = new EventEmitter<{
    isUnfavorited: boolean;
    locationId: string;
  }>();

  public readonly VIEWS: Record<string, View> = {
    searching: 'searching',
    noLocations: 'no-locations',
    locations: 'locations',
    savedDeliveryAddresses: 'saved-delivery-addresses',
    empty: 'empty'
  } as const;

  @HostBinding('class')
  get view(): View | void {
    if (this.searching) {
      return this.VIEWS['searching'];
    }

    if (this.isUserNotLoggedIn) {
      return this.VIEWS['noLogin'];
    }

    if (this.noLocationsFound) {
      return this.VIEWS['noLocations'];
    }

    if (this.isCarryout && this.locationsFound) {
      return this.VIEWS['locations'];
    }

    if (!this.isCarryout && this.hasSavedDeliveryAddresses) {
      return this.VIEWS['savedDeliveryAddresses'];
    }

    return this.VIEWS['empty'];
  }

  get isCarryout() {
    return this.selectedHandoffMode === 'carryout';
  }

  get noLocationsFound() {
    return (
      this.locations !== undefined &&
      this.locations !== null &&
      this.locations.length === 0
    );
  }

  get locationsFound() {
    return (
      this.locations !== undefined &&
      this.locations !== null &&
      this.locations.length !== 0
    );
  }

  get hasSavedDeliveryAddresses() {
    return (
      this.savedDeliveryAddresses && this.savedDeliveryAddresses.length !== 0
    );
  }

  get loadingMessage() {
    return `Loading Results`;
  }

  get noLocationsTitle() {
    if (!this.isCarryout) {
      return 'No Delivery Locations';
    }

    switch (this.tab) {
      case 'nearby':
        return 'No Nearby Locations';
      case 'recent':
        return 'No Recent Locations';
      case 'favorites':
        return 'No Favorite Locations';
      default:
        return '';
    }
  }

  get noLocationsMessage() {
    if (this.selectedHandoffMode === 'carryout') {
      switch (this.tab) {
        case 'nearby':
          return `Sorry, no nearby locations were found. Try searching in a different area.`;
        case 'recent':
          return `You have not placed any orders yet. Start an order and we'll keep track of your recent locations for quick reordering.`;
        case 'favorites':
          return `You don't have any favorite locations yet. Click the heart icon on your nearby locations to favorite and quickly reorder from them.`;
        default:
          return '';
      }
    } else {
      return `Bummer! It doesn't seem like any restaurant can deliver to your address. Why not order some delicious wings for carryout?`;
    }
  }

  get noLoginMessage() {
    return this.tab === 'recent' || this.tab === 'favorites'
      ? `View your ${this.tab} locations for faster checkout, every time.`
      : null;
  }

  get isUserNotLoggedIn() {
    return (
      this.selectedHandoffMode === 'carryout' &&
      (this.tab === 'recent' || this.tab === 'favorites') &&
      !this.isUserAuthenticated
    );
  }
}
