import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';

@Component({
  template: ``
})
export class LoginComponent implements OnInit {
  constructor(
    protected store: Store,
    private redirectService: RedirectService,
    private authService: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    const auth = await this.authService.getCurrentAuth();
    if(auth.isAuthenticated) {
      this.redirectService.redirectToMyAccount();
    } else {
      await this.authService.login(this.router.url);
    }
  }
}
