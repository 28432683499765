import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlavorsResponse } from '../../types/flavor.types';
import * as MaybeResponse from '../../types/maybe-response';
import { handleResourceResponse } from '../../utils/handle-resource-response';
import { WINDOW } from '../../providers/window/window.provider';

@Injectable({ providedIn: 'root' })
export class FlavorsRepository {
  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window
  ) {}

  getFlavors(): Observable<MaybeResponse.MaybeResponse<FlavorsResponse>> {
    const url = `${this.window.location.origin}/resources/flavors.json`;
    return this.http
      .get<FlavorsResponse>(url)
      .pipe(handleResourceResponse(FlavorsResponse));
  }
}
