<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<!-- error state -->
<wri-generic-error
  *ngIf="storeInfoState | shouldShowLocationError; else bag"
></wri-generic-error>

<ng-template #bag>
  <div class="bag-container">
    <h1 class="sr-only" tabindex="-1">My Bag</h1>
    <div class="my-bag">
      <div class="col-lg-8">
        <section class="continue-shopping">
          <button
            wriFocusOutline
            [ngClass]="'wri-btn wri-btn-secondary'"
            aria-label="Add more items"
            (click)="goToMenu()"
          >
            Add more items
            <wri-icon
              class="icon arrow-long-right"
              icon="wri-arrow-long-right"
            ></wri-icon>
          </button>
          <div class="keep-shopping">
            <a
              wriFocusOutline
              tabindex="0"
              data-testid="keep-shopping-anchor"
              (click)="goToMenu()"
            >
              <wri-icon
                icon="wri-arrow-back"
                class="arrow-left-icon"
              ></wri-icon>
              <span data-cy="keepShopping" class="breadcrumb-text"
                >Keep Shopping</span
              >
            </a>
          </div>
        </section>
        <section
          *ngIf="(itemsForMenuAdditionalRecommendation$ | async)?.length > 0"
          class="recommended-additions"
        >
          <wri-cart-additional-recommendation
            [sliderData]="itemsForMenuAdditionalRecommendation$ | async"
            [handoffMode]="cart?.handoffMode"
          >
          </wri-cart-additional-recommendation>
        </section>

        <ng-container
          *ngIf="
            cart?.items.length === 0 || cart?.items === undefined;
            then noItemsInCart;
            else products
          "
        ></ng-container>
        <ng-template #noItemsInCart>
          <section class="pad border-bottom-shade empty-cart spacing">
            <wri-add-more-items
              [locationSlug]="(storeInfo$ | async)?.storeDetails.slug"
            ></wri-add-more-items>
          </section>
        </ng-template>
        <ng-template #products>
          <section class="products spacing-top-bottom review-order">
            Review Order
          </section>
          <wri-review-order-items [cart]="cart"></wri-review-order-items>
        </ng-template>
      </div>
      <div class="col-lg-4">
        <div class="bag-sidebar">
          <section class="border-bottom-shade handoff spacing">
            <wri-cart-address
              [locationDetails]="storeInfo$ | async | getLocationDetails : cart?.handoffMode"
              [cart]="cart"
              [showPhoneNumber]="showPhoneNumber"
              [showEditIcon]="true"
              [showErrorText]="true"
              [overrideReasonDescription]="getOverrideReasonDescription()"
            ></wri-cart-address>
          </section>
          <section class="border-bottom-shade fulfillment-time spacing" id="select-time-to-order">
            <wri-cart-fulfillment-time
              [fulfillmentTime]="fulfillmentTime"
              [handoffMode]="cart?.handoffMode"
              [ftForLater]="laterTime"
              (futureDateTimeSelect)="onFutureDateSave($event)"
              (selectedDateTimeValid)="selectedDateTimeValid($event)"
              [cartFulfillmentTime]="cart?.fulfillmentTime"
              [cartReadyTimes]="cart?.readyTimes"
              [error$]="selectTimeErrorType$"
              [fulfillmentLaterFlowStateOpen]="fulfillmentLaterFlowStateOpen"
              [storeInfo]="storeInfo$ | async"
            >
            </wri-cart-fulfillment-time>
          </section>
          <section class="border-bottom-shade promo-code spacing">
            <wri-apply-promo-code
              [cartFeatureState]="cartState"
              [activeOfferFeatureState]="activeOfferState">
            </wri-apply-promo-code>
          </section>
          <section
            *ngIf="isTippingAvailable()"
            class="tip-selector border-bottom-shade tip-selection spacing"
          >
            <wri-tip 
              [tipOptions]="(regionalConfig$ | async)?.tipConfiguration 
                | tipOptions : cart?.handoffMode : cart?.subtotal | pick : 'tipOptions'"
              
              [handOffMode]="this.cart?.handOffMode" 
              [cartFeatureState]="cartState" 
              [currencyType]="(regionalConfig$ | async)?.tipConfiguration 
                | tipOptions : cart?.handoffMode : cart?.subtotal | pick : 'currencyType'"
            />
          </section>
          <section class="border-bottom-shade price-summary spacing">
            <wri-cart-price-summary [cart]="cart"></wri-cart-price-summary>
          </section>
          <section *ngIf="isMobile" class="border-bottom-shade reset-cart spacing">
            Having trouble with your cart?
            <a
              wriFocusOutline
              tabindex="0"
              class="wri-btn wri-btn-text"
              aria-label="Start a new one"
              (click)="openResetCartModal()"
            >
              Start a new one
            </a>
          </section>
          <section class="checkout spacing" id="order-button-section">
            <button
              class="wri-btn wri-btn-primary g-recaptcha"
              (click)="goToCheckout()"
              [disabled]="isCartEmpty() || isLoading"
              [attr.aria-label]="
                numItems +
                ' items in your bag, Checkout, ' +
                (cart?.total | currency : 'USD' : 'symbol') +
                ' due'
              "
              data-testid="checkout-btn"
            >
              <span
                class="badge float-left"
                data-testid="checkout-btn-number-of-items"
              >
                [{{ numItems }}]
              </span>
              Checkout
              <span
                class="badge float-right"
                data-testid="checkout-btn-total-price"
              >
                {{
                  cart?.items && cart.items.length > 0
                    ? (cart?.total | currency : 'USD' : 'symbol')
                    : '$0.00'
                }}
              </span>
            </button>
            <wri-google-recaptcha-branding />
          </section>
        </div>
        <div *ngIf="!isMobile" class="reset-cart">
          Having trouble with your cart?
          <a
            wriFocusOutline
            tabindex="0"
            data-testid="open-reset-cart-modal"
            class="wri-btn wri-btn-text"
            aria-label="Start a new one"
            (click)="openResetCartModal()"
          >
            Start a new one
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title"
      >Start Over?</span
      >
      <span class="wri-modal__description"
      >Are you sure you want to reset your cart and start over? All previous selections will be lost.</span
      >
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-primary"
          aria-label="Start Over"
          (click)="resetCartState()"
        >
          Start Over
        </button>
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="closeModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
