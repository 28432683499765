import {Directive, ElementRef, Renderer2, OnInit, Input} from '@angular/core';

@Directive({
  selector: '[wriInteractionStudio]'
})
export class InteractionStudioDirective implements OnInit {
  @Input() interactionStudioId!: string;
  @Input() wriInteractionStudio!: string;

  constructor(public renderer : Renderer2, public hostElement: ElementRef){}

  ngOnInit() {
    this.renderer.setAttribute(this.hostElement.nativeElement, this.wriInteractionStudio, this.interactionStudioId);
    this.renderer.setAttribute(this.hostElement.nativeElement, 'interactionStudio', this.wriInteractionStudio);
  }
}
