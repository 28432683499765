import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  MenuItem,
  MenuModifier
} from '../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { BreadCrumbType } from '../../../common';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';

@Component({
  selector: 'wri-global-item-details',
  templateUrl: './global-item-details.component.html',
  styleUrls: ['./global-item-details.component.scss']
})
export class GlobalItemDetailsComponent implements OnInit {
  @Input() public selectedItem!: MenuItem;
  @Input() breadcrumbs: Array<BreadCrumbType> | undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {
    addSpaceToBodyWithStickyFooter('pdp');
  }

  public startOrder = () => {
    this.router.navigate(['order'], {
      queryParams: {
        product: this.selectedItem?.slug
      }
    });
  };

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }
}
