import { Observable, from, switchMap } from 'rxjs';

export const asyncTap =
  <T>(sideEffect: (input: T) => Promise<unknown>) =>
  (input$: Observable<T>) =>
    input$.pipe(
      switchMap((input) => from(sideEffect(input).then(() => input)))
    );

export const asyncMap =
  <T, O>(mapper: (input: T) => Promise<O>) =>
  (input$: Observable<T>) =>
    input$.pipe(switchMap((input) => from(mapper(input))));
