import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CONFIG, Config } from '../../providers/config/config.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE
} from '../../providers/legacy-providers/seo.service';
import { routerNavigatedAction } from '@ngrx/router-store';
import { tap } from 'rxjs';

export const CAN_USE_CUSTOM_SEO = 'useCustomSeo';

@Injectable({ providedIn: 'root' })
export class StaticSeoMetadataEffects {
  constructor(
    private actions$: Actions,
    @Inject(CONFIG)
    private config: Config,
    @Inject(LEGACY_SEO_SERVICE) private seoService: ILegacySeoService
  ) {}

  readonly updateMetadata$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        tap(({ payload }) => {
          const route = payload.routerState.root;
          const canUseCustomSeo = !!route.data[CAN_USE_CUSTOM_SEO] ?? false;
          const path = route.fragment ?? '';
          const bestMatch: Config['seoMetadata'][number] | undefined =
            this.config.seoMetadata
              .filter((meta) => path.startsWith(meta.route))
              .sort((a, b) => b.route.length - a.route.length)[0];

          console.log({
            canUseCustomSeo,
            path,
            bestMatch
          });
          if (!canUseCustomSeo && bestMatch) {
            window.onload = () => {
              this.seoService.setMetaData(bestMatch);
            };
          }
        })
      );
    },
    { dispatch: false }
  );
}
