<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title"
      >{{ outage_message_title }}</span
      >
      <span class="wri-modal__description"
      >{{ outage_message_description }}</span
      >
      <div class="wri-modal__btn-wrapper">
        <button
          class="wri-btn wri-btn-primary"
          aria-label="Got it"
          (click)="closeModal()"
        >
          Got it
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
