import { Component } from '@angular/core';

@Component({
  selector: 'wri-page-loading-indicator',
  template: ` <div class="loading-indicator-wrapper-outer">
    <div class="loading-indicator-wrapper-inner">
      <wri-animation animation="loading" width="80"></wri-animation>
    </div>
  </div>`,
  styleUrls: ['./page-loading-indicator.component.scss']
})
export class PageLoadingIndicatorComponent {}
