<div class="flavor-quantity-wpr">
  <span class="quantity-title">Number of {{ itemType | titlecase }}:</span>
  <div class="quantity-container">
    <a
      wriFocusOutline
      class="wri-flavor-quantity-btn"
      [ngClass]="{'disabled-btn' : isMinModifierQuantityInvalid()}"
      role="button"
      (click)="changeFlavorQuantity(-1)"
      aria-label="Decrease flavor quantity"
    >
      <wri-icon class="icon" icon="wri-minus"></wri-icon>
    </a>
    <!-- restrict user from entering special characters like +, decimals etc -->
    <input
      type="tel"
      min="0"
      onkeypress="return (event.charCode !== 8 && event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57))"
      [attr.data-testid]="flavorId | prepend : 'quantity-'"
      [attr.value]="quantity"
      [attr.aria-label]="quantity"
      class="quantity-text"
      (focus)="previousQuantity = quantity"
      (blur)="handleQuantityValidity($event)"
      [(ngModel)]="quantity" />
    <a
      wriFocusOutline
      class="wri-flavor-quantity-btn"
      [ngClass]="{'disabled-btn' : isMaxModifierQuantityInvalid()}"
      role="button"
      (click)="changeFlavorQuantity(1)"
      aria-label="Increase flavor quantity"
    >
      <wri-icon class="icon" icon="wri-plus"></wri-icon>
    </a>
  </div>

</div>
