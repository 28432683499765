import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { formatBusinessDayTimings } from '../../../../ecomm/utils/format-business-day-timings';

type Hours = {
  startDay: string;
  startTime: string;
  endDay: string;
  endTime: string;
};

@Component({
  selector: 'wri-todays-hours',
  styleUrls: ['./todays-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p class="hours">
      <wri-icon icon="wri-clock"></wri-icon><span>{{ todayStoreHours }}</span>
    </p>
  `
})
export class TodaysHoursComponent {
  @Input()
  businessDay!: string;
  @Input()
  hours!: Hours[];

  get todayStoreHours(): string {
    const hours =
      this.hours?.filter((h) => {
        const startDay = h.startDay.toLowerCase();
        const businessDay = this.businessDay.toLowerCase();
        return startDay === businessDay;
      }) ?? [];
    return formatBusinessDayTimings(hours);
  }
}
