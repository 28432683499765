import { Component } from '@angular/core';

import { CurrentUrlService } from '../../../../../ecomm/utils/current-url/current-url.service';

@Component({
  selector: 'wri-no-payment-methods',
  templateUrl: './no-payment-methods.component.html',
  styleUrls: ['./no-payment-methods.component.scss']
})
export class NoPaymentMethodsComponent {
  constructor(private currentUrlService: CurrentUrlService) {}

  public goToOrderPage = () => {
    this.currentUrlService.goToOrderPage();
  };
}
