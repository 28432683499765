import { InjectionToken, ValueProvider } from '@angular/core';

export const DOCUMENT = new InjectionToken<Document>(
  'To inject the DOCUMENT object into components & services'
);

export class DocumentProvider {
  public static get = (): ValueProvider => ({
    provide: DOCUMENT,
    useValue: document
  });
}
