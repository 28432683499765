<div
  [attr.data-testid]="id | prepend : 'text-input-for-'"
  class="wri-text-input"
>
  <label [attr.for]="id" [attr.data-testid]="id | prepend : 'label-for-'">
    {{ label }}
  </label>

  <ng-container
    *ngIf="type === 'textarea'; then textAreaInput; else textInput"
  ></ng-container>
  <ng-template #textAreaInput>
    <textarea
      class="wri-textarea"
      rows="1"
      [id]="id"
      [attr.placeholder]="placeholder"
      [attr.autocomplete]="autoComplete"
      [attr.type]="type"
      [attr.data-testid]="id | prepend : 'input-for-'"
      [attr.aria-label]="
        ariaLabel ? ariaLabel : (label | prepend : 'input for')
      "
      [value]="textValue"
      [disabled]="disabled"
      (input)="updateValue($event)"
      (blur)="showErrors()"
      (focus)="hideErrors()"
    ></textarea>
  </ng-template>
  <ng-template #textInput>
    <input
      [id]="id"
      [attr.placeholder]="placeholder"
      [attr.autocomplete]="autoComplete"
      [attr.type]="type"
      [attr.data-testid]="id | prepend : 'input-for-'"
      [attr.aria-label]="ariaLabel"
      [value]="formatter(textValue)"
      [disabled]="disabled"
      [class.can-show-errors]="canShowErrors"
      [class.can-show-errors-gold-bg]="canShowErrorsWithGoldBg"
      (input)="updateValue($event)"
      (keypress)="preventNonNumericalInput($event)"
      (blur)="showErrors()"
      (focus)="onFocus()"
      [class]="showIcon ? 'show-icon' : ''"
    />
    <wri-icon *ngIf="showLockIcon" icon="wri-lock" class="input-icon wri-lock">
    </wri-icon>
    <wri-icon
      [ariaLabel]="ariaLabel"
      [ariaPressed]="ariaPressed"
      [isToggleable]="isToggleable"
      tabindex="0"
      icon="{{icon}}"
      class="input-icon {{icon}}"
      (click)="iconClick.emit()">
    </wri-icon>
  </ng-template>

  <div
    [attr.data-testid]="id | prepend : 'errors-for-'"
    class="inner-div"
    [hidden]="!canShowErrors && isFormValid"
  >
    <ng-content></ng-content>
  </div>
</div>
