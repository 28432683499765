import { Component, Input } from '@angular/core';

@Component({
  selector: 'wri-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() label = '';
  @Input() type = 'success';
  @Input() icon? = 'wri-check';
}
