/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, Subscription } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { CartFeature } from '../../../../ecomm/store/features/cart';
import { CurrentUrlService } from '../../../../ecomm/utils/current-url/current-url.service';

@Component({
  selector: 'wri-connected-bag-count',
  template:
    '<wri-bag-count [count]="cartCount | async" (clicked)="onClick()"></wri-bag-count>'
})
export class ConnectedBagCountComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private currentUrlScreenName = '';

  public cartCount: Observable<number> = this.store
    .select(CartFeature.selectCart)
    .pipe(
      map((cart) =>
        cart ? cart.items.reduce((sum, item) => (sum += item.quantity), 0) : 0
      )
    );

  constructor(
    private store: Store,
    private router: Router,
    private currentUrlService: CurrentUrlService,
    private analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.currentUrlService.getScreenName().subscribe((url) => {
        this.currentUrlScreenName = url;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onClick() {
    this.analyticsService.logGaEvent({
      event: 'return_to_cart'
    });
    this.analyticsService.logGaEvent({
      event: 'bag_click',
      screen_name: this.currentUrlScreenName
    });
    this.router.navigate(['/order/my-bag']);
  }
}
