import { Component } from '@angular/core';

@Component({
  selector: 'wri-home-page-wing-calculator',
  templateUrl: './home-page-wing-calculator.component.html',
  styleUrls: ['./home-page-wing-calculator.component.scss']
})
export class HomePageWingCalculatorComponent {

}
