import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { find, map } from 'lodash';
import { Observable, Subscription, filter, shareReplay } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { Cart } from '../../../../ecomm/types/cart.types';
import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';
import {
  MenuCategory,
  MenuItem,
  MenuProduct,
  MenuUpsellItem,
  MenuUpsells
} from '../../../../ecomm/types/store-info.types';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';

@Component({
  selector: 'wri-upsell-items',
  templateUrl: './upsell-items.component.html',
  styleUrls: ['./upsell-items.component.scss']
})
export class UpsellItemsComponent implements OnInit {
  @Input() upsells: MenuUpsells | undefined;
  @Input() categorySlug!: string;
  @Input() cart: Cart | null = null;

  public storeInfoState: StoreInfoFeatureState | null = null;

  private subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store,
    private asyncDispatcher: AsynchronousDispatcher,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    const storeInfoState$: Observable<StoreInfoFeatureState> = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean), shareReplay(1));
    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  public prependPlus(price: number) {
    return price > 0 ? '+' : '';
  }

  returnCalorieRange(item: MenuUpsellItem): string | number {
    return getCalorieRange(item);
  }

  private logGAEvent(item: MenuUpsellItem, categorySlug: string) {
    try {
      this.analyticsService.logGaEvent({
        event: 'upsell_view',
        currency: 'USD',
        item_id: item.id || '',
        item_name: item.name || '',
        item_category: categorySlug || '',
        order_method: (this.cart?.handoffMode as HandoffMode) || 'carryout',
        store_name: this.storeInfoState?.storeInfo?.storeDetails.name || ''
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  getProductUrl(item: MenuUpsellItem) {
    this.asyncDispatcher.dispatch(
      StoreInfoFeature.actions.setState({
        selectedItem: item as unknown as MenuItem
      })
    );

    const { selectedCategory, selectedProduct } = this.getSelectedCategory(
      item.id
    );
    const categorySlug = selectedCategory[0]?.slug;
    const productSlug = selectedProduct[0]?.slug;

    // log GA event
    this.logGAEvent(item, categorySlug);

    if (productSlug && categorySlug !== 'specials') {
      const url = `/location/${this.storeInfoState?.storeInfo?.storeDetails.slug}/menu/${categorySlug}/${productSlug}/${item?.slug}`;
      return this.router.navigate([url]);
    }

    const url = `/location/${this.storeInfoState?.storeInfo?.storeDetails.slug}/menu/${categorySlug}/${item?.slug}`;
    return this.router.navigate([url]);
  }

  private getSelectedCategory(selectedItemId: string) {
    const upsellsFromMenu: MenuCategory[] = [];
    const productItem: MenuProduct[] = [];
    map(this.storeInfoState?.storeInfo?.categories, (eachCategory) => {
      map(eachCategory?.products, (eachProduct) => {
        const { item, itemGroup } = eachProduct;
        if (item) {
          if (item?.id === selectedItemId) {
            upsellsFromMenu.push(eachCategory);
          }
        }

        if (itemGroup) {
          const itemGrpItem = find(itemGroup?.items, ['id', selectedItemId]);
          if (itemGrpItem) {
            upsellsFromMenu.push(eachCategory);
            productItem.push(eachProduct);
          }
        }
      });
    });
    return { selectedCategory: upsellsFromMenu, selectedProduct: productItem };
  }
}
