import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EmailDomainValidationRepository } from '../../repositories/email-domain-validation/email-domain-validation.repository';
import * as MaybeResponse from '../../types/maybe-response';
import { EmailDomainValidation } from '../../types/email-domain-validation.types';

@Injectable({ providedIn: 'root' })
export class EmailDomainValidationService {
	constructor(private emailDomainValidationRepository: EmailDomainValidationRepository) { }

	getEmailDomainValidations(): Observable<
		MaybeResponse.MaybeResponse<EmailDomainValidation>
	> {
		return this.emailDomainValidationRepository.getEmailDomainValidations().pipe(
			map(
				MaybeResponse.mapData((data) => {
					if (!data) return undefined;
					return data;
				})
			)
		);
	}
}