import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
export class ActivatedRouteSnapshotResolver
  implements Resolve<ActivatedRouteSnapshot>
{
  resolve(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return route;
  }
}

export const ACTIVATED_ROUTE_SNAPSHOT = 'activatedRouteSnapshot';
