import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'oidc-client-ts';

import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { SignupFormData } from '../sign-up-form/sign-up-form.component';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { PasswordRequirements } from '../../../../ecomm/types/password-requirements.types';
import { PasswordRequirementsWorkflowService } from '../../../../ecomm/workflows/password-requirements/password-requirements-workflow.service';
import { Store } from '@ngrx/store';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { PartialOutageModalComponent } from '../../../common';

@Component({
  selector: 'wri-connected-signup',
  templateUrl: './connected-signup.component.html',
  styleUrls: ['./connected-signup.component.scss']
})
export class ConnectedSignupComponent implements OnInit, OnDestroy {
  fallBackPasswordRequirement = [
    {
      id: 'minLength',
      description: '8 characters',
      allowed: '',
      count: 8
    }
  ];
  public passwordRequirements$ = new BehaviorSubject<PasswordRequirements[]>(
    []
  );
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private authService: AuthService,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private redirectService: RedirectService,
    private router: Router,
    private userAccountWorkflowService: CustomerWorkflowService,
    private analyticsService: AnalyticsService,
    private passwordRequirementsService: PasswordRequirementsWorkflowService,
    private store: Store
  ) {}

  isLoading = true;

  async ngOnInit() {
    const passwordRequirements =
      await this.passwordRequirementsService.getPasswordRequirements();

    this.passwordRequirements$.next(
      passwordRequirements ?? this.fallBackPasswordRequirement
    );

    this.isLoading = false;
    this.subscribeToRegionalConfigState();
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  navigateBasedOnFeatureFlag(configState: RegionalConfigurationFeatureState) {
    //redirect to legacy
    if (
      configState?.regionalConfigurationOptions[
        'feature_enable_account_register'
      ]?.value === 'false'
    ) {
      const encodeUrlPath = encodeURIComponent(this.router.url);
      this.redirectService.redirectToLegacy(
        `account/signup?ngfe_auth&ngfe_url=${encodeUrlPath}`
      );
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
        this.navigateBasedOnFeatureFlag(state)
      })
    );
  }

  onSocialSignupClick(type: string) {
    this.authService.setUserManager(type);
  }

  onLogin() {
    this.authService.login(this.router.url);
  }

  onPrivacyPolicy() {
    this.router.navigate(['/privacy']);
  }
  onTermsAndConditions() {
    this.router.navigate(['/terms-of-use']);
  }

  async onSignUpFormSubmit(formData: SignupFormData) {
    this.isLoading = true;

    try {
      const signUpUser = await this.userAccountWorkflowService.signUp(formData);
      if (signUpUser) {
        const profile =
          signUpUser?.accessToken &&
          JSON.parse(
            decodeURIComponent(atob(signUpUser.accessToken.split('.')[1]))
          );

        const user = new User({
          id_token: signUpUser.idToken,
          access_token: signUpUser.accessToken,
          refresh_token: signUpUser.refreshToken,
          profile: profile,
          token_type: 'Bearer'
        });

        await this.authService.userManager.storeUser(user).then(async () => {
          await this.authService.saveAuthState(this.router.url);
          window.location.reload();
        });
        this.analyticsService.logGaEvent({
          event: 'sign_up',
          method: 'email'
        });
      }
    } catch (error) {
      console.log('Error', error);
      this.isLoading = false;
    }
    this.isLoading = false;
  }
}
