import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rx from 'rxjs/operators';

import { StoreInfoService } from '../../../../ecomm/services/store-info/store-info.service';
import { MaybeResponse } from '../../../../ecomm/types/maybe-response';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { Store } from '@ngrx/store';
import { RegionalConfigurationFeature } from '../../../../ecomm/store/features/regional-configuration';

@Injectable({
  providedIn: 'root'
})
export class WingCalculatorService {
  private static readonly INIT_ERROR =
    'We ran into an issue loading the Wing Calculator. Please feel free to try again.';
  private static readonly SERVICE_MODE = 'carryout';
  private static readonly GLOBAL_MENU_SLUG_PROP = 'global_menu_slug';

  constructor(
    private storeInfoService: StoreInfoService,
    private store: Store
  ) { }

  private globalMenuFromRegionalConfig = () => {
    return this.store.select(RegionalConfigurationFeature.selectRegionalConfigurationState).pipe((
      rx.switchMap((regionalState) => {
        const options = regionalState?.regionalConfigurationOptions;
        const globalMenuSlug =
          options?.[WingCalculatorService.GLOBAL_MENU_SLUG_PROP]?.value;
        return globalMenuSlug
          ? this.storeInfoService.getStoreInfoBySlug(
            globalMenuSlug,
            WingCalculatorService.SERVICE_MODE
          )
          : of({ data: null, error: WingCalculatorService.INIT_ERROR });
      })
    ))
  }

  init = (): Observable<MaybeResponse<StoreInfo>> => this.globalMenuFromRegionalConfig()
}
