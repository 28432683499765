<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>

<div class="choose-location-wpr">
  <h3 class="choose-location-title">Wingstop Career Opportunities</h3>

  <div class="search-input-wpr job-search">
    <div
      [attr.data-testid]="'text-input-for-location-search-input'"
      class="wri-text-input"
    >
      <div class="label-and-map-list-toggle">
        <label
          [attr.for]="'location-search-input'"
          [attr.data-testid]="'label-for-search-input'"
        >Search Zip or City, State
        </label>

        <a
          class="map-view-toggle-mobile"
          (click)="onMobileMapViewToggle()"
        >
          <wri-icon
            class="map-view-icon"
            [icon]="showMobileMap ? 'wri-list-view' : 'wri-map-view'"
            data-testid="map-view-icon"
          ></wri-icon>
          {{ showMobileMap ? 'List View' : 'Map View' }}
        </a>
      </div>


      <div class="input-icons-wpr" id="input-wpr">
        <a
          wriFocusOutline
          tabindex="0"
          (click)="useMyCurrentLocation()"
          *ngIf="useMyLocationIsSupported"
        >
          <wri-icon
            icon="wri-compass"
            class="input-icon"
            data-testid="compass-icon"
          >
          </wri-icon>
        </a>
        <input
          [class.show-search-icon]="!useMyLocationIsSupported"
          [class.show-compass-icon]="useMyLocationIsSupported"
          data-testid="location-search-input"
          [id]="'location-search-input'"
          [attr.autocomplete]="'off'"
          [attr.type]="'text'"
          [attr.aria-label]="'location-search'"
          [ngbTypeahead]="search"
          [resultTemplate]="rt"
          [resultFormatter]="resultsFormatter"
          [inputFormatter]="inputFormatter"
          (click)="click$.next($any($event).target.value)"
          (selectItem)="selectedItem($event)"
          [value]="searchText"
          #instance="ngbTypeahead"
          #inputElem
        />
        <ng-template #rt let-r="result" let-t="term">
          <div [ngStyle]="{ width: getWidthOfInputQuickSearch() + 'px' }">
            {{ r.name }}
          </div>
        </ng-template>
        <wri-spinner
          class="search-input-spinner"
          *ngIf="searching"
        ></wri-spinner>
        <a wriFocusOutline tabindex="0" (click)="resetSearchData()">
          <wri-icon
            *ngIf="searchText?.length > 0"
            icon="wri-cross"
            class="input-icon"
            data-testid="cross-icon"
            ariaLabel="Clear search"
          >
          </wri-icon>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="showMobileMap" class="map-container mobile">
    <wri-map
      [interactive]="true"
      [showPreview]="false"
      [searchEnabled]="mapSearchEnabled"
      [locations]="mapLocations$ | async"
      (search)="onMapSearch($event)"
      (startSearch)="onMapStartSearch($event)"
      (markerSelect)="onMapMarkerSelect($event)"
    ></wri-map>
  </div>

  <div class="map-container desktop">
    <wri-map
      [interactive]="true"
      [showPreview]="showMapPreview"
      [searchEnabled]="mapSearchEnabled"
      [locations]="mapLocations$ | async"
      (search)="onMapSearch($event)"
      (startSearch)="onMapStartSearch($event)"
      (markerSelect)="onMapMarkerSelect($event)"
    ></wri-map>
  </div>

  <section class="location-list-wrapper">
    <div class="card-btn-wrapper" *ngIf="showUseMyLocation()">
      <button
        class="wri-btn wri-btn-primary"
        aria-label="USE MY CURRENT LOCATION"
        (click)="useMyCurrentLocation()"
      >
        USE MY CURRENT LOCATION
      </button>
    </div>
    <wri-job-location-search-list
      [locations]="locationsList?.locations"
      [selectedLocationMarkerId]="selectedMarker"
      [searching]="locationSearching"
      (navigate)="onLocationNavigate($event)"
    ></wri-job-location-search-list>
  </section>
</div>

<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title"
      >Location data has been turned off</span
      >
      <span class="wri-modal__description"
      >Your location permissions were recently denied. To re-enable your
        location data, please adjust your settings in the browser.</span
      >
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-primary"
          aria-label="Dismiss"
          (click)="closeModal()"
        >
          Dismiss
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
<wri-partial-outage-modal></wri-partial-outage-modal>
<ng-template
  #confirmationPopupModal
  let-close="close"
  let-dismiss="dismiss"
>
  <wri-common-modal [close]="close" [dismiss]="dismiss" [closeIcon]="false">
    <div class="confirm-popup-modal text-center">
      <div class="wri-modal__wrapper">
        <div class="wri-modal__title">Confirm</div>
        <div
          data-testid="confirm-popup-modal-description"
          class="wri-modal__description"
        >
          <p>
            You are about to be redirected to another site that is not
            Wingstop.com, and may be subject to separate terms, conditions and
            privacy policies. You can press “Okay” to proceed, or “Cancel” to
            stay here.
          </p>
        </div>
        <div class="wri-modal__btn-wrapper">
          <button
            data-testid="confirm-popup-modal-ok"
            class="wri-btn wri-btn-primary"
            aria-label="Okay"
            (click)="navigateToNewTab($event);"
          >
            Okay
          </button>
          <button
            data-testid="confirm-popup-modal-cancel"
            class="wri-btn wri-btn-tertiary"
            aria-label="Cancel"
            (click)="closeModal()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
