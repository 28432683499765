import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ECOMM_API_CONFIG,
  EcommAPIConfig
} from '../../config/ecomm-config.provider';
import {
  FulfillmentTimesDto,
  FulfillmentTimesResponse
} from '../../types/fulfillment-times.types';

import {
  OutOfStockItemsDto,
  OutOfStockItemsResponse
} from '../../types/out-of-stock-items.types';
import {
  MenuItemDto,
  MenuItemResponse,
  StoreInfoDto,
  StoreInfoResponse
} from '../../types/store-info.types';

import { handleAPIResponse } from '../../utils/handle-api-response';
import {
  OfferDetailsDto,
  OfferDetailsResponse
} from '../../types/offer-details.types';
import { MaybeResponse } from '../../types/maybe-response';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class StoreInfoRepository {
  constructor(
    private http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService
  ) {}

  public getStoreInfoBySlug(
    slug: string,
    serviceMode: string
  ): Observable<MaybeResponse<StoreInfoDto>> {
    const params = {
      slug,
      serviceMode
    };
    const url = `${this.config.baseUrl}/menu-worker`;
    return this.http
      .get<StoreInfoResponse>(url, {
        params
      })
      .pipe(
        handleAPIResponse(
          StoreInfoResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getStoreInfoById(
    locationId: string,
    serviceMode: string
  ): Observable<MaybeResponse<StoreInfoDto>> {
    const params = {
      locationId,
      serviceMode
    };
    const url = `${this.config.baseUrl}/menu-worker`;
    return this.http
      .get<StoreInfoResponse>(url, {
        params
      })
      .pipe(
        handleAPIResponse(
          StoreInfoResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getMenuItemInfo(
    itemId: string,
    locationId: string,
    serviceMode: string
  ): Observable<MaybeResponse<MenuItemDto>> {
    const params = {
      itemId,
      locationId,
      serviceMode
    };
    const url = `${this.config.baseUrl}/menu-worker`;
    return this.http
      .get<MenuItemResponse>(url, {
        params
      })
      .pipe(
        handleAPIResponse(
          MenuItemResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getOutOfStockitems(
    locationId: string
  ): Observable<MaybeResponse<OutOfStockItemsDto>> {
    const url = `${this.config.baseUrl}/api/locations/${locationId}/unavailable-items`;
    return this.http
      .get<OutOfStockItemsResponse>(url)
      .pipe(
        handleAPIResponse(
          OutOfStockItemsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getFulfillmentTimes(
    locationId: string,
    handoffMode: string
  ): Observable<MaybeResponse<FulfillmentTimesDto>> {
    const url = `${this.config.baseUrl}/api/locations/${locationId}/fulfillmenttimes`;
    const params = { handoffMode };
    return this.http
      .get<FulfillmentTimesResponse>(url, { params })
      .pipe(
        handleAPIResponse(
          FulfillmentTimesResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getOfferDetails(
    offerCode: string,
    storeId: string,
    recaptchaToken: string
  ): Observable<MaybeResponse<OfferDetailsDto>> {
    const headers = {
      recaptchatoken: recaptchaToken
    };
    const url = `${this.config.baseUrl}/api/offers/${offerCode}/store/${storeId}`;
    return this.http
      .get<OfferDetailsResponse>(url, { headers })
      .pipe(
        handleAPIResponse(
          OfferDetailsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs,
          'The offer is no longer valid or has expired.'
        )
      );
  }

  public getOfferDetailsByTimezone(
    offerCode: string,
    recaptchaToken: string,
    timeZone?: string
  ): Observable<MaybeResponse<OfferDetailsDto>> {
    const headers = {
      recaptchatoken: recaptchaToken
    };
    const hasTimeZoneUrl = timeZone ? `?timezone=${timeZone}` : '';
    const url = `${this.config.baseUrl}/api/offers/${offerCode}${hasTimeZoneUrl}`;

    return this.http
      .get<OfferDetailsResponse>(url, { headers })
      .pipe(
        handleAPIResponse(
          OfferDetailsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs,
          'The offer is no longer valid or has expired.'
        )
      );
  }
}
