import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';

import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { MenuItemWithSlug } from '../../../common';

@Component({
  selector: 'wri-cart-additional-recommendation',
  templateUrl: './cart-additional-recommendation.component.html',
  styleUrls: ['./cart-additional-recommendation.component.scss']
})
export class CartAdditionalRecommendationComponent implements OnInit {
  @Input() sliderData: MenuItemWithSlug[] | undefined;
  @Input() handoffMode: string | undefined;
  loading = true;
  private subscription = new Subscription();
  public storeInfoState: StoreInfoFeatureState | null = null;

  public slideConfig = {
    slidesToScroll: 1,
    variableWidth: false,
    infinite: false,
    slidesToShow: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 889,
        settings: {
          slidesToShow: 3,
          arrows: true
        }
      }
    ]
  };

  constructor(private store: Store) {}

  ngOnInit() {
    const stateInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      stateInfo$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  trackByItem(index: number, data: MenuItemWithSlug) {
    return `${index}-${data?.item?.id}`;
  }

  handleInit() {
    this.loading = false;
  }
}
