import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';

import {
  CustomerFeature,
  CustomerFeatureState
} from '../../../../ecomm/store/features/customer';
import { Order } from '../../../../ecomm/types/order.types';
import { LocationResponseData } from '../../../../ecomm/types/search-location.types';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { OrderScheduledTimePipe, PartialOutageModalComponent } from '../../../common';
import moment from 'moment-timezone';
import { RegionalConfigurationFeature, RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';

@Component({
  selector: 'wri-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @Input() isPastOrder = false;
  @Output() cancelOrderEmit = new EventEmitter<string>();
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  public customerState$ = this.store
    .select(CustomerFeature.selectCustomerState)
    .pipe(filter<CustomerFeatureState>(Boolean));
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.subscribeToRegionalConfigState();
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  private _order:
    | (Order & {
        locationDetails: LocationResponseData | null;
      })
    | null = null;

  get order():
    | (Order & {
        locationDetails: LocationResponseData | null;
      })
    | null {
    return this._order;
  }

  @Input()
  set order(
    _val:
      | (Order & {
          locationDetails: LocationResponseData | null;
        })
      | null
  ) {
    this._order = _val;
  }

  get isCancelOrderFeatureFlagEnabled() {
    return this.featureFlagService.featureFlags.enableCancelOrder;
  }

  getOrderScheduledTime(scheduledDate: string, timeZone: string): string {
    return new OrderScheduledTimePipe().transform(scheduledDate, timeZone);
  }

  handleCancelOrder() {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  cancelOrder() {
    this.modalService.dismissAll();
    this.cancelOrderEmit.emit(this.order?.id);
  }

  isOrderCancellable(): boolean {
    if (this.order?.cancelDeadline) {
      const currentTime = moment();
      const cancelDeadlineTime = moment(this.order.cancelDeadline);
      if (cancelDeadlineTime.diff(currentTime) > 0) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
