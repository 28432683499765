<div
  *ngIf="item"
  wriInteractionStudio="data-chainoptionid"
  [interactionStudioId]="item.id"
  [ngClass]="
    layout === 'condensed'
      ? 'wri-product-item-container-condensed'
      : 'wri-product-item-container'
  "
>
  <a
    wriFocusOutline
    class="wri-product-item-img-container"
    data-testid="wri-product-item-img-container"
    tabindex="0"
    (click)="getProductItemDetailsUrl()"
    [ngClass]="{ 'wri-product-item-disable-anchor': item?.outOfStock }"
  >
    <div class="wri-product-img">
      <img
        height="100%"
        srcset="{{ item.images[0]?.uri | imageResolution: 'srcset':layout === 'condensed'?'condensed-menu': 'standard-menu': '/assets/images/logo-green-product.png'}}"
        src="{{ item.images[0]?.uri | imageResolution: 'srcset':layout === 'condensed'?'condensed-menu': 'standard-menu': '/assets/images/logo-green-product.png'}}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        alt="{{
          item.images.length > 0 ? item.shortDescription || item.description : 'placeholder-image'
        }}"
      />
    </div>
  </a>

  <a
    wriFocusOutline
    class="wri-product-item-name"
    data-testid="wri-product-item-name"
    tabindex="0"
    (click)="getProductItemDetailsUrl()"
    [ngClass]="{
      'wri-product-item-disable-anchor': item?.outOfStock,
      'wri-product-item-disable-item': item?.outOfStock,
     }"
    aria-live="polite"
  >
    <span class="item-title">{{ item.name }}</span>
    <span
      class="wri-product-item-name-price"
      data-testid="wri-product-item-price"
      [ngClass]="{
        'wri-product-item-disable-item-price': item?.outOfStock,
      }"
    ><span data-testid="wri-product-item-price1" *ngIf="!isGlobalMenu && hasPrice(item.price)">
        {{ item.price | currency }}
      </span>
    </span>
    <wri-icon
      *ngIf="!item?.outOfStock"
      icon="wri-chevron-right"
      class="icon-chevron"
    ></wri-icon>
  </a>

  <div data-testid="wri-product-item-description">
    <span class="wri-product-item-description">{{ item.shortDescription || item.description }}</span>
    <ng-container *ngIf="item?.outOfStock; else metadata">
      <wri-unavailable-item></wri-unavailable-item>
    </ng-container>

    <ng-template #metadata>
      <ng-container
        #metadata
        *ngIf="!(item.minCalories === null && item.maxCalories === null)"
      >
        <span class="wri-product-item-calories">
          {{ returnCalorieRange(item) }}
        </span>
        <span
          data-testid="high-sodium"
          class="high-sodium"
          *ngIf="item?.highSodium && !highSodiumDisplay?.label"
        >
          <ng-container *ngTemplateOutlet="highSodiumDisplayTemplate"></ng-container>
        </span>

        <span
          wriFocusOutline
          data-testid="high-sodium-with-label"
          class="high-sodium-with-label"
          aria-live="polite"
        >
          <ng-container *ngIf="item?.highSodium && highSodiumDisplay?.label then highSodiumDisplayTemplate"></ng-container>
        </span>

      </ng-container>
    </ng-template>
  </div>
</div>
<ng-template #highSodiumDisplayTemplate>
  <img
    data-testid="high-sodium-display-img"
    class="high-sodium-display-img"
    aria-live="polite"
    alt="{{ highSodiumDisplay?.altText || 'Sodium warning icon' }}"
    src="{{ highSodiumDisplay?.imageUrl || 'https://cdn.bfldr.com/NDQASMJ1/as/cvqcrpvtk5b3ptmzp99p8knz/wri-sodium?auto=webp&format=png' }}"
    onerror="this.onerror=null;this.src='https://cdn.bfldr.com/NDQASMJ1/as/cvqcrpvtk5b3ptmzp99p8knz/wri-sodium?auto=webp&format=png';"
    role="img"
  />
  <span *ngIf="highSodiumDisplay?.label"> {{ highSodiumDisplay?.label }} </span>
</ng-template>
