import { EMPTY, Observable, concatMap, tap } from 'rxjs';

export type BufferWhileBusyFn<TIN, TOUT> = (input: TIN) => Observable<TOUT>;
export const bufferWhileBusy =
  <TIN, TOUT>(fn: BufferWhileBusyFn<TIN, TOUT>) =>
  (input$: Observable<TIN>) => {
    return new Observable((subscriber) => {
      let processing = false;
      let buffer: TIN[] = [];

      const subscription = input$
        .pipe(
          tap((n) => buffer.push(n)),
          concatMap(() => {
            if (processing || buffer.length === 0) {
              return EMPTY;
            }

            const lastItem = buffer[buffer.length - 1];
            processing = true;
            buffer = [];
            return fn(lastItem).pipe(
              tap({
                complete: () => {
                  processing = false;
                }
              })
            );
          })
        )
        .subscribe({
          next(value) {
            subscriber.next(value);
          },
          error(err) {
            subscriber.error(err);
          },
          complete() {
            subscriber.complete();
          }
        });

      return () => {
        subscription.unsubscribe();
      };
    });
  };
