import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'showInputError'
})
export class ShowInputErrorPipe implements PipeTransform {
  transform(
    errors: ValidationErrors | null = null,
    errorKey: string | undefined
  ): boolean {
    return errorKey ? !!errors?.[errorKey] : !!errors;
  }
}
