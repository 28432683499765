import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import {
  LEGACY_ANALYTICS_SERVICE,
  ILegacyAnalyticsService
} from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  MenuItem,
  MenuModifier,
  MenuModifierGroup,
  MenuModifierGroupItem
} from '../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { areModifierGroupsValid } from '../../../../ecomm/utils/pdp-utils';
import {
  FlavorSpiceHeading,
  FlavorSpiceHeadingColors,
  ModGroups,
  ModifierModalElementChangeService
} from '../../../common';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'wri-modifier-modal',
  templateUrl: './modifier-modal.component.html',
  styleUrls: ['./modifier-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModifierModalComponent implements OnInit {
  @Input() data: MenuModifierGroup[] | undefined;
  @Input() limited: MenuModifier[] = [];
  @Input() hot: MenuModifier[] = [];
  @Input() medium: MenuModifier[] = [];
  @Input() mild: MenuModifier[] = [];
  @Input() editFlavor: MenuModifier | undefined;
  @Input() selectedItemModifierGroups: ModGroups[] = [];
  // to determine if options to be selected from default values or selected values
  @Input() hasSelectedModifierElementsChanged = false;
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer!: ToastContainerDirective;
  itemModifierGroups: ModGroups[] = [];
  appliedItemModifierGroups: ModGroups[] = [];

  readonly flavorSpiceHeading = FlavorSpiceHeading;
  readonly flavorSpiceHeadingColors = FlavorSpiceHeadingColors;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public modifierModalElementChangeService: ModifierModalElementChangeService,
    @Inject(LEGACY_ANALYTICS_SERVICE)
    private analyticsService: ILegacyAnalyticsService
  ) {}

  filterModGroupElements(elements: MenuModifierGroupItem[]) {
    return elements
      .filter((e) => e.availableInSchedule)
      .sort((x, y) => {
        return x.sortOrder - y.sortOrder;
      });
  }

  ngOnInit(): void {
    // auto select options based on already selected values
    if (
      this.hasSelectedModifierElementsChanged ||
      this.selectedItemModifierGroups.length > 0
    ) {
      this.selectedItemModifierGroups?.forEach((selectedItemModifierGroup) => {
        const selectedItemModifierGroupObj = this.data?.find(
          (modifierGroup) =>
            modifierGroup.id === selectedItemModifierGroup.modifierGroupId
        );
        selectedItemModifierGroupObj &&
          selectedItemModifierGroup.modifiers?.forEach((selectedModifier) => {
            const selectedModifierObj =
              selectedItemModifierGroupObj.modifierGroupElements.find(
                (modifierGroupElement) =>
                  modifierGroupElement?.modifier?.id ===
                    selectedModifier.modifierId ||
                  modifierGroupElement?.item?.id === selectedModifier.modifierId
              );
            if (selectedModifierObj?.item) {
              this.modifierModalElementChangeService.onChangeModalModifierElement(
                this.itemModifierGroups,
                true,
                selectedItemModifierGroupObj.maxSelectionsAllowed > 1
                  ? 'multiple'
                  : 'single',
                selectedItemModifierGroupObj.id,
                selectedModifierObj.item
              );
            } else {
              selectedModifierObj &&
                selectedModifierObj.modifier &&
                this.modifierModalElementChangeService.onChangeModalModifierElement(
                  this.itemModifierGroups,
                  true,
                  selectedItemModifierGroupObj.maxSelectionsAllowed > 1
                    ? 'multiple'
                    : 'single',
                  selectedItemModifierGroupObj.id,
                  selectedModifierObj.modifier
                );
            }

            if (selectedItemModifierGroupObj.type === 'flavor') {
              this.editFlavor = selectedModifierObj?.modifier || undefined;
            }
          });
      });
    }
    // auto select options based on isDefault value
    else if (this.data) {
      for (const modifierGroup of this.data) {
        for (const modifierGroupElement of modifierGroup.modifierGroupElements) {
          if (
            modifierGroupElement.modifier &&
            modifierGroupElement.modifier?.isDefault &&
            !modifierGroupElement.modifier?.outOfStock &&
            modifierGroupElement.modifier.availableInSchedule
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.itemModifierGroups,
              true,
              modifierGroup.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
              modifierGroup.id,
              modifierGroupElement.modifier
            );
          } else if (
            modifierGroupElement.item &&
            modifierGroupElement.item?.isDefault &&
            !modifierGroupElement.item?.outOfStock &&
            modifierGroupElement.item.availableInSchedule
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.itemModifierGroups,
              true,
              modifierGroup.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
              modifierGroup.id,
              modifierGroupElement.item
            );
          }
        }
      }
    }
    this.appliedItemModifierGroups = cloneDeep(this.itemModifierGroups);
  }

  checkIfItemsSelected(
    itemModifierGroups: ModGroups[],
    modifierGroup: MenuModifierGroup
  ) {
    return (
      itemModifierGroups.findIndex(
        (itemModifierGroup) =>
          itemModifierGroup.modifierGroupId === modifierGroup.id
      ) > -1
    );
  }

  isItemDisabled(
    modifierGroupId: string,
    modifierItemId: string,
    maxSelectionsAllowed: number
  ): boolean {
    if (
      this.itemModifierGroups.some((a) => a.modifierGroupId === modifierGroupId)
    ) {
      const index = this.itemModifierGroups.findIndex(
        (a) => a.modifierGroupId === modifierGroupId
      );
      if (
        this.itemModifierGroups[index].modifiers?.length ===
          maxSelectionsAllowed &&
        !this.itemModifierGroups[index].modifiers?.some(
          (modifier) => modifier.modifierId === modifierItemId
        )
      ) {
        return true;
      }
    }
    return false;
  }

  changeModifierEvent(
    itemModGroups: ModGroups[],
    checked: boolean,
    type: string,
    modifierGroupId: string,
    modifierData: MenuModifier | MenuItem
  ) {
    this.modifierModalElementChangeService.onChangeModalModifierElement(
      itemModGroups,
      checked,
      type,
      modifierGroupId,
      modifierData
    );
    // Log GA event
    if (checked) {
      this.analyticsService.logGaEvent({
        event: 'choose_options',
        item_name: modifierData.name,
        count: 1
      });
    }
  }

  isItemChecked(modifierGroupId: string, modifierItemId: string): boolean {
    if (
      this.itemModifierGroups.some((a) => a.modifierGroupId === modifierGroupId)
    ) {
      const index = this.itemModifierGroups.findIndex(
        (a) => a.modifierGroupId === modifierGroupId
      );
      if (
        this.itemModifierGroups[index].modifiers?.some(
          (a) => a.modifierId === modifierItemId
        )
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  areSelectionsValid(modifierGroup: MenuModifierGroup): boolean {
    if (this.itemModifierGroups.length > 0) {
      const modGroupIndex = this.itemModifierGroups.findIndex(
        (modGroup) => modGroup.modifierGroupId === modifierGroup.id
      );
      if (modGroupIndex > -1) {
        if (
          this.itemModifierGroups[modGroupIndex].modifiers &&
          this.itemModifierGroups[modGroupIndex]?.modifiers.length >=
            modifierGroup.minSelectionsRequired &&
          modifierGroup.type == 'flavor'
        ) {
          return true;
        } else if (
          this.itemModifierGroups[modGroupIndex].modifiers &&
          this.itemModifierGroups[modGroupIndex]?.modifiers.length >=
            modifierGroup.minModifierQuantity &&
          modifierGroup.type != 'flavor'
        ) {
          return true;
        }
      }
    }
    return false;
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  closeModal() {
    this.toastr.overlayContainer = undefined;
    this.activeModal.close({
      itemModifierGroups: this.appliedItemModifierGroups,
      isValid: areModifierGroupsValid(this.data, this.appliedItemModifierGroups),
      isApplied: false
    });
  }

  applyModifierSelections() {
    if (areModifierGroupsValid(this.data, this.itemModifierGroups)) {
      this.appliedItemModifierGroups = cloneDeep(this.itemModifierGroups);
      this.toastr.overlayContainer = undefined;
      this.activeModal.close({
        itemModifierGroups: this.appliedItemModifierGroups,
        isValid: true,
        isApplied: true
      });
    } else {
      this.toastr.overlayContainer = this.toastContainer;
      this.toastr.error(
        'Additional selections are needed. Please complete all required selections to customize the item.',
        '',
        {
          positionClass: 'inline'
        }
      );
    }
  }

  handleSelectedValues(
    values: {
      selectedAddOnFlavor: MenuModifier;
      editFlavor: MenuModifier | undefined;
    },
    modifierGroupId: string
  ) {
    this.changeModifierEvent(
      this.itemModifierGroups,
      true,
      'single',
      modifierGroupId,
      values.selectedAddOnFlavor
    );
    this.isItemChecked(modifierGroupId, values.selectedAddOnFlavor.id);
    this.editFlavor = values.editFlavor;
  }
}