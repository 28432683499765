import { Router } from '@angular/router';

export type BreadCrumbDisplayType = 'icon' | 'label';

export type BreadCrumbType = {
  display: string;
  displayType: BreadCrumbDisplayType;
  displaySeparator: boolean;
  onClick: () => void;
};

export type CreateBreadCrumbOptions = {
  router: Router;
  url: string;
  fragment?: string;
  displaySeparator?: boolean;
  display: string;
  displayType?: BreadCrumbDisplayType;
};

export const createBreadCrumbClickHandler =
  (router: Router, url: string, fragment?: string) => () => {
    router.navigate([url], { fragment });
  };
