<div class="recaptcha-branding">
  This site is protected by reCAPTCHA and the Google
  <a target="_blank" href="https://policies.google.com/privacy"
    >Privacy Policy</a
  >
  and
  <a target="_blank" href="https://policies.google.com/terms"
    >Terms of Service</a
  >
  apply.
</div>
