
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as MaybeResponse from '../../types/maybe-response';
import { handleResourceResponse } from '../../utils/handle-resource-response';
import { WINDOW } from '../../providers/window/window.provider';
import { RoundupCharityResponse } from '../../types/roundup-charity.types';

@Injectable({ providedIn: 'root' })
export class RoundupCharityRepository {
  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window
  ) {}

  getRoundupCharityData(): Observable<
    MaybeResponse.MaybeResponse<RoundupCharityResponse>
  > {
    const url = `${this.window.location.origin}/resources/roundup-charity.json`;
    return this.http
      .get<RoundupCharityResponse>(url)
      .pipe(handleResourceResponse(RoundupCharityResponse));
  }
}
