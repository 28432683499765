import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { PaymentRepository } from '../../repositories/payment/payment.repository';
import * as MaybeResponse from '../../types/maybe-response';
import {
  ApplePayMerchantSession,
  CustomerVaultedCards,
  GiftCardDetails,
  GiftCardDetailsRequest,
  PaymentSessionDetails,
  PaymentValidateApplePayMerchantRequest
} from '../../types/payment.types';
import { fromDto } from '../../utils/from-dto';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private paymentRepository: PaymentRepository) {}

  public getPaymentSessionDetails(customErrorMessage: boolean): Observable<
    MaybeResponse.MaybeResponse<PaymentSessionDetails>
  > {
    return this.paymentRepository
      .getPaymentSessionDetails(customErrorMessage)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getGiftCardDetails(
    request: GiftCardDetailsRequest,
    recaptchaToken: string
  ): Observable<MaybeResponse.MaybeResponse<GiftCardDetails>> {
    return this.paymentRepository
      .getGiftCardDetails(request, recaptchaToken)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public validateMerchantForApplePay(
    request: PaymentValidateApplePayMerchantRequest
  ): Observable<MaybeResponse.MaybeResponse<ApplePayMerchantSession>> {
    return this.paymentRepository
      .validateMerchantForApplePay(request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getVaultedCards(): Observable<
    MaybeResponse.MaybeResponse<CustomerVaultedCards>
  > {
    return this.paymentRepository
      .getVaultedCards()
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public deleteVaultedCard(
    vaultedAccountId: string,
    deleteCreditCard: boolean
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    return this.paymentRepository.deleteVaultedCard(
      vaultedAccountId,
      deleteCreditCard
    );
  }
}
