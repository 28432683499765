import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, CONFIG } from '../../providers/config/config.provider';
import { WINDOW } from '../../providers/window/window.provider';

@Injectable()
export class RedirectService {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(CONFIG) private config: Config,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public redirectToLegacy(val?: string): void {
    console.log('Redirecting to legacy');
    const relativePath = this.route.snapshot.url.map((p) => p.path).join('/');
    this.window.location.href = `${this.config.legacy.webAppBaseUrl}/${
      val ?? relativePath
    }`;
  }

  public redirectToLegacyStoreMenuUrl(
    val?: string,
    isEatStoreMenuUrlEnabled = true
  ): void {
    const relativePath = this.route.snapshot.url.map((p) => p.path).join('/');
    const url = isEatStoreMenuUrlEnabled
      ? this.config.legacy.webAppStoreMenuBaseUrl
      : this.config.legacy.webAppBaseUrl;
    this.window.location.href = `${url}/${val ?? relativePath}`;
  }

  public redirectToHome() {
    this.router.navigateByUrl('');
  }

  public redirectToOrder(handoffMode: string) {
    this.router.navigate(['order'], {
      queryParams: {
        handoffMode: handoffMode
      }
    });
  }

  public async redirectToMyAccount() {
    this.router.navigate(['/account/settings']);
  }
}
