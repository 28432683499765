import { Component, Input } from '@angular/core';

@Component({
  selector: 'wri-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent {
  _rubType = 'wet';
  isDry = false;

  @Input() separator = '|';
  @Input() isNew?: boolean;
  @Input() isPopular?: boolean;
  @Input()
  set rubType(value: string) {
    this.isDry = value === 'dry';
    this._rubType = value;
  }
  get rubType() {
    return this._rubType;
  }
}
