import moment from 'moment-timezone';

import { MenuAvailabilityScheduleDto } from '../types/store-info.types';

export function ifAvailableInSchedule(
  alwaysAvailable: boolean | undefined,
  availabilitySchedule: MenuAvailabilityScheduleDto[] | undefined,
  timeZone: string
): boolean {
  if (alwaysAvailable === undefined && availabilitySchedule === undefined) {
    return false;
  }
  return alwaysAvailable
    ? true
    : IsAvailableInSchedule(availabilitySchedule, timeZone);
}

function IsAvailableInSchedule(
  availabilitySchedule: MenuAvailabilityScheduleDto[] | undefined,
  timeZone: string
) {
  const today = moment(moment().tz(timeZone));
  const dayOfWeek = today.format('dddd');
  return availabilitySchedule
    ? availabilitySchedule.filter(
        (as) =>
          (as.startDay === dayOfWeek || as.endDay === dayOfWeek) &&
          validateDate(
            as.startTime,
            as.startDay,
            as.endTime,
            as.endDay,
            timeZone
          )
      ).length > 0
    : false;
}

function validateDate(
  startTime: string,
  startDay: string,
  endTime: string,
  endDay: string,
  timezone: string
) {
  const current = moment().tz(timezone);

  const startDate = moment().tz(timezone).weekday(weekdayNumber(startDay));
  startDate
    .set('hour', Number(startTime.split(':')[0]))
    .set('minute', Number(startTime.split(':')[1]))
    .set('seconds', 0);

  const endDate = moment().tz(timezone).weekday(weekdayNumber(endDay));

  endDate
    .set('hour', Number(endTime.split(':')[0]))
    .set('minute', Number(endTime.split(':')[1]))
    .set('seconds', 0);

  return startDate <= current && endDate > current;
}

function weekdayNumber(day: string) {
  return [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ].findIndex((s) => s === day);
}
