import { Component, Input } from '@angular/core';
import { cond, constant, stubTrue } from 'lodash';

import { HeatIconClasses } from '../../types';

@Component({
  selector: 'wri-heat-scale',
  templateUrl: './heat-scale.component.html',
  styleUrls: ['./heat-scale.component.scss']
})
export class HeatScaleComponent {
  @Input() heatScale = 0;

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  getIconClass(index: number) {
    return cond([
      [(n: number) => n >= this.heatScale, constant(HeatIconClasses.UNFILLED)],
      [() => this.heatScale > 3, constant(HeatIconClasses.HOT)],
      [
        () => this.heatScale === 2 || this.heatScale === 3,
        constant(HeatIconClasses.MEDIUM)
      ],
      [() => this.heatScale < 2, constant(HeatIconClasses.MILD)],
      [stubTrue, constant(HeatIconClasses.UNKNWON)]
    ])(index);
  }
}
