import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { filter, of, Subscription, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { CustomerOrders } from '../../../../ecomm/types/customer.types';
import {
  AuthFeature,
  AuthFeatureState
} from '../../../../ecomm/store/features/auth';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { CurrentUrlService } from '../../../../ecomm/utils/current-url/current-url.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { PartialOutageModalComponent } from '../../../common';

@Component({
  selector: 'wri-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent implements OnInit, OnDestroy {
  isLoading = true;
  orderHistory: CustomerOrders | undefined;
  userLoggedIn = false;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private store: Store,
    private authService: AuthService,
    @Inject(RedirectService)
    private redirectService: RedirectService,
    private currentUrlService: CurrentUrlService,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    private userAccountService: CustomerWorkflowService,
    private storeInfoService: StoreInfoWorkflowService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.authService.signInEvents.subscribe({
        next: (val) => {
          if (val !== true) {
            this.redirectService.redirectToHome();
          }
        }
      })
    );
    this.subscribeToRegionalConfigState();
    this.subscribeToAuthState();
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  startOrder() {
    this.currentUrlService.goToOrderPage();
  }

  private subscribeToAuthState(): void {
    const authState$ = this.store
      .select(AuthFeature.selectAuthState)
      .pipe(filter<AuthFeatureState>(Boolean));
    authState$
      .pipe(
        switchMap((state) => {
          this.userLoggedIn = state.isAuthenticated;
          return state.isAuthenticated
            ? this.userAccountService.getOrderHistory()
            : of(null);
        })
      )
      .subscribe((orderHistory) => {
        this.isLoading = false;
        this.orderHistory = orderHistory ?? undefined;
      });
  }
}
