import * as t from 'io-ts';

import { ecommApiResponse, optional } from './common.types';

/* #region PaymentSessionDetailsHostedPage */
export const PaymentSessionDetailsHostedPageDto = t.intersection(
  [
    t.type(
      {
        href: t.string,
        method: t.string,
        version: t.string
      },
      'PaymentSessionDetailsHostedPageDtoRequired'
    ),
    t.partial(
      {
        rel: optional(t.string)
      },
      'PaymentSessionDetailsHostedPageDtoOptional'
    )
  ],
  'PaymentSessionDetailsHostedPageDto'
);

export type PaymentSessionDetailsHostedPageDto = t.TypeOf<
  typeof PaymentSessionDetailsHostedPageDto
>;
export type PaymentSessionDetailsHostedPage =
  PaymentSessionDetailsHostedPageDto;
/* #endregion */

/* #region PaymentSessionDetailsToken */
export const PaymentSessionDetailsTokenDto = t.intersection(
  [
    t.type(
      {
        tokenId: t.string,
        issuedOn: t.number,
        expiresInSeconds: t.number,
        publicKey: t.string,
        algorithm: t.string,
        status: t.string
      },
      'PaymentSessionDetailsTokenDtoRequired'
    ),
    t.partial({}, 'PaymentSessionDetailsTokenDtoOptional')
  ],
  'PaymentSessionDetailsTokenDto'
);

export type PaymentSessionDetailsTokenDto = t.TypeOf<
  typeof PaymentSessionDetailsTokenDto
>;
export type PaymentSessionDetailsToken = PaymentSessionDetailsTokenDto;

/* #endregion */

/* #region ApplePayMerchantSession */
export const ApplePayMerchantSessionDto = t.intersection(
  [
    t.type(
      {
        displayName: t.string,
        domainName: t.string,
        epochTimestamp: t.number,
        expiresAt: t.number,
        merchantIdentifier: t.string,
        merchantSessionIdentifier: t.string,
        nonce: t.string,
        operationalAnalyticsIdentifier: t.string,
        retries: t.number,
        signature: t.string,
        pspId: t.string
      },
      'ApplePayMerchantSessionDtoRequired'
    ),
    t.partial({}, 'ApplePayMerchantSessionDtoOptional')
  ],
  'ApplePayMerchantSessionDto'
);

export type ApplePayMerchantSessionDto = t.TypeOf<
  typeof ApplePayMerchantSessionDto
>;

export type ApplePayMerchantSession = ApplePayMerchantSessionDto;

export const ApplePayMerchantSessionResponse = ecommApiResponse(
  ApplePayMerchantSessionDto
);

export type ApplePayMerchantSessionResponse = t.TypeOf<
  typeof ApplePayMerchantSessionResponse
>;

/* #endregion */

/* #region PaymentSessionDetails */
export const PaymentSessionDetailsDto = t.intersection(
  [
    t.type(
      {
        hostedPages: t.array(PaymentSessionDetailsHostedPageDto),
        token: PaymentSessionDetailsTokenDto
      },
      'PaymentSessionDetailsDtoRequired'
    ),
    t.partial(
      {
        customerId: optional(t.string),
        fdCustomerId: optional(t.string)
      },
      'PaymentSessionDetailsDtoOptional'
    )
  ],
  'PaymentSessionDetailsDto'
);

export type PaymentSessionDetailsDto = t.TypeOf<
  typeof PaymentSessionDetailsDto
>;
export type PaymentSessionDetails = Omit<
  PaymentSessionDetailsDto,
  'hostedPages' | 'token'
> & {
  hostedPages: PaymentSessionDetailsHostedPage[];
  token: PaymentSessionDetailsToken;
};
/* #endregion */

/* #region PaymentSessionDetailsResponse */
export const PaymentSessionDetailsResponse = ecommApiResponse(
  PaymentSessionDetailsDto
);

export type PaymentSessionDetailsResponse = t.TypeOf<
  typeof PaymentSessionDetailsResponse
>;
/* #endregion */

/* #region GiftCardDetailsRequest */
export const GiftCardDetailsRequest = t.intersection(
  [
    t.type(
      {
        cardNumber: t.string,
        securityCode: t.string,
        svsMerchantNumber: t.string,
        currency: t.string,
        vault: t.boolean
      },
      'GiftCardDetailsDtoRequired'
    ),
    t.partial({}, 'GiftCardDetailsDtoOptional')
  ],
  'GiftCardDetailsDto'
);

export type GiftCardDetailsRequest = t.TypeOf<typeof GiftCardDetailsRequest>;
/* #endregion */

/* #region GiftCardDetails */
export const GiftCardDetailsDto = t.intersection(
  [
    t.type(
      {
        balance: t.number,
        currency: t.string,
        alias: t.string
      },
      'GiftCardDetailsDtoRequired'
    ),
    t.partial(
      {
        vaultedAccountId: optional(t.string)
      },
      'GiftCardDetailsDtoOptional'
    )
  ],
  'GiftCardDetailsDto'
);

export type GiftCardDetailsDto = t.TypeOf<typeof GiftCardDetailsDto>;
export type GiftCardDetails = GiftCardDetailsDto;
/* #endregion */

/* #region GiftCardDetailsResponse */
export const GiftCardDetailsResponse = ecommApiResponse(GiftCardDetailsDto);

export type GiftCardDetailsResponse = t.TypeOf<typeof GiftCardDetailsResponse>;
/* #endregion */

/* #region PaymentValidateApplePayMerchantRequest */
export const PaymentValidateApplePayMerchantRequest = t.intersection(
  [
    t.type(
      {
        validationUrl: t.string,
        merchantIdentifier: t.string,
        displayName: t.literal('Wingstop'),
        initiative: t.literal('web'),
        initiativeContext: t.string
      },
      'PaymentValidateApplePayMerchantRequestOptional'
    ),
    t.partial({}, 'PaymentValidateApplePayMerchantRequestRequired')
  ],
  'PaymentValidateApplePayMerchantRequest'
);

export type PaymentValidateApplePayMerchantRequest = t.TypeOf<
  typeof PaymentValidateApplePayMerchantRequest
>;

/* #endregion */

/* #region CustomerVaultedCardsRequest*/

/* #endregion */

/* #region VaultedGiftCards*/

export const VaultedGiftCardsDto = t.intersection(
  [
    t.type(
      {
        vaultedAccountId: t.string,
        alias: t.string,
        balance: t.number
      },
      'VaultedGiftCardsDtoRequired'
    ),
    t.partial({}, 'VaultedGiftCardsDtoOptional')
  ],
  'VaultedGiftCardsDto'
);

export type VaultedGiftCardsDto = t.TypeOf<typeof VaultedGiftCardsDto>;
export type VaultedGiftCards = VaultedGiftCardsDto;

/* #endregion */

/* #region CreditDto and PrepaidDto */

export const CreditDto = t.intersection(
  [
    t.type(
      {
        alias: t.string,
        cardType: t.string,
        expiryDate: t.intersection(
          [
            t.type(
              {
                month: t.string,
                year: t.string
              },
              'ExpiryDateDtoRequired'
            ),
            t.partial({}, 'ExpiryDateDtoOptional')
          ],
          'ExpiryDateDto'
        )
      },
      'CreditDtoRequired'
    ),
    t.partial({}, 'CreditDtoOptional')
  ],
  'CreditDto'
);

export type CreditCardDetailsDto = t.TypeOf<typeof CreditDto>;
export type CreditCardDetails = CreditCardDetailsDto;

export const PrepaidDto = t.intersection(
  [
    t.type(
      {
        alias: t.string,
        balance: t.intersection(
          [
            t.type(
              {
                currentBalance: t.number,
                currencyCode: t.intersection(
                  [
                    t.type({ number: t.number }, 'CurrencyCodeDtoRequired'),
                    t.partial({}, 'CurrencyCodeDtoOptional')
                  ],
                  'CurrencyCodeDto'
                )
              },
              'BalanceDtoRequired'
            ),
            t.partial({}, 'BalanceDtoOptional')
          ],
          'BalanceDto'
        )
      },
      'PrepaidDtoRequired'
    ),
    t.partial({}, 'PrepaidDtoOptional')
  ],
  'PrepaidDto'
);

/* #endregion */

/* #region VaultedCreditCards*/

export const VaultedCreditCardsDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        status: t.string,
        type: t.string,
        credit: CreditDto
      },
      'VaultedCreditCardsDtoRequired'
    ),
    t.partial(
      {
        prepaid: optional(PrepaidDto)
      },
      'VaultedCreditCardsDtoOptional'
    )
  ],
  'VaultedCreditCardsDto'
);

export type VaultedCreditCardsDto = t.TypeOf<typeof VaultedCreditCardsDto>;
export type VaultedCreditCards = VaultedCreditCardsDto;

/* #endregion */

/* #region CustomerVaultedCardsRequest*/

export const CustomerVaultedCardsDto = t.intersection(
  [
    t.type(
      {
        vaultedGiftCard: t.array(VaultedGiftCardsDto),
        vaultedCreditCard: t.array(VaultedCreditCardsDto)
      },
      'CustomerVaultedCardsDtoRequired'
    ),
    t.partial({}, 'CustomerVaultedCardsDtoOptional')
  ],
  'CustomerVaultedCardsDto'
);

export type CustomerVaultedCardsDto = t.TypeOf<typeof CustomerVaultedCardsDto>;
export type CustomerVaultedCards = CustomerVaultedCardsDto;

/* #endregion */

/* #region CustomerVaultedCardsResponse */
export const CustomerVaultedCardsResponse = ecommApiResponse(
  CustomerVaultedCardsDto
);

export type CustomerVaultedCardsResponse = t.TypeOf<
  typeof CustomerVaultedCardsResponse
>;

/* #region CustomerVaultedCardsResponse */
export const DeleteCustomerVaultedCardsResponse = ecommApiResponse(t.boolean);

export type DeleteCustomerVaultedCardsResponse = t.TypeOf<
  typeof DeleteCustomerVaultedCardsResponse
>;
/* #endregion */
