import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Location } from '../../../../ecomm/types/search-location.types';
import { formatBusinessDayTimings } from '../../../../ecomm/utils/format-business-day-timings';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'wri-location-card',
  template: `
    <h4 class="title">{{ location.name }}</h4>
    <p class="address">
      {{ location.streetAddress }}, {{ location.locality }},
      {{ location.region }} {{ location.postalCode }}
    </p>
    <ng-container
      *ngIf="location?.comingSoonStatus && location?.openDate; else showHours"
    >
      <div class="coming-soon-city-badge">
        <span class="coming-soon-city-badge-text">Coming Soon</span>
      </div>
    </ng-container>
    <ng-template #showHours>
      <wri-todays-hours
        [businessDay]="location.businessDay"
        [hours]="location.storeHours"
      ></wri-todays-hours>
    </ng-template>
    <div class="phone-wrapper">
      <wri-phone-number [phoneNumber]="location.phoneNumber"></wri-phone-number>
    </div>
    <a class="location-details" [href]="locationDetailsPageUrl">
      <wri-icon icon="wri-info"></wri-icon>
      <span>Location details</span>
    </a>

    <div class="btn-wrpr">
      <ng-container *ngIf="!(location?.comingSoonStatus && location?.openDate)">
        <a
          *ngIf="location.handoffModes?.includes('carryout')"
          class="btn carryout wri-btn wri-btn-primary-icon"
          [href]="carryoutUrl"
          (click)="logAnalytics('carryout')"
        >
          <wri-icon icon="wri-carryout"></wri-icon>
          <span>carryout</span>
        </a>
        <a
          *ngIf="location.handoffModes?.includes('delivery')"
          class="btn delivery wri-btn wri-btn-primary-icon"
          [href]="deliveryUrl"
          (click)="logAnalytics('delivery')"
        >
          <wri-icon icon="wri-delivery"></wri-icon>
          <span>delivery</span>
        </a>
      </ng-container>
    </div>
  `,
  styleUrls: ['./location-card.component.scss']
})
export class LocationCardComponent {
  @Input() location!: Location;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {}

  get todayStoreHours(): string {
    const hours =
      this.location.storeHours.filter((h) => {
        const startDay = h.startDay.toLowerCase();
        const businessDay = h.startDay.toLowerCase();
        return startDay === businessDay;
      }) ?? [];
    return formatBusinessDayTimings(hours);
  }

  get locationDetailsPageUrl(): string {
    return [...this.activatedRoute.snapshot.url, this.location.slug].join('/');
  }

  get carryoutUrl(): string {
    const url = ['location', this.location.slug, 'menu'].join('/');
    return `${url}?handoffMode=carryout`;
  }

  get deliveryUrl(): string {
    const url = ['order'].join('/');
    return `${url}?handoffMode=delivery`;
  }

  logAnalytics(handoff: string) {
    this.analyticsService.logGaEvent({
      event: 'store_order_now',
      order_method: handoff
    });
  }
}
