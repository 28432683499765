import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  showSuccess(message: string, title?: string) {
    this.toastr.success(`<span>${message}</span>`, title);
  }

  showError(message: string, title?: string, disableTimeOut = false) {
    this.toastr.error(`<span>${message}</span>`, title, {
      disableTimeOut: disableTimeOut
    });
  }

  showInfo(message: string, title?: string) {
    this.toastr.info(`<span>${message}</span>`, title);
  }

  showWarning(message: string, title?: string) {
    this.toastr.warning(`<span>${message}</span>`, title);
  }
}
