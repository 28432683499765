import { Injectable } from '@angular/core';
import { State } from '@ngrx/store';
import { SecretMenuSignupSource } from '@wingstop/models/cms/banner.model';
import { UserRegistrationSignupSource } from '@wingstop/models/cms/user-registration-banner.model';
import { IAppStore } from '@wingstop/store/app-store';

@Injectable({
  providedIn: 'root',
})
export class UserIdentityService {
  constructor(private state: State<IAppStore>) {}

  isWithinTimeLimit(timestamp: Date, timeLimitInMinutes: number) {
    if (timestamp === null) return;
    const t0 = timestamp.getTime();
    const t1 = new Date().getTime();

    if (t1 <= t0 + timeLimitInMinutes * 60 * 1000) {
      return true;
    }

    return false;
  }

  isWithinFirstThreeMonths() {
    const firstVisitAt = localStorage.getItem('firstVisitAt');
    if (firstVisitAt !== null) {
      return this.isWithinTimeLimit(new Date(firstVisitAt), 90 * 24 * 60);
    }

    return true;
  }

  setFirstVisitTimestamp() {
    if (!localStorage.getItem('firstVisitAt')) {
      localStorage.setItem('firstVisitAt', new Date().toString());
    }
  }

  setAccountCreatedTimestamp() {
    localStorage.setItem('accountCreatedAt', new Date().toString());
  }

  getSignupSource() {
    return localStorage.getItem('signupSource');
  }

  setSignupSource(
    source: SecretMenuSignupSource | UserRegistrationSignupSource
  ) {
    localStorage.setItem('signupSource', source);
  }

  getSignupViaSecretMenu() {
    return localStorage.getItem('signupViaSecretMenu');
  }

  setSignupViaSecretMenu(signupViaSecretMenu: boolean) {
    localStorage.setItem(
      'signupViaSecretMenu',
      JSON.stringify(signupViaSecretMenu)
    );
  }

  isOrganicUser(): boolean {
    const queryParamLength = window.location.search.length;
    return queryParamLength === 0 ? true : false;
  }

  getUserLocale(): string {
    return this.getUserLocaleFromIp()
      ? this.getUserLocaleFromIp()
      : this.getUserLocaleFromBrowserLanguage();
  }

  // User IP from cloudflare cf-ipcountry response header in locale-interceptor.ts
  getUserLocaleFromIp(): string {
    const userLocale = this.state.getValue().appState.userLocale;
    return userLocale ? userLocale.toLowerCase() : '';
  }

  getUserLocaleFromBrowserLanguage(): string {
    let langLocale = '';
    if (navigator.languages && navigator.languages.length) {
      langLocale = navigator.languages[0].toLowerCase();
    } else if (navigator && navigator.language) {
      langLocale = navigator.language.toLowerCase();
    }

    langLocale = langLocale.slice(-2);

    if (langLocale === 'en') {
      langLocale = 'us';
    }

    return langLocale;
  }

  isInUS(): boolean {
    const locale = this.getUserLocale();
    return locale === 'en-us' || locale === 'en' || locale === 'us';
  }

  hasUnclearLocale(): boolean {
    const locale = this.getUserLocale();
    return locale === 'en' || locale.includes('-') === false;
  }
}
