import { Pipe, PipeTransform } from '@angular/core';

import { StoreInfoFeatureState } from '../../../ecomm/store/features/store-info';

@Pipe({
  name: 'shouldShowLocationError'
})
export class ShouldShowLocationErrorPipe implements PipeTransform {
  transform(state: StoreInfoFeatureState | null): boolean {
    if (!state || state.isLoading || !state.error) {
      return false;
    } else {
      return true;
    }
  }
}
