import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pick'
})
export class PickPipe implements PipeTransform {
  transform(input: Record<string, unknown>, key: string): unknown {
    return input[key];
  }
}
