import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSliderModule } from 'ngx-slider-v2';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common/common.module';
import { OrderModule } from '../order/order.module';
import { WingCalculatorModule } from '../wing-calculator';
import { ConnectedHomePageComponent } from './components/connected-home-page/connected-home-page.component';
import { HomePageFindYourMenuComponent } from './components/home-page-find-your-menu/home-page-find-your-menu.component';
import { HomePageFlavorSectionComponent } from './components/home-page-flavor-section/home-page-flavor-section.component';
import { HomePageHeroComponent } from './components/home-page-hero/home-page-hero.component';
import { HomePageProductCarouselComponent } from './components/home-page-product-carousel/home-page-product-carousel.component';
import { HomePageWingCalculatorComponent } from './components/home-page-wing-calculator/home-page-wing-calculator.component';

const components = [
  ConnectedHomePageComponent,
  HomePageHeroComponent,
  HomePageProductCarouselComponent,
  HomePageWingCalculatorComponent,
  HomePageFlavorSectionComponent,
  HomePageFindYourMenuComponent
];

@NgModule({
  imports: [
    CommonModule,
    WriCommonModule,
    RouterModule,
    OrderModule,
    SlickCarouselModule,
    NgxSliderModule,
    WingCalculatorModule,
    FormsModule,
  ],
  exports: components,
  declarations: components
})
export class HomePageModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<HomePageModule> {
    return {
      ngModule: HomePageModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
