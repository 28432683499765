import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wri-bag-count',
  templateUrl: './bag-count.component.html',
  styleUrls: ['./bag-count.component.scss']
})
export class BagCountComponent {
  @Input() count = 0;
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  handleClick(event: Event) {
    event.preventDefault();
    this.clicked.emit();
  }
}
