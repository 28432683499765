export const QueryParams = {
  handoffMode: 'handoffMode',
  deliveryAddress: 'deliveryAddress'
} as const;

export const PathParams = {
  locationSlug: 'locationSlug',
  categorySlug: 'categorySlug',
  productSlug: 'productSlug',
  itemSlug: 'itemSlug',
  citySlug: 'citySlug'
} as const;
