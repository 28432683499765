import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class OrderIdGuardService implements CanActivate {
  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const orderId = route.queryParams['orderid'];
    if (orderId) {
      this.router.navigate([`/order/recent/order-details/${orderId}`]);
      return false;
    }
    return true;
  }
}
