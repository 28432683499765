import * as t from 'io-ts';

/* #region RoundupCharity */
export const RoundupCharityDto = t.intersection(
  [
    t.type({}, 'RoundupCharityDtoRequired'),
    t.partial(
      {
        charityName: t.string,
        roundupDescription: t.string,
        modalImageUrl: t.string,
        modalTitle: t.string,
        modalDescription: t.string,
        charityImageUrl: t.string
      },
      'RoundupCharityOptional'
    )
  ],
  'RoundupCharity'
);

export type RoundupCharityDto = t.TypeOf<typeof RoundupCharityDto>;
export type RoundupCharity = RoundupCharityDto;
/* #endregion */

/* #region Response */
export const RoundupCharityResponse = RoundupCharityDto;
export type RoundupCharityResponse = t.TypeOf<typeof RoundupCharityResponse>;
/* #endregion */
