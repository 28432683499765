import { Pipe, PipeTransform } from '@angular/core';

import { Modifiers } from '../types/modifiers';

@Pipe({
  name: 'flavor-rebalance'
})
export class FlavorRebalancePipe implements PipeTransform {
  transform(
    quantity: number,
    maxModifierQuantity: number,
    selectedModifiers: Modifiers[]
  ): Modifiers[] {
    if (quantity % selectedModifiers.length === 0) {
      selectedModifiers.forEach(
        (s) =>
          (s.quantity = Math.min(
            quantity / selectedModifiers.length,
            maxModifierQuantity
          ))
      );
    } else {
      selectedModifiers.forEach((s) => (s.quantity = 0));

      for (let i = 0; i < quantity; i++) {
        const flavor = selectedModifiers[i % selectedModifiers.length];
        if (flavor && flavor.quantity) {
          flavor.quantity = Math.min(flavor.quantity + 1, maxModifierQuantity);
        } else {
          flavor.quantity = 1;
        }
      }
    }
    return selectedModifiers;
  }
}
