<div
  *ngIf="control?.errors | showInputError : errorKey"
  [attr.data-testid]="errorKey | default : 'fallback' | prepend : 'form-error-'"
  class="form-error"
  [ngClass]="{
    'form-warning': formWarning
  }"
>
  {{ display }}
</div>
