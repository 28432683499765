<div class="wri-designation-wrapper">
  <div data-testid="designation-item" *ngIf="isDry">
    <span class="wri-designation-text">{{ rubType }}</span>
  </div>
  <span
    data-testid="designation-item-separator"
    *ngIf="isDry && (isNew || isPopular)"
    aria-hidden="true"
  >
    {{ separator }}
  </span>
  <div data-testid="designation-item" *ngIf="isPopular">
    <wri-icon
      class="wri-designation-icon"
      icon="wri-popular-designation"
    ></wri-icon>
    <span class="wri-designation-text">popular</span>
  </div>
  <div data-testid="designation-item" *ngIf="isNew">
    <wri-icon
      class="wri-designation-icon"
      icon="wri-new-designation"
    ></wri-icon>
    <span class="wri-designation-text">new</span>
  </div>
</div>
