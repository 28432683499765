<div
  data-testid="wri-review-order-item"
  [ngClass]="{
    'wri-review-order-items-invalid': lineItem?.status === 'invalid',
    'wri-review-order-items-readonly': isReadOnly
  }"
  class="wri-review-order-items"
>
  <div class="invalid-items" *ngIf="!lineItemHasInvalidModifiers() && lineItem?.status === 'invalid'">
    <span>{{ lineItem?.statusReason }}</span>
  </div>
  <div
    *ngIf="isReadOnly"
    class="flex-col prod-name"
    [ngClass]="{
      'with-modifiers':
        lineItem?.modifierGroups && lineItem?.modifierGroups.length > 0
    }"
  >
    <span tabindex="0" data-testid="prod-name-val"
      >{{ lineItem?.productName }}
    </span>
  </div>

  <a
    wriFocusOutline
    role="button"
    [attr.aria-label]="'Edit Item'"
    (click)="editItem()"
    [ngClass]="{'line-item-disabled': !lineItemHasInvalidModifiers() && lineItem?.status === 'invalid'}"
    data-testid="edit-item"
    *ngIf="!isReadOnly"
  >
    <div
      class="flex-col prod-name"
      [ngClass]="{
        'with-modifiers':
          lineItem?.modifierGroups && lineItem?.modifierGroups.length > 0
      }"
    >
      <span tabindex="0" data-testid="prod-name-val"
        >{{ lineItem?.productName }}
      </span>
      <div [ngClass]="{'disabled': !lineItemHasInvalidModifiers() && lineItem?.status === 'invalid'}" class="edit-icon-wrapper">
        <wri-icon icon="wri-edit"></wri-icon>
      </div>
    </div>
  </a>

  <div
    class="modifier-wrapper"
    *ngIf="lineItem?.modifierGroups && lineItem?.modifierGroups.length > 0"
  >
    <ng-container
      *ngFor="
        let modifierGroup of lineItem.modifierGroups
          | sort : 'asc' : 'sortOrder'
      "
    >
      <ng-container
        *ngFor="
          let modifier of modifierGroup.modifiers | sort : 'asc' : 'sortOrder'
        "
      >
        <div class="flex-col modifier-name">
          <span
            [attr.data-testid]="
              modifier.modifierId | prepend : 'modifier-name-val-'
            "
          >
            {{ modifier?.name }}
            <ng-container *ngIf="modifier.unitPrice > 0">
              (+{{ modifier.unitPrice | currency }})
            </ng-container>
            <ng-container *ngIf="modifier.quantity > 1">
              x {{ modifier.quantity }}
            </ng-container>

            <div class="invalid-items" *ngIf="modifier?.status === 'invalid'">
              <span>
                &ndash;
                {{ modifier?.statusReason }}</span
              >
            </div>
          </span>
        </div>
        <!-- List item modifiers -->
        <ng-container *ngFor="let modifierGroup of modifier.modifierGroups">
          <ng-container *ngFor="let modifier of modifierGroup.modifiers">
            <div class="flex-col modifier-name">
              <span
                [attr.data-testid]="
                  modifier.modifierId | prepend : 'modifier-name-val-'
                "
              >
                {{ modifier?.name }}
                <ng-container *ngIf="modifier.unitPrice > 0">
                  (+{{ modifier.unitPrice | currency }})
                </ng-container>
                <ng-container *ngIf="modifier.quantity > 1">
                  x {{ modifier.quantity }}
                </ng-container>

                <div
                  class="invalid-items"
                  *ngIf="modifier?.status === 'invalid'"
                >
                  <span>
                    &ndash;
                    {{ modifier?.statusReason }}</span
                  >
                </div>
              </span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="flex-col">
    <div class="quantity-changer">
      <ng-container
        *ngIf="isReadOnly; then readOnlyQuantity; else editQuantity"
      ></ng-container>

      <ng-template #readOnlyQuantity>
        <div class="read-only-quantity">Qty: {{ lineItem.quantity }}</div>
      </ng-template>

      <ng-template #editQuantity>
        <a
          #decreaseCntrl
          wriFocusOutline
          [class.decrease-quantity-hyperlink]="isDecreaseLoading"
          (click)="onDecrease()"
          data-testid="decrease-quantity"
          role="button"
          class="quantity-btn"
        >
          <wri-icon
            role="text"
            [attr.aria-label]="
              'Remove one ' +
              lineItem?.productName +
              '. Current number: ' +
              lineItem?.quantity
            "
            class="wri-legacy-img"
            icon="wri-subtract-circle"
          ></wri-icon>
        </a>
        <div class="spinner-wrapper-overlay" *ngIf="isDecreaseLoading">
          <wri-spinner></wri-spinner>
        </div>
        <span class="flex-col item-quantity" data-testid="item-quantity">{{
          lineItem?.quantity
        }}</span>
        <a
          #increaseCntrl
          wriFocusOutline
          [class.increase-quantity-hyperlink]="isIncreaseLoading"
          (click)="onIncrease()"
          data-testid="increase-quantity"
          [ngClass]="{
            'prod-links-disabled': lineItem?.status === 'invalid'
          }"
          role="button"
          class="quantity-btn"
        >
          <wri-icon
            role="text"
            [attr.aria-label]="
              'Add one ' +
              lineItem?.productName +
              '. Current number: ' +
              lineItem?.quantity
            "
            class="wri-legacy-img"
            [ngClass]="{
              'prod-links-disabled': lineItem?.status === 'invalid'
            }"
            icon="wri-add-circle"
          ></wri-icon>
        </a>
        <div class="spinner-wrapper-overlay" *ngIf="isIncreaseLoading">
          <wri-spinner></wri-spinner>
        </div>
      </ng-template>
    </div>
    <div data-testid="line-item-subtotal" class="total-item-price text-right">
      {{ lineItem?.itemSubtotal | currency }}
    </div>
    <!-- /* quantity < 0 modal */ -->
    <ng-template #commonModal let-close="close" let-dismiss="dismiss">
      <wri-common-modal [close]="close" [dismiss]="dismiss">
        <div class="wri-modal__wrapper">
          <div data-testid="cart-modal-title" class="wri-modal__title">
            Remove Item?
          </div>
          <div
            data-testid="cart-modal-description"
            class="wri-modal__description"
            *ngIf="lineItem?.productName"
          >
            Are you sure you want to remove {{ lineItem?.productName }} from your
            order?
            <span
              data-testid="cart-modal-description-if-offer-applied"
              *ngIf="cartState?.cart?.offer?.status === 'valid'"
            >
              This might affect the qualifying offer in your bag.
            </span>
          </div>
          <div class="wri-modal__btn-wrapper">
            <button
              [disabled]="isRemoveButtonClicked"
              data-testid="cart-modal-remove"
              class="wri-btn wri-btn-primary"
              aria-label="Remove"
              (click)="removeItem()"
            >
              <ng-container *ngIf="!isRemoveButtonClicked"> Remove </ng-container>
              <div
                *ngIf="isRemoveButtonClicked"
                class="cart-modal__spinner-wrapper-overlay"
              >
                <wri-spinner spinnerColor="white"></wri-spinner>
              </div>
            </button>
            <button
              data-testid="cart-modal-cancel"
              class="wri-btn wri-btn-tertiary"
              aria-label="Cancel"
              (click)="close($event)"
            >
              Cancel
            </button>
          </div>
        </div>
      </wri-common-modal>
    </ng-template>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <wri-spinner></wri-spinner>
  </div>
</ng-template>
