import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResponseCacheService {
  private cache = new Map<string, any>();

  constructor() {}

  public set(key: string, value: any) {
    this.cache.set(key, value);
  }

  public get(key: string) {
    return this.cache.get(key);
  }

  public delete(key: string) {
    this.cache.delete(key);
  }
}
