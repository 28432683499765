import { Injectable } from '@angular/core';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { IAppStore } from '../store/app-store';
import { State } from '@ngrx/store';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(
    private state: State<IAppStore>,
    private appStateActions: AppStateActions
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: any) => {
        if (event instanceof HttpResponse) {
          const existingLocale = this.state.getValue().appState.userLocale;
          const locale = event.headers.get('cf-ipcountry');
          if (!existingLocale || existingLocale !== locale) {
            if (locale) {
              this.appStateActions.setUserLocale(locale);
            }
          }
        }
        return event;
      })
    );
  }
}
