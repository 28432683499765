/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

import {
  ILegacyAnalyticsService,
  LEGACY_ANALYTICS_SERVICE
} from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  MenuItem,
  MenuModifier,
  MenuModifierGroup
} from '../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { areModifierGroupsValid } from '../../../../ecomm/utils/pdp-utils';
import {
  ModGroups,
  ModifierModalElementChangeService,
  Modifiers
} from '../../../common';
import { ModifierModalComponent } from '../modifier-modal/modifier-modal.component';

export type ModifierGroupFormData = {
  modifiers: Modifiers[] | null;
};

export type ModifierGroupsFormData = {
  modifierGroups: ModGroups[] | null;
};

@Component({
  selector: 'wri-modifier-element',
  templateUrl: './modifier-element.component.html',
  styleUrls: ['./modifier-element.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ModifierElementComponent)
    }
  ]
})
export class ModifierElementComponent implements AfterContentInit, OnInit {
  @Input() simpleModifierData!: MenuModifier | null;
  @Input() complexModifierData!: MenuItem | null;
  @Input() parentId: string | undefined;
  @Input() maxSelectionsAllowed = 0;
  @Input() selectedModifiers: Modifiers[] = [];
  @Input() class = '';
  @Input() type = 'simpleModifier';
  @Input() modGroupId: string | undefined;
  @Output()
  resetPDPValidations = new EventEmitter();

  public selectedItemModifierGroups: ModGroups[] = [];
  /** Implement ControlValueAccessor */
  disabled = false;
  touched = false;
  private defaultSelectedItemModifierGroups: ModGroups[] = [];
  private hasSelectedModifierElementsChanged = false;
  requiredUnselectedModifierGroupLabels: string | undefined | null;

  constructor(
    public modalService: NgbModal,
    private modifierModalElementChangeService: ModifierModalElementChangeService,
    @Inject(LEGACY_ANALYTICS_SERVICE)
    private analyticsService: ILegacyAnalyticsService
  ) {}

  /** Implement ControlValueAccessor */
  set value(value: number | Modifiers[]) {
    this.onChange(value);
  }

  ngAfterContentInit(): void {
    if (
      this.simpleModifierData &&
      this.simpleModifierData.isDefault &&
      !this.simpleModifierData?.outOfStock &&
      this.simpleModifierData?.availableInSchedule
    ) {
      this.onChangeModifierElement(
        true,
        this.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
        this.simpleModifierData,
        true // if triggered by default without manually clicking on checkbox
      );
    }
    if (
      this.complexModifierData &&
      this.complexModifierData.isDefault &&
      !this.complexModifierData?.outOfStock &&
      this.complexModifierData?.availableInSchedule
    ) {
      this.onChangeModifierElement(
        true,
        this.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
        this.complexModifierData,
        true // if triggered by default without manually clicking on checkbox
      );
    }
  }

  ngOnInit(): void {
    this.getDefaultSelectedValue();
    this.defaultSelectedItemModifierGroups = cloneDeep(
      this.selectedItemModifierGroups
    );
  }

  async openModifierModal() {
    const modalRef = this.modalService.open(ModifierModalComponent, {
      windowClass: 'modifier-common-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.data = this.complexModifierData?.modifierGroups;
    modalRef.componentInstance.selectedItemModifierGroups =
      this.selectedItemModifierGroups;
    modalRef.componentInstance.hasSelectedModifierElementsChanged =
      this.hasSelectedModifierElementsChanged;

    const values = await modalRef.result;
    if (values) {
      const { itemModifierGroups, isValid, isApplied } = values;
      if (isApplied) {
        this.requiredUnselectedModifierGroupLabels = null;
        //whenever a modifier is selected in customise modal - reset validations
        this.resetPDPValidations.emit();
        this.hasSelectedModifierElementsChanged = true;
        this.selectedItemModifierGroups = itemModifierGroups;
        // update validity of modifier
        this.selectedModifiers[0] = {
          ...this.selectedModifiers[0],
          isValid
        };
        if (itemModifierGroups.length > 0) {
          this.selectedModifiers[0] = {
            ...this.selectedModifiers[0],
            modifierGroups: itemModifierGroups
          };
        } else {
          // remove modifierGroups from payload if none selected
          delete this.selectedModifiers[0].modifierGroups;
        }
        // trigger change in the form to reflect modifier modal values
        this.onTouched();
        this.onChange(this.selectedModifiers);
      } else {
        if (!isValid) this.requiredUnselectedModifierGroupLabels = this.getRequiredUnselectedModifierGroupLabels();
      }
    } else {
      this.requiredUnselectedModifierGroupLabels = this.getRequiredUnselectedModifierGroupLabels();
    }
  }

  isItemDisabled(modifierItemId: string): boolean {
    if (
      this.selectedModifiers.length === this.maxSelectionsAllowed &&
      !this.selectedModifiers.some(
        (modifier) => modifier.modifierId === modifierItemId
      )
    ) {
      return true;
    }
    return false;
  }

  isItemChecked(modifierItemId: string): boolean {
    if (
      this.selectedModifiers.some(
        (modifier) => modifier.modifierId === modifierItemId
      )
    ) {
      return true;
    }
    return false;
  }

  onChange: (v: number | Modifiers[]) => void = () => void 0;

  onTouched: () => void = () => void 0;

  onChangeModifierElement(
    checked: boolean,
    type: string,
    modifierData: MenuModifier | MenuItem,
    isTriggeredByDefault = false
  ) {
    //whenever a modifier is selected - reset validations
    this.resetPDPValidations.emit();
    if (checked) {
      // reset to default values when modifier checked
      if (!isTriggeredByDefault) {
        this.getDefaultSelectedValue(true);
        this.analyticsService.logGaEvent({
          event: 'choose_options',
          item_name: modifierData.name,
          count: 1
        });
        this.requiredUnselectedModifierGroupLabels = undefined;
        if (this.itemHasRequiredUndefaultedModifiers()) {
          // open customise modal
          this.openModifierModal()
        } else {
          this.requiredUnselectedModifierGroupLabels = null;
        }
      } else {
        this.requiredUnselectedModifierGroupLabels =
          this.itemHasRequiredUndefaultedModifiers()
            ? this.getRequiredUnselectedModifierGroupLabels()
            : null;
      }
      if (type === 'multiple') {
        this.selectedModifiers.push({
          modifierId: modifierData.id,
          quantity: 1,
          price: modifierData.price,
          name: modifierData.name,
          isValid: areModifierGroupsValid(
            this.complexModifierData?.modifierGroups,
            this.selectedItemModifierGroups
          ),
          modifierGroups: this.selectedItemModifierGroups
        });
      } else if (type === 'single') {
        this.selectedModifiers = [
          {
            modifierId: modifierData.id,
            quantity: 1,
            price: modifierData.price,
            name: modifierData.name,
            isValid: areModifierGroupsValid(
              this.complexModifierData?.modifierGroups,
              this.selectedItemModifierGroups
            ),
            modifierGroups: this.selectedItemModifierGroups
          }
        ];
      }
    } else {
      this.selectedModifiers = this.selectedModifiers.filter(
        (mod) => mod.modifierId !== (modifierData ? modifierData.id : '')
      );
    }
    this.onTouched();
    this.onChange(this.selectedModifiers);
  }

  writeValue(newValue: number | Modifiers[]): void {
    this.value = newValue;
  }

  registerOnChange(
    onChange: (v: number | Modifiers[]) => Modifiers | undefined
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  private getDefaultSelectedValue(isTriggeredWithModifierChange = false) {
    const itemModifierGroups = this.complexModifierData?.modifierGroups?.filter(
      (m) => m.availableInSchedule
    );
    if (isTriggeredWithModifierChange)
      this.selectedItemModifierGroups = this.defaultSelectedItemModifierGroups;
    itemModifierGroups?.forEach((itemModifierGroup) => {
      itemModifierGroup.modifierGroupElements.forEach(
        (modifierGroupElement) => {
          if (
            modifierGroupElement.modifier?.isDefault &&
            !(modifierGroupElement.modifier as MenuModifier)?.outOfStock &&
            modifierGroupElement.modifier?.availableInSchedule
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.selectedItemModifierGroups, // this variable will be updated with default selected modifiers
              true,
              itemModifierGroup.maxSelectionsAllowed > 1
                ? 'multiple'
                : 'single',
              itemModifierGroup.id,
              modifierGroupElement.modifier!
            );
          } else if (
            modifierGroupElement.item?.isDefault &&
            !(modifierGroupElement.item as MenuModifier)?.outOfStock &&
            modifierGroupElement.item?.availableInSchedule
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.selectedItemModifierGroups, // this variable will be updated with default selected modifiers
              true,
              itemModifierGroup.maxSelectionsAllowed > 1
                ? 'multiple'
                : 'single',
              itemModifierGroup.id,
              modifierGroupElement.item!
            );
          }
        }
      );
    });
    this.requiredUnselectedModifierGroupLabels = null;
  }

  itemHasRequiredUnselectedModifierGroups(itemModifierGroup: MenuModifierGroup): boolean {
    const defaultModifier = itemModifierGroup.modifierGroupElements.find(
      (modifierGroupElement) =>
        (modifierGroupElement.modifier && modifierGroupElement.modifier.isDefault === true) ||
        (modifierGroupElement.item && modifierGroupElement.item.isDefault === true)
    );
    return itemModifierGroup.required && defaultModifier === undefined;
  }

  itemHasRequiredUndefaultedModifiers(): boolean {
    const itemModifierGroups = this.complexModifierData?.modifierGroups?.filter(
      (m) => m.availableInSchedule
    );
    let isConditionMatched = false;
    isConditionMatched = itemModifierGroups?.some((itemModifierGroup) => {
      return this.itemHasRequiredUnselectedModifierGroups(itemModifierGroup);
    }) || false;
    return isConditionMatched;
  }

  getRequiredUnselectedModifierGroupLabels(): string | undefined {
    const itemModifierGroups = this.complexModifierData?.modifierGroups?.filter(
      (m) => m.availableInSchedule
    );
    let label = '';
    const allLabels: string[] = [];
    itemModifierGroups?.forEach((itemModifierGroup) => {
      if (this.itemHasRequiredUnselectedModifierGroups(itemModifierGroup)) {
        allLabels.push(itemModifierGroup.name); 
      }
    })
    if (allLabels.length > 0) {
      label += allLabels.join(', ')
      return label;
    }
    return;
  }
}