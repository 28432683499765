import { Pipe, PipeTransform } from '@angular/core';

import {
  TipConfiguration,
  TipConfigurationDto,
  TipConfigurationTipOption
} from '../../../ecomm/types/regional-configuration.types';

type TipConfigurationTipDisplayOption = Omit<
  TipConfigurationTipOption,
  'amount'
> & {
  amount: string;
};

@Pipe({
  name: 'tipOptions'
})
export class TipOptionsPipe implements PipeTransform {
  public static readonly DOLLAR = 'dollar';
  public static readonly PERCENT = 'percent';

  private format(
    options: TipConfigurationTipOption[],
    type: typeof TipOptionsPipe.DOLLAR | typeof TipOptionsPipe.PERCENT
  ): TipConfigurationTipDisplayOption[] | undefined {
    return options.map((opt) => ({
      ...opt,
      amount:
        type === TipOptionsPipe.DOLLAR ? `$${opt.amount}.00` : `${opt.amount}%`
    }));
  }

  private handOffModepresetOptions =
    (input: TipConfigurationDto, handOffMode: string): TipConfiguration => {
      return {
        threshold: input?.threshold,
        presetOptions:
          input?.presetOptions?.find((po) => {
            return po.handoffMode === handOffMode
          }) ?? null
      }
    };

  transform(
    tipConfiguration: TipConfigurationDto | undefined,
    handOffMode: string,
    cartSubTotal: number,
  ): { threshold: number, tipOptions: TipConfigurationTipDisplayOption[], currencyType: string } | undefined {

    if (!tipConfiguration) {
      return undefined
    }

    const options = this.handOffModepresetOptions(tipConfiguration, handOffMode)
    const { presetOptions, threshold } = options;
    const isAboveThreshold = cartSubTotal > threshold;

    if (!presetOptions || !threshold) {
      return undefined
    }

    return {
      threshold,
      tipOptions: this.format(
        isAboveThreshold ? presetOptions.percentOptions : presetOptions.dollarOptions,
        isAboveThreshold ? TipOptionsPipe.PERCENT : TipOptionsPipe.DOLLAR
      ),
      currencyType: isAboveThreshold ? 'percent' : 'dollar'
    };
  }
}
