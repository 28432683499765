/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { FlavorsRepository } from '../../repositories/flavors/flavors.repository';
import { Flavor } from '../../types/flavor.types';
import * as MaybeResponse from '../../types/maybe-response';
import { fromDto } from '../../utils/from-dto';

@Injectable({ providedIn: 'root' })
export class FlavorsService {
  constructor(private flavorsRepository: FlavorsRepository) {}

  public getFlavors(): Observable<MaybeResponse.MaybeResponse<Flavor[]>> {
    return this.flavorsRepository.getFlavors().pipe(
      map(
        MaybeResponse.mapData((data) => {
          if (!data) return undefined;
          return data.flavors.map(fromDto);
        })
      )
    );
  }
}
