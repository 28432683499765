<div class="wri-category-nav-wrapper menu-item-background">
  <div class="wri-menu-title">Menu</div>
  <div class="hor-scroll-wrap" id="scrollWrapper">
    <div
      id="wri-nav-container"
      class="container-fluid wri-category-nav-container"
      (scroll)="onScroll()"
    >
      <div class="row">
        <ng-container *ngFor="let category of categories">
          <div class="col">
            <div
              class="cat-links"
              (click)="focusOnCat(category?.slug)"
              [id]="'cat-' + category?.slug"
            >
              <a
                [href]="'#' + category?.slug"
                [attr.log-data]="category?.slug"
                wriFocusOutline
                (click)="scrollToSelectedCategory($event, category?.slug)"
              >
                <span class="category-name">{{ category.name }}</span>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
