import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { PilotService } from '../services/pilot.service';

@Injectable()
export class PilotAuthenticationResolver implements Resolve<void> {
  constructor(private pilotService: PilotService) {}

  resolve(route: ActivatedRouteSnapshot): void {
    this.pilotService.handleNgfeAuthLogout(route);
  }
}
