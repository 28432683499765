import moment from 'moment-timezone';

import { LocationNormalCalendarRange } from '../types/search-location.types';

export const TIME_INPUT_FORMAT = 'HH:mm A' as const;
export const HOUR_MINUTE_FORMAT = 'hmm A' as const;
export const LABEL_FORMAT = 'h:mm A' as const;
export const MIDNIGHT_LABEL = 'Midnight' as const;
export const MIDNIGHT_HOUR_MINUTE = '1200 AM' as const;

export function formatBusinessDayTimings(
  hours: LocationNormalCalendarRange[],
  prefix?: string
) {
  if (hours?.length) {
    const firstObject = hours[0];
    const formatedFirstTiming = firstObject && formatRange(firstObject);
    const remainingTiming = hours?.slice(1);
    if (!remainingTiming?.length && formatedFirstTiming) {
      return prefix ? `${prefix} ${formatedFirstTiming}` : formatedFirstTiming;
    } else {
      let otherTimings = '';
      remainingTiming.forEach((timing) => {
        const formatTiming = formatRange(timing);
        otherTimings = `${otherTimings} and ${formatTiming}`;
      });
      return prefix
        ? `${prefix} ${formatedFirstTiming}${otherTimings}`
        : `${formatedFirstTiming}${otherTimings}`;
    }
  }
  return 'Closed';
}

export function formatRange(r: LocationNormalCalendarRange): string {
  const startLabel = getTimeLabel(r.startTime);
  const endLabel = getTimeLabel(r.endTime);

  if (startLabel === MIDNIGHT_LABEL && endLabel === MIDNIGHT_LABEL) {
    return '24 hours';
  } else {
    return `${startLabel} to ${endLabel}`;
  }
}

export function getTimeLabel(time: string): string {
  const parsed = moment(time, TIME_INPUT_FORMAT);
  return parsed.format(HOUR_MINUTE_FORMAT) === MIDNIGHT_HOUR_MINUTE
    ? MIDNIGHT_LABEL
    : parsed.format(LABEL_FORMAT);
}
