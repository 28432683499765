import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

import { ActiveOfferFeature } from '../../../ecomm/store/features/active-offer';
import { CacheLoadedFeature } from '../../../ecomm/store/features/cache-loaded';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';

@Injectable()
export class ActiveOfferResolver implements Resolve<void> {
  constructor(
    @Inject(AsynchronousDispatcher)
    private asynchronousDispatcher: AsynchronousDispatcher<FeaturesState>,
    public router: Router,
    private store: Store
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const redeemCode = route.queryParamMap.get('redeem');
    const cacheLoaded = await firstValueFrom(
      this.store
        .select(CacheLoadedFeature.selectCacheLoaded)
        .pipe(filter(Boolean))
    );

    if (cacheLoaded) {
      if (redeemCode) {
        await this.asynchronousDispatcher.dispatch(
          ActiveOfferFeature.actions.resetToDefault()
        );
        await this.asynchronousDispatcher.dispatch(
          ActiveOfferFeature.actions.setState({
            activeOffer: redeemCode
          })
        );
      }
    }
  }
}

export const ACTIVE_OFFER = 'activeOffer';
