import { ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function formatFrombday(value: string) {
  return value.replace(/[\s-\D]+/g, '/').substring(0, 5);
}

export function birthDateValidator(): ValidatorFn {
  return (control) => {
    if (control.value === undefined || control.value === null) {
      return null;
    }
    const parsedNumber = formatFrombday(control.value as string);
    if (parsedNumber.length > 0 && parsedNumber.length < 5) {
      return { minLength: true };
    }
    if (parsedNumber.length > 5) {
      return { maxLength: true };
    }
    if (parsedNumber.length === 5) {
      const month = parsedNumber.substring(0, 2);
      const day = parsedNumber.substring(3, 5);
      const date = moment(`2004-${month}-${day}`);
      if (!date.isValid()) {
        return { pattern: true };
      }
    }
    return null;
  };
}
