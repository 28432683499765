import { Inject, Injectable } from '@angular/core';
import { GRECAPTCHA_SCRIPT } from '../../constants/recaptcha';
import { CONFIG, Config } from '../../providers/config/config.provider';

@Injectable()
export class ReCaptchaService {
  constructor(@Inject(CONFIG) private config: Config) {}

  public async execute(action: string): Promise<string> {
    const site_key = this.config.grecaptcha.siteKey ?? '';
    return new Promise((res) => {
      return grecaptcha.enterprise.ready(function () {
        grecaptcha.enterprise.execute(site_key, { action }).then(res);
      });
    });
  }

  public getRecaptchaURL(): string {
    return GRECAPTCHA_SCRIPT.replace(
      'site_key',
      this.config.grecaptcha.siteKey ?? ''
    );
  }
}
