import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default'
})
export class DefaultPipe<T> implements PipeTransform {
  transform(input: T | undefined | null, fallback: T): T {
    return input ?? fallback;
  }
}
