import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import { EcommModule } from '../../ecomm/ecomm.module';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common';
import { AuthNavigationComponent } from './components/auth-navigation/auth-navigation.component';
import { ConnectedSignupComponent } from './components/connected-signup/connected-signup.component';
import { FavoriteLocationsComponent } from './components/favorite-locations/favorite-locations.component';
import { NoFavoriteLocationsComponent } from './components/favorite-locations/no-favorite-locations/no-favorite-locations.component';
import { RemoveFavoriteLocationModalComponent } from './components/favorite-locations/remove-favorite-location-modal/remove-favorite-location-modal.component';
import { NoPaymentMethodsComponent } from './components/payment-methods/no-payment-methods/no-payment-methods.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { RemovePaymentMethodComponent } from './components/payment-methods/remove-payment-method-modal/remove-payment-method-modal.component';
import { SigninCallbackComponent } from './components/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './components/signout-callback/signout-callback.component';
import { LoginComponent } from './components/login/login.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import {
  MyAddressesComponent,
  NoMyAddressesComponent,
  RemoveMyAddressModalComponent,
  SignUpFormComponent,
} from './index';

export const AccountsComponents = [
  AuthNavigationComponent,
  SigninCallbackComponent,
  SignoutCallbackComponent,
  LoginComponent,
  UserAccountComponent,
  PaymentMethodsComponent,
  NoPaymentMethodsComponent,
  RemovePaymentMethodComponent,
  FavoriteLocationsComponent,
  NoFavoriteLocationsComponent,
  RemoveFavoriteLocationModalComponent,
  MyAddressesComponent,
  NoMyAddressesComponent,
  RemoveMyAddressModalComponent,
  ConnectedSignupComponent,
  SignUpFormComponent
];

@NgModule({
  declarations: [...AccountsComponents],
  imports: [
    CommonModule,
    WriCommonModule,
    EcommModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [],
  exports: [...AccountsComponents]
})
export class AccountsModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<AccountsModule> {
    return {
      ngModule: AccountsModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
