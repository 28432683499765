<div
  class="common-modal"
  role="dialog"
  aria-modal="true"
  aria-label="Remove Item"
>
  <div class="common-modal__header">
    <div *ngIf="closeIcon" class="common-modal__icon-wrapper">
      <a
        wriFocusOutline
        (click)="onModalClose($event)"
        href="#"
        [attr.aria-label]="'close modal'"
        data-testid="common-modal"
      >
        <wri-icon
          class="common-modal__cross_icon"
          icon="wri-cross"
        ></wri-icon>
      </a>
    </div>
  </div>
  <ng-content></ng-content>
</div>