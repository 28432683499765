<div data-testid="empty-payment-section" class="wri-empty-payment-method-wrapper">
  <div class="wri-credit-card-wrapper icon-round spacing-bottom">
    <wri-icon icon="wri-credit-card"></wri-icon>
  </div>

  <span class="payment-method-empty-text" data-testid="payment-method-empty-text"
    >No Saved Payment Methods</span
  >
  <span class="no-payment-method-text" data-testid="no-payment-method-text"
    >You do not have any saved payment methods. You can add a new payment method when you checkout.</span
  >
  <button
    wriFocusOutline
    data-testid="start-order"
    [ngClass]="'wri-btn wri-btn-primary'"
    aria-label="Start Order"
    (click)="goToOrderPage()"
  >
    Start Order
  </button>
</div>
