import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wri-external-link-confirmation-modal',
  templateUrl: './external-link-confirmation-modal.component.html',
})
export class ExternalLinkConfirmationModalComponent {
  @Input() externalLinkTarget: string;
  @ViewChild('externalLinkConfirmationModal')
  externalLinkConfirmationModal!: TemplateRef<HTMLElement>;
  modalReference: NgbModalRef | undefined;

  constructor(private modalService: NgbModal) {}

  handleClick($event: MouseEvent) {
    $event.preventDefault();
    window.open(this.externalLinkTarget, '_blank');
  }

  openModal(externalLinkTarget: string) {
    this.externalLinkTarget = externalLinkTarget;
    this.modalReference = this.modalService.open(
      this.externalLinkConfirmationModal,
      {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm',
      }
    );
  }

  closeModal($event: MouseEvent) {
    $event.preventDefault();
    this.modalReference?.close();
  }
}
