import { inject } from '@angular/core';
import { StoreInfoWorkflowService } from '../../../ecomm/workflows/store-info/store-info-workflow.service';
import { Router } from '@angular/router';

export const offlineGuard = async () => {
	const storeInfoService = inject(StoreInfoWorkflowService);
	const router = inject(Router)
	const regionalConfig =
		await storeInfoService.getRegionalConfigurationAndSave();

	if (regionalConfig.isOffline) {
		return router.navigateByUrl('/offline');
	}
	return true;
}