import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { filter, Subscription } from 'rxjs';

import {
  Customer,
  CustomerPutRequest
} from '../../../../ecomm/types/customer.types';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { RegexPatterns } from '../../../../ecomm/constants/regex-patterns';
import {
  PartialOutageModalComponent,
  emailValidator,
  formatFromPhone,
  formatToPhone,
  phoneValidator
} from '../../../common';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { zipValidator } from '../../../common/form-validators/zip.validator';
import { birthDateValidator } from '../../../common/form-validators/birthday.validator';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { Store } from '@ngrx/store';
import { CustomerVaultedCards } from '../../../../ecomm/types/payment.types';
import { PaymentWorkflowService } from '../../../../ecomm/workflows/payment/payment-workflow.service';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { DOCUMENT } from '@angular/common';

export type MyAccountFormData = {
  firstName: string;
  lastName: string;
  email: string;
  zip: string;
  phone: string;
  birthdate: string;
  emailPreference: boolean;
};

@Component({
  selector: 'wri-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit, OnDestroy {
  isLoading = true;
  deleteAccountLoading = false;
  logoutLoading = false;
  canShowErrors = false;
  userDetails!: Customer;
  myAccountForm: UntypedFormGroup = new UntypedFormGroup({});
  public userAccountFormValid = true;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @ViewChild('changeMyPasswordModal')
  changeMyPasswordModal!: TemplateRef<HTMLElement>;
  private subscription = new Subscription();
  private PING_AUTH_COOKIE = 'pingAuth';
  private PING_AUTH_PROFILE_COOKIE = 'pingAuthProfile';
  public regionalConfigState: RegionalConfigurationFeatureState | null = null;

  public vaultedCards: CustomerVaultedCards | undefined | null;
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private userAccountService: CustomerWorkflowService,
    private storeInfoService: StoreInfoWorkflowService,
    @Inject(RedirectService)
    private redirectService: RedirectService,
    private notificationService: NotificationService,
    private analyticsService: AnalyticsService,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private store: Store,
    private paymentWorkflowService: PaymentWorkflowService,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  private _initialValues: Partial<MyAccountFormData> = {};

  @Input()
  set initialValues(values: Partial<MyAccountFormData>) {
    this._initialValues = values;
    this.initForm(this.userDetails);
  }

  public regionalConfig$ = this.store
    .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
    .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

  async ngOnInit(): Promise<void> {
    this.myAccountForm = this.createForm();
    await this.setUserDetails();
    this.subscription.add(
      this.authService.signInEvents.subscribe({
        next: (val) => {
          if (val === true) {
            this.setUserDetails();
          } else {
            this.redirectService.redirectToHome();
          }
        }
      })
    );

    await this.subscribeToRegionalConfigState();
    await this.getVaultedCards();
  }

  async subscribeToRegionalConfigState(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscription.add(
        this.regionalConfig$.subscribe({
          next: (state) => {
            this.regionalConfigState = state;
            this.partialOutageModalComponent?.showModal(state);
            resolve();
          },
          error: (err) => {
            reject(err);
          }
        })
      );
    });
  }

  async getVaultedCards() {
    try {
      this.vaultedCards =
        await this.paymentWorkflowService.getVaultedGiftAndCreditCards();
    } catch (err) {
      this.notificationService.showError(
        'We encountered an unexpected error loading your saved cards. Please try again.'
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  public getPhoneFormatter() {
    return formatToPhone;
  }

  onKeyup(event: { target: { value: string }; key: string; which: number }) {
    const current = this.myAccountForm.value.birthdate;
    if (event.target.value.length === 1) {
      if (event.target.value >= '2' && event.target.value <= '9') {
        const newVal = [0, event.target.value, '/'].join('');
        this.myAccountForm.controls['birthdate'].setValue(newVal);
      }
    } else if (
      event.key !== 'Backspace' &&
      event.which !== 191 &&
      event.target.value.length === 2 &&
      current[1] !== '/'
    ) {
      const newVal = event.target.value + '/';
      this.myAccountForm.controls['birthdate'].setValue(newVal);
    } else if (event.target.value.length === 2 && event.which === 191) {
      const newVal = event.target.value + '/';
      this.myAccountForm.controls['birthdate'].setValue(newVal);
    } else if (event.target.value.length === 3 && current[2] !== '/') {
      const newVal =
        event.target.value.substr(0, 2) + '/' + event.target.value.substr(2);
      this.myAccountForm.controls['birthdate'].setValue(newVal);
    }
  }

  async onUpdateAccount($event: MouseEvent) {
    $event.preventDefault();
    this.canShowErrors = true;
    if (this.myAccountForm.valid) {
      this.isLoading = true;
      const request: CustomerPutRequest = {
        username: this.userDetails.userName || '',
        email: this.userDetails.email || '',
        firstName: this.myAccountForm.get('firstName')?.value || '',
        lastName: this.myAccountForm.get('lastName')?.value || '',
        primaryPhone:
          formatFromPhone(this.myAccountForm.get('phone')?.value) || null,
        dateOfBirth: this.myAccountForm.get('birthdate')?.value || '',
        emailPreference:
          this.myAccountForm.get('emailPreference')?.value || false,
        address: {
          postalCode: this.myAccountForm.get('zip')?.value || ''
        }
      };

      if (this.myAccountForm.get('emailPreference')?.value) {
        this.analyticsService.logGaEvent({
          event: 'opt_in_email'
        });
      } else {
        this.analyticsService.logGaEvent({
          event: 'opt_out_email'
        });
      }

      const userDetails = await this.userAccountService.updateCustomer(request);
      if (userDetails !== null) {
        this.notificationService.showSuccess('Account has been updated.');
      }
      this.isLoading = false;
    } else {
      this.notificationService.showError(
        'Please correct the invalid fields before saving.'
      );
      this.scrollToFirstInvalidControl('my-account-form', 60);
    }
  }

  changeMyPassword() {
    this.modalService.open(this.changeMyPasswordModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  async logout($event: MouseEvent) {
    $event.preventDefault();
    this.deleteAuthCookies();
    this.authService.logout(true).then(async () => {
      // Some issues, it's not coming in success block
    });
    this.analyticsService.logGaEvent({
      event: 'logout'
    });
    this.analyticsService.logGaEvent({
      user_id: null
    });
  }

  private deleteAuthCookies() {
    this.cookieService.delete(this.PING_AUTH_COOKIE, '/', '.wingstop.com');
    this.cookieService.delete(
      this.PING_AUTH_PROFILE_COOKIE,
      '/',
      '.wingstop.com'
    );
  }
  openDeleteAccountModal() {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  async deleteAccount() {
    this.deleteAccountLoading = true;
    this.userAccountService.deleteAccount().then(async () => {
      this.deleteAccountLoading = false;
      this.closeModal();
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      firstName: [
        this.initialValues?.firstName ?? '',
        {
          validators: [
            Validators.required,
            Validators.pattern(RegexPatterns.firstName)
          ]
        }
      ],
      lastName: [
        this._initialValues?.lastName ?? '',
        {
          validators: [
            Validators.required,
            Validators.pattern(RegexPatterns.lastName)
          ]
        }
      ],
      email: [
        this._initialValues?.email ?? '',
        {
          validators: [Validators.required, emailValidator()]
        }
      ],
      zip: [
        this._initialValues?.zip ?? '',
        {
          validators: [zipValidator()]
        }
      ],
      phone: [
        this._initialValues?.phone ?? '',
        {
          validators: [phoneValidator()]
        }
      ],
      birthdate: [
        this._initialValues?.birthdate ?? '',
        {
          validators: [birthDateValidator()]
        }
      ],
      emailPreference: [this._initialValues?.emailPreference ?? false]
    });
  }

  private async setUserDetails() {
    const userDetails = await this.userAccountService.getUserAccount();
    this.userDetails = userDetails ?? ({} as Customer);
    this.initForm(this.userDetails);
    this.isLoading = false;
  }

  private initForm(userDetails?: Customer) {
    const firstName =
      this._initialValues.firstName ?? userDetails?.firstName ?? '';
    const lastName =
      this._initialValues.lastName ?? userDetails?.lastName ?? '';
    const email = this._initialValues.email ?? userDetails?.email ?? '';
    const zip =
      this._initialValues.zip ?? userDetails?.address?.postalCode ?? '';
    const phone = this._initialValues.phone ?? userDetails?.primaryPhone ?? '';
    const birthdate =
      this._initialValues.birthdate ?? userDetails?.dateOfBirth ?? '';
    const emailPreference =
      this._initialValues.emailPreference ??
      userDetails?.emailPreference ??
      false;

    this.myAccountForm.patchValue({
      firstName,
      lastName,
      email,
      zip,
      phone,
      birthdate,
      emailPreference
    });

    this.myAccountForm.get('email')?.disable();
    if (birthdate !== '') {
      this.myAccountForm.get('birthdate')?.disable();
    }

  }

  private scrollToFirstInvalidControl(
    formId: string,
    extraOffset: number
  ): void {
    const formElement = this._document
      .getElementById(formId)
      ?.querySelector('form');

    if (formElement) {
      const firstErrorElement = formElement.querySelector('.ng-invalid');
      if (firstErrorElement) {
        const headerOffset = 45 + extraOffset;
        const elementPosition =
          firstErrorElement?.getBoundingClientRect().top || 0;
        const offsetPosition =
          elementPosition + this._window.scrollY - headerOffset;
        this._window.scrollTo({
          behavior: 'smooth',
          top: offsetPosition
        });
      }
    }
  }
}
