import { State, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AlertModel } from '@wingstop/models/alert.model';
import { Basket } from '@wingstop/models/basket.model';
import { BasketChoice } from '@wingstop/models/basket/basket-choice.model';
import { BasketProduct } from '@wingstop/models/basket/basket-product.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { Location } from '@wingstop/models/location/location.model';
import { Menu } from '@wingstop/models/menu.model';
import { PaymentProviders } from '@wingstop/models/order/fiserv-payments.model';
import { TimeWanted } from '@wingstop/models/time-wanted.model';
import { ApiService } from '@wingstop/services/api.service';
import { UserIdentityService } from '@wingstop/services/user-identity-service';
import { IAppStore } from '@wingstop/store/app-store';

import moment from 'moment-mini';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';
@Injectable()
export class AppStateActions {
  static SET_LOCATION: string = 'SET_LOCATION';
  static SET_ERRORS: string = 'SET_ERRORS';
  static SET_MENU: string = 'SET_MENU';
  static SET_BASKET: string = 'SET_BASKET';
  static AUTHENTICATE: string = 'AUTHENTICATE';
  static RECENT: string = 'RECENT';
  static USER_FAVORITE_LOCATIONS: string = 'USER_FAVORITE_LOCATIONS';
  static USER_CONTACT_DETAILS: string = 'USER_CONTACT_DETAILS';
  static OPEN_ALERT_MODAL: string = 'OPEN_ALERT_MODAL';
  static USER_LOGGED_OUT: string = 'USER_LOGGED_OUT';
  static SET_REDIRECT: string = 'SET_REDIRECT';
  static SET_CMS_OFFER: string = 'SET_CMS_OFFER';
  static SET_CMS_OFFER_REDEEM_CODE: string = 'SET_CMS_OFFER_REDEEM_CODE';
  static SET_CMS_OFFER_TIMESTAMP = 'SET_CMS_OFFER_TIMESTAMP';
  static SET_USER_FIRST_THREE_MONTHS = 'SET_USER_FIRST_THREE_MONTHS';
  static SET_IS_DIGITAL_MENU = 'SET_IS_DIGITAL_MENU';
  static SET_PAYMENT_PROVIDER = 'SET_PAYMENT_PROVIDER';
  static SET_FISERV_USER = 'SET_FISERV_USER';
  static SET_FISERV_SESSION = 'SET_FISERV_SESSION';
  static SET_COKE_FREESTYLE = 'SET_COKE_FREESTYLE';
  static SET_SHOW_APP_BANNER = 'SET_SHOW_APP_BANNER';
  static SET_APP_BANNER_CLOSED_BY_USER = 'SET_APP_BANNER_CLOSED_BY_USER';
  static SET_USER_LOCALE = 'SET_USER_LOCALE';
  static SET_S3_SEO_METADATA = 'SET_S3_SEO_METADATA';
  static SET_PILOT_PROGRAM_FEATURE_FLAGS = 'SET_PILOT_PROGRAM_FEATURE_FLAGS';
  static SET_PILOT_PROGRAM_USER = 'SET_PILOT_PROGRAM_USER';
  static SET_LAST_FORCE_LOGOUT_DATE = 'SET_LAST_FORCE_LOGOUT_DATE';

  constructor(
    private api: ApiService,
    private userIdentityService: UserIdentityService,
    protected store: Store<IAppStore | any>,
    protected state: State<IAppStore | any>
  ) { }

  // Get a location
  // If we get a resoonse without an id then return null for the location
  async getLocation(
    id: string | number,
    update = true
  ): Promise<Location | any> {
    return this.api.location(id).then(async (l) => {
      if (update && l?.id) {
        return this.setLocation(l);
      }
      return l?.id ? l : null;
    });
  }

  async setLocation(location: Location | number | string) {
    if (typeof location === 'number' || typeof location === 'string') {
      location = await this.api.location(location);
    }
    await this.getMenu(location);
    // we get the payment provider when we select a new store
    // currently disabling WWT/Fiserv payment provider
    this.store.dispatch({
      type: AppStateActions.SET_PAYMENT_PROVIDER,
      payload:
        location &&
          location.nomnom &&
          location.nomnom.primary_pci_provider &&
          location.nomnom.primary_pci_provider !== PaymentProviders.WWT_FISERV
          ? location.nomnom.primary_pci_provider
          : PaymentProviders.PCI_PROXY,
    });
    this.setLocationAttributes(location);
    const obj = {
      type: AppStateActions.SET_LOCATION,
      payload: location,
    };
    this.store.dispatch(obj);
    return obj;
  }

  getMenu(location: Location, update = true): Promise<Menu | any> {
    const isDigitalMenu = this.state.getValue().appState.isDigitalMenu;
    return this.api
      .menu(location, isDigitalMenu)
      .then(async (data) => {
        if (update) {
          return this.setMenu(data);
        }
        return data;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async setMenu(menu: Menu) {
    const obj = {
      type: AppStateActions.SET_MENU,
      payload: menu,
    };
    this.store.dispatch(obj);
    return obj;
  }

  async refreshAuthentication() {
    if (this.state.getValue().appState.authentication) {
      // Attempt to load the contact details
      this.getContactDetails().catch(async (e) => {
        await this.clearAuthentication();
      });
    }
  }

  async clearAuthentication() {
    if (this.state.getValue().appState.authentication) {
      await this.api
        .logout(this.state.getValue().appState.authentication)
        .catch((e) => { });

      // If we have ping auth details then we need to revoke the token
      if (
        this.state.getValue().appState.authentication.nomnom?.ping
          ?.refresh_token
      ) {
        await this.api
          .revokePingToken(this.state.getValue().appState.authentication)
          .catch((e) => { });
      }
    }
    this.store.dispatch({
      type: AppStateActions.SET_FISERV_USER,
      payload: null,
    });
    this.store.dispatch({
      type: AppStateActions.SET_FISERV_SESSION,
      payload: null,
    });
    const authPayload = this.store.dispatch({
      type: AppStateActions.AUTHENTICATE,
      payload: null,
    });

    // If we have a basket currently, we need to add all the products to it..
    if (
      this.state.getValue().appState.basket &&
      Array.isArray(this.state.getValue().appState.basket.products)
    ) {
      // Swap product id with basket id for update
      const existingProducts = this.state
        .getValue()
        .appState.basket.products.map((product: BasketProduct) => {
          let p = new BasketProduct({
            quantity: Number(product.quantity),
            productid: product.productId,
          });
          // Strip unnecessary properties out of the basket choice
          p.choices = product.choices.map((c) => {
            return new BasketChoice({
              choiceid: c.choiceid,
              quantity: c.quantity,
            });
          });
          return p;
        });
      // Update coupon value once basket has been created
      const existingCoupon = this.state.getValue().appState.basket.coupon;
      // Create a new basket at the location
      await this.createBasket(
        this.state.getValue().appState.basket.vendorid,
        this.state.getValue().appState.basket.timewanted,
        this.state.getValue().appState.basket.deliverymode,
        this.state.getValue().appState.basket.deliveryaddress
      );
    }

    return authPayload;
  }

  clearBasket() {
    return this.store.dispatch({
      type: AppStateActions.SET_BASKET,
      payload: null,
    });
  }

  convertItemsNotTransferred(data: any) {
    let invalids: any[] = [];
    Object.keys(data).forEach((key) => {
      const num = parseFloat(key);
      if (!Number.isNaN(num) && num * 1 === num) {
        invalids.push(data[key]);
      }
    });
    return invalids;
  }

  async asyncForEach(array: any[], callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async createBasket(location: Location | number): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted: TimeWanted | moment.Moment
  ): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted: TimeWanted | moment.Moment,
    mode: string
  ): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted: TimeWanted | moment.Moment,
    mode: string,
    address: DeliveryAddress
  ): Promise<any>;
  async createBasket(
    location: Location | number,
    timeWanted?: TimeWanted | moment.Moment,
    mode?: string,
    address?: DeliveryAddress
  ): Promise<any> {
    await this.setLocation(location);
    return this.api
      .createBasket(location, this.state.getValue().appState.authentication)
      .then(async (data) => {
        let dispatch = this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });

        if (!address && !mode) {
          // If no address provided and no mode return the basket
          return dispatch;
        } else if (!address && mode) {
          // If no address, but a mode, set the mode
          return this.setDeliveryMode(mode);
        } else if (address && mode) {
          // We have an address AND a mode
          if (mode === Basket.MODE_DISPATCH) {
            return this.makeDispatch(address);
          }
        }
        return dispatch;
      })
      .catch((error: any) => {
        console.log('error creating basket: ', error);
        let dispatch = this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: null,
        });
        this.errors(error.error || error);
        throw error.error || error;
      });
  }

  async refreshBasket() {
    if (this.state.getValue().appState.basket != null) {
      await this.getBasket();
      if (
        this.state.getValue().appState.basket != null &&
        this.state.getValue().appState.basket.vendorid
      ) {
        await this.getLocation(this.state.getValue().appState.basket.vendorid);
      }
    }
  }
  async getBasket() {
    return this.api
      .getBasket(this.state.getValue().appState.basket)
      .then((data) => {
        const obj = { type: AppStateActions.SET_BASKET, payload: data };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        // We couldn't find the basket or something else went horribly wrong
        // And since we couldn't find it, we need to clear it so no one
        // is stuck in a state where they can't order
        return this.clearBasket();
      });
  }

  async makeDispatch(address: DeliveryAddress) {
    return this.setDispatchAddress(address).catch((error: any) => {
      this.errors(error.error);
      throw error.error;
    });
  }

  async _setDeliveryMode(mode: string, basket: Basket = null) {
    if (!basket) {
      basket = this.state.getValue().appState.basket;
    }
    return this.api.deliveryMode(basket, mode);
  }

  async setDeliveryMode(mode: string) {
    return this._setDeliveryMode(mode)
      .then(async (data) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  async _setDispatchAddress(address: DeliveryAddress, basket: Basket = null) {
    if (address && address.id) {
      address.id = 0;
    }
    if (!basket) {
      basket = this.state.getValue().appState.basket;
    }
    return this.api.dispatchAddress(basket, address);
  }

  async setDispatchAddress(address: DeliveryAddress) {
    return this._setDispatchAddress(address)
      .then((data) => {
        return this.store.dispatch({
          type: AppStateActions.SET_BASKET,
          payload: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
        this.errors(error.error);
        throw error.error;
      });
  }

  async getContactDetails() {
    return this.api
      .contactDetails(this.state.getValue().appState.authentication)
      .then((data) => {
        const obj = {
          type: AppStateActions.USER_CONTACT_DETAILS,
          payload: data,
        };
        this.store.dispatch(obj);
        return obj;
      })
      .catch((error: any) => {
        this.errors(error.error);
        throw error.error;
      });
  }

  setRedirect(redirect: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_REDIRECT,
      payload: redirect,
    });
  }

  errors(errors: any) {
    return this.store.dispatch({
      type: AppStateActions.SET_ERRORS,
      payload: errors,
    });
  }

  clearErrors() {
    return this.errors(null);
  }

  openAlertModalWith(
    title: string,
    description: string | string[],
    buttonLabel = 'OK',
    subTitle?: string,
    closeButton?: string,
    hasCloseButton?: boolean,
    hasFormField?: boolean,
    callback?: (result: string) => void,
    image?: string,
    topRightCloseButton?: boolean,
    name?: string,
    buttonAriaLabel?: string
  ) {
    const data: AlertModel = new AlertModel({
      title: title ? title : 'Alert',
      subTitle,
      closeButton,
      hasCloseButton,
      hasFormField,
      description: Array.isArray(description) ? description : [description],
      button: buttonLabel,
      buttonAriaLabel,
      callback,
      image,
      topRightCloseButton,
      name,
    });

    // Chrome-only bug, have to create the modal with a timeout
    // reference: https://github.com/angular/angular/issues/17572
    return setTimeout(() => {
      return this.openAlertModal(data);
    });
  }

  openAlertModal(data: AlertModel) {
    return this.store.dispatch({
      type: AppStateActions.OPEN_ALERT_MODAL,
      payload: data,
    });
  }

  async userLoggedOut() {
    this.store.dispatch({
      type: AppStateActions.RECENT,
      payload: [],
    });
    this.store.dispatch({
      type: AppStateActions.USER_FAVORITE_LOCATIONS,
      payload: [],
    });
    await this.clearAuthentication();
    return this.store.dispatch({
      type: AppStateActions.USER_LOGGED_OUT,
    });
  }

  setCmsOfferToUse(offer: CMSPromoOffer) {
    return this.store.dispatch({
      type: AppStateActions.SET_CMS_OFFER,
      payload: offer,
    });
  }

  setCmsOfferRedeemCode(redeemCode: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_CMS_OFFER_REDEEM_CODE,
      payload: redeemCode,
    });
  }

  setCmsOfferTimestamp(timestamp: number) {
    return this.store.dispatch({
      type: AppStateActions.SET_CMS_OFFER_TIMESTAMP,
      payload: timestamp,
    });
  }

  async optOutDoorDash(emailAddress: string) {
    return this.api
      .optOutDoorDash(emailAddress)
      .then((data) => { })
      .catch((error: any) => { });
  }

  setIsUserFirstThreeMonths() {
    const isWithinFirstThreeMonths =
      this.userIdentityService.isWithinFirstThreeMonths();
    return this.store.dispatch({
      type: AppStateActions.SET_USER_FIRST_THREE_MONTHS,
      payload: isWithinFirstThreeMonths,
    });
  }

  setIsDigitalMenu(isDigitalMenu: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_IS_DIGITAL_MENU,
      payload: isDigitalMenu,
    });
  }

  setLocationAttributes(location: Location) {
    if (location) {
      this.store.dispatch({
        type: AppStateActions.SET_COKE_FREESTYLE,
        payload: location.hasCokeFreestyle(),
      });
    }
  }

  setShowAppBanner(showAppBanner: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_SHOW_APP_BANNER,
      payload: showAppBanner,
    });
  }

  setAppBannerClosedByUser(appBannerClosedByUser: boolean) {
    return this.store.dispatch({
      type: AppStateActions.SET_APP_BANNER_CLOSED_BY_USER,
      payload: appBannerClosedByUser,
    });
  }

  setUserLocale(userLocale: string) {
    return this.store.dispatch({
      type: AppStateActions.SET_USER_LOCALE,
      payload: userLocale,
    });
  }

  setS3SeoMetadata() {
    return this.api.getS3SeoMetadata().then((seoMetadata) => {
      if (seoMetadata) {
        return this.store.dispatch({
          type: AppStateActions.SET_S3_SEO_METADATA,
          payload: seoMetadata,
        });
      }
    });
  }

  setPilotProgramUser(pilotProgramUser: IPilotProgramUser) {
    return this.store.dispatch({
      type: AppStateActions.SET_PILOT_PROGRAM_USER,
      payload: pilotProgramUser,
    });
  }
}
