import * as t from 'io-ts';

/* #region Flavor */
export const FlavorDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        isActive: t.boolean,
        name: t.string,
        description: t.string,
        imageUrl: t.string,
        heatScale: t.number,
        isNew: t.boolean,
        isPopular: t.boolean,
        isLimitedTime: t.boolean,
        isDry: t.boolean,
        sortOrder: t.number
      },
      'FlavorRequired'
    ),
    t.partial({}, 'FlavorOptional')
  ],
  'Flavor'
);

export type FlavorDto = t.TypeOf<typeof FlavorDto>;
export type Flavor = FlavorDto;
/* #endregion */

/* #region FlavorsResponse */
export const FlavorsResponse = t.intersection(
  [
    t.type(
      {
        flavors: t.array(FlavorDto)
      },
      'FlavorsResponseRequired'
    ),
    t.partial({}, 'FlavorsResponseOptional')
  ],
  'FlavorsResponse'
);
export type FlavorsResponse = t.TypeOf<typeof FlavorsResponse>;
/* #endregion */
