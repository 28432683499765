<ng-template #flavorModal>
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-header__header">
        <div class="modal-header__icon-wrapper">
          <a
            data-testid="close-modal"
            wriFocusOutline
            (click)="onModalClose($event)"
            href="#"
            [attr.aria-label]="'close modal'"
          >
            <wri-icon
              class="modal-header__cross_icon"
              icon="wri-cross"
            ></wri-icon>
          </a>
        </div>
      </div>
      <span data-testid="modal-header" class="modal-header__find-your-flavor"
        >Find Your Flavor</span
      >
    </div>
    <div class="modal-body">
      <wri-flavor-modal-category [modifiers]="limited" [flavorHeading]="flavorSpiceHeading.LIMITED_TIME" [flavorColor]="flavorSpiceHeadingColors.LIMITED_TIME" [flavorType]="'limitedTime'">
      </wri-flavor-modal-category>
      <wri-flavor-modal-category [modifiers]="hot" [flavorHeading]="flavorSpiceHeading.HOT" [flavorColor]="flavorSpiceHeadingColors.HOT" [flavorType]="'hot'">
      </wri-flavor-modal-category>
      <wri-flavor-modal-category [modifiers]="medium" [flavorHeading]="flavorSpiceHeading.MEDIUM" [flavorColor]="flavorSpiceHeadingColors.MEDIUM" [flavorType]="'medium'">
      </wri-flavor-modal-category>
      <wri-flavor-modal-category [modifiers]="mild" [flavorHeading]="flavorSpiceHeading.MILD" [flavorColor]="flavorSpiceHeadingColors.MILD" [flavorType]="'mild'">
      </wri-flavor-modal-category>
    </div>
  </div>
</ng-template>
