<div class="modal-content">
  <div class="modal-header">
    <span toastContainer class="inline-toast"></span>
    <div class="modal-header__header">
      <div class="modal-header__icon-wrapper">
        <a
          data-testid="close-modal"
          wriFocusOutline
          (click)="closeModal()"
          [attr.aria-label]="'close modal'"
        >
          <wri-icon
            class="modal-header__cross_icon"
            icon="wri-cross"
          ></wri-icon>
        </a>
      </div>
    </div>
    <span data-testid="modifier-modal-header" class="modal-header__customize"
      >Customize</span
    >
  </div>
  <div class="modal-body">
    <ng-container
      *ngFor="let modifierGroup of data | sort : 'asc' : 'sortOrder'"
    >
      <div
        [attr.data-testid]="modifierGroup.id | prepend : 'group-name-'"
        class="group-name"
        wriInteractionStudio="data-chainmodifierid"
        [interactionStudioId]="modifierGroup.id"
      >
        <span class="mod-group-header-name">{{ modifierGroup.name }}</span>
        <wri-badge
          *ngIf="modifierGroup.required"
          [icon]="areSelectionsValid(modifierGroup) ? 'wri-check' : null"
          [type]="areSelectionsValid(modifierGroup) ? 'success' : 'warn'"
          [label]="areSelectionsValid(modifierGroup) ? 'Done' : 'Required'"
          class="mod-group-header-badge"
        ></wri-badge>
      </div>
      <span
        wriFocusOutline
        [attr.data-testid]="
          modifierGroup.id | prepend : 'modifier-modal-selection-msg-'
        "
        class="modifier-modal-selection-msg"
        aria-live="polite"
      >
        {{ modifierGroup.message }}:
        <ng-container
          *ngIf="
            checkIfItemsSelected(itemModifierGroups, modifierGroup);
            then hasModifiersSelected;
            else noModifiersSelected
          "
        ></ng-container>
        <ng-template #noModifiersSelected> None selected </ng-template>
        <ng-template #hasModifiersSelected>
          <ng-container
            *ngFor="let itemModifierGroup of itemModifierGroups; index as i"
          >
            <ng-container
              *ngIf="itemModifierGroup.modifierGroupId === modifierGroup.id"
            >
              <ng-container
                *ngFor="
                  let itemModifier of itemModifierGroup.modifiers;
                  index as j
                "
                ><ng-container *ngIf="j > 0">, </ng-container>
                {{ itemModifier.name.trim() }}</ng-container
              >
            </ng-container>
          </ng-container>
        </ng-template>
      </span>
      <ng-container *ngIf="modifierGroup.type === 'flavor'">
        <div class="flavor-group">
          <wri-choose-flavor-spice-group
            [flavors]="limited"
            [heading]="flavorSpiceHeading.LIMITED_TIME"
            [iconColor]="flavorSpiceHeadingColors.LIMITED_TIME"
            [type]="'limitedTime'"
            [limited]="limited"
            [hot]="hot"
            [medium]="medium"
            [mild]="mild"
            [editFlavor]="editFlavor"
            (valuesChanged)="handleSelectedValues($event, modifierGroup.id)"
          >
          </wri-choose-flavor-spice-group>

          <wri-choose-flavor-spice-group
            [flavors]="hot"
            [heading]="flavorSpiceHeading.HOT"
            [iconColor]="flavorSpiceHeadingColors.HOT"
            [type]="'hot'"
            [limited]="limited"
            [hot]="hot"
            [medium]="medium"
            [mild]="mild"
            [editFlavor]="editFlavor"
            (valuesChanged)="handleSelectedValues($event, modifierGroup.id)"
          >
          </wri-choose-flavor-spice-group>

          <wri-choose-flavor-spice-group
            [flavors]="medium"
            [heading]="flavorSpiceHeading.MEDIUM"
            [iconColor]="flavorSpiceHeadingColors.MEDIUM"
            [type]="'medium'"
            [limited]="limited"
            [hot]="hot"
            [medium]="medium"
            [mild]="mild"
            [editFlavor]="editFlavor"
            (valuesChanged)="handleSelectedValues($event, modifierGroup.id)"
          >
          </wri-choose-flavor-spice-group>

          <wri-choose-flavor-spice-group
            [flavors]="mild"
            [heading]="flavorSpiceHeading.MILD"
            [iconColor]="flavorSpiceHeadingColors.MILD"
            [type]="'mild'"
            [limited]="limited"
            [hot]="hot"
            [medium]="medium"
            [mild]="mild"
            [editFlavor]="editFlavor"
            (valuesChanged)="handleSelectedValues($event, modifierGroup.id)"
          >
          </wri-choose-flavor-spice-group>
        </div>
      </ng-container>
      <ng-container *ngIf="modifierGroup.type !== 'flavor'">
        <ng-container
          *ngFor="
            let modifierGroupElement of filterModGroupElements(
              modifierGroup.modifierGroupElements
            )
          "
        >
          <hr class="wri-divider" aria-hidden="true" />
          <ng-container
            *ngIf="
              modifierGroupElement.modifier !== null;
              then isModifierElement;
              else isItemElement
            "
          ></ng-container>
          <ng-template #isModifierElement>
            <label
              class="modifier-elements-wrapper"
              wriInteractionStudio="data-chainoptionid"
              [interactionStudioId]="modifierGroupElement.modifier.id"
              [for]="'chk-' + modifierGroupElement.modifier.id"
            >
              <div
                *ngIf="
                  modifierGroupElement.modifier.images &&
                  modifierGroupElement.modifier.images.length > 0
                "
                class="mod-image-container"
              >
                <img
                  data-testid="modifier-img"
                  class="modifier-img"
                  aria-live="polite"
                  alt="{{ modifierGroupElement.modifier.name }}"
                  srcset="{{ modifierGroupElement.modifier?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
                  src="{{
                    modifierGroupElement.modifier?.images[0]?.uri
                      | imageResolution
                        : 'src'
                        : 'pdp-modifiers'
                        : '/assets/images/logo-green-product.png'
                  }}"
                  onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
                  role="img"
                />
              </div>
              <ng-container
                *ngIf="
                  modifierGroup.maxSelectionsAllowed > 1;
                  then isMultiselectCheckbox;
                  else isSingleselectRadio
                "
              ></ng-container>
            </label>
          </ng-template>
          <ng-template #isItemElement>
            <label
              class="modifier-elements-wrapper"
              wriInteractionStudio="data-chainoptionid"
              [interactionStudioId]="modifierGroupElement.item.id"
              [for]="'chk-' + modifierGroupElement.item.id"
            >
              <div
                *ngIf="
                  modifierGroupElement.item.images &&
                  modifierGroupElement.item.images.length > 0
                "
                class="mod-image-container"
              >
                <img
                  data-testid="modifier-img"
                  class="modifier-img"
                  aria-live="polite"
                  alt="{{ modifierGroupElement.item.name }}"
                  srcset="{{ modifierGroupElement.item?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
                  src="{{
                    modifierGroupElement.item?.images[0]?.uri
                      | imageResolution
                        : 'src'
                        : 'pdp-modifiers'
                        : '/assets/images/logo-green-product.png'
                  }}"
                  onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
                  role="img"
                />
              </div>
              <ng-container
                *ngIf="
                  modifierGroup.maxSelectionsAllowed > 1;
                  then isItemMultiSelectCheckbox;
                  else isItemSingleselectRadio
                "
              ></ng-container>
            </label>
          </ng-template>
          <ng-template #isItemMultiSelectCheckbox>
            <div
              class="mod-elements-details checkbox-control-container"
              [attr.data-disabled]="
                modifierGroupElement.item?.outOfStock ||
                isItemDisabled(
                  modifierGroup.id,
                  modifierGroupElement.item.id,
                  modifierGroup.maxSelectionsAllowed
                )
              "
              [ngClass]="
                modifierGroupElement.item.price ||
                modifierGroupElement.item?.minCalories ||
                modifierGroupElement.item?.maxCalories ||
                modifierGroupElement.item?.outOfStock
                  ? 'checkbox-control-container-top'
                  : ''
              "
            >
              <div>
                <span
                  class="mod-name"
                  [ngClass]="
                    modifierGroupElement.item.price ||
                    modifierGroupElement.item?.minCalories ||
                    modifierGroupElement.item?.maxCalories ||
                    modifierGroupElement.item?.outOfStock
                      ? 'mod-name-top'
                      : ''
                  "
                  data-testid="mod-name"
                  [ngStyle]="
                    modifierGroupElement.item?.outOfStock && {
                      color: '#A1A1A1'
                    }
                  "
                >
                  {{ modifierGroupElement.item.name }}
                </span>
                <ng-container
                  *ngIf="
                    modifierGroupElement.item?.outOfStock;
                    else modalMetaData
                  "
                >
                  <wri-unavailable-item></wri-unavailable-item>
                </ng-container>
                <ng-template #modalMetaData>
                  <div class="mod-meta-text">
                    <span
                      *ngIf="modifierGroupElement.item.price"
                      data-testid="mod-price"
                    >
                      +{{ modifierGroupElement.item.price | currency
                      }}<span
                        *ngIf="returnCalorieRange(modifierGroupElement.item)"
                        >,
                      </span>
                    </span>
                    <ng-container
                      *ngIf="
                        modifierGroupElement.item?.minCalories !== undefined
                      "
                    >
                      <ng-container
                        *ngIf="
                          !(
                            modifierGroupElement.item.minCalories === null &&
                            modifierGroupElement.item.maxCalories === null
                          )
                        "
                      >
                        <span data-testid="mod-calories">{{
                          returnCalorieRange(modifierGroupElement.item)
                        }}</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
              <input
                [id]="'chk-' + modifierGroupElement.item.id"
                [attr.data-testid]="
                  modifierGroupElement.item.id | prepend : 'input-for-'
                "
                class="mod-elements-checkbox-input"
                tabindex="0"
                [checked]="
                  isItemChecked(modifierGroup.id, modifierGroupElement.item.id)
                "
                [disabled]="
                  modifierGroupElement.item?.outOfStock ||
                  isItemDisabled(
                    modifierGroup.id,
                    modifierGroupElement.item.id,
                    modifierGroup.maxSelectionsAllowed
                  )
                "
                (change)="
                  changeModifierEvent(
                    itemModifierGroups,
                    $event.target.checked,
                    'multiple',
                    modifierGroup.id,
                    modifierGroupElement.item
                  )
                "
                type="checkbox"
                [attr.aria-label]="modifierGroupElement.item.name"
              />
            </div>
          </ng-template>

          <ng-template #isItemSingleselectRadio>
            <div
              class="mod-elements-details radio-control-container"
              [attr.data-disabled]="modifierGroupElement.item?.outOfStock"
            >
              <div class="mod-elements-details-text">
                <span
                  class="mod-name"
                  [ngClass]="
                    modifierGroupElement.item.price ||
                    modifierGroupElement.item?.minCalories ||
                    modifierGroupElement.item?.maxCalories
                      ? 'mod-name-top'
                      : ''
                  "
                  data-testid="mod-name"
                >
                  {{ modifierGroupElement.item.name }}
                </span>
                <ng-container
                  *ngIf="
                    modifierGroupElement.item?.outOfStock;
                    else modalMetaData
                  "
                >
                  <wri-unavailable-item></wri-unavailable-item>
                </ng-container>
                <ng-template #modalMetaData>
                  <div class="mod-meta-text">
                    <span
                      *ngIf="modifierGroupElement.item.price"
                      data-testid="mod-price"
                    >
                      +{{ modifierGroupElement.item.price | currency
                      }}<span
                        *ngIf="returnCalorieRange(modifierGroupElement.item)"
                        >,
                      </span>
                    </span>
                    <ng-container
                      *ngIf="
                        modifierGroupElement.item?.minCalories !== undefined
                      "
                    >
                      <ng-container
                        *ngIf="
                          !(
                            modifierGroupElement.item.minCalories === null &&
                            modifierGroupElement.item.maxCalories === null
                          )
                        "
                      >
                        <span data-testid="mod-calories">{{
                          returnCalorieRange(modifierGroupElement.item)
                        }}</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
              <input
                class="mod-elements-radio-input"
                [name]="'mod-elements-' + modifierGroupElement.item.id"
                [id]="'chk-' + modifierGroupElement.item.id"
                [attr.data-testid]="
                  modifierGroupElement.item.id | prepend : 'input-for-'
                "
                tabindex="0"
                type="checkbox"
                [checked]="
                  isItemChecked(modifierGroup.id, modifierGroupElement.item.id)
                "
                (change)="
                  changeModifierEvent(
                    itemModifierGroups,
                    $event.target.checked,
                    'single',
                    modifierGroup.id,
                    modifierGroupElement.item
                  )
                "
                [attr.aria-label]="modifierGroupElement.item.name"
                [disabled]="modifierGroupElement.item?.outOfStock"
              />
            </div>
          </ng-template>

          <ng-template #isMultiselectCheckbox>
            <div
              class="mod-elements-details checkbox-control-container"
              [attr.data-disabled]="
                modifierGroupElement.modifier?.outOfStock ||
                isItemDisabled(
                  modifierGroup.id,
                  modifierGroupElement.modifier.id,
                  modifierGroup.maxSelectionsAllowed
                )
              "
              [ngClass]="
                modifierGroupElement.modifier.price ||
                modifierGroupElement.modifier?.minCalories ||
                modifierGroupElement.modifier?.maxCalories ||
                modifierGroupElement.modifier?.outOfStock
                  ? 'checkbox-control-container-top'
                  : ''
              "
            >
              <div>
                <span
                  class="mod-name"
                  [ngClass]="
                    modifierGroupElement.modifier.price ||
                    modifierGroupElement.modifier?.minCalories ||
                    modifierGroupElement.modifier?.maxCalories ||
                    modifierGroupElement.modifier?.outOfStock
                      ? 'mod-name-top'
                      : ''
                  "
                  data-testid="mod-name"
                  [ngStyle]="
                    modifierGroupElement.modifier?.outOfStock && {
                      color: '#A1A1A1'
                    }
                  "
                >
                  {{ modifierGroupElement.modifier.name }}
                </span>
                <ng-container
                  *ngIf="
                    modifierGroupElement.modifier?.outOfStock;
                    else modalMetaData
                  "
                >
                  <wri-unavailable-item></wri-unavailable-item>
                </ng-container>
                <ng-template #modalMetaData>
                  <div class="mod-meta-text">
                    <span
                      *ngIf="modifierGroupElement.modifier.price"
                      data-testid="mod-price"
                    >
                      +{{ modifierGroupElement.modifier.price | currency
                      }}<span
                        *ngIf="
                          returnCalorieRange(modifierGroupElement.modifier)
                        "
                        >,
                      </span>
                    </span>
                    <ng-container
                      *ngIf="
                        modifierGroupElement.modifier?.minCalories !== undefined
                      "
                    >
                      <ng-container
                        *ngIf="
                          !(
                            modifierGroupElement.modifier.minCalories ===
                              null &&
                            modifierGroupElement.modifier.maxCalories === null
                          )
                        "
                      >
                        <span data-testid="mod-calories">{{
                          returnCalorieRange(modifierGroupElement.modifier)
                        }}</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
              <input
                [id]="'chk-' + modifierGroupElement.modifier.id"
                [attr.data-testid]="
                  modifierGroupElement.modifier.id | prepend : 'input-for-'
                "
                class="mod-elements-checkbox-input"
                tabindex="0"
                [checked]="
                  isItemChecked(
                    modifierGroup.id,
                    modifierGroupElement.modifier.id
                  )
                "
                [disabled]="
                  modifierGroupElement.modifier?.outOfStock ||
                  isItemDisabled(
                    modifierGroup.id,
                    modifierGroupElement.modifier.id,
                    modifierGroup.maxSelectionsAllowed
                  )
                "
                (change)="
                  changeModifierEvent(
                    itemModifierGroups,
                    $event.target.checked,
                    'multiple',
                    modifierGroup.id,
                    modifierGroupElement.modifier
                  )
                "
                type="checkbox"
                [attr.aria-label]="modifierGroupElement.modifier.name"
              />
            </div>
          </ng-template>

          <ng-template #isSingleselectRadio>
            <div
              class="mod-elements-details radio-control-container"
              [attr.data-disabled]="modifierGroupElement.modifier?.outOfStock"
            >
              <div class="mod-elements-details-text">
                <span
                  class="mod-name"
                  [ngClass]="
                    modifierGroupElement.modifier.price ||
                    modifierGroupElement.modifier?.minCalories ||
                    modifierGroupElement.modifier?.maxCalories
                      ? 'mod-name-top'
                      : ''
                  "
                  data-testid="mod-name"
                >
                  {{ modifierGroupElement.modifier.name }}
                </span>
                <ng-container
                  *ngIf="
                    modifierGroupElement.modifier?.outOfStock;
                    else modalMetaData
                  "
                >
                  <wri-unavailable-item></wri-unavailable-item>
                </ng-container>
                <ng-template #modalMetaData>
                  <div class="mod-meta-text">
                    <span
                      *ngIf="modifierGroupElement.modifier.price"
                      data-testid="mod-price"
                    >
                      +{{ modifierGroupElement.modifier.price | currency
                      }}<span
                        *ngIf="
                          returnCalorieRange(modifierGroupElement.modifier)
                        "
                        >,
                      </span>
                    </span>
                    <ng-container
                      *ngIf="
                        modifierGroupElement.modifier?.minCalories !== undefined
                      "
                    >
                      <ng-container
                        *ngIf="
                          !(
                            modifierGroupElement.modifier.minCalories ===
                              null &&
                            modifierGroupElement.modifier.maxCalories === null
                          )
                        "
                      >
                        <span data-testid="mod-calories">{{
                          returnCalorieRange(modifierGroupElement.modifier)
                        }}</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
              <input
                class="mod-elements-radio-input"
                [name]="'mod-elements-' + modifierGroupElement.modifier.id"
                [id]="'chk-' + modifierGroupElement.modifier.id"
                [attr.data-testid]="
                  modifierGroupElement.modifier.id | prepend : 'input-for-'
                "
                tabindex="0"
                type="checkbox"
                [checked]="
                  isItemChecked(
                    modifierGroup.id,
                    modifierGroupElement.modifier.id
                  )
                "
                (change)="
                  changeModifierEvent(
                    itemModifierGroups,
                    $event.target.checked,
                    'single',
                    modifierGroup.id,
                    modifierGroupElement.modifier
                  )
                "
                [attr.aria-label]="modifierGroupElement.modifier.name"
                [disabled]="modifierGroupElement.modifier?.outOfStock"
              />
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer fixed-bottom">
    <button class="wri-btn wri-btn-tertiary" (click)="closeModal()">
      Cancel
    </button>
    <button
      class="wri-btn wri-btn-primary"
      (click)="applyModifierSelections()"
    >
      Apply
    </button>
  </div>
</div>
