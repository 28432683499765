import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { MenuModifier } from '../../../../ecomm/types/store-info.types';
import {
  FlavorSpiceHeading,
  FlavorSpiceHeadingColors
} from '../../../common/types/modifiers';

@Component({
  selector: 'wri-flavor-modal',
  templateUrl: './flavor-modal.component.html',
  styleUrls: ['./flavor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlavorModalComponent {
  @Input() limited: MenuModifier[] | undefined;
  @Input() hot: MenuModifier[] | undefined;
  @Input() medium: MenuModifier[] | undefined;
  @Input() mild: MenuModifier[] | undefined;
  readonly flavorSpiceHeading = FlavorSpiceHeading;
  readonly flavorSpiceHeadingColors = FlavorSpiceHeadingColors;

  @ViewChild('flavorModal') flavorModal!: TemplateRef<HTMLElement>;
  modalReference: NgbModalRef | undefined;

  constructor(private modalService: NgbModal) {}

  onModalClose(event: Event) {
    event.preventDefault();
    this.modalReference?.close();
  }

  public openFlavorModal() {
    this.modalReference = this.modalService.open(this.flavorModal, {
      windowClass: 'flavor-common-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down'
    });
  }
}
