import * as t from 'io-ts';

import { Cart, CartDto } from './cart.types';
import {
  EcommAPIError,
  ecommApiResponse,
  ecommWebSocketResponse,
  optional
} from './common.types';

/* #region OrderGuest */
export const OrderGuestDto = t.intersection(
  [
    t.type(
      {
        firstName: t.string,
        lastName: t.string,
        emailAddress: t.string,
        phoneNumber: t.string
      },
      'OrderGuestDtoRequired'
    ),
    t.partial({
      password: optional(t.string)
    }, 'OrderGuestDtoOptional')
  ],
  'OrderGuestDto'
);

export type OrderGuestDto = t.TypeOf<typeof OrderGuestDto>;
export type OrderGuest = OrderGuestDto;
/* #endregion */

/* #region OrderPayment */
export const OrderPaymentDto = t.intersection(
  [
    t.type(
      {
        billingMethod: t.string
      },
      'OrderPaymentDtoRequired'
    ),
    t.partial({}, 'OrderPaymentDtoOptional')
  ],
  'OrderPaymentDto'
);

export type OrderPaymentDto = t.TypeOf<typeof OrderPaymentDto>;
export type OrderPayment = OrderPaymentDto;
/* #endregion */

/* #region OrderLocation */
export const OrderLocationDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        name: t.string,
        number: t.string
      },
      'OrderLocationDtoRequired'
    ),
    t.partial(
      {
        pickupInstructions: optional(t.string)
      },
      'OrderLocationDtoOptional'
    )
  ],
  'OrderLocationDto'
);

export type OrderLocationDto = t.TypeOf<typeof OrderLocationDto>;
export type OrderLocation = OrderLocationDto;
/* #endregion */

/* #region OrderDelivery */
export const OrderDeliveryDto = t.intersection(
  [
    t.type({}, 'OrderDeliveryDtoRequired'),
    t.partial(
      {
        estimatedDeliveryTime: optional(t.string),
        deliveryTrackingUrl: optional(t.string)
      },
      'OrderDeliveryDtoOptional'
    )
  ],
  'OrderDeliveryDto'
);

export type OrderDeliveryDto = t.TypeOf<typeof OrderDeliveryDto>;
export type OrderDelivery = OrderDeliveryDto;
/* #endregion */

/* #region OrderCart */
export const OrderCartDto = CartDto;
export type OrderCartDto = CartDto;
export type OrderCart = Cart;
/* #endregion */

/* #region Order */
export const OrderSuccessDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        orderNumber: t.string,
        status: t.string,
        guest: OrderGuestDto,
        payment: OrderPaymentDto,
        location: OrderLocationDto,
        subtotal: t.number,
        totalTax: t.number,
        total: t.number,
        createdTimestamp: t.string,
        delivery: OrderDeliveryDto,
        cart: OrderCartDto
      },
      'OrderDtoRequired'
    ),
    t.partial(
      {
        cancelDeadline: optional(t.string)
      },
      'OrderDtoOptional'
    )
  ],
  'OrderDto'
);

export type OrderSuccessDto = t.TypeOf<typeof OrderSuccessDto>;
export type Order = Omit<
  OrderSuccessDto,
  'guest' | 'payment' | 'location' | 'delivery' | 'cart'
> & {
  guest: OrderGuest;
  payment: OrderPayment;
  location: OrderLocation;
  delivery: OrderDelivery;
  cart: OrderCart;
};
/* #endregion */

export const OrderPendingDto = t.intersection(
  [
    t.type({}, 'OrderPendingDtoRequired'),
    t.partial(
      {
        accessToken: t.string,
        idToken: t.string,
        refreshToken: t.string
      }, 'OrderPendingDtoOptional')
  ],
  'OrderPendingDto'
);

export type OrderPendingDto = t.TypeOf<typeof OrderPendingDto>;

export const OrderDto = t.union([
  OrderSuccessDto,
  OrderPendingDto
]);

export type OrderDto = t.TypeOf<typeof OrderDto>;

/* #region OrderResponse */
export const OrderSuccessResponse = ecommApiResponse(OrderSuccessDto);

export type OrderSuccessResponse = t.TypeOf<typeof OrderSuccessResponse>;
/* #endregion */

/* #region OrderResponse */
export const OrderResponse = ecommApiResponse(OrderDto);

export type OrderResponse = t.TypeOf<typeof OrderResponse>;
/* #endregion */

/* #region AccountCreationDuringCheckout */
export const AccountCreationDuringCheckoutDto = t.intersection(
  [
    t.type({}, 'AccountCreationDuringCheckoutDtoRequired'
    ),
    t.partial({
      data: OrderPendingDto,
      error: EcommAPIError
    }, 'AccountCreationDuringCheckoutDtoOptional')
  ],
  'AccountCreationDuringCheckoutDto'
);

export type AccountCreationDuringCheckoutDto = t.TypeOf<typeof AccountCreationDuringCheckoutDto>;
export type AccountCreationDuringCheckout = AccountCreationDuringCheckoutDto;
/* #endregion */

/* #region OrderSubmitRequestPayment */
export const OrderSubmitRequestAnonymousGiftCardPayment = t.intersection(
  [
    t.type(
      {
        type: t.string,
        cardNumber: t.string,
        securityCode: t.string,
        requestedAmount: t.number
      },
      'OrderSubmitRequestAnonymousGiftCardPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestAnonymousGiftCardPaymentOptional')
  ],
  'OrderSubmitRequestAnonymousGiftCardPayment'
);

export const OrderSubmitRequestVaultedGiftCardPayment = t.intersection(
  [
    t.type(
      {
        type: t.string,
        requestedAmount: t.number,
        vaultedGiftCardId: t.string
      },
      'OrderSubmitRequestVaultedGiftCardPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestVaultedGiftCardPaymentOptional')
  ],
  'OrderSubmitRequestVaultedGiftCardPayment'
);

export const OrderSubmitRequestAnonymousCreditCardPayment = t.intersection(
  [
    t.type(
      {
        type: t.string,
        tokenId: t.string
      },
      'OrderSubmitRequestAnonymousCreditCardPaymentRequired'
    ),
    t.partial(
      {
        requestedAmount: optional(t.number),
        clientSessionToken: optional(t.string)
      },
      'OrderSubmitRequestAnonymousCreditCardPaymentOptional'
    )
  ],
  'OrderSubmitRequestAnonymousCreditCardPayment'
);

export const OrderSubmitRequestVaultedCreditCardPayment = t.intersection(
  [
    t.type(
      {
        type: t.string,
        requestedAmount: t.number,
        customerId: t.string,
        vaultedAccountId: t.string
      },
      'OrderSubmitRequestVaultedCreditCardPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestVaultedCreditCardPaymentOptional')
  ],
  'OrderSubmitRequestVaultedCreditCardPayment'
);

export const OrderSubmitRequestApplePayPayment = t.intersection(
  [
    t.type(
      {
        type: t.literal('applePay'),
        requestedAmount: t.number,
        transactionIdentifier: t.string,
        publicKeyHash: t.string,
        ephemeralPublicKey: t.string,
        transactionId: t.string,
        version: t.string,
        data: t.string,
        signature: t.string,
        billingPostalCode: optional(t.string)
      },
      'OrderSubmitRequestApplePayPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestApplePayPaymentOptional')
  ],
  'OrderSubmitRequestApplePayPayment'
);

export const OrderSubmitRequestVenmoPayment = t.intersection(
  [
    t.type(
      {
        type: t.literal('venmo'),
        requestedAmount: t.number,
        nonce: t.string,
        source: t.string,
        dataCapture: t.intersection(
          [
            t.type(
              {
                rawData: t.string,
                dataEventId: t.string,
                captureTime: t.string
              },
              'OrderSubmitRequestVenmoPaymentDataCaptureRequired'
            ),
            t.partial({}, 'OrderSubmitRequestVenmoPaymentDataCaptureOptional')
          ],
          'OrderSubmitRequestVenmoPaymentDataCapture'
        ),
        dataDynamic: t.intersection(
          [
            t.type(
              {
                captureTime: t.string
              },
              'OrderSubmitRequestVenmoPaymentDataDynamicRequired'
            ),
            t.partial({}, 'OrderSubmitRequestVenmoPaymentDataDynamicOptional')
          ],
          'OrderSubmitRequestVenmoPaymentDataDynamic'
        )
      },
      'OrderSubmitRequestVenmoPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestVenmoPaymentOptional')
  ],
  'OrderSubmitRequestVenmoPayment'
);

export const OrderSubmitRequestPaypalPayment = t.intersection(
  [
    t.type(
      {
        type: t.literal('payPal'),
        requestedAmount: t.number,
        nonce: t.string,
        source: t.string,
        payerId: t.string,
        dataCapture: t.intersection(
          [
            t.type(
              {
                rawData: t.string,
                dataEventId: t.string,
                captureTime: t.string
              },
              'OrderSubmitRequestPaypalPaymentDataCaptureRequired'
            ),
            t.partial({}, 'OrderSubmitRequestPaypalPaymentDataCaptureOptional')
          ],
          'OrderSubmitRequestPaypalPaymentDataCapture'
        ),
        dataDynamic: t.intersection(
          [
            t.type(
              {
                captureTime: t.string
              },
              'OrderSubmitRequestPaypalPaymentDataDynamicRequired'
            ),
            t.partial({}, 'OrderSubmitRequestPaypalPaymentDataDynamicOptional')
          ],
          'OrderSubmitRequestPaypalPaymentDataDynamic'
        )
      },
      'OrderSubmitRequestPaypalPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestPaypalPaymentOptional')
  ],
  'OrderSubmitRequestPaypalPayment'
);

export const OrderSubmitRequestGooglePayPayment = t.intersection(
  [
    t.type(
      {
        type: t.literal('googlePay'),
        requestedAmount: t.number,
        version: t.string,
        data: t.string,
        signature: t.string,
        billingPostalCode: optional(t.string)
      },
      'OrderSubmitRequestGooglePayPaymentRequired'
    ),
    t.partial({}, 'OrderSubmitRequestGooglePayPaymentOptional')
  ],
  'OrderSubmitRequestGooglePayPayment'
);

export const OrderSubmitRequestPayment = t.union([
  OrderSubmitRequestAnonymousGiftCardPayment,
  OrderSubmitRequestVaultedGiftCardPayment,
  OrderSubmitRequestAnonymousCreditCardPayment,
  OrderSubmitRequestVaultedCreditCardPayment,
  OrderSubmitRequestApplePayPayment,
  OrderSubmitRequestVenmoPayment,
  OrderSubmitRequestPaypalPayment,
  OrderSubmitRequestGooglePayPayment
]);

export type OrderSubmitRequestAnonymousGiftCardPayment = t.TypeOf<
  typeof OrderSubmitRequestAnonymousGiftCardPayment
>;
export type OrderSubmitRequestVaultedGiftCardPayment = t.TypeOf<
  typeof OrderSubmitRequestVaultedGiftCardPayment
>;
export type OrderSubmitRequestAnonymousCreditCardPayment = t.TypeOf<
  typeof OrderSubmitRequestAnonymousCreditCardPayment
>;
export type OrderSubmitRequestVaultedCreditCardPayment = t.TypeOf<
  typeof OrderSubmitRequestVaultedCreditCardPayment
>;
export type OrderSubmitRequestApplePayPayment = t.TypeOf<
  typeof OrderSubmitRequestApplePayPayment
>;
export type OrderSubmitRequestGooglePayPayment = t.TypeOf<
  typeof OrderSubmitRequestGooglePayPayment
>;
export type OrderSubmitRequestPayment = t.TypeOf<
  typeof OrderSubmitRequestPayment
>;
/* #endregion */

/* #region OrderSubmitRequestDelivery */
export const OrderSubmitRequestDelivery = t.intersection([
  t.type({
    isContactlessDelivery: t.boolean
  }),
  t.partial({
    secondaryAddress: optional(t.string),
    deliveryInstructions: optional(t.string)
  })
]);

export type OrderSubmitRequestDelivery = t.TypeOf<
  typeof OrderSubmitRequestDelivery
>;
/* #endregion */

/* #region OrderSubmitRequest */
export const OrderSubmitRequest = t.intersection(
  [
    t.type(
      {
        action: t.literal('submit-order'),
        request: t.intersection(
          [
            t.type(
              {
                cartId: t.string,
                transactionId: t.string,
                guest: OrderGuestDto,
                acceptLanguage: t.string, // 'en-US',
                billingMethod: t.string,
                source: t.intersection([
                  t.type(
                    { platform: t.string, version: t.string },
                    'OrderSubmitRequestSourceRequired'
                  ),
                  t.partial({}, 'OrderSubmitRequestSourceOptional')
                ])
              },
              'OrderSubmitRequestBodyRequired'
            ),
            t.partial(
              {
                payments: optional(t.array(OrderSubmitRequestPayment)),
                delivery: optional(OrderSubmitRequestDelivery),
                accessToken: optional(t.string),
                sendmeoffers: optional(t.boolean),
                userSession: optional(
                  t.intersection([
                    t.type(
                      {
                        loginInfo: t.intersection([
                          t.type(
                            { loginProvider: t.string },
                            'OrderSubmitRequestLoginProviderRequired'
                          ),
                          t.partial(
                            {},
                            'OrderSubmitRequestLoginProviderOptional'
                          )
                        ])
                      },
                      'OrderSubmitRequestLoginInfoRequired'
                    ),
                    t.partial({}, 'OrderSubmitRequestLoginInfoOptional')
                  ])
                ),
                ravelinDeviceId: optional(t.string)
              },
              'OrderSubmitRequestBodyOptional'
            )
          ],
          'OrderSubmitRequestBody'
        )
      },
      'OrderSubmitRequestRequired'
    ),
    t.partial({}, 'OrderSubmitRequestOptional')
  ],
  'OrderSubmitRequest'
);

export type OrderSubmitRequest = t.TypeOf<typeof OrderSubmitRequest>;
/* #endregion */

/* #region OrderSubmitAction */
export const OrderSubmitActionC = t.union([
  t.literal('orderPending'),
  t.literal('orderScheduled'),
  t.literal('orderFailed'),
  t.literal('orderManuallyFetched')
]);
export type OrderSubmitAction = t.TypeOf<typeof OrderSubmitActionC>;

export const OrderSubmitAction: Record<OrderSubmitAction, OrderSubmitAction> = {
  orderPending: 'orderPending',
  orderScheduled: 'orderScheduled',
  orderFailed: 'orderFailed',
  orderManuallyFetched: 'orderManuallyFetched'
};
/* #endregion */

/* #region CartFinalizeResponse */
export const OrderSubmitResponse = ecommWebSocketResponse(
  OrderSubmitActionC,
  OrderDto
);

export type OrderSubmitResponse = t.TypeOf<typeof OrderSubmitResponse>;
/* #endregion */
