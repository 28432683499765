import { Component, Input } from '@angular/core';
import { PasswordRequirements } from '../../../../ecomm/types/password-requirements.types';

@Component({
  selector: 'wri-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent {
  @Input() passwordRequirements: PasswordRequirements[] = [];
  @Input() enteredPassword = '';
}
