import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'taxFeesPreposition'
})
export class TaxFeesPrepositionPipe implements PipeTransform {
  transform(ifFeesExist: boolean): string {
    return ifFeesExist ? 'Tax & Fees' : 'Tax';
  }
}
