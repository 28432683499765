<div
  class="saved-address-wrapper"
  data-testid="saved-address"
  (click)="selected.emit(address)"
>
  <div class="street-address" data-testid="street-address">
    {{ address?.streetAddress }}
  </div>
  <div class="secondary-street-address" *ngIf="address?.secondaryStreetAddress">
    {{ address?.secondaryStreetAddress }}
  </div>

  <div class="address-details" data-testid="address-details">
    {{ address?.locality }}, {{ address?.region }} {{ address?.postalCode }}
  </div>
</div>
<hr class="wri-divider" aria-hidden="true" />
