import { InjectionToken, ValueProvider } from '@angular/core';

import { EcommFeatureFlags } from '../../config/ecomm-feature-flags.provider';

export type Config = {
  appVersion: string;
  production: boolean;
  states: Record<string, string>;
  applePay: {
    merchantId: string;
  };
  defaultCareerUrl: string;
  ecomm: {
    baseUrl: string;
    webSocketUrl: string;
  };
  featureFlags: EcommFeatureFlags;
  fiserv: {
    apiKey: string;
    env: string;
    sdkUri: string;
    ravelinApiKey: string;
  };
  grecaptcha: {
    siteKey: string;
  };
  legacy: {
    webAppBaseUrl: string;
    webAppStoreMenuBaseUrl: string;
  };
  ngfeBaseUrl: string;
  brainTree: {
    authorization: string;
    merchantAccountId: string;
    useFakeNonce: boolean;
    urls: string[];
  };
  ping: {
    clientId: string;
    clientScope: string;
    stsAuthority: string;
  };
  mapBox: {
    key: string;
    defaults: {
      style: string;
      center: { lng: number; lat: number };
      zoom: number;
      mobileZoom: number;
      attributionControl: boolean;
    };
    marker: {
      color: string;
      height: string;
      width: string;
    };
    activeMarker: {
      color: string;
      height: string;
      width: string;
    };
    popup: {
      offset: number;
    };
    zoomPadding: number;
    minimumDiagonalZoomDistanceKM: number;
  };
  seoMetadata: {
    title: string;
    description: string;
    canonical: string;
    route: string;
  }[];
  baseAboutSection: string;
  cityAboutSection: string;
  locationPageAboutSection: string;
  googlePay: {
    environment: string;
    merchantId: string;
    gateway: string;
    gatewayMerchantId: string;
  };
};

export const CONFIG = new InjectionToken<Config>('NGFE_STATE_STORE_CONFIG');

export class ConfigProvider {
  public static get = (config: Config): ValueProvider => ({
    provide: CONFIG,
    useValue: config
  });
}
