import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { MenuModifier } from '../../../../ecomm/types/store-info.types';
import {
  FlavorSpiceHeading,
  FlavorSpiceHeadingColors,
  Modifiers
} from '../../../common/types/modifiers';

export type AddOnFlavorsData = {
  addOnFlavors: Modifiers[] | null;
};

@Component({
  selector: 'wri-choose-flavor-modal',
  templateUrl: './choose-flavor-modal.component.html',
  styleUrls: ['./choose-flavor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseFlavorModalComponent {
  @Input() limited: MenuModifier[] = [];
  @Input() hot: MenuModifier[] = [];
  @Input() medium: MenuModifier[] = [];
  @Input() mild: MenuModifier[] = [];
  @Input() editFlavor: MenuModifier | undefined;
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer!: ToastContainerDirective;

  readonly flavorSpiceHeading = FlavorSpiceHeading;
  readonly flavorSpiceHeadingColors = FlavorSpiceHeadingColors;
  public addOnFlavorsData: AddOnFlavorsData | undefined;
  selectedAddOnFlavor: MenuModifier | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  closeModal() {
    this.toastr.overlayContainer = undefined;
    this.activeModal.close({
      selectedAddOnFlavor: this.selectedAddOnFlavor,
      editFlavor: this.editFlavor,
      isApplied: false
    });
  }

  applySelections() {
    if (this.editFlavor) {
      this.selectedAddOnFlavor = this.editFlavor;
    }
    if (this.selectedAddOnFlavor) {
      this.toastr.overlayContainer = undefined;
      this.activeModal.close({
        selectedAddOnFlavor: this.selectedAddOnFlavor,
        editFlavor: this.editFlavor,
        isApplied: true
      });
    } else {
      this.toastr.overlayContainer = this.toastContainer;
      this.toastr.error(
        'There are no flavors selected. Please choose a flavor to customize the item.',
        '',
        {
          positionClass: 'inline'
        }
      );
    }
  }

  handleSelectedValues(values: {
    selectedAddOnFlavor: MenuModifier;
    editFlavor: MenuModifier | undefined;
  }) {
    this.selectedAddOnFlavor = values.selectedAddOnFlavor;
    this.editFlavor = values.editFlavor;
  }
}
