<div class="container">
  <ng-container *ngIf="!loading && !dynamicHtml">
    <div class="text-center">
      <img
        src="{{
          environment.ngfeBaseUrl + '/content/assets/wingstop_404_boat1.png'
        }}"
        class="img-fluid" />
      <h1 id="not-found-title">COMING UP EMPTY?</h1>
      <h2>
        AIN’T NOBODY GOT TIME FOR THAT.
        <br />
        GET BACK AT IT
      </h2>
    </div>
  </ng-container>

  <ng-container *ngIf="dynamicHtml">
    <div [innerHTML]="dynamicHtml | safeHtml"></div>
  </ng-container>
</div>

<wri-external-link-confirmation-modal>

</wri-external-link-confirmation-modal>
