import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(
    input: string,
    strToReplace: string,
    replacementStr: string
  ): string {
    if (!input || !strToReplace) {
      return input;
    }

    return input.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }
}
