import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

import { CacheLoadedFeature } from '../../../../ecomm/store/features/cache-loaded';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import {
  AuthFeature,
  AuthFeatureState
} from '../../../../ecomm/store/features/auth';
import { CustomerFeature } from '../../../../ecomm/store/features/customer';

@Component({
  template: ``
})
export class SignoutCallbackComponent implements OnInit {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    protected store: Store,
    @Inject(RedirectService)
    private redirectService: RedirectService,
    private authService: AuthService,
    @Inject(AsynchronousDispatcher)
    private asynchronousDispatcher: AsynchronousDispatcher<AuthFeatureState>
  ) {}

  getQueryParams(str: string) {
    return Object.fromEntries(new URLSearchParams(str));
  }

  async ngOnInit() {
    await firstValueFrom(
      this.store
        .select(CacheLoadedFeature.selectCacheLoaded)
        .pipe(filter(Boolean))
    );
    const auth = await this._authService.getCurrentAuth();
    const fullUrl = auth.pageBeforeLogout;
    if (!fullUrl) {
      await this.asynchronousDispatcher.dispatch(
        AuthFeature.actions.resetToDefault()
      );
      await this.asynchronousDispatcher.dispatch(
        CustomerFeature.actions.resetToDefault()
      );
      this.redirectService.redirectToHome();
    } else {
      if (fullUrl === 'reset') {
        await this.handleLogin();
      } else {
        const urlWithoutQueryParams = fullUrl.split('?')[0];
        const queryParamsInUrl = fullUrl.split('?')[1];
        const queryParams = this.getQueryParams(queryParamsInUrl);
        await this._router.navigate([urlWithoutQueryParams], { queryParams });
      }
    }
  }

  private async handleLogin() {
    await this.authService.login(this._router.url);
  }
}
