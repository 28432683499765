import {
  Component,
  Inject,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import {
  ActiveOfferFeature,
  ActiveOfferFeatureState
} from '../../../../ecomm/store/features/active-offer';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { OfferDetails } from '../../../../ecomm/types/offer-details.types';
import { EcommAPIError } from '../../../../ecomm/types/common.types';

@Component({
  selector: 'wri-apply-promo-code',
  templateUrl: './apply-promo-code.component.html',
  styleUrls: ['./apply-promo-code.component.scss']
})
export class ApplyPromoCodeComponent implements OnChanges {
  private static readonly MIN_MOBILE_WIDTH = 992;
  private static readonly MAX_MOBILE_WIDTH_BREAKPOINT1 = 1199;
  private static readonly MAX_MOBILE_WIDTH_BREAKPOINT2 = 576;
  @Input() cartFeatureState: CartFeatureState | undefined;
  @Input() activeOfferFeatureState: ActiveOfferFeatureState | undefined;
  public couponCodeInput = '';
  public isCouponApplied = false;
  public isLoading = false;
  public promoCodeSectionWhenOfferActive = false;
  public activeOffer: string | null = null;
  public showInputApplyOfferErrorSection = false;
  public showInfoIcon = true;
  public offerInputErrorMessage = 'Offer cannot be applied.';
  public showClippedOfferErrorSection = false;
  public offerDetails: OfferDetails | null = null;
  public offerErrorResponse: EcommAPIError | null = null;
  public offerInfoLoading = false;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  private subscription = new Subscription();

  constructor(
    private cartService: CartWorkflowService,
    private store: Store,
    @Inject(WINDOW) private window: Window,
    private modalService: NgbModal,
    private storeInfoWorkflowService: StoreInfoWorkflowService
  ) {}

  get isMobile() {
    return (
      (this.window.innerWidth >= ApplyPromoCodeComponent.MIN_MOBILE_WIDTH &&
      this.window.innerWidth <= ApplyPromoCodeComponent.MAX_MOBILE_WIDTH_BREAKPOINT1) ||
      this.window.innerWidth <= ApplyPromoCodeComponent.MAX_MOBILE_WIDTH_BREAKPOINT2
    );
  }

  ngOnChanges() {
    this.subscribeToCartState();
    this.subscribeToActiveOfferState();
  }

  quickApplyPromoCode(promoCode: string) {
    this.isLoading = true;
    this.showInputApplyOfferErrorSection = false;
    this.cartService
      .applyPromoCodeToCart(
        this.cartFeatureState?.cart?.cartId ?? '',
        promoCode,
        promoCode === this.activeOffer
      )
      .then((result) => {
        this.isLoading = false;
        this.showClippedOfferErrorSection = false;
        if (result.error) {
          this.showClippedOfferErrorSection = true;
          this.showInfoIcon = true;
          this.offerInputErrorMessage = result.error;
        }
      });
  }

  applyPromoCode() {
    if (this.couponCodeInput.trim()) {
      this.isLoading = true;
      this.cartService
        .applyPromoCodeToCart(
          this.cartFeatureState?.cart?.cartId ?? '',
          this.couponCodeInput.trim(),
          this.couponCodeInput.trim() === this.activeOffer
        )
        .then((result) => {
          this.isLoading = false;
          this.showInputApplyOfferErrorSection = false;
          if (result.error) {
            this.showInputApplyOfferErrorSection = true;
            this.showInfoIcon = true;
            this.offerInputErrorMessage = result.error;
          }
        });
    } else {
      this.showInputApplyOfferErrorSection = true;
      this.offerInputErrorMessage = 'Please input a valid offer code.';
      this.showInfoIcon = false;
    }
  }

  removePromoCode() {
    this.isLoading = true;
    this.cartService
      .deletePromoCode(this.cartFeatureState?.cart?.cartId ?? '')
      .then(() => {
        this.couponCodeInput = '';
        this.promoCodeSectionWhenOfferActive = false;
        this.isLoading = false;
      });
  }

  ifCouponApplied() {
    this.isCouponApplied = !!this.cartFeatureState?.cart?.offer;
  }

  handleDifferentOfferBtnClick() {
    this.showClippedOfferErrorSection = false;
    this.promoCodeSectionWhenOfferActive =
      !this.promoCodeSectionWhenOfferActive;
  }

  openOfferInfoModal(offerCode: string) {
    this.offerInfoLoading = true;
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
    this.getOfferDetails(offerCode);
  }

  resetOfferErrorOnModify() {
    this.showInputApplyOfferErrorSection = false;
    this.showClippedOfferErrorSection = false;
    this.offerDetails = null;
  }

  private async getOfferDetails(offerCode: string) {
    if (offerCode && this.cartFeatureState?.cart?.locationId) {
      this.offerErrorResponse = null;
      await this.storeInfoWorkflowService
        .getOfferDetailsWithErrorObject(
          offerCode,
          this.cartFeatureState?.cart?.locationId
        )
        .then((result) => {
          this.offerDetails = result.data;
          this.offerInfoLoading = false;
          if (result.error) {
            this.offerErrorResponse = result.error;
            this.showInputApplyOfferErrorSection = true;
            this.offerInputErrorMessage = result.error;
          }
        });
    }
  }

  private subscribeToActiveOfferState(): void {
    const activeOfferState$ = this.store
      .select(ActiveOfferFeature.selectActiveOfferState)
      .pipe(filter(Boolean));

    this.subscription.add(
      activeOfferState$.subscribe((state) => {
        this.activeOffer = state.activeOffer;
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartFeatureState = state;
        this.ifCouponApplied();
      })
    );
  }
}
