<div
  class="wri-homepage-flavor-section"
>
  <div class="flavor-wpr">
    <div class="flavor-wpr-titles">
      <div class="flavor-wpr-header" data-testid="find-flavor">
        Find your Flavor
      </div>
      <div class="flavor-wpr-subtitle" data-testid="find-flavor-subtitle">
        <span>Explore our saucy or dry rub flavors that range from mild to hot, in sweet or savory.</span>
      </div>
    </div>

    <div class="flavor-wpr-slider">
      <div class="flavor-wpr-slider-container">
        <ngx-slider
          [options]="heatScaleSliderConfig"
          [value]="selectedHeatScaleCount"
          (userChange)="onUserChange($event)"
        ></ngx-slider>
      </div>
      <div class="descriptors">
        <div class="descriptors-wpr">
          <div class="no-heat">{{ flavorSpiceHeading.MILD }}</div>
          <div class="some-heat">{{ flavorSpiceHeading.MEDIUM }}</div>
          <div class="all-the-heat">{{ flavorSpiceHeading.HOT }}</div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedFlavors?.length > 0; then hasFlavors; else noFlavors"></ng-container>

    <ng-template #hasFlavors>
      <div [class.loading]="loading" data-testid="has-flavors">
        <div class="carousel">
          <ngx-slick-carousel
            class="limited-time-offer-slider home-page-flavor-carousel"
            [config]="slideConfig"
            (init)="handleInit()"
            #slickModal="slick-carousel"
          >
            <div
              ngxSlickItem
              *ngFor="let flavor of selectedFlavors; trackBy: trackByItem; let first = first"
              class="slide tabfocus"
            >
              <wri-flavor-card
                [flavor]="flavor"
                [storeSlug]="storeSlug"
                [hasButton]="false"
              ></wri-flavor-card>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </ng-template>
    <ng-template #noFlavors>
      <ng-container *ngIf="allFlavors?.length > 0; then hasAPIFlavors; else noAPIFlavors"></ng-container>
      <ng-template #hasAPIFlavors>
        <div class="flavor-wpr-no-flavors" data-testid="no-flavors">
          Sorry, no flavors match your selection.
        </div>
      </ng-template>
      <ng-template #noAPIFlavors>
        <div class="flavor-wpr-no-flavors" data-testid="no-flavors">
          We ran into an issue loading flavor data. Please try again.
        </div>
      </ng-template>
    </ng-template>

    <div class="home-page-flavor-button-wrapper">
      <button
        class="wri-btn wri-btn-primary wri-btn-custom"
        aria-label="Browse our menu"
        (click)="navigateToMenu()"
      >
        Explore Menu
      </button>
    </div>
  </div>
</div>
