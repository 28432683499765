import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AlertModel } from '@wingstop/models/alert.model';
import { Basket } from '@wingstop/models/basket.model';
import { Location } from '@wingstop/models/location/location.model';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { IAppStore } from '@wingstop/store/app-store';
import { Observable } from 'rxjs';
import { IPilotProgram } from '@wingstop/models/pilot/pilot-program.model';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';
@Injectable()
export class AppStateSelectors {
  constructor(private store: Store<IAppStore>) { }

  selectedLocation: Observable<Location> = this.store.select(
    (state) => state.appState.selectedLocation
  );

  basket: Observable<Basket> = this.store.select(
    (state) => state.appState.basket
  );

  authentication: Observable<Authentication> = this.store.select(
    (state) => state.appState.authentication
  );

  errors: Observable<object> = this.store.select(
    (state) => state.appState.errors
  );

  openAlert: Observable<boolean> = this.store.select(
    (state) => state.appState.openAlert
  );

  alertContent: Observable<AlertModel> = this.store.select(
    (state) => state.appState.alertContent
  );

  isDigitalMenu: Observable<boolean> = this.store.select(
    (state) => state.appState.isDigitalMenu
  );

  showAppBanner: Observable<boolean> = this.store.select(
    (state) => state.appState.showAppBanner
  );

  appBannerClosedByUser: Observable<boolean> = this.store.select(
    (state) => state.appState.appBannerClosedByUser
  );

  appDownloadBannerEnabled: Observable<boolean> = this.store.select(
    (state) => state.appState.appDownloadBannerEnabled
  );

  userLocale: Observable<string> = this.store.select(
    (state) => state.appState.userLocale
  );

  s3SeoMetadata: Observable<SeoMetadata[]> = this.store.select(
    (state) => state.appState.s3SeoMetadata
  );

  pilotProgram: Observable<IPilotProgram> = this.store.select(
    (state) => state.appState.pilotProgram
  );

  pilotProgramUser: Observable<IPilotProgramUser> = this.store.select(
    (state) => state.appState.pilotProgramUser
  );
}
