import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import * as Workflow from '../../../ecomm/types/workflow';
import { FlavorsService } from '../../services/flavors/flavors.service';
import { NotificationService } from '../../utils/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class FlavorsWorkflowService {
  constructor(
    private flavorsService: FlavorsService,
    private notificationService: NotificationService
  ) {}

  public getFlavors = (showError = true) =>
    Workflow.createWorkflow(
      undefined,
      () => this.flavorsService.getFlavors(),
      this.reportErrors(showError)
    )();

  private reportErrors = <T>(showError = true) =>
    Workflow.onError<T>(
      tap((res) =>
        showError ? this.notificationService.showError(res.error) : null
      )
    );
}
