import { BasketChoice } from './basket-choice.model';
import { BaseModel } from '@wingstop/models/base.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';

export class BasketProduct extends BaseModel {
  id: any = null;
  categoryProduct: CategoryProduct;
  choicecustomfields: any[] = [];
  options: string = null;
  productid: any = null;
  productId: any = null;
  quantity: number = null;
  recipient: string = '';
  specialinstructions: string = null;
  choices: BasketChoice[] = [];
  status: string = null;
  totalcost: number = null;
  displayChoices: BasketChoice[] = [];
  name: string = null;

  constructor(values?: any) {
    super();
    if (values) {
      if (values.choices) {
        values.choices = values.choices.map(
          (value: any) => new BasketChoice(value)
        );
        this.addDisplayChoices(values.choices);
      }
    }
    this.initialize(values);
  }

  addDisplayChoices(choices: any) {
    let processedChoices = {};

    function addToProcessedChoices(choice: object) {
      let quantity = choice['quantity'] || 1;
      let price = choice['price'] || 0;
      let name = choice['name'] + price.toString() + choice['indent'];

      if (!processedChoices[name]) {
        processedChoices[name] = {
          quantity: quantity,
          choice: choice,
        };
      } else {
        processedChoices[name].quantity += quantity;
      }
    }

    choices.forEach(function (choice: any) {
      addToProcessedChoices(choice);
    });

    for (let name in processedChoices) {
      if (processedChoices.hasOwnProperty(name)) {
        let choice = { ...processedChoices[name]['choice'] };
        choice['quantity'] = processedChoices[name]['quantity'];
        this.displayChoices.push(choice);
      }
    }
  }
}
