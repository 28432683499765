import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GooglePayButtonModule } from "@google-pay/button-angular";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import { EcommModule } from '../../ecomm/ecomm.module';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common/common.module';
import { AddMoreItemsComponent } from '../order/components/add-more-items/add-more-items.component';
import { ApplyPromoCodeComponent } from '../order/components/apply-promo-code/apply-promo-code.component';
import { CartAdditionalRecommendationComponent } from './components/cart-additional-recommendation/cart-additional-recommendation.component';
import { CartAddressComponent } from './components/cart-address/cart-address.component';
import { CartFulfillmentTimeComponent } from './components/cart-fulfillment-time/cart-fulfillment-time.component';
import { CartPriceSummaryComponent } from './components/cart-price-summary/cart-price-summary.component';
import { CharitiesSectionComponent } from './components/charities-section/charities-section.component';
import { ChooseLocationComponent } from './components/choose-location/choose-location.component';
import { ConnectedBagComponent } from './components/connected-bag/connected-bag.component';
import { ConnectedCheckoutComponent } from './components/connected-checkout/connected-checkout.component';
import { ConfirmationComponent } from './components/connected-confirmation/confirmation.component';
import { ConnectedConfirmationComponent } from './components/connected-confirmation/connected-confirmation.component';
import { ConnectedOrderDetailsComponent } from './components/connected-order-details/connected-order-details.component';
import { CustomTipComponent } from './components/custom-tip/custom-tip.component';
import { DeliveryInstructionsFormComponent } from './components/delivery-instructions-form/delivery-instructions-form.component';
import { DonationComponent } from './components/donation/donation.component';
import { FutureFulfillmentDateTimeComponent } from './components/future-fulfillment-date-time/future-fulfillment-date-time.component';
import { GuestSignupFormComponent } from './components/guest-signup-form/guest-signup-form.component';
import { HandoffModeSelectorComponent } from './components/handoff-mode-selector/handoff-mode-selector.component';
import { LocationInfoModalComponent } from './components/location-info-modal/location-info-modal.component';
import { LocationSearchCardComponent } from './components/location-search-card/location-search-card.component';
import { LocationSearchListComponent } from './components/location-search-list/location-search-list.component';
import { PastOrderSummaryComponent } from './components/past-order-summary/past-order-summary.component';
import { ApplePayPaymentMethodComponent } from './components/payments/apple-pay-payment-method/apple-pay-payment-method.component';
import { CreditCardPaymentMethodComponent } from './components/payments/credit-card-payment-method/credit-card-payment-method.component';
import { GiftCardPaymentMethodComponent } from './components/payments/gift-card-payment-method/gift-card-payment-method.component';
import { GooglePayPaymentMethodComponent } from './components/payments/google-pay-payment-method/google-pay-payment-method.component';
import { InStorePaymentMethodComponent } from './components/payments/in-store-payment-method/in-store-payment-method.component';
import { PaymentMethodComponent } from './components/payments/payment-method/payment-method.component';
import { PaypalPaymentMethodComponent } from './components/payments/paypal-payment-method/paypal-payment-method.component';
import { VenmoPaymentMethodComponent } from './components/payments/venmo-payment-method/venmo-payment-method.component';
import { ReorderConfirmationModalComponent } from './components/reorder-confirmation-modal/reorder-confirmation-modal.component';
import { ReviewOrderItemComponent } from './components/review-order-item/review-order-item.component';
import { ReviewOrderItemsComponent } from './components/review-order-items/review-order-items.component';
import { RoundupInfoModalComponent } from './components/roundup-info-modal/roundup-info-modal.component';
import { SavedDeliveryAddressComponent } from './components/saved-delivery-address/saved-delivery-address.component';
import { TaxAndFeesModalComponent } from './components/tax-and-fees-modal/tax-and-fees-modal.component';
import { TipComponent } from './components/tip/tip.component';
import { UserOrdersComponent } from './components/user-orders/user-orders.component';

export const OrderComponents = [
  ConnectedBagComponent,
  ConnectedCheckoutComponent,
  ConnectedConfirmationComponent,
  ConfirmationComponent,
  DeliveryInstructionsFormComponent,
  GuestSignupFormComponent,
  PaymentMethodComponent,
  AddMoreItemsComponent,
  ApplyPromoCodeComponent,
  ReviewOrderItemComponent,
  ReviewOrderItemsComponent,
  CartAddressComponent,
  CartFulfillmentTimeComponent,
  CartPriceSummaryComponent,
  ReorderConfirmationModalComponent,
  FutureFulfillmentDateTimeComponent,
  TaxAndFeesModalComponent,
  InStorePaymentMethodComponent,
  GiftCardPaymentMethodComponent,
  ApplePayPaymentMethodComponent,
  VenmoPaymentMethodComponent,
  PaypalPaymentMethodComponent,
  CreditCardPaymentMethodComponent,
  CharitiesSectionComponent,
  CartAdditionalRecommendationComponent,
  UserOrdersComponent,
  TipComponent,
  CustomTipComponent,
  DonationComponent,
  RoundupInfoModalComponent,
  PastOrderSummaryComponent,
  ConnectedOrderDetailsComponent,
  ChooseLocationComponent,
  LocationSearchCardComponent,
  LocationSearchListComponent,
  SavedDeliveryAddressComponent,
  HandoffModeSelectorComponent,
  LocationInfoModalComponent,
  GooglePayPaymentMethodComponent
];

@NgModule({
  declarations: [...OrderComponents],
  imports: [
    CommonModule,
    WriCommonModule,
    EcommModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SlickCarouselModule,
    ScrollToModule,
    GooglePayButtonModule,
    NgxSkeletonLoaderModule
  ],
  providers: [ScrollToService],
  exports: [...OrderComponents]
})
export class OrderModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<OrderModule> {
    return {
      ngModule: OrderModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
