import {Pipe, PipeTransform} from '@angular/core';

import { Cart } from '../../../ecomm/types/cart.types';
import { StoreDetails } from '../../../ecomm/types/store-info.types';

@Pipe({
  name: 'tipSelection'
})
export class TipSelectionPipe implements PipeTransform {
  transform(cartDetails: Cart | null, storeDetails: StoreDetails | null): boolean {
    if(cartDetails?.items.length === 0 || cartDetails?.items === undefined){
      return false;
    }
    const cartHandoffMode = cartDetails?.handoffMode;
    const storeHandoffMode = storeDetails?.handoffModes.filter((hMode)=>hMode.handoffMode.toLowerCase() === cartHandoffMode.toLowerCase()) ?? [];
    return storeHandoffMode.length > 0 ? storeHandoffMode[0].tippingAllowed : false
  }
}
