<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>

<div class="tip-section-wrapper">
  <span class="tip-title" data-testid="tip-title">TIP:</span>
  <span class="tip-sub-title" data-testid="tip-sub-title">{{
    getTipSubTitle
  }}</span>
  <div class="tip-container">
    <div class="preset-tip-wrapper">
      <ng-container *ngFor="let tip of tipOptions">
        <button
          type="button"
          (click)="applyTip(tip)"
          [ngClass]="
            getTipFromCart(tip) && !showCustomTipInput ? 'active-tip' : ''
          "
          class="single-tip"
          data-testid="apply-tip"
        >
          <span class="tip-amount" data-testid="tip-amount">{{
            tip.amount
          }}</span>
        </button>
      </ng-container>
    </div>
    <button
      type="button"
      class="single-tip"
      data-testid="custom-apply-tip"
      [ngClass]="
        getCustomTipFromCart() || showCustomTipInput ? 'active-custom-tip' : ''
      "
      (click)="applyCustomTip()"
    >
      <div class="custom-tip-wrapper" data-testid="custom-tip-wrapper">
        <span
          [ngClass]="
            cartFeatureState?.cart?.tip?.type === null &&
            'custom-after-tip-amount'
          "
          class="custom-tip"
        >
          Custom
        </span>
        <span
          *ngIf="
            cartFeatureState?.cart?.tip &&
            cartFeatureState?.cart?.tip?.type === null
          "
          class="tip-amount"
        >
          ${{ (cartFeatureState.cart?.tip?.amount).toFixed(2) }}
        </span>
      </div>
    </button>
  </div>
  <wri-custom-tip
    (valuesChanged)="onCustomTipSubmit($event)"
    (tipInputValuesChanged)="onCustomTipInputChange($event)"
    [showCustomTipInput]="showCustomTipInput"
    [customTipAmount]="customTipAmount"
  ></wri-custom-tip>
</div>
