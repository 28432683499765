import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from '@ngrx/store';

import { LocationResponseData } from '../../../types/search-location.types';
import { FeatureState } from '../../types/feature-state';

export type LocationDetailsFeatureState = FeatureState<
  LocationResponseData,
  'locationDetails'
>;

const initialLocationDetailsFeatureState: LocationDetailsFeatureState = {
  isLoading: false,
  error: null,
  locationDetails: null
};

const LocationDetailsActions = createActionGroup({
  source: 'LocationDetails Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{
      error?: string;
      locationDetails?: LocationResponseData;
    }>(),
    'Reset To Default': emptyProps()
  }
});

const LocationDetailsReducer = createReducer(
  initialLocationDetailsFeatureState,
  on(
    LocationDetailsActions.setIsLoading,
    (state): LocationDetailsFeatureState => ({ ...state, isLoading: true })
  ),
  on(
    LocationDetailsActions.setState,
    (state, action): LocationDetailsFeatureState => {
      return {
        ...state,
        isLoading: false,
        error: action.error ?? null,
        locationDetails: action.locationDetails ?? null
      };
    }
  ),
  on(
    LocationDetailsActions.resetToDefault,
    (state): LocationDetailsFeatureState => ({
      ...state,
      ...initialLocationDetailsFeatureState
    })
  )
);

const LocationDetailsFeatureKey = 'locationDetails';

const LocationDetailsFeature = createFeature({
  name: LocationDetailsFeatureKey,
  reducer: LocationDetailsReducer
});

export default {
  ...LocationDetailsFeature,
  actions: LocationDetailsActions,
  initialState: initialLocationDetailsFeatureState
}
