import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notNull'
})
export class NotNullPipe<T> implements PipeTransform {
  transform(input: T): boolean {
    return input !== null ? true : false;
  }
}
