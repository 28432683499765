import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wri-add-more-items',
  templateUrl: './add-more-items.component.html',
  styleUrls: ['./add-more-items.component.scss']
})
export class AddMoreItemsComponent {
  @Input()
  locationSlug = '';

  constructor(private router: Router) {}

  public goToMenu = () => {
    const url = `/location/${this.locationSlug}/menu`;
    this.router.navigate([url]);
  };
}
