import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prepend'
})
export class PrependPipe implements PipeTransform {
  transform(input: string, prefix: string): string {
    return `${prefix}${input}`;
  }
}
