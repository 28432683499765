import { EnvironmentInjector, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { firstValueFrom, isObservable } from 'rxjs';

export const combineGuard =
  (...guards: CanActivateFn[]): CanActivateFn =>
  async (...guardArgs) => {
    const injectionContext = inject(EnvironmentInjector);
    for (const guard of guards) {
      try {
        const out = await injectionContext.runInContext(() =>
          guard(...guardArgs)
        );
        if (isObservable(out) ? !(await firstValueFrom(out)) : !out) {
          return false;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return true;
  };
