import { Pipe, PipeTransform } from '@angular/core';

import { CartFeatureState } from '../../../ecomm/store/features/cart';

@Pipe({
  name: 'shouldShowCartError'
})
export class ShouldShowCartErrorPipe implements PipeTransform {
  transform(state: CartFeatureState | null): boolean {
    if (!state || state.isLoading || !state.error) {
      return false;
    } else {
      return true;
    }
  }
}
