import { State } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Basket } from '@wingstop/models/basket.model';
import { BasketProduct } from '@wingstop/models/basket/basket-product.model';
import { GaItem, GaProduct } from '@wingstop/models/google-analytics.model';
import { IAppStore } from '@wingstop/store/app-store';
import { BehaviorSubject } from 'rxjs';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';
import { UpsellTypes } from '@wingstop/models/menu/upsell-product.model';

declare var dataLayer: any;
declare var ga: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private userStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: State<IAppStore>) {
    try {
      const appState = this.store.getValue().appState;
      const id =
        appState &&
        appState.authentication &&
        appState.authentication.nomnom.user_id;
      if (id) {
        const that = this;
        (window as any).logReturningUser = setInterval(function () {
          if (typeof (window as any).dataLayer !== 'undefined') {
            clearInterval((window as any).logReturningUser);
            that.logGaEvent({
              user_id: id,
            });
          }
        }, 100);
      }
    } catch (e) {
      console.error(e);
    }
  }

  setUserStatus() {
    if (this.store.getValue().appState.authentication) {
      ga('set', 'dimension2', 'logged_in');
      this.userStatus.next('logged_in');
    } else {
      ga('set', 'dimension2', 'guest');
      this.userStatus.next('guest');
    }
  }

  userLoggedOut() {
    ga('set', 'dimension2', 'guest');
    this.userStatus.next('guest');
  }

  userLoggedIn() {
    ga('set', 'dimension2', 'logged_in');
    this.userStatus.next('logged_in');
  }

  // Firebase Custom Event
  // logFirebaseEvent(eventName: string, eventParams?: any) {
  //   if ((<any>window).firebase && (<any>window).firebase.analytics()) {
  //     (<any>window).firebase.analytics().logEvent(eventName, eventParams);
  //   }
  // }

  // Google Analytics Event Dispatcher
  logGaEvent(event: any) {
    try {
      if (event.ecommerce) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      }
      dataLayer.push(event);
    } catch (e) {
      console.error(e);
    }
  }

  formatPageUrl(url: string) {
    let replacedSubstr = url
      .substr(url.lastIndexOf('/'))
      .replace('/', '')
      .replace('-', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (word) => word.toUpperCase());

    if (replacedSubstr && replacedSubstr.length) {
      if (!replacedSubstr.includes('?')) {
        if (replacedSubstr === 'Carryout' || replacedSubstr === 'Delivery') {
          return 'Order';
        }
        return replacedSubstr;
      } else if (
        replacedSubstr.includes('?') &&
        replacedSubstr.charAt(0) !== '?'
      ) {
        return replacedSubstr.substr(0, replacedSubstr.indexOf('?'));
      } else if (
        replacedSubstr.includes('?') &&
        replacedSubstr.charAt(0) === '?'
      ) {
        return 'Welcome';
      }
    } else {
      return 'Welcome';
    }
  }

  getHandoffMode(basket: Basket) {
    switch (basket && basket.deliverymode) {
      case 'pickup':
        return 'carryout';
      case 'curbside':
        return 'curbside';
      case 'dispatch':
        return 'delivery';
      case 'dinein':
        return 'dinein';
      default:
        return null;
    }
  }

  convertBasketProductToGaItems(products: BasketProduct[]): GaItem[] {
    return products.map((p: any) => {
      return {
        currency: 'USD',
        item_category: p.category && p.category.name ? p.category.name : '',
        item_id: p.productId ? p.productId : 0,
        item_name: p.name ? p.name : '',
        price: p.totalcost ? p.totalcost : 0,
        quantity: p.quantity ? p.quantity : 0,
        value: p.totalcost ? p.totalcost : 0,
      };
    });
  }

  convertBasketProductToGaProdcuts(products: BasketProduct[]): GaProduct[] {
    return products.map((p: any) => {
      return {
        category: p.category && p.category.name ? p.category.name : '',
        id: p.productId ? p.productId : 0,
        name: p.name ? p.name : '',
        price: p.totalcost ? p.totalcost : 0,
        quantity: p.quantity ? p.quantity : 0,
        value: p.totalcost ? p.totalcost : 0,
      };
    });
  }

  convertCategoryProductToGaItems(
    products: CategoryProduct[],
    category: string = null
  ): GaItem[] {
    return products.map((p: any) => {
      return {
        item_category: category
          ? category
          : p.category && p.category.name
            ? p.category.name
            : '',
        item_id: p.id ? p.id : 0,
        item_name: p.name ? p.name : '',
        price: p.cost ? p.cost : 0,
        quantity: p.quantity ? p.quantity : 0,
      };
    });
  }

  setUserIdEvent(): void {
    const appState = this.store.getValue().appState;
    const id =
      appState &&
      appState.authentication &&
      appState.authentication.nomnom.user_id;
    this.logGaEvent({
      user_id: id,
    });
  }
}
