<div class="wri-homepage-find-menu-section">
  <div class="find-menu-wpr">
    <div class="title-wrapper">
      <div class="icon-wrapper">
        <wri-icon class="icon" icon="wri-location"></wri-icon>
      </div>
      <div class="find-menu-title" data-testid="find-your-menu">Find your menu</div>
    </div>
    <div class="handoff-input-wpr">
      <wri-handoff-mode-selector
        [handoffMode]="selectedHandoffMode"
        (handoffModeChange)="onHandoffChange($event)"
      ></wri-handoff-mode-selector>
      <div class="input-wrapper">
        <label [attr.for]="'find-your-menu-search-input'">
          {{
            selectedHandoffMode === 'delivery'
              ? 'Enter Your Delivery Address'
              : 'Search Zip or City, State'
          }}
        </label>
        <div class="find-menu-input-button-wrapper">
          <input
            type="text"
            name="query"
            class="form-control border-right-none"
            aria-label="Find your menu"
            [(ngModel)]="findMenuInput"
            (keydown.enter)="encodeAndNavigate()"
            data-testid="find-menu-search-input"
          />
          <button
            class="wri-btn wri-btn-primary-icon"
            (click)="encodeAndNavigate()"
            aria-label="Find state or city, Button"
            data-testid="find-menu-button"
          >
            <wri-icon class="icon" icon="wri-arrow-right"></wri-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
