<div>
  <ng-container
    *ngIf="type === 'limitedTime'; then limitedTime; else spiceMod"
  ></ng-container>
  <ng-template #limitedTime>
    <wri-icon class="limited-time-icon" icon="wri-limited-time"></wri-icon>
  </ng-template>
  <ng-template #spiceMod>
    <wri-icon
      icon="wri-heat-scale"
      [ngStyle]="{ color: iconColor, fontSize: '21px' }"
    ></wri-icon>
  </ng-template>
  <label [attr.data-testid]="'spice-heading-'+type" class="spice-heading">{{ heading }}</label>
  <hr class="wri-divider" aria-hidden="true" />
  <div class="more-info" *ngIf="isMoreInfoNeeded">
    More info about our {{ heading | titlecase }} Flavors can be found
    <a
      [type]="type"
      wriFocusOutline
      role="button"
      [attr.aria-label]="'Click here'"
      (click)="onSelectType($event.target.type)"
    >
      here
    </a>
  </div>
</div>
