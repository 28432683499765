import { CartFee } from '../../types/cart.types';

export class FeesCalculationService {
  isDelivery(handoffMode?: string): boolean {
    return handoffMode?.toLowerCase() === 'delivery';
  }

  calculateDeliveryFees(fees: CartFee[] | undefined): number {
    let totalDeliveryFees = 0;
    // a delivery fee is a fee with 'category': 'delivery' in fees[]
    if (fees) {
      totalDeliveryFees = fees
        .filter((item) => item.category === 'delivery')
        .reduce((sum, current) => sum + current.amount, 0);
    }
    return totalDeliveryFees;
  }

  calculateFees(fees: CartFee[] | undefined): number {
    let totalFees = 0;

    if (fees) {
      totalFees = fees
        .filter(
          (item) => item.category !== 'delivery' && item.category !== 'donation'
        )
        .reduce((sum, current) => sum + current.amount, 0);
    }
    return totalFees;
  }

  isStandardCategory(fees: CartFee[] | undefined): boolean {
    const nonDeliveryCategoryItems = fees?.some(
      (a) => a.category !== 'delivery'
    );
    return nonDeliveryCategoryItems == null ? false : nonDeliveryCategoryItems;
  }
}
