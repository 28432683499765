/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pushOrReplace'
})
export class PushOrReplacePipe implements PipeTransform {
  transform(
    arrToTransform: any[] = [],
    arrIndexToWatch: string,
    indexToWatch: string,
    propertyToWatchIfSpliceNeeded: string,
    objToPush = {}
  ): any[] {
    const modifierGroupIndex = arrToTransform.findIndex(
      (arrObj) => arrObj[arrIndexToWatch] === indexToWatch
    );
    if (modifierGroupIndex > -1) {
      // replace with latest value if available
      arrToTransform[modifierGroupIndex] = objToPush;
      if (
        arrToTransform[modifierGroupIndex][propertyToWatchIfSpliceNeeded] === null ||
        (
          arrToTransform[modifierGroupIndex][propertyToWatchIfSpliceNeeded] &&
          (arrToTransform[modifierGroupIndex][propertyToWatchIfSpliceNeeded]).length === 0
        )
      ) {
        arrToTransform.splice(modifierGroupIndex, 1);
      }
    } else {
      // push to array if modifiergroup does not exist
      arrToTransform.push(objToPush);
    }
    return arrToTransform;
  }
}
