<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<div class="flex-col wri-donation-wrapper">
  <div class="flex">
    <wri-checkbox
      [id]="'round-up-and-donate'"
      class="span-full-width-lg"
      label="Round up and donate {{
        getRoundUpAmount() | currency
      }} to {{ roundupCharityData?.charityName }}"
      [(ngModel)]="value"
      (change)="finalizeCartWithRoundupForCharity($event.target.checked)"
    ></wri-checkbox>
    <a
      wriFocusOutline
      role="button"
      class="info-icon"
      data-testid="info-icon-click"
      (click)="openRoundupInfoModal()"
    >
      <wri-icon
        icon="wri-info"
        role="text"
        aria-label="view info about Wingstop Charities"
      ></wri-icon>
    </a>
  </div>
  <div class="charity-wrapper">
    <img
      *ngIf="roundupCharityData?.charityImageUrl"
      src="{{ roundupCharityData?.charityImageUrl }}"
      srcset="{{ roundupCharityData?.charityImageUrl | imageResolution: 'srcset':'charity-image': '/assets/images/logo-green-product.png'}}"
      alt="Charity Image"
    />
    <div class="wri-round-up-description">
      {{ roundupCharityData?.roundupDescription }}
    </div>
  </div>
</div>
