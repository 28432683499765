<div class="modal-content">
  <div class="modal-header">
    <span toastContainer class="inline-toast"></span>
    <div class="modal-header__header">
      <div class="modal-header__icon-wrapper">
        <a
          data-testid="close-modal"
          wriFocusOutline
          (click)="closeModal()"
          [attr.aria-label]="'close modal'"
        >
          <wri-icon
            class="modal-header__cross_icon"
            icon="wri-cross"
          ></wri-icon>
        </a>
      </div>
    </div>
    <span data-testid="modal-header" class="modal-header__find-your-flavor"
    >Choose A Flavor</span>
  </div>

  <div class="modal-body">
    <wri-choose-flavor-spice-group
      [flavors]="limited"
      [heading]="flavorSpiceHeading.LIMITED_TIME"
      [iconColor]="flavorSpiceHeadingColors.LIMITED_TIME"
      [type]="'limitedTime'"
      [limited]="limited"
      [hot]="hot"
      [medium]="medium"
      [mild]="mild"
      [editFlavor]="editFlavor"
      (valuesChanged)="handleSelectedValues($event)">

    </wri-choose-flavor-spice-group>

    <wri-choose-flavor-spice-group
      [flavors]="hot"
      [heading]="flavorSpiceHeading.HOT"
      [iconColor]="flavorSpiceHeadingColors.HOT"
      [type]="'hot'"
      [limited]="limited"
      [hot]="hot"
      [medium]="medium"
      [mild]="mild"
      [editFlavor]="editFlavor"
      (valuesChanged)="handleSelectedValues($event)">

    </wri-choose-flavor-spice-group>

    <wri-choose-flavor-spice-group
      [flavors]="medium"
      [heading]="flavorSpiceHeading.MEDIUM"
      [iconColor]="flavorSpiceHeadingColors.MEDIUM"
      [type]="'medium'"
      [limited]="limited"
      [hot]="hot"
      [medium]="medium"
      [mild]="mild"
      [editFlavor]="editFlavor"
      (valuesChanged)="handleSelectedValues($event)">

    </wri-choose-flavor-spice-group>

    <wri-choose-flavor-spice-group
      [flavors]="mild"
      [heading]="flavorSpiceHeading.MILD"
      [iconColor]="flavorSpiceHeadingColors.MILD"
      [type]="'mild'"
      [limited]="limited"
      [hot]="hot"
      [medium]="medium"
      [mild]="mild"
      [editFlavor]="editFlavor"
      (valuesChanged)="handleSelectedValues($event)">

    </wri-choose-flavor-spice-group>


  </div>

  <div class="modal-footer fixed-bottom">
    <button class="wri-btn wri-btn-tertiary"
            (click)="closeModal()">
      Cancel
    </button>
    <button data-testid="apply-modal-click" class="wri-btn wri-btn-primary"
            (click)="applySelections()">
      Apply
    </button>
  </div>

</div>

