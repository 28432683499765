import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { CustomerFeatureState } from '../../../../ecomm/store/features/customer';
import { Address } from '../../../../ecomm/types/address';
import { Cart } from '../../../../ecomm/types/cart.types';
import {
  LocationInfo,
  StoreHours
} from '../../../../ecomm/types/store-info.types';
import { formatBusinessDayTimings } from '../../../../ecomm/utils/format-business-day-timings';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { Buffer } from 'buffer';
import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';

@Component({
  selector: 'wri-cart-address',
  templateUrl: './cart-address.component.html',
  styleUrls: ['./cart-address.component.scss']
})
export class CartAddressComponent implements OnChanges {
  @Input() cart: Cart | null = null;

  @Input()
  public locationDetails: LocationInfo | null = null;

  @Input()
  public showPhoneNumber = false;

  @Input()
  public customerState: CustomerFeatureState | null = null;

  @Input()
  public orderLocationId: string | null = null;

  @Input()
  public showIcon = false;

  @Input()
  public showEditIcon = false;

  @Input()
  public showErrorText = false;

  @Input()
  public overrideReasonDescription = '';

  @Input()
  public showSecondaryAddress = true;

  handoffModeLabel: string | null = this.getHandoffLabel();

  cartAddress: Address | null = this.getCarryoutAddress();
  cartLocationName: string | null = this.getLocationName();
  locationAvailability: string | null = this.getStoreStatus();

  constructor(
    private userAccountService: CustomerWorkflowService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  ngOnChanges(): void {
    this.handoffModeLabel = this.getHandoffLabel();
    this.cartAddress = this.getCarryoutAddress();
    this.cartLocationName = this.getLocationName();
    this.locationAvailability = this.getStoreStatus();
  }

  private getHandoffLabel(): string {
    return this.isDelivery() ? 'delivery from' : 'carryout from';
  }

  public isDelivery(): boolean {
    return this.cart?.handoffMode?.toLowerCase() === 'delivery';
  }

  get toAddress() {
    return [
      this.cart?.deliveryAddress?.streetAddress,
      this.showSecondaryAddress ? this.cart?.deliveryAddress?.secondaryAddress : null,
      `${this.cart?.deliveryAddress?.locality}, ${this.cart?.deliveryAddress?.region} ${this.cart?.deliveryAddress?.postalCode}`
    ].filter(Boolean) as string[];
  }

  private getCarryoutAddress(): Address {
    return {
      streetAddress: this.locationDetails?.streetAddress1 ?? '',
      secondaryAddress: this.locationDetails?.streetAddress2 ?? '',
      description: this.locationDetails?.description ?? '',
      locality: this.locationDetails?.city ?? '',
      region: this.locationDetails?.state ?? '',
      postalCode: this.locationDetails?.postalCode ?? '',
      countryCode: this.locationDetails?.country ?? '',
      phoneNumber: this.locationDetails?.phoneNumber ?? '',
      latitude: this.locationDetails?.lat ?? 0,
      longitude: this.locationDetails?.long ?? 0
    };
  }

  private getLocationName(): string | null {
    return this.locationDetails?.name ?? null;
  }

  private getStoreStatus() {
    return !this.isDelivery() ? this.formatBusinessDayTimings() : null;
  }

  private getBusinessDayTimings(): StoreHours[] | null {
    const businessDayTiming = this.locationDetails?.storeTimings?.filter(
      (eachBusinessDay) => {
        return this.locationDetails?.businessDay === eachBusinessDay.startDay;
      }
    );
    return businessDayTiming ?? null;
  }

  private formatBusinessDayTimings() {
    return formatBusinessDayTimings(this.getBusinessDayTimings() ?? []);
  }

  public async onFavoriteLocation() {
    if (this.orderLocationId) {
      this.analyticsService.logGaEvent({ event: 'favorite_store' });
      await this.userAccountService.setFavoriteLocation(this.orderLocationId);
    }
  }

  public async unFavoriteLocation() {
    if (this.orderLocationId) {
      await this.userAccountService.unFavoriteLocation(this.orderLocationId);
    }
  }

  navigateToOrder(handoffMode: string) {
    const deliveryAddressStr = JSON.stringify(this.cart?.deliveryAddress);
    this.router.navigate(['/order'], {
      queryParams: {
        handoffMode: handoffMode,
        deliveryAddress:
          handoffMode === HandoffMode.delivery
            ? Buffer.from(deliveryAddressStr).toString('base64')
            : undefined
      }
    });
  }
}
