import { BaseModel } from '@wingstop/models/base.model';

export class AlertModel extends BaseModel {
  title: string = null;
  description: any[] = null;
  button: string = null;
  subTitle?: string = null;
  leadText?: string = null;
  closeButton?: string = null;
  hasCloseButton?: boolean = false;
  hasFormField?: boolean = false;
  callback?: Function = null;
  image?: string = null;
  topRightCloseButton?: boolean = false;
  name?: string = null;
  buttonAriaLabel?: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
