/* eslint-disable @typescript-eslint/no-explicit-any */
import { has } from 'lodash';

import {
  MenuItem,
  MenuModifier,
  MenuUpsellItem
} from '../types/store-info.types';

export const getCalorieRange = (
  data: MenuModifier | MenuItem | MenuUpsellItem
): string | number => {
  if (data && (data.maxCalories === null || data.maxCalories === undefined)) {
    // no range
    if (data.minCalories !== null && data.minCalories !== undefined) {
      if (data.minCalories > 0) {
        return `${data.minCalories} cal`;
      } else if (data.minCalories < 0) {
        if (
          (has(data, 'price') && (data as any).price === undefined) ||
          (data as any).price === 0
        ) {
          return `Removes ${Math.abs(data.minCalories)} cal`;
        }
        if (
          (has(data, 'priceDifference') &&
            (data as any).priceDifference === undefined) ||
          (data as any).priceDifference === 0
        ) {
          return `Removes ${Math.abs(data.minCalories)} cal`;
        }
        return `removes ${Math.abs(data.minCalories)} cal`;
      } else return '0 cal';
    }
    return '';
  } else {
    // full range
    if (
      data &&
      data.maxCalories !== null &&
      data.maxCalories !== undefined &&
      data.minCalories !== null &&
      data.minCalories !== undefined
    ) {
      if (data.maxCalories > 0) {
        return `${data.minCalories} \u2013 ${data.maxCalories} cal`;
      } else {
        if (
          (has(data, 'price') && (data as any).price === undefined) ||
          (data as any).price === 0
        ) {
          return `Removes ${Math.abs(data.maxCalories)} \u2013 ${Math.abs(
            data.minCalories
          )} cal`;
        }
        if (
          (has(data, 'priceDifference') &&
            (data as any).priceDifference === undefined) ||
          (data as any).priceDifference === 0
        ) {
          return `Removes ${Math.abs(data.maxCalories)} \u2013 ${Math.abs(
            data.minCalories
          )} cal`;
        }
        return `removes ${Math.abs(data.maxCalories)} \u2013 ${Math.abs(
          data.minCalories
        )} cal`;
      }
    }
    return '';
  }
};
