import { Component, Input } from '@angular/core';

import { Cart, CartItem } from '../../../../ecomm/types/cart.types';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { QuantityChangedEvent } from '../../../common/types/cart.types';

@Component({
  selector: 'wri-review-order-items',
  templateUrl: './review-order-items.component.html',
  styleUrls: ['./review-order-items.component.scss']
})
export class ReviewOrderItemsComponent {
  @Input() cart: Cart | null = null;
  @Input() public isReadOnly = false;
  @Input() page? = '';

  constructor(private cartService: CartWorkflowService) {}

  public async onQuantityChange($event: QuantityChangedEvent) {
    await this.cartService.updateQuantity(
      $event.cartId,
      $event.quantity,
      $event.lineItemId
    );
  }

  identify(item: CartItem) {
    return item.lineItemId;
  }
}
