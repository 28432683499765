import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';

@Component({
  selector: 'wri-handoff-mode-selector',
  template: `
    <button
      data-testid="carryout-selector"
      class="handoffmode-selector carryout"
      [class.active]="handoffMode === 'carryout'"
      (click)="handoffModeChange.emit('carryout')"
    >
      <wri-icon icon="wri-carryout" class="hide-small-screen"></wri-icon>
      <span> carryout </span>
    </button>
    <button
      data-testid="delivery-selector"
      class="handoffmode-selector delivery"
      [class.active]="handoffMode === 'delivery'"
      (click)="handoffModeChange.emit('delivery')"
    >
      <wri-icon icon="wri-delivery" class="hide-small-screen"></wri-icon>
      <span>delivery</span>
    </button>
  `,
  styleUrls: ['./handoff-mode-selector.component.scss']
})
export class HandoffModeSelectorComponent {
  @Input() handoffMode: HandoffMode = 'carryout';
  @Output() handoffModeChange = new EventEmitter<HandoffMode>();
}
