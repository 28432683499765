import { BaseModel } from '@wingstop/models/base.model';
import { Schedule } from '@wingstop/models/order/schedule.model';
import { CalendarRange } from '@wingstop/models/order/calendar-range.model';
import moment from 'moment-mini';
import { GlobalService } from '@wingstop/services/global.services';
import { CustomFieldLocation } from '@wingstop/models/location/custom-field.model';
import { Menu } from '@wingstop/models/menu.model';

export class Location extends BaseModel {
  id: any = null;
  advanceonly: boolean = null;
  advanceorderdays: 7 = null;
  allowhandoffchoiceatmanualfire: boolean = null;
  availabilitymessage: string = null;
  oloAttributes: string[] = [];
  attributes: string[] = [];
  brand: string = null;
  calendars: Schedule[] = null;
  candeliver: boolean = null;
  canpickup: boolean = null;
  city: string = null;
  country: string = null;
  crossstreet: string = null;
  customerfacingmessage: string = null;
  deliveryfee: number = null;
  distance: number = null;
  extref: number = null;
  hasolopass: boolean = null;
  isavailable: boolean = null;
  latitude: number = null;
  longitude: number = null;
  maximumpayinstoreorder: number = null;
  metadata: string = null;
  minimumdeliveryorder: number = null;
  mobileurl: string = null;
  name: string = null;
  nomnom: any = {
    primary_pci_provider: null,
    projected_open_date: null,
    redirect_to_pilot: null,
  };
  productrecipientnamelabel: string = null;
  requiresphonenumber: boolean = null;
  slug: string = null;
  specialinstructionsmaxlength: number = null;
  state: string = null;
  storename: string = null;
  streetaddress: string = null;
  supportedcardtypes: string = null;
  supportsbaskettransfers: boolean = null;
  supportscoupons: boolean = null;
  supportscurbside: boolean = null;
  supportsdinein: boolean = null;
  supportsdispatch: boolean = null;
  supportsdrivethru: boolean = null;
  supportsfeedback: boolean = null;
  supportsgrouporders: boolean = null;
  supportsguestordering: boolean = null;
  supportsloyalty: boolean = null;
  supportsmanualfire: boolean = null;
  supportsnationalmenu: boolean = null;
  supportsonlineordering: boolean = null;
  supportsproductrecipientnames: boolean = null;
  supportsspecialinstructions: boolean = null;
  supportssplitpayments: boolean = null;
  supportstip: boolean = null;
  telephone: string = null;
  url: string = null;
  utcoffset: number = null;
  zip: string = null;
  created_at: string = null;
  updated_at: string = null;
  deliveryarea: string = null;
  header_image_url: string = null;
  'dispatch-distance': number = null;
  supportedtimemodes: string[] = [];
  customfields: CustomFieldLocation[] = [];
  restaurant: Location;
  menu: Menu;
  acceptsordersbeforeopening: boolean;
  acceptsordersuntilclosing: boolean;

  // search web service doesn't return schedule in results (very inefficient)
  // to compensate, we call the service if "calendars" is missing
  schedulePromise: any;

  // derivative fields
  carryoutHoursLabel: any[];
  dispatchHoursLabel: any[];
  curbsideHoursLabel: any[];
  dineinHoursLabel: any[];
  isOpen: boolean = null;
  // For Covid-19
  dispatchOpen: boolean = null;
  dispatchHoursLabelToday: string;
  hasDispatchHoursForToday: Boolean;
  hasCurbsideHoursForToday: Boolean;
  hasDineinHoursForToday: boolean;
  isCurbsideOpen: Boolean;
  // End Covid-19
  hoursLabelToday: string;
  curbsideHoursLabelToday: string;
  dineinHoursLabelToday: string;
  directionsURL = 'https://www.google.com/maps/dir/?api=1';
  hasHoursForToday: boolean;
  isTempClosed: boolean;

  constructor(values?: any) {
    super();

    if (values) {
      if (values.customfields) {
        values.customfields = values.customfields.map(
          (value: any) => new CustomFieldLocation(value)
        );
      }
      if (values.calendars && values.calendars.calendar) {
        values.calendars = values.calendars.calendar.map(
          (value: any) =>
            new Schedule({ ...value, ...{ utcoffset: values.utcoffset } })
        );
      }

      if (values.latitude && values.longitude) {
        this.directionsURL +=
          '&destination=' + values.latitude + ',' + values.longitude;
      }
      if (values.restaurant && !(values.restaurant instanceof Location)) {
        values.restaurant = new Location(values.restaurant);
      }
      if (values.restaurant) {
        this.restaurant = values.restaurant;
      }
    }

    this.initialize(values);
  }

  // could be used for id tag of DOM elements
  public getGenericID() {
    return 'location' + this.id;
  }

  private generateLabels(schedule: Schedule[], which: string) {
    let daysOfTheWeek = [
      { short: 'Sun', long: 'Sunday' },
      { short: 'Mon', long: 'Monday' },
      { short: 'Tue', long: 'Tuesday' },
      { short: 'Wed', long: 'Wednesday' },
      { short: 'Thu', long: 'Thursday' },
      { short: 'Fri', long: 'Friday' },
      { short: 'Sat', long: 'Saturday' },
    ];

    const s = this.getHoursFrom(schedule, which);
    const hours = s ? this.getHoursFrom(schedule, which).ranges : null;

    // clear the hours
    let result = [];
    // for every set of hours (there should be 7 exactly unless the store is closed on a day)
    for (let x = 0; x < 7; x++) {
      // try to find the hours for the desired day
      let foundHours = null;
      if (hours) {
        foundHours = hours.find((current) => {
          return daysOfTheWeek[x].short === current.weekday;
        });
      }
      // if we couldn't find it, then the store is closed
      result.push(
        this.getLabelObj(foundHours ? foundHours : daysOfTheWeek[x].long)
      );
    }

    return result;
  }

  // Checks if this location supports Coke Freestyle
  // Data is currently coming over in two different places so check both
  public hasCokeFreestyle() {
    if (Array.isArray(this.oloAttributes) && this.oloAttributes.length > 0) {
      return this.oloAttributes.some(
        (attribute) => attribute === 'Coca-Cola Freestyle'
      )
        ? true
        : false;
    }

    if (Array.isArray(this.attributes) && this.attributes.length > 0) {
      return this.attributes.some(
        (attribute) => attribute === 'Coca-Cola Freestyle'
      )
        ? true
        : false;
    }
    return false;
  }

  public canCarryout() {
    return (
      this.supportsonlineordering && this.canpickup && !this.availabilitymessage
    );
  }

  public canDeliver() {
    return (
      this.supportsonlineordering && (this.supportsdispatch || this.candeliver)
    );
  }

  public isDarkKitchen() {
    return (this.supportsdispatch || this.candeliver) && !this.canpickup;
  }

  public isPilotLocation() {
    return this.nomnom.redirect_to_pilot;
  }

  private determineIfOpen() {
    this.isOpen = this.isOpenForBusiness();
    this.dispatchOpen = this.isOpenForDispatch();
    this.isCurbsideOpen = this.isOpenForCurbside();
  }

  private getHoursForToday(hours: string) {
    try {
      let now = GlobalService.getNow();

      let myOffset = +(now.utcOffset() / 60);
      let locationOffset = +this.utcoffset;
      let hoursDiff = myOffset - locationOffset;

      // Setting to dispatch hours COVID-19
      let covid = {
        business: this.getBusinessHours().ranges,
        dispatch: this.getDispatchHours()
          ? this.getDispatchHours().ranges
          : this.getBusinessHours().ranges,
        curbside: this.getCurbsideHours()
          ? this.getCurbsideHours().ranges
          : null,
        dinein: this.getDineinHours() ? this.getDineinHours().ranges : null,
      };
      let allHours = covid[hours];
      // the day-before business hours aren't instantly removed, so we check them all
      for (let x = 0; x < allHours.length; x++) {
        // Setting to dispatch hours COVID-19
        let hoursForToday = covid[hours][x];

        let todayLocationOpen = moment(hoursForToday.start).add(
          hoursDiff,
          'hours'
        );
        let todayLocationClose = moment(hoursForToday.end).add(
          hoursDiff,
          'hours'
        );

        if (now.isSame(todayLocationOpen, 'day')) {
          return {
            start: hoursForToday.start,
            end: hoursForToday.end,
            hasHoursToday: true,
            openNow: now.isBetween(todayLocationOpen, todayLocationClose),
          };
        }
      }
      return { hasHoursToday: false, openNow: false };
    } catch (e) {
      return { hasHoursToday: false, openNow: false };
    }
  }

  public isOpenForBusiness() {
    return this.getHoursForToday('business').openNow;
  }
  public isOpenForDispatch() {
    return this.getHoursForToday('dispatch').openNow;
  }

  public isOpenForCurbside() {
    return this.getHoursForToday('curbside').openNow;
  }

  public getBusinessHours() {
    return this.getHours('business');
  }

  public getDispatchHours() {
    return this.getHours('dispatch');
  }

  public getDineinHours() {
    return this.getHours('dinein');
  }

  public getCurbsideHours() {
    return this.getHours('curbsidepickup');
  }

  public getHours(which: string) {
    return this.getHoursFrom(this.calendars, which);
  }

  private getHoursFrom(calendars: Schedule[], which: string): Schedule {
    for (let x = 0; calendars && x < calendars.length; x++) {
      if (calendars[x].type === which) {
        return calendars[x];
      }
    }

    return null;
  }

  public getLabel(
    range: CalendarRange | { start: moment.Moment; end: moment.Moment }
  ) {
    if (!range || !range.start || !range.end) {
      return 'Closed';
    }

    let beginLabel =
      range.start.format('hmm') === '1200'
        ? 'Midnight'
        : range.start.format('h:mm A');
    let endLabel =
      range.end.format('hmm') === '1200'
        ? 'Midnight'
        : range.end.format('h:mm A');

    if (beginLabel === 'Midnight' && endLabel === 'Midnight') {
      return '24 hours';
    } else {
      return range.start.format('h:mm A') + ' to ' + endLabel;
    }
  }

  private getLabelObj(range: CalendarRange | string) {
    return typeof range === 'string'
      ? { day: range, hours: this.getLabel(null) }
      : { day: range.start.format('dddd'), hours: this.getLabel(range) };
  }

  public getHoursLabelFor(which: string, offset: number) {
    let hours = this.getHours(which);
    return hours ? this.getLabel(hours.ranges[offset]) : null;
  }

  public isComingSoon(): boolean {
    const now = moment().utc();
    return now.isBefore(moment(this.nomnom.projected_open_date), 'day');
  }

  public isRecentlyOpen(): boolean {
    if (this.isTempClosed) {
      return false;
    }

    const verifyDateRange = () => {
      const openDate = moment(this.nomnom.projected_open_date).utc();
      const thirtyDaysAgo = moment().subtract(30, 'days').utc();
      return (
        openDate.isSameOrAfter(thirtyDaysAgo, 'day') &&
        openDate.isSameOrBefore(moment().utc(), 'day')
      );
    };

    return this.nomnom.projected_open_date ? verifyDateRange() : false;
  }
}
