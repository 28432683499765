import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChangeContext, Options } from 'ngx-slider-v2';
import { BehaviorSubject, combineLatest, map, Subscription, tap } from 'rxjs';

import { StoreInfoFeature } from '../../../../ecomm/store/features/store-info';
import { Flavor } from '../../../../ecomm/types/flavor.types';
import { FlavorsWorkflowService } from '../../../../ecomm/workflows/flavors/flavors-workflow.service';
import { FlavorSpiceHeading } from '../../../common/types/modifiers';
import {
  IS_DRY_DEFAULT,
  IS_WET_DEFAULT,
  MAX_HEAT_SCALE,
  MIN_HEAT_SCALE
} from '../../../common/constants/app-constants';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'wri-connected-flavors-page',
  templateUrl: './connected-flavors-page.component.html',
  styleUrls: ['./connected-flavors-page.component.scss']
})
export class ConnectedFlavorsPageComponent implements OnInit {
  readonly flavorSpiceHeading = FlavorSpiceHeading;

  public readonly heatScaleSliderConfig: Options = {
    floor: MIN_HEAT_SCALE,
    ceil: MAX_HEAT_SCALE,
    showOuterSelectionBars: false,
    ariaLabel: 'Find the flavor',
    translate: (value: number): string => {
      if (value === 0) {
        return 'No heat';
      } else if (value === 1) {
        return 'A little heat';
      } else if (value === 2) {
        return 'More than a little heat';
      } else if (value === 3) {
        return 'Hot';
      } else if (value === 4) {
        return 'Hotter';
      } else {
        return 'All the heat';
      }
    },
  };

  public selectedMinHeatScaleCount$ = new BehaviorSubject(MIN_HEAT_SCALE);
  public selectedMaxHeatScaleCount$ = new BehaviorSubject(MAX_HEAT_SCALE);
  public selectedIsWetValue$ = new BehaviorSubject(IS_WET_DEFAULT);
  public selectedIsDryValue$ = new BehaviorSubject(IS_DRY_DEFAULT);
  public allFlavors$ = new BehaviorSubject<Flavor[]>([]);

  public selectedMinHeatScaleCount = MAX_HEAT_SCALE;
  public selectedMaxHeatScaleCount = MAX_HEAT_SCALE;
  public selectedIsWetValue = IS_WET_DEFAULT;
  public selectedIsDryValue = IS_DRY_DEFAULT;
  public allFlavors: Flavor[] = [];

  public selectedFlavors$ = combineLatest([
    this.selectedMinHeatScaleCount$,
    this.selectedMaxHeatScaleCount$,
    this.selectedIsWetValue$,
    this.selectedIsDryValue$,
    this.allFlavors$
  ]).pipe(
    tap(([min, max, wet, dry, flavors]) => {
      this.selectedMinHeatScaleCount = min;
      this.selectedMaxHeatScaleCount = max;
      this.selectedIsWetValue = wet;
      this.selectedIsDryValue = dry;
      this.allFlavors = flavors;
    }),
    map(([min, max, wet, dry, flavors]) => {
      return flavors
        .filter((f) => f.isActive)
        .filter((f) => f.heatScale >= min)
        .filter((f) => f.heatScale <= max)
        .filter((f) => (!f.isDry ? wet : true))
        .filter((f) => (f.isDry ? dry : true));
    })
  );
  public selectedFlavors: Flavor[] = [];

  public storeInfo$ = this.store.select(StoreInfoFeature.selectStoreInfo);
  public storeSlug?: string;

  private subscription = new Subscription();

  constructor(
    private flavorsService: FlavorsWorkflowService,
    private store: Store,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectedFlavors$.subscribe((selectedFlavors) => {
        this.selectedFlavors = selectedFlavors;
      })
    );
    this.subscription.add(
      this.storeInfo$.subscribe(
        (storeInfo) =>
          (this.storeSlug = storeInfo?.storeDetails.slug ?? undefined)
      )
    );

    this.flavorsService.getFlavors().then((flavors) => {
      this.allFlavors$.next(flavors ?? []);
    });
  }

  onChangeIsWet() {
    this.selectedIsWetValue$.next(!this.selectedIsWetValue);
  }

  onChangeIsDry() {
    this.selectedIsDryValue$.next(!this.selectedIsDryValue);
  }

  onUserChange(changeContext: ChangeContext): void {
    this.selectedMinHeatScaleCount$.next(
      changeContext.value ?? MIN_HEAT_SCALE
    );
    this.selectedMaxHeatScaleCount$.next(
      changeContext.highValue ?? MAX_HEAT_SCALE
    );
    this.analyticsService.logGaEvent({
      event: 'flavor_slide',
    });
  }
}
