
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as MaybeResponse from '../../types/maybe-response';
import { RoundupCharity } from '../../types/roundup-charity.types';
import { RoundupCharityRepository } from '../../repositories/roundup-charity/roundup-charity.repository';

@Injectable({ providedIn: 'root' })
export class RoundupCharityService {
  constructor(private roundupCharityRepository: RoundupCharityRepository) {}

  public getRoundupCharityData(): Observable<MaybeResponse.MaybeResponse<RoundupCharity>> {
    return this.roundupCharityRepository.getRoundupCharityData().pipe(
      map(
        MaybeResponse.mapData((data) => {
          if (!data) return undefined;
          return data;
        })
      )
    );
  }
}
