<div class="desktop-only">
  <ng-container *ngIf="isUserLoggedIn; else guestDesktop">
    <a
      data-testid="my-account"
      class="dropdown-item"
      (click)="onCtaClick($event, '/account/settings')"
      href="#"
      data-cy="MyAccount"
      tabindex="0"
    >
      My Account
    </a>
    <a
      data-testid="my-orders"
      class="dropdown-item"
      (click)="onCtaClick($event, '/order/recent')"
      href="#"
      data-cy="MyOrders"
      tabindex="0"
    >
      My Orders
    </a>
    <a
      data-testid="logout"
      class="dropdown-item"
      (click)="openCommonModal($event)"
      href="#"
      data-cy="LogOut"
      tabindex="0"
    >
      Log Out
    </a>
  </ng-container>

  <ng-template #guestDesktop>
    <a
      data-testid="log-in"
      class="dropdown-item"
      (click)="onCtaClick($event, '/account/login')"
      data-cy="LogIn"
      tabindex="0"
    >
      Log In
    </a>
    <a
      data-testid="sign-up"
      class="dropdown-item"
      (click)="onCtaClick($event, '/account/signup')"
      data-cy="SignUp"
      tabindex="0"
    >
      Sign Up
    </a>
  </ng-template>
</div>
<div class="btn-group mobile-only" *ngIf="!showOnlyLogoutButton">
  <ng-container *ngIf="isUserLoggedIn; else guestMobile">
    <button
      type="button"
      class="wri-btn wri-btn-primary"
      (click)="onCtaClick($event, '/account/settings')"
      data-cy="MyAccount"
    >
      My Account
    </button>
    <button
      type="button"
      class="wri-btn wri-btn-primary"
      (click)="onCtaClick($event, '/order/recent')"
      data-cy="MyOrders"
    >
      My Orders
    </button>
  </ng-container>
  <ng-template #guestMobile>
    <button
      type="button"
      class="wri-btn wri-btn-primary"
      (click)="onCtaClick($event, '/account/login')"
      data-cy="LogIn"
    >
      Log In
    </button>
    <button
      type="button"
      class="wri-btn wri-btn-primary"
      (click)="onCtaClick($event, '/account/signup')"
    >
      Sign Up
    </button>
  </ng-template>
</div>

<div class="btn-group mobile-only">
  <ng-container *ngIf="showOnlyLogoutButton && isUserLoggedIn">
    <button
      type="button"
      class="wri-btn wri-btn-primary"
      (click)="openCommonModal($event)"
    >
      Log Out
    </button>
  </ng-container>
</div>

<ng-template
  #logoutConfirmationPopupModal
  let-close="close"
  let-dismiss="dismiss"
>
  <wri-common-modal [close]="close" [dismiss]="dismiss" [closeIcon]="false">
    <div class="logout-popup-modal">
      <div class="wri-modal__wrapper">
        <div class="wri-modal__title">Log out?</div>
        <div
          data-testid="logout-popup-modal-description"
          class="wri-modal__description"
        >
          <p>Are you sure you want to log out?</p>
        </div>
        <div class="wri-modal__btn-wrapper">
          <button
            data-testid="logout-popup-modal-yes"
            class="wri-btn wri-btn-primary"
            aria-label="Yes"
            (click)="handleOnLogout($event); close($event)"
          >
            Log out
          </button>
          <button
            data-testid="logout-popup-modal-no"
            class="wri-btn wri-btn-tertiary"
            aria-label="No"
            (click)="close($event)"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
