<div *ngIf="order" class="order-container" data-testid="order-confirmation">
  <div class="order-section">
    <div data-testid="order-section-title" class="order-section-title">
      Thanks {{ order?.guest?.firstName }}!
    </div>
    <hr class="wri-divider" aria-hidden="true"/>
    <div class="order-detail">
      <div class="order-sub-section">
        <span class="order-sub-section-handoffMode">
          {{ (order | calFulfillmentTimePipe)[0] }}
        </span>

        <ng-container *ngIf="!order?.cart?.asap">
          <div class="order-sub-section-showFT">
            {{ (order | calFulfillmentTimePipe)[1] }}
          </div>
        </ng-container>

        <ng-container *ngIf="order?.cart?.asap">

          <div class="order-sub-section-showFT">
            {{order.cart.readyTimes.minMinutes}} - {{order.cart.readyTimes.maxMinutes}} minutes
          </div>

          <div class="order-sub-section-time-range">
            <wri-icon icon="wri-clock"></wri-icon>
            {{ (order | calFulfillmentTimePipe)[1] }}
          </div>

        </ng-container>

        <p *ngIf="!isPastOrder" class="order-sub-section-confirmation">
          Your order has been sent and you should receive an email confirmation
          shortly.
        </p>

      </div>

      <ng-container *ngIf="!isPastOrder; else pastOrderDate">
        <hr class="wri-divider" aria-hidden="true"/>
        <div class="order-placed flex column-flex">
          <span class="order-placed-title spacing-bottom">
            <wri-icon icon="wri-black-tick"></wri-icon>
            <span
              class="order-placed-title-sub"
              data-testid="order-placed-title-sub"
            >
              {{
              order?.status === 'cancelled'
                ? 'Order Canceled'
                : 'Order Placed'
              }}
            </span>
          </span>

          <span data-testid="order-date" class="order-placed-section spacing-bottom">
            {{
            getOrderScheduledTime(
              order?.createdTimestamp,
              order?.locationDetails?.location.timeZone
            )
            }}
          </span>
          <span data-testid="order-id" class="order-placed-section">
            Order #: {{ order?.orderNumber }}
          </span>
        </div>
      </ng-container>
      <ng-template #pastOrderDate>
        <hr class="wri-divider" aria-hidden="true"/>
        <div class="order-placed flex column-flex">
          <span class="order-placed-title spacing-bottom">
            <wri-icon icon="wri-black-tick"></wri-icon>
            <span
              class="order-placed-title-sub"
              data-testid="order-placed-title-sub"
            >
              {{
              order?.status === 'cancelled'
                ? 'Order Canceled'
                : 'Order Placed'
              }}
            </span>
          </span>

          <span
            data-testid="order-date"
            class="order-placed-section spacing-bottom"
          >
            {{
            getOrderScheduledTime(
              order?.createdTimestamp,
              order?.locationDetails?.location.timeZone
            )
            }}
          </span>
          <span data-testid="order-id" class="order-placed-section">
            Order #: {{ order?.orderNumber }}
          </span>
        </div>
      </ng-template>

      <ng-container *ngIf="!isPastOrder && order?.status !== 'cancelled'">
        <hr class="wri-divider" aria-hidden="true"/>
        <div
          *ngIf="!isPastOrder"
          class="order-instructions"
          data-testid="order-instructions"
        >
        <ng-container *ngIf="order?.location?.pickupInstructions">
          {{ order?.location?.pickupInstructions }}
        </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="order?.status === 'cancelled'">
        <hr class="wri-divider" aria-hidden="true"/>
        <div class="order-instructions" data-testid="order-instructions">
          Order has been canceled and a refund is being issued.
        </div>
      </ng-container>

      <hr class="wri-divider" aria-hidden="true"/>
    </div>
  </div>
  <div class="order-address-price">
    <div class="order-address">
      <wri-cart-address
        [showIcon]="true"
        [locationDetails]="order?.locationDetails | getLocationDetails : order?.cart?.handoffMode"
        [cart]="order?.cart"
        [showPhoneNumber]="true"
        [customerState]="customerState$ | async"
        [orderLocationId]="order?.location?.id"
      ></wri-cart-address>
      <div
        data-testid="delivery-instructions"
        class="order-address-delivery-instructions"
        *ngIf="
          order?.cart.handoffMode === 'delivery' &&
          order?.delivery.deliveryInstructions
        "
      >
        Delivery Instructions: {{ order?.delivery.deliveryInstructions }}
      </div>
    </div>
    <div class="order-price">
      <hr class="wri-divider first-hr-line" aria-hidden="true"/>
      <wri-review-order-items [cart]="order?.cart" [isReadOnly]="true">
      </wri-review-order-items>
      <hr aria-hidden="true"/>

      <wri-cart-price-summary
        [cart]="order?.cart"
        [handoffModeFromOrderDetails]="true"
      >
      </wri-cart-price-summary>

      <ng-container
        *ngIf="
          isCancelOrderFeatureFlagEnabled &&
            isPastOrder &&
            isOrderCancellable() &&
            order?.status === 'scheduled';
          then cancelOrderTemplate
        "
      >
      </ng-container>
    </div>
  </div>
  <div *ngIf="!isPastOrder" class="charities-section">
    <wri-charities-section></wri-charities-section>
  </div>
  <div *ngIf="!isPastOrder" class="marketing-section">
    <wri-marketing-section [page]="'confirmation'"></wri-marketing-section>
  </div>
</div>
<wri-partial-outage-modal></wri-partial-outage-modal>
<ng-template #cancelOrderTemplate>
  <hr class="margin-top-bottom" aria-hidden="true"/>
  <div class="order-cancel-wrapper" data-testid="order-cancel-wrapper">
    <button
      (click)="handleCancelOrder()"
      class="wri-btn wri-btn-secondary-error"
      aria-label="Cancel Order"
      data-testid="cancel-order-btn"
    >
      Cancel Order
    </button>
  </div>
</ng-template>

<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span
        data-testid="cancel-order-modal-title"
        class="wri-modal__title"
      >
        Cancel Order?
      </span>
      <span
        data-testid="cancel-order-modal-description"
        class="wri-modal__description"
      >
        Are you sure you want to cancel your order
        {{
        order
          | calFulfillmentTimePipe
          | join : ''
          | replace : 'Scheduled' : ''
          | replace : 'carryout' : ''
          | replace : 'delivery' : ''
        }}?
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="cancel-order-modal-login"
          class="wri-btn wri-btn-primary"
          aria-label="Cancel Order"
          (click)="cancelOrder()"
        >
          CANCEL ORDER
        </button>
        <button
          data-testid="cancel-order-modal-cancel"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="close($event)"
        >
          DISMISS
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
