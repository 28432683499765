import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, from, NEVER, of, Subscription, switchMap, tap } from 'rxjs';
import { CartFeature } from '../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../ecomm/store/features/store-info';
import { FeaturesState } from '../../../ecomm/store/types/features-state';

import { Cart } from '../../../ecomm/types/cart.types';
import { StoreInfo } from '../../../ecomm/types/store-info.types';

import { BasePageService } from './base-page-service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { CurrentUrlService } from '../../../ecomm/utils/current-url/current-url.service';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { StoreSnapshotService } from '../../../ecomm/utils/store-snapshot/store-snapshot.service';
import { StoreInfoWorkflowService } from '../../../ecomm/workflows/store-info/store-info-workflow.service';
import { CartWorkflowService } from '../../../ecomm/workflows/cart/cart-workflow.service';
import { CustomerWorkflowService } from '../../../ecomm/workflows/customer/customer-workflow.service';
import { ActiveOfferFeature } from '../../../ecomm/store/features/active-offer';
import { SearchLocationWorkflowService } from '../../../ecomm/workflows/search-location/search-location-workflow.service';
import { HandoffMode } from '../../../ecomm/types/selected-handoff-mode.types';

@Injectable()
export class BagPageService extends BasePageService {
  private subscription = new Subscription();
  public storeInfoState: StoreInfoFeatureState | undefined;
  public cart: Cart | undefined | null = null;

  constructor(
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private cartService: CartWorkflowService,
    private storeSnapshotService: StoreSnapshotService,
    private currentUrlService: CurrentUrlService,
    @Inject(AsynchronousDispatcher)
    asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    redirectService: RedirectService,
    store: Store,
    userAccountService: CustomerWorkflowService,
    private locationDetailsWorkflowService: SearchLocationWorkflowService
  ) {
    super(redirectService, store, userAccountService, asynchronusDispatcher);
  }

  loadData$(route: ActivatedRouteSnapshot) {
    this.subscribeToStoreInfoState();
    return from(this.cartService.getOrCreate(route)).pipe(
      tap((cart) => {
        this.cart = cart;
      }),
      switchMap((cart) =>
        this.storeInfoWorkflowService.getStoreInfoById(
          cart?.locationId ?? '',
          cart?.handoffMode ?? HandoffMode.carryout,
          true
        )
      ),
      switchMap((data) =>
        from(
          this.locationDetailsWorkflowService.getLocationById({
            locationId: data?.storeDetails.id ?? ''
          })
        )
      ),
      switchMap(() => this.storeSnapshotService.get()),
      switchMap((state) => {
        this.navigateTo(
          StoreInfoFeature.selectStoreInfo(state),
          CartFeature.selectCart(state)
        );
        const activeOfferState = ActiveOfferFeature.selectActiveOffer(state);
        if (activeOfferState) {
          return from(
            this.storeInfoWorkflowService.getOfferDetails(
              activeOfferState,
              this.storeInfoState?.storeInfo?.storeDetails.id || ''
            )
          );
        }
        return of(null);
      }),
      tap(async (result) => {
        if (result) {
          await this.asynchronusDispatcher.dispatch(
            ActiveOfferFeature.actions.setState({
              activeOffer: result.code,
              activeOfferDetails: result
            })
          );
        }
      })
    );
  }

  timeout$ = () => NEVER;

  isInInvalidState(): boolean {
    return false;
  }

  private subscribeToStoreInfoState(): void {
    const storeInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter(Boolean));

    this.subscription.add(
      storeInfo$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  private navigateTo(
    storeInfo: StoreInfo | null | undefined,
    cart: Cart | null | undefined
  ): void {
    if (storeInfo === null || cart === null) {
      this.currentUrlService.goToOrderPage(); // navigate to wingstop.com/order
    }
  }
}
