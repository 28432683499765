import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { MenuItem, StoreInfo } from '../../../types/store-info.types';
import { FeatureState } from '../../types/feature-state';

export type StoreInfoFeatureState = FeatureState<StoreInfo, 'storeInfo'> &
  FeatureState<MenuItem, 'selectedItem'>;

const initialStoreInfoFeatureState: StoreInfoFeatureState = {
  isLoading: false,
  error: null,
  storeInfo: null,
  selectedItem: null
};

const StoreInfoActions = createActionGroup({
  source: 'StoreInfo Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{
      error?: string;
      storeInfo?: StoreInfo;
      selectedItem: MenuItem | null;
    }>(),
    'Reset To Default': emptyProps()
  }
});

const StoreInfoReducer = createReducer(
  initialStoreInfoFeatureState,
  on(
    StoreInfoActions.setIsLoading,
    (state): StoreInfoFeatureState => ({ ...state, isLoading: true })
  ),
  on(StoreInfoActions.setState, (state, action): StoreInfoFeatureState => {
    return {
      ...state,
      isLoading: false,
      error: action.error ?? null,
      storeInfo: action.storeInfo ?? state.storeInfo ?? null,
      selectedItem: action.selectedItem ?? null
    };
  }),
  on(
    StoreInfoActions.resetToDefault,
    (state): StoreInfoFeatureState => ({
      ...state,
      ...initialStoreInfoFeatureState
    })
  )
);

const StoreInfoFeatureKey = 'storeInfo';

const StoreInfoFeature = createFeature({
  name: StoreInfoFeatureKey,
  reducer: StoreInfoReducer
});

export default {
  ...StoreInfoFeature,
  actions: StoreInfoActions,
  initialState: initialStoreInfoFeatureState
};
