import { Component, Input } from '@angular/core';
@Component({
  selector: 'wri-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})

export class CommonModalComponent {

  @Input() close?: () => void;
  @Input() dismiss?: () => void
  @Input() closeIcon? = true;
  
  onModalClose(event: Event){
    event.preventDefault();
    this.close && this.close()
  }
}