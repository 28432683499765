import * as t from 'io-ts';
import { CondPairUnary } from 'lodash';
import { Observable } from 'rxjs';

import { MaybeResponse } from './maybe-response';

/* #region  Optional<T> */
export type Optional<T> = T | null;

export type OptionalC<TC extends t.Mixed> = t.UnionC<[TC, t.NullC]>;

export function optional<TC extends t.Mixed>(codec: TC): OptionalC<TC> {
  return t.union([codec, t.null], `OptionalC<${codec.name}>`);
}
/* #endregion */

/* #region  EcommAPIError */
export const EcommAPIError = t.type(
  {
    code: t.string,
    message: t.string,
    customerFacingMessage: t.string
  },
  'EcommAPIError'
);

export type EcommAPIError = t.TypeOf<typeof EcommAPIError>;
/* #endregion */

export type EcommAPIErrorResponse = {
  error?: Optional<EcommAPIError>;
};

/* #region  EcommAPIResponse<T> */
export type EcommAPIResponse<T> = {
  data: Optional<T>;
  error: Optional<EcommAPIError>;
  status?: Optional<number>;
};

export type EcommAPIResponseC<TC extends t.Mixed> = t.Type<
  EcommAPIResponse<t.TypeOf<TC>>,
  EcommAPIResponse<t.OutputOf<TC>>,
  unknown
>;

export function ecommApiResponse<TC extends t.Mixed>(
  codec: TC
): EcommAPIResponseC<TC> {
  return t.type(
    {
      data: optional(codec),
      error: optional(EcommAPIError)
    },
    `EcommAPIResponse<${codec.name}>`
  );
}
/* #endregion */

/* #region  EcommWebSocketResponse<K, T> */
export type EcommWebSocketResponse<K extends string, T> = {
  type: K;
  data?: Optional<T>;
  error?: Optional<EcommAPIError>;
};

export type EcommWebSocketResponseC<
  KC extends t.Mixed,
  TC extends t.Mixed
> = t.Type<
  EcommWebSocketResponse<t.TypeOf<KC>, t.TypeOf<TC>>,
  EcommWebSocketResponse<t.OutputOf<KC>, t.OutputOf<TC>>,
  unknown
>;

export function ecommWebSocketResponse<KC extends t.Mixed, TC extends t.Mixed>(
  typeCodec: KC,
  dataCodec: TC
): EcommWebSocketResponseC<KC, TC> {
  return t.intersection(
    [
      t.type(
        {
          type: typeCodec
        },
        `EcommAPIResponseRequired<${typeCodec.name}, ${dataCodec.name}>`
      ),
      t.partial(
        {
          data: optional(dataCodec),
          error: optional(EcommAPIError)
        },
        `EcommAPIResponseOptional<${typeCodec.name}, ${dataCodec.name}>`
      )
    ],
    `EcommAPIResponse<${typeCodec.name}, ${dataCodec.name}>`
  );
}
/* #endregion */

/* #region  HttpError<T> */
export type HttpError<T> = { error: T; status?: number };

export type HttpErrorC<TC extends t.Mixed> = t.Type<
  HttpError<t.TypeOf<TC>>,
  HttpError<t.OutputOf<TC>>,
  unknown
>;

export function httpError<TC extends t.Mixed>(codec: TC): HttpErrorC<TC> {
  return t.intersection(
    [
      t.type({ error: codec }, `HttpErrorRequired<${codec.name}>`),
      t.partial({ status: t.number }, `HttpErrorOptional<${codec.name}>`)
    ],
    `HttpError<${codec.name}>`
  );
}
/* #endregion */

/* #region  TimeSpan */
export const TimeSpan = t.type({
  ticks: t.number,
  days: t.number,
  hours: t.number,
  milliseconds: t.number,
  microseconds: t.number,
  nanoseconds: t.number,
  minutes: t.number,
  seconds: t.number,
  totalDays: t.number,
  totalHours: t.number,
  totalMilliseconds: t.number,
  totalMicroseconds: t.number,
  totalNanoseconds: t.number,
  totalMinutes: t.number,
  totalSeconds: t.number
});

export type TimeSpan = t.TypeOf<typeof TimeSpan>;
/* #endregion */

/* #region  RecordOf<T> */
export type RecordOf<T> = Record<string, T>;

export type RecordOfC<TC extends t.Mixed> = t.RecordC<t.StringC, TC>;

export function recordOf<TC extends t.Mixed>(codec: TC): RecordOfC<TC> {
  return t.record(t.string, codec);
}
/* #endregion */

/* #region PreviouslyCaught<T> */
const PREVIOUSLY_CAUGHT = Symbol.for('previously_caught');

export type PreviouslyCaught<T> = {
  _tag: typeof PREVIOUSLY_CAUGHT;
  value: T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPreviouslyCaught = <T>(val: any): val is PreviouslyCaught<T> =>
  val['_tag'] ? val._tag === PREVIOUSLY_CAUGHT : false;

export const PreviouslyCaught = {
  fold:
    <PCT, NPCT, OT>(
      onPreviouslyCaught: (input: PCT) => OT,
      onNotPreviouslyCaught: (input: NPCT) => OT
    ) =>
    (val: PreviouslyCaught<PCT> | NPCT) =>
      isPreviouslyCaught(val)
        ? onPreviouslyCaught(val.value)
        : onNotPreviouslyCaught(val),
  map: <T>(value: T): PreviouslyCaught<T> => ({
    value,
    _tag: PREVIOUSLY_CAUGHT
  })
} as const;
/* endregion */

/* HttpAPICustomErrorHandler<T>  */
export type HttpAPICustomErrorHandler<T> = CondPairUnary<
  HttpError<EcommAPIResponse<T>>,
  Observable<MaybeResponse<T>>
>;
/* endregion */
