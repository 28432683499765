import * as t from 'io-ts';


/* #region PasswordRequirements */
export const PasswordRequirementsDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        description: t.string,
        allowed: t.string,
        count: t.number,
      },
      'PasswordRequirementsDtoRequired'
    ),
    t.partial({}, 'PasswordRequirementsOptional')
  ],
  'PasswordRequirements'
);

export type PasswordRequirementsDto = t.TypeOf<typeof PasswordRequirementsDto>;
export type PasswordRequirements = PasswordRequirementsDto;
/* #endregion */

/* #region Response */
export const PasswordRequirementsResponse = t.intersection(
  [
    t.type(
      {
        passwordRequirements: t.array(PasswordRequirementsDto)
      },
      'PasswordRequirementsResponseRequired'
    ),
    t.partial({}, 'PasswordRequirementsResponseOptional')
  ],
  'PasswordRequirementsResponse'
);
export type PasswordRequirementsResponse = t.TypeOf<typeof PasswordRequirementsResponse>;
/* #endregion */