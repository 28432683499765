import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';

import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import { Cart, CartUpdateTipRequest } from '../../../../ecomm/types/cart.types';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';

export type TipOptions = {
  amount: string;
  default: boolean;
  id: string;
};

@Component({
  selector: 'wri-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnChanges, OnInit {
  @Input() tipOptions: TipOptions[] | undefined;
  @Input() handOffMode: string | undefined;
  @Input() cartFeatureState: CartFeatureState | undefined;
  @Input() currencyType: string | undefined;
  @Input() tipThreshold: number | undefined;
  @Input() fromCheckout: boolean | undefined;

  public customTipAmount: number | string | null = null;

  private subscription = new Subscription();
  public showCustomTipInput = false;

  public isLoading = false;

  constructor(
    private cartService: CartWorkflowService,
    private store: Store,
    private router: Router
  ) {}

  private static readonly CARRYOUT_TITLE =
    '100% of the tip goes to the team members';
  private static readonly DELIVERY_TITLE =
    '100% of the tip goes to your driver';

  public get getTipSubTitle() {
    if (this.handOffMode === 'carryout') {
      return TipComponent.CARRYOUT_TITLE;
    }
    if (this.handOffMode === 'delivery') {
      return TipComponent.DELIVERY_TITLE;
    }
    return '';
  }

  ngOnInit() {
    const customTip = this.cartFeatureState?.cart?.tip;
    this.customTipAmount = customTip?.type === null ? customTip?.amount : null;
  }

  ngOnChanges() {
    this.subscribeToCartState();
  }

  public getTipFromCart(tip: TipOptions): boolean {
    const cartTip = this.cartFeatureState?.cart?.tip?.value;
    if (cartTip !== null) {
      if (tip.amount?.includes('%')) {
        return Number(tip.amount.replace(/[%]/g, '')) === cartTip;
      }
      return (
        Number(tip.amount.replace(/[$]/g, '')?.replace(/.{0,3}$/, '')) ===
        cartTip
      );
    }
    return false;
  }

  public async updateTipAPI(requestObj: CartUpdateTipRequest) {
    return await this.cartService.updateCartTip(
      this.cartFeatureState?.cart?.cartId ?? '',
      requestObj
    );
  }

  private isDonationCategoryAvailableInCart(): boolean {
    return (
      this.cartFeatureState?.cart?.fees.some(
        (a) => a.category === 'donation'
      ) || false
    );
  }

  private async cartFinalize(updateCartWithTip: Cart | null) {
    if (this.fromCheckout && updateCartWithTip) {
      const cartFinalizResponse = await this.cartService.finalizeCart(
        this.cartFeatureState?.cart?.cartId ?? '',
        this.isDonationCategoryAvailableInCart()
      );
      if (!cartFinalizResponse) {
        this.router.navigate(['/order/my-bag']);
      }
    }
  }

  private async apiCall(requestObj: CartUpdateTipRequest) {
    const updateCartWithTip = await this.updateTipAPI(requestObj);

    //Finalize Cart again
    await this.cartFinalize(updateCartWithTip);
    this.isLoading = false;
    this.showCustomTipInput = false;
    this.customTipAmount = null;
  }

  public applyTip(tip: TipOptions) {
    this.isLoading = true;
    const isDollar = tip.amount.includes('$');
    if (isDollar) {
      return this.apiCall({
        type: 'dollar',
        value: Number(tip.amount.replace('$', ''))
      });
    }
    //% -> API call
    return this.apiCall({
      type: 'percent',
      value: Number(tip.amount.replace('%', ''))
    });
  }

  public async onCustomTipSubmit({ tipAmount }: { tipAmount: number | null }) {
    if (tipAmount !== null) {
      this.showCustomTipInput = true;
      this.isLoading = true;

      const updateCustomTip = await this.updateTipAPI({
        amount: tipAmount
      });

      //Finalize Cart again
      await this.cartFinalize(updateCustomTip);

      if (!updateCustomTip) {
        this.customTipAmount = null;
        this.showCustomTipInput = false;
      }
      this.isLoading = false;
    }
  }

  public onCustomTipInputChange({
    customTipValue
  }: {
    customTipValue: number;
  }) {
    this.customTipAmount = customTipValue;
  }

  public applyCustomTip() {
    this.showCustomTipInput = true;
  }

  public getCustomTipFromCart() {
    const cartTip = this.cartFeatureState?.cart?.tip;
    if (cartTip?.type === null) {
      this.showCustomTipInput = true;
      return true;
    }
    return false;
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state: CartFeatureState | undefined) => {
        this.cartFeatureState = state;
      })
    );
  }
}
