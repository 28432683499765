import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuCategory } from '../../../../ecomm/types/store-info.types';

@Component({
  selector: 'wri-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuCategoryComponent {
  @Input() isGlobalMenu = false;
  @Input() public category!: MenuCategory;
  @Input() layout: string | undefined;

  filterOutUnavailableProducts() {
    return this.category.products.filter(
      (p) =>
        p.item?.availableInSchedule ||
        p.itemGroup?.items.some((s) => s.availableInSchedule)
    );
  }
}
