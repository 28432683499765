<div class="flavor-mod-group-wrapper">
  <div class="mod-image-container">
    <ng-container
      *ngIf="
        flavor[0]?.images && flavor[0]?.images.length > 0;
        then modifierImage;
        else placeHolderImage
      "
    >
    </ng-container>
    <ng-template #modifierImage>
      <img
        aria-live="polite"
        class="flavor-image"
        srcset="{{ flavor[0]?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
        src="{{ flavor[0]?.images[0]?.uri | imageResolution: 'src':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        role="img"
        alt="{{ flavor[0]?.name | prepend : 'image-for' }}"
      />
    </ng-template>
    <ng-template #placeHolderImage>
      <img
        class="flavor-image"
        aria-live="polite"
        alt="placeholder-image"
        src="/assets/images/logo-green-product.png"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png'"
        role="img"
      />
    </ng-template>
  </div>
  <div class="mod-elements-details">
    <label
      [attr.data-testid]="'flavor-name-' + flavor[0]?.id"
      class="mod-name"
      >{{ flavor[0]?.metadata['group-name'] }}</label
    >
    <div class="mod-meta-container">
      <wri-heat-scale [heatScale]="flavor[0] | heatScale"></wri-heat-scale>
      <wri-designation
        [rubType]="flavor[0]?.metadata['rub-type']"
        [isPopular]="flavor[0]?.isPopular"
        [isNew]="flavor[0]?.isNew"
      >
      </wri-designation>
    </div>
    <div class="group-flavors">
      <ng-container *ngFor="let item of flavor; let i = index">
        <div [ngClass]="isChecked(item?.id) ? 'selected' : ''"
          wriInteractionStudio="data-chainoptionid"
          [interactionStudioId]="item?.id">
          <hr *ngIf="i > 0" class="wri-mod-divider" aria-hidden="true" />
          <ng-container
            *ngIf="
              maxSelectionsAllowed > 1;
              then isMultiselectCheckbox;
              else isSingleselectRadio
            "
          ></ng-container>
          <ng-template #isMultiselectCheckbox>
            <div class="group-options">
                <label
                  [attr.data-testid]="'flavor-display-name-' + item?.id + i"
                  [ngStyle]="item?.outOfStock && { color: '#A1A1A1' }"
                  class="checkbox-control-container"
                  [attr.data-disabled]="item?.outOfStock || isItemDisabled(item?.id)"
                  >
                  <span class="flavor-display-name mod-name-top">{{ item?.metadata['display-name'] ?? item?.name }}</span>
                  <input
                    [id]="'chk-' + item?.id + i"
                    class="mod-elements-checkbox-input"
                    tabindex="0"
                    [ngClass]="isChecked(item?.id) ? 'flavor-input-checked' : ''"
                    [disabled]="item?.outOfStock || isItemDisabled(item?.id)"
                    (change)="onChangeModifierElement(!isChecked(item?.id), 'multiple', item!)"
                    (blur)="onTouched()"
                    (focus)="onTouched()"
                    type="checkbox"
                    [attr.data-testid]="item?.id | prepend : 'input-for-'"
                    [attr.aria-label]="item?.metadata['display-name'] ?? item?.name"
                  />
                </label>
                <ng-container *ngIf="item?.outOfStock; else quantity">
                  <wri-unavailable-item></wri-unavailable-item>
                </ng-container>
                <ng-template #quantity>
                  <div
                    *ngIf="quantitiesEnabled"
                    class="flavor-quantity-wrapper"
                  >
                    <wri-flavor-quantity
                      *ngIf="isChecked(item?.id)"
                      [itemType]="item?.metadata?.['item-type'] || 'Wings'"
                      [flavorId]="item?.id"
                      [quantity]="getQuantity(item?.id)"
                      [minModifierQuantity]="minModifierQuantity"
                      [maxModifierQuantity]="maxModifierQuantity"
                      (valuesChanged)="
                        handleFlavorQuantityChange($event, item)
                      "
                    ></wri-flavor-quantity>
                  </div>
                </ng-template>
            </div>
          </ng-template>
          <ng-template #isSingleselectRadio>
            <div class="group-options radio-control-container">
              <div>
                <label
                  [attr.data-testid]="'flavor-display-name-' + item?.id + i"
                  [ngStyle]="item?.outOfStock && { color: '#A1A1A1' }"
                  [attr.data-disabled]="item?.outOfStock"
                  >
                  <span class="flavor-display-name mod-name-top">{{ item?.metadata['display-name'] ?? item?.name }}</span>
                  <input
                    [id]="'chk-' + item?.id + i"
                    class="mod-elements-radio-input"
                    tabindex="0"
                    [checked]="isChecked(item?.id)"
                    [disabled]="item?.outOfStock"
                    (change)="onChangeModifierElement(!isChecked(item?.id), 'single', item!)"
                    (blur)="onTouched()"
                    (focus)="onTouched()"
                    type="checkbox"
                    [attr.data-testid]="item?.id | prepend : 'input-for-'"
                    [attr.aria-label]="item?.metadata['display-name'] ?? item?.name"
                  />
                </label>
                <ng-container *ngIf="item?.outOfStock">
                  <wri-unavailable-item></wri-unavailable-item>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
