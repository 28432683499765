import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handoffModePreposition'
})
export class HandoffModePrepositionPipe implements PipeTransform {
  transform(handoffMode: string): string {
    return handoffMode.toLowerCase() === 'carryout' ? ' from' : ' to';
  }
}
