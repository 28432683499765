import moment from 'moment-mini';

import { BaseModel } from '@wingstop/models/base.model';

export class CalendarRange extends BaseModel {
  calendar_id: number = null;
  created_at: string = null;
  end: any = null;
  id: number = null;
  start: any = null;
  updated_at: string = null;
  weekday: string = null;

  constructor(values?: any) {
    super();

    if (values.start && !(values.start instanceof moment)) {
      values.start = moment(values.start, 'YYYYMMDD HH:mm');
    }

    if (values.end && !(values.end instanceof moment)) {
      values.end = moment(values.end, 'YYYYMMDD HH:mm');
    }

    this.initialize(values);
  }
}
