import { Injectable } from '@angular/core';

@Injectable()
export class PhoneFormatter {
  format(originalValue: string): string {
    if (originalValue) {
      let value = originalValue.replace(/\D/g, '').replace(/^1/, '');
      value =
        value.length > 3 ? value.substr(0, 3) + ' ' + value.substr(3) : value;
      value =
        value.length > 7 ? value.substr(0, 7) + ' ' + value.substr(7) : value;
      if (value.length > 12) {
        value = value.substring(0, 12);
      }
      return value;
    }
  }
}
