import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, switchMap, of } from 'rxjs';
import {
  EcommAPIConfig,
  ECOMM_API_CONFIG
} from '../../config/ecomm-config.provider';
import {
  CartAddItemRequest,
  CartApplyOfferCodeRequest,
  CartCreationRequest,
  CartDto,
  CartFinalizeAction,
  CartFinalizeRequest,
  CartFinalizeRespone,
  CartResponse,
  CartUpdateFulfillmentTimeRequest,
  CartUpdateItemQuantityRequest,
  CartUpdateItemRequest,
  CartUpdateTipRequest
} from '../../types/cart.types';

import { handleAPIResponse } from '../../utils/handle-api-response';
import { WebSocketClient } from '../../web-socket/web-socket.client';
import { handleWebSocketResponse } from '../../utils/handle-websocket-response';
import { MaybeResponse } from '../../types/maybe-response';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class CartRepository {
  private static readonly FINALIZE_CART_TIMEOUT_MS = 75_000;
  private static readonly CART_NOT_FINALIZED_ERROR =
    'We were unable to prepare your order for checkout. Refresh the page and try again.';
  private static readonly CART_FINALIZED_STATUS = 'finalized';
  private static readonly ALLOWED_CART_STATUSES = [
    'empty',
    'valid',
    'invalid',
    'finalized'
  ];

  constructor(
    private http: HttpClient,
    private webSocketClient: WebSocketClient<
      CartFinalizeRequest,
      CartFinalizeRespone
    >,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService
  ) {}

  public createCart(
    request: CartCreationRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts`;
    return this.http
      .post<CartResponse>(url, request)
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getCartById(
    cartId: string,
    options: Partial<{
      createIfNotFound: CartCreationRequest;
    }> = {}
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}`;
    return this.http.get<CartResponse>(url).pipe(
      handleAPIResponse(
        CartResponse,
        [
          [
            (res) => res.status === 404 && !!options.createIfNotFound,
            () =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.createCart(options.createIfNotFound!)
          ]
        ],
        this.featureFlagService.featureFlags.enableDevLogs
      ),
      switchMap((res) =>
        res.data &&
        !CartRepository.ALLOWED_CART_STATUSES.includes(res.data.status) &&
        options.createIfNotFound
          ? this.createCart(options.createIfNotFound)
          : of(res)
      )
    );
  }

  public updateCartById(
    cartId: string,
    options: Partial<{
      createIfNotFound: CartCreationRequest;
    }> = {}
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}`;
    const body = {
      locationId: options.createIfNotFound?.locationId,
      handoffMode: options.createIfNotFound?.handoffMode,
      deliveryAddress: options.createIfNotFound?.deliveryAddress
    };
    return this.http.put<CartResponse>(url, body).pipe(
      handleAPIResponse(
        CartResponse,
        [
          [
            (res) => res.status !== 200 && !!options.createIfNotFound,
            (res) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.createCart(options.createIfNotFound!).pipe(
                map((createRes) => {
                  return {
                    ...createRes,
                    error:
                      createRes.error ?? res.error.error?.customerFacingMessage
                  };
                })
              )
          ]
        ],
        this.featureFlagService.featureFlags.enableDevLogs
      ),
      switchMap((res) =>
        res.data &&
        !CartRepository.ALLOWED_CART_STATUSES.includes(res.data.status) &&
        options.createIfNotFound
          ? this.createCart(options.createIfNotFound)
          : of(res)
      )
    );
  }

  public addItemToCart(
    cartId: string,
    request: CartAddItemRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/items`;
    return this.http
      .post<CartResponse>(url, request)
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public updateItemInCart(
    cartId: string,
    lineItemId: string,
    request: CartUpdateItemRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/items/${lineItemId}`;
    return this.http
      .put<CartResponse>(url, request)
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public updateItemQuantity(
    cartId: string,
    lineItemId: string,
    request: CartUpdateItemQuantityRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/items/${lineItemId}`;
    return this.http
      .put<CartResponse>(url, request)
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public removeItemFromCart(
    cartId: string,
    lineItemId: string
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/items/${lineItemId}`;
    const headers = {
      // needed for integration tests
      'Content-Type': 'application/json'
    };
    return this.http
      .delete<CartResponse>(url, { headers })
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public applyOfferCodeToCart(
    cartId: string,
    recaptchaToken: string,
    request: CartApplyOfferCodeRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/applyoffer`;
    const headers = {
      recaptchatoken: recaptchaToken
    };
    return this.http
      .post<CartResponse>(url, request, { headers })
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public removeOfferCodeFromCart(
    cartId: string
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/deleteoffer`;
    const headers = {
      // needed for integration tests
      'Content-Type': 'application/json'
    };
    return this.http
      .delete<CartResponse>(url, { headers })
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public updateCartFulfillmentTime(
    cartId: string,
    request: CartUpdateFulfillmentTimeRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/fulfillmenttime`;
    return this.http
      .put<CartResponse>(url, request)
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public updateCartTip(
    cartId: string,
    request: CartUpdateTipRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/carts/${cartId}/tip`;
    return this.http
      .put<CartResponse>(url, request)
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public finalizeCart(
    request: CartFinalizeRequest
  ): Observable<MaybeResponse<CartDto>> {
    const [closeFn, responses$] = this.webSocketClient.publish(
      request,
      () =>
        this.getCartById(request.request.cartId).pipe(
          map((res: MaybeResponse<CartDto>) => ({
            type: CartFinalizeAction.cartManuallyFetched,
            error: null,
            data: res.data
          }))
        ),
      CartRepository.FINALIZE_CART_TIMEOUT_MS
    );

    return responses$.pipe(
      handleWebSocketResponse(
        CartFinalizeRespone,
        [
          CartFinalizeAction.cartFinalizingFailed,
          CartFinalizeAction.cartFinalized,
          CartFinalizeAction.cartManuallyFetched
        ],
        CartFinalizeAction.cartFinalizingFailed,
        closeFn,
        this.featureFlagService.featureFlags.enableDevLogs
      ),
      switchMap((res) =>
        of({
          ...res,
          error: res.error
            ? res.error
            : res.data &&
              res.data.status !== CartRepository.CART_FINALIZED_STATUS
            ? CartRepository.CART_NOT_FINALIZED_ERROR
            : undefined
        })
      )
    );
  }
}
