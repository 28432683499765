<div
    data-testid="menu-category"
    wriInteractionStudio="data-chaincategoryid"
    [interactionStudioId]="category.id"
>
  <div [ngClass]="'wri-menu-category-wrapper'">
    <span
        [ngClass]="'wri-category-name'"
        [id]="category.slug"
        data-testid="menu-category-name"
    >{{ category.name }}</span
    >
    <hr [ngClass]="'wri-divider'" aria-hidden="true"/>
    <div class="wri-products-container">
      <ng-container *ngFor="let product of filterOutUnavailableProducts() | sort:'asc':'sortOrder'">
        <div
            wriInteractionStudio="data-chainproductid"
            [interactionStudioId]="product.id"
            class="wri-product-container-item">
          <wri-category-product
              [isGlobalMenu]="isGlobalMenu"
              [product]="product"
              [categorySlug]="category.slug"
              [categoryName]="category.name"
              [layout]="layout"
          ></wri-category-product>
          <hr class="wri-divider" aria-hidden="true"/>
        </div>
      </ng-container>
    </div>
  </div>
</div>
