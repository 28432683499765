import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomerFavoriteLocations } from '../../../../../ecomm/types/customer.types';
import { CustomerWorkflowService } from '../../../../../ecomm/workflows/customer/customer-workflow.service';

type ModalData = {
  data: CustomerFavoriteLocations;
  deleteFavoriteLocation: boolean;
};

@Component({
  selector: 'wri-remove-favorite-location-modal',
  templateUrl: './remove-favorite-location-modal.component.html',
  styleUrls: ['./remove-favorite-location-modal.component.scss']
})
export class RemoveFavoriteLocationModalComponent {
  @Input() modalData!: ModalData;
  constructor(
    public activeModal: NgbActiveModal,
    private userAccountService: CustomerWorkflowService
  ) {}

  public isLoading = false;

  closeModal() {
    this.activeModal.close();
  }

  public async removeFavoriteLocation(e: Event) {
    if (this.isLoading) {
      e.preventDefault();
    } else {
      const { data } = this.modalData;
      const locationId = data.id;
      this.isLoading = true;
      const response = await this.userAccountService.deleteFavoriteLocation(
        locationId
      );
      this.isLoading = false;
      if (response) {
        this.activeModal.close({
          isDeleted: true
        });
      }
    }
  }

  getModalBody() {
    const { data } = this.modalData;
    return `Are you sure you want to remove ${data.name}?`;
  }
}
