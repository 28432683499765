<div class="wri-review-order-items-container">
  <ng-container *ngFor="let item of cart?.items; trackBy: identify">
    <hr
      [ngClass]="[isReadOnly ? 'readonly-wri-divider' : '']"
      class="wri-divider"
      aria-hidden="true"
    />
    <wri-review-order-item
      [lineItem]="item"
      [cartId]="cart?.cartId"
      (quantityChanged)="onQuantityChange($event)"
      [isReadOnly]="isReadOnly"
    ></wri-review-order-item>
  </ng-container>
  <hr [ngClass]="[isReadOnly ? 'readonly-wri-divider' : '']" class="mobile-wri-divider" aria-hidden="true" />
</div>
