import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { CartRepository } from '../../repositories/cart/cart.repository';
import {
  Cart,
  CartAddItemRequest,
  CartApplyOfferCodeRequest,
  CartCreationRequest,
  CartFinalizeRequest,
  CartUpdateFulfillmentTimeRequest,
  CartUpdateItemQuantityRequest,
  CartUpdateItemRequest,
  CartUpdateTipRequest
} from '../../types/cart.types';
import * as MaybeResponse from '../../types/maybe-response';
import { fromDto } from '../../utils/from-dto';

@Injectable({ providedIn: 'root' })
export class CartService {
  constructor(private cartRepository: CartRepository) {}

  public createCart(
    request: CartCreationRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .createCart(request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getCartById(
    cartId: string,
    options: Partial<{
      createIfNotFound: CartCreationRequest;
    }> = {}
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .getCartById(cartId, options)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public updateCartById(
    cartId: string,
    options: Partial<{
      createIfNotFound: CartCreationRequest;
    }> = {}
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .updateCartById(cartId, options)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public addItemToCart(
    cartId: string,
    request: CartAddItemRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .addItemToCart(cartId, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public updateItemQuantity(
    cartId: string,
    lineItemId: string,
    request: CartUpdateItemQuantityRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .updateItemQuantity(cartId, lineItemId, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public removeItemFromCart(
    cartId: string,
    lineItemId: string
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .removeItemFromCart(cartId, lineItemId)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public updateItemInCart(
    cartId: string,
    lineItemId: string,
    request: CartUpdateItemRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .updateItemInCart(cartId, lineItemId, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public applyOfferCodeToCart(
    cartId: string,
    recaptchaToken: string,
    request: CartApplyOfferCodeRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .applyOfferCodeToCart(cartId, recaptchaToken, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public removeOfferCodeFromCart(
    cartId: string
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .removeOfferCodeFromCart(cartId)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public updateCartFulfillmentTime(
    cartId: string,
    request: CartUpdateFulfillmentTimeRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .updateCartFulfillmentTime(cartId, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public updateCartTip(
    cartId: string,
    request: CartUpdateTipRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .updateCartTip(cartId, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public finalizeCart(
    request: CartFinalizeRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.cartRepository
      .finalizeCart(request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }
}
