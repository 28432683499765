import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'safeHtml',
  standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: string): SafeHtml {
    const sanitizedHTML = DOMPurify.sanitize(value, { FORCE_BODY: true });
    return this.sanitized.bypassSecurityTrustHtml(sanitizedHTML);
  }
}
