<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<div *ngIf="shouldShow$ | async">
  <button
    #skipLink
    class="btn btn-primary"
    id="skip-link"
    (click)="skipToMain()">
    Skip to Main Content
  </button>

  <div
    aria-label="Mobile Navigation"
    class="border-bottom-shade nav-container mobile"
    [class.app-banner]="showAppBanner$ | async">
    <div *ngIf="(isOffline$ | async) === false" class="nav-offer-container">
      <button
        aria-label="Open Mobile Navigation"
        aria-controls="mobile-nav-overlay"
        [attr.aria-expanded]="mobileMenuOpen"
        type="button"
        (click)="toggleMobileMenu()"
        class="nav-btn"
        id="mobile-hamburger">
        <svg type="hamburger"></svg>
      </button>
    </div>

    <div
      id="mobile-nav-overlay"
      [class.mobile-menu-open]="mobileMenuOpen"
      [class.app-banner]="showAppBanner$ | async">
      <div class="header">
        <svg *ngIf="!isAuthenticated || (!firstName && !lastName) " type="user" aria-hidden="true"></svg>
        <div *ngIf="isAuthenticated && (firstName || lastName)" class="user-initial">
          {{ firstName[0] }}{{ lastName ? lastName[0] : '' }}
        </div>
        <div class="greeting">
          <h2 class="greeting_text h2">
            {{ isAuthenticated ? (firstName ? 'Hi, ' + firstName : 'Hi!') : 'Welcome' }}
          </h2>
        </div>
        <button
          #closeMobileMenuBtn
          class="header_button__close"
          aria-label="Close Mobile Navigation"
          (click)="toggleMobileMenu()">
          <svg type="close"></svg>
        </button>
      </div>

      <wri-auth-navigation></wri-auth-navigation>

      <div class="link-container mobile">
        <a
          class="h4 menu-link"
          (click)="onCtaClick($event, menuPath)"
          data-cy="Menu">
          Menu
        </a>
        <a
          class="h4 menu-link"
          (click)="onCtaClick($event, flavorsPath)"
          data-cy="Flavors">
          Flavors
        </a>

        <a
          class="h4 menu-link"
          (click)="onCtaClick($event, wingCalculatorPath)"
          data-cy="WingCalculator">
          Wing Calculator&trade;
        </a>

        <a
          class="h4 menu-link"
          href="#"
          (click)="onCtaClick($event, ngfeOrderPath)"
          data-cy="Locations">
          Locations
        </a>
        <a
          *ngIf="isSpanishTranslationEnabled"
          mporgnav
          class="user-locale h4 menu-link langLink mobile-lang-link"
          href="#"
          onclick="return chooser();
            function chooser(){
            var script=document.createElement('SCRIPT');
            script.src='https://wingstopcom.mpeasylink.com/mpel/mpel_chooser.js';
            document.body.appendChild(script);
            window.scroll({top:0,left:0,behavior:'smooth'});
            return false;}"
          title="Global Switch">
          <svg type="language" class="mr-2"></svg>
          <span>Language</span>
        </a>

        <div class="w-100 text-center mt-4">
          <wri-auth-navigation
            [showOnlyLogoutButton]="true"></wri-auth-navigation>
        </div>
      </div>
    </div>

    <div class="brand-container">
      <a [routerLink]="['/']">
        <img
          loading="lazy"
          width="340"
          height="117"
          src="assets/images/wingstop-logo-green-340.png"
          alt="Wingstop" />
      </a>
    </div>

    <div *ngIf="(isOffline$ | async) === false" class="account-container" [attr.aria-hidden]="mobileMenuOpen">
      <div class="mobile">
        <wri-active-offer></wri-active-offer>
      </div>
      <a
        *ngIf="!appCart?.locationId"
        (click)="onCtaClick($event, ngfeOrderPath)"
        aria-label="Find a Wingstop Restaurant Location"
        data-cy="Locations">
        <svg type="location"></svg>
      </a>
      <div class="mobile">
        <wri-connected-bag-count></wri-connected-bag-count>
      </div>
    </div>
  </div>

  <div
    [class]="(careersConditionalClass || '') + ' ' + 'nav-container desktop'"
   >
    <nav aria-label="Main" id="nav" class="constrain" [attr.is-offline]="(isOffline$ | async) === true">
      <div class="left">
        <a [routerLink]="['/']" class="brand">
          <img
            loading="lazy"
            width="340"
            height="117"
            src="assets/images/wingstop-logo-green-340.png"
            alt="Wingstop"
            data-cy="Home" />
        </a>
        <a
          *ngIf="(isOffline$ | async) === false"
          class="h6 menu-link"
          href="#"
          (click)="onCtaClick($event, menuPath)"
          [class.active]="currentUrl == '/menu'"
          data-cy="Menu">
          Menu
        </a>
        <a
          *ngIf="(isOffline$ | async) === false"
          class="h6 menu-link"
          href="#"
          (click)="onCtaClick($event, flavorsPath)"
          [class.active]="currentUrl == '/flavors'"
          data-cy="Flavors">
          Flavors
        </a>
        <a
          *ngIf="(isOffline$ | async) === false"
          class="h6 menu-link"
          href="#"
          (click)="onCtaClick($event, wingCalculatorPath)"
          [class.active]="currentUrl == '/order/wing-calculator'"
          data-cy="WingCalculator">
          Wing&nbsp;Calculator&trade;
        </a>
        <a
          *ngIf="(isOffline$ | async) === false"
          class="h6 menu-link"
          href="#"
          (click)="onCtaClick($event, ngfeOrderPath)"
          [class.active]="currentUrl == '/order'"
          data-cy="Locations">
          Locations
        </a>
      </div>
      <div *ngIf="(isOffline$ | async) === false" class="right">
        <wri-cart-location></wri-cart-location>
        <a
          *ngIf="isSpanishTranslationEnabled"
          mporgnav
          class="user-locale langLink lang-link"
          href="#"
          onclick="return chooser();
            function chooser(){
            var script=document.createElement('SCRIPT');
            script.src='https://wingstopcom.mpeasylink.com/mpel/mpel_chooser.js';
            document.body.appendChild(script);
            window.scroll({top:0,left:0,behavior:'smooth'});
            return false;}"
          title="Global Switch">
          <svg type="language"></svg>
          <div class="font-size-min text-wrap-none">Language</div>
        </a>
        <wri-active-offer></wri-active-offer>
        <div class="dropdown">
          <button
            id="user-menu-desktop"
            (click)="onCtaClick($event, 'account-popup')"
            data-toggle="dropdown"
            aria-label="User pop up menu, open for log in and sign up options"
            class="pointer"
            aria-haspopup="true"
            aria-expanded="false">
            <svg *ngIf="!isAuthenticated || (!firstName && !lastName) " type="user" alt=""></svg>
            <div *ngIf="isAuthenticated && (firstName || lastName)" class="user-initial">
              {{ firstName[0] }}{{ lastName ? lastName[0] : '' }}
            </div>
          </button>
          <div class="dropdown-menu" aria-labelledby="user-menu-desktop">
            <wri-auth-navigation
              (loginButtonClick)="onLoginClick($event)"></wri-auth-navigation>
          </div>
        </div>
        <wri-connected-bag-count></wri-connected-bag-count>
      </div>
    </nav>
  </div>
</div>
<ng-template *ngIf="kiosk && !digitalMenu">
  <div class="kiosk-mode">
    <div class="col-8 col-sm-5 col-md-3 text-center">
      <img
        loading="lazy"
        width="340"
        height="117"
        class="logo"
        src="assets/images/wingstop-logo-green-340.png"
        alt="Wingstop" />
    </div>
  </div>
</ng-template>
<div id="global_messaging_navigation"></div>
