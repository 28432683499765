import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import {
  CONFIG,
  Config
} from '../../../ecomm/providers/config/config.provider';

@Injectable()
export class LegacyRedirectGuard implements CanActivate {
  constructor(@Inject(CONFIG) private config: Config) {}
  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const relativePath = state.url.slice(1);
    const redirectUrl = this.config.legacy.webAppBaseUrl.concat(
      '/' + relativePath
    );
    window.location.href = redirectUrl;
    return true;
  }
}
