import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(input: string, spliterator = ','): string[] {
    return input.split(spliterator);
  }
}
