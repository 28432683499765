<div class="flavor-modal-item-wrapper">
  <ng-container
    *ngIf="
      images && images.length > 0;
      then modifierImage;
      else placeHolderImage
    "
  >
  </ng-container>

  <ng-template #modifierImage>
    <img
      class="flavor-image"
      aria-live="polite"
      srcset="{{ images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
      src="{{
        images[0]?.uri
          | imageResolution
            : 'src'
            : 'pdp-modifiers'
            : '/assets/images/logo-green-product.png'
      }}"
      onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
      role="img"
      alt="{{ name | prepend : 'image-for' }}"
    />
  </ng-template>

  <ng-template #placeHolderImage>
    <img
      class="flavor-image"
      aria-live="polite"
      alt="placeholder-image"
      src="/assets/images/logo-green-product.png"
      onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png'"
      role="img"
    />
  </ng-template>

  <div>
    <span data-testid="flavor-item-name" class="flavor-item-name">{{
      name
    }}</span>
    <div class="flavor-item-modal-meta-container">
      <wri-heat-scale [heatScale]="heatScale"></wri-heat-scale>
      <wri-designation
        [rubType]="rubType"
        [isPopular]="isPopular"
        [isNew]="isNew"
      >
      </wri-designation>
    </div>
    <div *ngIf="calorieRange !== ''">
      <span class="mod-calories">
        {{ calorieRange }}
      </span>
    </div>
    <div class="description-wrapper">
      <span class="flavor-item-description">{{ description }}</span>
    </div>
  </div>
</div>
<hr class="wri-divider" aria-hidden="true" *ngIf="showDivider" />
