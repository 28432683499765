import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Flavor } from '../../../../ecomm/types/flavor.types';

@Component({
  selector: 'wri-flavor-card',
  templateUrl: './flavor-card.component.html',
  styleUrls: ['./flavor-card.component.scss']
})
export class FlavorCardComponent {
  @Input() flavor: Flavor | undefined;

  @Input() storeSlug: string | undefined;

  @Input() hasButton = true;

  constructor(private router: Router) {}

  onOrderNow() {
    this.router.navigateByUrl(
      this.storeSlug ? `/location/${this.storeSlug}/menu` : '/order'
    );
  }
}
