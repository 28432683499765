import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  throwError
} from 'rxjs';

import { CacheLoadedFeature } from '../../../ecomm/store/features/cache-loaded';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { CustomerWorkflowService } from '../../../ecomm/workflows/customer/customer-workflow.service';

export abstract class BasePageService {
  constructor(
    protected redirectService: RedirectService,
    protected store: Store,
    protected userAccountService: CustomerWorkflowService,
    protected asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>
  ) {}

  abstract loadData$(route: ActivatedRouteSnapshot): Observable<unknown>;

  abstract timeout$(): Observable<unknown>;

  abstract isInInvalidState(state: FeaturesState): boolean;

  private _resetErrors$ = () => {
    return from(
      Promise.all([
        this.asynchronusDispatcher.dispatch(CartFeature.actions.setState({})),
        this.asynchronusDispatcher.dispatch(
          StoreInfoFeature.actions.setState({ selectedItem: null })
        )
      ])
    );
  };

  private _loadWorkflow$(route: ActivatedRouteSnapshot) {
    return this.store.select(CacheLoadedFeature.selectCacheLoaded).pipe(
      filter(Boolean),
      switchMap(this._resetErrors$),
      switchMap(() => this.loadData$(route)),
      switchMap(() => this.store.pipe(map((state) => state as FeaturesState))),
      map(this.isInInvalidState.bind(this)),
      switchMap((success) =>
        success ? throwError(() => 'State is invalid') : of(undefined)
      )
    );
  }

  load$(route: ActivatedRouteSnapshot): Observable<unknown> {
    return merge(this._loadWorkflow$(route), this.timeout$());
  }
}
