import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { FeatureState } from '../../types/feature-state';
import { OfferDetails } from '../../../types/offer-details.types';

const ActiveOfferFeatureKey = 'activeOffer';
const ActiveOfferDataFeatureKey = 'activeOfferDetails';

export type ActiveOfferFeatureState = FeatureState<
  string,
  typeof ActiveOfferFeatureKey
  > & FeatureState<
    OfferDetails,
    typeof ActiveOfferDataFeatureKey
  >;

const initialActiveOfferState: ActiveOfferFeatureState = {
  isLoading: false,
  error: null,
  activeOffer: null,
  activeOfferDetails: null
};

const ActiveOfferActions = createActionGroup({
  source: 'ActiveOffer Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{
      error?: string | null;
      activeOffer?: string | null;
      activeOfferDetails?: OfferDetails | null;
    }>(),
    'Reset To Default': emptyProps()
  }
});

const ActiveOfferReducer = createReducer(
  initialActiveOfferState,
  on(
    ActiveOfferActions.setIsLoading,
    (state): ActiveOfferFeatureState => ({ ...state, isLoading: true })
  ),
  on(ActiveOfferActions.setState, (state, action): ActiveOfferFeatureState => {
    return {
      ...state,
      isLoading: false,
      error: action.error ?? null,
      activeOffer: action.activeOffer ?? null,
      activeOfferDetails: action.activeOfferDetails ?? null
    };
  }),
  on(
    ActiveOfferActions.resetToDefault,
    (state): ActiveOfferFeatureState => ({
      ...state,
      ...initialActiveOfferState
    })
  )
);

const ActiveOfferFeature = createFeature({
  name: ActiveOfferFeatureKey,
  reducer: ActiveOfferReducer
});

export default {
  ...ActiveOfferFeature,
  actions: ActiveOfferActions,
  initialState: initialActiveOfferState
};
