<div
  class="cross-sell-card wri-addon-card-wrapper"
  wriInteractionStudio="data-chainoptionid"
  [interactionStudioId]="item?.id"
  [ngClass]="isAddOnCardSelected() ? 'selected' : ''"
  [class.first-slide]="first"
>
  <div
    class="wri-card"
    (click)="addOnClick()"
    data-testid="handle-add-on-click"
  >
    <div class="wri-card-img-wpr">
      <img
        class="card-img wri-card-img"
        srcset="{{ image | imageResolution: 'srcset':'pdp-addons': '/assets/images/logo-green-product.png'}}"
        src="{{
          image
            | imageResolution
              : 'src'
              : 'pdp-addons'
              : '/assets/images/logo-green-product.png'
        }}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        alt="{{ imageDescription }}"
      />

      <a
        wriFocusOutline
        rel="noopener noreferrer"
        tabindex="0"
        role="link"
        (click)="toggleAddOn($event)"
        class="wri-card-addon-add-icon"
        [attr.aria-label]="
          isAddOnCardSelected() ? 'Add on selected' : 'Add on unselected'
        "
      >
        <div class="icon-wrapper">
          <wri-icon
            data-testid="add-icon"
            [ngClass]="isAddOnCardSelected() ? 'check-icon' : 'plus-icon'"
            [icon]="isAddOnCardSelected() ? 'wri-check' : 'wri-plus'"
          ></wri-icon>
        </div>
      </a>

      <!-- Show Edit button only for complex modifiers -->
      <a
        wriFocusOutline
        rel="noopener noreferrer"
        tabindex="0"
        role="link"
        data-testid="add-on-edit"
        (click)="addOnEdit($event)"
        *ngIf="
          addOnModifiersGroups &&
          addOnModifiersGroups.length > 0 &&
          isAddOnCardSelected()
        "
        class="wri-card-addon-edit"
      >
        <label>Edit</label>
      </a>
    </div>

    <div>
      <h3 data-testid="wri-card-name" class="card-title wri-card-name">
        {{ name }}
      </h3>
      <div data-testid="wri-card-description" class="wri-card-description">
        <ng-container *ngIf="hasOnlyFlavorsModGroup(); else showNonFlavorModGroups">
          {{
            selectedAddOnFlavor
              ? 'Flavor selected: ' + selectedAddOnFlavor.name
              : description
          }}
        </ng-container>
        <ng-template #showNonFlavorModGroups>
          <ng-container
            *ngIf="
              selectedItemModifierGroups.length === 0;
              else showSelectedItemModGroups
            "
          >
            {{ description }}
          </ng-container>
          <ng-template #showSelectedItemModGroups>
            Selected: <ng-container
              *ngFor="
                let selectedItemModifierGroup of selectedItemModifierGroups;
                index as i
              "
              ><ng-container
                *ngFor="
                  let selectedItemModifier of selectedItemModifierGroup.modifiers;
                  index as j
                "
                ><ng-container
                  *ngIf="
                    (i < selectedItemModifierGroups.length &&
                      !(i === 0 && j === 0)) ||
                    (i >= selectedItemModifierGroups.length && j > 0)
                  "
                  >, </ng-container
                >{{ selectedItemModifier.name.trim() }}</ng-container
              ></ng-container
            >
          </ng-template>
        </ng-template>
      </div>
      <div>
        <span *ngIf="hasPrice(price)" class="wri-card-price">
          +{{ price?.toString() | currency }}<span *ngIf="calorieRange"> </span>
        </span>
        <span class="wri-card-calories">
          {{ calorieRange }}
        </span>
      </div>
    </div>
  </div>
</div>
