import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { AuthFeature } from '../../../ecomm/store/features/auth';
import { asyncTap } from '../../../ecomm/utils/async-tap';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { AuthService } from '../../../ecomm/utils/auth/auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  AsynchronousDispatcher
} from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';

export const loginRedirectUnauthenticatedGuard = async (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store);
  const redirectService = inject(RedirectService);
  const authService = inject(AuthService);
  const asynchronousDispatcher = inject(AsynchronousDispatcher)

  return await firstValueFrom(
    store.pipe(
      select(AuthFeature.selectIsAuthenticated),
      asyncTap(async (isAuthenticated) => {
        if (!isAuthenticated) {
          const auth = await authService.getCurrentAuth();
          if (auth.pageBeforeLogout === '/account/settings') {
            await redirectService.redirectToHome();
            await asynchronousDispatcher.dispatch(
              AuthFeature.actions.setPageBeforeLogout({
                pageBeforeLogout: null
              })
            );
          } else {
            await authService.login(auth.pageBeforeLogout || state.url || '');
          }
        }
      })
    )
  );
};
