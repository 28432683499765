<ng-template #externalLinkConfirmationModal>
    <div class="external-link-confirmation-modal">
      <div class="wri-modal__wrapper">
        <div class="wri-modal__title">CONFIRM</div>
        <div
          data-testid="external-link-confirmation-modal-description"
          class="wri-modal__description"
        >
          <p>
            You are about to be redirected to another site that is not Wingstop.com, and may be subject to separate
            terms, conditions, and privacy policies. You can press "Ok" to proceed, or "Cancel" to stay here.
          </p>
        </div>
        <div class="wri-modal__btn-wrapper">
          <button
            data-testid="external-link-confirmation-modal-ok"
            class="wri-btn wri-btn-primary"
            (click)="handleClick($event); closeModal($event)"
          >
            OK
          </button>
          <button
            data-testid="external-link-confirmation-modal-cancel"
            class="wri-btn wri-btn-tertiary"
            (click)="closeModal($event)"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
</ng-template>
