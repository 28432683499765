<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<div class="my-account" id="my-account-form">
  <span class="my-account-title">MY ACCOUNT</span>
  <hr class="wri-account-divider"/>
  <form [formGroup]="myAccountForm">
    <div class="settings-form-section">
      <span class="settings-title" data-cy="myInformation">
        My Information
      </span>
      <div class="my-account-form">
        <wri-text-input
          [id]="'first-name'"
          [label]="'First Name*'"
          [ariaLabel]="
            myAccountForm.controls['firstName'].value
              ? undefined
              : 'First name was not provided'
          "
          formControlName="firstName"
          [showLockIcon]="myAccountForm.controls['firstName']?.disabled"
          maxlength="50"
          [isFormValid]="userAccountFormValid"
          [canShowErrors]="canShowErrors"
        >
          <wri-form-error
            [control]="myAccountForm.controls['firstName']"
            errorKey="required"
            display="First name is required"
          ></wri-form-error>
          <wri-form-error
            [control]="myAccountForm.controls['firstName']"
            errorKey="pattern"
            display="Must be a valid first name"
          ></wri-form-error>
        </wri-text-input>

        <wri-text-input
          [id]="'last-name'"
          [label]="'Last Name*'"
          [ariaLabel]="
            myAccountForm.controls['lastName'].value
              ? undefined
              : 'Last name was not provided'
          "
          formControlName="lastName"
          [showLockIcon]="myAccountForm.controls['lastName']?.disabled"
          [isFormValid]="userAccountFormValid"
          [canShowErrors]="canShowErrors"
          maxlength="50"
        >
          <wri-form-error
            [control]="myAccountForm.controls['lastName']"
            errorKey="required"
            display="Last name is required"
          ></wri-form-error>
          <wri-form-error
            [control]="myAccountForm.controls['lastName']"
            errorKey="pattern"
            display="Must be a valid last name"
          ></wri-form-error>
        </wri-text-input>

        <wri-text-input
          [id]="'email-address'"
          [label]="'Email*'"
          [placeholder]="'N/A'"
          [ariaLabel]="
            myAccountForm.controls['email'].value
              ? undefined
              : 'Email was not provided'
          "
          type="email"
          formControlName="email"
          [showLockIcon]="true"
          [isFormValid]="userAccountFormValid"
          [canShowErrors]="canShowErrors"
        >
          <wri-form-error
            [control]="myAccountForm.controls['email']"
            errorKey="required"
            display="Email is required"
          ></wri-form-error>
          <wri-form-error
            [control]="myAccountForm.controls['email']"
            errorKey="pattern"
            display="Must be a valid email address"
          ></wri-form-error>
        </wri-text-input>

        <wri-text-input
          [id]="'phone'"
          [label]="'Phone Number (Optional)'"
          placeholder="XXX XXX XXXX"
          autoComplete="tel-national"
          type="tel"
          [ariaLabel]="
            myAccountForm.controls['phone'].value
              ? undefined
              : 'Phone number was not provided'
          "
          formControlName="phone"
          [formatter]="getPhoneFormatter()"
          [showLockIcon]="myAccountForm.controls['phone']?.disabled"
          [isFormValid]="userAccountFormValid"
          [canShowErrors]="canShowErrors"
        >
          <wri-form-error
            [control]="myAccountForm.controls['phone']"
            errorKey="required"
            display="Phone number is required"
          ></wri-form-error>
          <wri-form-error
            [control]="myAccountForm.controls['phone']"
            errorKey="inValidNumber"
            display="Must be a valid phone number">
          </wri-form-error>
        </wri-text-input>

        <wri-text-input
          [id]="'zip'"
          [label]="'Zip (Optional)'"
          [placeholder]="'N/A'"
          [ariaLabel]="
            myAccountForm.controls['zip'].value
              ? undefined
              : 'Zip was not provided'
          "
          formControlName="zip"
          [showLockIcon]="myAccountForm.controls['zip']?.disabled"
          type="number"
          [isFormValid]="userAccountFormValid"
          [canShowErrors]="canShowErrors"
          maxlength="5"
        >
          <wri-form-error
            [control]="myAccountForm.controls['zip']"
            errorKey="minLength"
            display="Must be a valid zip code"
          ></wri-form-error>
        </wri-text-input>

        <span class="birthdate-title"> Free Birthday Gift! </span>

        <div class="birthdate-desc">
          Let us hook you up with a free birthday gift, all we need is the date!
          Entering your birthday for a gift is not required for joining the
          club.
        </div>

        <wri-text-input
          [id]="'birthdate'"
          [label]="'Birthday (Optional)'"
          [placeholder]="'MM/DD'"
          [ariaLabel]="
            myAccountForm.controls['birthdate'].value
              ? undefined
              : 'Birthday was not provided'
          "
          formControlName="birthdate"
          [showLockIcon]="myAccountForm.controls['birthdate']?.disabled"
          [isFormValid]="userAccountFormValid"
          [canShowErrors]="canShowErrors"
          [preventSlashKey]="true"
          (keyup)="onKeyup($event)"
          maxlength="5"
        >
          <wri-form-error
            [control]="myAccountForm.controls['birthdate']"
            errorKey="minLength"
            display="Birthdate must be MM/DD format"
          ></wri-form-error>

          <wri-form-error
            [control]="myAccountForm.controls['birthdate']"
            errorKey="pattern"
            display="Must be a valid month and date"
          ></wri-form-error>
        </wri-text-input>

        <wri-checkbox
          [id]="'emailPreference'"
          label="Send me emails about future deals and features"
          formControlName="emailPreference"
        ></wri-checkbox>
      </div>
    </div>
  </form>

  <div class="card-btn-wrapper">
    <div class="wri-card-btn-wrapper">
      <button
        class="wri-btn wri-btn-primary"
        aria-label="Update Account"
        (click)="onUpdateAccount($event)"
      >
        Update Account
      </button>

      <a
        wriFocusOutline
        tabindex="0"
        class="change-my-password-btn wri-btn wri-btn-text"
        aria-label="change my password"
        data-testid="change-my-password-btn"
        (click)="changeMyPassword()"
      >
        Change my password
      </a>

    </div>
  </div>

  <wri-my-addresses [deliveryAddresses]="userDetails?.deliveryAddresses"/>

  <wri-payment-methods [regionalConfigState]="(regionalConfig$ | async)" [vaultedCards]="vaultedCards"/>

  <wri-favorite-locations
    [favoriteWingstopLocations]="userDetails?.favoriteWingstopLocations"
  />

  <ng-container>
    <div class="delete-account">
      <hr class="wri-divider" aria-hidden="true"/>

      <a
        wriFocusOutline
        tabindex="0"
        class="delete-account-btn wri-btn wri-btn-text"
        aria-label="delete my account"
        data-testid="delete-account-btn"
        (click)="openDeleteAccountModal()"
      >
        Delete my account
      </a>

    </div>

  </ng-container>
</div>


<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title">
        We’re sad to see you go.
      </span>
      <span class="wri-modal__description">
        <div class="wri-modal-user-account-title"> Deleting your account will: </div>
        <span>Permanently delete your account info and access to previous orders</span>
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          [disabled]="deleteAccountLoading"
          data-testid="permission-modal-action"
          class="wri-btn wri-btn-primary"
          aria-label="Delete Account"
          (click)="deleteAccount()">
          <ng-container *ngIf="!deleteAccountLoading"> DELETE ACCOUNT</ng-container>
          <div
            *ngIf="deleteAccountLoading"
            class="spinner-wrapper-overlay"
          >
            <wri-spinner spinnerColor="white"></wri-spinner>
          </div>

        </button>
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="closeModal()">
          Cancel
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
<wri-partial-outage-modal></wri-partial-outage-modal>

<ng-template #changeMyPasswordModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title">
        Change password
      </span>
      <span class="wri-modal__description">
        To change your password, you need to log out. You will then be able to change your password through the “Forgot/Reset Password” link.
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          [disabled]="logoutLoading"
          data-testid="permission-modal-action"
          class="wri-btn wri-btn-primary"
          aria-label="Log out"
          (click)="logout($event); close($event)">
          <ng-container *ngIf="!logoutLoading"> LOG OUT</ng-container>
          <div
            *ngIf="logoutLoading"
            class="spinner-wrapper-overlay"
          >
            <wri-spinner spinnerColor="white"></wri-spinner>
          </div>

        </button>
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="closeModal()">
          Cancel
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
