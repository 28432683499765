import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wri-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id = '';
  @Input() label = '';
  _value = false;

  @Input() customClass = '';

  /** Implement ControlValueAccessor */
  @Input()
  set value(value: boolean) {
    this._value = value;
    this.onChange(value);
  }

  get value(): boolean {
    return this._value;
  }

  public canShowErrors = false;
  public hideErrors() {
    this.canShowErrors = false;
    this.onTouched();
  }
  public showErrors() {
    this.canShowErrors = true;
    this.onTouched();
  }

  /** Implement ControlValueAccessor */
  @Input() disabled = false;
  @Input() touched = false;
  onChange: (v: boolean) => void = () => void 0;
  onTouched: () => void = () => void 0;

  writeValue(newValue: boolean): void {
    this.value = newValue;
  }

  registerOnChange(onChange: (v: boolean) => void): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
