import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay
} from 'rxjs';

// TODO combine with redirect service
@Injectable({ providedIn: 'root' })
export class CurrentUrlService {
  private screenName$: Observable<string> = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map((navEnd) => navEnd as NavigationEnd),
    map((navEnd) => navEnd.urlAfterRedirects),
    map((url) => url.substring(url.lastIndexOf('/'))),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(private router: Router) {}

  public getScreenName(): Observable<string> {
    return this.screenName$;
  }

  public goToMyBag = () => {
    const url = '/order/my-bag';
    this.router.navigate([url], {
      queryParamsHandling: 'preserve'
    });
  };

  public goToOrderPage = (handoffMode?: string) => {
    let extras = undefined;
    if (handoffMode)
      extras = {
        queryParams: {
          handoffMode: handoffMode
        }
      };
    this.router.navigate(['/order'], extras);
  };

  public goToRecentOrder = () => {
    const url = '/order/recent';
    this.router.navigate([url], {
      queryParamsHandling: 'preserve'
    });
  };

  public goToHomePage = () => {
    const url = ''; // homepage
    this.router.navigate([url]);
  };
}
