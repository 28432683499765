import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WriCommonModule } from '../common';
import { ConnectedOfflinePageComponent } from './components/connected-offline-page/connected-offline-page.component';

const components = [
	ConnectedOfflinePageComponent
];

@NgModule({
	imports: [
		CommonModule,
		WriCommonModule,
		RouterModule,
		NgbModule
	],
	exports: components,
	declarations: components
})
export class OfflineModule {
}