import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wri-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {
  @Input()
  public control: UntypedFormControl | AbstractControl | undefined;
  @Input()
  public errorKey: string | undefined;
  @Input()
  public display = 'Invalid';
  @Input()
  public formWarning = false;
}
