import { Component, Input } from '@angular/core';
import { CustomerFavoriteLocations } from '../../../../ecomm/types/customer.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemoveFavoriteLocationModalComponent } from './remove-favorite-location-modal/remove-favorite-location-modal.component';

@Component({
  selector: 'wri-favorite-locations',
  templateUrl: './favorite-locations.component.html',
  styleUrls: ['./favorite-locations.component.scss']
})
export class FavoriteLocationsComponent {
  @Input() favoriteWingstopLocations: CustomerFavoriteLocations[] | undefined;

  constructor(
    private modalService: NgbModal
  ) { }

  public areFavoriteLocationsAvailable(): boolean {
    return (
      (this.favoriteWingstopLocations &&
        this.favoriteWingstopLocations?.length > 0
      ) ||
      false
    );
  }

  filterFavoriteLocation(
    locations: CustomerFavoriteLocations[] | undefined,
    selectedLocation: CustomerFavoriteLocations
  ) {
    return locations?.filter((location) => location.id !== selectedLocation.id) || [];
  }

  async openDeleteConfirmationModal(delData: {
    deleteFavoriteLocation: boolean;
    data: CustomerFavoriteLocations;
  }) {
    const { data } = delData;
    const modalRef = this.modalService.open(RemoveFavoriteLocationModalComponent, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });

    modalRef.componentInstance.modalData = delData;

    const values = await modalRef.result;
    if (values) {
      const { isDeleted } = values;
      if (isDeleted) {
        this.favoriteWingstopLocations = this.favoriteWingstopLocations?.filter((location) => location.id !== data.id) || [];
      }
    }
  }
}
