import { Pipe, PipeTransform } from '@angular/core';

import {
  LocationHandoffModeCalendarDto,
  LocationNormalCalendarRangeDto
} from '../../../ecomm/types/search-location.types';

@Pipe({
  name: 'getStoreHandoffModeHours'
})
export class GetStoreHandoffModeHoursPipe implements PipeTransform {
  transform(
    storeHandoffModeHours: LocationHandoffModeCalendarDto[],
    handoffMode: string
  ): LocationNormalCalendarRangeDto[] | null {
    if (storeHandoffModeHours.length) {
      const hrs = storeHandoffModeHours.find(
        (storeHrs) => storeHrs.handoffMode === handoffMode
      )?.hours;
      return hrs ?? [];
    }
    return null;
  }
}
