import { Pipe, PipeTransform } from '@angular/core';

import { Customer } from '../../../ecomm/types/customer.types';

@Pipe({
  name: 'favLocation'
})
export class FavLocationPipe implements PipeTransform {
  transform(customer: Customer, orderLocationId: string): boolean {
    const hasFav = customer?.favoriteWingstopLocations?.some(
      (favLoc) => favLoc.id === orderLocationId
    );
    if (hasFav) {
      return true;
    }
    return false;
  }
}