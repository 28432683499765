import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export type ReorderModalData = {
  orderId: string;
  orderLocationId: string;
  orderHandoffMode: string;
};

@Component({
  selector: 'wri-reorder-confirmation-modal',
  template: `
    <ng-template #reorderConfirmationModal>
      <div class="wri-modal__wrapper">
        <div data-testid="cart-modal-title" class="wri-modal__title">
          Heads Up!
        </div>
        <div
          data-testid="cart-modal-description"
          class="wri-modal__description"
        >
          It looks like you already started an order. Reordering will replace
          the order you have in progress.
        </div>
        <div class="wri-modal__btn-wrapper">
          <button
            [disabled]="isLoading"
            data-testid="confirm-reorder-btn"
            class="wri-btn wri-btn-primary"
            aria-label="Reorder"
            (click)="reorder.emit(reorderModalData)"
          >
            <ng-container *ngIf="!isLoading"> Reorder </ng-container>
            <div
              *ngIf="isLoading"
              class="spinner-wrapper"
            >
              <wri-spinner spinnerColor="white"></wri-spinner>
            </div>
          </button>
          <button
            data-testid="cart-modal-cancel"
            class="wri-btn wri-btn-tertiary"
            aria-label="Cancel"
            data-testid="cancel-reorder-btn"
            (click)="closeModal()"
          >
            Cancel
          </button>
        </div>
      </div>

    </ng-template>
  `,
  styleUrls: ['./reorder-confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReorderConfirmationModalComponent {
  @Input()
  isLoading = false;

  @Output()
  reorder = new EventEmitter<string>();

  @ViewChild('reorderConfirmationModal')
  reorderConfirmationModal!: TemplateRef<HTMLElement>;

  modalReference: NgbModalRef | undefined;
  reorderModalData?: ReorderModalData;

  constructor(private modalService: NgbModal) {}

  closeModal() {
    this.modalReference?.close();
  }

  openModal(reorderModalData: ReorderModalData) {
    this.reorderModalData = reorderModalData;
    this.modalReference = this.modalService.open(
      this.reorderConfirmationModal,
      {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm'
      }
    );
  }
}
