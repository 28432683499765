import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Subscription,
  filter,
  switchMap,
  take,
  tap
} from 'rxjs';

import {
  LocationDetailsFeature,
  LocationDetailsFeatureState
} from '../../../../ecomm/store/features/location-details';
import {
  OrderFeature,
  OrderFeatureState
} from '../../../../ecomm/store/features/order';
import { Order } from '../../../../ecomm/types/order.types';
import { LocationResponseData } from '../../../../ecomm/types/search-location.types';
import { ACTIVATED_ROUTE_SNAPSHOT } from '../../../common';
import { ConfirmationPageService } from '../../../common/page-services/confirmation-page.service';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { AuthFeatureState } from '../../../../ecomm/store/features/auth';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';

@Component({
  selector: 'wri-connected-confirmation',
  template: `
    <wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
    <wri-confirmation [order]="orderWithLocation$ | async"></wri-confirmation>
  `
})
export class ConnectedConfirmationComponent implements OnInit, OnDestroy {
  public isLoading = true;
  orderWithLocation$ = new BehaviorSubject<
    (Order & { locationDetails: LocationResponseData | null }) | null
  >(null);
  private subscription = new Subscription();

  private orderState$ = this.store
    .select(OrderFeature.selectOrderState)
    .pipe(filter<OrderFeatureState>(Boolean));

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private pageService: ConfirmationPageService,
    private notificationService: NotificationService,
    private asynchronousDispatcher: AsynchronousDispatcher<AuthFeatureState>
  ) {}

  private locationDetailsSubscription = new Subscription();

  subscribeToOrderState() {
    this.orderState$
      .pipe(
        take(1) // Take only the first emission
      )
      .subscribe(async (orderState) => {
        if (orderState.lastSessionOrder?.cart.locationId) {
          this.locationDetailsSubscription = this.store
            .select(LocationDetailsFeature.selectLocationDetailsState)
            // eslint-disable-next-line
            .subscribe((state: LocationDetailsFeatureState) => {
              if (state.locationDetails && orderState.lastSessionOrder) {
                this.orderWithLocation$.next({
                  ...(orderState.lastSessionOrder as Order),
                  locationDetails: state.locationDetails
                });
                this.isLoading = false;
              }
            });
        }

        if (orderState.accountCreation) {
          if (orderState.accountCreation.error) {
            this.notificationService.showError(
              'Sorry, there was an error creating your account.',
              '',
              true
            );
          } else if (orderState.accountCreation.data) {
            this.notificationService.showSuccess(
              'Your account has been successfully created.'
            );
            // reset accountCreation
            this.resetAccountCreation();
          }
        }
      });
  }

  private async resetAccountCreation() {
    await this.asynchronousDispatcher.dispatch(
      OrderFeature.actions.setAccountCreation({
        accountCreation: {
          error: undefined,
          data: undefined
        }
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(
          filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
          tap(() => (this.isLoading = true)),
          switchMap((data) =>
            this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT])
          )
        )
        .subscribe(() => {
          this.isLoading = false;
        })
    );

    this.subscribeToOrderState();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.locationDetailsSubscription.unsubscribe();
  }
}
