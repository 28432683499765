<label
  class="flavor-mod-group-wrapper"
  [for]="'chk-' + flavorModGroupElement?.id"
  [attr.data-testid]="'flavor-name-' + flavorModGroupElement?.id"
  [ngStyle]="flavorModGroupElement?.outOfStock && { color: '#A1A1A1' }"
  [attr.data-disabled]="
    flavorModGroupElement?.outOfStock ||
    isItemDisabled(flavorModGroupElement?.id)
  "
>
  <div
    *ngIf="
      flavorModGroupElement?.images && flavorModGroupElement?.images.length > 0
    "
    class="mod-image-container"
  >
    <ng-container
      *ngIf="
        flavorModGroupElement?.images &&
          flavorModGroupElement?.images.length > 0;
        then modifierImage;
        else placeHolderImage
      "
    >
    </ng-container>
    <ng-template #modifierImage>
      <img
        aria-live="polite"
        class="flavor-image"
        srcset="{{ flavorModGroupElement?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
        src="{{
          flavorModGroupElement?.images[0]?.uri
            | imageResolution
              : 'src'
              : 'pdp-modifiers'
              : '/assets/images/logo-green-product.png'
        }}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        role="img"
        alt="{{ flavorModGroupElement?.name | prepend : 'image-for' }}"
      />
    </ng-template>
    <ng-template #placeHolderImage>
      <img
        class="flavor-image"
        aria-live="polite"
        alt="placeholder-image"
        src="/assets/images/logo-green-product.png"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        role="img"
      />
    </ng-template>
  </div>
  <ng-container
    *ngIf="
      maxSelectionsAllowed > 1;
      then isMultiselectCheckbox;
      else isSingleselectRadio
    "
  ></ng-container>

  <ng-template #isMultiselectCheckbox>
    <div class="mod-elements-details checkbox-control-container">
      <div>
        <span class="mod-name mod-name-top">{{
          flavorModGroupElement?.name
        }}</span>
        <ng-container
          *ngIf="
            flavorModGroupElement?.outOfStock;
            else flavorModGroupElementMetadata
          "
        >
          <wri-unavailable-item></wri-unavailable-item>
        </ng-container>

        <ng-template #flavorModGroupElementMetadata>
          <div class="mod-meta-container">
            <wri-heat-scale [heatScale]="heatScale"></wri-heat-scale>
            <wri-designation
              [rubType]="flavorModGroupElement?.metadata['rub-type']"
              [isPopular]="flavorModGroupElement?.isPopular"
              [isNew]="flavorModGroupElement?.isNew"
            >
            </wri-designation>
          </div>
          <div
            *ngIf="
              flavorModGroupElement?.minCalories !== null ||
              flavorModGroupElement?.maxCalories !== null
            "
          >
            <span class="mod-calories">
              {{ returnCalorieRange(flavorModGroupElement) }}
            </span>
          </div>
        </ng-template>
      </div>
      <input
        [id]="'chk-' + flavorModGroupElement?.id"
        class="mod-elements-checkbox-input"
        tabindex="0"
        [ngClass]="
          isChecked(flavorModGroupElement?.id) ? 'flavor-input-checked' : ''
        "
        [disabled]="
          flavorModGroupElement?.outOfStock ||
          isItemDisabled(flavorModGroupElement?.id)
        "
        (change)="
          onChangeModifierElement(
            !isChecked(flavorModGroupElement?.id),
            'multiple',
            flavorModGroupElement!
          )
        "
        (blur)="onTouched()"
        (focus)="onTouched()"
        type="checkbox"
        [attr.data-testid]="flavorModGroupElement?.id | prepend : 'input-for-'"
        [attr.aria-label]="flavorModGroupElement?.name"
      />
    </div>
  </ng-template>

  <ng-template #isSingleselectRadio>
    <div class="mod-elements-details radio-control-container">
      <div>
        <span class="mod-name mod-name-top">{{
          flavorModGroupElement?.name
        }}</span>
        <ng-container
          *ngIf="
            flavorModGroupElement?.outOfStock;
            else flavorModGroupElementMetadata
          "
        >
          <wri-unavailable-item></wri-unavailable-item>
        </ng-container>

        <ng-template #flavorModGroupElementMetadata>
          <div class="mod-meta-container">
            <wri-heat-scale [heatScale]="heatScale"></wri-heat-scale>
            <wri-designation
              [rubType]="flavorModGroupElement?.metadata['rub-type']"
              [isPopular]="flavorModGroupElement?.isPopular"
              [isNew]="flavorModGroupElement?.isNew"
            >
            </wri-designation>
          </div>
          <div
            *ngIf="
              flavorModGroupElement?.minCalories !== null ||
              flavorModGroupElement?.maxCalories !== null
            "
          >
            <span class="mod-calories">
              {{ returnCalorieRange(flavorModGroupElement) }}
            </span>
          </div>
        </ng-template>
      </div>
      <input
        [id]="'chk-' + flavorModGroupElement?.id"
        class="mod-elements-radio-input"
        tabindex="0"
        [checked]="isChecked(flavorModGroupElement?.id)"
        [disabled]="flavorModGroupElement?.outOfStock"
        (change)="
          onChangeModifierElement(
            !isChecked(flavorModGroupElement?.id),
            'single',
            flavorModGroupElement!
          )
        "
        (blur)="onTouched()"
        (focus)="onTouched()"
        type="checkbox"
        [attr.data-testid]="flavorModGroupElement?.id | prepend : 'input-for-'"
        [attr.aria-label]="flavorModGroupElement?.name"
      />
    </div>
  </ng-template>
</label>
<div
  *ngIf="
    maxSelectionsAllowed > 1 &&
    quantitiesEnabled &&
    !flavorModGroupElement?.outOfStock
  "
  class="flavor-quantity-wrapper"
>
  <wri-flavor-quantity
    *ngIf="isChecked(flavorModGroupElement?.id)"
    [itemType]="flavorModGroupElement?.metadata?.['item-type'] || 'Wings'"
    [flavorId]="flavorModGroupElement?.id"
    [minModifierQuantity]="minModifierQuantity"
    [maxModifierQuantity]="maxModifierQuantity"
    [quantity]="getQuantity(flavorModGroupElement?.id)"
    (valuesChanged)="
      handleFlavorQuantityChange($event, flavorModGroupElement?.id)
    "
  >
  </wri-flavor-quantity>
</div>
