import { Component } from '@angular/core';

@Component({
  selector: 'wri-google-recaptcha-branding',
  templateUrl: './google-recaptcha-branding.component.html',
  styleUrls: ['./google-recaptcha-branding.component.scss']
})
export class GoogleRecaptchaBrandingComponent {

}
