export type Slugs = {
  categorySlug: string;
  productSlug: string;
  itemSlug?: string;
};

export type PageType = {
  '/my-bag': string;
  '/checkout': string;
  '/confirmation': string;
};

export const PageType = {
  '/my-bag': 'Cart',
  '/checkout': 'Checkout',
  '/confirmation': 'Confirmation'
};
