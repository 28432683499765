import { Component, Input } from '@angular/core';

import {
  MenuItem,
  MenuModifier
} from '../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import {
  FlavorSpiceHeading,
  FlavorSpiceHeadingColors
} from '../../../common/types/modifiers';

@Component({
  selector: 'wri-flavor-modal-category',
  templateUrl: './flavor-modal-category.component.html'
})
export class FlavorModalCategoryComponent {
  @Input() modifiers: MenuModifier[] | undefined;
  @Input() flavorHeading!: string;
  @Input() flavorType: string | undefined;
  @Input() flavorColor: string | undefined;
  readonly flavorSpiceHeading = FlavorSpiceHeading;
  readonly flavorSpiceHeadingColors = FlavorSpiceHeadingColors;

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }
}
