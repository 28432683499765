import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CustomerRepository } from '../../repositories/customer/customer.repository';
import { Cart } from '../../types/cart.types';
import {
  Customer,
  CustomerOrders,
  CustomerPutRequest,
  DeliveryAddressPostRequest,
  ReOrderPostRequest,
  SignupCustomer,
  SignupCustomerRequest,
} from '../../types/customer.types';
import * as MaybeResponse from '../../types/maybe-response';
import { fromDto } from '../../utils/from-dto';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  constructor(private customerRepository: CustomerRepository) {}

  public getCustomer(
    accessToken: string,
    customer?: Customer
  ): Observable<MaybeResponse.MaybeResponse<Customer>> {
    return this.customerRepository.getCustomer(accessToken, customer).pipe(
      map(
        MaybeResponse.mapData((dto) => ({
          ...dto,
          address: fromDto(dto.address),
        }))
      )
    );
  }

  public updateCustomer(
    accessToken: string,
    request: CustomerPutRequest
  ): Observable<MaybeResponse.MaybeResponse<Customer>> {
    return this.customerRepository.updateCustomer(accessToken, request).pipe(
      map(
        MaybeResponse.mapData((dto) => ({
          ...dto,
          address: fromDto(dto.address),
        }))
      )
    );
  }

  public getOrderHistory(
    accessToken: string
  ): Observable<MaybeResponse.MaybeResponse<CustomerOrders>> {
    return this.customerRepository
      .getOrderHistory(accessToken)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public deleteFavoriteLocation(
    locationId: string
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    return this.customerRepository.deleteFavoriteLocation(locationId);
  }

  public setFavoriteLocation(
    locationId: string
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    return this.customerRepository.setFavoriteLocation(locationId);
  }

  public saveDeliveryAddress(
    accessToken: string,
    request: DeliveryAddressPostRequest
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    return this.customerRepository.saveDeliveryAddress(accessToken, request);
  }

  public deleteMyAddress(
    addressId: string
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    return this.customerRepository.deleteMyAddress(addressId);
  }

  public reorder(
    accessToken: string,
    request: ReOrderPostRequest
  ): Observable<MaybeResponse.MaybeResponse<Cart>> {
    return this.customerRepository
      .reorder(accessToken, request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public signUp(
    request: SignupCustomerRequest,
    recaptchaToken: string
  ): Observable<MaybeResponse.MaybeResponse<SignupCustomer>> {
    return this.customerRepository
      .signUp(request, recaptchaToken)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public deleteAccount(
    recaptchaToken: string
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    return this.customerRepository.deleteAccount(recaptchaToken);
  }
}
