<span
  data-testid="badge-text"
  [ngClass]="{
    'wri-badge-warn': type === 'warn',
    'wri-badge-success': type === 'success',
    'wri-badge-error': type === 'error'
  }"
  class="wri-badge wri-badge-pill"
>
  <ng-container *ngIf="icon">
    <wri-icon [icon]="icon" class="wri-badge-icon"></wri-icon>
  </ng-container>
  {{ label }}
</span>