<a
  wriFocusOutline
  href="#"
  data-testid="wri-bag-link"
  data-cy="MyBag"
  class="bag-page-link bag-anchor"
  role="button"
  [attr.aria-label]="
    'Go to My bag page, ' + count | pluralize : 'item' : 's' + ' in bag'
  "
  (click)="handleClick($event)"
  data-cy="MyBag"
>
  <div class="icon-wrapper">
    <wri-icon icon="wri-bag"></wri-icon>
  </div>
  <span *ngIf="count" data-testid="wri-bag-count" class="wri-bag-count">
    {{ count }}
  </span>
</a>
