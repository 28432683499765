<div
  class="round-up-modal"
  role="dialog"
  aria-modal="true"
  trapFocus
  aria-label="Wingstop Charities Information"
>
  <div class="round-up-modal__header">
    <button
      type="button"
      #closeBtn
      aria-label="Close"
      class="round-up-modal__btn-close"
      (click)="close($event)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="round-up-modal__body">
    <img
      class="round-up-modal__logo"
      src="{{ roundupCharityData?.modalImageUrl }}"
      alt="Wingstop Charities"
    />
    <div>
      <h1 id="round-up-modal-title" class="round-up-modal__title">
        {{ roundupCharityData?.modalTitle }}
      </h1>
      <p id="round-up-modal-description" class="round-up-modal__description">
        {{ roundupCharityData?.modalDescription }}
      </p>
    </div>
  </div>
</div>
