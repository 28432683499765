import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

import { CustomerOrders } from '../../../ecomm/types/customer.types';

@Pipe({
  name: 'sortPastOrder'
})
export class SortPastOrderPipe implements PipeTransform {
  transform(pastOrders: CustomerOrders) {
    const sortedOrders = orderBy(pastOrders, [(dateObj) => {
      return dateObj.orderDate && new Date(dateObj.orderDate);
    }],["desc"]);
    return sortedOrders
  }
}
