import { InjectionToken, ValueProvider } from '@angular/core';

export const WEB_SOCKET = new InjectionToken<typeof WebSocket>(
  'To inject the WebSocket object into components & services'
);

export class WebSocketProvider {
  public static get = (): ValueProvider => ({
    provide: WEB_SOCKET,
    useValue: {
      create: (...args: ConstructorParameters<typeof WebSocket>) =>
        new WebSocket(...args)
    }
  });
}

export type WebSocketProviderType = {
  create: (...args: ConstructorParameters<typeof WebSocket>) => WebSocket;
};
