<form>
  <div class="settings-form-section">
    <span class="settings-title" data-cy="myInformation">
      Payment Methods
    </span>
    <div class="payment-methods-form" data-testid="payment-methods-form">
      <ng-container
        *ngIf="
          areVaultedCardsAvailable();
          then paymentMethodsContainer;
          else noPaymentMethodsContainer
        "
      ></ng-container>
      <ng-template #noPaymentMethodsContainer>
        <wri-no-payment-methods />
      </ng-template>
      <ng-template #paymentMethodsContainer>
        <!-- MANAGE GIFT CARDS -->
        <div class="gift-section">
          <span class="settings-subtitle">Gift Cards</span>
          <ng-container
            *ngIf="areVaultedGiftCardsViewEnabled">
            <ng-container
              *ngIf="
                vaultedCards?.vaultedGiftCard.length > 0;
                then hasVaultedGiftCards;
                else noVaultedGiftCards
              "
            ></ng-container>

            <ng-template #hasVaultedGiftCards>
              <div data-testid="gift-card-payment-section">
                <div
                  *ngFor="let vaultedGiftCard of vaultedCards?.vaultedGiftCard"
                >
                  <div class="settings-payment-details-wrapper">
                    <div class="flex card-icon-wrapper">
                      <wri-icon icon="wri-gift-card"></wri-icon>
                    </div>
                    <div>
                      <div class="flex flex-baseline">
                        <div class="card-type">Wingstop gift card</div>
                        <div data-testid="card-alias" class="card-description">
                          ending in {{ vaultedGiftCard.alias }}
                        </div>
                      </div>
                      <div data-testid="card-balance" class="card-details">
                        Balance: {{ vaultedGiftCard.balance | currency }}
                      </div>
                    </div>
                    <div class="flex flex-align-left">
                      <a
                        wriFocusOutline
                        tabindex="0"
                        (click)="
                          openDeleteConfirmationModal({ data: vaultedGiftCard })
                        "
                        aria-label="Delete"
                      >
                        <wri-icon
                          class="delete-btn"
                          icon="wri-delete"
                        ></wri-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template #noVaultedGiftCards>
              <div
                data-testid="gift-card-empty-state"
                class="no-vaulted-gift-card"
              >
                You do not have any saved gift cards. You can add gift cards
                when you checkout.
              </div>
            </ng-template>
          </ng-container>
          <ng-container
            *ngIf="areGiftCardsVaulted() && !areVaultedGiftCardsViewEnabled">
            <div class="no-payment-support">
              Your saved gift cards are currently unavailable.
            </div>
          </ng-container>
          <ng-container
            *ngIf="!areGiftCardsVaulted() && !areVaultedGiftCardsViewEnabled">
            <div
              data-testid="gift-card-empty-state"
              class="no-vaulted-gift-card"
            >
              You do not have any saved gift cards. You can add gift cards
              when you checkout.
            </div>
          </ng-container>
        </div>
        <!-- MANAGE CREDIT CARDS -->
        <div class="credit-section">
          <span class="settings-subtitle">Credit/Debit Cards</span>
          <ng-container *ngIf="areVaultedCreditCardsViewEnabled">
            <ng-container
              *ngIf="
                vaultedCards?.vaultedCreditCard.length > 0;
                then hasVaultedCreditCards;
                else noVaultedCreditCards
              "
            ></ng-container>
            <ng-template #hasVaultedCreditCards>
              <div data-testid="credit-card-payment-section">
                <div
                  *ngFor="
                    let vaultedCreditCard of vaultedCards?.vaultedCreditCard
                  "
                >
                  <div class="settings-payment-details-wrapper">
                    <div class="card-icon-wrapper">
                      <wri-icon icon="wri-credit-card"></wri-icon>
                    </div>
                    <div>
                      <div class="flex flex-baseline">
                        <div class="card-type">
                          {{ vaultedCreditCard?.credit?.cardType }}
                        </div>
                        <div
                          data-testid="credit-card-alias"
                          class="card-description"
                        >
                          ending in {{ vaultedCreditCard?.credit?.alias }}
                        </div>
                      </div>
                      <div data-testid="card-exp-date" class="card-details" [ngClass]="{'expiry-data': vaultedCreditCard?.credit?.expiryDate | expiryData}">
                        <ng-container *ngIf="vaultedCreditCard?.credit?.expiryDate | expiryData; else notExpired">Expired</ng-container>
                        <ng-template #notExpired>
                          Expires
                        </ng-template>
                        {{ vaultedCreditCard?.credit?.expiryDate?.month }}/{{
                          vaultedCreditCard?.credit?.expiryDate?.year
                        }}
                      </div>
                    </div>
                    <div class="flex flex-align-left">
                      <a
                        wriFocusOutline
                        tabindex="0"
                        (click)="
                          openDeleteConfirmationModal({
                            data: vaultedCreditCard,
                            deleteCreditCard: true
                          })
                        "
                        aria-label="Delete"
                      >
                        <wri-icon
                          class="delete-btn"
                          icon="wri-delete"
                        ></wri-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #noVaultedCreditCards>
              <div
                data-testid="credit-card-empty-state"
                class="no-vaulted-credit-card"
              >
                You do not have any saved credit/debit cards. You can add
                credit/debit cards when you checkout.
              </div>
            </ng-template>
          </ng-container>
          <ng-container
            *ngIf="areCreditCardsVaulted() && !areVaultedCreditCardsViewEnabled">
            <div class="no-payment-support">
              Your saved credit/debit cards are currently unavailable.
            </div>
          </ng-container>
          <ng-container
            *ngIf="!areCreditCardsVaulted() && !areVaultedCreditCardsViewEnabled">
            <div
              data-testid="credit-card-empty-state"
              class="no-vaulted-credit-card"
            >
              You do not have any saved credit/debit cards. You can add
              credit/debit cards when you checkout.
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</form>
