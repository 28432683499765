import {
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Location } from '../../../../ecomm/types/search-location.types';
import { MarkerData } from '../../../common';

type Hours = {
  startDay: string;
  startTime: string;
  endDay: string;
  endTime: string;
};

@Component({
  selector: 'wri-location-info-modal',
  templateUrl: './location-info-modal.component.html',
  styleUrls: ['./location-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationInfoModalComponent implements OnChanges {
  private static readonly MAPS_BASE_URL = 'https://www.google.com/maps/search/';
  private static readonly MAPS_QUERY_STR = '?api=1&query=';
  private static readonly GET_DIRECTIONS_TO = 'Get directions to';
  private static readonly OPEN_IN_NEW_TAB_DISCLAIMER = 'Opens in a new tab.';

  public markerData: MarkerData[] = [];
  modalReference: NgbModalRef | undefined;
  mapUrl = '';
  linkLabel = '';
  @ViewChild('locationInfoModal') locationInfoModal!: TemplateRef<HTMLElement>;

  constructor(private modalService: NgbModal) {}

  private _locationData: Location | undefined;

  get locationData() {
    return this._locationData;
  }

  @Input() set locationData(val: Location | undefined) {
    this._locationData = val;
    this.markerData = this._locationData
      ? [
          {
            lat: this._locationData.latitude,
            lng: this._locationData.longitude,
            id: this._locationData.id,
            description: this._locationData.name
          }
        ]
      : [];
  }

  private _carryoutOrBusinessHours: Hours[] | undefined;

  get carryoutOrBusinessHours() {
    return this._carryoutOrBusinessHours;
  }

  @Input() set carryoutOrBusinessHours(val: Hours[] | undefined) {
    this._carryoutOrBusinessHours = val;
  }

  ngOnChanges() {
    this.mapUrl = this.getMapUrl();

    this.linkLabel = this.locationData?.name
      ? this.getLinkLabelForNamedLocation()
      : this.getLinkLabelForUnnamedLocation();
  }

  onModalClose(event: Event) {
    event.preventDefault();
    this.modalReference?.close();
  }

  openModal() {
    this.modalReference = this.modalService.open(this.locationInfoModal, {
      windowClass: 'location-info-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down'
    });
  }

  isAmenityAvailable(amenityName: string) {
    return this.locationData?.amenities?.some((f) => f.name === amenityName);
  }

  getDescriptionOfAmenity(amenityName: string) {
    return this.locationData?.amenities?.find((f) => f.name === amenityName)
      ?.description;
  }

  get offersDelivery() {
    return (
      this.locationData?.handoffModes.some(
        (f) => f.toLowerCase() === 'delivery'
      ) ?? false
    );
  }

  private getMapUrl(): string {
    return (
      LocationInfoModalComponent.MAPS_BASE_URL +
      LocationInfoModalComponent.MAPS_QUERY_STR +
      [
        this.getFullStreetAddress(),
        this.locationData?.locality,
        this.locationData?.region,
        this.locationData?.postalCode,
        this.locationData?.countryCode,
        this.locationData?.phoneNumber
      ].join(' ')
    );
  }

  private getFullStreetAddress(): string {
    return [
      this.locationData?.streetAddress,
      this.locationData?.secondaryAddress
    ]
      .filter((s) => !!s)
      .join(' ');
  }

  private getLinkLabelForNamedLocation(): string {
    return [
      LocationInfoModalComponent.GET_DIRECTIONS_TO,
      `${this.locationData?.name} at`,
      `${this.getFullStreetAddress()},`,
      `${this.locationData?.locality}.`,
      LocationInfoModalComponent.OPEN_IN_NEW_TAB_DISCLAIMER
    ].join(' ');
  }

  private getLinkLabelForUnnamedLocation(): string {
    return [
      LocationInfoModalComponent.GET_DIRECTIONS_TO,
      `${this.getFullStreetAddress()},`,
      `${this.locationData?.locality}.`,
      LocationInfoModalComponent.OPEN_IN_NEW_TAB_DISCLAIMER
    ].join(' ');
  }
}
