import { FactoryProvider, InjectionToken } from '@angular/core';
import localForage from 'localforage';

export const STORAGE = new InjectionToken<typeof localForage>(
  'To inject the Storage object into components & services'
);

export class StorageProvider {
  public static get = (): FactoryProvider => ({
    provide: STORAGE,
    useFactory: () => {
      localForage.config({
        driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE],
        name: 'Wingstop-NGFE'
      });
      return localForage;
    }
  });
}
