<div class="wri-empty-cart-wrapper">
  <div class="icon-round spacing-bottom">
    <wri-icon icon="wri-bag"></wri-icon>
  </div>

  <span class="cart-empty-text" data-testid="cart-empty-text"
    >Your Cart is Empty</span
  >
  <span class="no-items-text" data-testid="no-items-text"
    >Looks like you don't have any items in your order.</span
  >
  <button
    wriFocusOutline
    data-testid="browse-menu"
    [ngClass]="'wri-btn wri-btn-primary'"
    aria-label="Browse the menu"
    (click)="goToMenu()"
  >
    Browse the menu
    <wri-icon
      class="icon arrow-long-right"
      icon="wri-arrow-long-right"
    ></wri-icon>
  </button>
</div>
