import { Component, OnInit } from '@angular/core';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { RegionalConfiguration } from '../../../../ecomm/types/regional-configuration.types';
import { Router } from '@angular/router';

@Component({
	selector: 'wri-connected-offline-page',
	templateUrl: './connected-offline-page.component.html',
	styleUrls: ['./connected-offline-page.component.scss']
})
export class ConnectedOfflinePageComponent implements OnInit {

	public regionalConfigState: RegionalConfiguration

	constructor(
		private storeInfoService: StoreInfoWorkflowService,
		private router: Router
	) { }

	async ngOnInit() {
		const regionalConfig =
			await this.storeInfoService.getRegionalConfigurationAndSave();
		this.regionalConfigState = regionalConfig
		if (!regionalConfig.isOffline) {
			this.router.navigateByUrl('/');
		}
	}
}