import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

import { Optional } from '../../../../ecomm/types/common.types';

type View = 'searching' | 'no-locations' | 'locations' | 'empty';

@Component({
  selector: 'wri-job-location-search-list',
  template: `
    <ng-container [ngSwitch]="view">
      <ng-template [ngSwitchCase]="VIEWS['searching']">
        <div class="searching">
          <wri-spinner></wri-spinner>
          <p>{{ loadingMessage }}</p>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="VIEWS['noLocations']">
        <div class="no-locations-found">
          <div class="icon-round">
            <wri-icon icon="wri-location"></wri-icon>
          </div>
          <div>No Locations Available</div>
          <p>{{ noLocationsMessage }}</p>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="VIEWS['locations']">
        <wri-job-location-search-card
          *ngFor="let location of locations"
          [locationData]="location"
          [showFocus]="location.id === selectedLocationMarkerId"
          (navigate)="navigate.emit($event)"
        ></wri-job-location-search-card>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./job-location-search-list.component.scss']
})
export class JobLocationSearchListComponent {
  @Input() locations?: Optional<Location[]>;
  @Input() selectedLocationMarkerId?: Optional<string>;
  @Input() searching?: Optional<boolean>;

  @Output() navigate = new EventEmitter<string>();

  public readonly VIEWS: Record<string, View> = {
    searching: 'searching',
    noLocations: 'no-locations',
    locations: 'locations',
    empty: 'empty'
  } as const;

  @HostBinding('class')
  get view(): View | void {
    if (this.searching) {
      return this.VIEWS['searching'];
    }

    if (this.noLocationsFound) {
      return this.VIEWS['noLocations'];
    }

    if (this.locationsFound) {
      return this.VIEWS['locations'];
    }

    return this.VIEWS['empty'];
  }

  get noLocationsFound() {
    return (
      this.locations !== undefined &&
      this.locations !== null &&
      this.locations.length === 0
    );
  }

  get locationsFound() {
    return (
      this.locations !== undefined &&
      this.locations !== null &&
      this.locations.length !== 0
    );
  }

  get loadingMessage() {
    return `Loading Results`;
  }

  get noLocationsMessage() {
    return `Sorry, no job locations were found. Try searching in a different area.`;
  }
}
