import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@wingstop/environments/environment';
import { map } from 'rxjs/operators';

declare var window: any;

@Injectable()
export class InteractionStudioInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: any) => {
        if (!environment.interactionStudioDataEnabled) {
          return event;
        }

        if (event instanceof HttpResponse) {
          const requestUrl = request.urlWithParams;
          const globalMenuId = environment.globalMenuId.toString();
          const isGlobalMenuApiCall =
            requestUrl.indexOf('menu?nomnom=menu-products') > 0 &&
            requestUrl.indexOf(globalMenuId) > 0;
          const menuId = this.getLocationMenuId(requestUrl);
          const isLocationMenuApiCall = menuId && menuId !== globalMenuId;
          const isLocationCalendarApiCall =
            requestUrl.indexOf('restaurants/byslug') > 0;
          const modifiersApiCall =
            requestUrl.includes('/products') &&
            requestUrl.includes('/modifiers');

          // Handle global menu API call
          if (isGlobalMenuApiCall) {
            if (environment.envName !== 'prod') {
              console.log('Setting global menu data in window.ws.legacyMenu');
            }
            this.setWindowWsData('legacyMenu', event.body.categories);
          }

          // Handle location menu API call
          if (isLocationMenuApiCall) {
            if (environment.envName !== 'prod') {
              console.log('Setting location menu data in window.ws.location');
            }
            this.setWindowWsData('location', menuId, 'id');
            this.setWindowWsData('location', event.body.categories, 'menu');
          }

          // Handle location calendar API call
          if (isLocationCalendarApiCall) {
            if (environment.envName !== 'prod') {
              console.log(
                'Setting location calendar data in window.ws.location.calendar'
              );
            }
            this.setWindowWsData('location', event.body, 'calendar');
          }

          // Handle modifiers API call
          if (modifiersApiCall) {
            if (environment.envName !== 'prod') {
              console.log(
                'Setting location modifiers data in window.ws.location.modifiers'
              );
            }
            this.setWindowWsData('location', event.body, 'modifiers');
          }
        }
        return event;
      })
    );
  }

  setWindowWsData(key: string, data: any, subkey?: string) {
    if (!window.ws) {
      window.ws = {};
    }
    if (subkey) {
      if (!window.ws[key]) {
        window.ws[key] = {};
      }
      window.ws[key][subkey] = data;
    } else {
      window.ws[key] = data;
    }
  }

  getLocationMenuId(requestUrl: string): string {
    let locationId = '';
    if (requestUrl.indexOf('menu?nomnom=menu-products') > 0) {
      const match = requestUrl.match(/\/restaurants\/(\d+)\//);
      if (match) {
        locationId = match[1];
      }
    }
    return locationId;
  }
}
