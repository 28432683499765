import { Inject, NgModule } from '@angular/core';
import { Route, Router, RouterModule } from '@angular/router';

import { PathParams } from '../../ecomm/constants/params';
import { Config, CONFIG } from '../../ecomm/providers/config/config.provider';
import { CAN_USE_CUSTOM_SEO } from '../../ecomm/store/middleware/static-seo-metadata-effects';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { CityDetailsComponent } from './components/city-details/city-details.component';
import { LocationDetailsComponent } from './components/location-details/location-details.component';
import { StateDetailsComponent } from './components/state-details/state-details.component';
import { LocationsModule } from './locations.module';
import { ACTIVE_OFFER, ActiveOfferResolver, offlineGuard } from '../common';

@NgModule({
  imports: [RouterModule, LocationsModule]
})
export class LocationsRoutingModule {
  constructor(router: Router, @Inject(CONFIG) config: Config) {
    router.resetConfig?.([
      ...Object.values(config.states)
        .map((stateName) => ({
          stateSlug: stateName.replace(' ', '-').toLowerCase(),
          stateName
        }))
        .map(({ stateSlug, stateName }): Route[] => [
          {
            path: stateSlug,
            component: StateDetailsComponent,
            canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
            resolve: {
              [ACTIVE_OFFER]: ActiveOfferResolver
            },
            data: {
              [CAN_USE_CUSTOM_SEO]: true,
              stateSlug,
              stateName
            }
          },
          {
            path: `${stateSlug}/:${PathParams.citySlug}`,
            component: CityDetailsComponent,
            canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
            resolve: {
              [ACTIVE_OFFER]: ActiveOfferResolver
            },
            data: {
              [CAN_USE_CUSTOM_SEO]: true,
              stateSlug,
              stateName
            }
          },
          {
            path: `${stateSlug}/:${PathParams.citySlug}/:${PathParams.locationSlug}`,
            component: LocationDetailsComponent,
            canActivate: [combineGuard(cacheLoadedGuard, offlineGuard)],
            resolve: {
              [ACTIVE_OFFER]: ActiveOfferResolver
            },
            data: {
              [CAN_USE_CUSTOM_SEO]: true,
              stateSlug,
              stateName
            }
          }
        ])
        .flat(),
      ...(router.config ?? [])
    ]);
  }
}
