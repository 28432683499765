import * as t from 'io-ts';

import { ecommApiResponse } from './common.types';

/* #region FulfillmentTimeAvailableDateTime */
export const FulfillmentTimeAvailableDateTimeDto = t.intersection(
  [
    t.type(
      {
        date: t.string,
        availableTimes: t.array(t.string)
      },
      'FulfillmentTimeDtoRequired'
    ),
    t.partial({}, 'FulfillmentTimeDtoOptional')
  ],
  'FulfillmentTimeDto'
);

export type FulfillmentTimeAvailableDateTimeDto = t.TypeOf<
  typeof FulfillmentTimeAvailableDateTimeDto
>;

export type FulfillmentTimeAvailableDateTime =
  FulfillmentTimeAvailableDateTimeDto;
/* #endregion */

/* #region FulfillmentTimes */
export const FulfillmentTimesDto = t.intersection(
  [
    t.type(
      {
        locationId: t.string,
        handoffMode: t.string,
        days: t.array(FulfillmentTimeAvailableDateTimeDto),
        locationTimeZone: t.string,
        isStoreAcceptingAsapOrders: t.boolean
      },
      'FulfillmentTimeDtoRequired'
    ),
    t.partial({}, 'FulfillmentTimeDtoOptional')
  ],
  'FulfillmentTimeDto'
);

export type FulfillmentTimesDto = t.TypeOf<typeof FulfillmentTimesDto>;
export type FulfillmentTimes = Omit<FulfillmentTimesDto, 'days'> & {
  days: FulfillmentTimeAvailableDateTime[];
};
/* #endregion */

/* #region FulfillmentTimeResponse */
export const FulfillmentTimesResponse = ecommApiResponse(FulfillmentTimesDto);

export type FulfillmentTimesResponse = t.TypeOf<
  typeof FulfillmentTimesResponse
>;
/* #endregion */
