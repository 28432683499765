import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FlavorSelectionService } from '../../../common';

@Component({
  selector: 'wri-flavor-quantity',
  templateUrl: './flavor-quantity.component.html',
  styleUrls: ['./flavor-quantity.component.scss']
})
export class FlavorQuantityComponent implements OnInit, OnDestroy {
  @Input() quantity = 0;

  @Input() itemType: string | undefined;

  @Input() minModifierQuantity = 0;

  @Input() maxModifierQuantity = 0;

  @Input() flavorId = '';

  previousQuantity = 0;

  @Output()
  valuesChanged = new EventEmitter<{
    quantity: number;
    previousQuantity: number;
  }>();

  private subscription: Subscription = new Subscription();

  constructor(private flavorSelectionService: FlavorSelectionService) {}

  ngOnInit(): void {
    this.subscription = this.flavorSelectionService.notifyObservable$.subscribe(
      (res: { quantity: number; id: string }) => {
        const { quantity, id } = res;
        if (id === this.flavorId) {
          this.quantity = quantity;
        }
      }
    );
  }

  changeFlavorQuantity(flavorQuantity: number) {
    this.previousQuantity = this.quantity;
    this.quantity = flavorQuantity > 0 ? ++this.quantity : --this.quantity;
    this.valuesChanged.emit({
      quantity: this.quantity,
      previousQuantity: this.previousQuantity
    });
  }

  handleKeyPress(event: { charCode: number; target: { value: number } }) {
    if (event.charCode === 13) {
      this.handleQuantityValidity(event);
    }
  }

  handleQuantityValidity(event: { target: { value: number } }) {
    const userInputQuantity = event.target.value;
    if (!Number.isInteger(userInputQuantity)) {
      this.quantity = this.previousQuantity;
    }
    if (Number(this.previousQuantity) !== Number(userInputQuantity)) {
      this.valuesChanged.emit({
        quantity: Number(userInputQuantity),
        previousQuantity: Number(this.previousQuantity)
      });
    }
  }

  isMinModifierQuantityInvalid(): boolean {
    return this.quantity <= this.minModifierQuantity;
  }

  isMaxModifierQuantityInvalid(): boolean {
    return this.quantity >= this.maxModifierQuantity;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
