<div [id]="'mod-group-section-' + flavorModGroup.id">
  <div
    class="sticky-header"
    [id]="'header-' + flavorModGroup.id"
    wriInteractionStudio="data-chainmodifierid"
    [interactionStudioId]="flavorModGroup.id">
    <div
      wriFocusOutline
      class="wri-flavor-mod-group"
      data-toggle="collapse"
      role="button"
      [tabindex]="0"
      [attr.aria-label]="flavorModGroup.name + ' Accordion menu'"
      [attr.data-target]="'#mod-' + flavorModGroup.id"
      [attr.controls]="'mod-' + flavorModGroup.id"
      [attr.aria-expanded]="isModGroupFirstAndRequired? 'true':'false'"
    >
      <wri-icon
        icon="wri-chevron-right"
        class="flavor-mod-group-chevron"
      ></wri-icon>
      <span
        wriFocusOutline
        data-testid="flavor-mod-group-header-title"
        class="flavor-mod-group-header-title"
        aria-live="polite"
      >
      {{ flavorModGroup.name }}</span
      >
      <span
        wriFocusOutline
        data-testid="flavor-mod-group-selection-msg"
        class="flavor-mod-group-selection-msg"
        aria-live="polite"
      >{{ flavorModGroup.message }}:
      <span class="flavor-mod-group-selected-msg"
            data-testid="flavor-mod-group-selected-msg">{{getSelectedFlavorNames()}}</span>
    </span>

      <wri-badge
        *ngIf="flavorModGroup.required"
        [icon]="areRequiredSelectionsDone() ? 'wri-check' : null"
        [type]="areRequiredSelectionsDone() ? 'success' : startPDPValidations ? 'error' : 'warn'"
        [label]="areRequiredSelectionsDone() ? 'Done' : 'Required'"

        class="flavor-mod-group-header-badge"
      ></wri-badge>

      <span
        *ngIf="flavorModGroup.quantitiesEnabled && flavorModGroup.maxSelectionsAllowed > 1"
        wriFocusOutline
        data-testid="flavor-mod-group-count"
        class="flavor-mod-group-count-label"
        aria-live="polite"
      >{{getSelectedQuantities()}} of {{ flavorModGroup.aggregateQuantity }}
        <span
          *ngIf="flavorModGroup.quantitiesEnabled && flavorModGroup.maxSelectionsAllowed > 1"
          class="flavor-mod-group-flavored-label"
        > Selected</span>
    </span>
    </div>
  </div>
  <div
    [id]="'mod-' + flavorModGroup.id"
    class="collapse {{isModGroupFirstAndRequired? 'show':''}} wri-flavor-mod-group-options"
  >
    <form [formGroup]="flavorSelectionForm">

      <!-- LIMITED_TIME -->
      <ng-container *ngIf="limitedGroup.size>0">
        <wri-flavor-spice-heading
          [heading]="flavorSpiceHeading.LIMITED_TIME"
          [iconColor]="flavorSpiceHeadingColors.LIMITED_TIME"
          type="limitedTime"
          (selectedSpiceHeading)="selectedSpiceHeading()"
        ></wri-flavor-spice-heading>

        <div *ngIf="groupModifiers.size > 0" class="spacing flavor-mod-group-elements-container">
          <ng-container *ngFor="let items of getKeys(limitedGroup)">

            <!--For normal flavors-->
            <div *ngIf="limitedGroup.get(items).length === 1" class="flavor-mod-group-element"
                 wriInteractionStudio="data-chainoptionid"
                 [interactionStudioId]="limitedGroup.get(items)[0].id"
            >
              <div class="flavor-element-border" [ngClass]="isSelected(limitedGroup.get(items)[0].id)? 'selected': ''">
                <wri-flavor-mod-element
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [flavorModGroupElement]="updateFlavorModifierGroupWithCart(limitedGroup.get(items)[0] | modifierToFlavorModifier)"
                  [heatScale]="limitedGroup.get(items)[0] | heatScale"
                  [iconColor]="flavorSpiceHeadingColors.LIMITED_TIME"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>
                </wri-flavor-mod-element>
              </div>
              <hr class="wri-mod-divider" aria-hidden="true"/>
            </div>

            <!--for grouped flavors-->
            <div *ngIf="limitedGroup.get(items).length > 1" class="flavor-mod-group-element">
              <div class="flavor-element">
                <wri-group-flavors
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [numberOfFlavorsInGroup]="limitedGroup.get(items).length"
                  [flavor]="updateGroupedFlavorModifierGroupWithCart(limitedGroup.get(items))"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>
                </wri-group-flavors>
              </div>
            </div>

          </ng-container>
        </div>

      </ng-container>

      <!-- HOT -->
      <ng-container *ngIf="hotGroup.size > 0">
        <wri-flavor-spice-heading
          [heading]="flavorSpiceHeading.HOT"
          [iconColor]="flavorSpiceHeadingColors.HOT"
          type="hot"
          (selectedSpiceHeading)="selectedSpiceHeading()"
        ></wri-flavor-spice-heading>
        <div *ngIf="groupModifiers.size > 0" class="spacing flavor-mod-group-elements-container">
          <ng-container *ngFor="let items of getKeys(hotGroup)">

            <!--For normal flavors-->
            <div *ngIf="hotGroup.get(items).length === 1" class="flavor-mod-group-element"
                 wriInteractionStudio="data-chainoptionid"
                 [interactionStudioId]="hotGroup.get(items)[0].id"
            >
              <div class="flavor-element-border" [ngClass]="isSelected(hotGroup.get(items)[0].id)? 'selected': ''">
                <wri-flavor-mod-element
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [flavorModGroupElement]="updateFlavorModifierGroupWithCart(hotGroup.get(items)[0] | modifierToFlavorModifier)"
                  [heatScale]="hotGroup.get(items)[0] | heatScale"
                  [iconColor]="flavorSpiceHeadingColors.HOT"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>
                </wri-flavor-mod-element>
              </div>
              <hr class="wri-mod-divider" aria-hidden="true"/>
            </div>

            <!--for grouped flavors-->
            <div *ngIf="hotGroup.get(items).length > 1" class="flavor-mod-group-element">
              <div class="flavor-element">
                <wri-group-flavors
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [numberOfFlavorsInGroup]="hotGroup.get(items).length"
                  [flavor]="updateGroupedFlavorModifierGroupWithCart(hotGroup.get(items))"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>
                </wri-group-flavors>
              </div>
            </div>

          </ng-container>
        </div>
      </ng-container>

      <!-- MEDIUM -->
      <ng-container *ngIf="mediumGroup.size > 0">
        <wri-flavor-spice-heading
          [heading]="flavorSpiceHeading.MEDIUM"
          [iconColor]="flavorSpiceHeadingColors.MEDIUM"
          type="medium"
          (selectedSpiceHeading)="selectedSpiceHeading()"
        ></wri-flavor-spice-heading>
        <div *ngIf="groupModifiers.size>0" class="spacing flavor-mod-group-elements-container">
          <ng-container *ngFor="let items of getKeys(mediumGroup)">

            <!--For normal flavors-->
            <div *ngIf="mediumGroup.get(items).length === 1" class="flavor-mod-group-element"
                 wriInteractionStudio="data-chainoptionid"
                 [interactionStudioId]="mediumGroup.get(items)[0].id"
            >
              <div class="flavor-element-border" [ngClass]="isSelected(mediumGroup.get(items)[0].id)? 'selected': ''">
                <wri-flavor-mod-element
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [flavorModGroupElement]="updateFlavorModifierGroupWithCart(mediumGroup.get(items)[0] | modifierToFlavorModifier)"
                  [heatScale]="mediumGroup.get(items)[0] | heatScale"
                  [iconColor]="flavorSpiceHeadingColors.MEDIUM"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>
                </wri-flavor-mod-element>
              </div>
              <hr class="wri-mod-divider" aria-hidden="true"/>
            </div>

            <!--for grouped flavors-->
            <div *ngIf="mediumGroup.get(items).length > 1" class="flavor-mod-group-element">
              <div class="flavor-element">
                <wri-group-flavors
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [numberOfFlavorsInGroup]="mediumGroup.get(items).length"
                  [flavor]="updateGroupedFlavorModifierGroupWithCart(mediumGroup.get(items))"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>
                </wri-group-flavors>
              </div>
            </div>

          </ng-container>
        </div>
      </ng-container>

      <!-- MILD -->
      <ng-container *ngIf="mildGroup.size > 0">
        <wri-flavor-spice-heading
          [heading]="flavorSpiceHeading.MILD"
          [iconColor]="flavorSpiceHeadingColors.MILD"
          type="mild"
          (selectedSpiceHeading)="selectedSpiceHeading()"
        ></wri-flavor-spice-heading>
        <div *ngIf="groupModifiers.size>0" class="spacing flavor-mod-group-elements-container">
          <ng-container *ngFor="let items of getKeys(mildGroup)">
            <!--For normal flavors-->
            <div *ngIf="mildGroup.get(items).length === 1" class="flavor-mod-group-element"
                 wriInteractionStudio="data-chainoptionid"
                 [interactionStudioId]="mildGroup.get(items)[0].id"
            >
              <div class="flavor-element-border" [ngClass]="isSelected(mildGroup.get(items)[0].id)? 'selected': ''">
                <wri-flavor-mod-element
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [flavorModGroupElement]="updateFlavorModifierGroupWithCart(mildGroup.get(items)[0] | modifierToFlavorModifier)"
                  [heatScale]="mildGroup.get(items)[0] | heatScale"
                  [iconColor]="flavorSpiceHeadingColors.MILD"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>

                </wri-flavor-mod-element>
              </div>
              <hr class="wri-mod-divider" aria-hidden="true"/>
            </div>

            <!--for grouped flavors-->
            <div *ngIf="mildGroup.get(items).length > 1" class="flavor-mod-group-element">
              <div class="flavor-element">
                <wri-group-flavors
                  formControlName="modifiers"
                  [selectedModifiers]="flavorSelectionForm.value?.modifiers || []"
                  [maxSelectionsAllowed]="flavorModGroup.maxSelectionsAllowed"
                  [quantitiesEnabled]="flavorModGroup.quantitiesEnabled"
                  [aggregateQuantity]="flavorModGroup.aggregateQuantity"
                  [minModifierQuantity]="flavorModGroup.minModifierQuantity"
                  [maxModifierQuantity]="flavorModGroup.maxModifierQuantity"
                  [numberOfFlavorsInGroup]="mildGroup.get(items).length"
                  [flavor]="updateGroupedFlavorModifierGroupWithCart(mildGroup.get(items))"
                  (flavorQuantitySelections)="handleFlavorQuantitySelections($event)"
                  (resetPDPValidations)=resetPDPValidations()>

                </wri-group-flavors>
              </div>
            </div>

          </ng-container>
        </div>
      </ng-container>

    </form>
    <!-- Flavor Modal -->
    <wri-flavor-modal
      [limited]="mapToArray(limitedGroup)"
      [hot]="mapToArray(hotGroup)"
      [medium]="mapToArray(mediumGroup)"
      [mild]="mapToArray(mildGroup)"
    ></wri-flavor-modal>

  </div>
</div>
