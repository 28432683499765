<form id="paymentSection" [formGroup]="selectedPaymentMethodForm">
  <div class="express-payments">
    <ngx-skeleton-loader
        *ngIf="!expressPaySupported && buttonCount.value > 0"
        [className]="'skelton-styles'"
        count="3"
        appearance="circle"
        animation="progress-dark"
        [theme]="{
        'border-radius': '8px',
        height: '60px',
        'background-color': '#f4f4f2',
        flex: '1'
      }"
    ></ngx-skeleton-loader>
    <div
        *ngIf="expressPayDoneLoading"
        class="methods"
        [ngClass]="{
        'single-button': buttonCount.value === 1,
        'even-buttons': buttonCount.value === 2 || buttonCount.value > 3,
        'odd-buttons':  buttonCount.value  === 3,
        'hide': buttonCount.value === 0
      }"
        id="payment-methods">
      <wri-apple-pay-payment-method
          formControlName="applePay"
          [cart]="cart"
          [expressPayPrerequisitesMet]="expressPayPrerequisitesMet"
          [paymentsSelected]="paymentsSelected"
          [isPaymentMethodSupported]="
          ('applePay'
            | isPaymentMethodSupported
              : storeInfoData?.storeDetails.handoffModes
              : cart?.handoffMode).hasValidPayment
        "
          (expressPay)="handleExpressPay($event)"
          (expressPayClicked)="expressPayClicked.emit('Apple Pay')"
          (paymentSupported)="handleExpressPaymentMethodSupportedChange($event)"
      ></wri-apple-pay-payment-method>

      <wri-paypal-payment-method
          formControlName="payPal"
          [totalPrice]="cart?.total || 0"
          [expressPayPrerequisitesMet]="expressPayPrerequisitesMet"
          [paymentsSelected]="paymentsSelected"
          [isPaymentMethodSupported]="
          ('payPal'
            | isPaymentMethodSupported
              : storeInfoData?.storeDetails.handoffModes
              : cart?.handoffMode).hasValidPayment
        "
          (expressPay)="handleExpressPay($event)"
          (expressPayClicked)="expressPayClicked.emit('PayPal')"
          (paymentSupported)="handleExpressPaymentMethodSupportedChange($event)"
      ></wri-paypal-payment-method>

      <wri-google-pay-payment-method
          formControlName="googlePay"
          [totalPrice]="cart?.total || 0"
          [expressPayPrerequisitesMet]="expressPayPrerequisitesMet"
          [paymentsSelected]="paymentsSelected"
          [isPaymentMethodSupported]="
          ('googlePay'
            | isPaymentMethodSupported
              : storeInfoData?.storeDetails.handoffModes
              : cart?.handoffMode).hasValidPayment
        "
          (expressPay)="handleExpressPay($event)"
          (expressPayClicked)="expressPayClicked.emit('Google Pay')"
          (paymentSupported)="handleExpressPaymentMethodSupportedChange($event)"
      ></wri-google-pay-payment-method>
    </div>
    <div class="no-payment-support"
         *ngIf="expressPayDoneLoading && buttonCount.value === 0">
      Express checkout methods are currently unavailable.
    </div>

    <div class="divider">
      <hr/>
      <span>OR</span>
      <hr/>
    </div>
  </div>

  <div class="no-payment-support"
    *ngIf="userLoggedIn && areGiftCardsVaulted() && !areVaultedGiftCardsViewEnabled">
    Your saved gift cards are currently unavailable.
  </div>

  <div class="no-payment-support"
    *ngIf="userLoggedIn && areCreditCardsVaulted() && !areVaultedCreditCardsViewEnabled">
    Your saved credit/debit cards are currently unavailable.
  </div>

  <!-- For Wingstop Gift cart -->
  <wri-gift-card-payment-method
      formControlName="giftCard"
      [paymentType]="'giftCard'"
      [storeInfoData]="storeInfoData"
      [totalPrice]="cart?.total || 0"
      (statusChanged)="handleGiftCardStatusChange($event)"
      [customerVaultedGiftCards]="vaultedCards?.vaultedGiftCard"
      [userLoggedIn]="userLoggedIn"
      [isGiftCardPaymentSupported]="
      ('giftCard'
        | isPaymentMethodSupported
          : storeInfoData?.storeDetails.handoffModes
          : cart?.handoffMode).hasValidPayment
    "
      [isVaultedGiftCardPaymentSupported]="
    ('vaultedGiftCard'
      | isPaymentMethodSupported
        : storeInfoData?.storeDetails.handoffModes
        : cart?.handoffMode).hasValidPayment
    "
      [vaultedGiftCardView]="areVaultedGiftCardsViewEnabled"
      [vaultedGiftCardVault]="areVaultedGiftCardsVaultEnabled"
      [vaultedGiftCardPay]="areVaultedGiftCardsPayEnabled"
  >
  </wri-gift-card-payment-method>

  <wri-credit-card-payment-method
      formControlName="creditCard"
      [paymentType]="'creditCard'"
      [totalPrice]="
      giftCardPaymentStatus$.value.appliedGiftCardCount > 0
        ? creditCardAmount
        : cart?.total || 0
    "
      (statusChanged)="handleCreditCardStatusChange($event)"
      [customerVaultedCreditCards]="vaultedCards?.vaultedCreditCard"
      [userLoggedIn]="userLoggedIn"
      [isCreditCardPaymentSupported]="
      (('anonymousCredit'
        | isPaymentMethodSupported
          : storeInfoData?.storeDetails.handoffModes
          : cart?.handoffMode).hasValidPayment && !userLoggedIn) ||
      (('registeredCredit'
        | isPaymentMethodSupported
          : storeInfoData?.storeDetails.handoffModes
          : cart?.handoffMode).hasValidPayment &&
          userLoggedIn)
    "
      [isVaultedCreditCardPaymentSupported]="
      ('registeredVaulted'
      | isPaymentMethodSupported
      : storeInfoData?.storeDetails.handoffModes
      : cart?.handoffMode).hasValidPayment
    "
    [vaultedCreditCardView]="areVaultedCreditCardsViewEnabled"
    [vaultedCreditCardVault]="areVaultedCreditCardsVaultEnabled"
    [vaultedCreditCardPay]="areVaultedCreditCardsPayEnabled"
  ></wri-credit-card-payment-method>

  <!-- For Pay In Store -->
  <wri-in-store-payment-method
      formControlName="inStore"
      [paymentType]="'inStore'"
      [totalPrice]="cart?.total || 0"
      [isPayInStorePaymentSupported]="
      (
        'payInStore'
        | isPaymentMethodSupported
          : storeInfoData?.storeDetails.handoffModes
          : cart?.handoffMode
      ).hasValidPayment
    "
      [selectedHandoffModeDetails]="
      (
        'payInStore'
        | isPaymentMethodSupported
          : storeInfoData?.storeDetails.handoffModes
          : cart?.handoffMode
      ).selectedHandoffModeDetails
    "
      [cartSubTotal]="cart?.subtotal"
  >
  </wri-in-store-payment-method>
</form>
