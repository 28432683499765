<div
  class="wri-homepage-product-carousel"
  [class.loading]="loading"
  *ngIf="sliderData?.length"
>
  <div class="product-carousel-title">For you</div>
  <div class="product-carousel-subtitle">Curated by your flavor experts</div>
  <div class="carousel">
    <ngx-slick-carousel
      class="limited-time-offer-slider home-page-carousel"
      [config]="slideConfig"
      (init)="handleInit()"
      #slickModal="slick-carousel"
    >
      <div
        ngxSlickItem
        *ngFor="let data of sliderData; trackBy: trackByItem; let first = first"
        class="slide tabfocus"
      >
        <wri-additional-recommendation-card
          [first]="first"
          [image]="data.item.images.length > 0 ? data.item.images[0].uri : null"
          [imageDescription]="data.item?.description | prepend : 'image-for'"
          [name]="data.item.name"
          [description]="data.item.shortDescription || data.item.description"
          [item]="data.item"
          [locationSlug]="storeInfoState?.storeInfo?.storeDetails?.slug"
          [categorySlug]="data.categorySlug"
          [productSlug]="data.productSlug"
          [itemSlug]="data.itemSlug"
          [showCustomizeLink]="true"
          [recommendationType]="'home-recommendations'"
        ></wri-additional-recommendation-card>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
