import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import { EcommModule } from '../../ecomm/ecomm.module';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common';
import { LocationMenuModule } from '../location-menu';
import { ConnectedGlobalMenuComponent } from './components/connected-global-menu/connected-global-menu.component';
import { ConnectedGlobalProductDetailsComponent } from './components/connected-global-product-details/connected-global-product-details.component';
import { GlobalItemDetailsComponent } from './components/global-item-details/global-item-details.component';

@NgModule({
  imports: [
    CommonModule,
    WriCommonModule,
    EcommModule,
    RouterModule,
    LocationMenuModule
  ],
  declarations: [
    ConnectedGlobalMenuComponent,
    ConnectedGlobalProductDetailsComponent,
    GlobalItemDetailsComponent
  ]
})
export class GlobalMenuModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<GlobalMenuModule> {
    return {
      ngModule: GlobalMenuModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
