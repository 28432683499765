import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FeeModalData } from '../../../common/types/cart.types';

@Component({
  selector: 'wri-tax-and-fees-modal',
  templateUrl: './tax-and-fees-modal.component.html',
  styleUrls: ['./tax-and-fees-modal.component.scss']
})
export class TaxAndFeesModalComponent {
  modalData: FeeModalData | undefined;

  constructor(private modalService: NgbModal) {}

  close(event: Event) {
    event.preventDefault();
    this.modalService.dismissAll();
  }
}
