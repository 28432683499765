import * as t from 'io-ts';

import {
  Optional,
  ecommApiResponse,
  ecommWebSocketResponse,
  optional
} from './common.types';

/* #region CartItemModifier */
export type CartItemModifierDto = {
  modifierId: string;
  name: string;
  quantity: number;
  unitPrice: number;
  status: string;
  statusReason?: Optional<string>;
  modifierGroups: CartItemModGroupDto[];
  sortOrder: number;
};

export type CartItemModifier = Omit<CartItemModifierDto, 'modifierGroups'> & {
  modifierGroups: CartItemModGroup[];
};
/* #endregion */

/* #region CartItemModGroup */
export type CartItemModGroupDto = {
  modifierGroupId: string;
  name: string;
  status: string;
  statusReason?: Optional<string>;
  modifiers: CartItemModifierDto[];
  sortOrder: number;
};

export type CartItemModGroup = Omit<CartItemModGroupDto, 'modifiers'> & {
  modifiers: CartItemModifier[];
};

export const CartItemModGroupDto = t.recursion<CartItemModGroupDto>(
  'CartItemModGroupDto',
  (CartItemModGroupDtoC) =>
    t.intersection(
      [
        t.type(
          {
            modifierGroupId: t.string,
            name: t.string,
            status: t.string,
            sortOrder: t.number,
            modifiers: t.array(
              t.intersection(
                [
                  t.type(
                    {
                      modifierId: t.string,
                      name: t.string,
                      quantity: t.number,
                      unitPrice: t.number,
                      status: t.string,
                      modifierGroups: t.array(CartItemModGroupDtoC),
                      sortOrder: t.number
                    },
                    'CartItemModifierDtoRequired'
                  ),
                  t.partial(
                    {
                      statusReason: optional(t.string)
                    },
                    'CartItemModifierDtoOptional'
                  ),
                  t.record(t.string, t.any)
                ],
                'CartItemModifierDto'
              )
            )
          },
          'CartItemModGroupDtoRequired'
        ),
        t.partial(
          {
            statusReason: optional(t.string)
          },
          'CartItemModGroupDtoOptional'
        ),
        t.record(t.string, t.any)
      ],
      'CartItemModGroupDto'
    )
);
/* #endregion */

/* #region CartItemEstemedTax */
export const CartItemEstemedTaxDto = t.intersection(
  [
    t.type(
      {
        description: t.string,
        amount: t.number
      },
      'CartItemEstemedTaxDtoRequired'
    ),
    t.partial({}, 'CartItemEstemedTaxDtoOptional'),
    t.record(t.string, t.any)
  ],
  'CartItemEstemedTaxDto'
);

export type CartItemEstemedTaxDto = t.TypeOf<typeof CartItemEstemedTaxDto>;
export type CartItemEstemedTax = CartItemEstemedTaxDto;
/* #endregion */

/* #region CartItem */
export const CartItemDto = t.intersection(
  [
    t.type(
      {
        lineItemId: t.string,
        productId: t.string,
        productName: t.string,
        quantity: t.number,
        status: t.string,
        unitPrice: t.number,
        estimatedTaxes: t.array(CartItemEstemedTaxDto),
        estimatedTax: t.number,
        itemSubtotal: t.number,
        modifierGroups: t.array(CartItemModGroupDto)
      },
      'CartItemDtoRequired'
    ),
    t.partial(
      {
        statusReason: optional(t.string)
      },
      'CartItemDtoOptional'
    )
  ],
  'CartItemDto'
);

export type CartItemDto = t.TypeOf<typeof CartItemDto>;
export type CartItem = Omit<
  CartItemDto,
  'estimatedTaxes' | 'modifierGroups'
> & {
  estimatedTaxes: CartItemEstemedTax[];
  modifierGroups: CartItemModGroup[];
};
/* #endregion */

/* #region CartTip */
export const CartTipDto = t.intersection(
  [
    t.type(
      {
        amount: t.number
      },
      'CartTipDtoRequired'
    ),
    t.partial(
      {
        type: optional(t.string),
        value: optional(t.number)
      },
      'CartTipDtoOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartTipDto'
);

export type CartTipDto = t.TypeOf<typeof CartTipDto>;
export type CartTip = CartTipDto;
/* #endregion */

/* #region CartOffer */
export const CartOfferDto = t.intersection(
  [
    t.type(
      {
        id: t.string,
        code: t.string,
        priceAdjustment: t.number,
        customerDescription: t.string,
        status: t.string,
        statusReason: t.string
      },
      'CartOfferDtoRequired'
    ),
    t.partial(
      {
        targetType: optional(t.string)
      },
      'CartOfferDtoOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartOfferDto'
);

export type CartOfferDto = t.TypeOf<typeof CartOfferDto>;
export type CartOffer = CartOfferDto;
/* #endregion */

/* #region CartDeliveryAddress */
export const CartDeliveryAddressDto = t.intersection(
  [
    t.type({}, 'CartDeliveryAddressDtoRequired'),
    t.partial(
      {
        streetAddress: optional(t.string),
        secondaryAddress: optional(t.string),
        locality: optional(t.string),
        region: optional(t.string),
        postalCode: optional(t.string),
        countryCode: optional(t.string),
        longitude: optional(t.number),
        latitude: optional(t.number),
        deliveryInstructions: optional(t.string)
      },
      'CartDeliveryAddressDtoOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartDeliveryAddressDto'
);

export type CartDeliveryAddressDto = t.TypeOf<typeof CartDeliveryAddressDto>;
export type CartDeliveryAddress = CartDeliveryAddressDto;
/* #endregion */

/* #region CartFee */
export const CartFeeDto = t.intersection(
  [
    t.type(
      {
        name: t.string,
        description: t.string,
        amount: t.number,
        tax: t.number,
        category: t.string
      },
      'CartFeeDtoRequired'
    ),
    t.partial({}, 'CartFeeDtoOptional'),
    t.record(t.string, t.any)
  ],
  'CartFeeDto'
);

export type CartFeeDto = t.TypeOf<typeof CartFeeDto>;
export type CartFee = CartFeeDto;
/* #endregion */

/* #region CartReadyTimes */
export const CartReadyTimesDto = t.intersection(
  [
    t.type(
      {
        maxMinutes: t.number,
        minMinutes: t.number
      },
      'CartReadyTimesDtoRequired'
    ),
    t.partial({}, 'CartReadyTimesDtoOptional'),
    t.record(t.string, t.any)
  ],
  'CartReadyTimesDto'
);

export type CartReadyTimesDto = t.TypeOf<typeof CartReadyTimesDto>;
export type CartReadyTimes = CartReadyTimesDto;
/* #endregion */

/* #region CartFulfillmentReadyTimesDto */
export const CartFulfillmentReadyTimesDto = t.intersection(
  [
    t.type(
      {
        driveTimeMinutes: t.number,
        maxMinutes: t.number,
        minMinutes: t.number,
        prepTimeMinutes: t.number
      },
      'CartFulfillmentReadyTimesDtoRequired'
    ),
    t.partial({}, 'CartFulfillmentReadyTimesDtoOptional'),
    t.record(t.string, t.any)
  ],
  'CartFulfillmentReadyTimesDto'
);

export type CartFulfillmentReadyTimesDto = t.TypeOf<
  typeof CartFulfillmentReadyTimesDto
>;
export type CartFulfillmentReadyTimes = CartFulfillmentReadyTimesDto;
/* #endregion */

/* #region Fulfillment */
export const FulfillmentDto = t.intersection(
  [
    t.type(
      {
        status: t.string
      },
      'FulfillmentDtoRequired'
    ),
    t.partial(
      {
        statusReason: optional(t.string)
      },
      'FulfillmentDtoOptional'
    ),
    t.record(t.string, t.any)
  ],
  'FulfillmentDto'
);

export type FulfillmentDto = t.TypeOf<typeof FulfillmentDto>;
export type Fulfillment = FulfillmentDto;
/* #endregion */

/* #region Cart */
export const CartDto = t.intersection(
  [
    t.type(
      {
        cartId: t.string,
        locationId: t.string,
        status: t.string,
        handoffMode: t.string,
        channel: t.string,
        items: t.array(CartItemDto),
        priceUpdatedAfterFinalization: t.boolean,
        fees: t.array(CartFeeDto),
        asap: t.boolean,
        prepTimeMinutes: t.number,
        subtotal: t.number,
        totalTax: t.number,
        total: t.number,
        readyTimes: CartReadyTimesDto
      },
      'CartDtoRequired'
    ),
    t.partial(
      {
        statusReason: optional(t.string),
        estimatedDeliveryTime: optional(t.string),
        deliveryAddress: optional(CartDeliveryAddressDto),
        fulfillmentTime: optional(t.string),
        fulfillment: optional(FulfillmentDto),
        offer: optional(CartOfferDto),
        driveTimeMinutes: optional(t.number),
        tip: optional(CartTipDto)
      },
      'CartDtoOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartDto'
);

export type CartDto = t.TypeOf<typeof CartDto>;
export type Cart = CartDto & {
  items: CartItem[];
  fees: CartFee[];
  readyTimes: CartReadyTimes;
  deliveryAddress?: Optional<CartDeliveryAddress>;
  offer?: Optional<CartOffer>;
  tip?: Optional<CartTip>;
};
/* #endregion */

/* #region CartResponse */
export const CartResponse = ecommApiResponse(CartDto);

export type CartResponse = t.TypeOf<typeof CartResponse>;
/* #endregion */

/* #region CartCreationRequest */
export const CartCreationRequest = t.intersection(
  [
    t.type(
      {
        locationId: t.string,
        handoffMode: t.string
      },
      'CartCreationRequestRequired'
    ),
    t.partial(
      {
        deliveryAddress: optional(CartDeliveryAddressDto)
      },
      'CartCreationRequestOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartCreationRequest'
);

export type CartCreationRequest = t.TypeOf<typeof CartCreationRequest>;
/* #endregion */

/* #region CartAddItemRequestModifier */
export type CartAddItemRequestModifier = {
  modifierId: string;
  quantity: number;
  modifierGroups?: Optional<CartAddItemRequestModGroup[]>;
};
/* #endregion */

/* #region CartAddItemRequestModGroup */
export type CartAddItemRequestModGroup = {
  modifierGroupId: string;
  modifiers?: Optional<CartAddItemRequestModifier[]>;
};

export const CartAddItemRequestModGroup =
  t.recursion<CartAddItemRequestModGroup>(
    'CartAddItemRequestModGroup',
    (CartAddItemRequestModGroupC) =>
      t.intersection([
        t.type(
          {
            modifierGroupId: t.string
          },
          'CartAddItemRequestModGroupRequired'
        ),
        t.partial(
          {
            modifiers: optional(
              t.array(
                t.intersection([
                  t.type({
                    modifierId: t.string,
                    quantity: t.number
                  }),
                  t.partial({
                    price: optional(t.number),
                    modifierGroups: optional(
                      t.array(CartAddItemRequestModGroupC)
                    )
                  })
                ])
              )
            )
          },
          'CartAddItemRequestModGroupOptional'
        ),
        t.record(t.string, t.any)
      ])
  );
/* #endregion */

/* #region CartAddItemRequestProduct */
export const CartAddItemRequestProduct = t.intersection(
  [
    t.type(
      {
        itemId: t.string
      },
      'CartAddItemRequestProductRequired'
    ),
    t.partial(
      {
        modifierGroups: optional(t.array(CartAddItemRequestModGroup)),
        price: optional(t.number)
      },
      'CartAddItemRequestProductOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartAddItemRequestProduct'
);

export type CartAddItemRequestProduct = t.TypeOf<
  typeof CartAddItemRequestProduct
>;
/* #endregion */

/* #region CartAddItemRequest */
export const CartAddItemRequest = t.intersection(
  [
    t.type(
      {
        quantity: t.number,
        product: CartAddItemRequestProduct
      },
      'CartAddItemRequestRequired'
    ),
    t.partial(
      {
        deliveryAddrss: optional(CartDeliveryAddressDto)
      },
      'CartAddItemRequestOptional'
    ),
    t.record(t.string, t.any)
  ],
  'CartAddItemRequest'
);

export type CartAddItemRequest = t.TypeOf<typeof CartAddItemRequest>;
/* #endregion */

/* #region CartUpdateItemRequest */
export const CartUpdateItemRequest = CartAddItemRequest;
export type CartUpdateItemRequest = t.TypeOf<typeof CartUpdateItemRequest>;
/* #endregion */

/* #region CartUpdateItemQuantityRequest */
export const CartUpdateItemQuantityRequest = t.intersection(
  [
    t.type(
      {
        quantity: t.number
      },
      'CartUpdateItemQuantityRequestRequired'
    ),
    t.partial({}, 'CartUpdateItemQuantityRequestOptional'),
    t.record(t.string, t.any)
  ],
  'CartUpdateItemQuantityRequest'
);

export type CartUpdateItemQuantityRequest = t.TypeOf<
  typeof CartUpdateItemQuantityRequest
>;
/* #endregion */

/* #region CartApplyOfferCodeRequest */
export const CartApplyOfferCodeRequest = t.intersection(
  [
    t.type(
      {
        offerCode: t.string
      },
      'CartApplyOfferCodeRequestRequired'
    ),
    t.partial({}, 'CartApplyOfferCodeRequestOptional'),
    t.record(t.string, t.any)
  ],
  'CartApplyOfferCodeRequest'
);

export type CartApplyOfferCodeRequest = t.TypeOf<
  typeof CartApplyOfferCodeRequest
>;
/* #endregion */

/* #region CartUpdateFulfillmentTimeRequest */
export const CartUpdateFulfillmentTimeRequest = t.union(
  [
    t.intersection(
      [
        t.type(
          {
            isAsap: t.literal(false)
          },
          'CartUpdateFulfillmentTimeFutureRequestRequired'
        ),
        t.partial(
          {
            fulfillmentTime: optional(t.string)
          },
          'CartUpdateFulfillmentTimeFutureRequestOptional'
        ),
        t.record(t.string, t.any)
      ],
      'CartUpdateFulfillmentTimeFutureRequest'
    ),
    t.intersection(
      [
        t.type(
          {
            isAsap: t.literal(true)
          },
          'CartUpdateFulfillmentTimeAsapRequestRequired'
        ),
        t.partial({}, 'CartUpdateFulfillmentTimeAsapRequestOptional'),
        t.record(t.string, t.any)
      ],
      'CartUpdateFulfillmentTimeAsapRequest'
    )
  ],
  'CartUpdateFulfillmentTimeRequest'
);

export type CartUpdateFulfillmentTimeRequest = t.TypeOf<
  typeof CartUpdateFulfillmentTimeRequest
>;
/* #endregion */

/* #region CartUpdateTipRequest */
export const CartUpdateCustomTipRequest = t.intersection(
  [
    t.type(
      {
        amount: t.number
      },
      'CartUpdateCustomTipRequest'
    ),
    t.partial({}, 'CartUpdateCustomTipRequestOptional'),
    t.record(t.string, t.any)
  ],
  ''
);
export const CartUpdatePresetRequest = t.intersection(
  [
    t.type(
      {
        type: t.union([t.literal('percent'), t.literal('dollar')]),
        value: t.number
      },
      'CartUpdateTipRequestRequired'
    ),
    t.partial({}, 'CartUpdateTipRequestOptional'),
    t.record(t.string, t.any)
  ],
  'CartUpdateTipRequest'
);

export const TipRequest = t.union([
  CartUpdatePresetRequest,
  CartUpdateCustomTipRequest
]);

export type CartUpdateTipRequest = t.TypeOf<typeof TipRequest>;
/* #endregion */

/* #region CartFinalizeRequest */
export const CartFinalizeRequest = t.intersection(
  [
    t.type(
      {
        action: t.literal('finalize-cart'),
        request: t.intersection(
          [
            t.type(
              {
                cartId: t.string,
                recaptchaToken: t.string,
                acceptLanguage: t.string // 'en-US'
              },
              'CartFinalizeRequestBodyRequired'
            ),
            t.partial(
              {
                accessToken: optional(t.string),
                addRoundUpFee: optional(t.boolean)
              },
              'CartFinalizeRequestBodyOptional'
            ),
            t.record(t.string, t.any)
          ],
          'CartFinalizeRequestBody'
        )
      },
      'CartFinalizeRequestRequired'
    ),
    t.partial({}, 'CartFinalizeRequestOptional'),
    t.record(t.string, t.any)
  ],
  'CartFinalizeRequest'
);

export type CartFinalizeRequest = t.TypeOf<typeof CartFinalizeRequest>;
/* #endregion */

/* #region CartFinalizeAction */
export const CartFinalizeActionC = t.union([
  t.literal('cartFinalizingPending'),
  t.literal('cartFinalizingFailed'),
  t.literal('cartFinalized'),
  t.literal('cartManuallyFetched')
]);

export type CartFinalizeAction = t.TypeOf<typeof CartFinalizeActionC>;

export const CartFinalizeAction: Record<
  CartFinalizeAction,
  CartFinalizeAction
> = {
  cartFinalized: 'cartFinalized',
  cartFinalizingFailed: 'cartFinalizingFailed',
  cartFinalizingPending: 'cartFinalizingPending',
  cartManuallyFetched: 'cartManuallyFetched'
};
/* #endregion */

/* #region CartFinalizeResponse */
export const CartFinalizeRespone = ecommWebSocketResponse(
  CartFinalizeActionC,
  CartDto
);

export type CartFinalizeRespone = t.TypeOf<typeof CartFinalizeRespone>;
/* #endregion */
