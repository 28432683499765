import * as t from 'io-ts';

import { ecommApiResponse, Optional, optional } from './common.types';

/* #region GeocodePlaceAddress */
const GeocodePlaceAddressDto = t.intersection(
  [
    t.type(
      {
        streetAddress: t.string,
        locality: t.string,
        region: t.string,
        postalCode: t.string,
        countryCode: t.string
      },
      'GeocodePlaceAddressDtoRequired'
    ),
    t.partial({}, 'GeocodePlaceAddressDtoOptional')
  ],
  'GeocodePlaceAddressDto'
);

export type GeocodePlaceAddressDto = t.TypeOf<typeof GeocodePlaceAddressDto>;
export type GeocodePlaceAddress = GeocodePlaceAddressDto;
/* #endregion */

/* #region PaymentValidateApplePayMerchantRequest */
export const GetDeliveryLocationsByGeocodeRequest = t.intersection(
  [
    t.type(
      {
        latitude: t.number,
        longitude: t.number,
        radius: t.number,
        radiusUnits: t.string,
        deliveryAddress: GeocodePlaceAddressDto
      },
      'GetDeliveryLocationsByGeocodeRequestOptional'
    ),
    t.partial({}, 'GetDeliveryLocationsByGeocodeRequestRequired')
  ],
  'GetDeliveryLocationsByGeocodeRequest'
);

export type GetDeliveryLocationsByGeocodeRequest = t.TypeOf<
  typeof GetDeliveryLocationsByGeocodeRequest
>;

/* #endregion */

/* #region GeocodePlaces */
export const GeocodePlacesDto = t.intersection([
  t.type(
    {
      places: t.array(
        t.intersection([
          t.type(
            {
              id: t.string,
              name: t.string,
              latitude: t.number,
              longitude: t.number
            },
            'PlacesDtoRequired'
          ),
          t.partial(
            {
              address: optional(GeocodePlaceAddressDto)
            },
            'PlacesDtoOptional'
          )
        ])
      )
    },
    'GeocodePlacesDtoRequired'
  ),
  t.partial({}, 'GeocodePlacesDtoOptional')
]);
export type GeocodePlacesDto = t.TypeOf<typeof GeocodePlacesDto>;
export type GeocodePlaces = GeocodePlacesDto;
/* #endregion */

/* #region GeocodePlaceResponse */
export const GeocodePlacesResponse = ecommApiResponse(GeocodePlacesDto);
export type GeocodePlacesResponse = t.TypeOf<typeof GeocodePlacesResponse>;
/* #endregion */

/* #region LocationNormalCalendarRange */
export const LocationNormalCalendarRangeDto = t.intersection([
  t.type({
    startTime: t.string,
    endTime: t.string,
    startDay: t.string,
    endDay: t.string
  }),
  t.partial({})
]);

export type LocationNormalCalendarRangeDto = t.TypeOf<
  typeof LocationNormalCalendarRangeDto
>;
export type LocationNormalCalendarRange = LocationNormalCalendarRangeDto;
/* #endregion */

/* #region LocationOverrideCalendarRange */
export const LocationOverrideCalendarRangeDto = t.intersection([
  t.type({
    startTime: t.string,
    endTime: t.string,
    startDate: t.string,
    endDate: t.string,
    isClosed: t.boolean
  }),
  t.partial({
    reason: optional(t.string)
  })
]);

export type LocationOverrideCalendarRangeDto = t.TypeOf<
  typeof LocationOverrideCalendarRangeDto
>;
export type LocationOverrideCalendarRange = LocationOverrideCalendarRangeDto;
/* #endregion */

/* #region LocationHandoffModeCalendar */
export const LocationHandoffModeCalendarDto = t.intersection([
  t.type({
    handoffMode: t.string,
    hours: t.array(LocationNormalCalendarRangeDto)
  }),
  t.partial({})
]);

export type LocationHandoffModeCalendarDto = t.TypeOf<
  typeof LocationHandoffModeCalendarDto
>;
export type LocationHandoffModeCalendar = Omit<
  LocationHandoffModeCalendarDto,
  'hours'
> & {
  hours: LocationNormalCalendarRange[];
};
/* #endregion */

/* #region LocationAmenity */
export const LocationAmenityDto = t.intersection([
  t.type({}),
  t.partial({ name: optional(t.string), description: optional(t.string) })
]);

export type LocationAmenityDto = t.TypeOf<typeof LocationAmenityDto>;
export type LocationAmenity = LocationAmenityDto;
/* #endregion */

/* #region LocationDisclaimer */
export const LocationDisclaimerDto = t.intersection([
  t.type({
    id: t.string,
    name: t.string,
    message: t.string
  }),
  t.partial({})
]);

export type LocationDisclaimerDto = t.TypeOf<typeof LocationDisclaimerDto>;
export type LocationDisclaimer = LocationDisclaimerDto;
/* #endregion */

export type LocationStatus = 'Active' | 'Inactive' | 'Lab' | 'Test' | 'Legacy';

export const LocationStatus: Record<LocationStatus, LocationStatus> = {
  Active: 'Active',
  Inactive: 'Inactive',
  Lab: 'Lab',
  Test: 'Test',
  Legacy: 'Legacy'
} as const;

/* #region  HighSodiumDisplay */
const HighSodiumDisplayDto = t.intersection(
  [
    t.type({}, 'HighSodiumDisplayDtoRequired'),
    t.partial(
      {
        imageUrl: optional(t.string),
        altText: optional(t.string),
        label: optional(t.string)
      },
      'HighSodiumDisplayDtoOptional'
    )
  ],
  'HighSodiumDisplay'
);

export type HighSodiumDisplayDto = t.TypeOf<typeof HighSodiumDisplayDto>;
export type HighSodiumDisplay = HighSodiumDisplayDto;

/* #endregion  HighSodiumDisplay */

/* #region Location */
const LocationDto = t.intersection([
  t.type({
    id: t.string,
    name: t.string,
    number: t.string,
    legacyStoreNumber: t.number,
    locality: t.string,
    region: t.string,
    countryCode: t.string,
    postalCode: t.string,
    latitude: t.number,
    longitude: t.number,
    phoneNumber: t.string,
    currencyType: t.string,
    estimatedTaxRate: t.number,
    distance: t.number,
    distanceUnit: t.union([t.null, t.string]),
    timeZone: t.string,
    handoffModes: t.array(t.string),
    locationStatus: t.string,
    slug: t.string,
    storeHandoffModeHours: t.array(LocationHandoffModeCalendarDto),
    storeHours: t.array(LocationNormalCalendarRangeDto),
    businessDay: t.string
  }),
  t.partial({
    description: optional(t.string),
    secondaryAddress: optional(t.string),
    streetAddress: optional(t.string),
    message: optional(t.string),
    payInStoreInstructions: optional(t.string),
    prepaidOrdersInstructions: optional(t.string),
    openDate: optional(t.string),
    careerUrl: optional(t.string),
    amenities: optional(t.array(LocationAmenityDto)),
    disclaimers: optional(t.array(LocationDisclaimerDto)),
    newlyOpenedStatus: optional(t.boolean),
    comingSoonStatus: optional(t.boolean),
    businessHours: optional(t.any),
    channelHandoffModeHours: optional(t.any),
    unavailableCarryoutOverrideHoursActive: optional(t.boolean),
    unavailableDeliveryOverrideHoursActive: optional(t.boolean),
    unavailableCarryoutOverrideReasonDescription: optional(t.string),
    unavailableDeliveryOverrideReasonDescription: optional(t.string),
    unavailableOverrideReasonDescription: optional(t.string),
    highSodiumDisplay: optional(HighSodiumDisplayDto)
  })
]);

export type LocationDto = t.TypeOf<typeof LocationDto>;
export type Location = Omit<
  LocationDto,
  | 'storeHandoffModeHours'
  | 'storeHours'
  | 'amenities'
  | 'disclaimers'
  | 'locationStatus'
> & {
  storeHandoffModeHours: LocationHandoffModeCalendar[];
  storeHours: LocationNormalCalendarRange[];
  locationStatus?: Optional<string | LocationStatus>;
  amenities?: Optional<LocationAmenity[]>;
  disclaimers?: Optional<LocationDisclaimer[]>;
  streetAddress1?: Optional<string>;
  streetAddress2?: Optional<string>;
  city?: Optional<string>;
  state?: Optional<string>;
  country?: Optional<string>;
};
/* #endregion */

/* #region LocalitySummary */
export const LocalitySummaryDto = t.intersection([
  t.type({
    name: t.string,
    slug: t.string,
    count: t.number
  }),
  t.partial({
    ids: t.array(t.string)
  })
]);

export type LocalitySummaryDto = t.TypeOf<typeof LocalitySummaryDto>;
export type LocalitySummary = LocalitySummaryDto;
/* #endregion */

/* #region RegionSummary */
export const RegionSummaryDto = t.intersection([
  t.type({
    name: t.string,
    slug: t.string,
    count: t.number,
    cities: t.array(LocalitySummaryDto)
  }),
  t.partial({
    abbreviation: t.string
  })
]);

export type RegionSummaryDto = t.TypeOf<typeof RegionSummaryDto>;
export type RegionSummary = Omit<RegionSummaryDto, 'cities'> & {
  cities: LocalitySummary[];
};
/* #endregion */

/* #region LocationResponse */
export const LocationResponseDto = ecommApiResponse(
  t.intersection([
    t.type({
      location: LocationDto
    }),
    t.partial({})
  ])
);

export type LocationResponseDto = t.TypeOf<typeof LocationResponseDto>;
export type LocationResponse = Omit<LocationResponseDto, 'data'> & {
  data?: Optional<{
    location: Location;
  }>;
};
export type LocationResponseData = Exclude<
  LocationResponse['data'],
  null | undefined
>;
/* #endregion */

/* #region LocationsResponse */
export const LocationsResponseDto = ecommApiResponse(
  t.intersection([
    t.type({
      locations: t.array(LocationDto)
    }),
    t.partial({})
  ])
);

export type LocationsResponseDto = t.TypeOf<typeof LocationsResponseDto>;
export type LocationsResponse = Omit<LocationsResponseDto, 'data'> & {
  data?: Optional<{
    locations: Location[];
  }>;
};
export type LocationsResponseData = Exclude<
  LocationsResponse['data'],
  null | undefined
>;
/* #endregion */

/* #region RegionResponse */
export const RegionResponseDto = ecommApiResponse(t.array(RegionSummaryDto));

export type RegionResponseDto = t.TypeOf<typeof RegionResponseDto>;
export type RegionResponse = Omit<RegionResponseDto, 'data'> & {
  data?: Optional<RegionSummary[]>;
};
/* #endregion */

export type RadiusUnits = 'km' | 'mi';

export type SearchByIdsRequest = {
  locationIds: string[];
};

export type SearchByIdRequest = {
  locationId: string;
};

export type SearchBySlugRequest = {
  locationSlug: string;
};

export type SearchByRegionRequest = {
  region: string;
};

export type SearchByLocalityRequest = {
  locality: string;
  region: string;
};

export type SearchByCarryoutRequest = {
  latitude: number;
  longitude: number;
  radius: number;
  radiusUnits?: RadiusUnits;
};

export type SearchByDeliveryAddressRequest = {
  deliveryAddress: {
    countryCode: string;
    postalCode: string;
    region: string;
    locality: string;
    streetAddress: string;
  };
  latitude: number;
  longitude: number;
  radius?: number;
  radiusUnits?: RadiusUnits;
};

/* #region DeliveryLocationResponse */
export const DeliveryLocationResponseDto = ecommApiResponse(
  t.intersection([
    t.type({
      locationId: t.string,
      locationStatus: t.string,
      slug: t.string,
    }),
    t.partial({})
  ])
);

export type DeliveryLocationResponseDto = t.TypeOf<typeof DeliveryLocationResponseDto>;
export type DeliveryLocationResponse = Omit<DeliveryLocationResponseDto, 'data'> & {
  data?: Optional<{
    locationId: string,
    locationStatus: string,
    slug: string,
  }>;
};
export type DeliveryLocationResponseData = Exclude<
  DeliveryLocationResponse['data'],
  null | undefined
>;
/* #endregion */
