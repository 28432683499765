import { FactoryProvider } from '@angular/core';
import {
  Action,
  ActionCreator,
  ActionReducer,
  META_REDUCERS
} from '@ngrx/store';
import { ActionType } from '@ngrx/store/src/models';
import * as _ from 'lodash';

import { DOCUMENT } from '../../providers/document/document.provider';
import { WINDOW } from '../../providers/window/window.provider';
import { AuthFeature } from '../features/auth';
import { CartFeature } from '../features/cart';
import { CustomerFeature } from '../features/customer';
import { OrderFeature } from '../features/order';
import { StoreInfoFeature } from '../features/store-info';
import { FeaturesState } from '../types/features-state';
import { providedOnceMiddleware } from './provided-once-middleware';
import { Cart } from '../../types/cart.types';

// generic types
type WSActionHandlerArgs<T extends string, A extends ActionCreator<T>, S> = {
  action: ActionType<A>;
  state: S;
};

type WSActionHandler<T extends string, A extends ActionCreator<T>, S> = (
  args: WSActionHandlerArgs<T, A, S>
) => void;

type WSActionHandlerCreatorArgs = {
  window: Window;
  document: Document;
};

type WSActionHandlerCreator<T extends string, A extends ActionCreator<T>, S> = (
  args: WSActionHandlerCreatorArgs
) => WSActionHandler<T, A, S>;

// set for setting data on window.ws
type SetHandler<T extends string, A extends ActionCreator<T>, S> = (
  args: WSActionHandlerArgs<T, A, S>
) => {
  path: string[];
  value: unknown;
};

const set =
  <T extends string, A extends ActionCreator<T>, S>(
    handler: SetHandler<T, A, S>
  ): WSActionHandlerCreator<T, A, S> =>
  ({ window }) =>
  (args) => {
    const { path, value } = handler(args);
    _.set(window, ['ws', ...path], value);
  };

// emit for converting to CustomEvents
type EmitHandler<T extends string, A extends ActionCreator<T>, S> = (
  args: WSActionHandlerArgs<T, A, S>
) => {
  type: string;
  value: unknown;
} | null;

const emit =
  <T extends string, A extends ActionCreator<T>, S>(
    handler: EmitHandler<T, A, S>
  ): WSActionHandlerCreator<T, A, S> =>
  ({ document }) =>
  (args) => {
    const eventData = handler(args);
    if (!eventData) {
      return;
    }

    document.dispatchEvent(
      new CustomEvent(eventData.type, {
        detail: eventData.value,
        bubbles: true
      })
    );
  };

// composing it all together
const on =
  <T extends string, A extends ActionCreator<T>>(
    ac: A,
    handlerCreators: WSActionHandlerCreator<T, A, FeaturesState>[] = []
  ) =>
  (deps: WSActionHandlerCreatorArgs) =>
  (input: WSActionHandlerArgs<T, A, FeaturesState>) => {
    if (ac.type === input.action.type) {
      handlerCreators
        .map((handlerCreator) => handlerCreator(deps))
        .forEach((handler) => handler(input));
    }
  };

const createListener =
  (...ons: ReturnType<typeof on>[]) =>
  (deps: WSActionHandlerCreatorArgs, state: FeaturesState, action: Action) =>
    ons.forEach((o) => o(deps)({ state, action }));

export const updateWS = createListener(
  on(AuthFeature.actions.loginEvent, [
    emit(({ action }) => ({
      type: 'wsLogin',
      value: { email: action.email }
    }))
  ]),
  on(AuthFeature.actions.logoutEvent, [
    emit(({ action }) => ({
      type: 'wsLogout',
      value: { email: action.email }
    })),
    set(() => ({ path: ['registeredUser'], value: null }))
  ]),
  on(CustomerFeature.actions.setState, [
    set(({ action }) => ({
      path: ['registeredUser'],
      value: action.customer ?? null
    }))
  ]),
  on(StoreInfoFeature.actions.setState, [
    set(({ state }) => ({
      path: ['location', 'id'],
      value: state.storeInfo?.storeInfo?.storeDetails?.id ?? ''
    })),
    /* eslint-disable @typescript-eslint/no-explicit-any */
    set(({ state }) => ({
      path: ['location', 'calendar'],
      value: state.storeInfo?.storeInfo?.channelHandoffModeHours ?? {}
    })),

    set(({ state }) => ({
      path: ['location', 'storeDetails'],
      value: state.storeInfo?.storeInfo?.storeDetails ?? {}
    })),
    set(({ state }) => ({
      path: ['location', 'menu'],
      value: state.storeInfo?.storeInfo?.categories ?? {}
    })),
    set(({ state }) => ({
      path: ['location', 'item'],
      value: state.storeInfo?.selectedItem ?? {}
    }))
  ]),
  on(CartFeature.actions.setState, [
    set(({ state }) => ({
      path: ['cart', 'products'],
      value: state.cart?.cart?.items ?? []
    })),
    set(({ state }) => ({
      path: ['cart', 'appliedTip'],
      value: state.cart?.cart?.tip ?? {}
    }))
  ]),
  on(CartFeature.actions.tipSelected, [
    emit(({ action }) =>
      action.tip
        ? {
            type: 'wsTipSelected',
            value: action.tip
          }
        : null
    )
  ]),
  on(CartFeature.actions.itemAddedToCart, [
    emit(({ action }) =>
      action.lineItem
        ? {
            type: 'wsAddToCart',
            value: action.lineItem
          }
        : null
    )
  ]),
  on(CartFeature.actions.setAddToCartOnWs, [
    set(({ action }) => ({
      path: ['addToCart'],
      value: action.item
    }))
  ]),
  on(OrderFeature.actions.setState, [
    set(() => ({
      path: ['cart'],
      value: {}
    })),
    set(() => ({
      path: ['addToCart'],
      value: {}
    })),
    set(() => ({
      path: ['checkout'],
      value: {}
    })),
    set(({ state }) => ({
      path: ['checkout', 'products'],
      value: state.order?.lastSessionOrder?.cart?.items ?? []
    })),
    set(({ state }) => ({
      path: ['checkout', 'orderId'],
      value: state.order?.lastSessionOrder?.id ?? ''
    })),
    set(({ state }) => ({
      path: ['checkout', 'total'],
      value: state.order?.lastSessionOrder?.total ?? 0.0
    })),
    set(({ state }) => ({
      path: ['checkout', 'roundup'],
      value: isRoundupAvailable(state.order?.lastSessionOrder?.cart)
    }))
  ])
);

const isRoundupAvailable = (cart: Cart | undefined) => {
  if (cart && cart.fees?.find((f) => f.category === 'donation')) {
    return true;
  }
  return false;
}

export const windowWSUpdater = (window: Window, document: Document) =>
  providedOnceMiddleware(
    'windowWSUpdaterApplied',
    (reducer: ActionReducer<FeaturesState, Action>) =>
      (state: FeaturesState, action: Action) => {
        const nextState = reducer(state, action);
        updateWS({ window, document }, nextState, action);
        return nextState;
      }
  );

export class WindowWSUpdaterMiddlewareProvider {
  static get(): FactoryProvider {
    return {
      provide: META_REDUCERS,
      deps: [WINDOW, DOCUMENT],
      useFactory: windowWSUpdater,
      multi: true
    };
  }
}
