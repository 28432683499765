<div
  wriInteractionStudio="data-chainproductid"
  [interactionStudioId]="itemGroup.id"
  [ngClass]="
    layout === 'condensed'
      ? 'wri-product-item-group-container-condensed'
      : 'wri-product-item-group-container'
  "
>
  <a
    wriFocusOutline
    class="wri-product-item-group-img-container"
    data-testid="group-image-click-event"
    tabindex="0"
    (click)="openModal()"
  >
    <div [ngClass]="'wri-product-img'">
      <img
        height="100%"
        srcset="{{ itemGroup.images[0]?.uri | imageResolution: 'srcset':layout === 'condensed'?'condensed-menu': 'standard-menu': '/assets/images/logo-green-product.png'}}"
        src="{{ itemGroup.images[0]?.uri | imageResolution: 'srcset':layout === 'condensed'?'condensed-menu': 'standard-menu': '/assets/images/logo-green-product.png'}}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        alt="{{
          itemGroup.images.length > 0
            ? itemGroup.shortDescription || itemGroup.description
            : 'placeholder-image'
        }}"
      />
    </div>
  </a>

  <a
    wriFocusOutline
    class="wri-product-item-group-name"
    data-testid="wri-product-item-group-name"
    tabindex="0"
    (click)="openModal()"
  >
    {{ itemGroup.name }}
  </a>

  <div
    class="wri-product-item-group-description"
    data-testid="wri-product-item-group-description"
  >
    {{ itemGroup.shortDescription || itemGroup.description }}
  </div>

  <ng-template [ngTemplateOutlet]="productItems"></ng-template>

  <ng-template #productItems>
    <div
      class="wri-product-item-group-items"
      data-testid="wri-product-item-group-items"
    >
      <ng-container *ngFor="let item of filterUnavailableItems() | sort:'asc':'sortOrder'">
        <div
          class="wri-product-item-group-items-metadata"
          wriInteractionStudio="data-chainoptionid"
          [interactionStudioId]="item?.id"
        >
          <a
            wriFocusOutline
            class="wri-product-item-group-item"
            data-testid="wri-product-item-group-item"
            tabindex="0"
            (click)="getProductItemDetailsUrl(item)"
            [ngClass]="{
              'wri-product-item-group-disable-anchor': item?.outOfStock,
              'wri-product-item-group-disable-item': item?.outOfStock,
            }"
            aria-live="polite"
          >
            <span
              class="wri-product-item-group-item-name"
              data-testid="wri-product-item-group-item-name"
              [ngClass]="{
                'wri-product-item-group-disable-item': item?.outOfStock,
              }"
            >
              {{
              item.shortName
                ? (item.shortName)
                : (item.name)
              }}
            </span>
            <span
              [ngClass]="{
                'wri-product-item-group-disable-item-price': item?.outOfStock,
              }"
              class="wri-product-item-group-item-price"
              data-testid="wri-product-item-group-item-price"
            >
              <span
                data-testid="wri-product-item-group-item-price1"
                *ngIf="!isGlobalMenu && item.price !== 0.0"
              >
                {{ item.price | currency }}
              </span>
            </span>
            <wri-icon
              *ngIf="!item?.outOfStock"
              icon="wri-chevron-right"
              class="icon-chevron"
            ></wri-icon>
          </a>

          <ng-container *ngIf="item?.outOfStock; else metadata">
            <wri-unavailable-item></wri-unavailable-item>
          </ng-container>

          <ng-template #metadata>
            <div
              class="wri-product-item-group-calories"
            >
              <span  *ngIf="!(item?.minCalories === null && item?.maxCalories === null)"> {{ returnCalorieRange(item) }} </span>
              <span
                data-testid="high-sodium"
                class="high-sodium"
                *ngIf="item?.highSodium && !highSodiumDisplay?.label"
              >
                <ng-container *ngTemplateOutlet="highSodiumDisplayTemplate"></ng-container>
              </span>

              <span
                wriFocusOutline
                data-testid="high-sodium-with-label"
                class="high-sodium-with-label"
                aria-live="polite"
              >
                <ng-container
                  *ngIf="item?.highSodium && highSodiumDisplay?.label then highSodiumDisplayTemplate"></ng-container>
              </span>

            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #commonModal let-close="close" let-dismiss="dismiss">
    <wri-common-modal [close]="close" [dismiss]="dismiss">
      <div class="wri-modal__wrapper">
        <div class="wri-modal__title">choose an option</div>
        <div class="wri-modal__description wri-product-item-group-choose-option">
          <a
            wriFocusOutline
            class="wri-product-item-group-name"
            data-testid="wri-product-item-group-name"
            tabindex="0"
          >
            {{ itemGroup.name }}
          </a>
          <hr class="wri-product-item-group-divider" aria-hidden="true"/>
          <ng-template [ngTemplateOutlet]="productItems"></ng-template>
        </div>
      </div>
    </wri-common-modal>
  </ng-template>
</div>
<ng-template #highSodiumDisplayTemplate>
  <img
    data-testid="high-sodium-display-img"
    class="high-sodium-display-img"
    aria-live="polite"
    alt="{{ highSodiumDisplay?.altText || 'Sodium warning icon' }}"
    src="{{ highSodiumDisplay?.imageUrl || 'https://cdn.bfldr.com/NDQASMJ1/as/cvqcrpvtk5b3ptmzp99p8knz/wri-sodium?auto=webp&format=png' }}"
    onerror="this.onerror=null;this.src='https://cdn.bfldr.com/NDQASMJ1/as/cvqcrpvtk5b3ptmzp99p8knz/wri-sodium?auto=webp&format=png';"
    role="img"
  />
  <span *ngIf="highSodiumDisplay?.label"> {{ highSodiumDisplay?.label }} </span>
</ng-template>
