/* eslint-disable @ngrx/no-typed-global-store */
import { ClassProvider, Inject, Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { reportableAction } from '../../store/middleware/dispatch-reporter';
import { v4 as uuid } from 'uuid';
import { filter, firstValueFrom } from 'rxjs';

@Injectable()
export class AsynchronousDispatcher<TSTATE = Record<string, unknown>> {
  constructor(@Inject(Store) private store: Store<TSTATE>) {}

  public async dispatch(action: Action): Promise<TSTATE | undefined> {
    const id = uuid();
    const nextStatePromise = firstValueFrom(
      this.store.pipe(
        filter(Boolean),
        filter((state) => (state as { lastId: string })['lastId'] === id)
      )
    );
    this.store.dispatch(reportableAction(id, action));
    return nextStatePromise;
  }
}

export class AsynchronousDispatcherProvider {
  static get(): ClassProvider {
    return {
      provide: AsynchronousDispatcher,
      useClass: AsynchronousDispatcher
    };
  }
}
