import { BaseModel } from '@wingstop/models/base.model';

export class CustomFieldLocation extends BaseModel {
  required: boolean = false;
  validationregex: string = null;
  qualificationcriteria: string = null;
  label: string = null;
  id: any = 0;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
