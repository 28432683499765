<div *ngIf="showCustomTipInput" class="custom-tip-wrapper">
  <div class="doller-input-wrapper">
    <div class="doller-wrapper">
      <span class="doller-text">$</span>
    </div>
    <input
      data-testid="custom-tip-input"
      wriAppTwoDigitDecimalNumber
      minlength="1"
      maxlength="10"
      onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
      type="tel"
      class="input-custom-tip form-control"
      name="custom-tip"
      inputmode="decimal"
      (change)="setTwoNumberDecimal($event)"
      (ngModelChange)="customTipAmount = $event"
      [ngModel]="customTipAmount"
      placeholder="0.00"
    />
  </div>

  <div class="plus-wrapper">
    <button class="wri-btn wri-btn-primary-icon" data-testid="custom-tip-btn" (click)="onCustomInputSubmit()">
      <wri-icon icon="wri-plus"></wri-icon>
    </button>
  </div>
</div>
