import * as t from 'io-ts';
import { optional } from './common.types';

/* #region EmailDomainValidation */
export const EmailDomainValidationResponse = t.intersection(
	[
		t.type({}, 'EmailDomainValidationDtoRequired'),
		t.partial(
			{
				distanceThreshold: optional(t.number),
				validDomains: optional(t.array(t.string))
			},
			'EmailDomainValidationDtoOptional'
		)
	],
	'EmailDomainValidationDto'
);

export type EmailDomainValidationResponse = t.TypeOf<typeof EmailDomainValidationResponse>;
export type EmailDomainValidation = EmailDomainValidationResponse
/* #endregion */