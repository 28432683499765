import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(dateStr: string, format: string): string {
    return moment(dateStr).format(format);
  }
}
