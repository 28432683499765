<form>
  <div class="settings-form-section">
    <span class="settings-title" data-cy="myInformation">
      Favorite Locations
    </span>
    <div class="favorite-locations-form" data-testid="favorite-locations-form">
      <ng-container *ngIf="
          areFavoriteLocationsAvailable();
          then favoriteLocationsContainer;
          else noFavoriteLocationsContainer
        "></ng-container>
      <ng-template #noFavoriteLocationsContainer>
        <wri-no-favorite-locations />
      </ng-template>
      <ng-template #favoriteLocationsContainer>
        <!-- MANAGE FAVORITE LOCATIONS -->
        <div *ngFor="let favoriteWingstopLocation of favoriteWingstopLocations">
          <div class="settings-favorite-locations-wrapper">
            <div>
              <div class="flex flex-baseline">
                <div class="location-name">{{ favoriteWingstopLocation.name }}</div>
              </div>
              <div data-testid="location-detail" class="location-details">
                <span>{{favoriteWingstopLocation?.streetAddress}}</span>
                <span>{{favoriteWingstopLocation?.locality}}, {{favoriteWingstopLocation?.region}} {{favoriteWingstopLocation?.postalCode}}</span>
              </div>
            </div>
            <div class="flex flex-align-left">
              <a
                wriFocusOutline
                tabindex="0"
                (click)="
                  openDeleteConfirmationModal({ data: favoriteWingstopLocation })
                "
                aria-label="Delete"
              >
                <wri-icon class="delete-btn" icon="wri-delete"></wri-icon>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</form>
