import { Component, EventEmitter, Input, Output } from '@angular/core';

export type SpiceHeadingData = {
  selectedType: string;
};

@Component({
  selector: 'wri-flavor-spice-heading',
  templateUrl: './flavor-spice-heading.component.html',
  styleUrls: ['./flavor-spice-heading.component.scss']
})
export class FlavorSpiceHeadingComponent {
  fontSize = '21px';

  @Input() iconColor: string | undefined;
  @Input() heading: string | undefined;
  @Input() type: string | undefined;
  @Input() isMoreInfoNeeded = true;

  @Output()
  selectedSpiceHeading = new EventEmitter<SpiceHeadingData>();

  onSelectType(type: string) {
    this.selectedSpiceHeading.emit({
      selectedType: type
    });
  }
}
