import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { ConnectedFlavorsPageComponent } from './components/connected-flavors-page/connected-flavors-page.component';
import { ACTIVE_OFFER, ActiveOfferResolver, offlineGuard } from '../common';

const routes: Routes = [
  {
    path: '',
    component: ConnectedFlavorsPageComponent,
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableFlavorsPage'), offlineGuard)
    ],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class FlavorsPageRouting {}
