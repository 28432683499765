<div class="upsell-wrapper">
  <div class="upsell-message">
    {{ upsells?.message }}
  </div>

  <div class="upsell-items-wrapper">
    <ng-container *ngFor="let item of upsells?.upsellItems">
      <a
        *ngIf="!item?.outOfStock && item?.availableInSchedule"
        wriFocusOutline
        tabindex="0"
        (click)="getProductUrl(item)"
      >
        <div class="upsell-item">
          <div class="upsell-item-image">
            <img
              aria-live="polite"
              onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
              role="img"
              srcset="{{ item?.images[0]?.uri | imageResolution: 'srcset':'pdp-upsells': '/assets/images/logo-green-product.png'}}"
              src="{{ item?.images[0]?.uri | imageResolution: 'src':'pdp-upsells': '/assets/images/logo-green-product.png'}}"
              alt="{{ item?.name }}"
            />
          </div>
          <div class="upsell-item-info">
            <span class="upsell-item-info-name">{{ item?.name }}</span>
            <span
              *ngIf="item?.priceDifference"
              class="upsell-item-info-price-change"
            >
              {{ prependPlus(item?.priceDifference)
              }}{{ item?.priceDifference | currency }}</span
            >
            <span class="upsell-item-info-calories">{{
              returnCalorieRange(item)
              }}</span>
          </div>

          <div class="upsell-item-chevron">
            <wri-icon icon="wri-chevron-right"></wri-icon>
          </div>
        </div>
      </a>
    </ng-container>
  </div>
</div>
