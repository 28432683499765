import { cond, constant, stubTrue } from 'lodash';
import moment from 'moment-timezone';

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';
const TODAY_FORMAT = 'today';
const TOMORROW_FORMAT = 'tomorrow';
const LATER_FORMAT = 'ddd, MMM D';
const TIME_FORMAT = 'LT';

export const getLaterTime = (
  laterTime: string | undefined | null,
  timeZone: string | null
) => {
  if (timeZone) {
    /* convert API UTC to timezone time */
    const date = moment.tz(laterTime, timeZone);

    /* get today using timezone time */
    const today = moment(moment().tz(timeZone), DATE_TIME_FORMAT);

    const time = cond([
      [isToday(today), constant(TODAY_FORMAT)],
      [isTomorrow(today), constant(TOMORROW_FORMAT)],
      [stubTrue, (d: moment.Moment) => d.format(LATER_FORMAT)]
    ])(date);
    return `${time} at ${date.format(TIME_FORMAT)}`;
  }
  return '';
};

export const isToday = (today: moment.Moment) => (date: moment.Moment) =>
  date.dayOfYear() === today.dayOfYear();

export const isTomorrow = (today: moment.Moment) => (date: moment.Moment) =>
  date.dayOfYear() === ((today.dayOfYear() + 1) % 366 || 1);
