import moment from 'moment-mini';
import { BaseModel } from '@wingstop/models/base.model';
import { BasketProduct } from '@wingstop/models/basket/basket-product.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';
import { CustomField } from '@wingstop/models/order/custom-field.model';
import { Location } from '@wingstop/models/location/location.model';
import { Donation } from './basket/donation.model';
import { Fee } from './basket/fee.model';
import { Tax } from './basket/tax.model';
import { Discount } from './order/discount.model';

export class Basket extends BaseModel {
  // Time modes
  public static ASAP: string = 'asap';
  public static LATER: string = 'later';

  // Delivery modes
  public static MODE_PICKUP: string = 'pickup';
  public static MODE_DISPATCH: string = 'dispatch';
  public static MODE_CURBSIDE: string = 'curbside';
  public static MODE_DINEIN: string = 'dinein';

  id: string;
  timewanted: any;
  earliestreadytime: any;
  subtotal: number;
  salestax: number;
  total: number;
  tip: number;
  coupondiscount: number;
  vendoronline: number;
  deliverymode: string;
  isupsellenabled: number;
  wasupsold: number;
  customerhandoffcharge: number;
  leadtimeestimateminutes: number;
  vendorid: any;
  supportsbaskettransfers: number;
  allowstip: boolean;
  errors: any[];
  nomnom: any = {};
  products: BasketProduct[];
  customfields: CustomField[];
  deliveryaddress: DeliveryAddress;
  crosssell: CategoryProduct[] = [];
  timemode: string = '';
  coupon: { couponcode: string; description: string };
  itemsnottransferred: any[];
  restaurant: Location;
  donations: Donation[] = [];
  totaldonations?: number;
  fees: Fee[];
  taxes: Tax[];
  totalfees: number;
  discounts: Discount[];

  constructor(values?: any) {
    super();

    if (values.timewanted) {
      values.timewanted = moment(values.timewanted, 'YYYYMMDD HH:mm');
    }

    if (values.earliestreadytime) {
      const earliestReadyTime =
        values.readytime && values.timemode === Basket.ASAP
          ? values.readytime
          : values.earliestreadytime;
      values.earliestreadytime = moment(earliestReadyTime, 'YYYYMMDD HH:mm');
    }

    if (values.products) {
      values.products = values.products.map(
        (value: any) => new BasketProduct(value)
      );
    }

    if (values.deliveryaddress) {
      values.deliveryaddress = new DeliveryAddress(values.deliveryaddress);
    }

    if (values.nomnom && values.nomnom.cross_sell) {
      // prevent malformed cross-sells from showing
      const completeCrossSells = (values.nomnom.cross_sell as any[]).filter(
        (crossSell) => crossSell.name && crossSell.description
      );

      if (completeCrossSells.length) {
        values.crosssell = values.nomnom.cross_sell;
      }
    }

    if (values.tip) {
      values.tip = Number(values.tip).toUSD();
    }

    if (values.crosssell) {
      values.crosssell = values.crosssell.map((current: any) => {
        return new CategoryProduct(current);
      });
    }

    this.initialize(values);
  }

  public inASAPMode() {
    return this.timemode === 'asap';
  }

  public inLaterMode() {
    return this.timemode === 'advance' || this.timemode === 'later';
  }

  getScheduleDeliveryMode() {
    switch (this.deliverymode) {
      case 'delivery':
        return 'delivery';
      default:
        return 'business';
    }
  }

  getDeliveryMode() {
    return this.deliverymode;
  }

  public isDispatch() {
    return this.getDeliveryMode() === Basket.MODE_DISPATCH;
  }

  public isPickup() {
    return this.getDeliveryMode() === Basket.MODE_PICKUP;
  }

  public isCurbside() {
    return this.getDeliveryMode() === Basket.MODE_CURBSIDE;
  }

  public isDinein() {
    return this.getDeliveryMode() === Basket.MODE_DINEIN;
  }

  public isEmpty() {
    return !this.products || this.products.length === 0;
  }

  public getTimeWanted() {
    if (this.timewanted) {
      return this.timewanted;
    } else {
      return this.getCurrentTime();
    }
  }

  private generateOffsetPrefix(offset: number) {
    let offsetPrefix = '';
    if (offset > 0) {
      offsetPrefix += '+';
    } else {
      offsetPrefix += '-';
    }
    offset = Math.abs(offset);
    if (offset < 10) {
      offsetPrefix += '0';
    }
    return offsetPrefix;
  }

  public getCurrentTime() {
    const localTime = moment();
    const localOffset = localTime.utcOffset();
    const storeOffset = this.restaurant.utcoffset * 60;
    let calcOffset = localOffset;
    if (storeOffset < localOffset) {
      calcOffset = storeOffset - localOffset;
    } else {
      calcOffset = Math.abs(storeOffset - localOffset);
    }
    localTime.add(calcOffset, 'minutes');
    const adjustedTime = moment.parseZone(
      localTime.format('YYYY-MM-DDTHH:mm:ss') +
        this.generateOffsetPrefix(this.restaurant.utcoffset) +
        Math.abs(this.restaurant.utcoffset) +
        '00'
    );
    return adjustedTime;
  }
}
