<ng-template #locationInfoModal>
  <div class="modal-content">
    <wri-map
      height="225px"
      [id]="locationData.id | prepend : 'wri-location-map-'"
      [interactive]="true"
      [showPreview]="false"
      [allowPopup]="false"
      [locations]="markerData"
    ></wri-map>
    <div class="modal-header">
      <div class="modal-header__header">
        <div class="modal-header__icon-wrapper">
          <a
            data-testid="close-modal"
            wriFocusOutline
            (click)="onModalClose($event)"
            class="close-modal-button"
            href="#"
            [attr.aria-label]="'close modal'"
          >
            <wri-icon
              class="modal-header__cross_icon"
              icon="wri-cross"
            ></wri-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="scrollable-area">
      <div class="location-modal-body location-name">
      <span class="location-modal-body__location-name" data-testid="location-name">
        {{ locationData?.name }}
      </span>
        <ng-container *ngIf="locationData?.comingSoonStatus && locationData?.openDate">
          <div class="location-modal-body__coming-soon-badge">
            <span class="location-modal-body__coming-soon-badge-text">Coming Soon</span>
          </div>
        </ng-container>
        <div class="location-modal-body__location-address" data-testid="location-address">
          <a
              wriFocusOutline
              *ngIf="locationData?.streetAddress"
              [href]="mapUrl"
              [attr.aria-label]="linkLabel"
              target="_blank"
              rel="”noopener”"
          >
            {{ locationData?.streetAddress }}, {{ locationData?.locality }},
            {{ locationData?.region }} {{ locationData?.postalCode }}
          </a>
        </div>

        <div class="location-modal-body__location-phone">
          <wri-phone-number
              [phoneNumber]="locationData?.phoneNumber"
          ></wri-phone-number>
        </div>

        <ng-container *ngIf="!(locationData?.comingSoonStatus && locationData?.openDate)">
          <div class="location-modal-body__location-hours">
            <wri-todays-hours
                [businessDay]="locationData?.businessDay"
                [hours]="carryoutOrBusinessHours"
            ></wri-todays-hours>
          </div>
    
          <div class="location-modal-body__location-grid-wpr">
            <wri-location-amenities
                [offersDelivery]="offersDelivery"
                [amenities]="locationData?.amenities"
            ></wri-location-amenities>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="!(locationData?.comingSoonStatus && locationData?.openDate)">
        <wri-location-hours
            class="location-modal-body store-hours"
            [handoffModeCalendars]="locationData.storeHandoffModeHours"
        ></wri-location-hours>
      </ng-container>
    </div>
  </div>
</ng-template>
