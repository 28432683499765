import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  ECOMM_API_CONFIG,
  EcommAPIConfig
} from '../../config/ecomm-config.provider';
import * as MaybeResponse from '../../types/maybe-response';
import {
  ApplePayMerchantSessionDto,
  ApplePayMerchantSessionResponse,
  CustomerVaultedCardsResponse,
  DeleteCustomerVaultedCardsResponse,
  GiftCardDetailsDto,
  GiftCardDetailsRequest,
  GiftCardDetailsResponse,
  PaymentSessionDetailsDto,
  PaymentSessionDetailsResponse,
  PaymentValidateApplePayMerchantRequest,
  CustomerVaultedCardsDto
} from '../../types/payment.types';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';
import { handleAPIResponse } from '../../utils/handle-api-response';
@Injectable({ providedIn: 'root' })
export class PaymentRepository {
  constructor(
    private http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService
  ) {}

  public getPaymentSessionDetails(
    customErrorMessage: boolean
  ): Observable<MaybeResponse.MaybeResponse<PaymentSessionDetailsDto>> {
    const url = `${this.config.baseUrl}/api/payments/session`;
    return this.http
      .post<PaymentSessionDetailsResponse>(url, {})
      .pipe(
        handleAPIResponse(
          PaymentSessionDetailsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs,
          customErrorMessage
            ? 'Sorry, your previously stored credit cards could not be loaded. Refresh the page to try again.'
            : undefined
        )
      );
  }

  public getGiftCardDetails(
    request: GiftCardDetailsRequest,
    recaptchaToken: string
  ): Observable<MaybeResponse.MaybeResponse<GiftCardDetailsDto>> {
    const headers = {
      recaptchatoken: recaptchaToken
    };
    const url = `${this.config.baseUrl}/api/payments/giftcardbalance`;
    return this.http
      .post<GiftCardDetailsResponse>(url, request, { headers: headers })
      .pipe(
        handleAPIResponse(
          GiftCardDetailsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public validateMerchantForApplePay(
    request: PaymentValidateApplePayMerchantRequest
  ): Observable<MaybeResponse.MaybeResponse<ApplePayMerchantSessionDto>> {
    const url = `${this.config.baseUrl}/api/payments/apple-pay/session`;
    return this.http
      .post<ApplePayMerchantSessionResponse>(url, request)
      .pipe(
        handleAPIResponse(
          ApplePayMerchantSessionResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getVaultedCards(): Observable<
    MaybeResponse.MaybeResponse<CustomerVaultedCardsDto>
  > {
    const url = `${this.config.baseUrl}/api/customer/vault`;
    const headers = {
      // needed for integration tests
      'Content-Type': 'application/json'
    };
    return this.http
      .get<CustomerVaultedCardsResponse>(url, { headers: headers })
      .pipe(
        handleAPIResponse(
          CustomerVaultedCardsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs,
          'Sorry, your previously stored payment methods could not be loaded. Refresh the page to try again.'
        )
      );
  }

  public deleteVaultedCard(
    vaultedAccountId: string,
    deleteCreditCard: boolean
  ): Observable<MaybeResponse.MaybeResponse<boolean>> {
    const url = deleteCreditCard
      ? `${this.config.baseUrl}/api/customer/account/${vaultedAccountId}`
      : `${this.config.baseUrl}/api/customer/vault/${vaultedAccountId}`;
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http
      .delete<DeleteCustomerVaultedCardsResponse>(url, { headers })
      .pipe(
        map(() => ({ data: true, error: null })),
        handleAPIResponse(
          DeleteCustomerVaultedCardsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }
}
