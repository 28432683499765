import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';
import { User } from 'oidc-client-ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AuthState<T, K extends keyof any> = {
  isAuthenticated: boolean;
  error: string | null;
  currentPageUrl: string | null;
  pageBeforeLogout: string | null;
} & Record<K, T | null>;

export type AuthFeatureState = AuthState<User, 'user'>;

const initialAuthFeatureState: AuthFeatureState = {
  isAuthenticated: false,
  error: null,
  user: null,
  currentPageUrl: null,
  pageBeforeLogout: null
};

const AuthActions = createActionGroup({
  source: 'Auth Feature',
  events: {
    'set currentPageUrl': props<{ currentPageUrl?: string | null }>(),
    'set page before logout': props<{ pageBeforeLogout?: string | null }>(),
    'login event': props<{ email?: string }>(),
    'logout event': props<{ email?: string }>(),
    'Set Is Authenticated': emptyProps(),
    'Set State': props<{
      isAuthenticated: boolean;
      error?: string;
      user?: User | null;
      currentPageUrl?: string;
    }>(),
    'Reset To Default': emptyProps()
  }
});

const AuthReducer = createReducer(
  initialAuthFeatureState,
  on(
    AuthActions.setCurrentpageurl,
    (state, action): AuthFeatureState => ({
      ...state,
      currentPageUrl: action.currentPageUrl ?? null
    })
  ),
  on(
    AuthActions.setPageBeforeLogout,
    (state, action): AuthFeatureState => ({
      ...state,
      pageBeforeLogout: action.pageBeforeLogout ?? null
    })
  ),
  on(
    AuthActions.setIsAuthenticated,
    (state): AuthFeatureState => ({ ...state, isAuthenticated: true })
  ),
  on(
    AuthActions.setState,
    (state, action): AuthFeatureState => ({
      ...state,
      isAuthenticated: action.isAuthenticated,
      error: action.error ?? null,
      user: action.user ?? null,
      currentPageUrl: action.currentPageUrl ?? null
    })
  ),
  on(
    AuthActions.resetToDefault,
    (state): AuthFeatureState => ({
      ...state,
      ...initialAuthFeatureState,
      pageBeforeLogout: state.pageBeforeLogout
    })
  )
);

const AuthFeatureKey = 'auth';

const AuthFeature = createFeature({
  name: AuthFeatureKey,
  reducer: AuthReducer
});

export default {
  ...AuthFeature,
  actions: AuthActions,
  initialState: initialAuthFeatureState
};
