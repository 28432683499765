import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(count: number, word: string, pluralizedSuffix = 's', includeCount = true): string {
    let transformed;
    // 'ies' vs singular 'y' (city / cities)
    if (pluralizedSuffix === 'ies') {
      // replace y with ies
      transformed = count != 1 ? word.replace(/.$/, pluralizedSuffix) : word;
    } else {
      // default to plural 's' (applicant / applicants)
      transformed = count === 1 ? word : word + pluralizedSuffix;
    }

    // include count by default
    transformed = includeCount ? `${count} ${transformed}` : transformed;

    return transformed;
  }
}