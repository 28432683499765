import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { SelectedHandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';

export type SelectedHandoffModeFeatureState = SelectedHandoffMode;

const initialSelectedHandoffModeFeatureState: SelectedHandoffModeFeatureState =
  {
    handoffMode: null,
    address: null
  };

const SelectedHandoffModeActions = createActionGroup({
  source: 'Selected Handoff Mode Feature',
  events: {
    'Set State': props<SelectedHandoffMode>(),
    'Reset To Default': emptyProps()
  }
});

const SelectedHandoffModeReducer = createReducer(
  initialSelectedHandoffModeFeatureState,
  on(
    SelectedHandoffModeActions.setState,
    (state, action): SelectedHandoffModeFeatureState => ({
      ...state,
      handoffMode: action.handoffMode,
      address: action.address
    })
  ),
  on(
    SelectedHandoffModeActions.resetToDefault,
    (state): SelectedHandoffModeFeatureState => ({
      ...state,
      ...initialSelectedHandoffModeFeatureState
    })
  )
);

const SelectedHandoffModeFeatureKey = 'selectedHandoffMode';

const SelectedHandoffModeFeature = createFeature({
  name: SelectedHandoffModeFeatureKey,
  reducer: SelectedHandoffModeReducer
});

export default {
  ...SelectedHandoffModeFeature,
  actions: SelectedHandoffModeActions,
  initialState: initialSelectedHandoffModeFeatureState
};
