import { Inject, Injectable } from '@angular/core';
import { from, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { last } from 'lodash';

import { CartService } from '../../../ecomm/services/cart/cart.service';
import {
  Cart,
  CartAddItemRequestProduct,
  CartCreationRequest,
  CartDeliveryAddress
} from '../../../ecomm/types/cart.types';
import { asyncTap } from '../../../ecomm/utils/async-tap';
import * as Workflow from '../../../ecomm/types/workflow';

import { ActivatedRouteSnapshot } from '@angular/router';
import { FulfillmentTimes } from '../../../ecomm/types/fulfillment-times.types';
import { StoreInfo } from '../../../ecomm/types/store-info.types';
import { StoreInfoRepository } from '../../../ecomm/repositories/store-info/store-info.repository';
import { MaybeResponse } from '../../../ecomm/types/maybe-response';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import { SelectedHandoffModeFeature } from '../../../ecomm/store/features/selected-handoff-mode';
import { HandoffMode } from '../../../ecomm/types/selected-handoff-mode.types';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { StoreSnapshotService } from '../../../ecomm/utils/store-snapshot/store-snapshot.service';
import { NAVIGATOR } from '../../../ecomm/providers/navigator/navigator.provider';
import { ReCaptchaService } from '../../../ecomm/utils/recaptcha/recaptcha.service';
import { AuthService } from '../../../ecomm/utils/auth/auth.service';
import { AnalyticsService } from '../../../ecomm/providers/legacy-providers/analytics.service';
import { NotificationService } from '../../../ecomm/utils/notification/notification.service';
import { SelectedHandoffModeService } from '../selected-handoff-mode/selected-handoff-mode.service';
import { handoffModesAreEqual } from '../selected-handoff-mode/selected-handoff-mode.utilities';

@Injectable({ providedIn: 'root' })
export class CartWorkflowService {
  public static FUTURE_TIME_UPDATE_ERROR =
    'We encountered an error updating your order to a later time. Please try again.';
  public static ASAP_UPDATE_ERROR =
    'We encountered an error updating your order to ASAP. Please try again';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setCartLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(CartFeature.actions.setIsLoading())
    );
  };

  private saveState = Workflow.onAny<Cart>(
    asyncTap((res) => {
      return this.asynchronusDispatcher.dispatch(
        CartFeature.actions.setState({
          cart: res.data,
          error: res.error
        })
      );
    })
  );

  private reportErrors = Workflow.onError<Cart>(
    tap((res) => this.notificationService.showError(res.error))
  );

  private defaultToPreviousState = Workflow.onError<Cart>(
    switchMap((res) =>
      from(this.storeSnapshotService.get()).pipe(
        switchMap((state) =>
          state
            ? of({
                data: res.data ?? CartFeature.selectCart(state) ?? undefined,
                error: res.error
              })
            : of(res)
        )
      )
    )
  );

  private logGAPromoCodeEvent = (promoCode: string) =>
    Workflow.onAny<Cart>(
      tap((res) => {
        this.analyticsService.logGaEvent({
          event: 'promo_code',
          promo_code: promoCode,
          valid_promo_code:
            res.data?.offer?.status === 'valid' ? 'valid' : 'invalid'
        });
      })
    );

  private logGAOfferApplyEvent = (
    offerCode: string,
    isFromMarketingCampaign = false
  ) =>
    Workflow.onAny<Cart>(
      tap((res) => {
        if (isFromMarketingCampaign) {
          this.analyticsService.logGaEvent(
            res.error
              ? {
                  event: 'offer_apply_error',
                  coupon: offerCode,
                  error_message: res.error
                }
              : {
                  event: 'offer_apply',
                  coupon: offerCode
                }
          );
        }
      })
    );

  private get = Workflow.createWorkflow(
    this.setCartLoading,
    (...args: Parameters<CartService['getCartById']>) =>
      this.cartService.getCartById(...args),
    this.reportErrors,
    this.defaultToPreviousState,
    this.saveState
  );

  private update = Workflow.createWorkflow(
    this.setCartLoading,
    (...args: Parameters<CartService['updateCartById']>) =>
      this.cartService.updateCartById(...args),
    this.reportErrors,
    this.defaultToPreviousState,
    this.saveState
  );

  private create = Workflow.createWorkflow(
    this.setCartLoading,
    (
      locationId: string,
      handoffMode: string,
      deliveryAddress?: CartDeliveryAddress | null
    ) =>
      this.cartService.createCart({
        locationId,
        handoffMode,
        deliveryAddress
      }),
    this.reportErrors,
    this.saveState
  );

  constructor(
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    @Inject(StoreSnapshotService)
    private storeSnapshotService: StoreSnapshotService<FeaturesState>,
    @Inject(NAVIGATOR)
    private navigator: Navigator,
    private notificationService: NotificationService,
    private reCaptchaService: ReCaptchaService,
    private cartService: CartService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private selectedHandoffModeService: SelectedHandoffModeService,
    private storeInfoRepository: StoreInfoRepository
  ) {}

  public async getOrCreate(
    route: ActivatedRouteSnapshot
  ): Promise<Cart | null> {
    const currentStore = await this.storeSnapshotService.get();
    const cart = CartFeature.selectCart(currentStore);
    const storeInfoState = StoreInfoFeature.selectStoreInfoState(currentStore);
    const handoffMode =
      SelectedHandoffModeFeature.selectSelectedHandoffModeState(currentStore);

    const createIfNotFound =
      storeInfoState.storeInfo && handoffMode.handoffMode
        ? {
            locationId: storeInfoState.storeInfo?.storeDetails.id,
            handoffMode: handoffMode.handoffMode ?? HandoffMode.carryout,
            deliveryAddress: handoffMode.address
          }
        : undefined;

    const createCarryoutCartIfNotFound =
      storeInfoState.storeInfo && handoffMode.handoffMode
        ? {
            locationId: storeInfoState.storeInfo?.storeDetails.id,
            handoffMode: HandoffMode.carryout
          }
        : undefined;

    if (cart) {
      const { cartTransferred, createCarryoutCart } = await this.isCartTransferred(
        route,
        storeInfoState.storeInfo?.storeDetails.id || ''
      );
      if (cartTransferred) {
        return this.updateExistingCart(cart.cartId, {
          createIfNotFound: createCarryoutCart
            ? createCarryoutCartIfNotFound
            : createIfNotFound
        });
      } else {
        return this.getExistingCart(cart.cartId, { createIfNotFound });
      }
    } 

    if (!storeInfoState.storeInfo || !handoffMode.handoffMode) {
      return null;
    }
    this.analyticsService.logGaEvent({ event: 'start_new_order' });
    return this.create(
      storeInfoState.storeInfo.storeDetails.id,
      handoffMode.handoffMode,
      handoffMode.address
    );
  }

  public async isCartTransferred(
    route: ActivatedRouteSnapshot,
    storeId: string,
    handoffModeFromCTA?: string
  ): Promise<{
    cartTransferred: boolean,
    createCarryoutCart?: boolean
  }> {
    const currentStore = await this.storeSnapshotService.get();
    const cart = CartFeature.selectCart(currentStore);
    const defaultResponse = {
      cartTransferred: false,
      createCarryoutCart: false
    }

    if (cart) {
      const fromUrl = this.selectedHandoffModeService.getSelectedHandoffModeFromUrl(route);
      const fromCart = await this.selectedHandoffModeService.getSelectedHandoffModeFromCart();
      if (handoffModeFromCTA && fromCart?.handoffMode !== handoffModeFromCTA) {
        return {
          ...defaultResponse,
          cartTransferred: true
        }
      } else if (cart?.locationId !== storeId) {
        if (fromUrl === null || fromUrl.handoffMode === HandoffMode.carryout) {
          return {
            ...defaultResponse,
            cartTransferred: true,
            createCarryoutCart: true
          }
        }
        return {
          ...defaultResponse,
          cartTransferred: true
        }
      } else {
        if (fromCart && fromUrl && !handoffModesAreEqual(fromCart, fromUrl)) {
          return {
            ...defaultResponse,
            cartTransferred: true
          }
        }
        return defaultResponse;
      }
    }
    return defaultResponse;
  }

  private async getExistingCart(
    cartId: string,
    options: Partial<{
      createIfNotFound: CartCreationRequest;
    }> = {}
  ): Promise<Cart | null> {
    return await this.get(cartId, options);
  }

  public async updateExistingCart(
    cartId: string,
    options: Partial<{
      createIfNotFound: CartCreationRequest;
    }> = {}
  ): Promise<Cart | null> {
    return await this.update(cartId, options);
  }

  public addToCart = (
    cartId: string,
    quantity: number,
    product: CartAddItemRequestProduct
  ) =>
    Workflow.createWorkflow(
      this.setCartLoading,
      (cartId: string, quantity: number, product: CartAddItemRequestProduct) =>
        this.cartService.addItemToCart(cartId, {
          quantity,
          product
        }),
      this.reportErrors,
      this.saveState,
      Workflow.onSuccess(
        asyncTap((res) => {
          const cartItem = last(res.data?.items);
          return this.asynchronusDispatcher.dispatch(
            CartFeature.actions.itemAddedToCart({
              lineItem:
                cartItem?.productId === product.itemId ? cartItem : undefined
            })
          );
        })
      )
    )(cartId, quantity, product);

  public updateCart = Workflow.createWorkflow(
    this.setCartLoading,
    (
      cartId: string,
      lineItemId: string,
      quantity: number,
      product: CartAddItemRequestProduct
    ) =>
      this.cartService.updateItemInCart(cartId, lineItemId, {
        quantity,
        product
      }),
    this.reportErrors,
    this.saveState
  );

  public updateQuantity = Workflow.createWorkflow(
    this.setCartLoading,
    (cartId: string, quantity: number, lineItemId: string) =>
      this.cartService.updateItemQuantity(cartId, lineItemId, {
        quantity
      }),
    this.reportErrors,
    this.saveState
  );

  public removeItem = Workflow.createWorkflow(
    this.setCartLoading,
    (...args: Parameters<CartService['removeItemFromCart']>) =>
      this.cartService.removeItemFromCart(...args),
    this.reportErrors,
    this.saveState
  );

  private saveFulfillmentData = async (
    res: MaybeResponse<FulfillmentTimes>
  ) => {
    const currentStore = await this.storeSnapshotService.get();
    const storeInfoState = StoreInfoFeature.selectStoreInfoState(currentStore);

    this.asynchronusDispatcher.dispatch(
      StoreInfoFeature.actions.setState({
        storeInfo: {
          ...storeInfoState.storeInfo,
          fulfillmentTimes: res.data
        } as StoreInfo,
        error: storeInfoState.error || undefined,
        selectedItem: storeInfoState.selectedItem
      })
    );
  };

  public finalizeCart = (cartId: string, addRoundUpFee: boolean) =>
    Workflow.createWorkflow(
      this.setCartLoading,
      (cartId: string, addRoundUpFee: boolean) =>
        from(this.reCaptchaService.execute('finalize_cart')).pipe(
          withLatestFrom(from(this.authService.getCurrentAuth())),
          map(([recaptchaToken, auth]) => ({
            action: 'finalize-cart' as const,
            request: {
              cartId,
              recaptchaToken,
              addRoundUpFee,
              acceptLanguage: this.navigator.language || 'en-US',
              accessToken: auth.user?.access_token ?? undefined
            }
          })),
          switchMap((finalizeCartData) =>
            this.cartService.finalizeCart(finalizeCartData)
          )
        ),
      this.reportErrors,

      Workflow.onError(() =>
        from(this.cartService.getCartById(cartId)).pipe(
          switchMap((res) => {
            if (res.data?.status == 'invalid') {
              this.storeInfoRepository
                .getFulfillmentTimes(
                  res.data?.locationId,
                  res.data?.handoffMode
                )
                .subscribe((val) => this.saveFulfillmentData(val));
            }

            return of(res);
          })
        )
      ),
      this.saveState
    )(cartId, addRoundUpFee);

  public saveFutureDate = Workflow.createWorkflow(
    this.setCartLoading,
    (cartId: string, isAsap?: boolean, fulfillmentTime?: string) =>
      this.cartService.updateCartFulfillmentTime(cartId, {
        isAsap: isAsap ?? false,
        fulfillmentTime
      }),
    this.reportErrors,
    this.saveState
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public resetCartState = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(CartFeature.actions.resetToDefault())
    );
  };

  public applyPromoCodeToCart = (
    cartId: string,
    offerCode: string,
    isFromMarketingCampaign = false
  ) =>
    Workflow.createWorkflowWithBothDataAndError(
      this.setCartLoading,
      (cartId: string, offerCode: string) =>
        from(this.reCaptchaService.execute('apply_offer')).pipe(
          switchMap((recaptchaToken) =>
            this.cartService.applyOfferCodeToCart(cartId, recaptchaToken, {
              offerCode
            })
          )
        ),
      this.logGAPromoCodeEvent(offerCode),
      this.logGAOfferApplyEvent(offerCode, isFromMarketingCampaign),
      this.saveState
    )(cartId, offerCode);

  public deletePromoCode = Workflow.createWorkflow(
    this.setCartLoading,
    (...args: Parameters<CartService['removeOfferCodeFromCart']>) =>
      this.cartService.removeOfferCodeFromCart(...args),
    this.reportErrors,
    this.saveState
  );

  public updateCartTip = Workflow.createWorkflow(
    this.setCartLoading,
    (...args: Parameters<CartService['updateCartTip']>) =>
      this.cartService.updateCartTip(...args),
    this.reportErrors,
    this.saveState,
    Workflow.onSuccess(
      tap((res) => {
        this.analyticsService.logGaEvent({
          event: 'tip',
          amount: res.data?.tip?.amount || 0
        });
      })
    ),
    Workflow.onSuccess(
      asyncTap((res) => {
        return this.asynchronusDispatcher.dispatch(
          CartFeature.actions.tipSelected({
            tip: res.data?.tip ?? undefined
          })
        );
      })
    ),
    Workflow.onError(
      tap(() => {
        this.analyticsService.logGaEvent({
          event: 'checkout_error',
          error_reason: 'tip_failed'
        });
      })
    )
  );
}
