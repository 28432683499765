import { Component, Input } from '@angular/core';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'wri-marketing-section',
  templateUrl: './marketing-section.component.html',
  styleUrls: ['./marketing-section.component.scss']
})
export class MarketingSectionComponent {
  constructor(private analyticsService: AnalyticsService) {}

  @Input() page = '';

  onAppDownloadClick(app: string) {
    this.analyticsService.logGaEvent({
      event: 'download_app',
      page: this.page,
      app_store_type: app
    });
  }
}
