import { ValidatorFn } from '@angular/forms';

import parsePhoneNumber from 'libphonenumber-js';

export function formatFromPhone(value: string) {
  return value.replace(/[\s-\D]+/g, '').substring(0, 10);
}

export function formatToPhone(value: string) {
  const input = formatFromPhone(value);
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  return `${areaCode} ${middle} ${last}`.trim();
}

export function phoneValidator(): ValidatorFn {
  return (control) => {
    if (control.value === undefined || control.value === null) {
      return null;
    }
    const phoneNumber = parsePhoneNumber(`${control.value}`, 'US');
    const valid = phoneNumber && phoneNumber.isValid();
    if (valid === false) {
      return { inValidNumber: true };
    }

    const parsedNumber = formatFromPhone(control.value as string);
    if (parsedNumber.length > 0 && parsedNumber.length < 10) {
      return { minLength: true };
    }
    if (parsedNumber.length > 10) {
      return { maxLength: true };
    }
    return null;
  };
}
