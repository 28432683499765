import { Inject, Injectable } from '@angular/core';
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';

import { filter, firstValueFrom, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CONFIG, Config } from '../../providers/config/config.provider';
import { WINDOW } from '../../providers/window/window.provider';
import { AnalyticsService } from '../../providers/legacy-providers/analytics.service';
import { AuthFeature, AuthFeatureState } from '../../store/features/auth';
import { AsynchronousDispatcher } from '../asynchronus-dispatcher/asynchronous-dispatcher.service';
import { StoreSnapshotService } from '../store-snapshot/store-snapshot.service';
import { CacheLoadedFeature } from '../../store/features/cache-loaded';
import { CustomerFeature } from '../../store/features/customer';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public static currentPageUrl = 'currentPageUrl';
  userManager: UserManager;
  private subject = new Subject<boolean>();
  private fireLoginEvent = true;
  private FACEBOOK_ACR = 'Facebook_Wingstop';
  private GOOGLE_ACR = 'Google_Wingstop';
  private APPLE_ACR = 'Apple_Wingstop';

  constructor(
    @Inject(CONFIG) private config: Config,
    @Inject(AsynchronousDispatcher)
    private asynchronousDispatcher: AsynchronousDispatcher<AuthFeatureState>,
    @Inject(StoreSnapshotService)
    private storeSnapshotService: StoreSnapshotService<AuthFeatureState>,
    @Inject(WINDOW) private window: Window,
    private analyticsService: AnalyticsService,
    protected store: Store,
    private router: Router
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const ngfeSsoParam = urlParams.get('ngfe_sso') ?? '';

    const ngfeSso =
      ngfeSsoParam === 'facebook' ||
      ngfeSsoParam === 'google' ||
      ngfeSsoParam === 'apple'
        ? ngfeSsoParam
        : '';
    const settings = this.setupUserManagerSettings(ngfeSso);
    this.userManager = new UserManager(settings);
    this.registerUserLoadedEvents();
    if (ngfeSso !== '') this.login(window.location.pathname);
  }

  public setUserManager(externalIdp: string) {
    const settings = this.setupUserManagerSettings(externalIdp);
    this.userManager = new UserManager(settings);
    this.registerUserLoadedEvents();
    if (externalIdp !== '') {
      this.login(window.location.pathname);
    }
  }

  get signInEvents() {
    return this.subject;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public async notifyLogin() {
    const user = await this.getUser();
    const customer = await this.getCustomer();
    this.analyticsService.logGaEvent({ user_id: customer?.customerId });

    if (user) {
      const canonicalUser = await this.getCustomer();
      if (canonicalUser) {
        await this.asynchronousDispatcher.dispatch(
          AuthFeature.actions.loginEvent({
            email: canonicalUser?.email ?? undefined
          })
        );
      }
      if (this.fireLoginEvent) {
        this.analyticsService.logGaEvent({
          event: 'login',
          method: (user?.profile['ssoUser'] as string) ?? 'email'
        });
        this.fireLoginEvent = false;
      }
    }
  }

  public async login(url: string) {
    await firstValueFrom(
      this.store
        .select(CacheLoadedFeature.selectCacheLoaded)
        .pipe(filter(Boolean))
    );
    await this.asynchronousDispatcher.dispatch(
      AuthFeature.actions.setCurrentpageurl({
        currentPageUrl: url
      })
    );
    await this.asynchronousDispatcher.dispatch(
      AuthFeature.actions.setPageBeforeLogout({
        pageBeforeLogout: null
      })
    );
    try {
      return this.userManager.signinRedirect();
    } catch (err) {
      console.warn(err);
      return;
    }
  }

  public async saveAuthStateWithSubject() {
    await this.saveAuthState().then(() => this.subject.next(true));
  }

  private async getCustomer() {
    return firstValueFrom(this.store.select(CustomerFeature.selectCustomer));
  }

  public async saveAuthState(url?: string) {
    await firstValueFrom(
      this.store
        .select(CacheLoadedFeature.selectCacheLoaded)
        .pipe(filter(Boolean))
    );
    const user = await this.getUser();
    const retVal = await this.asynchronousDispatcher.dispatch(
      AuthFeature.actions.setState({
        isAuthenticated: true,
        user: user,
        currentPageUrl: url
      })
    );
    return retVal;
  }

  async getCurrentAuth(): Promise<AuthFeatureState> {
    await firstValueFrom(
      this.store
        .select(CacheLoadedFeature.selectCacheLoaded)
        .pipe(filter(Boolean))
    );
    const currentStore = await this.storeSnapshotService.get();
    return AuthFeature.selectAuthState(currentStore);
  }

  public async renewToken(): Promise<User | null> {
    const user = await this.userManager.signinSilent();
    await this.asynchronousDispatcher.dispatch(
      AuthFeature.actions.setState({
        isAuthenticated: true,
        user: user
      })
    );
    return user;
  }

  public async logout(resetPassword = false) {
    const user = await this.getUser();
    if (user) {
      const canonicalUser = await this.getCustomer();
      if (canonicalUser) {
        await this.asynchronousDispatcher.dispatch(
          AuthFeature.actions.logoutEvent({
            email: canonicalUser.email ?? undefined
          })
        );
      }
    }
    await this.asynchronousDispatcher.dispatch(
      AuthFeature.actions.resetToDefault()
    );
    await this.asynchronousDispatcher.dispatch(
      CustomerFeature.actions.resetToDefault()
    );
    await this.asynchronousDispatcher.dispatch(
      AuthFeature.actions.setPageBeforeLogout({
        pageBeforeLogout: resetPassword ? 'reset' : this.router.url
      })
    );
    this.analyticsService.logGaEvent({
      event: 'account_click_option',
      account_option_value: 'Logout'
    });
    this.subject.next(false);
    await this.userManager
      .signoutRedirect()
      .then(async () => {
        await this.asynchronousDispatcher.dispatch(
          AuthFeature.actions.resetToDefault()
        );
        await this.asynchronousDispatcher.dispatch(
          CustomerFeature.actions.resetToDefault()
        );
        await this.asynchronousDispatcher.dispatch(
          AuthFeature.actions.setPageBeforeLogout({
            pageBeforeLogout: resetPassword ? 'reset' : this.router.url
          })
        );
      })
      .catch((error) => console.info('Error ', error));
  }

  private setupUserManagerSettings(ngfeSso: string) {
    return {
      authority: this.config.ping.stsAuthority,
      client_id: this.config.ping.clientId,
      redirect_uri: `${this.clientRoot()}/auth/signin-callback`,
      post_logout_redirect_uri: `${this.clientRoot()}/auth/signout-callback`,
      response_type: 'code',
      scope: this.config.ping.clientScope,
      automaticSilentRenew: true,
      acr_values:
        {
          facebook: this.FACEBOOK_ACR,
          google: this.GOOGLE_ACR,
          apple: this.APPLE_ACR
        }[ngfeSso] || '',
      userStore: new WebStorageStateStore({ store: this.window.localStorage })
    };
  }

  private registerUserLoadedEvents() {
    this.userManager.events.addUserLoaded(async () => {
      await this.saveAuthStateWithSubject();
    });
  }

  private clientRoot = () => {
    const protocol = this.window.location?.protocol;
    const host = this.window.location.host?.replace(/:80$/, '');
    return `${protocol}//${host}`;
  };
}
