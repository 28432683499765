<wri-feature-flags [with]="['enablePaypal']">
  <div
    *ngIf="isPaymentMethodSupported"
    class="paypal-button-wrapper"
    [attr.data-testid]="methodSupported ? 'paypal-payment-method' : ''"
  >
    <div
      #paypal
      id="paypal-button"
      class="btn-primary paypal-button paypal-payment-method"
    ></div>
  </div>
</wri-feature-flags>
