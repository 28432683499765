import { BaseModel } from '@wingstop/models/base.model';

export class Metadata extends BaseModel {
  key: string = null;
  value: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
