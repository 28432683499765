import { Action, ActionReducer } from '@ngrx/store';

type ReducerFn<TSTATE> = (
  next: ActionReducer<TSTATE, Action>
) => (state: TSTATE, action: Action) => TSTATE;

export const providedOnceMiddleware =
  <TSTATE>(key: string, reducer: ReducerFn<TSTATE>): ReducerFn<TSTATE> =>
  (next) =>
  (state, action) => {
    if ((action as Action & Record<string, boolean>)[key]) {
      return next(state, action);
    }

    const nextAction = { ...action, [key]: true };
    return reducer(next)(state, nextAction);
  };
