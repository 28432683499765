import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as MaybeResponse from '../../types/maybe-response';
import { handleResourceResponse } from '../../utils/handle-resource-response';
import { EmailDomainValidationResponse } from '../../types/email-domain-validation.types';
import { Config, CONFIG } from '../../providers/config/config.provider';

@Injectable({ providedIn: 'root' })
export class EmailDomainValidationRepository {
  constructor(
    private http: HttpClient,
    @Inject(CONFIG) private config: Config
  ) {}

  getEmailDomainValidations(): Observable<
    MaybeResponse.MaybeResponse<EmailDomainValidationResponse>
  > {
    const url = `${this.config.ngfeBaseUrl}/resources/email-domain-validation.json`;
    return this.http
      .get<EmailDomainValidationResponse>(url)
      .pipe(handleResourceResponse(EmailDomainValidationResponse));
  }
}
