/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { map, ReplaySubject, Subscription } from 'rxjs';

import {
  LocationHandoffModeCalendar,
  LocationNormalCalendarRange
} from '../../../../ecomm/types/search-location.types';
import { formatBusinessDayTimings } from '../../../../ecomm/utils/format-business-day-timings';
import { Tab } from '../tabs/tabs.component';

type HourDisplay = { day: string; time: string };

@Component({
  selector: 'wri-location-hours',
  template: `
    <section class="store-hours">
      <h3>store hours</h3>
      <wri-tabs
        [tabs]="storeHoursTabs"
        (selected)="selectedTab$.next($event)"
      ></wri-tabs>
      <ul class="display-hours">
        <li *ngFor="let display of displayHours">
          <span class="display-hours-day">{{ display.day }}</span>
          <span class="display-hours-time">{{ display.time }}</span>
        </li>
      </ul>
    </section>
  `,
  styleUrls: ['./location-hours.component.scss']
})
export class LocationHoursComponent implements OnInit, OnDestroy, OnChanges {
  private static readonly dayMap = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7
  };
  public storeHoursTabs: Tab[] = [
    { id: 'carryout', display: 'carryout' },
    { id: 'delivery', display: 'delivery' }
  ];
  public selectedTab$ = new ReplaySubject<Tab>();
  public displayHours: HourDisplay[] = [];
  public defaultDisplayHours: HourDisplay[] = [
    { day: 'Sunday', time: 'Closed' },
    { day: 'Monday', time: 'Closed' },
    { day: 'Tuesday', time: 'Closed' },
    { day: 'Wednesday', time: 'Closed' },
    { day: 'Thursday', time: 'Closed' },
    { day: 'Friday', time: 'Closed' },
    { day: 'Saturday', time: 'Closed' }
  ];

  @Input()
  handoffModeCalendars?: LocationHandoffModeCalendar[];

  private subscription = new Subscription();

  ngOnInit(): void {
    if (this.handoffModeCalendars) {
      this.processHours();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['handoffModeCalendars'] && this.handoffModeCalendars) {
      this.processHours();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private processHours() {
    this.subscription.add(
      this.selectedTab$
        .pipe(
          map((tab) => {
            const handoffModeHours = this.handoffModeCalendars?.find((h) => {
              const calendarHandoffMode = h.handoffMode.toLowerCase();
              const selectedHandoffMode = tab.id.toLowerCase();
              return calendarHandoffMode === selectedHandoffMode;
            })?.hours;
            const handoffModeHoursSortedByDay = handoffModeHours?.sort(
              (a, b) => {
                return (
                  (LocationHoursComponent.dayMap as any)[a.startDay] -
                  (LocationHoursComponent.dayMap as any)[b.startDay]
                );
              }
            );
            Object.keys(LocationHoursComponent.dayMap).some((day) => {
              if (
                handoffModeHoursSortedByDay?.findIndex(
                  (a) => a.startDay === day
                ) === -1
              ) {
                handoffModeHoursSortedByDay.push({
                  startDay: day,
                  startTime: '',
                  endDay: day,
                  endTime: ''
                });
              }
            });
            const groupedByDay = Object.entries(
              handoffModeHoursSortedByDay?.reduce((acc, hour) => {
                const key = hour.startDay;
                const currentValue = acc[hour.startDay] ?? [];
                const newValue = [...currentValue, hour];
                return { ...acc, [key]: newValue };
              }, {} as Record<string, LocationNormalCalendarRange[]>) ?? {}
            );
            const retVal = groupedByDay.map(([day, hours]) => ({
              day,
              time:
                hours[0].startTime === ''
                  ? 'Closed'
                  : formatBusinessDayTimings(hours)
            }));
            return retVal.length > 0 ? retVal : this.defaultDisplayHours;
          })
        )
        .subscribe((hours) => {
          this.displayHours = hours;
        })
    );
  }
}
