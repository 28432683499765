<div id="global_messaging_footer"></div>
<footer id="footer" class="footer" *ngIf="shouldShow$ | async">
  <div class="constrain">
    <div class="wri-marketing-section" data-testid="marketing-section">
      <div class="wri-marketing-section-content">
        <div class="wri-marketing-section-container">
          <span wriFocusOutline tabindex="0" class="wri-marketing-section-title">Order Wings for Delivery or Carryout</span>
          <span wriFocusOutline tabindex="0" class="wri-marketing-section-description">
            We're making it easier than ever to bring our iconic flavors straight to you.
            Build a custom order as bold as your crew on the Wingstop app.
          </span>
        </div>
        <div class="logos-app d-flex">
          <a
            (click)="onAppDownloadClick('apple')"
            href="https://apps.apple.com/us/app/wingstop/id454719277"
            class="pointer"
            [attr.aria-label]="'Download on the App Store'"
          >
            <img
              src="/assets/images/app-store.png"
              usemap="#apple-map"
              alt="Download on the App Store"
              aria-hidden="true"
              class="social-icon apple-icon"
            />
          </a>
          <a
            (click)="onAppDownloadClick('google')"
            href="https://play.google.com/store/apps/details?id=com.mobilaurus.wingstopandroid&hl=en_US"
            class="pointer"
            [attr.aria-label]="'Get it on Google Play'"
          >
            <img
              src="/assets/images/google-play.png"
              usemap="#google-map"
              alt="Get it on Google Play"
              aria-hidden="true"
              class="social-icon google-icon"
            />
          </a>
        </div>
      </div>
    </div>
    <ul
      class="nav h6 lead footer-links footer-corporate">
      <li class="nav-item">
        <a class="nav-link" routerLink="/about-us">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/gift-cards">Gift Cards</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/careers">Careers</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://www.wingstopcharities.org"
          target="_blank"
          rel="”noopener”"
          title="Opens in a new tab"
          (click)="externalSite($event, 'https://www.wingstopcharities.org')">
          Wingstop Charities
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact-us">Contact Us</a>
      </li>
    </ul>

    <ul
      class="nav small footer-links footer-legal">
      <li class="nav-item">
        <a
          id="ot-sdk-btn"
          tabindex="0"
          class="ot-sdk-show-settings nav-link"
          aria-label="Cookie Preferences, Open one-trust cookie management">
          Cookie Preferences
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/offers"
          aria-label="Promotions & Offers">
          Promotions & Offers
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/terms-of-use"
          aria-label="Wingstop's Terms & Conditions">
          Terms
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/privacy"
          aria-label="Wingstop's Privacy Policy">
          Privacy
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/sitemap">Sitemap</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/accessibility">Accessibility</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://ir.wingstop.com"
          target="_blank"
          rel="”noopener”"
          title="Opens in a new tab">
          Investor Relations
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/own-a-wingstop">Own a Wingstop</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/nutrition">Nutritional Info</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/allergens">Allergen Info</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/faq">FAQ</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/california-privacy">
          California Privacy
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/privacy" fragment="dnsmi">
          Do Not Sell My Info
        </a>
      </li>
    </ul>

    <div class="footer-border">
      <div class="col-12 p-0 d-flex justify-content-center align-items-center">
        <ul class="nav footer-user">
          <li class="nav-item">
            <a
              href="https://www.facebook.com/wingstop/"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Facebook will open in a new tab"
              (click)="
                openSocialLinks($event, 'https://www.facebook.com/wingstop/')
              ">
              <fa-icon [icon]="icons.faFacebookF" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://www.instagram.com/wingstop"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Instagram will open in a new tab"
              (click)="
                openSocialLinks($event, 'https://www.instagram.com/wingstop')
              ">
              <fa-icon [icon]="icons.faInstagram" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://twitter.com/wingstop/"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Twitter will open in a new tab"
              (click)="openSocialLinks($event, 'https://twitter.com/wingstop/')">
              <fa-icon [icon]="icons.faXTwitter" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://open.spotify.com/user/r1cb3fd7wt2v3nqjbl5p7p5wx"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Spotify will open in a new tab"
              (click)="
                openSocialLinks(
                  $event,
                  'https://open.spotify.com/user/r1cb3fd7wt2v3nqjbl5p7p5wx'
                )
              ">
              <fa-icon [icon]="icons.faSpotify" size="lg"></fa-icon>
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@wingstop"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="TikTok will open in a new tab"
              (click)="
                openSocialLinks($event, 'https://www.tiktok.com/@wingstop')
              ">
              <fa-icon [icon]="icons.faTiktok" size="lg"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-copyright">
      <p class="small text-muted m-0">
        &copy; Wingstop Restaurants, Inc. {{ copyrightYear }}
      </p>
    </div>
  </div>
</footer>

<wri-external-link-confirmation-modal></wri-external-link-confirmation-modal>
