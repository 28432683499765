import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { v4 as uuid } from 'uuid';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';

export type Tab = { id: string; display?: string; default?: true };

@Component({
  selector: 'wri-tabs',
  styleUrls: ['./tabs.component.scss'],
  template: `
    <ul [id]="tabsId" class="tabs-container" role="tablist">
      <div [id]="tabsId | prepend : 'slider-'" class="slider"></div>
      <li
        [id]="tab.id"
        [attr.data-testid]="tab.id"
        class="tab"
        [class.active]="selectedTab?.id === tab.id"
        role="tab"
        [aria-controls]="tab.id"
        [attr.aria-selected]="selectedTab?.id === tab.id"
        (click)="handleTabSelect(tab)"
        *ngFor="let tab of tabs"
        [attr.tabindex]="0"
        wriFocusOutline
      >
        {{ tab.display }}
      </li>
    </ul>
  `
})
export class TabsComponent implements AfterViewInit {
  tabsId = uuid();
  _selectedTabId?: string;
  selectedTab?: Tab;

  @Input()
  public tabs: Tab[] = [];
  @Input()
  public set selectedTabId(id: string) {
    this._selectedTabId = id;
    const selected =
      this.tabs.find((t) => t.id === id) ??
      this.tabs.find((t) => t.default) ??
      this.tabs[0];
    this.handleTabSelect(selected);
  }

  @Output()
  public selected = new EventEmitter<Tab>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit(): void {
    const defaultTab =
      this.tabs.find((t) => t.id === this._selectedTabId) ??
      this.tabs.find((t) => t.default) ??
      this.tabs[0];
    defaultTab && this.handleTabSelect(defaultTab);
  }

  handleTabSelect(tab: Tab) {
    this.moveSlider(tab);
    this.selectedTab = tab;
    this.selected.emit(tab);
  }

  moveSlider(tab: Tab) {
    const containerEl = this.document.getElementById(this.tabsId);
    const sliderEl = this.document.getElementById(`slider-${this.tabsId}`);
    const newTabEl = this.document.getElementById(tab.id);
    if (containerEl && sliderEl && newTabEl) {
      const containerOffset = containerEl.getBoundingClientRect().x;
      const newTabOffset = newTabEl.getBoundingClientRect().x;
      sliderEl.style.setProperty('left', `${newTabOffset - containerOffset}px`);
    }
  }
}
