import { Pipe, PipeTransform } from '@angular/core';

import { MenuModifier } from '../../../ecomm/types/store-info.types';

@Pipe({
  name: 'heatScale'
})
export class HeatScalePipe implements PipeTransform {
  transform(modifier: MenuModifier): number {
    const metadata = modifier['metadata'] ?? modifier['metadata'] ?? {};
    return Number(metadata['heat-scale'] ?? '0');
  }
}
