import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomerDeliveryAddress } from '../../../../../ecomm/types/customer.types';
import { CustomerWorkflowService } from '../../../../../ecomm/workflows/customer/customer-workflow.service';

type ModalData = {
  data: CustomerDeliveryAddress;
  deleteMyAddress: boolean;
};

@Component({
  selector: 'wri-remove-my-address-modal',
  templateUrl: './remove-my-address-modal.component.html',
  styleUrls: ['./remove-my-address-modal.component.scss']
})
export class RemoveMyAddressModalComponent {
  @Input() modalData!: ModalData;

  constructor(
    public activeModal: NgbActiveModal,
    private userAccountService: CustomerWorkflowService
  ) {}

  public isLoading = false;

  closeModal() {
    this.activeModal.close();
  }

  public async removeMyAddress(e: Event) {
    if (this.isLoading) {
      e.preventDefault();
    } else {
      const { data: { id = '' } = {} } = this.modalData;
      this.isLoading = true;
      const response = await this.userAccountService.deleteMyAddress(id);
      this.isLoading = false;
      if (response) {
        this.activeModal.close({
          isDeleted: true
        });
      }
    }
  }
}
