import { AlertModel } from '@wingstop/models/alert.model';
import { Basket } from '@wingstop/models/basket.model';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { Location } from '@wingstop/models/location/location.model';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { IPilotProgram } from '@wingstop/models/pilot/pilot-program.model';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';

export class AppState implements AppState {
  errors: any;
  selectedLocation: Location;
  basket: Basket;
  itemsnottransferred: string[]; // after a basket transfer, this may be populated
  authentication: Authentication;

  addressesGuest: UserDeliveryAddress[]; // local storage address history (used for logged out users)

  openAlert: boolean;
  alertContent: AlertModel;
  logout: boolean;

  // CMS Offer redemption
  cmsOffer: CMSPromoOffer;
  cmsOfferRedeemCode: string;
  cmsOfferTimestamp: number;

  // User detection
  userFirstThreeMonths: boolean;
  userLocale: string;

  // Digital menu
  isDigitalMenu: boolean = false;

  // App banner
  appDownloadBannerEnabled: boolean = false;
  showAppBanner: boolean = false;
  appBannerClosedByUser: boolean = false;

  // Seo
  s3SeoMetadata: SeoMetadata[];

  // Pilot Program Feature Flags
  pilotProgram: IPilotProgram = {
    programEnabled: undefined,
    forceLogoutEnabled: undefined,
    redirectEnabled: undefined,
  };

  // Pilot Program User Data
  pilotProgramUser: IPilotProgramUser = {
    ngfeOrderSession: false,
    ngfeOrderSessionExpires: null,
  };
  lastForceLogoutDate: number;

  detailedBasketDeliveryAddress: UserDeliveryAddress = null;
}
