import { Injectable } from '@angular/core';
import * as Workflow from '../../../ecomm/types/workflow';
import { EmailDomainValidationService } from '../../services/email-domain-validation/email-domain-validation.service';

@Injectable({ providedIn: 'root' })
export class EmailDomainValidationWorkflowService {
	constructor(
		private emailDomainValidationService: EmailDomainValidationService
	) { }

	public getEmailDomainValidations = () =>
		Workflow.createWorkflow(
			undefined,
			() => this.emailDomainValidationService.getEmailDomainValidations()
		)()
}