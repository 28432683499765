import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, filter, findIndex } from 'lodash';

import { CartItem } from '../../../../ecomm/types/cart.types';
import {
  MenuItem,
  MenuModifierGroup,
  MenuModifierGroupItem
} from '../../../../ecomm/types/store-info.types';
import {
  removeModGroupsWithEmptyModifiers,
  selectedModifierGroupsForPayload,
  totalBasketPrice
} from '../../../../ecomm/utils/pdp-utils';
import {
  ModGroups,
  OnlyModifiersOfTypePipe,
  PushOrReplacePipe,
  SortPipe
} from '../../../common';

@Component({
  selector: 'wri-mod-groups',
  templateUrl: './mod-groups.component.html',
  styleUrls: ['./mod-groups.component.scss']
})
export class ModGroupsComponent implements AfterViewChecked {
  @Input() public modifierGroups!: MenuModifierGroup[];
  @Input() public selectedLineItem!: CartItem;
  @Input() public startPDPValidations = false;
  @Input() public itemQuantity = 1;
  @Output()
  failedModifierGroups = new EventEmitter<MenuModifierGroup>();
  @Output()
  resetPDPValidationsEmit = new EventEmitter();
  @Output()
  valuesChanged = new EventEmitter<{
    totalPrice: number;
    selectedModifierGroups: ModGroups[];
  }>();

  public sortedModifierGroups = cloneDeep(this.modifierGroups);

  private selectedModifierGroups: ModGroups[] = [];

  constructor(private cdr: ChangeDetectorRef, public modalService: NgbModal) {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getCustomStructureForSortedModGroups(): MenuModifierGroup[] {
    const modGroupsWithoutSpecialRequest = filter(
      this.modifierGroups,
      (currentObject) => currentObject.type !== 'special-request'
    );
    // special treatment for group with type = 'special-request', club all of them together
    const specialRequestModGroups = filter(this.modifierGroups, [
      'type',
      'special-request'
    ]);
    if (specialRequestModGroups.length > 0) {
      const mergedSpecialRequestModGroup = {
        ...specialRequestModGroups[0],
        // add this extra property to keep all special req type modGroups together
        consolidatedModGroups: specialRequestModGroups,
        sortOrder: specialRequestModGroups[0].sortOrder, // consider sortOrder of first special-request
        type: 'special-request'
      };
      modGroupsWithoutSpecialRequest.push(mergedSpecialRequestModGroup);
    }
    return new SortPipe().transform(
      modGroupsWithoutSpecialRequest,
      'asc',
      'sortOrder'
    );
  }

  isModGroupFirstAndRequired(index: number, modifierGroup: MenuModifierGroup) {
    return index === 0 && modifierGroup?.required;
  }

  filteredAddOnModifiers(): MenuModifierGroup[] {
    const addonModGroups = new OnlyModifiersOfTypePipe().transform(
      this.modifierGroups,
      'add-on'
    );
    const filteredModifierGroups: MenuModifierGroup[] = [];
    addonModGroups.forEach((modGroup) => {
      const filteredModElements: MenuModifierGroupItem[] = [];
      modGroup.modifierGroupElements.forEach((modGroupElement) => {
        const item: MenuItem | null | undefined = modGroupElement.item;

        if (
          (item === null ||
            item === undefined ||
            item.outOfStock === undefined ||
            !item.outOfStock) &&
          item?.availableInSchedule
        ) {
          item.modifierGroups?.forEach((itemModGrp) => {
            // filter out flavors with availableInSchedule: false
            itemModGrp.modifierGroupElements =
              itemModGrp.modifierGroupElements.filter(
                (el) => el.availableInSchedule
              );
          });
          filteredModElements.push(modGroupElement);
        }
      });
      const newModGroup = { ...modGroup };
      newModGroup.modifierGroupElements = [...filteredModElements];
      if (filteredModElements.length > 0) {
        filteredModifierGroups.push(newModGroup);
      }
    });
    return filteredModifierGroups;
  }

  failedModifierGroupsEmit(value: boolean, modifierGroup: MenuModifierGroup) {
    if (!value) {
      this.failedModifierGroups.emit(modifierGroup);
    }
  }

  resetPDPValidations() {
    this.resetPDPValidationsEmit.emit();
  }

  /** To handle modifiers of type standard, flavor */
  handleModifierGroupData(
    values: Partial<ModGroups>,
    modifierGroup: MenuModifierGroup
  ): void {
    if (values.modifiers) {
      /** if modifierGroup already exists in selectedModifierGroups array,
       * then replace, else push*/
      this.selectedModifierGroups = new PushOrReplacePipe().transform(
        this.selectedModifierGroups,
        'modifierGroupId',
        modifierGroup.id,
        'modifiers',
        // objToPush
        {
          modifierGroupId: modifierGroup.id,
          ...values
        }
      );
    }
    this.valuesChanged.emit({
      totalPrice: totalBasketPrice(this.selectedModifierGroups),
      selectedModifierGroups: selectedModifierGroupsForPayload(
        this.selectedModifierGroups
      )
    });
  }

  /** To handle modifiers of type add-on */
  handleAddonModifierGroupsData(values: {
    modifierGroupId: string;
    totalPrice: number;
    selectedModifierGroups: ModGroups[];
  }): void {
    const { modifierGroupId, selectedModifierGroups } = values;
    /** if modifierGroup already exists in selectedModifierGroups array,
     * then replace, else push*/
    if (selectedModifierGroups.length > 0) {
      selectedModifierGroups.forEach((selectedModifierGroup) => {
        if (selectedModifierGroup.modifiers.length > 0) {
          this.selectedModifierGroups = new PushOrReplacePipe().transform(
            this.selectedModifierGroups,
            'modifierGroupId',
            selectedModifierGroup.modifierGroupId,
            'modifiers',
            // objToPush
            {
              modifierGroupId: selectedModifierGroup.modifierGroupId,
              modifiers: selectedModifierGroup.modifiers
            }
          );
        } else {
          const index = findIndex(this.selectedModifierGroups, [
            'modifierGroupId',
            selectedModifierGroup.modifierGroupId
          ]);
          if (index > -1) this.selectedModifierGroups.splice(index, 1);
        }
      });
    } else {
      const index = findIndex(this.selectedModifierGroups, [
        'modifierGroupId',
        modifierGroupId
      ]);
      if (index > -1) this.selectedModifierGroups.splice(index, 1);
    }
    removeModGroupsWithEmptyModifiers(this.selectedModifierGroups);
    this.valuesChanged.emit({
      totalPrice: totalBasketPrice(this.selectedModifierGroups),
      selectedModifierGroups: selectedModifierGroupsForPayload(
        this.selectedModifierGroups
      )
    });
  }

  /** To handle modifiers of type special-request */
  handleSpecialRequestsModifierGroupsData(values: {
    selectedModifierGroups: ModGroups[];
  }): void {
    const { selectedModifierGroups } = values;
    let selectedModifierGroupsWithSpecialRequests = cloneDeep(
      this.selectedModifierGroups
    );
    /** Remove all modifier groups of type special requests from payload obj */
    const specialReqModifierGroups = new OnlyModifiersOfTypePipe().transform(
      this.modifierGroups,
      'special-request'
    );
    selectedModifierGroupsWithSpecialRequests =
      selectedModifierGroupsWithSpecialRequests.filter(
        (modgroup) =>
          !specialReqModifierGroups.some(
            (specialmodgroup) => specialmodgroup.id === modgroup.modifierGroupId
          )
      );
    /** if modifierGroup already exists in selectedModifierGroups array,
     * then replace, else push*/
    if (selectedModifierGroups.length > 0) {
      selectedModifierGroups.forEach((specialReqModGroup) => {
        selectedModifierGroupsWithSpecialRequests =
          new PushOrReplacePipe().transform(
            selectedModifierGroupsWithSpecialRequests,
            'modifierGroupId',
            specialReqModGroup.modifierGroupId,
            'modifiers',
            // objToPush
            {
              modifierGroupId: specialReqModGroup.modifierGroupId,
              modifiers: specialReqModGroup.modifiers
            }
          );
      });
    }

    this.selectedModifierGroups = selectedModifierGroupsWithSpecialRequests;
    this.valuesChanged.emit({
      totalPrice: totalBasketPrice(selectedModifierGroupsWithSpecialRequests),
      selectedModifierGroups: selectedModifierGroupsForPayload(
        selectedModifierGroupsWithSpecialRequests
      )
    });
  }
}
