import { InjectionToken, ValueProvider } from '@angular/core';
import _mapboxgl from 'mapbox-gl';

export const mapboxgl = _mapboxgl;
export const MAPBOX = new InjectionToken<typeof _mapboxgl>('MAPBOX');

export class MapboxProvider {
  public static get = (): ValueProvider => ({
    provide: MAPBOX,
    useValue: _mapboxgl
  });
}
