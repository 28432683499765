import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { sum } from 'lodash';

import type { MenuModifier } from '../../../../ecomm/types/store-info.types';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import {
  FlavorQuantitySelectionsType,
  FlavorSelectionService,
  Modifiers
} from '../../../common';

@Component({
  selector: 'wri-group-flavors',
  templateUrl: './group-flavors.component.html',
  styleUrls: ['./group-flavors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GroupFlavorsComponent)
    }
  ]
})
export class GroupFlavorsComponent implements AfterContentInit {
  @Input() flavor: MenuModifier[] = [];

  @Input() selectedModifiers: Modifiers[] = [];

  @Input() maxSelectionsAllowed = 0;

  @Input() quantitiesEnabled = true;

  @Input() aggregateQuantity = 0;

  @Input() minModifierQuantity = 0;

  @Input() maxModifierQuantity = 0;

  @Input() numberOfFlavorsInGroup = 0;

  @Output() flavorQuantitySelections = new EventEmitter<{
    systemDefined: number;
    userDefined: number;
  }>();

  @Output()
  resetPDPValidations = new EventEmitter();

  constructor(
    private flavorSelectionService: FlavorSelectionService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) {
    this.isPDPEditable = this.route.snapshot.queryParamMap.get('lineItemId');
  }

  ngAfterContentInit(): void {
    this.setDefaultValues();
  }

  /** Implement ControlValueAccessor */
  disabled = false;
  touched = false;
  checkedModElementIds: string[] = [];
  private editableFlavors = 0;
  public isPDPEditable: string | null = null;

  /** Implement ControlValueAccessor */
  set value(value: number | Modifiers[]) {
    this.onChange(value);
  }

  // setting flavorModGroupElements to checked if isDefault : true
  private setDefaultValues(): void {
    this.flavor.forEach((flavorModGroupElement: MenuModifier) => {
      if (
        flavorModGroupElement.isDefault &&
        !flavorModGroupElement?.outOfStock
      ) {
        this.onChangeModifierElement(
          flavorModGroupElement?.isDefault,
          this.maxSelectionsAllowed > 1 ? 'multiple' : 'single',
          flavorModGroupElement
        );
      }
    });
  }

  getQuantity(id: string) {
    return this.selectedModifiers.find((ss) => ss.modifierId == id)?.quantity;
  }

  isChecked(id: string) {
    return this.selectedModifiers.some(
      (modifier) => modifier.modifierId === id
    );
  }

  private isModifierElementEligibleToBeChecked(id: string): boolean {
    if (this.maxSelectionsAllowed > 1) {
      const currentSelectedFlavorsQuantity = this.selectedModifiers.reduce(
        (a, b) => a + b.quantity,
        0
      );
      if (
        this.flavorSelectionService.getSystemDefinedModifiers(
          this.selectedModifiers
        ).length === 0
      ) {
        if (currentSelectedFlavorsQuantity === this.aggregateQuantity)
          return false;
        if (
          currentSelectedFlavorsQuantity <= this.aggregateQuantity &&
          this.getMaxAvailableToAssign(id) < this.minModifierQuantity
        )
          return false;
      } else {
        if (this.getMaxAvailableToAssign(id) < this.minModifierQuantity)
          return false;
      }
    }
    return true;
  }

  updateCheckedModElements() {
    this.checkedModElementIds = [];
    for (let i = 0; i < this.selectedModifiers.length; i++) {
      this.checkedModElementIds.push(this.selectedModifiers[i].modifierId);
    }
  }

  onChangeModifierElement(
    checked: boolean,
    type: string,
    modifierData: MenuModifier
  ): void {
    //whenever a modifier is selected - reset validations
    this.resetPDPValidations.emit();
    if (checked) {
      if (this.quantitiesEnabled) {
        // if quantitiesEnabled: true, do validation logic check
        if (this.isModifierElementEligibleToBeChecked(modifierData.id)) {
          if (type === 'multiple') {
            this.selectedModifiers.push({
              modifierId: modifierData.id,
              quantity: 0,
              price: modifierData.price,
              name: modifierData.name
            });
          } else {
            this.selectedModifiers = [
              {
                modifierId: modifierData.id,
                quantity: 0,
                price: modifierData.price,
                name: modifierData.name
              }
            ];
          }
        } else {
          this.notificationService.showError(
            `You have ${this.flavorSelectionService.pluralize(
              this.flavorSelectionService.totalNumberOfFlavorsSelected(
                this.selectedModifiers
              ),
              'flavor'
            )} selected
            and do not have any more wings available to split. Please reduce your wing amounts.`
          );
        }
      } else {
        // if quantitiesEnabled: false, skip validation logic & send quantity as 1
        if (type === 'multiple') {
          this.selectedModifiers.push({
            modifierId: modifierData.id,
            quantity: 1,
            price: modifierData.price,
            name: modifierData.name
          });
        } else {
          this.selectedModifiers = [
            {
              modifierId: modifierData.id,
              quantity: 1,
              price: modifierData.price,
              name: modifierData.name
            }
          ];
        }
      }
    } else {
      if (modifierData.quantity) {
        const modifierWithQuantityChangedIndex =
          this.selectedModifiers.findIndex(
            (modifier) => modifier.modifierId === modifierData.id
          );
        this.selectedModifiers[modifierWithQuantityChangedIndex].quantity = 0;
        this.selectedModifiers[modifierWithQuantityChangedIndex].touched =
          false;
      }
      this.selectedModifiers = this.selectedModifiers.filter(
        (mod) => mod.modifierId !== (modifierData ? modifierData.id : '')
      );
    }
    if (this.quantitiesEnabled) {
      if (modifierData.quantity && this.editableFlavors < this.numberOfFlavorsInGroup) {
        // not to rebalance if quantity values present (which means editable)
        const modifierWithQuantityChangedIndex =
          this.selectedModifiers.findIndex(
            (modifier) => modifier.modifierId === modifierData.id
          );
        if (modifierWithQuantityChangedIndex > -1) {
          this.selectedModifiers[modifierWithQuantityChangedIndex].quantity =
            modifierData.quantity;
        }
        this.editableFlavors++;
      } else {
        this.flavorSelectionService.rebalanceSystemDefinedIfNeeded(
          modifierData?.metadata?.['item-type'] || 'Wings',
          this.selectedModifiers,
          this.aggregateQuantity,
          this.maxModifierQuantity,
          this.minModifierQuantity
        );
      }
    }
    this.buildFlavorQuantitySelectionState();
    this.updateCheckedModElements();
    this.onTouched();
    this.onChange(this.selectedModifiers);
  }

  isItemDisabled(modifierItemId: string): boolean {
    return (
      this.selectedModifiers.length === this.maxSelectionsAllowed &&
      !this.selectedModifiers.some(
        (modifier) => modifier.modifierId === modifierItemId
      )
    );
  }

  onChange: (v: number | Modifiers[]) => void = () => void 0;
  onTouched: () => void = () => void 0;

  writeValue(newValue: number | Modifiers[]): void {
    this.value = newValue;
  }

  registerOnChange(
    onChange: (v: number | Modifiers[]) => Modifiers | undefined
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private buildFlavorQuantitySelectionState() {
    const flavorQuantitySelections =
      this.flavorSelectionService.updateFlavorQuantitySelections(
        this.selectedModifiers
      );
    this.emitFlavorState(flavorQuantitySelections);
  }

  private emitFlavorState(
    flavorQuantitySelections: FlavorQuantitySelectionsType
  ) {
    const sumOfAllSystemDefined = sum(
      Object.values(flavorQuantitySelections.systemDefined)
    );
    const sumOfAllUserDefined = sum(
      Object.values(flavorQuantitySelections.userDefined)
    );
    this.flavorQuantitySelections.emit({
      systemDefined: sumOfAllSystemDefined,
      userDefined: sumOfAllUserDefined
    });
  }

  getMaxAvailableToAssign(id: string): number {
    const current_flavor_quantity = this.getQuantity(id || '') || 0;
    const flavorQuantitySelections =
      this.flavorSelectionService.updateFlavorQuantitySelections(
        this.selectedModifiers
      );
    const sumOfAllSystemDefined = sum(
      Object.values(flavorQuantitySelections.systemDefined)
    );
    const sumOfAllUserDefined = sum(
      Object.values(flavorQuantitySelections.userDefined)
    );
    const any_unassigned_count =
      this.aggregateQuantity - (sumOfAllSystemDefined + sumOfAllUserDefined);
    const max_available_to_assign =
      current_flavor_quantity +
      any_unassigned_count +
      (sumOfAllSystemDefined -
        this.minModifierQuantity *
          Object.values(flavorQuantitySelections.systemDefined).length);
    return max_available_to_assign;
  }

  handleFlavorQuantityChange(
    values: { quantity: number; previousQuantity: number },
    modifierData: MenuModifier
  ) {
    this.resetPDPValidations.emit();
    const { quantity, previousQuantity } = values;
    const modifierWithQuantityChangedIndex = this.selectedModifiers.findIndex(
      (modifier) => modifier.modifierId === modifierData.id
    );

    this.selectedModifiers[modifierWithQuantityChangedIndex].quantity =
      quantity;
    this.selectedModifiers[modifierWithQuantityChangedIndex].touched = true;
    this.flavorSelectionService.rebalanceSystemDefinedIfNeeded(
      modifierData.metadata?.['item-type'] || 'Wings',
      this.selectedModifiers,
      this.aggregateQuantity,
      this.maxModifierQuantity,
      this.minModifierQuantity,
      this.getMaxAvailableToAssign(modifierData.id),
      quantity,
      previousQuantity,
      modifierData.id
    );
    this.buildFlavorQuantitySelectionState();
    this.onTouched();
    this.onChange(this.selectedModifiers);
    this.updateCheckedModElements();
  }
}
