import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(inputs: string[], glue = ','): string {
    return inputs.join(glue);
  }
}
