import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { MenuItemWithSlug } from '../../../common';
import { Router } from '@angular/router';
import { MenuItem } from '../../../../ecomm/types/store-info.types';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';

@Component({
  selector: 'wri-menu-recommendations',
  templateUrl: './menu-recommendations.component.html',
  styleUrls: ['./menu-recommendations.component.scss']
})
export class MenuRecommendationsComponent implements OnInit {
  @Input() sliderData: MenuItemWithSlug[] | undefined;
  private subscription = new Subscription();
  public storeInfoState: StoreInfoFeatureState | null = null;
  loading = true;

  // Slide config
  public slideConfig = {
    slidesToScroll: 1,
    accessibility: false,
    variableWidth: false,
    infinite: false,
    slidesToShow: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 3,
          arrows: true
        }
      }
    ]
  };

  constructor(
    private store: Store,
    private analyticsService: AnalyticsService,
    private router: Router,
    private asyncDispatcher: AsynchronousDispatcher
  ) {}

  ngOnInit() {
    const stateInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      stateInfo$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  afterChange() {
    this.analyticsService.logGaEvent({
      event: 'menu_featured_carousel'
    });
  }

  trackByItem(index: number, data: MenuItemWithSlug) {
    return `${index}-${data?.item?.id}`;
  }

  handleInit() {
    this.loading = false;
  }

  logGAEvent(item: MenuItem) {
    try {
      // Log menu GA event
      this.analyticsService.logGaEvent({
        event: 'menu_featured_product',
        product_name: item?.name || ''
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  getProductItemDetailsUrl(data: MenuItemWithSlug) {
    const locationSlug = this.storeInfoState?.storeInfo?.storeDetails?.slug;
    const { item, itemSlug, categorySlug, productSlug } = data;
    this.logGAEvent(item);
    this.asyncDispatcher.dispatch(
      StoreInfoFeature.actions.setState({
        selectedItem: item ?? null
      })
    );

    if (locationSlug) {
      const url = itemSlug
        ? `/location/${locationSlug}/menu/${categorySlug}/${productSlug}/${itemSlug}`
        : `/location/${locationSlug}/menu/${categorySlug}/${productSlug}`;

      this.router.navigate([url], {
        state: { item: item, categorySlug: categorySlug }
      });
    } else {
      this.router.navigate(['/order'], {
        queryParams: {
          product: productSlug
        }
      });
    }
  }
}
