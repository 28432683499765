import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

import { RegexPatterns } from '../../../../ecomm/constants/regex-patterns';
import {
  ValidatePasswordPipe,
  emailValidator,
  formatToPhone,
  phoneValidator
} from '../../../common';
import { birthDateValidator } from '../../../common/form-validators/birthday.validator';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import { DOCUMENT } from '@angular/common';
import { ConfirmedValidator } from '../../../common/form-validators/confirm.validator';
import { PasswordRequirements } from '../../../../ecomm/types/password-requirements.types';

export type SignupFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  birthday: string;
};

export type SignupFormDataWithErrors = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  birthday: string;
  errors: Record<string, ValidationErrors | null>;
};

@Component({
  selector: 'wri-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit, OnChanges {
  @Input()
  canShowErrors = false;

  @Input()
  isFormValid = true;

  @Input()
  passwordRequirements = [];

  @Output()
  signUpFormData = new EventEmitter<SignupFormData>();

  initialValues: Partial<SignupFormData> = {};
  signupForm: UntypedFormGroup = new UntypedFormGroup({});
  public hidePassword = true;
  public hideConfirmPassword = true;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit() {
    this.signupForm = this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['passwordRequirements'] &&
      !changes['passwordRequirements'].isFirstChange()
    ) {
      this.updatePasswordValidators();
    }
  }

  private updatePasswordValidators(): void {
    const passwordControl = this.signupForm.get('password');
    if (passwordControl) {
      passwordControl.setValidators([
        this.checkPasswordValidation(this.passwordRequirements)
      ]);
      passwordControl.updateValueAndValidity();
    }
  }

  checkPasswordValidation(passwordRequirements: PasswordRequirements[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      let passedCases = 0;
      const password = control.value;

      if (!password) {
        return { required: true };
      }

      for (const req of passwordRequirements) {
        const passed = new ValidatePasswordPipe().transform(req, password);

        if (passed) {
          passedCases += 1;
        }
      }

      return passedCases == passwordRequirements.length
        ? null
        : { notMetReq: true };
    };
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group(
      {
        firstName: [
          this.initialValues.firstName ?? '',
          {
            validators: [
              Validators.required,
              Validators.pattern(RegexPatterns.firstName)
            ]
          }
        ],
        lastName: [
          this.initialValues.lastName ?? '',
          {
            validators: [
              Validators.required,
              Validators.pattern(RegexPatterns.lastName)
            ]
          }
        ],
        email: [
          this.initialValues.email ?? '',
          {
            validators: [Validators.required, emailValidator()]
          }
        ],
        phone: [
          this.initialValues.phone ?? '',
          {
            validators: [phoneValidator()]
          }
        ],
        password: [
          this.initialValues.password ?? '',
          {
            validators: [
              this.checkPasswordValidation(this.passwordRequirements)
            ]
          }
        ],
        confirmPassword: [
          this.initialValues.confirmPassword ?? '',
          {
            validators: [Validators.required]
          }
        ],
        birthday: [
          this.initialValues.birthday ?? '',
          {
            validators: [birthDateValidator()]
          }
        ]
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword')
      }
    );
  }

  private scrollToFirstInvalidControl(
    formId: string,
    extraOffset: number
  ): void {
    const formElement = this._document
      .getElementById(formId)
      ?.querySelector('form');

    if (formElement) {
      const firstErrorElement = formElement.querySelector('.ng-invalid');
      if (firstErrorElement) {
        const headerOffset = 45 + extraOffset;
        const elementPosition =
          firstErrorElement?.getBoundingClientRect().top || 0;
        const offsetPosition =
          elementPosition + this._window.scrollY - headerOffset;
        this._window.scrollTo({
          behavior: 'smooth',
          top: offsetPosition
        });
      }
    }
  }

  public getPhoneFormatter() {
    return formatToPhone;
  }

  onKeyup(event: { target: { value: string }; key: string; which: number }) {
    const current = this.signupForm.value.birthday;
    if (event.target.value.length === 1) {
      if (event.target.value >= '2' && event.target.value <= '9') {
        const newVal = [0, event.target.value, '/'].join('');
        this.signupForm.controls['birthday'].setValue(newVal);
      }
    } else if (
      event.key !== 'Backspace' &&
      event.which !== 191 &&
      event.target.value.length === 2 &&
      current[1] !== '/'
    ) {
      const newVal = event.target.value + '/';
      this.signupForm.controls['birthday'].setValue(newVal);
    } else if (event.target.value.length === 2 && event.which === 191) {
      const newVal = event.target.value + '/';
      this.signupForm.controls['birthday'].setValue(newVal);
    } else if (event.target.value.length === 3 && current[2] !== '/') {
      const newVal =
        event.target.value.substr(0, 2) + '/' + event.target.value.substr(2);
      this.signupForm.controls['birthday'].setValue(newVal);
    }
  }

  handlePasswordIconClick() {
    this.hidePassword = !this.hidePassword;
  }

  handleConfirmPasswordIconClick() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  onSignUpButtonClick() {
    this.canShowErrors = true;
    if (this.signupForm.valid) {
      const updatedValues = {
        ...this.signupForm.value,
        phone: this.signupForm.value['phone'].replace(/\s/g, '')
      };
      this.signUpFormData.emit(updatedValues);
    } else {
      this.scrollToFirstInvalidControl('customer-sign-up-form', 60);
    }
  }
}
