import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';

import { StoreInfoService } from '../../../ecomm/services/store-info/store-info.service';
import { MenuItem, StoreInfo } from '../../../ecomm/types/store-info.types';
import { asyncTap } from '../../../ecomm/utils/async-tap';
import { MaybeResponse } from '../../../ecomm/types/maybe-response';
import * as Workflow from '../../../ecomm/types/workflow';
import { RegionalConfigurationService } from '../../services/regional-configuration/regional-configuration.service';
import { RegionalConfigurationFeature } from '../../../ecomm/store/features/regional-configuration';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { ReCaptchaService } from '../../../ecomm/utils/recaptcha/recaptcha.service';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { NotificationService } from '../../../ecomm/utils/notification/notification.service';
import { ActiveOfferFeature } from '../../store/features/active-offer';
import { SearchLocationService } from '../../../ecomm/services/search-location/search-location.service';
import { Cart } from '../../types/cart.types';

@Injectable({ providedIn: 'root' })
export class StoreInfoWorkflowService {
  private saveStoreInfoState = Workflow.onAny<StoreInfo>(
    asyncTap( (storeInfo) => {
      return this.asynchronusDispatcher.dispatch(
        StoreInfoFeature.actions.setState({
          storeInfo: storeInfo.data,
          error: storeInfo.error,
          selectedItem: null
        })
      )
    })
  );
  private saveStoreInfoAndMenuItemState = Workflow.onAny<
    [StoreInfo | undefined, MenuItem | undefined]
  >(
    asyncTap(({ data, error }) =>
      this.asynchronusDispatcher.dispatch(
        StoreInfoFeature.actions.setState({
          storeInfo: data && data[0] ? data[0] : undefined,
          selectedItem: data && data[1] ? data[1] : null,
          error
        })
      )
    )
  );
  private resetMismatchedCart = Workflow.onAny<StoreInfo>(
    asyncTap((storeInfo) =>
      firstValueFrom(
        this.store.select(CartFeature.selectCart).pipe(
          take(1),
          asyncTap(async (cart: Cart) => {
            if (cart && cart?.locationId !== storeInfo.data?.storeDetails.id) {
              return await this.asynchronusDispatcher.dispatch(
                CartFeature.actions.resetToDefault()
              );
            }
            return null;
          })
        )
      )
    )
  );
  private resetMismatchedCartForStoreInfoMenuItem = Workflow.onAny<
    [StoreInfo | undefined, MenuItem | undefined]
  >(
    asyncTap(({ data }) =>
      firstValueFrom(
        this.store.select(CartFeature.selectCart).pipe(
          take(1),
          asyncTap(async (cart: Cart) => {
            if (
              data &&
              data[0] &&
              cart &&
              cart?.locationId !== data[0].storeDetails.id
            ) {
              return await this.asynchronusDispatcher.dispatch(
                CartFeature.actions.resetToDefault()
              );
            }
            return null;
          })
        )
      )
    )
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public resetStoreInfoState = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(
        StoreInfoFeature.actions.resetToDefault()
      )
    );
  };

  constructor(
    private store: Store,
    private storeInfoService: StoreInfoService,
    private regionalConfigurationService: RegionalConfigurationService,
    private notificationService: NotificationService,
    private reCaptchaService: ReCaptchaService,
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    private searchLocationService: SearchLocationService
  ) { }

  public getStoreInfoBySlugAndMenuItem = (
    locationSlug: string,
    serviceMode: string,
    categorySlug: string | null,
    productSlug: string | null,
    itemSlug: string | null
  ) =>
    Workflow.createWorkflow(
      this.setStoreInfoStateLoading,
      this.fetchStoreAndMenuItem,
      this.saveStoreInfoAndMenuItemState,
      this.resetMismatchedCartForStoreInfoMenuItem
    )(locationSlug, serviceMode, categorySlug, productSlug, itemSlug).then(
      (data) => {
        return data && data[0] ? data[0] : null;
      }
    );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setStoreInfoStateLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(
        StoreInfoFeature.actions.setIsLoading()
      )
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setRegionalConfigurationStateLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(
        RegionalConfigurationFeature.actions.setIsLoading()
      )
    );
  };

  private reportErrors = <T>(showError = true) =>
    Workflow.onError<T>(
      tap((res) =>
        showError ? this.notificationService.showError(res.error) : null
      )
    );

  private reportErrorsWithCustomErrorHandling = <T>(
    showError = true,
    saveState: boolean
  ) =>
    Workflow.onError<T>(
      tap(async (res) => {
        showError ? this.notificationService.showError(res.error) : null;
        if (res.status === 404 && saveState) {
          await this.asynchronusDispatcher.dispatch(
            ActiveOfferFeature.actions.setState({
              activeOffer: null,
              activeOfferDetails: null
            })
          );
        }
      })
    );

  public getStoreInfoBySlug = Workflow.createWorkflow(
    this.setStoreInfoStateLoading,
    (locationSlug: string, serviceMode: string) =>
      this.storeInfoService.getStoreInfoBySlug(locationSlug, serviceMode),
    this.reportErrors(),
    this.saveStoreInfoState
  );

  public getStoreInfoById = Workflow.createWorkflow(
    this.setStoreInfoStateLoading,
    (
      locationId: string,
      serviceMode: string,
      callFulfillmentTimesAPI?: boolean
    ) =>
      this.storeInfoService.getStoreInfoById(
        locationId,
        serviceMode,
        callFulfillmentTimesAPI
      ),
    this.reportErrors(),
    this.saveStoreInfoState,
    this.resetMismatchedCart
  );

  public getStoreInfoWithoutSavingState = Workflow.createWorkflow(
    this.setStoreInfoStateLoading,
    (locationId: string, serviceMode: string) =>
      this.storeInfoService.getStoreInfoById(locationId, serviceMode),
    this.reportErrors()
  );

  public getStoreInfoBySlugWithoutSavingState = (
    locationId: string,
    serviceMode: string,
    showError = true
  ) =>
    Workflow.createWorkflow(
      this.setStoreInfoStateLoading,
      () => this.storeInfoService.getStoreInfoBySlug(locationId, serviceMode),
      this.reportErrors(showError)
    )();

  public getOfferDetails = (
    offerCode: string,
    storeId: string,
    saveState = true
  ) =>
    Workflow.createWorkflow(
      undefined,
      () =>
        from(this.reCaptchaService.execute('offer_details')).pipe(
          switchMap((recaptchaToken) =>
            this.storeInfoService.getOfferDetails(
              offerCode,
              storeId,
              recaptchaToken
            )
          )
        ),
      this.reportErrorsWithCustomErrorHandling(true, saveState)
    )();

  public getLocationDetailsUsingMenuLocationId = (
    slug: string,
    serviceMode: string
  ) =>
    Workflow.createWorkflow(undefined, () =>
      from(
        this.storeInfoService.getLocationDetailsUsingMenuLocationId(
          slug,
          serviceMode
        )
      ).pipe(
        switchMap(({ data, error }) => {
          if (data && data.storeDetails && data.storeDetails.id) {
            return this.searchLocationService.getLocationById({
              locationId: data.storeDetails.id
            });
          }
          return of({
            error: error
          });
        })
      )
    )();

  public getOfferDetailsWithErrorObject = (
    offerCode: string,
    storeId: string
  ) =>
    Workflow.createWorkflowWithBothDataAndError(undefined, () =>
      from(this.reCaptchaService.execute('offer_details')).pipe(
        switchMap((recaptchaToken) =>
          this.storeInfoService.getOfferDetails(
            offerCode,
            storeId,
            recaptchaToken
          )
        )
      )
    )();

  public getOfferDetailsByTimezoneWithErrorObject = (
    offerCode: string,
    timeZone?: string,
    saveState = true
  ) =>
    Workflow.createWorkflowWithBothDataAndError(
      undefined,
      () =>
        from(this.reCaptchaService.execute('offer_details')).pipe(
          switchMap((recaptchaToken) =>
            this.storeInfoService.getOfferDetailsByTimezone(
              offerCode,
              recaptchaToken,
              timeZone
            )
          )
        ),
      this.reportErrorsWithCustomErrorHandling(true, saveState)
    )();

  public getRegionalConfiguration = Workflow.createWorkflow(
    this.setRegionalConfigurationStateLoading,
    () =>
      this.regionalConfigurationService.getRegionalConfiguration(),
    this.reportErrors()
  );

  public getRegionalConfigurationAndSave = (
    showError = true
  ) =>
    Workflow.createWorkflow(
      this.setRegionalConfigurationStateLoading,
      () =>
        this.regionalConfigurationService.getRegionalConfiguration(),
      this.reportErrors(showError),
      Workflow.onAny(
        asyncTap(({ data, error }) => {
          return this.asynchronusDispatcher.dispatch(
            RegionalConfigurationFeature.actions.setState({
              regionalConfigurationOptions:
                data?.regionalConfigurationOptions ?? null,
              tipConfiguration: data?.tipConfiguration ?? null,
              featureFlags: data?.featureFlags ?? null,
              offlineDisplay: data?.offlineDisplay ?? null,
              isOffline: data?.isOffline ?? false,
              error: error
            })
          );
        })
      )
    )();

  private hasItemId(
    categorySlug: string | null,
    productSlug: string | null,
    itemSlug: string | null,
    storeInfo: StoreInfo
  ): boolean {
    const itemId = this.getItemIdFromSlugs(
      categorySlug,
      productSlug,
      itemSlug,
      storeInfo
    );

    return !!itemId;
  }

  private fetchStoreAndMenuItem = (
    locationSlug: string,
    serviceMode: string,
    categorySlug: string | null,
    productSlug: string | null,
    itemSlug: string | null
  ) =>
    this.storeInfoService.getStoreInfoBySlug(locationSlug, serviceMode).pipe(
      switchMap(
        (
          storeInfo
        ): Observable<
          MaybeResponse<[StoreInfo | undefined, MenuItem | undefined]>
        > => {
          return storeInfo.data &&
            this.hasItemId(categorySlug, productSlug, itemSlug, storeInfo.data)
            ? this.storeInfoService
              .getMenuItemInfo(
                this.getItemIdFromSlugs(
                  categorySlug,
                  productSlug,
                  itemSlug,
                  storeInfo.data
                ),
                storeInfo.data.storeDetails.id,
                serviceMode,
                storeInfo.data.storeDetails.timeZone
              )
              .pipe(
                map((menuItem) => ({
                  data: [storeInfo.data, menuItem.data],
                  error: menuItem.error ?? storeInfo.error
                }))
              )
            : of({
              data: [storeInfo.data, undefined],
              error: storeInfo.error
            });
        }
      )
    );

  private getItemIdFromSlugs(
    categorySlug: string | null,
    productSlug: string | null,
    itemSlug: string | null,
    storeInfo: StoreInfo
  ): string {
    if (!categorySlug || !productSlug) {
      return '';
    }
    const categories = storeInfo?.categories;
    const category = categories?.find((c) => c.slug == categorySlug);
    const product = category?.products.find((p) => p.slug === productSlug);
    if (!product) {
      return '';
    }
    // from item group
    if (product.itemGroup) {
      let item: MenuItem | undefined;
      if (itemSlug) {
        item = product.itemGroup.items.find((i) => i.slug === itemSlug);
      }
      return item?.id ?? '';
    }
    // from item
    if (product.item) {
      return product.item.id;
    }
    return '';
  }
}
