import { Component, Input } from '@angular/core';

@Component({
  selector: 'wri-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent {
  @Input() title?: string;
  @Input() content?: string;
}
