import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomerDeliveryAddress } from '../../../../ecomm/types/customer.types';
import { RemoveMyAddressModalComponent } from './remove-my-address-modal/remove-my-address-modal.component';

@Component({
  selector: 'wri-my-addresses',
  templateUrl: './my-addresses.component.html',
  styleUrls: ['./my-addresses.component.scss']
})
export class MyAddressesComponent {
  @Input() deliveryAddresses: CustomerDeliveryAddress[] | undefined;

  constructor(private modalService: NgbModal) {}

  async openDeleteConfirmationModal(delData: {
    deleteMyAddress: boolean;
    data: CustomerDeliveryAddress;
  }) {
    const { data } = delData;
    const modalRef = this.modalService.open(RemoveMyAddressModalComponent, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });

    modalRef.componentInstance.modalData = delData;

    const values = await modalRef.result;
    if (values) {
      const { isDeleted } = values;
      if (isDeleted) {
        this.deliveryAddresses =
          this.deliveryAddresses?.filter((address) => address.id !== data.id) ||
          [];
      }
    }
  }
}
