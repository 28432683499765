import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { OrderRepository } from '../../repositories/order/order.repository';
import * as MaybeResponse from '../../types/maybe-response';
import { OrderDto, OrderSubmitRequest } from '../../types/order.types';
import { fromDto } from '../../utils/from-dto';

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(private orderRepository: OrderRepository) {}

  public getByOrderId(
    orderId: string,
    fromConfirmationPage?: boolean
  ): Observable<MaybeResponse.MaybeResponse<OrderDto>> {
    return this.orderRepository
      .getByOrderId(orderId, fromConfirmationPage)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public submitOrder(
    request: OrderSubmitRequest
  ): Observable<MaybeResponse.MaybeResponse<OrderDto>> {
    return this.orderRepository
      .submitOrder(request)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public cancelOrder(
    orderId: string
  ): Observable<MaybeResponse.MaybeResponse<OrderDto>> {
    return this.orderRepository
      .cancelOrder(orderId)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }
}
