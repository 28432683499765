<div class="address-wrapper">
  <div>
    <div class="cart-location-handoff-mode">
      <div class="handoff-mode icon-wrapper">
        <span tabindex="0">{{ handoffModeLabel }}:</span>
        <div *ngIf="showIcon && customerState?.customer && !isDelivery()">
          <ng-container
            *ngIf="customerState?.customer | favLocation : orderLocationId"
          >
            <a
              data-testid="unfav-icon"
              wriFocusOutline
              role="button"
              aria-label="Location favorited"
              (click)="unFavoriteLocation()"
            >
              <wri-icon class="heart-icon" icon="wri-heart-filled"></wri-icon>
            </a>
          </ng-container>

          <ng-container
            *ngIf="!(customerState?.customer | favLocation : orderLocationId)"
          >
            <a
              data-testid="fav-icon"
              role="button"
              wriFocusOutline
              aria-label="Location unfavorited"
              (click)="onFavoriteLocation()"
            >
              <wri-icon class="heart-icon" icon="wri-heart"></wri-icon>
            </a>
          </ng-container>
        </div>
        <a
            *ngIf="!isDelivery() && showEditIcon"
            wriFocusOutline
            role="button"
            data-testid="edit-click"
            [attr.aria-label]="'Edit your ' + cart?.handoffMode +' location'"
            (click)="navigateToOrder(cart?.handoffMode)"
        >
          <wri-icon class="edit-icon-wrapper" icon="wri-edit"></wri-icon>
        </a>
      </div>
      <wri-address-link
        data-testid="address-link"
        [address]="cartAddress"
        [handOffMode]="cart?.handoffMode"
        [lines]="3"
        [showName]="true"
        [name]="cartLocationName"
      ></wri-address-link>

      <span
        data-testid="wri-addr-description"
        class="wri-address"
        *ngIf="!isDelivery() &&  cartAddress?.description"
      >
        {{ cartAddress.description }}
      </span>

      <wri-phone-number
        *ngIf="showPhoneNumber"
        [phoneNumber]="cartAddress?.phoneNumber"
      ></wri-phone-number>

      <div *ngIf="showErrorText && overrideReasonDescription" class="override-hrs-error-text" data-testid="override-hrs-desc">
        {{overrideReasonDescription}}
      </div>

      <div
        *ngIf="locationAvailability"
        class="cart-location-handoff-mode-store-hours"
      >
        <wri-icon icon="wri-clock"></wri-icon>
        <span data-testid="location-availability">{{
          locationAvailability
        }}</span>
      </div>
    </div>
    <!-- To address (if delivery)-->
    <div *ngIf="isDelivery()">
      <div class="to-address-label icon-wrapper">
        <span tabindex="0">TO:</span>
        <a
            *ngIf="showEditIcon"
            wriFocusOutline
            role="button"
            [attr.aria-label]="'Edit your ' + cart?.handoffMode +' location'"
            data-testid="edit-click"
            (click)="navigateToOrder(cart?.handoffMode)"
        >
          <wri-icon class="edit-icon-wrapper" icon="wri-edit"></wri-icon>
        </a>
      </div>
      <span class="to-address-value" *ngFor="let line of toAddress; let last = last">
        {{ line }}<br *ngIf="!last" />
      </span>
    </div>
  </div>

</div>
