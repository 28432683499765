import { Address } from '../../../ecomm/types/address';
import { CartDeliveryAddress } from '../../../ecomm/types/cart.types';
import {
  HandoffMode,
  SelectedHandoffDeliveryAddress,
  SelectedHandoffMode
} from '../../../ecomm/types/selected-handoff-mode.types';

export const fromSelectedHandoffDeliveryAddress = (
  input: SelectedHandoffDeliveryAddress | null
): Address | null =>
  input
    ? {
        streetAddress: input.streetAddress,
        secondaryAddress: input.secondaryAddress,
        locality: input.locality || input.city || '',
        region: input.region || input.state || '',
        postalCode: input.postalCode,
        countryCode: input.country,
        latitude: input.latitude || 0,
        longitude: input.longitude || 0,
        deliveryInstructions: input.deliveryInstructions || ''
      }
    : null;

export const fromCartDeliveryAddress = (
  deliveryAddress: CartDeliveryAddress
): Address => ({
  streetAddress: deliveryAddress.streetAddress ?? '',
  secondaryAddress: deliveryAddress.secondaryAddress,
  locality: deliveryAddress.locality ?? '',
  region: deliveryAddress.region ?? '',
  postalCode: deliveryAddress.postalCode ?? '',
  countryCode: deliveryAddress.countryCode ?? '',
  latitude: deliveryAddress.latitude ?? 0,
  longitude: deliveryAddress.longitude ?? 0
});

export const handoffModesAreEqual = (
  a: SelectedHandoffMode,
  b: SelectedHandoffMode
): boolean => {
  if (a.handoffMode !== b.handoffMode) {
    return false;
  }

  if (a.handoffMode === HandoffMode.carryout) {
    return true;
  }

  if (
    a.handoffMode === HandoffMode.delivery &&
    a.address?.streetAddress === b.address?.streetAddress &&
    a.address?.secondaryAddress === b.address?.secondaryAddress &&
    a.address?.postalCode === b.address?.postalCode
  ) {
    return true;
  }

  return false;
};
