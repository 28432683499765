import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { AuthFeature } from '../../../ecomm/store/features/auth';
import { asyncTap } from '../../../ecomm/utils/async-tap';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';

export const unAuthenticatedGuard = async () => {
  const store = inject(Store);
  const redirectService = inject(RedirectService);

  return await firstValueFrom(
    store.pipe(
      select(AuthFeature.selectIsAuthenticated),
      asyncTap(async (isAuthenticated) => {
        if (!isAuthenticated) {
          await redirectService.redirectToHome();
        }
      })
    )
  );
};
