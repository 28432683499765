<div
  class="tax-and-fees-modal"
  role="dialog"
  aria-modal="true"
  aria-label="Tax & Fees"
>
  <div class="tax-and-fees-modal__header">
    <div class="tax-and-fees-modal__icon-wrapper">
      <a
        wriFocusOutline
        (click)="close($event)"
        href="#"
        [attr.aria-label]="'close modal'"
      >
        <wri-icon
          class="tax-and-fees-modal__cross_icon"
          icon="wri-cross"
        ></wri-icon>
      </a>
    </div>
  </div>
  <div class="tax-and-fees-modal__body">
    <div>
      <span
        data-testid="tax-and-fees-modal-title"
        class="tax-and-fees-modal__title"
      >
        Tax & Fees
      </span>
      <ul class="list-wrapper">
        <li
          data-testid="tax-and-fees-modal-label"
          *ngIf="modalData?.totalTax"
          class="tax-and-fees-modal__total_tax_wrapper"
        >
          <span class="tax-and-fees-modal__label">Tax: </span>
          <span class="tax-and-fees-modal__value">{{
            modalData?.totalTax | currency
          }}</span>
        </li>
        <li
          *ngFor="let fee of modalData?.fees"
          class="tax-and-fees-modal__line-item"
        >
          <ng-container *ngIf="fee.category !== 'delivery'">
            <div data-testid="tax-and-fees-modal-fee-label">
              <span class="tax-and-fees-modal__label"> {{ fee.name }}: </span>
              <span class="tax-and-fees-modal__value">{{
                fee.amount | currency
              }}</span>
            </div>
            <span
              *ngIf="fee.description"
              data-testid="tax-and-fees-modal-description"
              class="tax-and-fees-modal__description"
            >
              {{ fee.description }}
            </span>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
