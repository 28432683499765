import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { find } from 'lodash';

import { CartItem } from '../../../../ecomm/types/cart.types';
import { MenuModifierGroup } from '../../../../ecomm/types/store-info.types';
import {
  ModGroups,
  ModifierModalElementChangeService,
  Modifiers,
  OnlyModifiersOfTypePipe
} from '../../../common';
import { ModifierModalComponent } from '../modifier-modal/modifier-modal.component';

@Component({
  selector: 'wri-special-requests',
  templateUrl: './special-requests.component.html',
  styleUrls: ['./special-requests.component.scss']
})
export class SpecialRequestsComponent implements OnInit {
  @Input() public modifierGroups!: MenuModifierGroup[];
  @Input() public selectedLineItem!: CartItem;
  @Output()
  valuesChanged = new EventEmitter<{
    selectedModifierGroups: ModGroups[];
  }>();

  public selectedModifierGroups: ModGroups[] = [];
  selectedSpecialRequestModifiers: Modifiers[] = [];
  private hasSelectedModifierElementsChanged = false;

  constructor(
    public modalService: NgbModal,
    private modifierModalElementChangeService: ModifierModalElementChangeService
  ) {}

  ngOnInit(): void {
    this.getDefaultSelectedValue();
  }

  public updateSpecialReqWithCart(
    specialReqModifierGroups: MenuModifierGroup[]
  ) {
    if (this.selectedLineItem) {
      specialReqModifierGroups.forEach((specialReqModifierGroup) => {
        const hasSpcReqModGrpInCart = find(
          this.selectedLineItem.modifierGroups,
          ['modifierGroupId', specialReqModifierGroup.id]
        );
        if (hasSpcReqModGrpInCart) {
          specialReqModifierGroup.modifierGroupElements.forEach(
            (modifierGroupElement) => {
              if (modifierGroupElement?.modifier) {
                const hasModInCart = find(hasSpcReqModGrpInCart.modifiers, [
                  'modifierId',
                  modifierGroupElement.modifier.id
                ]);
                if (hasModInCart) {
                  modifierGroupElement.modifier.isDefault = true;
                } else {
                  modifierGroupElement.modifier.isDefault = false;
                }
              }
            }
          );
        } else {
          specialReqModifierGroup.modifierGroupElements.forEach(
            (modifierGroupElement) => {
              if (modifierGroupElement?.modifier) {
                modifierGroupElement.modifier.isDefault = false;
              }
            }
          );
        }
      });
    }
    return specialReqModifierGroups;
  }

  private getDefaultSelectedValue() {
    const specialReqModifierGroups = new OnlyModifiersOfTypePipe().transform(
      this.modifierGroups,
      'special-request'
    );
    this.updateSpecialReqWithCart(specialReqModifierGroups);
    specialReqModifierGroups.forEach((specialReqModifierGroup) => {
      specialReqModifierGroup.modifierGroupElements.forEach(
        (modifierGroupElement) => {
          if (
            modifierGroupElement?.modifier?.isDefault &&
            !modifierGroupElement?.modifier?.outOfStock
          ) {
            this.modifierModalElementChangeService.onChangeModalModifierElement(
              this.selectedModifierGroups, // this variable will be updated with default selected modifiers
              true,
              specialReqModifierGroup.maxSelectionsAllowed > 1
                ? 'multiple'
                : 'single',
              specialReqModifierGroup.id,
              modifierGroupElement.modifier
            );
          }
        }
      );
    });
    this.valuesChanged.emit({
      selectedModifierGroups: this.selectedModifierGroups
    });
  }

  async openModifierModal() {
    const modalRef = this.modalService.open(ModifierModalComponent, {
      windowClass: 'modifier-common-modal',
      centered: true,
      scrollable: true,
      modalDialogClass: 'modal-fullscreen-md-down'
    });
    const specialReqModifierGroups = new OnlyModifiersOfTypePipe().transform(
      this.modifierGroups,
      'special-request'
    );
    modalRef.componentInstance.data = this.updateSpecialReqWithCart(
      specialReqModifierGroups
    );
    modalRef.componentInstance.selectedItemModifierGroups =
      this.selectedModifierGroups;
    modalRef.componentInstance.hasSelectedModifierElementsChanged =
      this.hasSelectedModifierElementsChanged;

    const values = await modalRef.result;
    if (values) {
      const { itemModifierGroups } = values;
      this.hasSelectedModifierElementsChanged = true;
      this.selectedModifierGroups = itemModifierGroups;
      this.valuesChanged.emit({
        selectedModifierGroups: this.selectedModifierGroups
      });
    }
  }
}
