import * as Either from 'fp-ts/Either';
import * as t from 'io-ts';
import * as _ from 'lodash/fp';
import { Observable, of } from 'rxjs';
import * as rx from 'rxjs/operators';

import {
  EcommWebSocketResponse,
  PreviouslyCaught
} from '../types/common.types';
import { MaybeResponse } from '../types/maybe-response';
import {
  handleValidatedResponse,
  handleDecodeError,
} from './throw-error';

export const handleWebSocketResponse =
  <TYPES extends string, DATA>(
    codec: t.Type<EcommWebSocketResponse<TYPES, DATA>>,
    actions: TYPES[],
    decodeFailAction: TYPES,
    onClose: () => void,
    enableDevLogs = false
  ) =>
  (
    input$: Observable<EcommWebSocketResponse<TYPES, DATA>>
  ): Observable<MaybeResponse<DATA>> =>
    input$.pipe(
      rx.catchError(
        _.pipe(
          codec.decode,
          Either.fold(
            (decodeErr: t.Errors) =>
              handleDecodeError(decodeFailAction)(decodeErr),
            handleValidatedResponse(enableDevLogs)
          ),
          PreviouslyCaught.map,
          _.tap(onClose),
          of
        )
      ),
      rx.map((value) => {
        return _.pipe(
          PreviouslyCaught.fold(
            _.identity,
            _.pipe(
              codec.decode,
              Either.fold(
                (decodeErr: t.Errors) =>
                  handleDecodeError(decodeFailAction)(decodeErr),
                (validValue) =>
                  handleValidatedResponse(enableDevLogs)(validValue)
              )
            )
          )
        )(value);
      }),
      rx.filter((res: MaybeResponse<DATA>) =>
        actions.includes(res.type as unknown as TYPES)
      ),
      rx.take(1),
      rx.tap(onClose)
    );
