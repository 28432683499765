<wri-feature-flags [with]="['enableVenmo']">
  <button
    #venmo
    *ngIf="isPaymentMethodSupported"
    class="btn-primary venmo-button venmo-payment-method"
    [attr.data-testid]="methodSupported ? 'venmo-payment-method' : ''"
    aria-label="Venmo"
    (click)="onButtonClick()"
  >
    <svg
      id="venmo-button"
      height="18px"
      viewBox="0 0 126 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 46.1 (44463) - http://www.bohemiancoding.com/sketch -->
      <title>white_logosvg/</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="white_logo" fill="#FFFFFF">
          <g id="Group">
            <path
              d="M21.2033583,0.0964593301 C22.0495843,1.49403336 22.4310921,2.93353679 22.4310921,4.75192551 C22.4310921,10.5516514 17.4799637,18.0859072 13.4615306,23.3763973 L4.28318255,23.3763973 L0.602153567,1.36628993 L8.63880252,0.603305315 L10.5850138,16.2649114 C12.4034764,13.3024803 14.6475506,8.6470141 14.6475506,5.47298073 C14.6475506,3.73562654 14.349905,2.55226174 13.8847522,1.57789215 L21.2033583,0.0964593301 L21.2033583,0.0964593301 Z"
              id="Shape"
            />
            <path
              d="M31.6192168,9.7884495 C33.0981028,9.7884495 36.8212801,9.11193069 36.8212801,6.99590844 C36.8212801,5.97982672 36.1028027,5.47298073 35.2561421,5.47298073 C33.7748663,5.47298073 31.8310449,7.24922281 31.6192168,9.7884495 L31.6192168,9.7884495 Z M31.4497544,13.9792163 C31.4497544,16.5629794 32.8864919,17.5766714 34.7912066,17.5766714 C36.8653838,17.5766714 38.8513536,17.0698254 41.4326146,15.7580654 L40.460378,22.359881 C38.6416981,23.2484366 35.8073297,23.8413138 33.0561717,23.8413138 C26.0775769,23.8413138 23.5799608,19.6092694 23.5799608,14.318562 C23.5799608,7.46125954 27.6427148,0.180100866 36.0187232,0.180100866 C40.630275,0.180100866 43.2089289,2.76364671 43.2089289,6.36110177 C43.2093634,12.1606104 35.7656158,13.937287 31.4497544,13.9792163 L31.4497544,13.9792163 Z"
              id="Shape"
            />
            <path
              d="M66.4016058,5.26137851 C66.4016058,6.1077874 66.2732054,7.33547136 66.1454568,8.13777835 L63.7338755,23.37618 L55.9084028,23.37618 L58.108156,9.4073917 C58.1498699,9.0285064 58.278053,8.26573904 58.278053,7.84253459 C58.278053,6.82645286 57.6432206,6.57292125 56.8799877,6.57292125 C55.8662545,6.57292125 54.8501315,7.03805509 54.1733679,7.377618 L51.6783589,23.3763973 L43.8087826,23.3763973 L47.403994,0.561810423 L54.2150817,0.561810423 L54.3013338,2.38280616 C55.9081856,1.32479503 58.0240766,0.180535368 61.0261694,0.180535368 C65.0035404,0.180100866 66.4016058,2.21248157 66.4016058,5.26137851 L66.4016058,5.26137851 Z"
              id="Shape"
            />
            <path
              d="M89.6331722,2.67761541 C91.874422,1.07082892 93.9907475,0.180100866 96.9087609,0.180100866 C100.926977,0.180100866 102.324608,2.21248157 102.324608,5.26137851 C102.324608,6.1077874 102.196642,7.33547136 102.068893,8.13777835 L99.6601361,23.37618 L91.8324909,23.37618 L94.0741752,9.11214794 C94.1154545,8.73087288 94.2021411,8.26573904 94.2021411,7.97071253 C94.2021411,6.82667012 93.5670913,6.57292125 92.8040757,6.57292125 C91.8320564,6.57292125 90.8604715,6.9961257 90.1391698,7.377618 L87.6445953,23.3763973 L79.8189054,23.3763973 L82.0605897,9.11236519 C82.101869,8.73109013 82.1859485,8.26595629 82.1859485,7.97092978 C82.1859485,6.82688737 81.5506815,6.5731385 80.790273,6.5731385 C79.7743672,6.5731385 78.7604168,7.03827234 78.0836532,7.37783525 L75.5866889,23.3766145 L67.7197197,23.3766145 L71.3144966,0.562027674 L78.0423739,0.562027674 L78.2537674,2.46666494 C79.8189054,1.32522954 81.9330583,0.180969869 84.7672095,0.180969869 C87.2211564,0.180100866 88.827791,1.23832924 89.6331722,2.67761541 L89.6331722,2.67761541 Z"
              id="Shape"
            />
            <path
              d="M117.898643,9.32375016 C117.898643,7.46125954 117.432838,6.19142894 116.038032,6.19142894 C112.949687,6.19142894 112.315506,11.6511574 112.315506,14.4441329 C112.315506,16.5629794 112.908408,17.8743049 114.30278,17.8743049 C117.221879,17.8743049 117.898643,12.1165085 117.898643,9.32375016 L117.898643,9.32375016 Z M104.36272,14.1069598 C104.36272,6.91226691 108.169107,0.180100866 116.926841,0.180100866 C123.525883,0.180100866 125.938116,4.07518945 125.938116,9.4514936 C125.938116,16.5627622 122.172791,23.9271279 113.203881,23.9271279 C106.562256,23.9271279 104.36272,19.5669055 104.36272,14.1069598 L104.36272,14.1069598 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </button>
</wri-feature-flags>
