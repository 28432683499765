
<a
  wriFocusOutline
  role="button"
  [attr.aria-label]="selectedModifierGroups.length === 0 ? 'Add Special Requests' : 'Edit Special Requests'"
  (click)="openModifierModal()">
  <div
    data-testid="special-requests-container"
    class="wri-special-requests-container menu-item-background"
  >
    <div class="wri-btn wri-btn-branded">
      <wri-icon
        [ngClass]="{
          'wri-edit-icon': selectedModifierGroups.length > 0
        }"
        icon="{{ selectedModifierGroups.length === 0 ? 'wri-plus' : 'wri-edit' }}">
      </wri-icon>
    </div>
    <div class="flex column-flex">
      <span>
        {{ selectedModifierGroups.length === 0 ? 'Add' : 'Edit' }} Special Requests
      </span>
      <div class="mod-special-selected-modifers">
        <ng-container *ngFor="let selectedModifierGroup of selectedModifierGroups, index as i">
          <ng-container *ngFor="let selectedSpecialRequestItemModifier of selectedModifierGroup.modifiers, index as j">
            <ng-container *ngIf="selectedSpecialRequestItemModifier.outOfStock" else showModifier>
              <wri-unavailable-item></wri-unavailable-item>
            </ng-container>
            <ng-container #showModifier>
              <ng-container
                *ngIf="(i < selectedModifierGroups.length && !(i === 0 && j === 0)) || (i >= selectedModifierGroups.length && j>0)">,
              </ng-container>
              {{selectedSpecialRequestItemModifier.name}}
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</a>