import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@wingstop/services/analytics.service.service';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-app-banner',
  templateUrl: './mobile-app-banner.component.html',
  styleUrls: ['./mobile-app-banner.component.scss'],
})
export class MobileAppBannerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public bannerEnabled$: Subject<boolean> = new Subject();
  public showAppBanner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public appBannerClosedByUser$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isIos: boolean = false;
  public isAndroid: boolean = false;
  public isSafari: boolean = false;

  constructor(
    private appStateSelectors: AppStateSelectors,
    private appStateActions: AppStateActions,
    private router: Router,
    private metaService: Meta,
    private analyticsService: AnalyticsService
  ) {
    this.subscriptions.push(
      this.appStateSelectors.showAppBanner.subscribe(
        (showAppBanner: boolean) => {
          this.showAppBanner$.next(showAppBanner);
        }
      )
    );

    this.subscriptions.push(
      this.appStateSelectors.appBannerClosedByUser.subscribe(
        (appBannerClosedByUser: boolean) => {
          this.appBannerClosedByUser$.next(appBannerClosedByUser);
        }
      )
    );

    this.subscriptions.push(
      this.appStateSelectors.appDownloadBannerEnabled.subscribe(
        (bannerEnabled: boolean) => {
          this.bannerEnabled$.next(bannerEnabled);
        }
      )
    );

    const routerEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    this.subscriptions.push(
      combineLatest([this.bannerEnabled$, routerEvents$]).subscribe(
        ([bannerEnabled, evt]) => {
          if (evt instanceof NavigationEnd) {
            if (this.appBannerClosedByUser$.value) {
              return;
            }

            // Show on home, global menu, location menu and location finder pages
            const isWhiteListedPage =
              evt.url === '/' ||
              evt.url.endsWith('/menu') ||
              evt.url.endsWith('/order');

            // Show the custom banner on Android and iOS/non-Safari browsers
            // Let Apple decide whether to show the iOS native smart banner on iOS Safari browsers
            if (bannerEnabled && isWhiteListedPage) {
              if (this.isAndroid || (this.isIos && !this.isSafari)) {
                this.openAppBanner();
              }
              this.addAppleMetaTag();
            } else {
              if (this.isAndroid || (this.isIos && !this.isSafari)) {
                this.closeAppBanner(false);
              }
              this.removeAppleMetaTag();
            }
          }
        }
      )
    );

    this.addAppleMetaTag();
  }

  ngOnInit(): void {
    this.setUserAgentProperties();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((s) => s.unsubscribe());
  }

  openAppBanner() {
    this.appStateActions.setShowAppBanner(true);
    const appContainer = document.querySelector<HTMLElement>('.app-container');
    if (appContainer) {
      appContainer.classList.add('app-banner');
    }

    this.analyticsService.logGaEvent({
      event: 'app_banner_download_view',
      source: this.analyticsService.formatPageUrl(
        this.router.routerState.snapshot.url
      ),
    });
  }

  closeAppBanner(closedByUser: boolean) {
    if (closedByUser) {
      this.appStateActions.setAppBannerClosedByUser(true);

      this.analyticsService.logGaEvent({
        event: 'app_banner_download_close',
        source: this.analyticsService.formatPageUrl(
          this.router.routerState.snapshot.url
        ),
      });
    }
    this.appStateActions.setShowAppBanner(false);
    const appContainer = document.querySelector<HTMLElement>('.app-container');
    if (appContainer) {
      appContainer.classList.remove('app-banner');
    }
  }

  onBannerDownloadClick() {
    this.analyticsService.logGaEvent({
      event: 'app_banner_download_tap',
      source: this.analyticsService.formatPageUrl(
        this.router.routerState.snapshot.url
      ),
    });
  }

  private addAppleMetaTag(): void {
    // <meta name="apple-itunes-app" content="app-id=myAppStoreID, app-argument=myURL">
    this.metaService.addTag({
      name: 'apple-itunes-app',
      content: 'app-id=454719277',
    });
  }

  private removeAppleMetaTag(): void {
    const tag = this.metaService.getTag('name=apple-itunes-app');
    this.metaService.removeTagElement(tag);
  }

  private setUserAgentProperties() {
    const ua = navigator.userAgent;
    this.isAndroid = /android/i.test(ua);
    this.isIos = /ip[honead]{2,4}\b(?:.*os ([\w]+) like mac|; opera)/i.test(ua);
    this.isSafari =
      /version\/([\w\.]+) .*mobile\/\w+ (safari)/i.test(ua) ||
      /version\/([\w\.]+) .*(mobile ?safari|safari)/i.test(ua);
  }
}
