<div class="flavor-card" [ngClass]="{'has-margin': !hasButton}">
  <img
    aria-live="polite"
    srcset="{{ flavor?.imageUrl | imageResolution: 'srcset':'flavors-page': '/assets/images/logo-green-product.png'}}"
    src="{{
      flavor?.imageUrl
        | imageResolution
          : 'src'
          : 'flavors-page'
          : '/assets/images/logo-green-product.png'
    }}"
    onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
    role="img"
    alt="{{ flavor?.name | prepend : 'image-for' }}"
  />
  <div class="flavor-card-name" data-testid="flavor-name">
    {{ flavor?.name }}
  </div>
  <wri-heat-scale [heatScale]="flavor?.heatScale"></wri-heat-scale>
  <div class="flavor-card-badges" *ngIf="hasButton">
    <wri-designation [isPopular]="flavor?.isPopular" [isNew]="flavor?.isNew">
    </wri-designation>
    <div class="flavor-card-badges-limited-time" *ngIf="flavor?.isLimitedTime">
      <wri-icon
        class="flavor-card-badges-limited-time-icon"
        icon="wri-limited-time"
      ></wri-icon>
      <span class="flavor-card-badges-limited-time-text">Limited Time</span>
    </div>
    <div
      *ngIf="!flavor?.isPopular && !flavor?.isNew && !flavor?.isLimitedTime"
      style="height: 16px"
    ></div>
  </div>

  <div class="flavor-card-desc" [ngClass]="{'has-margin': !hasButton}">
    {{ flavor?.description }}
  </div>

  <button
    *ngIf="hasButton"
    class="wri-btn wri-btn-primary"
    (click)="onOrderNow()"
    data-testid="order-now"
  >
    order now
  </button>
</div>
