import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { EcommFeatureFlags } from '../../../ecomm/config/ecomm-feature-flags.provider';
import {
  CONFIG,
  Config
} from '../../../ecomm/providers/config/config.provider';

export const featureFlagRouteGuard =
  (flag: keyof EcommFeatureFlags) =>
  async (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const config: Config = inject(CONFIG);
    if (!config.featureFlags[flag]) {
      window.location.href = `${config.legacy.webAppBaseUrl}${state.url}`;
      return false;
    }

    return true;
  };
