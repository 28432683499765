import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { RegexPatterns } from '../../../../ecomm/constants/regex-patterns';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { Cart } from '../../../../ecomm/types/cart.types';

export type DeliveryInstructionsFormData = {
  secondaryAddress: string;
  isContactlessDelivery: boolean;
  deliveryInstructions: string;
};

export type DeliveryInstructionsDataWithErrors = {
  secondaryAddress: string;
  isContactlessDelivery: boolean;
  deliveryInstructions: string;
  errors: Record<string, ValidationErrors | null>;
};

@Component({
  selector: 'wri-delivery-instructions-form',
  styleUrls: ['./delivery-instructions-form.component.scss'],
  templateUrl: './delivery-instructions-form.component.html'
})
export class DeliveryInstructionsFormComponent implements OnInit, OnDestroy {
  @Input()
  initialValues: Partial<DeliveryInstructionsFormData> = {};
  @Input() cart: Cart | null = null;

  @ViewChild('noContactDeliveryInfoModal')
  noContactDeliveryInfoModal!: TemplateRef<HTMLElement>;

  @Output()
  valuesChanged = new EventEmitter<DeliveryInstructionsDataWithErrors>();
  /**
   * @ignore
   */
  deliveryInstructionsForm: FormGroup = new FormGroup({});
  /**
   * @ignore
   */
  private subscription = new Subscription();
  public isLoading = false;
  public secondaryAddressValue: string | undefined;
  public deliveryInstructionsValue: string | undefined;

  /**
   * @ignore
   */
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private cartService: CartWorkflowService
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.deliveryInstructionsForm = this.createForm();
    this.handleFormUpdate(this.deliveryInstructionsForm.value);
    this.subscription.add(
      this.deliveryInstructionsForm.valueChanges.subscribe((values) =>
        this.handleFormUpdate(values)
      )
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * @ignore
   */
  private createForm(): FormGroup {
    return this.fb.group({
      secondaryAddress: [
        this.initialValues.secondaryAddress ?? '',
        {
          validators: [Validators.pattern(RegexPatterns.apartmentBuilding)]
        }
      ],
      deliveryInstructions: [
        this.initialValues.deliveryInstructions ?? '',
        {
          validators: [Validators.pattern(RegexPatterns.deliveryInstructions)]
        }
      ],
      isContactlessDelivery: [this.initialValues.isContactlessDelivery ?? true]
    });
  }

  /**
   * @ignore
   */
  private handleFormUpdate(values: DeliveryInstructionsFormData) {
    this.valuesChanged.emit({
      ...values,
      errors: this.getAllValidationErrors()
    });
  }

  /**
   * @ignore
   */
  private getAllValidationErrors(): Record<string, ValidationErrors | null> {
    return Object.entries(this.deliveryInstructionsForm.controls).reduce(
      (errors, [controlName, control]) => ({
        ...errors,
        ...(control.errors ? { [controlName]: control.errors } : {})
      }),
      {}
    );
  }

  openModal() {
    this.modalService.open(this.noContactDeliveryInfoModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  secondaryAddressFieldFocus() {
    this.secondaryAddressValue =
      this.deliveryInstructionsForm.value?.['secondaryAddress'];
  }

  deliveryInstructionsFieldFocus() {
    this.deliveryInstructionsValue =
      this.deliveryInstructionsForm.value?.['deliveryInstructions'];
  }

  async onBlurTextValueEmit(value: string, field: string) {
    if (
      value !== this.cart?.deliveryAddress?.secondaryAddress &&
      field === 'secondaryAddress'
    ) {
      this.analyticsService.logGaEvent({
        event: 'delivery_secondary_address_modified_checkout'
      });
    }
    if (value !== '' && field === 'deliveryInstructions') {
      this.analyticsService.logGaEvent({
        event: 'special_request'
      });
    }
  }
}
