import { InjectionToken, ValueProvider } from '@angular/core';

import { Config } from '../providers/config/config.provider';

export type EcommAPIConfig = Config['ecomm'];

export const ECOMM_API_CONFIG = new InjectionToken<EcommAPIConfig>(
  'ECOMM_API_CONFIG'
);

export class EcommAPIConfigProvider {
  public static get = (config: EcommAPIConfig): ValueProvider => ({
    provide: ECOMM_API_CONFIG,
    useValue: config
  });
}
