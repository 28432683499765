<div class="past-orders-container">
  <ng-container *ngFor="let order of pastOrders | sortPastOrder">
    <div class="past-order-card" [attr.data-testid]="order.displayNumber | prepend : 'past-order-'">
      <div class="flex past-order-card-title">
        <div class="past-order-card-title-details">
          <!-- Order scheduled time -->
          <div class="order-scheduled-time" data-testid="order-scheduled-time">
            {{ getOrderScheduledTime(order.orderDate, order.location.timeZone) }}
          </div>
          <!-- Handoff mode and Status tag-->
          <div class="handoff-mode-status-tag">
            <div class="handoff-mode" data-testid="handoff-mode">
              {{ getHandoffMode(order.handoffMode) }}
            </div>
          </div>
          <!-- Order carryout location or delivery address-->
          <div
            class="carryout-location-or-delivery-address"
            data-testid="carryout-location-or-delivery-address"
          >
            <!--delivery-->
            <ng-container *ngIf="order.handoffMode === 'Delivery'">
              <span *ngIf="order.orderDeliveryAddress.streetAddress">
                {{ order.orderDeliveryAddress.streetAddress }},
              </span>
              <span *ngIf="order.orderDeliveryAddress.secondaryAddress">
                {{ order.orderDeliveryAddress.secondaryAddress }},
              </span>
              <span *ngIf="order.orderDeliveryAddress.locality">
                {{ order.orderDeliveryAddress.locality }},
              </span>
              <span *ngIf="order.orderDeliveryAddress.region">
                {{ order.orderDeliveryAddress.region }},
              </span>
              <span *ngIf="order.orderDeliveryAddress.postalCode">
                {{ order.orderDeliveryAddress.postalCode }}
              </span>
            </ng-container>
            <!--Carryout-->
            <ng-container *ngIf="order.handoffMode === 'Carryout'">
              <span *ngIf="order.location.name"> {{ order.location.name }}</span>
            </ng-container>
          </div>

          <a
            wriFocusOutline
            data-testid="view-order-details"
            rel="noopener noreferrer"
            tabindex="0"
            role="link"
            (click)="navigateToDetailsPage(order.id)"
          >
            <div class="view-details">View Details</div>
          </a>

          <!-- Order Canceled -->
          <div *ngIf="order?.status==='cancelled'" class="order-cancelled-label" data-testid="order-cancelled-label">
            Canceled
          </div>
        </div>
        
      </div>

      <!--Product Name-->
      <div *ngIf="order.cart.items.length > 0" class="cart-items">
        <ng-container *ngFor="let item of order.cart.items">
          <div class="product-name" data-testid="product-name">
            {{ item.productName }}
          </div>
        </ng-container>
      </div>

      <div class="reorder-btn-wrapper">
        <wri-feature-flags [with]="['enableReorder']">
          <button
            wriOutline
            data-testid="reorder-btn"
            class="wri-btn wri-btn-primary"
            tabindex="0"
            (click)="onReorderClick(order.id, order.location.id, order?.handoffMode)"
          >
            <span *ngIf="reorderInProgress && selectedOrderId === order.id" class="spinner-wrapper">
              <wri-spinner spinnerColor="white"></wri-spinner>
            </span>
            <span *ngIf="!reorderInProgress || selectedOrderId !== order.id">reorder</span>
          </button>
        </wri-feature-flags>
      </div>
    </div>
  </ng-container>
</div>

<wri-reorder-confirmation-modal
  (reorder)="onReorderConfirmation($event)"
  [isLoading]="reorderInProgress"
></wri-reorder-confirmation-modal>
