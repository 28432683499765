// link to field glossary for more details
// https://github.com/wingstop-inc/ecomm-dev-docs/blob/main/nomnom_to_ecomm/processing_payments/fieldGlossary.md

import { BillingAccount } from '../basket/billing-account.model';

export class FiservAddress {
  streetaddress: string;
  city: string; // will get translated to locality
  country: string; // USA
  zipcode: string; // will get translated to postalCode
  isdefault: boolean; // will get translated to primary
  // note, Olo does not have a "state" concept -> OR, MO, TX, etc. may be able to pull it from underlying data though
  // state would get translated to region
}

export class FiservUser {
  // required
  /* oloCustomerId null for anonymous; no other required fields */
  oloCustomerId: string | null = null; // if this is left blank, we can assume that we're processing an anonymous guest and remaining fields will be empty
  fdCustomerId: string | null;
  // all other fields optional
  firstName?: string;
  lastName?: string;
  emailAddress?: string; // ecomm will wrap this in Fiserv nesting when submitting; will only ever be one from WS
  phoneNumber?: string; // this is how Olo represents number. Fiserv doesn't include a country code in docs
  addresses?: FiservAddress[]; // need to re-send all addresses if updating later on

  constructor(values: any) {
    this.initializeTruthyProps(values);
  }

  initializeTruthyProps(values: any) {
    Object.keys(values).forEach((prop) => {
      if (!!values[prop]) {
        this[prop] = values[prop];
      }
    });
  }
}

export interface FiservAccount {
  id: string;
  type: string;
  credit: {
    alias: string;
    cardType: string;
    expiryDate: {
      month: string;
      year: string;
    };
  };
}

export interface ErrorResponse {
  code: string;
  message: string;
  errors: {
    name: string;
    messages: string[];
  }[];
}

export enum FiservIFrameTypes {
  GUEST_USER_CARD = 'wingstop.guest.checkout',
  AUTH_USER_CARD = 'wingstop.checkout',
  AUTH_USER_ADD_CARD = 'wingstop.add.card',
}

export interface FiservIFrame {
  href: string; // https://api-int.payeezy.com/ucom/v1/hosted-pages/pages/d4067eb6-6761-4904-bdef-988336410654
  rel: string; // account.add.guest_checkout
  method: string; // GET
  version: string; // v2
}

export interface FiservToken {
  tokenId: string; // "xuZ0cRxMI7H0QxJAw1ZMTjHBaGNh",
  issuedOn: number; // 1621384691444,
  expiresInSeconds: number; // 1199,
  publicKey: string; // "<big key>",
  algorithm: string; // "RSA/NONE/PKCS1Padding",
  status: string; // "ACTIVE"
}

export interface FiservSessionSuccessResponse {
  oloCustomerId: string;
  fdCustomerId: string; // 32 char string and is the fdCustomerId, needed for the hosted pages flow
  accounts: FiservAccount[]; // empty if no saved cards
  hostedPages: FiservIFrame[];
  invoiceId: string;
  token: FiservToken;
}

export interface FiservSessionResponse {
  data: FiservSessionSuccessResponse | null;
  errors: ErrorResponse;
}

export interface FiservHostedPageResponse {
  type: string;
  token: {
    tokenType: string;
    tokenProvider: string;
    tokenId: string;
  };
  isSaveCard: boolean;
  credit?: {
    nameOnCard: string;
    cardType: string;
    alias: string;
    billingAddress: { postalCode: string };
  };
  cardType: string;
  code?: string;
  message?: string;
  category?: string;
  developerInfo?: {
    developerMessage?: string;
    fieldError?: [
      {
        code: number;
        field: string;
        message: string;
      }
    ];
  };
  billingAddress?: FiservBillingAddress;
}

export interface FiservGiftCard {
  type: string; // SVS
  requestedAmount: number;
  svsMerchantNumber: string;
  svsCardNumber: string;
  svsSecurityCode: string;
  svsStoreNumber: string;
}

export interface GiftCardSuccessResponse {
  balance: number;
  currency: string; // USD
  cardNumber: string;
  expirationDate: string; // yyyyMMdd
}

export interface FiservGiftCardResponse {
  data: {
    data: GiftCardSuccessResponse | null;
    error: ErrorResponse | null;
  };
}

export interface FiservBillingAddress {
  type: string;
  streetAddress: string;
  locality: string;
  region: string;
  postalCode: string;
  country: string; // US
  formatted: string;
  primary: boolean;
}

export interface UcomConfig {
  accessToken: string;
  env: string; // int/prod
  apiKey: string;
  fdCustomerId: string;
  pageUrl: string;
  pageId?: string; // GUID
  mountId: string;
  encryptionKey: string;
  redirectUrl: string;
  mobilePageFullView?: boolean;
  /* If ThreatMetrix feature should be enabled on HP then following
    params must be passed */
  orgId?: string;
  sessionId?: string;
  extraObjects?: {
    billingAddress?: FiservBillingAddress;
    /* Passing fd AccountId for preferred card selection in Vaulted
        cards use case */
    preferredCard?: {
      fdAccountId: string;
    };
  };
}

export interface TokenizedFiservPayment {
  fdCustomerId: string | null;
  fiservCardVault: boolean; // saveonfile
  fiservClientSessionToken: string;
  fiservMerchantId: string; // location extref
  fiservTokenId: string;
  fiservVaultedAccountId: string | null;
  requestedAmount: number; // amount
  type: string; // FISERV, SVS
  tokenType?: string;
  tokenProvider?: string;
  cardBrand?: string; // VISA
  lastFour?: string; // 1234
}

export interface CombinedFiservOloBillingAccount {
  oloBillingAccount?: Pick<
    BillingAccount,
    'amount' | 'tipportion' | 'billingmethod'
  >;
  fiservBillingAccount?: TokenizedFiservPayment | FiservGiftCard;
  invoiceId?: string;
}
export interface FiservPaymentDTO {
  oloCustomerId: null | string; // GUID
  oloLocationId: string; // hardcoded to 1111 in dev
  orderTotal: number; // must equal requestedAmount
  invoiceId: string; // used to track the transaction
  currencyCode: string; // USD
  currencyNumber: number; // hardcoded 840
  payments: TokenizedFiservPayment[];
}

export enum PaymentProviders {
  WWT_FISERV = 'WWT_FISERV',
  PCI_PROXY = 'PCI_PROXY',
}
