import { Pipe, PipeTransform } from '@angular/core';

import { MenuModifierGroup } from '../../../ecomm/types/store-info.types';

@Pipe({
  name: 'onlyModifiersOfId'
})
export class OnlyModifiersOfIdPipe implements PipeTransform {
  transform(
    modifiers: MenuModifierGroup[] = [],
    ...modIds: string[]
  ): MenuModifierGroup[] {
    return modifiers.filter((m) => modIds.includes(m.id));
  }
}
