import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, of, switchMap, throwError } from 'rxjs';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.callAPI(req, next);
  }

  private callAPI(
    originalRequest: HttpRequest<unknown>,
    next: HttpHandler,
    retriesLeft = 1
  ): Observable<HttpEvent<unknown>> {
    const reqWithAuthHeaders = from(this.addAuthHeaders(originalRequest));
    return reqWithAuthHeaders.pipe(
      switchMap((newReq) => next.handle(newReq)),
      catchError(
        this.handleErrors.bind(this)(originalRequest, next, retriesLeft)
      )
    );
  }

  private async addAuthHeaders(
    req: HttpRequest<unknown>
  ): Promise<HttpRequest<unknown>> {
    const authState = await this.authService.getCurrentAuth();
    return req.clone({
      setHeaders: {
        ...(authState?.isAuthenticated
          ? { Authorization: `Bearer ${authState.user?.access_token ?? ''}` }
          : {})
      }
    });
  }

  private handleErrors(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    retiresLeft: number
  ) {
    return (res: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
      if (res.status !== 401) {
        return throwError(() => res);
      }

      if (retiresLeft > 0) {
        return this.handle401WithRetry(req, next, retiresLeft);
      }

      if (retiresLeft === 0) {
        return this.handle401WithoutRetry(req, next);
      }

      return throwError(() => res);
    };
  }

  private handle401WithRetry(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    retriesLeft: number
  ) {
    console.debug('Auth token can be refreshed. Refreshing, and trying again');
    return from(this.authService.renewToken()).pipe(
      catchError(() => of(null)), // catch errors from refreshing
      switchMap(
        (newUser) =>
          newUser
            ? this.callAPI(req, next, retriesLeft - 1) // if new user, call api again
            : this.handle401WithoutRetry(req, next) // handle if could not be retried
      )
    );
  }

  private handle401WithoutRetry(req: HttpRequest<unknown>, next: HttpHandler) {
    console.debug(
      'Auth token could not be refreshed. Logging out, and trying again'
    );
    return from(this.authService.logout()).pipe(
      catchError(() => of(void 0)), // catch errors if logging out causes issues
      switchMap(() => this.callAPI(req, next, -1)) // call API
    );
  }
}
