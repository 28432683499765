import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxComponent } from '../../../../common';
import {
  ConditionalPaymentMethodLike,
  ExpressPaymentMethodLike,
  PaymentInfo,
  PaymentType
} from '../payment-method/payment-method.types';
import moment from 'moment-timezone';
import { FeatureFlagService } from '../../../../../ecomm/utils/feature-flag/feature-flag.service';
import {
  BRAIN_TREE,
  BrainTreeClient,
  BrainTreeDataCollector,
  BrainTreeSDK,
  BrainTreeVenmo
} from '../../../../../ecomm/providers/brain-tree/brain-tree.provider';
import {
  Config,
  CONFIG
} from '../../../../../ecomm/providers/config/config.provider';
import { NotificationService } from '../../../../../ecomm/utils/notification/notification.service';

@Component({
  selector: 'wri-venmo-payment-method',
  templateUrl: './venmo-payment-method.component.html',
  styleUrls: ['./venmo-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VenmoPaymentMethodComponent)
    }
  ]
})
export class VenmoPaymentMethodComponent
  extends CheckboxComponent
  implements ExpressPaymentMethodLike, ConditionalPaymentMethodLike, OnInit
{
  @Input() totalPrice = 0;
  @Input() expressPayPrerequisitesMet = false;
  @Input() isPaymentMethodSupported = false;

  @Output() expressPay = new EventEmitter<PaymentInfo>();
  @Output() paymentSupported = new EventEmitter<boolean>();
  @Output() expressPayClicked = new EventEmitter<string>();

  @HostBinding('class.method-supported')
  methodSupported = false;

  private readonly VENMO_ERROR_MSG =
    'There was an issue with Venmo. Please try again.';
  private readonly UPDATE_GUEST_INFO =
    'Please update the form before using Venmo.';

  constructor(
    @Inject(BRAIN_TREE)
    private brainTreeSdkProvider: Promise<BrainTreeSDK | undefined>,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
    @Inject(CONFIG) private config: Config
  ) {
    super();
  }

  private brainTreeClient?: BrainTreeClient;
  private brainTreeDataCollector?: BrainTreeDataCollector;
  private venmoClient?: BrainTreeVenmo;

  async ngOnInit(): Promise<void> {
    if (
      !this.featureFlagService.featureFlags.enableVenmo ||
      !this.brainTreeSdkProvider
    ) {
      this.paymentSupported.emit(false);
      return;
    }

    const brainTreeSdk = await this.brainTreeSdkProvider;
    if (!brainTreeSdk) {
      this.paymentSupported.emit(false);
      return;
    }
    try {
      this.brainTreeClient = await brainTreeSdk.client.create({
        authorization: this.config.brainTree.authorization,
        merchantAccountId: this.config.brainTree.authorization
      });
      if (!this.brainTreeClient) {
        this.paymentSupported.emit(false);
        return;
      }

      this.brainTreeDataCollector = await brainTreeSdk.dataCollector.create({
        client: this.brainTreeClient
      });
      if (!this.brainTreeDataCollector) {
        this.paymentSupported.emit(false);
        return;
      }

      this.venmoClient = await brainTreeSdk.venmo.create({
        client: this.brainTreeClient,
        allowDesktop: true,
        paymentMethodUsage: 'multi_use',
        allowNewBrowserTab: false,
        requireManualReturn: this.iOS()
      });

      if (!this.venmoClient) {
        this.paymentSupported.emit(false);
        return;
      }

      if (
        this.featureFlagService.featureFlags.enableVenmo &&
        this.isPaymentMethodSupported &&
        this.venmoClient.isBrowserSupported()
      ) {
        this.methodSupported = true;
        this.paymentSupported.emit(true);
      }
    } catch (err) {
      this.paymentSupported.emit(false);
      console.error(err);
      return;
    }
  }

  iOS() {
    const platform = navigator.userAgentData?.platform || navigator.platform;
    const iOSPlatforms = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];
    return (
      iOSPlatforms.includes(platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  async onButtonClick() {
    this.expressPayClicked.emit(PaymentType.venmo);
    if (!this.brainTreeDataCollector || !this.venmoClient) {
      this.notificationService.showError(this.VENMO_ERROR_MSG);
      return;
    }

    if (!this.expressPayPrerequisitesMet) {
      this.notificationService.showError(this.UPDATE_GUEST_INFO);
      return;
    }

    try {
      const captureTime = moment().format();
      const venmoToken = await this.venmoClient.tokenize();
      if (!venmoToken) {
        this.notificationService.showError(this.VENMO_ERROR_MSG);

        return;
      }
      this.writeValue(true);
      this.expressPay.emit({
        billingMethod: 'onlinePay',
        payments: [
          {
            type: 'venmo',
            requestedAmount: this.totalPrice,
            nonce: venmoToken.nonce,
            source: 'desktop',
            dataCapture: {
              rawData: this.brainTreeDataCollector.rawDeviceData.correlation_id,
              dataEventId:
                this.brainTreeDataCollector.rawDeviceData.correlation_id,
              captureTime: captureTime
            },
            dataDynamic: {
              captureTime: captureTime
            }
          }
        ]
      });
    } catch (err) {
      if (
        [
          'VENMO_CANCELED',
          'VENMO_APP_CANCELED',
          'VENMO_DESKTOP_CANCELED'
        ].includes(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (err as any)?.['code']
        )
      ) {
        return;
      }
      this.notificationService.showError(this.VENMO_ERROR_MSG);
    }
  }
}
