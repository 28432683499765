import { Injectable } from '@angular/core';
import * as Workflow from '../../../ecomm/types/workflow';
import { PasswordRequirementsService } from '../../services/password-requirements/password-requirements.service';

@Injectable({ providedIn: 'root' })
export class PasswordRequirementsWorkflowService {
  constructor(
    private passwordRequirementsService: PasswordRequirementsService
  ) {}

  public getPasswordRequirements = () =>
    Workflow.createWorkflow(
      undefined,
      () => this.passwordRequirementsService.getPasswordRequirements()
    )();
}
