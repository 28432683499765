export const levenshteinDistance = (s: string, t: string, caseSensitive = true): number => {
	// Handle case insensitivity
	if (!caseSensitive) {
		s = s.toLowerCase();
		t = t.toLowerCase();
	}

	// Early exits for trivial cases
	if (s === t) return 0;
	if (s.length === 0) return t.length;
	if (t.length === 0) return s.length;

	// Initialize distance vectors
	const v0: number[] = Array(t.length + 1).fill(0);
	const v1: number[] = Array(t.length + 1).fill(0);

	// Set initial values for the first row
	for (let i = 0; i <= t.length; i++) {
		v0[i] = i;
	}

	// Populate the distance matrix
	for (let i = 0; i < s.length; i++) {
		v1[0] = i + 1;

		for (let j = 0; j < t.length; j++) {
			const cost = s[i] === t[j] ? 0 : 1;
			v1[j + 1] = Math.min(
				v1[j] + 1,      // Deletion
				v0[j + 1] + 1,  // Insertion
				v0[j] + cost    // Substitution
			);
		}

		// Copy current row to previous row for next iteration
		for (let j = 0; j <= t.length; j++) {
			v0[j] = v1[j];
		}
	}

	// Return the bottom-right value, which is the distance
	return v1[t.length];
};