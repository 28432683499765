import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wriFocusOutline]'
})
export class FocusOutlineDirective {
  constructor(renderer: Renderer2, private el: ElementRef<HTMLElement>) {
    renderer.addClass(this.el.nativeElement, 'focus-outline');
    renderer.setAttribute(this.el.nativeElement, 'tabindex', '0');
  }

  @HostListener('keyup.enter', ['$event'])
  handleEnter(event: KeyboardEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    this.el.nativeElement.blur();
    this.el.nativeElement.click();
  }
}
