import { InjectionToken, ValueProvider } from '@angular/core';

export type EcommFeatureFlags = {
  enableApplePay: boolean;
  enableDevLogs: boolean;
  enableOrderRoute: boolean;
  enableVenmo: boolean;
  enablePaypal: boolean;
  enableGooglePay: boolean;
  enableGlobalMenu: boolean;
  enableReorder: boolean;
  enableHomePage: boolean;
  enableFlavorsPage: boolean;
  enableCancelOrder: boolean;
  enableSpanishTranslation: boolean;
  enableEatStoreMenuUrl: boolean;
  enableVaultedGiftCardPay: boolean;
  enableVaultedGiftCardView: boolean;
  enableVaultedGiftCardVault: boolean;
  enableVaultedCreditCardPay: boolean;
  enableVaultedCreditCardView: boolean;
  enableVaultedCreditCardVault: boolean;
  [x: string]: boolean;
};

export const ECOMM_FEATURE_FLAGS = new InjectionToken<EcommFeatureFlags>(
  'ECOMM_FEATURE_FLAGS'
);

export class EcommFeatureFlagsProvider {
  public static get = (flags: EcommFeatureFlags): ValueProvider => ({
    provide: ECOMM_FEATURE_FLAGS,
    useValue: flags
  });
}
