import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';

import { PathParams } from '../../../ecomm/constants/params';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import { asyncTap } from '../../../ecomm/utils/async-tap';

export const globalMenuGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot
) => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);

  return await firstValueFrom(
    store.pipe(
      select(StoreInfoFeature.selectStoreInfo),
      map((storeInfo) => storeInfo?.storeDetails?.slug),
      asyncTap(async (slug) => {
        if (slug) {
          const params = route.paramMap;
          const catSlug = params.get(PathParams.categorySlug);
          const productSlug = params.get(PathParams.productSlug);
          const itemSlug = params.get(PathParams.itemSlug);
          if (catSlug && productSlug && itemSlug) {
            await router.navigateByUrl(
              `/location/${slug}/menu/${catSlug}/${productSlug}/${itemSlug}`
            );
          } else if (catSlug && productSlug) {
            await router.navigateByUrl(
              `/location/${slug}/menu/${catSlug}/${productSlug}`
            );
          } else {
            await router.navigateByUrl(`/location/${slug}/menu`);
          }
        }
      }),
      map((slug) => !slug)
    )
  );
};
