import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wri-home-page-find-your-menu-section',
  templateUrl: './home-page-find-your-menu.component.html',
  styleUrls: ['./home-page-find-your-menu.component.scss']
})
export class HomePageFindYourMenuComponent {
  selectedHandoffMode = 'carryout';
  public findMenuInput = '';

  constructor(private router: Router) {}

  onHandoffChange(handoffMode: string) {
    this.selectedHandoffMode = handoffMode;
  }

  encodeAndNavigate() {
    if (this.findMenuInput) {
      const encodeInputValue = encodeURIComponent(this.findMenuInput);
      this.router.navigate(['/order'], {
        queryParams: {
          handoffMode: this.selectedHandoffMode,
          searchQuery: encodeInputValue
        }
      });
    }
  }
}
