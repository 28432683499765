import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  NEVER,
  combineLatest,
  from,
  map,
  of,
  switchMap,
  Observable,
  tap
} from 'rxjs';
import { PathParams } from '../../../ecomm/constants/params';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { CartWorkflowService } from '../../../ecomm/workflows/cart/cart-workflow.service';
import { CustomerWorkflowService } from '../../../ecomm/workflows/customer/customer-workflow.service';
import { SelectedHandoffModeService } from '../../../ecomm/workflows/selected-handoff-mode/selected-handoff-mode.service';
import { StoreInfoWorkflowService } from '../../../ecomm/workflows/store-info/store-info-workflow.service';

import { BasePageService } from './base-page-service';
import { SelectedHandoffMode } from '../../../ecomm/types/selected-handoff-mode.types';

@Injectable()
export class ProductDisplayPageService extends BasePageService {
  queryParams:
    | { handoffMode: SelectedHandoffMode; locationSlug: string | null }
    | undefined;
  constructor(
    private selectedHandoffModeService: SelectedHandoffModeService,
    private storeInfoService: StoreInfoWorkflowService,
    private cartService: CartWorkflowService,
    @Inject(AsynchronousDispatcher)
    asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    redirectService: RedirectService,
    store: Store,
    userAccountService: CustomerWorkflowService
  ) {
    super(redirectService, store, userAccountService, asynchronusDispatcher);
  }

  private getLoadParams = (route: ActivatedRouteSnapshot) => {
    return combineLatest([
      from(this.selectedHandoffModeService.get(route)),
      of(route.paramMap)
    ]).pipe(
      map(([handoffMode, params]) => ({
        handoffMode,
        locationSlug: params.get(PathParams.locationSlug),
        categorySlug: params.get(PathParams.categorySlug),
        productSlug: params.get(PathParams.productSlug),
        itemSlug: params.get(PathParams.itemSlug)
      }))
    );
  };

  timeout$ = () => NEVER;

  async loadData(route: ActivatedRouteSnapshot): Promise<void> {
    const handoffMode = await this.selectedHandoffModeService.get(route);
    const params = route.paramMap;
    await this.storeInfoService.getStoreInfoBySlugAndMenuItem(
      params.get(PathParams.locationSlug) ?? '',
      handoffMode.handoffMode ?? '',
      params.get(PathParams.categorySlug),
      params.get(PathParams.productSlug),
      params.get(PathParams.itemSlug)
    );
    await this.cartService.getOrCreate(route);
  }

  loadData$(route: ActivatedRouteSnapshot) {
    return new Observable((subscriber) =>
      this.getLoadParams(route)
        .pipe(
          tap((params) => {
            this.queryParams = params;
          }),
          switchMap((params) =>
            this.storeInfoService.getStoreInfoBySlugAndMenuItem(
              params.locationSlug ?? '',
              params.handoffMode.handoffMode ?? '',
              params.categorySlug,
              params.productSlug,
              params.itemSlug
            )
          ),
          tap(() => subscriber.next(undefined)),
          switchMap(() => this.cartService.getOrCreate(route)),
          tap(() => subscriber.next(undefined))
        )
        .subscribe(() => subscriber.complete())
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInInvalidState(_state: FeaturesState): boolean {
    return false;
  }
}
