import { ValidatorFn } from '@angular/forms';

export function zipValidator(): ValidatorFn {
  return (control) => {
    if (control.value === undefined || control.value === null) {
      return null;
    }
    const parsedNumber = control.value as string;
    if (parsedNumber.length > 0 && parsedNumber.length < 5) {
      return { minLength: true };
    }
    return null;
  };
}
