import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common';
import { ChooseJobLocationComponent } from './components/choose-job-location/choose-job-location.component';
import { JobLocationSearchCardComponent } from './components/job-location-search-card/job-location-search-card.component';
import { JobLocationSearchListComponent } from './components/job-location-search-list/job-location-search-list.component';

const components = [
  ChooseJobLocationComponent,
  JobLocationSearchListComponent,
  JobLocationSearchCardComponent
];

@NgModule({
  imports: [
    CommonModule,
    WriCommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: components,
  declarations: components
})
export class JobSearchModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<JobSearchModule> {
    return {
      ngModule: JobSearchModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
