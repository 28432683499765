import { Component } from '@angular/core';

@Component({
  selector: 'wri-charities-section',
  templateUrl: './charities-section.component.html',
  styleUrls: ['./charities-section.component.scss']
})
export class CharitiesSectionComponent {

}
