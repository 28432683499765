import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  Subscription,
  switchMap,
  tap
} from 'rxjs';

import { PathParams } from '../../../../ecomm/constants/params';
import {
  Config,
  CONFIG
} from '../../../../ecomm/providers/config/config.provider';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataCity,
  structuredDataThing
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { LocationsResponseData } from '../../../../ecomm/types/search-location.types';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { SearchLocationWorkflowService } from '../../../../ecomm/workflows/search-location/search-location-workflow.service';
import { Store } from '@ngrx/store';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { UNKNOWN_ERROR } from '../../../../ecomm/utils/throw-error';

import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { PartialOutageModalComponent } from '../../../common';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';

type CityDetailsData = {
  stateSlug: string;
  stateName: string;
  [PathParams.citySlug]: string;
};

@Component({
  selector: 'wri-city-details',
  styleUrls: ['./city-details.component.scss'],
  template: `
    <wri-partial-outage-modal></wri-partial-outage-modal>
    <ng-container *ngIf="isLoading; then loading; else loaded"></ng-container>
    <ng-template #loading>
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>
    </ng-template>

    <ng-template #loaded>
      <div>
        <section class="location-banner">
          <div class="location-name">
            {{ bannerTitle }}
          </div>
          <button
            class="wri-btn wri-btn-secondary wri-btn-city-override-hover"
            aria-label="Find a location"
            (click)="navigateToOrder()"
          >
            Find a location
          </button>
        </section>
        <section *ngIf="details?.locations?.length > 0" class="locations-list">
          <wri-location-card
            *ngFor="let location of details | pick : 'locations'"
            [location]="location"
          ></wri-location-card>
        </section>

        <section class="about-section">
          <div class="about-title">
            {{ aboutSectionTitle }}
          </div>
          <div class="about-desc" [innerHTML]="formattedAboutSection"></div>
        </section>
      </div>
    </ng-template>
  `
})
export class CityDetailsComponent implements OnInit, OnDestroy {
  public storeInfo: StoreInfo | null = null;
  public isLoading = false;
  public data: CityDetailsData | null = null;
  public details: LocationsResponseData | null = null;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchLocationService: SearchLocationWorkflowService,
    private redirectService: RedirectService,
    private store: Store,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(CONFIG) private config: Config,
    private notificationService: NotificationService,
    private storeInfoWorkflowService: StoreInfoWorkflowService
  ) {
    const data$: Observable<CityDetailsData> = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.params
    ]).pipe(
      map(([data, params]) => ({ ...data, ...params } as CityDetailsData)),
      tap((data) => (this.data = data))
    );

    const details$ = data$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap(() => this.getCityLocations()),
      tap((details) => {
        if (details?.locations) {
          details.locations.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.details = details;
      }),
      tap(() => (this.isLoading = false)),
      tap(this.setSeoMetaData.bind(this)),
      tap(this.setStructuredData.bind(this))
    );

    this.subscription.add(details$.subscribe());
  }

  public get stateName() {
    return this.data?.stateName;
  }

  public get stateSlug() {
    return this.data?.stateSlug;
  }

  public get localityName() {
    return this.details?.locations[0].locality;
  }

  public get aboutSectionTitle() {
    return `For Great Wings in ${this.localityName}: Get Wingstop`;
  }

  public get bannerTitle() {
    return `Wingstop Locations in ${this.localityName}`;
  }

  public get formattedAboutSection() {
    return this.config.cityAboutSection.replace(
      /\[City]/gi,
      this.data?.stateName ?? ''
    );
  }

  private get getFriendlyLastBreadcrumbUrl() {
    return this.document.location.origin + this.document.location.pathname;
  }

  async getCityLocations() {
    const region = await this.searchLocationService.getRegionSummary();

    const stateDetail = region?.find((s) => s.slug === this.data?.stateSlug);
    const city = stateDetail?.cities.find(
      (c) => c.slug === this.data?.[PathParams.citySlug]
    );

    if (!stateDetail || !city || !city.ids?.length) {
      this.navigateToStatePage();
      return null;
    }
    const locations = await this.searchLocationService.getLocationsByIds({
      locationIds: city.ids
    });

    return this.reportErrorIfNull(locations);
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToStoreInfoState();
    this.subscribeToRegionalConfigState();
  }

  navigateBasedOnFeatureFlag(configState: RegionalConfigurationFeatureState) {
    if (
      configState?.regionalConfigurationOptions[
        'feature_enable_state_routes'
      ]?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy();
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
        this.navigateBasedOnFeatureFlag(state)
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  public navigateToOrder() {
    this.router.navigate(['/order/']);
  }

  public navigateToStatePage() {
    this.router.navigate([`/${this.stateSlug}`]);
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfo = state.storeInfo;
      })
    );
  }

  private reportErrorIfNull = (
    res: LocationsResponseData | null
  ): LocationsResponseData | null => {
    if (res === null || res.locations.length === 0) {
      this.notificationService.showError(UNKNOWN_ERROR);
    }
    return res;
  };

  private setSeoMetaData = () => {
    const locality = this.localityName;
    this.seoService.setMetaData({
      title: `${locality} - Information - Wings Restaurant | Wingstop`,
      description: `View all Wingstop wing locations in ${locality}, ${this.stateName}`
    });
  };

  private setStructuredData = () => {
    const regionName = this.stateName;
    const regionPathParam = this.stateSlug;
    const localityName = this.localityName;
    if (regionName && localityName) {
      this.seoService.addStructuredData([
        structuredDataThing(
          this.document.location.origin,
          this.formattedAboutSection
        ),
        structuredDataCity(localityName),
        structuredDataBreadcrumbList([
          {
            name: 'Home',
            url: this.document.location.origin
          },
          {
            name: regionName,
            url: this.document.location.origin + '/' + regionPathParam
          },
          {
            name: localityName,
            url: this.getFriendlyLastBreadcrumbUrl
          }
        ])
      ]);
    }
  };
}
