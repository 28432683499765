import { BaseModel } from '@wingstop/models/base.model';

export class BasketChoice extends BaseModel {
  quantity: number;
  cost: number;
  created_at: string;
  id: any;
  choiceid: any;
  indent: number;
  metric: number;
  name: string;
  optiongroupid: any;
  optiongroupname: string;
  optionid: any;
  product_id: any;
  updated_at: string;

  constructor(values?: any) {
    super();

    if (values) {
      if (values.choiceid == null) {
        values.choiceid = values.optionid;
      }
    }

    this.initialize(values);
  }
}
