<div class="wri-signup-form" id="customer-sign-up-form">
  <div class="form-title">Sign up with email</div>
  <span class="required-field-disclaimer">
    Required fields are marked with an asterisk (*).
  </span>
  <form [formGroup]="signupForm">
    <wri-text-input
      [id]="'firstName'"
      label="First Name*"
      autoComplete="given-name"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      formControlName="firstName"
      maxlength="50"
    >
      <wri-form-error
        [control]="signupForm.controls['firstName']"
        errorKey="required"
        display="First name is required"
      ></wri-form-error>
      <wri-form-error
        [control]="signupForm.controls['firstName']"
        errorKey="pattern"
        display="Must be a valid first name"
      ></wri-form-error>
    </wri-text-input>

    <wri-text-input
      [id]="'lastName'"
      label="Last Name*"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      autoComplete="family-name"
      formControlName="lastName"
      maxlength="50"
    >
      <wri-form-error
        [control]="signupForm.controls['lastName']"
        errorKey="required"
        display="Last name is required"
      ></wri-form-error>
      <wri-form-error
        [control]="signupForm.controls['lastName']"
        errorKey="pattern"
        display="Must be a valid last name"
      ></wri-form-error>
    </wri-text-input>

    <wri-text-input
      [id]="'email'"
      label="Email*"
      autoComplete="email"
      type="email"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      formControlName="email"
      maxlength="128"
      class="span-full-width-custom"
    >
      <wri-form-error
        [control]="signupForm.controls['email']"
        errorKey="required"
        display="Email is required"
      ></wri-form-error>
      <wri-form-error
        [control]="signupForm.controls['email']"
        errorKey="pattern"
        display="Must be a valid email"
      ></wri-form-error>
    </wri-text-input>

    <wri-text-input
      [id]="'phone'"
      label="Phone (Optional)"
      placeholder="XXX XXX XXXX"
      autoComplete="tel-national"
      type="tel"
      formControlName="phone"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      [formatter]="getPhoneFormatter()"
      class="span-full-width-custom"
    >
      <wri-form-error
        [control]="signupForm.controls['phone']"
        errorKey="inValidNumber"
        display="Must be a valid phone number ">
      </wri-form-error>
    </wri-text-input>

    <div class="span-full-width-custom" [ngClass]="signupForm.controls['password']?.errors ? '' : 'change-child'">
      <wri-text-input
        [id]="'password'"
        label="Password*"
        type="{{ hidePassword ? 'password' : 'text' }}"
        [icon]="hidePassword ? 'wri-eye' : 'wri-eye-strikethrough'"
        [isFormValid]="isFormValid"
        [canShowErrors]="canShowErrors"
        formControlName="password"
        (iconClick)="handlePasswordIconClick()"
        class="span-full-width-custom"
        autoComplete="new-password"
        wriPasswordRequirements
        [value]="signupForm.value.password"
        [passwordRequirements]="passwordRequirements"
        [ariaPressed]="!hidePassword"
        [isToggleable]="true"
      >
        <wri-form-error
          [control]="signupForm.controls['password']"
          errorKey="required"
          display="Password is required"></wri-form-error>
        <wri-form-error
          [control]="signupForm.controls['password']"
          errorKey="notMetReq"
          display="Password doesn’t meet requirements">
        </wri-form-error>
      </wri-text-input>
    </div>

    <wri-text-input
      [id]="'confirmPassword'"
      label="Confirm Password*"
      type="{{ hideConfirmPassword ? 'password' : 'text' }}"
      [icon]="hideConfirmPassword ? 'wri-eye' : 'wri-eye-strikethrough'"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      formControlName="confirmPassword"
      (iconClick)="handleConfirmPasswordIconClick()"
      class="span-full-width-custom"
      autoComplete="new-password"
      [ariaPressed]="!hideConfirmPassword"
      [isToggleable]="true"
    >
      <wri-form-error
        [control]="signupForm.controls['confirmPassword']"
        errorKey="required"
        display="Confirm password is required"
      ></wri-form-error>
      <div
        class="form-error"
        *ngIf="
          signupForm.controls['confirmPassword'].value !== '' &&
          signupForm.controls['confirmPassword'].errors &&
          !signupForm.controls['confirmPassword'].errors['pattern'] &&
          signupForm.controls['confirmPassword'].invalid
        "
      >
        Your password and confirmation password must match
      </div>
    </wri-text-input>

    <div class="span-full-width">
      <div class="form-title">Your B-Day Hookup</div>
      <span class="required-field-disclaimer">
        Drop the date and we’ll show out.
      </span>

      <wri-text-input
        [id]="'birthday'"
        label="Birthday (Optional)"
        formControlName="birthday"
        placeholder="MM/DD"
        [isFormValid]="isFormValid"
        [canShowErrors]="canShowErrors"
        (keyup)="onKeyup($event)"
        maxlength="5"
      >
        <wri-form-error
          [control]="signupForm.controls['birthday']"
          errorKey="minLength"
          display="Birthdate must be MM/DD format"
        ></wri-form-error>
        <wri-form-error
          [control]="signupForm.controls['birthday']"
          errorKey="pattern"
          display="Must be a valid month and date"
        ></wri-form-error>
      </wri-text-input>
    </div>

    <button class="wri-btn wri-btn-primary span-full-width" (click)="onSignUpButtonClick()">Sign Up</button>
  </form>
</div>
