/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import * as MaybeResponse from '../../types/maybe-response';
import { fromDto } from '../../utils/from-dto';
import { PasswordRequirementsRepository } from '../../repositories/password-requirements/password-requirements.repository';
import { PasswordRequirements } from '../../types/password-requirements.types';

@Injectable({ providedIn: 'root' })
export class PasswordRequirementsService {
  constructor(private passwordRequirementsRepository: PasswordRequirementsRepository) {}

  public getPasswordRequirements(): Observable<MaybeResponse.MaybeResponse<PasswordRequirements[]>> {
    return this.passwordRequirementsRepository.getPasswordRequirements().pipe(
      map(
        MaybeResponse.mapData((data) => {
          if (!data) return undefined;
          return data.passwordRequirements.map(fromDto);
        })
      )
    );
  }
}
