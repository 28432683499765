import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'svg[type]',
  template: `
    <svg:use [attr.xlink:href]="'assets/images/sprite.svg#' + type"></svg:use>
  `,
  styleUrls: ['./icon.component.scss'],
  host: {
    viewBox: '0 0 24 24',
    class: 'icon',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class IconComponent {
  @Input() type: string;
  @Input() className: string;
}
