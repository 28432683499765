<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>

<!--for SFMC -->
<div id="choose-location-banner"></div>

<div class="choose-location-wpr">
  <h3 class="get-started-title">Get started</h3>

  <wri-handoff-mode-selector
    [handoffMode]="selectedHandoffMode"
    (handoffModeChange)="onHandoffChange($event)"
  ></wri-handoff-mode-selector>

  <div
    class="choose-location-handoffmode-copy hidden"
    [attr.data-handoffmode]="selectedHandoffMode"
  >
    <p class="carryout"></p>
    <p class="delivery"></p>
  </div>

  <div
    class="search-input-wpr"
    [class.carryout]="selectedHandoffMode === 'carryout'"
    [class.delivery]="selectedHandoffMode === 'delivery'"
  >
    <div
      [attr.data-testid]="'text-input-for-location-search-input'"
      class="wri-text-input"
    >
      <div class="label-and-map-list-toggle">
        <label
          [attr.for]="'location-search-input'"
          [attr.data-testid]="'label-for-search-input'"
        >
          {{
          selectedHandoffMode === 'delivery'
            ? 'Delivery Address'
            : 'Search Zip or City, State'
          }}
        </label>
        <a
          *ngIf="selectedHandoffMode !== 'delivery'"
          class="map-view-toggle-mobile"
          (click)="onMobileMapViewToggle()"
        >
          <wri-icon
            class="map-view-icon"
            [icon]="showMobileMap ? 'wri-list-view' : 'wri-map-view'"
            data-testid="map-view-icon"
          ></wri-icon>
          {{ showMobileMap ? 'List View' : 'Map View' }}

        </a>
      </div>

      <div class="input-icons-wpr" id="input-wpr">
        <a
          wriFocusOutline
          tabindex="0"
          (click)="useMyCurrentLocation()"
          *ngIf="useMyLocationIsSupported && selectedHandoffMode === 'carryout'"
        >
          <wri-icon
            icon="wri-compass"
            class="input-icon"
            data-testid="compass-icon"
          >
          </wri-icon>
        </a>
        <input
          [class.show-search-icon]="
            !useMyLocationIsSupported || selectedHandoffMode === 'delivery'
          "
          [class.show-compass-icon]="
            useMyLocationIsSupported && selectedHandoffMode === 'carryout'
          "
          data-testid="location-search-input"
          [id]="'location-search-input'"
          [attr.autocomplete]="'off'"
          [attr.type]="'text'"
          [attr.aria-label]="'location-search'"
          [ngbTypeahead]="search"
          [resultTemplate]="rt"
          [resultFormatter]="resultsFormatter"
          [inputFormatter]="inputFormatter"
          (click)="click$.next($any($event).target.value)"
          (selectItem)="selectedItem($event)"
          [value]="searchText"
          (blur)="noItemSelected($event)"
          [attr.placeholder]="selectedHandoffMode === 'delivery' ? deliveryPlaceholderText : placeholderText"
          #instance="ngbTypeahead"
          #inputElem
        />
        <ng-template #rt let-r="result" let-t="term">
          <div [ngStyle]="{ width: getWidthOfInputQuickSearch() + 'px' }">
            {{ r.name }}
          </div>
        </ng-template>
        <wri-spinner
          class="search-input-spinner"
          *ngIf="searching"
        ></wri-spinner>
        <a
          wriFocusOutline
          tabindex="0"
          (click)="resetSearchData(selectedHandoffMode)"
          *ngIf="searchText?.length > 0"
        >
          <wri-icon
            icon="wri-cross"
            class="input-icon"
            data-testid="cross-icon"
            ariaLabel="Clear search"
          >
          </wri-icon>
        </a>
      </div>

      <div class="form-error" *ngIf="selectedHandoffMode === 'delivery' && showPrimaryAddressError">
        Delivery address is required
      </div>
      <div class="form-error" *ngIf="selectedHandoffMode === 'delivery' && showPrimaryAddressNoSelectedAddressError">
        Must be a selected delivery address
      </div>
    </div>
    <!-- Building/Suite/Apt for Delivery Handoff Mode -->
    <div
      *ngIf="selectedHandoffMode === 'delivery'"
      [attr.data-testid]="'text-input-for-secondary-search-input'"
      class="wri-text-input"
    >
      <div class="label-and-map-list-toggle">
        <label
          [attr.for]="'secondary-search-input'"
          [attr.data-testid]="'label-for-secondary-search-input'"
        >
        Building/Suite/Apt
        </label>
      </div>

      <div class="input-icons-wpr" id="input-wpr">
        <input
          class="show-search-icon"
          [ngClass]="{'can-show-errors': showSecondaryAddressError}"
          data-testid="secondary-search-input"
          [id]="'secondary-search-input'"
          [attr.type]="'text'"
          maxlength="30"
          [attr.aria-label]="'secondary-search'"
          [resultFormatter]="resultsFormatter"
          [inputFormatter]="inputFormatter"
          [(ngModel)]="secondaryAddressSearchText"
          [attr.placeholder]="'e.g. Apt 123'"
          (blur)="validateField($event)"
        />
      </div>
      <div class="form-error" *ngIf="showSecondaryAddressError">
        Address contains invalid characters
      </div>
    </div>

    <!-- Button for Delivery Handoff Mode -->
    <button
      *ngIf="selectedHandoffMode === 'delivery'"
      class="wri-btn wri-btn-primary"
      aria-label="CONTINUE for delivery"
      (click)="continueForDeliveryBtnClick()"
    >
      continue for delivery
    </button>

  </div>
  <div *ngIf="showMobileMap" class="map-container mobile">
    <wri-map
      [interactive]="selectedHandoffMode !== 'delivery'"
      [showPreview]="false"
      [searchEnabled]="mapSearchEnabled"
      [locations]="mapLocations$ | async"
      (search)="onMapSearch($event)"
      (startSearch)="onMapStartSearch($event)"
      (markerSelect)="onMapMarkerSelect($event)"
    ></wri-map>
  </div>

  <div class="map-container desktop">
    <wri-map
      [interactive]="selectedHandoffMode !== 'delivery'"
      [showPreview]="showMapPreview"
      [searchEnabled]="mapSearchEnabled"
      [locations]="mapLocations$ | async"
      (search)="onMapSearch($event)"
      (startSearch)="onMapStartSearch($event)"
      (markerSelect)="onMapMarkerSelect($event)"
    ></wri-map>
  </div>

  <section class="location-list-wrapper">
    <div
      class="card-btn-wrapper"
      *ngIf="
        selectedHandoffMode === 'carryout'
      "
    >
      <wri-tabs
        [tabs]="locationTabs"
        (selected)="selectedTab$.next($event)"
      ></wri-tabs>
    </div>
    <div class="card-btn-wrapper" *ngIf="showUseMyLocation()">
      <button
        class="wri-btn wri-btn-primary"
        aria-label="USE MY CURRENT LOCATION"
        (click)="useMyCurrentLocation()"
      >
        USE MY CURRENT LOCATION
      </button>
    </div>
    <div class="search-list-wrapper"
         *ngIf="showLocationSearchResult()"
         [ngClass]="{
        'delivery-tab': selectedHandoffMode === 'delivery',
        'no-locations': nearbyLocationsList?.locations?.length === 0
      }"
    >
      <wri-location-search-list
        *ngIf="showLocationSearchResult()"
        [locations]="nearbyLocationsList?.locations"
        [tab]="selectedTabId"
        [savedDeliveryAddresses]="userDetails?.deliveryAddresses"
        [selectedLocationMarkerId]="selectedMarker"
        [selectedHandoffMode]="selectedHandoffMode"
        [searching]="locationSearching"
        [isUserAuthenticated]="userDetails !== null"
        (navigate)="onLocationNavigate($event)"
        (selectSavedDeliveryAdress)="onSelectSavedDeliveryAdress($event)"
      ></wri-location-search-list>
    </div>

    <wri-location-search-list
      *ngIf="showRecentLocations()"
      [locations]="recentLocationsList?.locations"
      [tab]="selectedTabId"
      [savedDeliveryAddresses]="userDetails?.deliveryAddresses"
      [selectedLocationMarkerId]="selectedMarker"
      [selectedHandoffMode]="selectedHandoffMode"
      [searching]="locationSearching"
      [isUserAuthenticated]="userDetails !== null"
      (navigate)="onLocationNavigate($event)"
      (selectSavedDeliveryAdress)="onSelectSavedDeliveryAdress($event)"
      (login)="onLoginNavigate()"
    ></wri-location-search-list>

    <wri-location-search-list
      *ngIf="showFavoriteLocationsTab()"
      [locations]="favoriteLocationsList?.locations"
      [tab]="selectedTabId"
      [selectedHandoffMode]="selectedHandoffMode"
      [searching]="locationSearching"
      [isUserAuthenticated]="userDetails !== null"
      (navigate)="onLocationNavigate($event)"
      (login)="onLoginNavigate()"
      (isLocationUnFavorited)="onUnFavoriteLocation($event)"
    ></wri-location-search-list>
  </section>
</div>
<wri-partial-outage-modal></wri-partial-outage-modal>
<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title"
      >Location permissions have been turned off</span
      >
      <span class="wri-modal__description"
      >Your location permissions were recently denied. To re-enable your
        location data, please adjust your settings in the browser.</span
      >
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-primary"
          aria-label="Dismiss"
          (click)="closeModal()"
        >
          Dismiss
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>

<ng-template #switchLocationConfirmationModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title">
        Heads Up!
      </span>
      <span class="wri-modal__description">
        Delivery fees and other charges may apply.
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-primary"
          aria-label="Continue"
          (click)="navigateToMenuPage(); closeModal();"
        >
          Continue
        </button>
        <button
          data-testid="permission-modal-dismiss"
          class="wri-btn wri-btn-tertiary"
          aria-label="Cancel"
          (click)="closeModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>
