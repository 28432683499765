import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SCREEN_SIZE } from '@wingstop/bootstrap/size-detector/size-detector.component';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  private resizeSubject: BehaviorSubject<SCREEN_SIZE> = new BehaviorSubject(
    null
  );

  public onResizeSubscription(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  constructor() {}

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }
}
