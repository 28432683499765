import { either } from 'fp-ts';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { Observable, of } from 'rxjs';
import * as rx from 'rxjs/operators';

import { MaybeResponse } from '../types/maybe-response';
import { UNKNOWN_ERROR, log } from './throw-error';

export const handleResourceResponse =
  <T>(codec: t.Type<T>, defaultErrorMessage = UNKNOWN_ERROR) =>
  (input$: Observable<T>): Observable<MaybeResponse<T>> =>
    input$.pipe(
      rx.map((res) => {
        const validationResult = codec.decode(res);
        if (either.isLeft(validationResult)) {
          PathReporter.report(validationResult).forEach((err) => log(err));
          return { error: defaultErrorMessage };
        } else {
          return { data: res };
        }
      }),
      rx.catchError(() => {
        return of({ error: defaultErrorMessage });
      })
    );
