import { Component, Input } from '@angular/core';

@Component({
  selector: 'wri-section-loading-indicator',
  template: `
    <div
      [ngStyle]="{ 'grid-auto-rows': gridAutoRows }"
      class="section-loading-indicator"
    >
      <span class="spinning-progress-indicator"></span>
    </div>
  `,
  styleUrls: ['./section-loading-indicator.component.scss']
})
export class SectionLoadingIndicatorComponent {
  @Input() gridAutoRows: string | undefined;
}
