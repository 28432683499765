<div
  *ngFor="let pwdRequirement of passwordRequirements"
  class="pwd-reqs-container"
>
  <ng-container
    *ngIf="pwdRequirement | validatePassword : enteredPassword; else unselected"
  >
    <div class="circle-wrapper-selected">
      <wri-icon [icon]="'wri-check'"></wri-icon>
    </div>
  </ng-container>
  <ng-template #unselected>
    <div class="circle-wrapper-unselected"></div>
  </ng-template>
  <div class="pwd-reqs-container-desc">{{ pwdRequirement.description }}</div>
</div>
