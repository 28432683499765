import { DeepPartial } from '../types/deep-partial';

const isObject = <T>(val: T): val is T & object =>
  !!(val && typeof val === 'object' && !Array.isArray(val));

const deepMerge = <T extends object>(
  complete: T,
  partial: DeepPartial<T>
): T => {
  const keysInPartial: (keyof T)[] = Object.keys(partial) as (keyof T)[];
  return keysInPartial.reduce((merged, key) => {
    const valueFromComplete = merged[key];
    const valueFromPartial: T[keyof T] | undefined = (partial as T)[key];
    const newValue = isObject(valueFromComplete)
      ? deepMerge(
          valueFromComplete,
          (valueFromPartial ?? {}) as DeepPartial<T[keyof T] & object>
        )
      : valueFromPartial ?? valueFromComplete;
    return {
      ...merged,
      [key]: newValue
    };
  }, complete);
};

export const deepMergeAll = <T extends object>(
  initial: T,
  ...deepPartials: DeepPartial<T>[]
): T =>
  deepPartials.reduce(
    (merged, nextPartial) => deepMerge(merged, nextPartial),
    initial
  );
