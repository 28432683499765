import { QueryParams } from '../constants/params';
import { Address } from './address';

export type SelectedHandoffDeliveryAddress = {
  streetAddress: string;
  city?: string;
  state?: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  secondaryAddress?: string | null;
  locality?: string;
  region?: string;
  deliveryInstructions?: string | null;
};

export type HandoffMode = 'delivery' | 'carryout';
export const HandoffMode: Record<HandoffMode, HandoffMode> = {
  carryout: 'carryout',
  delivery: 'delivery'
} as const;

export type SelecteHandoffModeQueryParams = 'handoffMode' | 'deliveryAddress';
export const SelecteHandoffModeQueryParams: Record<
  SelecteHandoffModeQueryParams,
  SelecteHandoffModeQueryParams
> = {
  handoffMode: QueryParams.handoffMode,
  deliveryAddress: QueryParams.deliveryAddress
} as const;

export type SelectedHandoffMode = {
  address: Address | null;
  handoffMode: HandoffMode | null;
};
