<wri-feature-flags [with]="['enableGooglePay']">
  <div *ngIf="methodSupported" tabindex="0">
    <google-pay-button
      id="google-pay-button"
      class="google-pay-button-wrapper"
      environment="{{ config.googlePay.environment }}"
      buttonType="plain"
      buttonColor="black"
      buttonSizeMode="fill"
      [paymentRequest]="paymentRequest"
      (loadpaymentdata)="onLoadPaymentData($event)"
      [errorCallback]="onError"
      [clickCallback]="onGpayButtonClick"
    ></google-pay-button>
  </div>
</wri-feature-flags>
