<div
  class="wri-cart-additional-recommendation-wrapper"
  [class.loading]="loading"
>
  <div class="wri-title">Add something extra to your order</div>
  <ngx-slick-carousel
    data-testid="cross-sell-deck-carousel"
    class="cross-sell-deck carousel"
    [config]="slideConfig"
    (init)="handleInit()"
    #slickModal="slick-carousel"
  >
    <div
      ngxSlickItem
      *ngFor="let data of sliderData; trackBy: trackByItem; let first = first"
      class="slide tabfocus"
    >
      <wri-additional-recommendation-card
        [first]="first"
        [image]="data.item.images.length > 0 ? data.item.images[0].uri : null"
        [imageDescription]="data.item?.description | prepend : 'image-for'"
        [name]="data.item.name"
        [description]="data.item.shortDescription || data.item.description"
        [item]="data.item"
        [locationSlug]="storeInfoState?.storeInfo?.storeDetails.slug"
        [storeName]="storeInfoState?.storeInfo?.storeDetails.name"
        [handoffMode]="handoffMode"
        [categorySlug]="data.categorySlug"
        [productSlug]="data.productSlug"
        [itemSlug]="data.itemSlug"
        [showButton]="true"
        [recommendationType]="'cart-recommendations'"
      ></wri-additional-recommendation-card>
    </div>
  </ngx-slick-carousel>
</div>
