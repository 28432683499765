import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Authentication } from '@wingstop/models/login/authentication.model';
import { Basket } from '@wingstop/models/basket.model';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { ContactDetails } from '@wingstop/models/login/contact-details.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { Injectable } from '@angular/core';
import { Location } from '@wingstop/models/location/location.model';
import { Menu } from '@wingstop/models/menu.model';
import { ResponseCacheService } from '@wingstop/services/response-cache.service';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { environment } from '@wingstop/environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment-mini';

enum SortDirection {
  'ASC' = 'asc',
  'DESC' = 'desc',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public nomnomHeaders = new HttpHeaders().set(
    'locale',
    navigator && navigator.language
      ? navigator.language.toLowerCase()
      : 'en-us'.toLowerCase()
  );

  constructor(
    protected http: HttpClient,
    protected responseCache: ResponseCacheService
  ) { }

  async location(id: string | number): Promise<Location> {
    // Construct an encoded query string parameter
    let params = new HttpParams().set('nomnom', 'calendars');
    params = params.append(
      'nomnom_calendars_from',
      moment().format('YYYYMMDD')
    );
    params = params.append(
      'nomnom_calendars_to',
      moment().add(7, 'days').format('YYYYMMDD')
    );

    let headers = this.createHeaders([
      ['ui-cache-ttl', '300'],
      ['ui-transformer', 'restaurant'],
    ]);

    if (typeof id === 'number' || !isNaN(id as any)) {
      return this.http
        .get<Location>(environment.apiBaseUrl + '/restaurants/' + id, {
          params: params,
          headers: headers,
          observe: 'response',
        })
        .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
        .toPromise();
    }
    return this.http
      .get<Location>(environment.apiBaseUrl + '/restaurants/byslug/' + id, {
        params: params,
        headers: headers,
        observe: 'response',
      })
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async menu(
    location: Location | number | string,
    isDigitalMenu = false
  ): Promise<Menu> {
    if (location instanceof Location) {
      location = location.id;
    }
    let headers = this.createHeaders([
      ['ui-cache-ttl', '300'],
      ['ui-transformer', 'menu'],
    ]);
    const value = isDigitalMenu ? 'digital-menu' : 'menu-products';
    const params = new HttpParams().set('nomnom', value);
    return this.http
      .get<Menu>(
        environment.apiBaseUrl + '/restaurants/' + location + '/menu',
        { headers, params, observe: 'response' }
      )
      .pipe(
        map((r) => {
          return this.responseCache.get(r.headers.get('ui-cache-key'));
        })
      )
      .toPromise()
      .catch((e) => console.log(e));
  }

  async createBasket(
    location: Location | number | string,
    authentication?: Authentication
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);

    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/create',
        {
          vendorid: location instanceof Location ? location.id : location,
          authtoken: authentication ? authentication.authtoken : null,
        },
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async getBasket(basket: Basket): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
    ]);
    return this.http
      .get<Basket>(environment.apiBaseUrl + '/baskets/' + basket.id, {
        headers: headers,
        observe: 'response',
      })
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async dispatchAddress(
    basket: Basket,
    address: DeliveryAddress
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/dispatchaddress',
        address,
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async deliveryMode(basket: Basket, mode: string): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/deliverymode',
        { deliverymode: mode },
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async contactDetails(authentication: Authentication) {
    return this.http
      .get<ContactDetails>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/contactdetails',
        { headers: this.nomnomHeaders }
      )
      .pipe(map((c) => new ContactDetails(c)))
      .toPromise();
  }

  async logout(authentication: Authentication) {
    return this.http
      .delete(environment.apiBaseUrl + '/users/' + authentication.authtoken)
      .toPromise();
  }

  // Log customer out of Ping
  async revokePingToken(authentication: Authentication) {
    await this.http
      .post(environment.apiBaseUrl + `/ping/as/revoke`, {
        token: authentication.nomnom.ping.refresh_token,
      })
      .toPromise();
  }

  createHeaders(headers: [string, string][]) {
    let _headers: HttpHeaders = new HttpHeaders();
    headers.forEach((h) => {
      _headers = _headers.append(h[0], h[1]);
    });
    return _headers;
  }

  async getOffer(id: string) {
    return this.http
      .get(environment.apiBaseUrl + '/content/type/WingstopOffer/id/' + id)
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          return new CMSPromoOffer(r['content'][0]);
        })
      )
      .toPromise();
  }

  async optOutDoorDash(emailAddress: string) {
    return this.http
      .put<string>(environment.apiBaseUrl + '/sessionm/doordash/optout', {
        email: emailAddress,
      })
      .pipe(map((r) => r))
      .toPromise();
  }

  async getS3SeoMetadata(): Promise<SeoMetadata[]> {
    const s3Path: string = `${environment.ngfeBaseUrl}/resources/`;
    return this.http
      .get<SeoMetadata[]>(s3Path + 'seo-metadata.json?v=' + Date.now())
      .toPromise();
  }
}
