import { BaseModel } from '@wingstop/models/base.model';
import { Category } from '@wingstop/models/menu/category.model';
import { Menu } from '@wingstop/models/menu.model';
import { Metadata } from '@wingstop/models/metadata.model';
import { Router } from '@angular/router';
import { UpsellProduct } from '@wingstop/models/menu/upsell-product.model';
import { environment } from '@wingstop/environments/environment';

export class CategoryProduct extends BaseModel {
  id: any = null;
  category_id: any = null;
  chainproductid: any = null;
  name: string = null;
  description: string = null;
  cost: number = null;
  basecalories: number = 0;
  maxcalories: number = 0;
  displayid: string = null;
  starthour: number = null;
  endhour: number = null;
  imagefilename: string = null;
  maximumquantity: number = null;
  minimumquantity: number = null;
  nomnom: any = {};
  created_at: string = null;
  updated_at: string = null;
  slug: string = null;
  category_slug: string = null;
  store_id: number = null;
  images: any[] = [];
  metadata: Metadata[] = [];
  unavailablehandoffmodes: string[];
  category: Category;
  menuitemlabels: { code: string; images: any[]; name: string }[] = [];
  availability: {
    always: boolean;
    description: any;
    enddate: any;
    now: boolean;
    startdate: any;
  };
  upsellProducts: UpsellProduct[];

  constructor(values?: any) {
    super();

    if (values) {
      values.basecalories = Number(values.basecalories);
      values.maxcalories = Number(values.maxcalories);

      if (values.name) {
        values.slug = this.slugify(values.name);
      }
      if (values.metadata) {
        values.metadata = values.metadata.map(
          (value: any) => new Metadata(value)
        );
      }

      if (values.images) {
        let image = values.images.find((image: any) => {
          return image.groupname === environment.productImageId;
        });

        if (image) {
          values.imagefilename = image.filename;
        }
      }
      if (values.nomnom && values.nomnom.upsell) {
        this.upsellProducts = values.nomnom.upsell;
        this.upsellProducts.forEach((cv: any) => {
          cv.product = cv.product ? new CategoryProduct(cv.product) : null;
        });
      }
    }

    this.initialize(values);
  }

  public hasSodium(): string {
    if (Array.isArray(this.menuitemlabels)) {
      let label = this.menuitemlabels.find((current) => {
        return current.name === 'Sodium';
      });
      if (label) {
        let imageObj = label.images.find((current: any) => {
          return current.groupname === 'desktop-disclaimer';
        });
        if (imageObj) {
          return imageObj.url;
        }
      }
    }

    return null;
  }

  getProductUrl(router: Router, locationSelected = false, menu: Menu = null) {
    let url: string[] = [];
    if (locationSelected) {
      if (this.category_slug) {
        url = ['/menu', this.category_slug, this.slug];
      } else {
        let category = menu.getCategoryByProduct(this);
        if (category) {
          url = ['/menu', category.slug, this.slug];
        } else {
          console.log('WARNING: Could not create product URL for:');
        }
      }
    } else {
      url = ['/order', this.slug];
    }

    return url;
  }

  getProductImage(
    menu: Menu,
    imageGroupName: string = 'mobile-webapp-menu'
  ): string {
    // get this products category
    let category = menu.getCategoryByProduct(this);

    let menuImage = null;
    if (
      Array.isArray(this.images) ||
      Array.isArray(category ? category.images : false)
    ) {
      // Menu image
      menuImage = this.images
        ? this.images.find((i: any) => i.groupname === imageGroupName)
        : category.images.find((i: any) => i.groupname === imageGroupName);
    }

    // if the product has an image, return the url
    if (menuImage) {
      return menu.imagepath + menuImage.filename;
    } else if (category && category.images && category.images.length) {
      // if no image, then fall back to category image
      return menu.imagepath + category.images[0].filename;
    } else {
      // last resort: use filler image
      return environment.emptyProductImage;
    }
  }

  private slugify(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
}
