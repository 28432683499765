import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomerDeliveryAddress } from '../../../../ecomm/types/customer.types';

@Component({
  selector: 'wri-saved-delivery-address',
  templateUrl: './saved-delivery-address.component.html',
  styleUrls: ['./saved-delivery-address.component.scss']
})
export class SavedDeliveryAddressComponent {
  @Input() address?: CustomerDeliveryAddress;
  @Output() selected = new EventEmitter<CustomerDeliveryAddress>();
}
