import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { find, findIndex } from 'lodash';

import { CartItem } from '../../../../ecomm/types/cart.types';
import {
  MenuItem,
  MenuModifier,
  MenuModifierGroup,
  MenuModifierGroupItem
} from '../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../ecomm/utils/calorie-range';
import { removeModGroupsWithEmptyModifiers } from '../../../../ecomm/utils/pdp-utils';
import { ModGroups, Modifiers, PushOrReplacePipe } from '../../../common';

@Component({
  selector: 'wri-add-on-items',
  templateUrl: './add-on-items.component.html',
  styleUrls: ['./add-on-items.component.scss']
})
export class AddOnItemsComponent implements OnInit {
  @Input() public modifierGroups: MenuModifierGroup[] | undefined;
  @Input() public selectedLineItem!: CartItem;
  @Input() public itemQuantity = 1;
  @Output()
  valuesChanged = new EventEmitter<{
    modifierGroupId: string;
    selectedModifierGroups: ModGroups[];
  }>();

  private selectedModifierGroups: ModGroups[] = [];
  private selectedModifiers: Modifiers[] = [];

  public slideConfig = {
    slidesToScroll: 1,
    variableWidth: false,
    infinite: false,
    slidesToShow: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 3,
          arrows: true
        }
      }
    ]
  };

  ngOnInit(): void {
    this.updateAddOnsModGroupsWithCart();
  }

  trackByModifierGroup(index: number, modifierGroup: MenuModifierGroup) {
    return modifierGroup.id;
  }

  trackByItem(index: number, modifierGroupElement: MenuModifierGroupItem) {
    return modifierGroupElement.item?.id;
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  handleModifierGroupData(
    values: Partial<ModGroups>,
    modifierGroup: MenuModifierGroup,
    modifierId: string
  ): void {
    const { modifiers } = values;
    if (modifiers && modifiers.length > 0) {
      // check if modGroup with this modifierGroup.id is present
      const modGroupIndex = findIndex(this.selectedModifierGroups, [
        'modifierGroupId',
        modifierGroup.id
      ]);
      if (modGroupIndex > -1) {
        /** if modifier already exists in selectedModifiers array,
         * then replace, else push*/
        this.selectedModifiers = new PushOrReplacePipe().transform(
          this.selectedModifierGroups[modGroupIndex].modifiers,
          'modifierId',
          modifiers[0].modifierId,
          'modifierGroups',
          // objToPush
          {
            modifierId: modifiers[0].modifierId,
            quantity: modifiers[0].quantity,
            name: modifiers[0].name,
            price: modifiers[0].price,
            modifierGroups: modifiers[0].modifierGroups
          }
        );
      } else {
        this.selectedModifiers = modifiers;
      }
    } else {
      const modGroupIndex = findIndex(this.selectedModifierGroups, [
        'modifierGroupId',
        modifierGroup.id
      ]);
      if (modGroupIndex > -1) {
        this.selectedModifiers =
          this.selectedModifierGroups[modGroupIndex].modifiers;
        const modifierIndex = findIndex(this.selectedModifiers, [
          'modifierId',
          modifierId
        ]);
        if (modifierIndex > -1) this.selectedModifiers.splice(modifierIndex, 1);
      }
    }

    /** if modifierGroup already exists in selectedModifierGroups array,
     * then replace, else push*/
    this.selectedModifierGroups = new PushOrReplacePipe().transform(
      this.selectedModifierGroups,
      'modifierGroupId',
      modifierGroup.id,
      'modifiers',
      // objToPush
      {
        modifierGroupId: modifierGroup.id,
        modifiers: this.selectedModifiers
      }
    );

    removeModGroupsWithEmptyModifiers(this.selectedModifierGroups);

    this.valuesChanged.emit({
      modifierGroupId: modifierGroup.id,
      selectedModifierGroups: this.selectedModifierGroups
    });
  }

  isAddOnSelected(modifierGroupId: string): boolean {
    const selectedModifierGroup: ModGroups[] =
      this.selectedModifierGroups.filter(
        (a) => a.modifierGroupId == modifierGroupId
      );
    return selectedModifierGroup.length > 0;
  }

  getSelectedModifierGroupNames(modifierGroupId: string): string {
    let result = '';
    const selectedModifierGroup: ModGroups[] =
      this.selectedModifierGroups.filter(
        (a) => a.modifierGroupId == modifierGroupId
      );
    if (selectedModifierGroup.length > 0) {
      selectedModifierGroup[0].modifiers.forEach((modifierGroupElement) => {
        result +=
          this.itemQuantity > 1
            ? ` ${modifierGroupElement.name} x ${this.itemQuantity},`
            : ` ${modifierGroupElement.name},`;
      });
      result = result.slice(0, -1); // remove last ,
    }
    return result;
  }

  // logic to make selected modifiers inside add-on item mod groups as default
  public updateAddOnsModGroupsWithCart() {
    this.modifierGroups?.forEach((addOnModGroup) => {
      const hasAddOnModGrpInCart = find(this.selectedLineItem?.modifierGroups, [
        'modifierGroupId',
        addOnModGroup.id
      ]);
      if (hasAddOnModGrpInCart) {
        addOnModGroup.modifierGroupElements.forEach((modifierGroupElement) => {
          if (modifierGroupElement?.item) {
            const hasModInCart = find(hasAddOnModGrpInCart.modifiers, [
              'modifierId',
              modifierGroupElement.item.id
            ]);
            if (hasModInCart) {
              modifierGroupElement.item.isDefault = true;
              if (modifierGroupElement.item.modifierGroups) {
                modifierGroupElement.item.modifierGroups.forEach((modgrp) => {
                  modgrp.modifierGroupElements.forEach(
                    (flavorModGroupElement) => {
                      if (flavorModGroupElement.modifier) {
                        const cartModGrpId = findIndex(
                          hasModInCart.modifierGroups,
                          ['modifierGroupId', modgrp.id]
                        );
                        if (cartModGrpId > -1) {
                          const hasFlavorModInCart = find(
                            hasModInCart.modifierGroups[cartModGrpId].modifiers,
                            ['modifierId', flavorModGroupElement.modifier.id]
                          );
                          if (hasFlavorModInCart) {
                            flavorModGroupElement.modifier.isDefault = true;
                          } else {
                            flavorModGroupElement.modifier.isDefault = false;
                          }
                        }
                      } else if (flavorModGroupElement.item) {
                        const cartModGrpId = findIndex(
                          hasModInCart.modifierGroups,
                          ['modifierGroupId', modgrp.id]
                        );
                        if (cartModGrpId > -1) {
                          const hasFlavorModInCart = find(
                            hasModInCart.modifierGroups[cartModGrpId].modifiers,
                            ['modifierId', flavorModGroupElement.item.id]
                          );
                          if (hasFlavorModInCart) {
                            flavorModGroupElement.item.isDefault = true;
                          } else {
                            flavorModGroupElement.item.isDefault = false;
                          }
                        }
                      }
                    }
                  );
                });
              }
            } else {
              modifierGroupElement.item.isDefault = false;
            }
          }
        });
      } else {
        addOnModGroup.modifierGroupElements.forEach((modifierGroupElement) => {
          if (modifierGroupElement?.item) {
            modifierGroupElement.item.isDefault = false;
          }
        });
      }
    });
  }
}
