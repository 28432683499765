import { InjectionToken, ValueProvider } from '@angular/core';

export const NAVIGATOR = new InjectionToken<Navigator>(
  'To inject the Navigator object into components & services'
);

export class NavigatorProvider {
  public static get = (): ValueProvider => ({
    provide: NAVIGATOR,
    useValue: window.navigator
  });
}
