import { FactoryProvider, InjectionToken, Injector } from '@angular/core';

import { DOCUMENT } from '../document/document.provider';

export type ScriptLoader = (href: string) => Promise<boolean>;

export const SCRIPT_LOADER = new InjectionToken<ScriptLoader>('scriptLoader');

export class ScriptLoaderProvider {
  public static get = (): FactoryProvider => ({
    provide: SCRIPT_LOADER,
    deps: [Injector],
    useFactory: (injector: Injector) => (script: string) =>
      new Promise((resolve, reject) => {
        try {
          const _document = injector.get(DOCUMENT);
          if (
            new Array(document.scripts.length)
              .fill(-1)
              .map((i) => document.scripts.item(i))
              .some((s) => s?.src === script)
          ) {
            console.debug(`Script ${script} is already loaded.`);
            resolve(true);
          }

          const scriptEle = _document.createElement('script');
          scriptEle.type = 'text/javascript';
          scriptEle.async = false;
          scriptEle.src = script;

          scriptEle.addEventListener('load', () => {
            resolve(true);
          });

          scriptEle.addEventListener('error', (ev) => {
            console.warn(ev);
            reject(`Could not load ${script}`);
          });

          _document.head.appendChild(scriptEle);
        } catch (error) {
          console.warn(error);
          reject(`Could not load ${script}`);
        }
      })
  });
}
