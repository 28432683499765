import { Pipe, PipeTransform } from '@angular/core';

import { MenuModifierGroup } from '../../../ecomm/types/store-info.types';

@Pipe({
  name: 'onlyModifiersOfType'
})
export class OnlyModifiersOfTypePipe implements PipeTransform {
  transform(
    modifiers: MenuModifierGroup[] = [],
    ...modTypes: string[]
  ): MenuModifierGroup[] {
    return modifiers.filter((m) => modTypes.includes(m.type));
  }
}
