import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import type { Cart } from '../../../../ecomm/types/cart.types';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { CheckboxComponent } from '../../../common';
import { RoundupInfoModalComponent } from '../roundup-info-modal/roundup-info-modal.component';
import { RoundupCharity } from '../../../../ecomm/types/roundup-charity.types';

@Component({
  selector: 'wri-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DonationComponent)
    }
  ]
})
export class DonationComponent extends CheckboxComponent implements OnInit {
  @Input()
  public cart: Cart | null = null;

  @Input()
  roundupCharityData: RoundupCharity = {};
  public isLoading = false;

  constructor(
    private modalService: NgbModal,
    private cartService: CartWorkflowService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.writeValue(this.isDonationCategoryAvailableInCart());
  }

  isDonationCategoryAvailableInCart(): boolean {
    return this.cart?.fees.some((a) => a.category === 'donation') || false;
  }

  getRoundUpAmount(): number | undefined {
    let roundUpAmount;
    if (this.cart) {
      if (this.isDonationCategoryAvailableInCart()) {
        const donationFeesFromCart = this.cart?.fees.find(
          (a) => a.category === 'donation'
        )?.amount;
        roundUpAmount = donationFeesFromCart;
      } else {
        const nearestRoundUpAmount =
          Math.ceil(this.cart.total) - this.cart.total;
        roundUpAmount =
          Math.ceil(this.cart.total) === this.cart.total
            ? 1
            : nearestRoundUpAmount;
      }
    }
    return roundUpAmount;
  }

  openRoundupInfoModal() {
    const modalRef = this.modalService.open(RoundupInfoModalComponent, {
      windowClass: 'round-up-modal',
      centered: true
    });
    modalRef.componentInstance.roundupCharityData = this.roundupCharityData;
    this.analyticsService.logGaEvent({
      event: 'roundup_info_tap'
    });
  }

  private async finalizeCart(addRoundUpFee: boolean) {
    const cartFinalizeResponse = await this.cartService.finalizeCart(
      this.cart?.cartId || '',
      addRoundUpFee
    );
    if (!cartFinalizeResponse) {
      this.analyticsService.logGaEvent({
        event: 'checkout_error',
        error_reason: 'roundup_failed'
      });
    }
    if (cartFinalizeResponse?.status !== 'finalized') {
      this.router.navigate(['/order/my-bag']);
    } else {
      super.writeValue(
        cartFinalizeResponse.fees.some((a) => a.category === 'donation')
      );
    }
    this.isLoading = false;
  }

  public async finalizeCartWithRoundupForCharity(addRoundUpFee: boolean) {
    this.isLoading = true;
    this.analyticsService.logGaEvent({
      event: 'roundup_tap',
      roundup_action: addRoundUpFee ? 'checked' : 'unchecked'
    });
    await this.finalizeCart(addRoundUpFee);
  }
}
