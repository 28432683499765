import { Injectable } from '@angular/core';
import * as Workflow from '../../../ecomm/types/workflow';
import { RoundupCharityService } from '../../services/roundup-charity/roundup-charity.service';

@Injectable({ providedIn: 'root' })
export class RoundupCharityWorkflowService {
  constructor(
    private roundupCharityService: RoundupCharityService
  ) {}

  public getRoundupCharityData = () =>
    Workflow.createWorkflow(
      undefined,
      () => this.roundupCharityService.getRoundupCharityData()
    )();
}
