import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';

import {
  MaybeAnyResponse,
  MaybeDataResponse,
  MaybeErrorResponse,
  MaybeResponse
} from './maybe-response';

type ArgsMapFn<TARGS extends unknown[], TOUT> = (
  ...args: TARGS
) => Observable<TOUT>;

type ObservableMapFn<TIN, TOUT = TIN> = (
  input$: Observable<TIN>
) => Observable<TOUT>;

export type PreWorkflowFn<TARGS extends unknown[]> = ArgsMapFn<TARGS, unknown>;

export type WorkflowFn<TARGS extends unknown[], TDATA> = ArgsMapFn<
  TARGS,
  MaybeResponse<TDATA>
>;

export type PostWorkflowFn<TDATA> = ObservableMapFn<MaybeResponse<TDATA>>;

type MaybeDataResponseHandler<TDATA> = ObservableMapFn<
  MaybeDataResponse<TDATA>,
  MaybeResponse<TDATA>
>;

type MaybeErrorResponseHandler<TDATA> = ObservableMapFn<
  MaybeErrorResponse<TDATA>,
  MaybeResponse<TDATA>
>;

type MaybeAnyResponseHandler<TDATA> = ObservableMapFn<
  MaybeAnyResponse<TDATA>,
  MaybeResponse<TDATA>
>;

const defaultPreWorkflowFn =
  <TARGS extends unknown[]>(): PreWorkflowFn<TARGS> =>
  () =>
    of(null);

export const createWorkflow =
  <TARGS extends unknown[], TDATA>(
    preWorkflowFn: PreWorkflowFn<TARGS> | undefined,
    workflowFn: WorkflowFn<TARGS, TDATA>,
    ...postWorkflowFns: PostWorkflowFn<TDATA>[]
  ) =>
  (...args: TARGS): Promise<TDATA | null> =>
    firstValueFrom(
      of(args).pipe(
        switchMap((input) =>
          (preWorkflowFn ?? defaultPreWorkflowFn<TARGS>())(...input)
        ),
        switchMap(() => of(args)),
        switchMap((input: TARGS) => workflowFn(...input)),
        ...(postWorkflowFns as []),
        map((res: MaybeResponse<TDATA>) => res.data ?? null)
      )
    );

/* eslint-disable @typescript-eslint/no-explicit-any */
export const createWorkflowWithBothDataAndError =
  <TARGS extends unknown[], TDATA>(
    preWorkflowFn: PreWorkflowFn<TARGS> | undefined,
    workflowFn: WorkflowFn<TARGS, TDATA>,
    ...postWorkflowFns: PostWorkflowFn<TDATA>[]
  ) =>
  (...args: TARGS): Promise<{ data: TDATA | null; error: any }> =>
    firstValueFrom(
      of(args).pipe(
        switchMap((input) =>
          (preWorkflowFn ?? defaultPreWorkflowFn<TARGS>())(...input)
        ),
        switchMap(() => of(args)),
        switchMap((input: TARGS) => workflowFn(...input)),
        ...(postWorkflowFns as []),
        map((res: MaybeResponse<TDATA>) => ({
          data: res.data ?? null,
          error: res.error ?? null
        }))
      )
    );

export const isMaybeDataResponse = <T>(
  input: MaybeResponse<T>
): input is MaybeDataResponse<T> => !!input.data;

export const isMaybeErrorResponse = <T>(
  input: MaybeResponse<T>
): input is MaybeErrorResponse<T> => !!input.error || input.error === '';

export const isMaybeSuccessResponse = <T>(
  input: MaybeResponse<T>
): input is MaybeDataResponse<T> =>
  isMaybeDataResponse(input) && !isMaybeErrorResponse(input);

export const isMaybeFailureResponse = <T>(
  input: MaybeResponse<T>
): input is MaybeErrorResponse<T> =>
  !isMaybeDataResponse(input) && isMaybeErrorResponse(input);

export const onData =
  <T>(cb: MaybeDataResponseHandler<T>): PostWorkflowFn<T> =>
  (input$) =>
    input$.pipe(
      switchMap((input) =>
        isMaybeDataResponse(input) ? of(input).pipe(cb) : of(input)
      )
    );

export const onError =
  <T>(cb: MaybeErrorResponseHandler<T>): PostWorkflowFn<T> =>
  (input$) =>
    input$.pipe(
      switchMap((input) =>
        isMaybeErrorResponse(input) ? of(input).pipe(cb) : of(input)
      )
    );

export const onSuccess =
  <T>(cb: MaybeDataResponseHandler<T>): PostWorkflowFn<T> =>
  (input$) =>
    input$.pipe(
      switchMap((input) =>
        isMaybeSuccessResponse(input) ? of(input).pipe(cb) : of(input)
      )
    );

export const onFailure =
  <T>(cb: MaybeErrorResponseHandler<T>): PostWorkflowFn<T> =>
  (input$) =>
    input$.pipe(
      switchMap((input) =>
        isMaybeFailureResponse(input) ? of(input).pipe(cb) : of(input)
      )
    );

export const onAny = <T>(cb: MaybeAnyResponseHandler<T>): PostWorkflowFn<T> =>
  cb;
