import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output
} from '@angular/core';
import moment from 'moment';
import { formatBusinessDayTimings } from '../../../../ecomm/utils/format-business-day-timings';
import { Location } from '../../../../ecomm/types/search-location.types';

type HTMLElementWithOptionalScroll = HTMLElement & {
  scrollIntoViewIfNeeded?: HTMLElement['scrollIntoView'];
};

@Component({
  selector: 'wri-job-location-search-card',
  templateUrl: './job-location-search-card.component.html',
  styleUrls: ['./job-location-search-card.component.scss']
})
export class JobLocationSearchCardComponent {
  @Input() locationData: Location | undefined;
  @Output() navigate = new EventEmitter<string>();

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  private _showFocus = false;

  get showFocus() {
    return this._showFocus;
  }

  @Input()
  @HostBinding('class.show-focus')
  set showFocus(val: boolean) {
    this._showFocus = val;
    if (val && this.id) {
      const el: HTMLElementWithOptionalScroll | null =
        this._document.getElementById(this.id);
      if (!el) {
        return;
      }
      if (el.scrollIntoView) {
        el.scrollIntoView({ behavior: 'smooth', block: 'end' });
        return;
      }
    }
  }

  @HostBinding('attr.id')
  @HostBinding('attr.data-testid')
  get id() {
    return this.locationData?.id;
  }

  emit() {
    this.navigate.emit(this.locationData?.careerUrl || '');
  }

  get displayHours(): string {
    const hours =
      this.locationData?.storeHours?.filter(
        (h) =>
          h.startDay.toLowerCase() ===
          this.locationData?.businessDay.toLowerCase()
      ) ?? [];
    return formatBusinessDayTimings(hours);
  }

  getFormattedDate(data: string, timeZone: string) {
    const formattedDate = moment.tz(data, timeZone).format('MMMM Do');
    return formattedDate;
  }
}
