<div
  class="cross-sell-card wri-additional-recommendation-card-wrapper"
  (click)="recommendationType !== 'menu-recommendations' && getProductItemDetailsUrl()"
  [class.first-slide]="first"
  [id]="item?.id"
>
  <div
    class="card-img-left wri-card"
    [ngClass]="{
      'wri-card-with-btn': (showButton === true || showCustomizeLink === true)
    }"
  >
    <div class="card-image-title-wrapper">
      <img
        class="card-img wri-card-img"
        srcset="{{ image | imageResolution: 'srcset':recommendationType: '/assets/images/logo-green-product.png'}}"
        src="{{
          image
            | imageResolution
              : 'src'
              : recommendationType
              : '/assets/images/logo-green-product.png'
        }}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        role="img"
        alt="{{ imageDescription }}"
      />
      <h3 class="card-title wri-card-name">{{ name }}</h3>
      <span class="wri-card-price hidden"></span>
      <div class="wri-card-title-wrapper">
        <div class="wri-card-description">{{ description }}</div>
        <div *ngIf="showCaret" class="wri-card-caret-icon">
          <wri-icon class="icon arrow-right" icon="wri-arrow-right"></wri-icon>
        </div>
      </div>
    </div>

    <div class="card-btn-wrapper" *ngIf="showButton">
      <div class="wri-card-btn-wrapper wri-card-btn-carousel">
        <button tabindex="-1" class="wri-btn wri-btn-primary" aria-label="Add to Order">
          Add to Order
        </button>
      </div>
    </div>

    <div class="card-btn-wrapper" *ngIf="showCustomizeLink">
      <div class="wri-card-btn-wrapper">
        <a wriFocusOutline class="wri-btn wri-btn-text" aria-label="Customize It">
          Customize It
        </a>
      </div>
    </div>
  </div>
</div>
