<div class="charities-banner" data-testid="charities-section">
  <div class="charities-banner__inner">
    <img
      src="assets/images/charities_logo-white.png"
      alt="Wingstop Charities"
    />
    <div>
      <p class="mb-0">
        Wingstop Charities positively impacts communities through partnerships
        with local organizations.
      </p>
      <a
        class="btn"
        href="https://wingstopcharities.org/"
        target="_blank"
        aria-label="See How, opens in a new tab"
      >
        See How
        <wri-icon icon="wri-arrow-back" class="arrow-right-icon"></wri-icon>
      </a>
    </div>
  </div>
</div>
