<div class="checkbox-control-container">
  <input
    [id]="id"
    [attr.data-testid]="id | prepend : 'input-for-'"
    class="wri-custom-checkbox-control"
    tabindex="0"
    [value]="value"
    [checked]="value"
    [disabled]="disabled"
    [class.can-show-errors]="canShowErrors"
    (change)="writeValue($event.target.checked)"
    (blur)="onTouched()"
    (focus)="onTouched()"
    type="checkbox"
    [attr.aria-label]="label | prepend : 'chk-input-'"
  />
  <label
    [ngClass]="{
      'payment-option-label': customClass === 'paymentLabel'
    }"
    class="wri-custom-control custom-checkbox"
    [attr.for]="id"
    [attr.data-testid]="id | prepend : 'label-for-'"
  >
    {{ label }}
  </label>
</div>
