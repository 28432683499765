import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

import { CacheLoadedFeature } from '../../../ecomm/store/features/cache-loaded';

export const cacheLoadedGuard: CanActivateFn = async () => {
  const store: Store = inject(Store);
  return await firstValueFrom(
    store.pipe(select(CacheLoadedFeature.selectCacheLoaded), filter(Boolean))
  );
};
