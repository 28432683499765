import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';

@Component({
  selector: 'wri-partial-outage-modal',
  templateUrl: './partial-outage-modal.component.html',
  styleUrls: ['./partial-outage-modal.component.scss']
})
export class PartialOutageModalComponent {
  public static readonly defaultExpireTimeInSecs = 1800;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;

  public outage_message_title: string | undefined;
  public outage_message_description: string | undefined;
  private outage_message_ttl_seconds: string | undefined;

  constructor(
    private modalService: NgbModal,
    private asyncDispatcher: AsynchronousDispatcher
  ) {}

  public closeModal() {
    this.modalService.dismissAll();
  }

  private openModal(): void {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
    const currentTime = moment().toISOString();
    this.asyncDispatcher.dispatch(
      RegionalConfigurationFeature.actions.setOutagemessagepresenteddate({
        outageMessagePresentedDate: currentTime
      })
    );
  }

  public showModal(
    regionalConfigState: RegionalConfigurationFeatureState
  ): void {
    if (regionalConfigState.regionalConfigurationOptions) {
      this.outage_message_title = regionalConfigState.regionalConfigurationOptions['outage_message_title']?.value;
      this.outage_message_description = regionalConfigState.regionalConfigurationOptions['outage_message_description']?.value;
      this.outage_message_ttl_seconds = regionalConfigState.regionalConfigurationOptions['outage_message_ttl_seconds']?.value;
      const outageMessagePresentedDate = regionalConfigState.outageMessagePresentedDate;

      if (
        this.outage_message_title !== undefined &&
        this.outage_message_description !== undefined
      ) {
        if (outageMessagePresentedDate) {
          const now = new Date();
          const storedExpiryTime = new Date(outageMessagePresentedDate);
          const timeElapsedInSeconds = (now.getTime() - storedExpiryTime.getTime()) / 1000;
          const expiryTime = this.outage_message_ttl_seconds ?
            Number(this.outage_message_ttl_seconds) :
            PartialOutageModalComponent.defaultExpireTimeInSecs;

          if (timeElapsedInSeconds >= expiryTime) {
            this.openModal();
          }
        } else {
          this.openModal();
        }
      }
    }
  }
}
