/* eslint-disable @ngrx/no-store-subscription */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/no-typed-global-store */
import { ClassProvider, Inject, Injectable } from '@angular/core';
import { AsynchronousDispatcher } from '../asynchronus-dispatcher/asynchronous-dispatcher.service';

/// TODO remove
@Injectable()
export class StoreSnapshotService<TSTATE = Record<string, unknown>> {
  constructor(
    @Inject(AsynchronousDispatcher)
    private asyncDispatcher: AsynchronousDispatcher<TSTATE>
  ) {}

  public async get(): Promise<TSTATE> {
    return (await this.asyncDispatcher.dispatch({ type: '' })) as TSTATE;
  }
}

export class StoreSnapshotServiceProvider {
  static get(): ClassProvider {
    return {
      provide: StoreSnapshotService,
      useClass: StoreSnapshotService
    };
  }
}
