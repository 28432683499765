import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Type } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxSliderModule } from "ngx-slider-v2";

import { EcommAPIConfigProvider } from "../../ecomm/config/ecomm-config.provider";
import { EcommFeatureFlagsProvider } from "../../ecomm/config/ecomm-feature-flags.provider";
import { EcommModule } from "../../ecomm/ecomm.module";
import { Config, ConfigProvider } from "../../ecomm/providers/config/config.provider";
import { ILegacyAnalyticsService, LegacyAnalyticsServiceProvider } from "../../ecomm/providers/legacy-providers/analytics.service";
import { ILegacySeoService, LegacySeoServiceProvider } from "../../ecomm/providers/legacy-providers/seo.service";
import { WriCommonModule } from "../common/common.module";
import { ConnectedFlavorsPageComponent } from "./components/connected-flavors-page/connected-flavors-page.component";
import { FlavorsPageRouting } from "./flavors.routes";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxSliderModule,
    WriCommonModule,
    EcommModule,
    FlavorsPageRouting
  ],
  declarations: [ConnectedFlavorsPageComponent],
  providers: []
})
export class FlavorsModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<FlavorsModule> {
    return {
      ngModule: FlavorsModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}