<ng-container *ngIf="flavors.length > 0">
  <wri-flavor-spice-heading
    [heading]="heading"
    [iconColor]="iconColor"
    [type]="type"
    (selectedSpiceHeading)="selectedSpiceHeading()"
  ></wri-flavor-spice-heading>

  <div
    *ngIf="flavors.length > 0"
    class="spacing flavor-mod-group-elements-container"
  >
    <ng-container *ngFor="let item of flavors">
      <div
        class="flavor-mod-group-element"
        wriInteractionStudio="data-chainoptionid"
        [interactionStudioId]="item.id"
      >
        <label
          class="flavor-mod-group-wrapper"
          [for]="'radio-' + item.id"
          [attr.data-disabled]="item?.outOfStock"
        >
          <div
            *ngIf="item.images && item.images.length > 0"
            class="mod-image-container"
          >
            <img
              aria-live="polite"
              class="flavor-image"
              srcset="{{ item?.images[0]?.uri | imageResolution: 'srcset':'pdp-modifiers': '/assets/images/logo-green-product.png'}}"
              src="{{
                item?.images[0]?.uri
                  | imageResolution
                    : 'src'
                    : 'pdp-modifiers'
                    : '/assets/images/logo-green-product.png'
              }}"
              onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
              role="img"
              alt="{{ item?.name | prepend : 'image-for' }}"
            />
          </div>

          <div class="mod-elements-details radio-control-container">
            <div>
              <span
                class="mod-name"
                [ngClass]="
                  item.price || item?.minCalories || item?.maxCalories
                    ? 'mod-name-top'
                    : ''
                "
                data-testid="mod-name"
              >
                {{ item.name }}
              </span>
              <ng-container *ngIf="item?.outOfStock; else heatScale">
                <wri-unavailable-item></wri-unavailable-item>
              </ng-container>
              <ng-template #heatScale>
                <div class="mod-meta-container">
                  <wri-heat-scale
                    [heatScale]="item | heatScale"
                  ></wri-heat-scale>
                  <wri-designation
                    [rubType]="item?.metadata['rub-type']"
                    [isPopular]="item?.isPopular"
                    [isNew]="item?.isNew"
                  >
                  </wri-designation>
                </div>
                <div
                  *ngIf="item?.minCalories !== null || item?.maxCalories !== null"
                >
                  <span class="mod-calories">
                    {{ returnCalorieRange(item) }}
                  </span>
                </div>
              </ng-template>
            </div>
            <input
              class="mod-elements-radio-input"
              [name]="'addon-flavors'"
              [id]="'radio-' + item.id"
              [disabled]="item?.outOfStock"
              [attr.data-testid]="item.id | prepend : 'input-for-'"
              tabindex="0"
              type="radio"
              [attr.aria-label]="item.name"
              [checked]="isItemChecked(item.id)"
              (change)="onChangeAddOnFlavor($event.target.checked, item)"
              (blur)="onTouched()"
              (focus)="onTouched()"
            />
          </div>
        </label>

        <hr class="wri-divider" aria-hidden="true" />
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- Flavor Modal -->
<wri-flavor-modal
  [limited]="limited"
  [hot]="hot"
  [medium]="medium"
  [mild]="mild"
></wri-flavor-modal>
