<div class="wri-addon-wrapper">
  <ng-container
    *ngFor="let modifierGroup of modifierGroups; trackBy: trackByModifierGroup"
  >
    <div class="add-on-item-wrapper">
      <div class="add-on-item-container">
        <div
          wriInteractionStudio="data-chainmodifierid"
          [interactionStudioId]="modifierGroup.id"
        >
          <span class="wri-title">{{ modifierGroup.name }}</span>
          <div class="wri-selected" *ngIf="isAddOnSelected(modifierGroup.id)">
            Selected: {{ getSelectedModifierGroupNames(modifierGroup.id) }}
          </div>
          <ngx-slick-carousel
            class="cross-sell-deck carousel"
            [config]="slideConfig"
            #slickModal="slick-carousel"
          >
            <div
              ngxSlickItem
              *ngFor="
                let modifierGroupElement of modifierGroup.modifierGroupElements;
                trackBy: trackByItem;
                let first = first
              "
              class="slide tabfocus"
            >
              <wri-add-on-card
                [item]="modifierGroupElement.item"
                [first]="first"
                [image]="
                  modifierGroupElement.item.images.length > 0
                    ? modifierGroupElement.item.images[0].uri
                    : '/assets/images/logo-green-product.png'
                "
                [imageDescription]="
                  modifierGroupElement?.item.description | prepend : 'image-for'
                "
                [name]="modifierGroupElement.item.name"
                [description]="
                  modifierGroupElement.item.shortDescription ||
                  modifierGroupElement.item.description
                "
                [price]="modifierGroupElement.item.price"
                [addOnModifiersGroups]="
                  modifierGroupElement.item.modifierGroups
                "
                [calorieRange]="returnCalorieRange(modifierGroupElement.item)"
                [parentItemName]="modifierGroup.name"
                (valuesChanged)="
                  handleModifierGroupData(
                    $event,
                    modifierGroup,
                    modifierGroupElement.item.id
                  )
                "
              ></wri-add-on-card>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </ng-container>
</div>
