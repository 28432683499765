<div class="offline-container">
  <div class="image-wrapper">
    <img
      *ngIf="regionalConfigState?.offlineDisplay?.imageUrl"
      alt="offline-image"
      [src]="
        regionalConfigState?.offlineDisplay?.imageUrl ||
        '/assets/images/logo-green-product.png'
      "
      role="img"
      onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'" />
  </div>
  <div class="title">{{ regionalConfigState?.offlineDisplay?.title }}</div>
  <div class="desc">
    <ng-container *ngIf="regionalConfigState?.offlineDisplay?.description">
      {{ regionalConfigState.offlineDisplay.description }}
      <br />
      Please refresh your browser to try again.
    </ng-container>
  </div>
</div>
