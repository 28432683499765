import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';

import {
  ECOMM_API_CONFIG,
  EcommAPIConfig
} from '../../config/ecomm-config.provider';
import { MaybeResponse } from '../../types/maybe-response';
import {
  OrderDto,
  OrderPendingDto,
  OrderResponse,
  OrderSubmitAction,
  OrderSubmitRequest,
  OrderSubmitResponse,
  OrderSuccessDto
} from '../../types/order.types';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';
import { handleAPIResponse } from '../../utils/handle-api-response';
import { handleWebSocketResponse } from '../../utils/handle-websocket-response';
import { WebSocketClient } from '../../web-socket/web-socket.client';
import { OrderFeature } from '../../store/features/order';
import { AsynchronousDispatcher } from '../../utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { AuthFeatureState } from '../../store/features/auth';
import { EcommWebSocketResponse } from '../../types/common.types';
import { User } from 'oidc-client-ts';
import { Router } from '@angular/router';
import { AuthService } from '../../utils/auth/auth.service';
import { AnalyticsService } from '../../providers/legacy-providers/analytics.service';

@Injectable({ providedIn: 'root' })
export class OrderRepository {
  private static SUBMIT_ORDER_TIMEOUT_MS = 75_000;
  private static ORDER_NOT_SUBMITTED_ERROR =
    'We were unable to submit your order. Please try again.';
  private static ORDER_SUBMITTED_STATUS = 'scheduled';

  constructor(
    private http: HttpClient,
    private webSocketClient: WebSocketClient<
      OrderSubmitRequest,
      OrderSubmitResponse
    >,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService,
    @Inject(AsynchronousDispatcher)
    private asynchronousDispatcher: AsynchronousDispatcher<AuthFeatureState>,
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  public getByOrderId(
    orderId: string,
    fromConfirmationPage?: boolean
  ): Observable<MaybeResponse<OrderDto>> {
    const url = fromConfirmationPage
      ? `${this.config.baseUrl}/api/orders/${orderId}`
      : `${this.config.baseUrl}/api/customer/orders/${orderId}`;
    return this.http
      .get<OrderResponse>(url)
      .pipe(
        handleAPIResponse(
          OrderResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public submitOrder(
    request: OrderSubmitRequest
  ): Observable<MaybeResponse<OrderDto>> {
    const [closeFn, responses$] = this.webSocketClient.publish(
      request,
      (() =>
        this.getByOrderId(request.request.transactionId).pipe(
          map((res: MaybeResponse<OrderDto>) => ({
            type: OrderSubmitAction.orderManuallyFetched,
            error: null,
            data: res.data
          }))
        )).bind(this),
      OrderRepository.SUBMIT_ORDER_TIMEOUT_MS
    );
    return responses$.pipe(
      map((res) => {
        if (
          res.type === OrderSubmitAction.orderPending &&
          (res.data || res.error)
        ) {
          // handle account creation here
          this.handleAccountCreation(res);
        }
        return res;
      }),
      handleWebSocketResponse(
        OrderSubmitResponse,
        [
          OrderSubmitAction.orderFailed,
          OrderSubmitAction.orderScheduled,
          OrderSubmitAction.orderManuallyFetched
        ],
        OrderSubmitAction.orderFailed,
        closeFn,
        this.featureFlagService.featureFlags.enableDevLogs
      ),
      switchMap((res) =>
        of({
          ...res,
          error: res.error
            ? res.error
            : OrderSuccessDto.is(res.data) &&
              res.data.status !== OrderRepository.ORDER_SUBMITTED_STATUS
            ? OrderRepository.ORDER_NOT_SUBMITTED_ERROR
            : res.type === OrderSubmitAction.orderManuallyFetched &&
              res.data === undefined
            ? OrderRepository.ORDER_NOT_SUBMITTED_ERROR
            : undefined
        })
      )
    );
  }

  private async handleAccountCreation(
    res: EcommWebSocketResponse<string, OrderPendingDto>
  ) {
    await this.asynchronousDispatcher.dispatch(
      OrderFeature.actions.setAccountCreation({
        accountCreation: {
          error: res.error ?? undefined,
          data: res.data ?? undefined
        }
      })
    );
    if (res.data) {
      this.analyticsService.logGaEvent({
        event: 'sign_up',
        method: 'email',
        source: 'guest_checkout'
      });
      const { idToken, accessToken, refreshToken } = res.data;
      const profile =
        accessToken &&
        JSON.parse(decodeURIComponent(atob(accessToken.split('.')[1])));

      const user = new User({
        id_token: idToken,
        access_token: accessToken ?? '',
        refresh_token: refreshToken,
        profile: profile,
        token_type: 'Bearer'
      });

      await this.authService.userManager.storeUser(user).then(async () => {
        await this.authService.saveAuthState(this.router.url);
      });
    }
  }

  public cancelOrder(orderId: string): Observable<MaybeResponse<OrderDto>> {
    const url = `${this.config.baseUrl}/api/orders/${orderId}/cancel`;
    return this.http
      .post<OrderResponse>(url, {})
      .pipe(
        handleAPIResponse(
          OrderResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }
}
