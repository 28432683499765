import { BaseModel } from '@wingstop/models/base.model';

export class DeliveryAddress extends BaseModel {
  id: number = 0;
  building: string = null;
  streetaddress: string = null;
  city: string = null;
  state: string = null;
  zipcode: string = null;
  phonenumber: string = null;
  specialinstructions: string = null;
  isdefault: boolean = true;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
