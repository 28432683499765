<div class="wri-empty-favorite-locations-wrapper">
  <div class="wri-location-wrapper icon-round spacing-bottom">
    <wri-icon icon="wri-location"></wri-icon>
  </div>

  <span class="favorite-locations-empty-text" data-testid="favorite-locations-empty-text"
    >No Favorite Locations</span
  >
  <span class="no-favorite-locations-text" data-testid="no-favorite-locations-text"
    >You don't have any favorite stores yet. Click the heart icon on your nearby stores to favorite and quickly reorder from
    them.</span
  >
  <button
    wriFocusOutline
    data-testid="start-order"
    [ngClass]="'wri-btn wri-btn-primary'"
    aria-label="Start Order"
    (click)="goToOrderPage()"
  >
    Start Order
  </button>
</div>
