<div class="wri-modal__wrapper">
  <div data-testid="remove-favorite-location-modal-title" class="wri-modal__title">
    Remove Location?
  </div>
  <div
    data-testid="remove-favorite-location-modal-description"
    class="wri-modal__description"
  >
    {{ getModalBody() }}
  </div>
  <div class="wri-modal__btn-wrapper">
    <button
      [disabled]="isLoading"
      data-testid="remove-favorite-location-modal-remove"
      class="wri-btn wri-btn-primary"
      aria-label="Remove"
      (click)="removeFavoriteLocation($event)"
    >
      <ng-container *ngIf="!isLoading"> Remove </ng-container>
      <div
        *ngIf="isLoading"
        class="spinner-wrapper-overlay"
      >
        <wri-spinner spinnerColor="white"></wri-spinner>
      </div>
    </button>
    <button
      data-testid="remove-favorite-location-modal-cancel"
      class="wri-btn wri-btn-tertiary"
      aria-label="Cancel"
      (click)="closeModal()"
    >
      Cancel
    </button>
  </div>
</div>
