<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<div class="delivery-instructions-form">
  <hr class="wri-divider" />
  <h3 class="form-title">Delivery Instructions</h3>
  <form [formGroup]="deliveryInstructionsForm">
    <wri-text-input
      [id]="'secondary-address'"
      label="Building/Suite/Apt"
      placeholder="e.g. Apt 123"
      formControlName="secondaryAddress"
      maxlength="30"
      (focusTextValue)="secondaryAddressFieldFocus()"
      (blurTextValue)="onBlurTextValueEmit($event, 'secondaryAddress')"
    >
      <wri-form-error
        [control]="deliveryInstructionsForm.controls['secondaryAddress']"
        errorKey="pattern"
        display="Address contains invalid characters"
      ></wri-form-error>
    </wri-text-input>

    <wri-text-input
      [id]="'delivery-instructions'"
      label="Special Instructions"
      placeholder="e.g. Leave it at my door"
      formControlName="deliveryInstructions"
      maxlength="128"
      (focusTextValue)="deliveryInstructionsFieldFocus()"
    >
      <wri-form-error
        [control]="deliveryInstructionsForm.controls['deliveryInstructions']"
        errorKey="pattern"
        display="Must be valid instructions"
      ></wri-form-error>
    </wri-text-input>

    <!-- Request No-contact delivery checkbox input field -->
    <span class="contactless-delivery-wrapper">
      <wri-checkbox
        [id]="'contactless-delivery'"
        class="span-full-width-lg"
        label="Request No-Contact Delivery."
        formControlName="isContactlessDelivery"
      ></wri-checkbox>
      <a
        wriFocusOutline
        role="button"
        class="info-icon contactless-delivery-info-icon"
        (click)="openModal()"
      >
        <wri-icon
          icon="wri-info"
          role="text"
          aria-label="request no contact delivery"
        ></wri-icon>
      </a>
    </span>
  </form>

  <ng-template
    #noContactDeliveryInfoModal
    let-close="close"
    let-dismiss="dismiss"
  >
    <wri-common-modal [close]="close" [dismiss]="dismiss">
      <div class="wri-modal__wrapper">
        <span class="wri-modal__title">
          No-Contact Delivery
        </span>
        <div class="wri-modal__description">
          If you prefer a no-contact delivery, select the Request No-Contact
          Delivery option and include details about the drop-off location.
        </div>
      </div>
    </wri-common-modal>
  </ng-template>
</div>
