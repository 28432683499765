import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { FeatureState } from '../../../../ecomm/store/types/feature-state';
import { AccountCreationDuringCheckout, Order } from '../../../../ecomm/types/order.types';

const orderFeatureStateKey = 'lastSessionOrder';
const accountCreationOrderFeatureStateKey = 'accountCreation';

export type OrderFeatureState = FeatureState<
  Order,
  typeof orderFeatureStateKey
  > & FeatureState<
    AccountCreationDuringCheckout,
    typeof accountCreationOrderFeatureStateKey
  >;

const initialOrderFeatureState: OrderFeatureState = {
  isLoading: false,
  error: null,
  lastSessionOrder: null,
  accountCreation: null
};

const OrderActions = createActionGroup({
  source: 'Order Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{ error?: string; lastSessionOrder?: Order; }>(),
    'Set Account Creation': props<{ accountCreation?: AccountCreationDuringCheckout }>(),
    'Reset To Default': emptyProps()
  }
});

const OrderReducer = createReducer(
  initialOrderFeatureState,
  on(
    OrderActions.setIsLoading,
    (state): OrderFeatureState => ({ ...state, isLoading: true })
  ),
  on(
    OrderActions.setState,
    (state, action): OrderFeatureState => ({
      ...state,
      isLoading: false,
      error: action.error ?? null,
      lastSessionOrder: action.lastSessionOrder ?? null
    })
  ),
  on(
    OrderActions.setAccountCreation,
    (state, action): OrderFeatureState => ({
      ...state,
      accountCreation: action.accountCreation ?? null
    })
  ),
  on(
    OrderActions.resetToDefault,
    (state): OrderFeatureState => ({
      ...state,
      ...initialOrderFeatureState
    })
  )
);

const OrderFeatureKey = 'order';

const OrderFeature = createFeature({
  name: OrderFeatureKey,
  reducer: OrderReducer
});

export default {
  ...OrderFeature,
  actions: OrderActions,
  initialState: initialOrderFeatureState
};
