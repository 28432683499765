import { Pipe, PipeTransform } from '@angular/core';

import { StoreDetailsHandoffMode } from '../../../ecomm/types/store-info.types';

@Pipe({
  name: 'isPaymentMethodSupported'
})
export class PaymentMethodSupportedPipe implements PipeTransform {
  transform(
    input: string,
    storeHandoffModes: StoreDetailsHandoffMode[],
    selectedHandoffMode: string
  ): {
    hasValidPayment: boolean;
    selectedHandoffModeDetails: StoreDetailsHandoffMode | null;
  } {
    if (selectedHandoffMode) {
      const supportedPayments = storeHandoffModes.find((mode) => {
        return (
          mode.handoffMode.toLowerCase() === selectedHandoffMode.toLowerCase()
        );
      });

      return {
        hasValidPayment:
          supportedPayments?.paymentTypes?.includes(input) || false,
        selectedHandoffModeDetails: supportedPayments || null
      };
    }
    return { hasValidPayment: false, selectedHandoffModeDetails: null };
  }
}
