<div
  *ngIf="!isReadOnly"
  class="cart-fulfillment-time"
  xmlns="http://www.w3.org/1999/html"
>
  <div class="div-left">
    <span tabindex="0">Time:</span>
  </div>
  <div class="div-right">
    <div
      class="radio-panel"
      wriFocusOutline
      role="radiogroup"
      aria-labelledby="time-heading"
    >
      <label class="radio-control my-custom-radio">
        <input
          class="radio-control-input"
          type="radio"
          name="time-select-type"
          [value]="'asap'"
          [checked]="radioMode === 'asap'"
          [disabled]="!asapIsAvailable"
          (change)="onRadioChange($event)"
          data-testid="asap-radio"
        />

        <span class="radio-control-label">ASAP</span>
      </label>
      <label class="radio-control my-custom-radio">
        <input
          class="radio-control-input"
          type="radio"
          name="time-select-type"
          [value]="'later'"
          [checked]="radioMode === 'later'"
          (change)="onRadioChange($event)"
          data-testid="later-radio"
        />
        <span class="radio-control-label">Later</span>
      </label>
    </div>
  </div>
</div>

<div
  [ngClass]="{
    'scheduled-ft-readonly': isReadOnly,
    'scheduled-ft': !isReadOnly,
    'error-border': error$.value
  }"
  [ngStyle]="{ cursor: radioMode === 'later' ? 'pointer' : 'auto' }"
  class="spacing"
  *ngIf="!showFutureDateTime"
  data-testid="scheduled-ft"
>
  <div *ngIf="isLoading" class="spinner-wrapper">
    <wri-spinner></wri-spinner>
  </div>
  <div class="scheduled-date-time" *ngIf="!isLoading && (isReadOnly || radioMode === 'asap')">
    <span class="scheduled-ft-text">
      {{ prepTimeText }}
    </span>
  </div>
</div>
<span *ngIf="error$.value && !showFutureDateTime" class="error-text" data-testid="error-text">{{error$.value}}</span>

<wri-future-fulfillment-date-time
  *ngIf="showFutureDateTime"
  [fulfillmentTimes]="fulfillmentTime"
  (futureDateTime)="onFutureDateChange($event)"
  (isSelectedDateTimeValid)="isSelectedDateTimeValid($event)"
  [cartFulfillmentTime]="cartFulfillmentTime"
  [errorText]="error$ | async"
  [fulfillmentLaterFlowStateOpen]="fulfillmentLaterFlowStateOpen"
  [ftForLater]="ftForLater"
></wri-future-fulfillment-date-time>

<div
  *ngIf="!isReadOnly && !asapIsAvailable"
  class="asap-unavailable spacing"
  [class.future-fulfillment-selected]="!!cartFulfillmentTime"
  data-testid="asap-unavailable-text"
>
  <div class="asap-unavailable-title"> ASAP Unavailable </div>
  <div class="asap-unavailable-subtitle">
    {{
    !!cartFulfillmentTime
      ? 'We are not currently accepting ASAP orders.'
      : 'We are not currently accepting ASAP orders. Please select a later time to continue with your order.'
    }}
  </div>
</div>
