<nav aria-label="breadcrumb">
  <ol class="wri-breadcrumb-list">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <li
        class="wri-breadcrumb-item"
        data-testid="breadcrumb-item"
        [ngClass]="
          breadcrumb.displayType === 'icon' ? 'wri-breadcrumb-img' : ''
        "
      >
        <a
          wriFocusOutline
          class="wri-breadcrumb-text"
          data-testid="breadcrumb-list-anchor"
          rel="noopener noreferrer"
          tabindex="0"
          role="link"
          [attr.aria-label]="
            breadcrumb.display | split : '-' | join : ' ' | titlecase
          "
          (click)="breadcrumb.onClick()"
        >
          <ng-container
            *ngIf="breadcrumb.displayType === 'icon'; then isIcon; else isLabel"
          ></ng-container>
          <ng-template #isIcon>
            <wri-icon [icon]="breadcrumb.display"></wri-icon>
          </ng-template>
          <ng-template #isLabel>
            {{ breadcrumb.display }}
          </ng-template>
        </a>
      </li>

      <li
        *ngIf="breadcrumb.displaySeparator"
        class="wri-breadcrumb-separator wri-breadcrumb-item"
        data-testid="breadcrumb-item"
        aria-hidden="true"
      >
        {{ separator }}
      </li>
    </ng-container>
  </ol>
</nav>
