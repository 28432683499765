import { AppState } from './app-state.model';
import { AppStateActions } from './app-state.actions';

export const AppStateInitial = new AppState();

export const AppStateReducer = (
  state: AppState = AppStateInitial,
  action: any
): AppState => {
  switch (action.type) {
    case AppStateActions.SET_LOCATION:
      return { ...state, ...state, ...{ selectedLocation: action.payload } };
    case AppStateActions.SET_BASKET:
      return { ...state, ...state, ...{ basket: action.payload } };
    case AppStateActions.SET_ERRORS:
      return { ...state, ...state, ...{ errors: action.payload } };
    case AppStateActions.AUTHENTICATE:
      return { ...state, ...state, ...{ authentication: action.payload } };
    case AppStateActions.RECENT:
      return { ...state, ...state, ...{ recent: action.payload } };
    case AppStateActions.OPEN_ALERT_MODAL:
      return {
        ...state,
        ...state,
        ...{ openAlert: true, alertContent: action.payload },
      };
    case AppStateActions.USER_LOGGED_OUT:
      return { ...state, ...state, ...{ logout: true } };
    case AppStateActions.SET_CMS_OFFER:
      return { ...state, ...state, ...{ cmsOffer: action.payload } };
    case AppStateActions.SET_CMS_OFFER_REDEEM_CODE:
      return { ...state, ...state, ...{ cmsOfferRedeemCode: action.payload } };
    case AppStateActions.SET_CMS_OFFER_TIMESTAMP:
      return { ...state, ...state, ...{ cmsOfferTimestamp: action.payload } };
    case AppStateActions.SET_USER_FIRST_THREE_MONTHS:
      return {
        ...state,
        ...state,
        ...{ userFirstThreeMonths: action.payload },
      };
    case AppStateActions.SET_IS_DIGITAL_MENU:
      return { ...state, ...state, ...{ isDigitalMenu: action.payload } };
    case AppStateActions.SET_SHOW_APP_BANNER:
      return { ...state, ...state, ...{ showAppBanner: action.payload } };
    case AppStateActions.SET_APP_BANNER_CLOSED_BY_USER:
      return {
        ...state,
        ...state,
        ...{ appBannerClosedByUser: action.payload },
      };
    case AppStateActions.SET_USER_LOCALE:
      return { ...state, ...state, ...{ userLocale: action.payload } };
    case AppStateActions.SET_S3_SEO_METADATA:
      return { ...state, ...state, ...{ s3SeoMetadata: action.payload } };
    case AppStateActions.SET_PILOT_PROGRAM_FEATURE_FLAGS:
      return { ...state, ...state, ...{ pilotProgram: action.payload } };
    case AppStateActions.SET_PILOT_PROGRAM_USER:
      return { ...state, ...state, ...{ pilotProgramUser: action.payload } };
    case AppStateActions.SET_LAST_FORCE_LOGOUT_DATE:
      return { ...state, ...{ lastForceLogoutDate: action.payload } };
    default:
      return state;
  }
};
