import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { CartFeature } from '../../../../ecomm/store/features/cart';
import {
  CustomerOrders,
  ReOrderPostRequest
} from '../../../../ecomm/types/customer.types';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import {
  ReorderConfirmationModalComponent,
  ReorderModalData
} from '../reorder-confirmation-modal/reorder-confirmation-modal.component';
import moment from 'moment-timezone';

@Component({
  selector: 'wri-past-order-summary',
  templateUrl: './past-order-summary.component.html',
  styleUrls: ['./past-order-summary.component.scss']
})
export class PastOrderSummaryComponent {
  private static readonly DELIVERY = 'delivery';
  private static readonly CARRYOUT = 'carryout';

  @ViewChild(ReorderConfirmationModalComponent)
  reorderConfirmationModal!: ReorderConfirmationModalComponent;

  @Input() pastOrders: CustomerOrders[] | undefined;

  reorderInProgress = false;
  selectedOrderId = '';

  constructor(
    private router: Router,
    private store: Store,
    private customerWorkflowService: CustomerWorkflowService,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private analyticsService: AnalyticsService
  ) {}

  getOrderScheduledTime(scheduledDate: string, timeZone: string): string {
    const d = moment.tz(scheduledDate, timeZone);
    const today = moment().tz(timeZone).startOf('day');

    if (d.isSame(today, 'day')) {
      return 'Today';
    }

    return d.format('MMMM Do');
  }

  getHandoffMode(handoffMode: string) {
    if (handoffMode.toLowerCase() == PastOrderSummaryComponent.DELIVERY) {
      return 'Delivery to';
    }
    if (handoffMode.toLowerCase() == PastOrderSummaryComponent.CARRYOUT) {
      return 'Carryout from';
    }
    return '';
  }

  navigateToDetailsPage(id: string) {
    this.router.navigate([`/order/recent/order-details/${id}`]).then();
  }

  async onReorderClick(
    orderId: string,
    orderLocationId: string,
    orderHandoffMode: string
  ) {
    this.selectedOrderId = orderId;
    const currentCart = await firstValueFrom(
      this.store.select(CartFeature.selectCart)
    );
    if (currentCart?.status === undefined || currentCart?.status === 'empty') {
      await this.reorder(orderId, orderLocationId, orderHandoffMode);
    } else {
      const reorderModalData = {
        orderId: orderId,
        orderLocationId: orderLocationId,
        orderHandoffMode: orderHandoffMode
      } as ReorderModalData;
      this.reorderConfirmationModal.openModal(reorderModalData);
    }
  }

  async onReorderConfirmation($event: ReorderModalData) {
    await this.reorder(
      $event.orderId,
      $event.orderLocationId,
      $event.orderHandoffMode
    );
  }

  async reorder(
    orderId: string,
    orderLocationId: string,
    orderHandoffMode: string
  ) {
    this.analyticsService.logGaEvent({
      event: 'recent_order_again'
    });

    this.analyticsService.logGaEvent({
      event: 'reorder'
    });

    this.reorderInProgress = true;
    const reOrderPostRequest: ReOrderPostRequest = {
      orderId: orderId
    };
    const cart = await this.customerWorkflowService.reorder(reOrderPostRequest);
    if (cart) {
      this.reorderConfirmationModal.closeModal();
      await this.storeInfoWorkflowService.getStoreInfoById(
        orderLocationId,
        orderHandoffMode
      );
      this.router.navigate(['/order/my-bag']);
    }
    this.reorderInProgress = false;
  }
}
