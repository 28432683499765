import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { StoreInfoFeature } from '../../../../ecomm/store/features/store-info';
import type { MenuItem } from '../../../../ecomm/types/store-info.types';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';

@Component({
  selector: 'wri-additional-recommendation-card',
  templateUrl: './additional-recommendation-card.component.html',
  styleUrls: ['./additional-recommendation-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalRecommendationCardComponent {
  @Input() first = false;
  @Input() image: string | undefined;
  @Input() imageDescription: string | undefined;
  @Input() name: string | undefined;
  @Input() description: string | undefined;
  @Input() item: MenuItem | undefined;
  @Input() locationSlug: string | undefined;
  @Input() categorySlug: string | undefined;
  @Input() productSlug: string | undefined;
  @Input() itemSlug?: string | undefined;
  @Input() showCaret = false;
  @Input() showButton = false;
  @Input() recommendationType: string | undefined;
  @Input() storeName: string | undefined;
  @Input() handoffMode: string | undefined;
  @Input() showCustomizeLink = false;

  constructor(
    private router: Router,
    private asyncDispatcher: AsynchronousDispatcher,
    private analyticsService: AnalyticsService
  ) {}

  logGAEvent() {
    try {
      if (this.recommendationType === 'cart-recommendations') {
        // log cart GA event
        this.analyticsService.logGaEvent({
          event: 'cross_sell_view',
          currency: 'USD',
          quantity: 1,
          item_id: this.item?.id || '',
          item_name: this.item?.name || '',
          item_category: this.categorySlug || '',
          order_method: this.handoffMode || 'carryout',
          store_name: this.storeName || ''
        });
      } else if (this.recommendationType === 'menu-recommendations') {
        // Log menu GA event
        this.analyticsService.logGaEvent({
          event: 'menu_featured_product',
          product_name: this.item?.name || ''
        });
      } else if (this.recommendationType === 'home-recommendations') {
        // Log homepage GA event
        this.analyticsService.logGaEvent({
          event: 'product_homepage_click',
          component: 'Main Carousel',
          item_name: this.item?.name || ''
        });
      }
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  getProductItemDetailsUrl() {
    this.logGAEvent();
    this.asyncDispatcher.dispatch(
      StoreInfoFeature.actions.setState({
        selectedItem: this.item ?? null
      })
    );

    if (this.locationSlug) {
      const url = this.itemSlug
        ? `/location/${this.locationSlug}/menu/${this.categorySlug}/${this.productSlug}/${this.itemSlug}`
        : `/location/${this.locationSlug}/menu/${this.categorySlug}/${this.productSlug}`;

      this.router.navigate([url], {
        state: { item: this.item, categorySlug: this.categorySlug }
      });
    } else {
      this.router.navigate(['/order'], {
        queryParams: {
          product: this.productSlug
        }
      });
    }
  }
}
