import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { State } from '@ngrx/store';

import { IAppStore } from '@wingstop/store/app-store';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, private store: State<IAppStore>) {}

  canActivate(): boolean {
    if (this.store.getValue().appState.authentication == null) {
      this.router.navigate(['/welcome']);
      return false;
    }
    return true;
  }
}
