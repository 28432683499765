import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import {
  map,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subscription,
  take,
  tap
} from 'rxjs';
import { OrderSubmitRequestPayment } from '../../../../../ecomm/types/order.types';
import {
  PaymentSessionDetails,
  PaymentSessionDetailsHostedPage,
  VaultedCreditCards
} from '../../../../../ecomm/types/payment.types';
import { CheckboxComponent } from '../../../../common';
import {
  AddedCreditCardDetails,
  CreditCardPaymentMethodStatusEvent,
  PaymentInfo,
  PaymentMethodLike
} from '../payment-method/payment-method.types';
import {
  Config,
  CONFIG
} from '../../../../../ecomm/providers/config/config.provider';
import {
  SCRIPT_LOADER,
  ScriptLoader
} from '../../../../../ecomm/providers/script-loader/script-loader.provider';
import { UcomSDK } from '../../../../../ecomm/providers/ucom-sdk/ucom-sdk';
import {
  UCOM_SDK,
  UcomSDKProviderType
} from '../../../../../ecomm/providers/ucom-sdk/ucom-sdk.provider';
import { NotificationService } from '../../../../../ecomm/utils/notification/notification.service';
import { PaymentWorkflowService } from '../../../../../ecomm/workflows/payment/payment-workflow.service';
import { AnalyticsService } from '../../../../../ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'wri-credit-card-payment-method',
  templateUrl: './credit-card-payment-method.component.html',
  styleUrls: ['./credit-card-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CreditCardPaymentMethodComponent)
    }
  ]
})
export class CreditCardPaymentMethodComponent
  extends CheckboxComponent
  implements OnInit, PaymentMethodLike {
  private static readonly GUEST_CHECKOUT_PAGE = 'wingstop.guest.checkout';
  private static readonly REGISTERED_USER_CHECKOUT_PAGE = 'wingstop.checkout';
  private static readonly SESSION_EXPIRED_MESSAGE =
    'Sorry, we were not able to submit your credit card information. Please re-enter your card information and place your order.';
  @Output()
  statusChanged = new EventEmitter<CreditCardPaymentMethodStatusEvent>();
  public ucomSdk: UcomSDK | undefined;
  public sessionTokenId: string | undefined;
  public fdCustomerId: string | undefined;
  public hasNoSessionDetails: boolean | null = null;
  public isLoading = false;
  public creditCardForm: UntypedFormGroup = new UntypedFormGroup({});
  public addedCreditCardForm: UntypedFormGroup = new UntypedFormGroup({});
  public addedCreditCards: AddedCreditCardDetails[] = [];
  private paymentSubject$: ReplaySubject<PaymentInfo> = new ReplaySubject();
  private _totalBalance = 0;
  private subscription = new Subscription();

  constructor(
    private paymentWorkflowService: PaymentWorkflowService,
    @Inject(SCRIPT_LOADER)
    private scriptLoaderProvider: ScriptLoader,
    @Inject(CONFIG) private config: Config,
    @Inject(UCOM_SDK) private ucomProvider: UcomSDKProviderType,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  get totalPrice(): number {
    return this._totalBalance;
  }

  @Input()
  set totalPrice(val: number) {
    this._totalBalance = val ?? 0;
    this.addedCreditCards = this.updateAddedCardDetails(this.addedCreditCards);
    this.updateStatus(super.value);
  }

  private _userLoggedIn = false;

  get userLoggedIn(): boolean {
    return this._userLoggedIn;
  }

  @Input() set userLoggedIn(input: boolean) {
    this._userLoggedIn = input;
  }

  @Input()
  paymentType = '';

  @Input()
  isCreditCardPaymentSupported = false;

  @Input()
  isVaultedCreditCardPaymentSupported = false;

  @Input()
  vaultedCreditCardView = false;

  @Input()
  vaultedCreditCardVault = false;

  @Input()
  vaultedCreditCardPay = false;

  @Input()
  public set customerVaultedCreditCards(input: VaultedCreditCards[] | null) {
    this.removeAllVaultedCardsFromForm();
    this.addAllVaultedCreditCardsToForm(input);
    this.addedCreditCards = this.setAddedCards(input);
    // initialize session to get fdCustomerId when user has vaulted credit cards
    if (this.addedCreditCards.length > 0) {
      const customErrorMessage = true;
      this.initializeSession(customErrorMessage);
    }
    this.updateStatus();
  }
  public get isVaultedCreditCardEnabled() {
    return this.userLoggedIn && this.vaultedCreditCardVault;
  }

  ngOnInit() {
    this.scriptLoaderProvider(this.config.fiserv.sdkUri);
    this.addedCreditCardForm = this.fb.group({});

    this.subscription.add(
      this.addedCreditCardForm.valueChanges.subscribe(() => {
        this.addedCreditCards = this.updateAddedCardDetails(
          this.addedCreditCards
        );
        this.updateStatus();
      })
    );
    this.updateStatus();
  }

  onCheckboxUpdate(newValue: boolean): void {
    super.writeValue(newValue);
    if (newValue) {
      this.addedCreditCardForm.reset();
      this.initializeSession();
    } else {
      this.updateStatus();
    }
  }

  updatePayment(paymentInfo: PaymentInfo) {
    paymentInfo.payments?.map((cardDetails) => {
      cardDetails.requestedAmount = Number(this.totalPrice.toFixed(2));
    });
    return paymentInfo.payments;
  }

  public toggleAddedCardStatus(id: string, checked: boolean) {
    if (checked) {
      this.addedCreditCardForm.reset();
      super.writeValue(false);
    }
    this.addedCreditCardForm.patchValue({ [id]: checked });
  }

  getPaymentInfo(): Observable<PaymentInfo> {
    const selectedCreditCards = this.addedCreditCards.filter(
      (c) => c.requestedAmount > 0
    );
    if (selectedCreditCards.length === 0) {
      try {
        if (this.ucomSdk) {
          this.ucomSdk.triggerSaveAction();
          return this.paymentSubject$.pipe(
            take(1),
            map((value: PaymentInfo) => {
              const updatedPaymentInfo = {
                ...value,
                payments: this.updatePayment(value)
              };
              return updatedPaymentInfo;
            }),
            tap(() => this.ucomSdk?.stop()),
            shareReplay(1)
          );
        }
        return of({ billingMethod: 'onlinePay' });
      } catch (error) {
        return NEVER;
      }
    }
    const payments: OrderSubmitRequestPayment[] = this.addedCreditCards
      .filter((c) => c.requestedAmount > 0)
      .map(this.toOrderSubmitRequestPayment.bind(this));
    return of({
      billingMethod: 'onlinePay',
      payments
    });
  }

  turnAllAppliedCreditCardsOff(): void {
    this.addedCreditCardForm.reset();
  }

  public async placeOrderResponse() {
    this.initializeSession();
  }

  private removeAllVaultedCardsFromForm() {
    this.addedCreditCards
      .filter((c) => c.type !== 'anonymous')
      .forEach((c) => this.addedCreditCardForm.removeControl(c.id));
  }

  private addCreditCardToForm(id: string, val = true) {
    this.addedCreditCardForm.addControl(id, new FormControl(val));
  }

  private addAllVaultedCreditCardsToForm(
    vaultedCards: VaultedCreditCards[] | null
  ) {
    if (vaultedCards) {
      vaultedCards.map((vc) => this.addCreditCardToForm(vc.id, false));
    }
  }

  private toVaultedAddedCreditCardDetails(
    details: VaultedCreditCards
  ): AddedCreditCardDetails {
    return {
      ...details,
      id: details.id ?? '',
      type: 'vaulted',
      requestedAmount: 0
    };
  }

  private setAddedCards(
    vaultedCards: VaultedCreditCards[] | null
  ): AddedCreditCardDetails[] {
    if (vaultedCards) {
      return this.updateAddedCardDetails([
        ...vaultedCards.map((vc) =>
          this.toVaultedAddedCreditCardDetails({
            ...vc
          })
        ),
        ...this.addedCreditCards.filter((ac) => ac.type === 'anonymous')
      ]);
    }
    return this.updateAddedCardDetails([
      ...this.addedCreditCards.filter((ac) => ac.type === 'anonymous')
    ]);
  }

  private updateAddedCardDetails(
    input: AddedCreditCardDetails[]
  ): AddedCreditCardDetails[] {
    let amountLeftToAssign = this.totalPrice;
    return input.reduce((acc, ac) => {
      const id = ac.id;
      const isSelected: boolean = this.addedCreditCardForm.controls[id]?.value;
      const amountToUse = isSelected ? amountLeftToAssign : 0;
      amountLeftToAssign = Math.max(0, amountLeftToAssign - amountToUse);
      return [...acc, { ...ac, requestedAmount: amountToUse }];
    }, [] as AddedCreditCardDetails[]);
  }

  private updateStatus(newCreditCard = false) {
    this.statusChanged.emit({
      priceAfterCreditCards:
        this._totalBalance -
        this.addedCreditCards.reduce((sum, ac) => sum + ac.requestedAmount, 0),
      creditCardCount: this.addedCreditCards.length,
      appliedCreditCardCount: this.addedCreditCards.filter(
        (ac) => ac.requestedAmount > 0
      ).length,
      appliedCreditCardCountWithZeroBalance: this.addedCreditCards.filter(
        (ac) =>
          this.addedCreditCardForm.value[ac.id] &&
          ac.requestedAmount === 0
      ).length,
      newCreditCard
    });
  }

  private toOrderSubmitRequestPayment(
    input: AddedCreditCardDetails
  ): OrderSubmitRequestPayment {
    switch (input.type) {
      case 'anonymous': {
        return {
          type: 'creditCard',
          tokenId: ''
        };
      }
      case 'vaulted': {
        return {
          type: 'registeredVaulted',
          customerId: this.fdCustomerId ?? '',
          requestedAmount: Number(input.requestedAmount.toFixed(2)),
          vaultedAccountId: input.id
        };
      }
    }
  }

  private async getCreditCardType(
    hostedPages: PaymentSessionDetailsHostedPage[] = []
  ) {
    const checkoutPage =
      hostedPages.find(
        (p) =>
          p.rel ===
          (this.isVaultedCreditCardEnabled
            ? CreditCardPaymentMethodComponent.REGISTERED_USER_CHECKOUT_PAGE
            : CreditCardPaymentMethodComponent.GUEST_CHECKOUT_PAGE)
      ) ?? null;

    if (!checkoutPage) {
      this.hasNoSessionDetails = true;
    }
    return checkoutPage;
  }

  private async createConfigObject(
    checkoutPage: PaymentSessionDetailsHostedPage,
    sessionDetails: PaymentSessionDetails
  ) {
    const configObject = {
      apiKey: this.config.fiserv.apiKey,
      env: this.config.fiserv.env,
      mountId: 'payment-guest-checkout-container',
      redirectUrl: '#',
      pageUrl: checkoutPage?.href,
      accessToken: sessionDetails?.token?.tokenId,
      encryptionKey: sessionDetails?.token?.publicKey
    };

    if (this.isVaultedCreditCardEnabled) {
      return {
        ...configObject,
        fdCustomerId: this.fdCustomerId
      };
    }
    return configObject;
  }

  private getPaymentObjBasedOnUserType(
    tokenId: string,
    shouldVaultCard?: boolean
  ) {
    const paymentObj = {
      type: 'anonymousCredit',
      requestedAmount: Number(this.totalPrice.toFixed(2)),
      clientSessionToken: this.sessionTokenId,
      tokenId
    };

    if (this._userLoggedIn) {
      paymentObj.type = 'registeredCredit';
      /* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
      (paymentObj as any).customerId = this.fdCustomerId;
    }

    if (this.isVaultedCreditCardEnabled) {
      /* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
      (paymentObj as any).shouldVaultCard = shouldVaultCard;
    }

    return paymentObj;
  }

  private handleFiservResponse(res: string) {
    const token = JSON.parse(res);
    if (!token?.token?.tokenId || !this.paymentSubject$) {
      this.notificationService.showError(
        CreditCardPaymentMethodComponent.SESSION_EXPIRED_MESSAGE
      );
      this.analyticsService.logGaEvent({
        event: 'checkout_error',
        error_reason: 'add_credit_card_failed'
      });
      this.analyticsService.logGaEvent({
        event: 'iframe_error',
        error_response: token?.developerInfo?.developerMessage ?? 'unknown',
        payment_provider: 'Fiserv'
      });
      this.writeValue(false);
      return;
    }
    this.paymentSubject$.next({
      billingMethod: 'onlinePay',
      payments: [
        this.getPaymentObjBasedOnUserType(token.token.tokenId, token.isSaveCard)
      ]
    });
  }

  private async initializeSession(customErrorMessage = false) {
    this.isLoading = true;
    this.paymentSubject$ = new ReplaySubject();
    const paymentSessionDetails =
      await this.paymentWorkflowService.getPaymentSessionDetails(
        customErrorMessage
      );
    this.isLoading = false;
    if (!paymentSessionDetails) {
      this.hasNoSessionDetails = true;
      return;
    }

    const { hostedPages } = paymentSessionDetails;
    const checkoutPage = await this.getCreditCardType(hostedPages);
    if (!checkoutPage) {
      this.hasNoSessionDetails = true;
      return;
    }

    this.hasNoSessionDetails = false;
    this.sessionTokenId = paymentSessionDetails.token.tokenId;
    this.fdCustomerId = paymentSessionDetails.fdCustomerId ?? undefined;
    const configObject = await this.createConfigObject(
      checkoutPage,
      paymentSessionDetails
    );
    this.updateStatus(true);
    this.ucomSdk = await this.ucomProvider;

    try {
      if (this.ucomSdk) {
        this.ucomSdk.init(
          configObject,
          this.handleFiservResponse.bind(this),
          false
        );
        this.ucomSdk.start();
      }
    } catch (error) {
      // error
    }
  }
}
