<div class="wri-pdp-wrapper">
  <wri-breadcrumb [breadcrumbs]="breadcrumbs"></wri-breadcrumb>
  <div *ngIf="selectedItem" class="details-container item-details">
    <div class="item-image">
      <img
        data-testid="item-details-img"
        class="item-img"
        aria-live="polite"
        alt="{{
          selectedItem.images && selectedItem.images.length > 0
            ? selectedItem.name
            : 'placeholder-image'
        }}"
        srcset="{{ selectedItem.images[0]?.uri | imageResolution: 'srcset':'pdp-item': '/assets/images/logo-green-product.png'}}"
        src="{{
          selectedItem.images[0]?.uri
            | imageResolution
              : 'src'
              : 'pdp-item'
              : '/assets/images/logo-green-product.png'
        }}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        role="img"
      />
    </div>
    <span
      wriFocusOutline
      data-testid="item-details-name"
      class="item-name"
      aria-live="polite"
    >
      {{ selectedItem.name }}
    </span>
    <span
      wriFocusOutline
      data-testid="item-details-desc"
      class="item-description"
      aria-live="polite"
    >
      {{ selectedItem.description }}
    </span>
    <!-- Display button only for items w/no modifier groups -->
    <div class="desktop-only" id="primary-btn-container">
      <button
        class="add-to-bag wri-btn wri-btn-primary"
        aria-label="Start Order"
        (click)="startOrder()"
      >
        <div>
          Start Order
        </div>
      </button>
    </div>
    <div class="mobile-only sticky-footer">
      <button
        aria-label="Start Order"
        class="add-to-bag wri-btn wri-btn-primary"
        (click)="startOrder()"
      >
        <div>
          Start Order
        </div>
      </button>
    </div>
  </div>
</div>
