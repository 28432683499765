import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PaymentWorkflowService } from '../../../../../ecomm/workflows/payment/payment-workflow.service';
import { AllVaultedCards } from '../payment-methods.component';

type ModalData = {
  data: AllVaultedCards;
  deleteCreditCard: boolean;
};

@Component({
  selector: 'wri-remove-payment-modal',
  templateUrl: './remove-payment-method-modal.component.html',
  styleUrls: ['./remove-payment-method-modal.component.scss']
})
export class RemovePaymentMethodComponent {
  @Input() modalData!: ModalData;
  constructor(
    public activeModal: NgbActiveModal,
    private paymentWorkflowService: PaymentWorkflowService
  ) {}

  public isLoading = false;

  closeModal() {
    this.activeModal.close();
  }

  public async removePaymentCard(e: Event) {
    if (this.isLoading) {
      e.preventDefault();
    } else {
      const { data, deleteCreditCard } = this.modalData;
      const vaultId = deleteCreditCard ? data.id : data.vaultedAccountId;
      this.isLoading = true;
      const response = await this.paymentWorkflowService.deleteVaultedCard(
        vaultId,
        deleteCreditCard
      );
      this.isLoading = false;
      if (response) {
        this.activeModal.close({
          isDeleted: true
        });
      }
    }
  }

  getModalData() {
    const { data, deleteCreditCard } = this.modalData;
    if (deleteCreditCard) {
      if (data?.credit) {
        return {
          title: 'Remove Credit/Debit Card?',
          body: `Are you sure you want to remove the ${data.credit?.cardType} ending in ${data.credit?.alias}?`
        }
      }
      if (data?.prepaid) {
        return {
          title: 'Remove Prepaid Card?',
          body: `Are you sure you want to remove the Prepaid Card ending in ${data.prepaid?.alias}?`
        }
      }
    }
    return {
      title: 'Remove Gift Card?',
      body: `Are you sure you want to remove the Wingstop Gift Card ending in ${data.alias}?`
    }
  }
}
