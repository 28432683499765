<ng-container *ngIf="cart;then cartExist else noCart"></ng-container>

<ng-template #cartExist>
  <div class="location-info sticky-header" data-testid="location-info">
    <wri-icon
      *ngIf="!cart?.locationId"
      class="location-info-icon"
      data-testid="location-info-icon"
      icon="wri-location"
    ></wri-icon>
    <span class="location-info-handoff" data-testid="location-info-handoff">
      <span
        class="location-info-handoff-type"
        data-testid="location-info-handoff-type"
      >{{ cart?.handoffMode | handoffModeLabel | uppercase }}</span
      >
      <span
        class="location-info-handoff-preposition"
        data-testid="location-info-handoff-preposition"
      >{{ cart?.handoffMode | handoffModePreposition | uppercase }}</span
      >
    </span>
    <span class="location-info-address" data-testid="location-info-address">
      <span
        *ngIf="cart?.handoffMode === 'delivery'"
        class="location-info-address-info"
        data-testid="location-info-address-info"
      >{{ cart?.deliveryAddress?.streetAddress }}</span
      >
      <span
        *ngIf="cart?.handoffMode === 'carryout'"
        class="location-info-address-info"
        data-testid="location-info-address-info"
      >{{ storeInfo?.storeDetails.name }}</span
      >
      <a
        wriFocusOutline
        class="location-info-address-change"
        data-testid="location-info-address-change"
        rel="noopener noreferrer"
        tabindex="0"
        (click)="navigateToOrder()"
        id="location-info-address-change"
      >(Change)</a
      ></span
    >
  </div>
  <div class="placeholder-height"></div> <!-- This placeholder creates the margin -->
</ng-template>

<ng-template #noCart>
  <div
    class="handoff-mode-selection-group"
    data-testid="handoff-mode-selection-group">

    <div class="btn-group">
      <button
        (click)="navigateTo('carryout')"
        class="wri-btn wri-btn-primary-icon"
        data-testid="carryout-btn"
        data-cy="Carryout">
        <wri-icon icon="wri-carryout" class="wri-carryout" data-testid="carryout-img"></wri-icon>

        <span class="button-text">
          Carryout
        </span>
      </button>

      <button
        (click)="navigateTo('delivery')"
        class="wri-btn wri-btn-primary-icon"
        data-testid="delivery-btn"
        data-cy="Delivery">
        <wri-icon icon="wri-delivery" data-testid="delivery-img"></wri-icon>
        <span class="button-text">Delivery</span>
      </button>
    </div>
  </div>
</ng-template>
