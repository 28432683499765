/* eslint-disable @ngrx/prefer-inline-action-props */
import { FactoryProvider } from '@angular/core';
import { Action, ActionReducer, META_REDUCERS } from '@ngrx/store';

import { providedOnceMiddleware } from './provided-once-middleware';

export type ReportableAction = Action & { id: string };

export const reportableAction = (id: string, action: Action) => {
  return { ...action, id };
};

export const isReportableAction = (
  action: Action & { id?: string }
): action is ReportableAction => !!action.id;

export const dispatchReporter = providedOnceMiddleware(
  'dispatchReporterApplied',
  (reducer: ActionReducer<unknown>): ActionReducer<unknown> =>
    (state, action) => {
      const nextState = reducer(state, action);
      if (isReportableAction(action)) {
        return { ...(nextState as Record<string, unknown>), lastId: action.id };
      }
      return nextState;
    }
);

export class DispatchReporterMiddlewareProvider {
  static get(): FactoryProvider {
    return {
      provide: META_REDUCERS,
      useFactory: () => dispatchReporter,
      multi: true
    };
  }
}
