import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      const customerApiRegex = /\/api\/customer$/;
      if (customerApiRegex.test(req.url)) {
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status === 304) {
            return new HttpResponse({ status: event.status, body: {} });
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 304) {
            return of(
              new HttpResponse({
                status: error.status,
                body: {},
              })
            );
          }
          return next.handle(req);
        })
      );
    }
    return next.handle(req);
  }
}
