import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoundupCharity } from '../../../../ecomm/types/roundup-charity.types';

@Component({
  selector: 'wri-roundup-info-modal',
  templateUrl: './roundup-info-modal.component.html',
  styleUrls: ['./roundup-info-modal.component.scss']
})
export class RoundupInfoModalComponent {
  @Input() roundupCharityData: RoundupCharity = {};
  constructor(private modalService: NgbModal) { }

  close(event: Event) {
    event.preventDefault();
    this.modalService.dismissAll();
  }
}
