import { Component, Input } from '@angular/core';

import { LocationAmenity } from '../../../../ecomm/types/search-location.types';

@Component({
  selector: 'wri-location-amenities',
  styleUrls: ['./location-amenities.component.scss'],
  template: `
    <ul class="amenities">
      <li *ngIf="offersDelivery" class="location-delivery">
        <wri-icon icon="wri-delivery" data-testid="delivery-img"></wri-icon>
        Offers Delivery
      </li>
      <li
        *ngIf="isAmenityAvailable('cokeFreestyle')"
        class="location-coke-freestyle"
      >
        <img
          data-testid="coke-freestyle-img"
          role="img"
          src="https://cdn.bfldr.com/NDQASMJ1/as/733373b67brgf9h7rh9pfj6j/wri-coke-freestyle?auto=webp&format=png"
          width="24"
          alt="Coke Freestyle"
        />
        {{ getDescriptionOfAmenity('cokeFreestyle') }}
      </li>
      <li *ngIf="isAmenityAvailable('wifi')" class="location-wifi">
        <wri-icon
          icon="wri-wifi"
          class="icon-shift"
          data-testid="wifi-img"
        ></wri-icon>
        {{ getDescriptionOfAmenity('wifi') }}
      </li>
      <li *ngIf="isAmenityAvailable('cashless')" class="location-cashless">
        <wri-icon
          icon="wri-credit-card"
          class="icon-shift"
          data-testid="cashless-img"
        ></wri-icon>
        {{ getDescriptionOfAmenity('cashless') }}
      </li>
    </ul>
  `
})
export class LocationAmenitiesComponent {
  @Input()
  offersDelivery = false;

  @Input()
  amenities: LocationAmenity[] = [];

  getDescriptionOfAmenity(amenityName: string) {
    return this.amenities?.find((f) => f.name === amenityName)?.description;
  }

  isAmenityAvailable(amenityName: string) {
    return this.amenities?.some((f) => f.name === amenityName);
  }
}
