import { Inject, Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { Observable, firstValueFrom, startWith } from 'rxjs';
import {
  EcommFeatureFlags,
  ECOMM_FEATURE_FLAGS
} from '../../config/ecomm-feature-flags.provider';
import { RegionalConfigurationFeature } from '../../store/features/regional-configuration';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  private featureFlagSelector = createSelector(
    RegionalConfigurationFeature.selectFeatureFlags,
    (serverFlags) => {
      const clientFlags = this._clientFlags;
      const keys: (keyof EcommFeatureFlags)[] = Object.keys(clientFlags);
      return keys.reduce(
        (flags, flagName) => ({
          ...flags,
          [flagName]:
            typeof serverFlags?.[flagName] === 'boolean'
              ? clientFlags[flagName] && serverFlags[flagName]
              : clientFlags[flagName]
        }),
        {}
      ) as EcommFeatureFlags;
    }
  );

  private _featureFlags$: Observable<EcommFeatureFlags>;
  get featureFlags$() {
    return this._featureFlags$;
  }

  private _featureFlags = this._clientFlags;
  get featureFlags() {
    return this._featureFlags;
  }

  constructor(
    private store: Store,
    @Inject(ECOMM_FEATURE_FLAGS) private _clientFlags: EcommFeatureFlags
  ) {
    this._featureFlags$ = this.store.select(this.featureFlagSelector);
    this._featureFlags$
      .pipe(startWith(this._clientFlags))
      .subscribe((featureFlags) => {
        this._featureFlags = featureFlags;
      });
  }

  isEnabled(flag: string): boolean {
    return this._featureFlags[flag] ?? false;
  }

  isDisabled(flag: string): boolean {
    return !this.isEnabled(flag);
  }

  async getFeatureFlagValue(
    intrinsicFeatureFlagName: keyof EcommFeatureFlags,
    adminFeatureFlagName: string
  ): Promise<boolean> {
    if (this.featureFlags[intrinsicFeatureFlagName]) {
      const regionalConfigOptions = await firstValueFrom(
        this.store.select(
          RegionalConfigurationFeature.selectRegionalConfigurationOptions
        )
      );
      if (
        regionalConfigOptions &&
        regionalConfigOptions[adminFeatureFlagName]?.value ===
          'false'
      ) {
        return false;
      }
    }
    return this.featureFlags[intrinsicFeatureFlagName];
  }
}
