<wri-page-loading-indicator
  *ngIf="(isLoading$ | async) || cartStateAfterAddOrUpdate"
></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<div>
  <wri-item-details
    [selectedItem]="selectedItem"
    [outOfStock]="selectedItem ? (selectedItem | pick : 'outOfStock') : null"
    [availableInSchedule]="
      selectedItem ? (selectedItem | pick : 'availableInSchedule') : null
    "
    [breadcrumbs]="breadcrumbs$ | async"
    [cart]="cartState$ | async | pick : 'cart'"
    [isCartStateLoading]="isLoading$ | async"
    [storeInfo]="storeInfoState$ | async | pick : 'storeInfo'"
    [categorySlug]="routeSlugs$ | async | pick : 'categorySlug'"
    [categoryName]="categoryName$ | async"
  >
  </wri-item-details>
</div>
