import { Component, Input } from '@angular/core';
import { BehaviorSubject, map, withLatestFrom } from 'rxjs';

import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';

@Component({
  selector: 'wri-feature-flags',
  template: `<ng-content *ngIf="view$ | async"></ng-content> `
})
export class FeatureFlagsComponent {
  private _with$ = new BehaviorSubject<string[]>([]);
  @Input() set with(flags: string[]) {
    this._with$.next(flags ?? []);
  }

  private _without$ = new BehaviorSubject<string[]>([]);
  @Input() set without(flags: string[]) {
    this._without$.next(flags ?? []);
  }

  public view$ = this.featureFlagService.featureFlags$.pipe(
    withLatestFrom(this._with$, this._without$),
    map(([featureFlags, withFlags, withoutFlags]) => {
      const hasAllWithFlags = withFlags.every(
        (flag) => featureFlags[flag] ?? false
      );
      const missingAllWithoutFlags = withoutFlags.every(
        (flag) => !(featureFlags[flag] ?? true)
      );
      return hasAllWithFlags && missingAllWithoutFlags;
    })
  );

  constructor(private featureFlagService: FeatureFlagService) {}
}
