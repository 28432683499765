import { Inject, Injectable } from '@angular/core';

import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { FeaturesState } from '../../../ecomm/store/types/features-state';

import {
  fromCartDeliveryAddress,
  fromSelectedHandoffDeliveryAddress
} from './selected-handoff-mode.utilities';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Buffer } from 'buffer';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import {
  HandoffMode,
  SelectedHandoffMode,
  SelecteHandoffModeQueryParams
} from '../../../ecomm/types/selected-handoff-mode.types';
import { Address } from '../../../ecomm/types/address';
import { SelectedHandoffModeFeature } from '../../../ecomm/store/features/selected-handoff-mode';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { NotificationService } from '../../utils/notification/notification.service';

@Injectable()
export class SelectedHandoffModeService {
  private static DEFAULT_SELECTED_HANDOFF_MODE: SelectedHandoffMode = {
    handoffMode: HandoffMode.carryout,
    address: null
  };

  constructor(
    private store: Store,
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    @Inject(RedirectService)
    private redirectService: RedirectService,
    private notificationService: NotificationService
  ) {}

  public async get(
    route: ActivatedRouteSnapshot
  ): Promise<SelectedHandoffMode> {
    let handoffMode = SelectedHandoffModeService.DEFAULT_SELECTED_HANDOFF_MODE;
    const fromUrl = this.getSelectedHandoffModeFromUrl(route);
    const fromCart = await this.getSelectedHandoffModeFromCart();

    const cart = await firstValueFrom(
      this.store.select(CartFeature.selectCart)
    );

    const storeInfo$ = this.store.select(StoreInfoFeature.selectStoreInfoState);
    const storeInfo = await firstValueFrom(storeInfo$.pipe(filter(Boolean)));

    if (fromCart && fromUrl) {
      handoffMode = fromUrl;
    }

    if (fromCart && !fromUrl) {
      if (
        fromCart.handoffMode?.toLowerCase() === HandoffMode.delivery &&
        cart?.locationId !== storeInfo.storeInfo?.storeDetails.id
      ) {
        handoffMode = SelectedHandoffModeService.DEFAULT_SELECTED_HANDOFF_MODE;
      } else {
        handoffMode = fromCart;
      }
    }

    if (!fromCart && fromUrl) {
      handoffMode = fromUrl;
    }
    await this.asynchronusDispatcher.dispatch(
      SelectedHandoffModeFeature.actions.setState(handoffMode)
    );
    return handoffMode;
  }

  public async getSelectedHandoffModeFromCart(): Promise<SelectedHandoffMode | null> {
    const cart = await firstValueFrom(
      this.store.select(CartFeature.selectCart)
    );

    if (!cart) {
      return null;
    }
    if (cart.handoffMode?.toLowerCase() === HandoffMode.carryout) {
      return {
        handoffMode: cart.handoffMode.toLowerCase() as HandoffMode,
        address: null
      };
    }

    if (
      cart.handoffMode?.toLowerCase() === HandoffMode.delivery &&
      !!cart.deliveryAddress
    ) {
      const address: Address = fromCartDeliveryAddress(cart.deliveryAddress);

      return {
        handoffMode: cart.handoffMode.toLowerCase() as HandoffMode,
        address
      };
    }

    return null;
  }

  public getSelectedHandoffModeFromUrl(
    route: ActivatedRouteSnapshot
  ): SelectedHandoffMode | null {
    const handoffMode = this.getHandoffModeFromUrl(route);
    if (handoffMode === HandoffMode.carryout) {
      return { handoffMode, address: null };
    }

    const address = this.getDeliveryAddressFromUrl(route);
    if (handoffMode === HandoffMode.delivery && !address) {
      this.notificationService.showError(
        'We ran into an unexpected error with the delivery address. Please try again.'
      );
      this.redirectService.redirectToOrder(HandoffMode.delivery);
      return null;
    }

    if (handoffMode === HandoffMode.delivery) {
      return { handoffMode, address };
    }

    return null;
  }

  private getHandoffModeFromUrl(route: ActivatedRouteSnapshot): string | null {
    const handoffMode = route.queryParamMap.get(
      SelecteHandoffModeQueryParams.handoffMode
    );
    return handoffMode ? handoffMode.toLowerCase() : null;
  }

  private getDeliveryAddressFromUrl(route: ActivatedRouteSnapshot) {
    const deliveryAddress = route.queryParamMap.get(
      SelecteHandoffModeQueryParams.deliveryAddress
    );
    if (!deliveryAddress) {
      return null;
    }

    try {
      const jsonStr = Buffer.from(deliveryAddress, 'base64').toString('utf-8');
      const parsed = JSON.parse(jsonStr);
      return fromSelectedHandoffDeliveryAddress(parsed);
    } catch (err) {
      console.warn(err);
      return null;
    }
  }
}
