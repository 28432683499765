import { Injectable } from '@angular/core';
import { configureStore } from '@wingstop/store/app-store';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';

@Injectable()
export class ReduxProvider {
  constructor(
    private appStateActions: AppStateActions
  ) { }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      configureStore().then((store) => {
        const resolvePromise = () => {
          resolve(store);
        };

        // Resync the basket if possible
        this.appStateActions.refreshAuthentication().then((a) => {
          this.appStateActions.refreshBasket().then(resolvePromise);
        });
      });
    });
  }
}
