import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AnalyticsService } from './analytics.service.service';
import { SeoMetadata } from '../models/seo/seo-metadata.model';
import { environment } from '@wingstop/environments/environment';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { Schedule } from '@wingstop/models/order/schedule.model';
import moment from 'moment-mini';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  s3SeoMetadata: SeoMetadata[];
  params: any;
  currentUrl: string;
  useS3Metadata: boolean = true;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private router: Router,
    private analyticsService: AnalyticsService,
    private appStateActions: AppStateActions,
    private appStateSelectors: AppStateSelectors,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.appStateSelectors.s3SeoMetadata.subscribe(
      (seoMetadata: SeoMetadata[]) => {
        this.s3SeoMetadata = seoMetadata;

        this.addS3Metadata();
      }
    );

    // Refresh seo json file every day
    setInterval(() => {
      this.appStateActions.setS3SeoMetadata();
    }, 24 * 60 * 60 * 1000);
  }

  addSeoData() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let root = this.router.routerState.snapshot.root;

        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
            continue;
          }
          this.currentUrl = event.url;
          this.useS3Metadata = root && !root.data.useCustomSeo;
          this.addS3Metadata();

          this.analyticsService.logGaEvent({
            event: 'pageLoad',
            url: this.router.url,
          });

          return;
        }
      });
  }

  private addS3Metadata() {
    if (this.useS3Metadata && this.s3SeoMetadata) {
      const metaData = this.selectMetadataUsingRoute(this.currentUrl);

      // If seo json data matches the current route, use it to set the metadata
      if (metaData) {
        this.setMetaData({ ...metaData });
      } else {
        this.removeMetaData();
      }
    }
  }

  private selectMetadataUsingRoute(url: string): SeoMetadata {
    if (!url) {
      return;
    }
    return this.s3SeoMetadata.find((data) => {
      // Current route path with query params removed
      let currentRoutePath = url.substring(1).split('?')[0];

      // Use a single route for dynamically generated routes (e.g., order/wing-calculator/hungry/3-people)
      const dynamicallyGeneratedRoutes = [
        'order/wing-calculator',
        'order/recent/order-details',
        'order/search',
      ];
      dynamicallyGeneratedRoutes.forEach((route: string) => {
        if (currentRoutePath.includes(route)) {
          return (currentRoutePath = route);
        }
      });
      return data.route === currentRoutePath;
    });
  }

  public setMetaData(meta: SeoMetadata) {
    this.removeMetaData();

    // Use defaults if necessary
    meta.canonical = this.getCanonicalUrl(meta.canonical);
    meta.type = 'website';
    meta.site_name = 'Wingstop';
    meta.image =
      meta.image && meta.image !== environment.emptyProductImage
        ? meta.image
        : `${this.document.location.origin}${environment.emptyProductImage}`;

    this.titleService.setTitle(meta.title);
    this.metaService.addTags([
      { name: 'description', content: meta.description },
    ]);
    this.addCanonicalLink(meta.canonical);
    this.setOpenGraphMetadata(meta);
  }

  public setRobotsNoIndexNoFollow() {
    this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  private getCanonicalUrl(canonical?: string) {
    const url = canonical
      ? `${this.document.location.origin}/${canonical}`
      : `${this.document.location.origin}${this.router.url}`;

    // remove any query params
    return url.split('?')[0];
  }

  private removeMetaData() {
    this.titleService.setTitle(
      'Wingstop | Chicken Wings from the Wing Experts'
    );
    const description = this.metaService.getTag('name=description');
    this.metaService.removeTagElement(description);
    this.removeCanonicalLink();
    this.removeOpenGraphMetadata();
  }

  addStructuredData(data: {}[]) {
    this.removeStructuredData();
    data.forEach((cv) => {
      this.addStructuredDataToPage(this.getSafeHTML(cv));
    });
  }

  private getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `${json}`;
    return html;
  }

  private removeStructuredData() {
    Array.from(this.document.getElementsByClassName('jsonld')).forEach(
      (element) => {
        element.remove();
      }
    );
  }
  private addStructuredDataToPage(schema: any) {
    let tag = this.document.createElement('script');
    tag.setAttribute('type', 'application/ld+json');
    tag.className = 'jsonld';
    tag.innerHTML = schema;
    this.document.head.append(tag);
  }

  private addCanonicalLink(url: string) {
    const link = <HTMLLinkElement>(
      this.document.head.appendChild(this.document.createElement('link'))
    );
    link.rel = 'canonical';
    link.href = url;
  }

  private removeCanonicalLink() {
    const link = this.document.querySelector('link[rel="canonical"]');
    if (link) {
      this.document.head.removeChild(link);
    }
  }

  public setOpenGraphMetadata(meta: SeoMetadata) {
    this.removeOpenGraphMetadata();
    const openGraphProperties = [
      'title',
      'type',
      'url',
      'image',
      'description',
      'site_name',
    ];

    if (meta.canonical) {
      meta['url'] = meta.canonical;
    }

    for (const property of openGraphProperties) {
      if (meta.hasOwnProperty(property)) {
        this.metaService.addTag({
          property: `og:${property}`,
          content: meta[property],
        });
      }
    }
  }

  private removeOpenGraphMetadata() {
    const openGraphProperties = [
      'title',
      'type',
      'url',
      'image',
      'description',
      'site_name',
    ];

    openGraphProperties.forEach((property) => {
      const tag = this.metaService.getTag(`property='og:${property}'`);
      if (tag) {
        this.metaService.removeTagElement(tag);
      }
    });
  }

  public getStoreBusinessHours(schedule: Schedule, formatPattern = 'HH:mm') {
    if (schedule) {
      const structuredOpenHours: string[] = [];
      const buildOpenHours = {};

      // get rid of the dual weekday
      if (schedule.ranges.length > 7) {
        schedule.ranges.shift();
      }

      // sort to make returned value look nice
      schedule.ranges.sort((a, b) => {
        const sorter = {
          Sun: 0,
          Mon: 1,
          Tue: 2,
          Wed: 3,
          Thu: 4,
          Fri: 5,
          Sat: 6,
        };
        return sorter[a.weekday] - sorter[b.weekday];
      });

      // build the unique schedule ranges object
      schedule.ranges.forEach((r) => {
        const start = moment(r.start).format(formatPattern);
        const end = moment(r.end).format(formatPattern);
        const range = `${start}-${end !== '00:00' ? end : '23:59'}`;

        if (!buildOpenHours.hasOwnProperty(range)) {
          buildOpenHours[range] = r.weekday.slice(0, 2);
        } else {
          // the ternary check probably isn't necessary with the shift() above, but it's another level of insurance (e.g. location might be closed one day per week)
          buildOpenHours[range] =
            buildOpenHours[range] +
            (buildOpenHours[range].indexOf(r.weekday.slice(0, 2)) === -1
              ? `, ${r.weekday.slice(0, 2)}`
              : '');
        }
      });

      // create the array for the schema.org openingHours type
      for (const [key, value] of Object.entries(buildOpenHours)) {
        structuredOpenHours.push(`${value} ${key}`);
      }

      return structuredOpenHours.length === 1
        ? structuredOpenHours[0]
        : structuredOpenHours;
    }
    return '';
  }
}
