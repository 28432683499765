import { either } from 'fp-ts';
import * as t from 'io-ts';
import * as _ from 'lodash/fp';
import { Observable, of } from 'rxjs';
import * as rx from 'rxjs/operators';

import {
  EcommAPIResponse,
  EcommWebSocketResponse,
  HttpAPICustomErrorHandler,
  PreviouslyCaught,
  httpError
} from '../types/common.types';
import { MaybeResponse } from '../types/maybe-response';
import {
  UNKNOWN_ERROR,
  handleAPIErrorResponse,
  handleDecodeError,
  handleValidatedResponse
} from './throw-error';

export const handleAPIResponse =
  <T>(
    codec: t.Type<EcommAPIResponse<T>>,
    customErrorHandlers: HttpAPICustomErrorHandler<T>[] = [],
    enableDevLogs = false,
    defaultErrorMessage = UNKNOWN_ERROR
  ) =>
  (input$: Observable<EcommAPIResponse<T>>): Observable<MaybeResponse<T>> =>
    input$.pipe(
      rx.catchError(
        _.pipe(
          httpError(codec).decode,
          either.fold(
            _.pipe(
              (decodeErr: t.Errors) =>
                handleDecodeError(undefined, defaultErrorMessage)(decodeErr),
              PreviouslyCaught.map,
              of
            ),
            handleAPIErrorResponse<T>(
              customErrorHandlers,
              enableDevLogs,
              defaultErrorMessage
            )
          )
        )
      ),

      rx.map((value) => {
        return _.pipe(
          PreviouslyCaught.fold(
            _.identity,
            _.pipe(
              codec.decode,
              either.fold(
                (decodeErr: t.Errors) =>
                  handleDecodeError(undefined, defaultErrorMessage)(decodeErr),
                (
                  validValue:
                    | EcommAPIResponse<T>
                    | EcommWebSocketResponse<string, T>
                ) =>
                  handleValidatedResponse<T>(
                    enableDevLogs,
                    defaultErrorMessage
                  )(validValue)
              )
            )
          )
        )(value);
      })
    );
