import { FactoryProvider, InjectionToken, Injector } from '@angular/core';

import { CONFIG } from '../config/config.provider';
import { WINDOW } from '../window/window.provider';

export type ApplePayProviderType = {
  get: (req: ApplePayJS.ApplePayPaymentRequest) => ApplePaySession | undefined;
  isSupported: () => Promise<boolean>;
  STATUS_SUCCESS: () => typeof ApplePaySession.STATUS_SUCCESS;
  STATUS_FAILURE: () => typeof ApplePaySession.STATUS_FAILURE;
};

export const APPLE_PAY_PROVIDER = new InjectionToken<
  ApplePayProviderType | undefined
>('APPLE_PAY_PROVIDER');

export const applePayProvider = (injector: Injector): ApplePayProviderType => {
  const hasApplePay = () =>
    Object.hasOwn(injector.get(WINDOW), 'ApplePaySession');

  const isSupported = async () => {
    const merchantId = injector.get(CONFIG).applePay.merchantId;
    return hasApplePay()
      ? await ApplePaySession?.canMakePaymentsWithActiveCard(merchantId)
      : false;
  };
  const get = (req: ApplePayJS.ApplePayPaymentRequest) => {
    const applePayVersion = 3;
    return hasApplePay()
      ? new ApplePaySession(applePayVersion, req)
      : undefined;
  };

  return {
    isSupported,
    get,
    STATUS_SUCCESS: () =>
      hasApplePay() ? ApplePaySession?.STATUS_SUCCESS ?? NaN : NaN,
    STATUS_FAILURE: () =>
      hasApplePay() ? ApplePaySession?.STATUS_FAILURE ?? NaN : NaN
  };
};

export class ApplePayProviderProvider {
  public static get = (): FactoryProvider => ({
    provide: APPLE_PAY_PROVIDER,
    deps: [Injector],
    useFactory: applePayProvider
  });
}
