import { BaseModel } from '@wingstop/models/base.model';

export class CMSPromoOffer extends BaseModel {
  id: string = null;
  ref: string = null;
  offerTitle: string = null;
  image1Uri: string = null;
  image2Uri: string = null;
  imageAlt: string = null;
  body: string = null;
  tooltipTitle: string = null;
  tooltipInstructions: string = null;
  expirationDate: string = null;
  expirationTitle: string = null;
  expirationMessage: string = null;
  cta1Label: string = null;
  cta1Uri: string = null;
  cta2Label: string = null;
  cta2Uri: string = null;
  codeApplyErrorMessageTextToReplace: string = null;
  codeApplyErrorTitle: string = null;
  codeApplyErrorMessageTextOverride: string = null;
  codeApplyErrorImage: string = null;
  codeApplyErrorCta1Label: string = null;
  codeApplyErrorCta1Uri: string = null;
  codeApplyErrorCta2Label: string = null;
  codeApplyErrorCta2Uri: string = null;
  startsAt: string = null;
  endsAt: string = null;
  startTime: string = null;
  endTime: string = null;
  createdAt: string = null;
  updatedAt: string = null;
  isActive: boolean = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
