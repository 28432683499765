import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import { Cart } from '../../../../ecomm/types/cart.types';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { Router } from '@angular/router';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  Config,
  CONFIG
} from '../../../../ecomm/providers/config/config.provider';
import { CurrentUrlService } from '../../../../ecomm/utils/current-url/current-url.service';

@Component({
  selector: 'wri-cart-location',
  templateUrl: './cart-location.component.html',
  styleUrls: ['./cart-location.component.scss']
})
export class CartLocationComponent implements OnInit {
  public storeInfo: StoreInfo | null = null;
  public cart: Cart | null = null;
  private subscription = new Subscription();
  private currentUrlScreenName = '';

  constructor(
    private router: Router,
    private store: Store,
    private redirectService: RedirectService,
    private analyticsService: AnalyticsService,
    private featureFlagService: FeatureFlagService,
    private currentUrlService: CurrentUrlService,
    @Inject(CONFIG) private readonly config: Config
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.currentUrlService.getScreenName().subscribe((url) => {
        this.currentUrlScreenName = url;
      })
    );
    this.subscribeToStoreInfoState();
    this.subscribeToCartState();
  }
  navigateToOrder() {
    this.analyticsService.logGaEvent({
      event: 'change_store'
    });
    this.router.navigate(['/order']);
  }

  navigateTo(handoffMode: string) {
    this.analyticsService.logGaEvent({
      event: 'order_click',
      handoff: handoffMode,
      screen_name: this.currentUrlScreenName
    });

    if (this.featureFlagService.featureFlags.enableOrderRoute) {
      this.router.navigate(['/order'], {
        queryParams: {
          handoffMode: handoffMode
        }
      });
    } else {
      this.redirectService.redirectToLegacy(`order/action/${handoffMode}`);
    }
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cart = state.cart;
      })
    );
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfo = state.storeInfo;
      })
    );
  }
}
