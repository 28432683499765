import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';
import { StoreInfoFeature } from '../../../ecomm/store/features/store-info';
import { asyncTap } from '../../../ecomm/utils/async-tap';
import { CurrentUrlService } from '../../../ecomm/utils/current-url/current-url.service';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { OrderFeature } from '../../../ecomm/store/features/order';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const CommonGuard: CanActivateFn = async (
  state: ActivatedRouteSnapshot
): Promise<any> => {
  const store: Store = inject(Store);
  const currentUrlService = inject(CurrentUrlService);

  return await firstValueFrom(
    store.pipe(
      select(StoreInfoFeature.selectStoreInfo),
      map((storeInfo) => storeInfo?.storeDetails?.slug),
      asyncTap(async (storeInfo) => {
        const cart = store.pipe(select(CartFeature.selectCart));
        const lastSessionOrder = store.pipe(
          select(OrderFeature.selectLastSessionOrder)
        );

        if (
          state.url.filter((url) => url.path === 'confirmation').length === 0
        ) {
          if (!storeInfo || !cart) {
            currentUrlService.goToOrderPage();
          }
        } else {
          if (!storeInfo || !cart || !lastSessionOrder) {
            currentUrlService.goToHomePage();
          }
        }
      })
    )
  );
};
