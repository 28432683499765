import { Pipe, PipeTransform } from '@angular/core';
import { PasswordRequirements } from '../../../ecomm/types/password-requirements.types';

@Pipe({
  name: 'validatePassword'
})
export class ValidatePasswordPipe implements PipeTransform {
  validateUsingRegex(
    pwdRequirement: PasswordRequirements,
    enteredPassword: string
  ) {
    const { count = 1, allowed, id } = pwdRequirement;
    const cleanedPassword = enteredPassword.replace(/\s+/g, '');
    if (id === 'minLength') {
      return cleanedPassword.length >= count ? true : false;
    }
    let matchCount = 0;
    for (const char of cleanedPassword) {
      if (allowed.includes(char)) {
        matchCount += 1;
      }
    }
    const valid = matchCount >= count ? true : false;
    return valid;
  }

  transform(pwdRequirement: PasswordRequirements, enteredPassword: string) {
    return this.validateUsingRegex(pwdRequirement, enteredPassword);
  }
}
