import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { environment } from '@wingstop/environments/environment';
import { WINDOW } from './window.provider';

@Injectable()
export class ClientidInterceptor implements HttpInterceptor {
  constructor(@Inject(WINDOW) private window: Window) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url === environment.gmapGeocodeApi) {
      return next.handle(req);
    }
    let updateObj = {
      setHeaders: {
        clientid: 'wingstop',
        'Content-Type': 'application/json',
      },
    };
    // This allows splitting multiple versions of production to different ports,
    // without mangling the api.service.ts file.
    // Example: prod.wingstop.com:6001 --> prod-api.wingstop.com:6001
    let apiBaseUrlMultiPort = environment.apiBaseUrlMultiPort;
    let isApiCall = req.url.indexOf(environment.apiBaseUrl) === 0;
    // let apiPortNotAlreadySet = (req.url.indexOf(':') === -1);
    //  && apiPortNotAlreadySet
    let nonStandardPort = this.window.location.port;
    if (apiBaseUrlMultiPort && isApiCall && nonStandardPort) {
      let newBaseUrl = apiBaseUrlMultiPort + ':' + nonStandardPort;
      updateObj['url'] =
        newBaseUrl + req.url.replace(environment.apiBaseUrl, '');
    }
    const updatedReq = req.clone(updateObj);
    return next.handle(updatedReq);
  }
}
