import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { AuthFeature } from '../../../ecomm/store/features/auth';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';

export const authenticatedGuard = async () => {
  const store = inject(Store);
  const redirectService = inject(RedirectService);

  const isUserLoggedIn = await firstValueFrom(
    store.pipe(
      select(AuthFeature.selectIsAuthenticated),
    )
  );
  if(isUserLoggedIn) {
    redirectService.redirectToMyAccount();
  }
  return true;
};
