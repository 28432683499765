import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FeatureFlagService } from '../../../../../ecomm/utils/feature-flag/feature-flag.service';
import { NotificationService } from '../../../../../ecomm/utils/notification/notification.service';
import { CheckboxComponent } from '../../../../common';
import {
  ConditionalPaymentMethodLike,
  ExpressPaymentMethodLike,
  PaymentInfo,
  PaymentMethodLike,
  PaymentType
} from '../payment-method/payment-method.types';
import {
  CONFIG,
  Config
} from '../../../../../ecomm/providers/config/config.provider';

@Component({
  selector: 'wri-google-pay-payment-method',
  templateUrl: './google-pay-payment-method.component.html',
  styleUrls: ['./google-pay-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GooglePayPaymentMethodComponent)
    }
  ]
})
export class GooglePayPaymentMethodComponent
  extends CheckboxComponent
  implements ExpressPaymentMethodLike, ConditionalPaymentMethodLike, OnInit {
  @Input() totalPrice = 0;
  @Input() expressPayPrerequisitesMet = false;
  @Input() paymentsSelected: PaymentMethodLike[] = [];
  @Input() isPaymentMethodSupported = false;

  @Output() expressPay = new EventEmitter<PaymentInfo>();
  @Output() expressPayClicked = new EventEmitter<string>();
  @Output() paymentSupported = new EventEmitter<boolean>();

  @HostBinding('class.method-supported')
  methodSupported = false; //TODO method supported
  paymentRequest!: google.payments.api.PaymentDataRequest;
  private readonly GOOGLE_PAY_ERROR_MSG =
    'There was an issue with Google Pay. Please try again.';
  private readonly UPDATE_GUEST_INFO =
    'Please update the form before using Google Pay.';

  constructor(
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
    @Inject(CONFIG) public config: Config
  ) {
    super();
  }

  ngOnInit(): void {
    if (
      this.featureFlagService.featureFlags.enableGooglePay &&
      this.isPaymentMethodSupported
    ) {
      this.methodSupported = true;
      this.paymentSupported.emit(true);

      this.paymentRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD', 'DISCOVER'],
              billingAddressRequired: true
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: this.config.googlePay.gateway,
                gatewayMerchantId: this.config.googlePay.gatewayMerchantId
              }
            }
          }
        ],
        merchantInfo: {
          merchantName: 'Wingstop',
          merchantId: this.config.googlePay.merchantId
        },
        transactionInfo: {
          countryCode: 'US',
          currencyCode: 'USD',
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: this.totalPrice + ''
        }
      };
    }
  }

  public onGpayButtonClick = (event: Event) => {
    this.expressPayClicked.emit(PaymentType.googlePay);
    if (this.paymentsSelected.length === 0) {
      if (!this.expressPayPrerequisitesMet) {
        this.notificationService.showError(this.UPDATE_GUEST_INFO);
        this.scrollToSection('guest-signup-form');
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };

  public onError = (): void => {
    this.notificationService.showError(this.GOOGLE_PAY_ERROR_MSG);
  };

  onLoadPaymentData = (event: {
    detail: google.payments.api.PaymentData;
  }): void => {
    try {
      this.writeValue(true);
      const tokenData = JSON.parse(
        event.detail.paymentMethodData.tokenizationData.token
      );
      this.expressPay.emit({
        billingMethod: 'onlinePay',
        payments: [
          {
            type: 'googlePay',
            requestedAmount: this.totalPrice,
            version: tokenData.protocolVersion,
            data: tokenData.signedMessage,
            signature: tokenData.signature,
            billingPostalCode: event.detail.paymentMethodData.info
              ?.billingAddress?.postalCode
              ? event.detail.paymentMethodData.info.billingAddress.postalCode
              : null
          }
        ]
      });
    } catch (error) {
      this.notificationService.showError(this.GOOGLE_PAY_ERROR_MSG);
    }
  };

  private scrollToSection(elementId: string, extraOffset = 0) {
    const element = document.getElementById(elementId);
    const headerOffset = 45 + extraOffset;
    const elementPosition = element?.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      behavior: 'smooth',
      top: offsetPosition
    });
  }
}
