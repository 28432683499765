import { cloneDeep } from 'lodash';

import { ModGroups } from '../../ui/common/types';
import { Optional } from '../types/common.types';
import { MenuModifierGroup } from '../types/store-info.types';

export const totalBasketPrice = (
  selectedModifierGroups: ModGroups[]
): number => {
  let totalPrice = 0;
  const modifierGroupsWithPrice = cloneDeep(selectedModifierGroups);
  modifierGroupsWithPrice.forEach((selectedModifierGroup) => {
    selectedModifierGroup.modifiers?.forEach((modifier) => {
      totalPrice = modifier.price ? totalPrice + modifier.price : totalPrice;
      // accounting for each item modifier price if available
      modifier.modifierGroups?.forEach((modifierGroup) => {
        modifierGroup.modifiers?.forEach((modifier) => {
          if (modifier.price) {
            totalPrice += modifier.price;
          }
        });
      });
    });
  });
  return totalPrice;
};

export const selectedModifierGroupsForPayload = (
  selectedModifierGroups: ModGroups[]
): ModGroups[] => {
  const modifierGroupsWithoutPrice = cloneDeep(selectedModifierGroups);
  modifierGroupsWithoutPrice.forEach((selectedModifierGroup) => {
    selectedModifierGroup.modifiers?.forEach((modifier) => {
      delete modifier.price;
      delete modifier.isValid;
    });
  });
  return modifierGroupsWithoutPrice;
};

export const areModifierGroupsValid = (
  data: Optional<Array<MenuModifierGroup>> | undefined,
  selectedData: ModGroups[]
): boolean => {
  const validityOfModifierGroups: boolean[] = [];
  data?.forEach((modGroup) => {
    const minSelectionsRequired = modGroup.minSelectionsRequired || 0;
    if (minSelectionsRequired > 0) {
      const selectedModGroupIndex = selectedData.findIndex(
        (modGp) => modGp.modifierGroupId === modGroup.id
      );

      if (selectedModGroupIndex > -1) {
        if (
          selectedData[selectedModGroupIndex].modifiers.length >=
          minSelectionsRequired
        )
          validityOfModifierGroups.push(true);
        else validityOfModifierGroups.push(false);
      } else validityOfModifierGroups.push(false);
    }
  });

  return validityOfModifierGroups.reduce((res, cur) => res && cur, true);
};

export const removeModGroupsWithEmptyModifiers = (
  modifierGroups: ModGroups[]
) => {
  return modifierGroups.forEach((modifierGroup, index) => {
    if (modifierGroup.modifiers?.length === 0) {
      modifierGroups.splice(index, 1);
    }
  });
};

export const checkIsVisible = (element: HTMLElement | null): boolean => {
  if (element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  return false;
};
