import { map } from 'lodash/fp';

import { FulfillmentTimesDto } from '../types/fulfillment-times.types';
import { OutOfStockItemsDto } from '../types/out-of-stock-items.types';
import {
  MenuCategory,
  MenuCategoryDto,
  MenuItem,
  MenuItemDto,
  MenuItemGroup,
  MenuItemGroupDto,
  MenuModifier,
  MenuModifierDto,
  MenuModifierGroup,
  MenuModifierGroupDto,
  MenuModifierGroupItem,
  MenuModifierGroupItemDto,
  MenuPOSId,
  MenuPOSIdDto,
  MenuProduct,
  MenuProductDto,
  MenuUpsellItem,
  MenuUpsellItemDto,
  MenuUpsells,
  MenuUpsellsDto,
  StoreInfo,
  StoreInfoDto
} from '../types/store-info.types';
import { fromDto } from './from-dto';
import { ifAvailableInSchedule } from './if-available-in-schedule';
import { ifFound } from './if-found';

export const fromMenuUpsellItemDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuUpsellItemDto): MenuUpsellItem => ({
    ...input,
    outOfStock: outOfStockItems.items.some((i) => i.id === input.id),
    availableInSchedule: ifAvailableInSchedule(
      input.alwaysAvailable ?? true,
      input.availabilitySchedule ?? undefined,
      timeZone
    )
  });

export const fromMenuUpsellsDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuUpsellsDto): MenuUpsells => ({
    ...input,
    upsellItems: input.upsellItems.map(
      fromMenuUpsellItemDto(outOfStockItems, timeZone)
    )
  });

export const fromMenuPOSIdDto = (input: MenuPOSIdDto): MenuPOSId => ({
  ...input,
  POSSystem: fromDto(input.POSSystem)
});

export const fromMenuModifierDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuModifierDto): MenuModifier => ({
    ...input,
    images: input.images.map(fromDto),
    metadata: ifFound(fromDto)(input.metadata),
    availabilitySchedule: input.availabilitySchedule.map(fromDto),
    outOfStock: outOfStockItems.modifiers.some((i) => i.id === input.id),
    availableInSchedule: ifAvailableInSchedule(
      input.alwaysAvailable,
      input.availabilitySchedule,
      timeZone
    )
  });

export const fromMenuModifierGroupItemDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuModifierGroupItemDto): MenuModifierGroupItem => ({
    ...input,
    item: ifFound(fromMenuItemDto(outOfStockItems, timeZone))(input.item),
    modifier: ifFound(fromMenuModifierDto(outOfStockItems, timeZone))(
      input.modifier
    ),
    availableInSchedule:
      ifAvailableInSchedule(
        input.item?.alwaysAvailable,
        input.item?.availabilitySchedule,
        timeZone
      ) ||
      ifAvailableInSchedule(
        input.modifier?.alwaysAvailable,
        input.modifier?.availabilitySchedule,
        timeZone
      )
  });

export const fromMenuModifierGroupDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuModifierGroupDto): MenuModifierGroup => ({
    ...input,
    modifierGroupElements: input.modifierGroupElements.map(
      fromMenuModifierGroupItemDto(outOfStockItems, timeZone)
    ),
    availableInSchedule: input.modifierGroupElements.some(
      (s) =>
        ifAvailableInSchedule(
          s.modifier?.alwaysAvailable,
          s.modifier?.availabilitySchedule,
          timeZone
        ) ||
        ifAvailableInSchedule(
          s.item?.alwaysAvailable,
          s.item?.availabilitySchedule,
          timeZone
        )
    )
  });

export function fromMenuItemDto(
  outOfStockItems: OutOfStockItemsDto,
  timeZone: string
) {
  return (input: MenuItemDto): MenuItem => ({
    ...input,
    images: input.images.map(fromDto),
    metadata: ifFound(fromDto)(input.metadata),
    upsells: ifFound(fromMenuUpsellsDto(outOfStockItems, timeZone))(
      input.upsells
    ),
    availabilitySchedule: input.availabilitySchedule.map(fromDto),
    modifierGroups: ifFound(
      map(fromMenuModifierGroupDto(outOfStockItems, timeZone))
    )(input.modifierGroups),
    outOfStock: outOfStockItems.items.some((i) => i.id === input.id),
    availableInSchedule: ifAvailableInSchedule(
      input.alwaysAvailable,
      input.availabilitySchedule,
      timeZone
    )
  });
}

export const fromMenuItemGroupDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuItemGroupDto): MenuItemGroup => ({
    ...input,
    images: input.images.map(fromDto),
    items: input.items.map(fromMenuItemDto(outOfStockItems, timeZone)),
    availableInSchedule: input.items.some((s) =>
      ifAvailableInSchedule(s.alwaysAvailable, s.availabilitySchedule, timeZone)
    )
  });

export const fromMenuProductDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuProductDto): MenuProduct => ({
    ...input,
    item: ifFound(fromMenuItemDto(outOfStockItems, timeZone))(input.item),
    itemGroup: ifFound(fromMenuItemGroupDto(outOfStockItems, timeZone))(
      input.itemGroup
    )
  });

export const fromMenuCategoryDto =
  (outOfStockItems: OutOfStockItemsDto, timeZone: string) =>
  (input: MenuCategoryDto): MenuCategory => {
    return {
      ...input,
      products: input.products.map(
        fromMenuProductDto(outOfStockItems, timeZone)
      ),
      metadata: ifFound(fromDto)(input.metadata),
      availableInSchedule: input.products.some(
        (p) =>
          ifAvailableInSchedule(
            p.item?.alwaysAvailable,
            p.item?.availabilitySchedule,
            timeZone
          ) ||
          p.itemGroup?.items.some((s) =>
            ifAvailableInSchedule(
              s.alwaysAvailable,
              s.availabilitySchedule,
              timeZone
            )
          )
      )
    };
  };

export function fromStoreInfoDto(
  outOfStockItems: OutOfStockItemsDto,
  fulfillmentTimes: FulfillmentTimesDto | undefined
) {
  return (input: StoreInfoDto): StoreInfo => ({
    ...input,
    fulfillmentTimes,
    categories:
      input.categories?.map(
        fromMenuCategoryDto(outOfStockItems, input.storeDetails.timeZone)
      ) ?? [],
    storeDetails: fromDto(input.storeDetails)
  });
}
