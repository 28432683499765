import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import {
  Cart,
  CartAddItemRequest,
  CartItem,
  CartTip
} from '../../../types/cart.types';
import { FeatureState } from '../../types/feature-state';

export type CartFeatureState = FeatureState<Cart, 'cart'>;

const initialCartFeatureState: CartFeatureState = {
  isLoading: false,
  error: null,
  cart: null
};

const CartActions = createActionGroup({
  source: 'Cart Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{ error?: string; cart?: Cart }>(),
    'Set Add To Cart On WS': props<{ item: CartAddItemRequest }>(),
    'Item Added To Cart': props<{ lineItem?: CartItem }>(),
    'Tip Selected': props<{ tip?: CartTip }>(),
    'Reset To Default': emptyProps()
  }
});

const CartReducer = createReducer(
  initialCartFeatureState,
  on(
    CartActions.setIsLoading,
    (state): CartFeatureState => ({ ...state, isLoading: true })
  ),
  on(
    CartActions.setState,
    (state, action): CartFeatureState => ({
      ...state,
      isLoading: false,
      error: action.error ?? null,
      cart: action.cart ?? state.cart ?? null
    })
  ),
  on(
    CartActions.resetToDefault,
    (state): CartFeatureState => ({
      ...state,
      ...initialCartFeatureState
    })
  )
);

const CartFeatureKey = 'cart';

const CartFeature = createFeature({
  name: CartFeatureKey,
  reducer: CartReducer
});

export default {
  ...CartFeature,
  actions: CartActions,
  initialState: initialCartFeatureState
};
