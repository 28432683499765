/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

import { StoreInfoRepository } from '../../repositories/store-info/store-info.repository';
import * as MaybeResponse from '../../types/maybe-response';
import { OfferDetails } from '../../types/offer-details.types';
import {
  MenuItem,
  StoreInfo,
  StoreInfoDto
} from '../../types/store-info.types';
import { fromDto } from '../../utils/from-dto';
import {
  fromMenuItemDto,
  fromStoreInfoDto
} from '../../utils/from-store-info-dto';

@Injectable({ providedIn: 'root' })
export class StoreInfoService {
  constructor(private storeInfoRepository: StoreInfoRepository) {}

  public getStoreInfoBySlug(
    slug: string,
    serviceMode: string
  ): Observable<MaybeResponse.MaybeResponse<StoreInfo>> {
    return combineLatest([
      this.storeInfoRepository.getStoreInfoBySlug(slug, serviceMode)
    ]).pipe(
      switchMap(([info]) =>
        info.data
          ? combineLatest([
              of(info),
              this.storeInfoRepository.getOutOfStockitems(
                info.data.storeDetails.id
              )
            ]).pipe(
              map(([info, outOfStockItems]) => ({
                data:
                  info.data && outOfStockItems.data
                    ? fromStoreInfoDto(
                        outOfStockItems.data,
                        undefined
                      )(info.data)
                    : undefined,
                error: info.error ?? outOfStockItems.error
              }))
            )
          : of({
              error: info.error
            })
      )
    );
  }

  public getStoreInfoById(
    locationId: string,
    serviceMode: string,
    callFulfillmentTimesAPI = false
  ): Observable<MaybeResponse.MaybeResponse<StoreInfo>> {
    return this.storeInfoRepository
      .getStoreInfoById(locationId, serviceMode)
      .pipe(
        switchMap((info) =>
          this.storeInfoRepository.getOutOfStockitems(locationId).pipe(
            switchMap((outOfStockItems) => {
              if (callFulfillmentTimesAPI) {
                return this.storeInfoRepository
                  .getFulfillmentTimes(locationId, serviceMode)
                  .pipe(
                    map((fulfillmentTimes) => ({
                      data:
                        info.data &&
                        outOfStockItems.data &&
                        fulfillmentTimes.data
                          ? fromStoreInfoDto(
                              outOfStockItems.data,
                              fulfillmentTimes.data
                            )(info.data)
                          : undefined,
                      error:
                        info.error ??
                        outOfStockItems.error ??
                        fulfillmentTimes.error
                    }))
                  );
              } else {
                return of({
                  data:
                    info.data && outOfStockItems.data
                      ? fromStoreInfoDto(
                          outOfStockItems.data,
                          undefined
                        )(info.data)
                      : undefined,
                  error: info.error ?? outOfStockItems.error
                });
              }
            })
          )
        )
      );
  }

  public getMenuItemInfo(
    itemId: string,
    locationId: string,
    serviceMode: string,
    timeZone: string
  ): Observable<MaybeResponse.MaybeResponse<MenuItem>> {
    return combineLatest([
      this.storeInfoRepository.getMenuItemInfo(itemId, locationId, serviceMode),
      this.storeInfoRepository.getOutOfStockitems(locationId)
    ]).pipe(
      map(([info, outOfStockItems]) =>
        info.data && outOfStockItems.data
          ? {
              data: fromMenuItemDto(outOfStockItems.data, timeZone)(info.data),
              error: info.error ?? outOfStockItems.error
            }
          : { data: info.data, error: info.error ?? outOfStockItems.error }
      )
    );
  }

  public getOfferDetails(
    offerCode: string,
    storeId: string,
    recaptchaToken: string
  ): Observable<MaybeResponse.MaybeResponse<OfferDetails>> {
    return this.storeInfoRepository
      .getOfferDetails(offerCode, storeId, recaptchaToken)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getOfferDetailsByTimezone(
    offerCode: string,
    recaptchaToken: string,
    timeZone?: string
  ): Observable<MaybeResponse.MaybeResponse<OfferDetails>> {
    return this.storeInfoRepository
      .getOfferDetailsByTimezone(offerCode, recaptchaToken, timeZone)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationDetailsUsingMenuLocationId(
    slug: string,
    serviceMode: string
  ): Observable<MaybeResponse.MaybeResponse<StoreInfoDto>> {
    return this.storeInfoRepository
      .getStoreInfoBySlug(slug, serviceMode)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }
}
