import { BaseModel } from '@wingstop/models/base.model';
import { CalendarRange } from './calendar-range.model';

export class Schedule extends BaseModel {
  created_at: string = null;
  id: number = null;
  label: string = null;
  store_id: number = null;
  type: string = null;
  updated_at: string = null;
  ranges: CalendarRange[] = null;
  utcoffset: number = null;

  constructor(values?: any) {
    super();

    if (values) {
      if (values.ranges) {
        values.ranges = values.ranges.map(
          (value: any) =>
            new CalendarRange({ ...value, ...{ utcoffset: values.utcoffset } })
        );
      }
    }

    this.initialize(values);
  }
}
