import { Location } from '@wingstop/models/location/location.model';
import { Basket } from '@wingstop/models/basket.model';
import { Injectable } from '@angular/core';
import { environment } from '@wingstop/environments/environment';
import moment from 'moment-mini';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private static DEBUG = false;
  private static DEBUG_TIME_TRAVEL_TO = moment()
    .set('hour', 6)
    .set('minute', 0);

  public static getNow() {
    if (GlobalService.DEBUG) {
      console.log(
        'GlobalService: In debug mode. Now time: ' +
        GlobalService.DEBUG_TIME_TRAVEL_TO.toString()
      );
    }

    return GlobalService.DEBUG ? GlobalService.DEBUG_TIME_TRAVEL_TO : moment();
  }

  public static logGaEvent(event: any) {
    try {
      dataLayer.push(event);
    } catch (e) {
      console.error(e);
    }
  }
  public static getEarliestReadyMoment(
    location: Location,
    basket: Basket | OrderResponse,
    adjustTimezone = true
  ) {
    let hoursDiff = 0;
    if (adjustTimezone) {
      let myOffset = +(GlobalService.getNow().utcOffset() / 60);
      let locationOffset = +location.utcoffset;
      hoursDiff = myOffset - locationOffset;
    }

    let earliestReadyTimeInMyTimeZone: moment.Moment;
    if (basket instanceof Basket) {
      earliestReadyTimeInMyTimeZone = basket.earliestreadytime.clone();
    } else {
      earliestReadyTimeInMyTimeZone = basket.readytime.clone();
    }
    earliestReadyTimeInMyTimeZone.add(hoursDiff, 'hours');
    earliestReadyTimeInMyTimeZone.set('seconds', 59);

    return earliestReadyTimeInMyTimeZone;
  }

  public static getWaitTimeLabel(
    location: Location,
    basket: Basket | OrderResponse,
    twoLines = false
  ) {
    let description = '';
    let time = '';

    if (basket.inASAPMode()) {
      if (basket instanceof Basket) {
        let waitTime = basket.leadtimeestimateminutes;
        let waitTimeLower = waitTime + environment.orderWindow.lower;
        let waitTimeUpper = waitTime + environment.orderWindow.upper;
        time = waitTimeLower + ' – ' + waitTimeUpper + ' minutes';
      } else {
        // Get the diff between our current client time and the store's time
        let waitTime = GlobalService.getEarliestReadyMoment(
          location,
          basket,
          false
        );
        // Diff the two moment objects in minujtes
        let diff = waitTime.diff(basket.timeplaced, 'minutes');
        // Add the upper and lower..
        let waitTimeLower = diff + environment.orderWindow.lower;
        let waitTimeUpper = diff + environment.orderWindow.upper;
        // Update the label
        time = waitTimeLower + ' – ' + waitTimeUpper + ' minutes';
      }

      if (basket.deliverymode === 'pickup') {
        description = 'Ready for carryout in about';
      }
      if (basket.deliverymode === 'curbside') {
        description = 'Ready for curbside pickup in about';
      }
      if (basket.deliverymode === 'dispatch') {
        description = 'Delivery in about';
      }
      if (basket.deliverymode === 'dinein') {
        description = 'Ready for dine-in in about';
      }
    } else if (basket.inLaterMode()) {
      let end: any;
      if (basket instanceof Basket) {
        end = basket.timewanted
          ? basket.timewanted
          : GlobalService.getEarliestReadyMoment(location, basket, false);
      } else {
        end = GlobalService.getEarliestReadyMoment(location, basket, false);
      }

      // earliest ready time doesn't have seconds component but "now" does.
      // this causes us to be off by 1 minute. Ex: 9:30AM - 9:14:47AM = 15 whole minutes, not 16 as it should be
      // here, we set the seconds to 59 to compensate
      end.set('seconds', 59);

      // may already be a moment, but here we make sure
      let momentStart = GlobalService.getNow();
      let momentEnd = moment(end);

      if (momentStart.isSame(momentEnd, 'day')) {
        time = momentEnd.format('[today at] h:mmA');
      } else if (momentStart.clone().add(1, 'days').isSame(momentEnd, 'day')) {
        time = momentEnd.format('[tomorrow at] h:mmA');
      } else {
        time = momentEnd.format('dddd, MMMM D [at] h:mmA');
      }

      if (basket) {
        // description = 'Scheduled for ' + basket.deliverymode;
        if (basket.deliverymode === 'pickup') {
          description = 'Scheduled for carryout';
        }
        if (basket.deliverymode === 'curbside') {
          description = 'Scheduled for curbside';
        }
        if (basket.deliverymode === 'dispatch') {
          description = 'Scheduled for delivery';
        }
        if (basket.deliverymode === 'dinein') {
          description = 'Scheduled for dine-in';
        }
      }
    }

    if (twoLines) {
      return [description, time];
    } else {
      return description + ' ' + time + '.';
    }
  }
}
