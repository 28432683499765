import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import { EcommModule } from '../../ecomm/ecomm.module';
import {
  Config,
  ConfigProvider
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider
} from '../../ecomm/providers/legacy-providers/seo.service';
import { WriCommonModule } from '../common/common.module';
import { CityDetailsComponent } from './components/city-details/city-details.component';
import { LocationCardComponent } from './components/location-card/location-card.component';
import { LocationDetailsComponent } from './components/location-details/location-details.component';
import { StateDetailsComponent } from './components/state-details/state-details.component';

const components = [
  CityDetailsComponent,
  LocationCardComponent,
  LocationDetailsComponent,
  StateDetailsComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, WriCommonModule, EcommModule],
  exports: components,
  declarations: components
})
export class LocationsModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<LocationsModule> {
    return {
      ngModule: LocationsModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
