<div
  class="group-heat-scale-wrapper"
  role="text"
  [attr.aria-label]="'Heat scale ' + heatScale + ' of 5'"
>
  <div *ngFor="let scale of numSequence(5); index as i">
    <wri-icon
      icon="wri-heat-scale"
      aria-hidden="true"
      [ngClass]="getIconClass(i)"
    ></wri-icon>
  </div>
</div>
