import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CAN_USE_CUSTOM_SEO } from '../../ecomm/store/middleware/static-seo-metadata-effects';
import { ACTIVE_OFFER, ActiveOfferResolver, CommonGuard, offlineGuard } from '../common';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { unAuthenticatedGuard } from '../common/guards/un-authenticated.guard';
import {
  ACTIVATED_ROUTE_SNAPSHOT,
  ActivatedRouteSnapshotResolver
} from '../common/resolvers/activated-route-snapshot.resolver';
import {
  DYNAMIC_SCRIPTS,
  DynamicScriptsResolver
} from '../common/resolvers/dynamic-scripts.resolver';
import { ChooseLocationComponent } from './components/choose-location/choose-location.component';
import { ConnectedBagComponent } from './components/connected-bag/connected-bag.component';
import { ConnectedCheckoutComponent } from './components/connected-checkout/connected-checkout.component';
import { ConnectedConfirmationComponent } from './components/connected-confirmation/connected-confirmation.component';
import { ConnectedOrderDetailsComponent } from './components/connected-order-details/connected-order-details.component';
import { UserOrdersComponent } from './components/user-orders/user-orders.component';
import { OrderModule } from './order.module';

export const orderRoutes: Routes = [
  {
    path: 'my-bag',
    component: ConnectedBagComponent,
    data: {
      reuse: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, CommonGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: 'checkout',
    component: ConnectedCheckoutComponent,
    data: {
      reuse: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, CommonGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: 'confirmation',
    component: ConnectedConfirmationComponent,
    data: {
      reuse: true
    },
    canActivate: [combineGuard(cacheLoadedGuard, CommonGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: 'recent',
    component: UserOrdersComponent,
    canActivate: [combineGuard(cacheLoadedGuard, unAuthenticatedGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: 'recent/order-details/:id',
    component: ConnectedOrderDetailsComponent,
    canActivate: [combineGuard(cacheLoadedGuard, unAuthenticatedGuard, offlineGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: '',
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableOrderRoute'), offlineGuard)
    ],
    data: {
      [CAN_USE_CUSTOM_SEO]: true
    },
    component: ChooseLocationComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: 'action/carryout',
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableOrderRoute'), offlineGuard)
    ],
    data: {
      [CAN_USE_CUSTOM_SEO]: true
    },
    component: ChooseLocationComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: 'action/delivery',
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableOrderRoute'), offlineGuard)
    ],
    data: {
      [CAN_USE_CUSTOM_SEO]: true
    },
    component: ChooseLocationComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path: ':product',
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableOrderRoute'), offlineGuard)
    ],
    data: {
      [CAN_USE_CUSTOM_SEO]: true
    },
    component: ChooseLocationComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  }
];

@NgModule({ imports: [OrderModule, RouterModule.forChild(orderRoutes)] })
export class OrderRoutingModule { }
