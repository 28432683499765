import * as t from 'io-ts';

import { ecommApiResponse, optional } from './common.types';

/** OutOfStockItem */
const OutOfStockItemDto = t.intersection(
  [
    t.type({ id: t.string }, 'OutOfStockItemDtoOptional'),
    t.partial({}, 'OutOfStockItemDtoOptional')
  ],
  'OutOfStockItemDto'
);

export type OutOfStockItemDto = t.TypeOf<typeof OutOfStockItemDto>;

export type OutOfStockItem = OutOfStockItemDto;
/* #endregion */

/* #region OutOfStockItemsDto */
export const OutOfStockItemsDto = t.intersection(
  [
    t.type(
      {
        version: optional(t.string),
        items: t.array(OutOfStockItemDto),
        modifiers: t.array(OutOfStockItemDto)
      },
      'OutOfStockItemsDtoRequired'
    ),
    t.partial({}, 'OutOfStockItemsDtoOptional')
  ],
  'OutOfStockItemsDto'
);

export type OutOfStockItemsDto = t.TypeOf<typeof OutOfStockItemsDto>;

export type OutOfStockItems = Omit<
  OutOfStockItemsDto,
  'items' | 'modifiers'
> & {
  items: OutOfStockItem[];
  modifiers: OutOfStockItem[];
};
/* #endregion */

/* #region OutOfStockItem */
export const OutOfStockItemsResponse = ecommApiResponse(OutOfStockItemsDto);

export type OutOfStockItemsResponse = t.TypeOf<typeof OutOfStockItemsResponse>;
/* #endregion */
