import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { OrderSubmitRequestPayment } from '../../../../../ecomm/types/order.types';
import {
  CreditCardDetails,
  VaultedCreditCards,
  VaultedGiftCards
} from '../../../../../ecomm/types/payment.types';
import { BillingMethod } from '../../../../../ecomm/workflows/order/order-workflow.service';

export type ApplePayPaymentData = {
  data: string;
  header: {
    ephemeralPublicKey: string;
    publicKeyHash: string;
    transactionId: string;
  };
  signature: string;
  version: string;
};

export type ApplePayPaymentMethod = {
  displayName: string;
  network: string;
  type: string;
};

export type ApplePayPaymentTransaction = {
  billingContact: ApplePayPaymentTransactionBillingContract;
  shippingContact: ApplePayPaymentTransactionShippingContact;
  token: ApplePayPaymentTransactionToken;
};

export type ApplePayPaymentTransactionBillingContract = {
  addressLines: string[];
  administrativeArea: string;
  country: string;
  countryCode: string;
  familyName: string;
  givenName: string;
  locality: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
  postalCode: string;
  subAdministrativeArea: string;
  subLocality: string;
};

export type ApplePayPaymentTransactionShippingContact = {
  emailAddress: string;
  familyName: string;
  givenName: string;
  phoneNumber: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
};

export type ApplePayPaymentTransactionToken = {
  paymentData: ApplePayPaymentData;
  paymentMethod: ApplePayPaymentMethod;
  transactionIdentifier: string;
};

export type ApplePayPaymentAuthorizedEvent = {
  bubbles: boolean;
  cancelBubble: boolean;
  cancelable: boolean;
  composed: boolean;
  defaultPrevented: boolean;
  eventPhase: number;
  isTrusted: boolean;
  payment: ApplePayPaymentTransaction;
  returnValue: boolean;
  srcElement: object;
  target: object;
  timeStamp: number;
  type: string;
};

export type PaymentInfo = {
  billingMethod: BillingMethod;
  payments?: OrderSubmitRequestPayment[];
};

export type PaymentMethodLike = {
  paymentType?: string;
  getPaymentInfo(): Observable<PaymentInfo>;
};

export type ExpressPaymentMethodLike = {
  expressPay: EventEmitter<PaymentInfo>;
  expressPayClicked: EventEmitter<string>;
  expressPayPrerequisitesMet: boolean;
};

export type ConditionalPaymentMethodLike = {
  paymentSupported: EventEmitter<boolean>;
};

export type GiftCardResponse = {
  balance: number;
  currency: string;
  alias: string;
  vaultedAccountId: string;
};

export type GiftCardFormData = {
  cardNumber: string;
  securityCode: string;
  vault: boolean;
};

export type AnonymousGiftCardType = 'anonymous';
export const AnonymousGiftCardType: Record<
  AnonymousGiftCardType,
  AnonymousGiftCardType
> = {
  anonymous: 'anonymous'
};

export type ValutedGiftCardType = 'valuted';
export const ValutedGiftCardType: Record<
  ValutedGiftCardType,
  ValutedGiftCardType
> = {
  valuted: 'valuted'
};

export type AddAnonymousGiftCard = GiftCardResponse &
  GiftCardFormData & { type: AnonymousGiftCardType; requestedAmount: number };

export type AddedGiftCard =
  | (GiftCardResponse &
      GiftCardFormData & {
        type: AnonymousGiftCardType;
        requestedAmount: number;
      })
  | (VaultedGiftCards & {
      type: ValutedGiftCardType;
      requestedAmount: number;
    });

export type GiftCardPaymentMethodStatusEvent = {
  appliedGiftCardCount: number;
  giftCardCount: number;
  priceAfterGiftCards: number;
  appliedGiftCardCountWithZeroBalance: number;
};

export type CreditCardPaymentMethodStatusEvent = {
  appliedCreditCardCount: number;
  appliedCreditCardCountWithZeroBalance: number;
  creditCardCount: number;
  priceAfterCreditCards: number;
  newCreditCard: boolean;
};

export type AddedCreditCardDetails =
  | (CreditCardDetails & {
      id: string;
      type: 'anonymous';
      requestedAmount: number;
    })
  | (VaultedCreditCards & {
      id: string;
      type: 'vaulted';
      requestedAmount: number;
    });

export type PaymentType =
  | 'inStore'
  | 'giftCard'
  | 'creditCard'
  | 'applePay'
  | 'venmo'
  | 'payPal'
  | 'googlePay';

export const PaymentType: Record<PaymentType, PaymentType> = {
  inStore: 'inStore',
  giftCard: 'giftCard',
  creditCard: 'creditCard',
  applePay: 'applePay',
  venmo: 'venmo',
  payPal: 'payPal',
  googlePay: 'googlePay'
} as const;

export const AllowedSplits: Record<PaymentType, Array<PaymentType>> = {
  inStore: [],
  giftCard: ['creditCard'],
  creditCard: ['giftCard'],
  applePay: [],
  venmo: [],
  payPal: [],
  googlePay: []
};

export type FiservPaymentFailedType =
  | 'UnableToProcessPaymentRequest'
  | 'InvalidPaymentData'
  | 'PaymentDeclined'
  | 'TransactionNotFound'
  | 'CustomerProfileNotFound'
  | 'AccountDetailNotFound'
  | 'UnknownPaymentError';

export const FiservPaymentFailedType: Record<
  FiservPaymentFailedType,
  FiservPaymentFailedType
> = {
  UnableToProcessPaymentRequest: 'UnableToProcessPaymentRequest',
  InvalidPaymentData: 'InvalidPaymentData',
  PaymentDeclined: 'PaymentDeclined',
  TransactionNotFound: 'TransactionNotFound',
  CustomerProfileNotFound: 'CustomerProfileNotFound',
  AccountDetailNotFound: 'AccountDetailNotFound',
  UnknownPaymentError: 'UnknownPaymentError'
} as const;

export type OtherPaymentFailedType = 'ValidationErrorPaymentTypeNotAllowed';

export const OtherPaymentFailedType: Record<
  OtherPaymentFailedType,
  OtherPaymentFailedType
> = {
  ValidationErrorPaymentTypeNotAllowed: 'ValidationErrorPaymentTypeNotAllowed'
} as const;
