import { Component, ViewChild } from '@angular/core';
import {
  faFacebookF,
  faInstagram,
  faSpotify,
  faTiktok,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { GlobalService } from '@wingstop/services/global.services';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalLinkConfirmationModalComponent } from '../../../../lib/ngfe-app/src/lib/ui';
import { AnalyticsService } from '../../../../lib/ngfe-app/src/lib/ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  icons = {
    faFacebookF,
    faInstagram,
    faXTwitter,
    faSpotify,
    faTiktok,
  };

  public language = 'English';
  public copyrightYear: string;
  public digitalMenu = false;
  public kiosk = false;
  public shouldShow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild(ExternalLinkConfirmationModalComponent)
  externalLinkConfirmationModalComponent!: ExternalLinkConfirmationModalComponent;
  private subscriptions: Subscription[] = [];
  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private appStateSelectors: AppStateSelectors,
    private analyticsService: AnalyticsService
  ) {
    const params = new URL(window.location.href).searchParams;
    this.kiosk = !!params.get('kiosk');
    this.copyrightYear = GlobalService.getNow().format('YYYY');

    this.subscriptions.push(
      this.appStateSelectors.isDigitalMenu
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((isDigitalMenu: boolean) => {
          this.digitalMenu = isDigitalMenu;
          this.shouldShow$.next(!this.kiosk && !this.digitalMenu);
        })
    );
  }

  public externalSite(event: MouseEvent, whereTo: string = null) {
    event.preventDefault();
    this.externalLinkConfirmationModalComponent.openModal(whereTo);
  }

  public openSocialLinks(event: MouseEvent, whereTo: string = null) {
    event.preventDefault();
    window.open(whereTo);
  }

  onAppDownloadClick(app: string) {
    this.analyticsService.logGaEvent({
      event: 'download_app',
      page: window.location.pathname,
      app_store_type: app,
    });
  }
}
