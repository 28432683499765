import { ClassProvider, InjectionToken, Type } from '@angular/core';
import moment from 'moment-timezone';

import { Location } from '../../types/search-location.types';
import { MenuItem, StoreDetails } from '../../types/store-info.types';

export type SeoMetadata = {
  route?: string;
  canonical?: string;
  title?: string;
  description?: string;
  type?: string;
  image?: string | void;
  site_name?: string;
};

/* #region StructuredData */

/* #region Thing */
export type StructuredDataThing = {
  '@context': 'http://schema.org';
  '@type': 'Thing';
  name: string;
  url: string;
  description: string;
};

export const structuredDataThing = (
  url: string,
  description: string,
  partial: Partial<StructuredDataThing> = {}
): StructuredDataThing => ({
  '@context': 'http://schema.org',
  '@type': 'Thing',
  name: 'Wingstop',
  url,
  description,
  ...partial
});
/* #endregion */

/* #region City */
export type StructuredDataCity = {
  '@context': 'http://schema.org';
  '@type': 'City';
  name: string;
};

export const structuredDataCity = (
  city: string,
  partial: Partial<StructuredDataCity> = {}
): StructuredDataCity => ({
  '@context': 'http://schema.org',
  '@type': 'City',
  name: city,
  ...partial
});
/* #endregion */

/* #region State */
export type StructuredDataState = {
  '@context': 'http://schema.org';
  '@type': 'State';
  name: string;
};

export const structuredDataState = (
  state: string,
  partial: Partial<StructuredDataState> = {}
): StructuredDataState => ({
  '@context': 'http://schema.org',
  '@type': 'State',
  name: state,
  ...partial
});
/* #endregion */

/* #region Corporation */
export type StructuredDataCorporation = {
  '@context': 'http://schema.org';
  '@type': 'Corporation';
  name: string;
  slogan: string;
  logo: string;
  url: string;
  tickerSymbol: string;
  sameAs: string[];
};
export const structuredDataCorporation = (
  document: Document,
  partial: Partial<StructuredDataCorporation> = {}
): StructuredDataCorporation => ({
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Wingstop Inc',
  slogan: 'Bring the Flavor',
  logo: 'https://www.wingstop.com/assets/images/wingstop-logo-green-340.png',
  url: document.location.origin,
  tickerSymbol: 'Wing',
  sameAs: [
    'https://www.facebook.com/wingstop/',
    'https://www.instagram.com/wingstop/ ',
    'https://twitter.com/wingstop/',
    'https://www.snapchat.com/add/wingstopoffical',
    'https://www.tiktok.com/@wingstop'
  ],
  ...partial
});
/* #endregion */

/* #region BreadcrumbList */
export type StructuredDataBreadcrumbListItem = {
  '@type': 'ListItem';
  position: number;
  name: string;
  item: string;
};

export type StructuredDataBreadcrumbList = {
  '@context': 'http://schema.org';
  '@type': 'BreadcrumbList';
  itemListElement: StructuredDataBreadcrumbListItem[];
};

export const structuredDataBreadcrumbList = (
  crumbs: { name: string; url: string }[],
  partial: Partial<StructuredDataBreadcrumbList> = {}
): StructuredDataBreadcrumbList => ({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: crumbs.map((crumb, pos) => ({
    '@type': 'ListItem',
    position: pos + 1,
    name: crumb.name,
    item: crumb.url
  })),
  ...partial
});
/* #endregion */

/* #region Restaurant */
export type StructuredDataAddress = {
  '@type': 'PostalAddress';
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  streetAddress: string;
};

export type StructuredDataGeoCoordinates = {
  '@type': 'GeoCoordinates';
  latitude: number;
  longitude: number;
};

export type StructuredDataOpeningHoursSpecification = {
  '@type': 'OpeningHoursSpecification';
  closes: string;
  dayOfWeek: string;
  opens: string;
};

export type StructuredDataMenu = {
  '@type': 'Menu';
  hasMenuSection: {
    '@type': 'MenuSection';
    name: string;
    hasMenuItem: {
      '@context': 'http://schema.org';
      '@type': 'MenuItem';
      name: string;
      description: string;
      url: string;
      image: string;
    };
  };
};

export type StructuredDataRestaurant = {
  '@context': 'http://schema.org';
  '@type': 'Restaurant';
  name: string;
  url: string;
  description?: string;
  address?: StructuredDataAddress;
  geo?: StructuredDataGeoCoordinates;
  image: string;
  telephone?: string;
  openingHoursSpecification?: StructuredDataOpeningHoursSpecification[];
  hasMenu?: string | StructuredDataMenu;
  servesCuisine: string;
};

export const structuredDataRestaurantFromLocation = (
  document: Document,
  location: Location,
  description: string,
  partial: Partial<StructuredDataRestaurant> = {}
): StructuredDataRestaurant => ({
  '@context': 'http://schema.org',
  '@type': 'Restaurant',
  name: location.name,
  url: document.location.origin,
  description: description,
  address: {
    '@type': 'PostalAddress',
    addressLocality: location.locality,
    addressRegion: location.region,
    postalCode: location.postalCode,
    streetAddress: location.streetAddress ?? ''
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: location.latitude,
    longitude: location.longitude
  },
  image: 'https://www.wingstop.com/assets/images/logo-green-product.png',
  telephone: location.phoneNumber,
  openingHoursSpecification: location.storeHours.map((sh) => ({
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: `https://schema.org/${sh.startDay}`,
    opens: moment(sh.startTime, 'HH:mm:ss').format('h:mm:ss'),
    closes: moment(sh.endTime, 'HH:mm:ss').format('h:mm:ss')
  })),
  hasMenu: `${document.location.origin}/location/${location.slug}/menu`,
  servesCuisine: 'American, Chicken Wings',
  ...partial
});

export const structuredDataRestaurantFromStoreDetails = (
  document: Document,
  storeDetails: StoreDetails,
  partial: Partial<StructuredDataRestaurant> = {}
): StructuredDataRestaurant => ({
  '@context': 'http://schema.org',
  '@type': 'Restaurant',
  name: 'Wingstop',
  url: `${document.location.origin}/location/${storeDetails.slug}/menu`,
  description: storeDetails.description ?? '',
  address: {
    '@type': 'PostalAddress',
    addressLocality: storeDetails.city,
    addressRegion: storeDetails.state,
    postalCode: storeDetails.postalCode,
    streetAddress: [storeDetails.streetAddress1, storeDetails.streetAddress2]
      .filter(Boolean)
      .join(', ')
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: storeDetails.lat,
    longitude: storeDetails.long
  },
  image: 'https://www.wingstop.com/assets/images/logo-green-product.png',
  telephone: storeDetails.phoneNumber,
  servesCuisine: 'American, Chicken Wings',
  ...partial
});

export const structuredDataRestaurantFromMenuItem = (
  document: Document,
  menuItem: MenuItem,
  categoryName: string,
  partial: Partial<StructuredDataRestaurant> = {}
): StructuredDataRestaurant => ({
  '@context': 'http://schema.org',
  '@type': 'Restaurant',
  url: document.location.origin,
  name: 'Wingstop',
  image: 'https://www.wingstop.com/assets/images/logo-green-product.png',
  servesCuisine: 'American, Chicken Wings',
  hasMenu: {
    '@type': 'Menu',
    hasMenuSection: {
      '@type': 'MenuSection',
      name: categoryName,
      hasMenuItem: {
        '@context': 'http://schema.org',
        '@type': 'MenuItem',
        name: menuItem.name,
        description: menuItem.description ?? '',
        url: document.location.href,
        image: menuItem.images[0].uri
      }
    }
  },
  ...partial
});
/* #endregion */

export type StructuredData =
  | StructuredDataThing
  | StructuredDataState
  | StructuredDataCity
  | StructuredDataRestaurant
  | StructuredDataCorporation
  | StructuredDataBreadcrumbList;

/* #endregion */

export type ILegacySeoService = {
  setMetaData(meta: SeoMetadata): void;
  addStructuredData(schema: StructuredData[]): void;
  setRobotsNoIndexNoFollow(): void;
};

export const LEGACY_SEO_SERVICE = new InjectionToken<ILegacySeoService>(
  'Token for the legacy SeoService'
);

export class LegacySeoServiceProvider {
  public static get = (clazz: Type<ILegacySeoService>): ClassProvider => ({
    provide: LEGACY_SEO_SERVICE,
    useClass: clazz
  });
}
