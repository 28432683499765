import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

import loadingIndicator from './animations/sauce-n-toss-loader';

export const AnimationMap = {
  loading: loadingIndicator
} as const;

@Component({
  selector: 'wri-animation',
  template: `<ng-lottie
    [options]="_animationOptions"
    [width]="_widthPx"
  ></ng-lottie>`,
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent {
  /** @ignore */
  public _animationOptions: AnimationOptions | null = null;
  /** @ignore */
  public _widthPx: string | null = null;

  @Input() public set animation(animationName: keyof typeof AnimationMap) {
    this._animationOptions = {
      animationData: AnimationMap[animationName]
    };
  }

  @Input() public set width(width: number) {
    this._widthPx = `${width ?? 0}px`;
  }
}
