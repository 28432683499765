<div class="wri-homepage-hero hidden" id="wri-homepage-hero">
  <div class="homepage-hero-bg">
    <div class="hero-content">
      <div class="hero-title" data-testid="hero-title">
        Bring the Flavor<sup class="tm">&trade;</sup>
      </div>

      <div class="hero-message hero-message-desktop">
        We know one flavor doesn't fit all, that's why we perfected 12 of them. Your next
        flavor obsession starts with just one click.
      </div>

      <div class="hero-cta-wrapper">
        <button
            class="wri-btn wri-btn-primary hidden"
            aria-label="Order Carryout"
            data-testid="order-carryout-cta"
        >
          Order Carryout
        </button>

        <button
            class="wri-btn wri-btn-secondary"
            aria-label="Explore Now"
            data-testid="order-delivery-cta"
            (click)="navigateToMenu()"
        >
          Explore Menu
        </button>
      </div>
    </div>
  </div>
  <div class="hero-message hero-message-mobile">
    We know one flavor doesn't fit all, that's why we perfected 12 of them. Your next
    flavor obsession starts with just one click.
  </div>

</div>

