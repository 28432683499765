import { Injectable } from '@angular/core';
import { from, tap } from 'rxjs';

import { SearchLocationService } from '../../../ecomm/services/search-location/search-location.service';
import {
  LocationResponseData,
  SearchByCarryoutRequest,
  SearchByDeliveryAddressRequest,
  SearchByIdRequest,
  SearchByIdsRequest,
  SearchByLocalityRequest,
  SearchBySlugRequest
} from '../../../ecomm/types/search-location.types';
import * as Workflow from '../../../ecomm/types/workflow';
import { NotificationService } from '../../../ecomm/utils/notification/notification.service';
import { LocationDetailsFeature } from '../../store/features/location-details';
import { FeaturesState } from '../../store/types/features-state';
import { asyncTap } from '../../utils/async-tap';
import { AsynchronousDispatcher } from '../../utils/asynchronus-dispatcher/asynchronous-dispatcher.service';

@Injectable()
export class SearchLocationWorkflowService {
  constructor(
    private searchLocationService: SearchLocationService,
    private notificationService: NotificationService,
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>
  ) {}

  private reportErrors = <T>() =>
    Workflow.onError<T>(
      tap((res) => {
        console.log('res.error', res.error);
        this.notificationService.showError(res.error);
      })
    );

  private reportErrorsIfStatusIsNot404 = <T>() =>
    Workflow.onError<T>(
      tap((res) => {
        console.log('res.error', res.error);
        if (res.status !== 404) this.notificationService.showError(res.error);
      })
    );

  public getGeocodePlaces = Workflow.createWorkflow(
    undefined,
    (searchText: string, serviceMode: string) =>
      this.searchLocationService.getGeocodePlaces(searchText, serviceMode),
    this.reportErrors()
  );

  public getLocationsByIds = Workflow.createWorkflow(
    undefined,
    (params: SearchByIdsRequest) =>
      this.searchLocationService.getLocationsByIds(params),
    this.reportErrors()
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setLocationDetailsStateLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(
        LocationDetailsFeature.actions.setIsLoading()
      )
    );
  };

  private saveLocationDetailsState = Workflow.onAny<LocationResponseData>(
    asyncTap((locationDetails) => {
      return this.asynchronusDispatcher.dispatch(
        LocationDetailsFeature.actions.setState({
          locationDetails: locationDetails.data,
          error: locationDetails.error
          //selectedItem TODO: Once get Location by ID is implemented in mybag page.
        })
      );
    })
  );

  public getLocationById = Workflow.createWorkflow(
    this.setLocationDetailsStateLoading,
    (params: SearchByIdRequest) =>
      this.searchLocationService.getLocationById(params),
    this.reportErrors(),
    this.saveLocationDetailsState
  );

  public getLocationByIdWithoutSavingState = Workflow.createWorkflow(
    this.setLocationDetailsStateLoading,
    (params: SearchByIdRequest) =>
      this.searchLocationService.getLocationById(params),
    this.reportErrors()
  );

  public getLocationBySlug = Workflow.createWorkflow(
    undefined,
    (params: SearchBySlugRequest) =>
      this.searchLocationService.getLocationBySlug(params),
    this.reportErrors()
  );

  public getLocationsByLocality = Workflow.createWorkflow(
    undefined,
    (params: SearchByLocalityRequest) =>
      this.searchLocationService.getLocationsByLocality(params),
    this.reportErrors()
  );

  public getRegionSummary = Workflow.createWorkflow(
    undefined,
    () => this.searchLocationService.getRegionSummary(),
    this.reportErrors()
  );

  public getLocationsByCarryout = Workflow.createWorkflow(
    undefined,
    (params: SearchByCarryoutRequest) =>
      this.searchLocationService.getLocationsByCarryout(params),
    this.reportErrors()
  );

  public getLocationByDeliveryAddress = Workflow.createWorkflow(
    undefined,
    (params: SearchByDeliveryAddressRequest) =>
      this.searchLocationService.getLocationByDeliveryAddress(params),
    this.reportErrorsIfStatusIsNot404()
  );
}
