import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import {
  CONFIG,
  Config
} from '../../../../ecomm/providers/config/config.provider';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';

import { CacheLoadedFeature } from '../../../../ecomm/store/features/cache-loaded';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';

@Component({
  template: ``
})
export class SigninCallbackComponent implements OnInit {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    protected store: Store,
    @Inject(CONFIG) private readonly config: Config,
    @Inject(WINDOW) private window: Window,
    private customerWorkflowService: CustomerWorkflowService,
    private redirectService: RedirectService
  ) {}

  getQueryParams(str: string) {
    return Object.fromEntries(new URLSearchParams(str));
  }

  async ngOnInit() {
    await firstValueFrom(
      this.store
        .select(CacheLoadedFeature.selectCacheLoaded)
        .pipe(filter(Boolean))
    );
    const auth = await this._authService.getCurrentAuth();
    const fullUrl = auth.currentPageUrl;
    await this._authService.userManager.signinCallback();
    //fetch customer and save state after login
    await this.customerWorkflowService.getUserAccount();
    await this._authService.notifyLogin();
    if (!fullUrl) {
      // If we don't have a url, there isn't much
      // we can do but redirect to the home page
      this.redirectService.redirectToHome();
    } else {
      const urlWithoutQueryParams = fullUrl.split('?')[0];
      const queryParamsInUrl = fullUrl.split('?')[1];
      const queryParams = this.getQueryParams(queryParamsInUrl);
      this._router
        .navigate([urlWithoutQueryParams], { queryParams })
        .then(() => {
          this._authService.saveAuthStateWithSubject();
        });
    }
  }
}
