type MaybeResponseTypeBase = {
  type?: string;
};

export type MaybeDataResponse<T> = MaybeResponseTypeBase & {
  data: T;
  error?: string;
  errorCode?: string;
  status?: number;
};

export type MaybeErrorResponse<T> = MaybeResponseTypeBase & {
  data?: T;
  error: string;
  errorCode?: string;
  status?: number;
};

export type MaybeAnyResponse<T> = MaybeResponseTypeBase & {
  data?: T;
  error?: string;
  errorCode?: string;
  status?: number;
};

export type MaybeResponse<T> =
  | MaybeDataResponse<T>
  | MaybeErrorResponse<T>
  | MaybeAnyResponse<T>;

export const mapData =
  <TIN, TOUT>(fn: (val: TIN) => TOUT) =>
  (input: MaybeResponse<TIN>): MaybeResponse<TOUT> => ({
    ...input,
    data: input.data && fn(input.data)
  });
