import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { find } from 'lodash';
import { Subscription, filter } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { CartItem } from '../../../../ecomm/types/cart.types';
import { MenuItem } from '../../../../ecomm/types/store-info.types';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { getCategoryFromItemId } from '../../../../ecomm/workflows/store-info/store-info.utilities';
import { QuantityChangedEvent } from '../../../common/types/cart.types';
import { Slugs } from '../../../common/types/location-menu.types';

@Component({
  selector: 'wri-review-order-item',
  templateUrl: './review-order-item.component.html',
  styleUrls: ['./review-order-item.component.scss']
})
export class ReviewOrderItemComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('increaseCntrl') increaseCntrl: ElementRef | undefined;
  @ViewChild('decreaseCntrl') decreaseCntrl: ElementRef | undefined;

  @Input() lineItem: CartItem | null = null;
  @Input() cartId: string | null = null;
  @Input() public isReadOnly = false;

  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;

  @Output()
  public quantityChanged: EventEmitter<QuantityChangedEvent> =
    new EventEmitter<QuantityChangedEvent>();
  public storeInfoState: StoreInfoFeatureState | null = null;
  public cartState: CartFeatureState | null = null;
  public isRemoveButtonClicked = false;
  isDecreaseLoading = false; // internal;
  isIncreaseLoading = false; // internal;
  quantity: number | null = null; // internal; for detecting changes
  private subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private cartService: CartWorkflowService,
    private router: Router,
    private asyncDispatcher: AsynchronousDispatcher,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.subscribeToStoreInfoState();
    this.subscribeToCartState();
  }

  public ngOnChanges(): void {
    if (this.quantity === null || this.quantity !== this.lineItem?.quantity) {
      this.isDecreaseLoading = false;
      this.isIncreaseLoading = false;
      this.quantity = this.lineItem?.quantity ?? 0;
    }
  }

  public onIncrease() {
    this.isIncreaseLoading = true;
    this.changeQuantity((this.lineItem?.quantity ?? 0) + 1);
    this.increaseCntrl?.nativeElement.focus();
  }

  public onDecrease() {
    if (this.lineItem && this.lineItem.quantity > 1) {
      this.isDecreaseLoading = true;
      this.changeQuantity(Math.max((this.lineItem?.quantity ?? 0) - 1, 0));
      // fire GA event
      this.logGAEvent();
    } else {
      this.openRemoveItemModal();
    }
    this.decreaseCntrl?.nativeElement.focus();
  }

  private logGAEvent() {
    try {
      this.analyticsService.logGaEvent({
        event: 'remove_from_cart',
        ecommerce: {
          items: [
            {
              item_id: this.lineItem?.lineItemId || '',
              item_name: this.lineItem?.productName || '',
              item_category:
                getCategoryFromItemId(
                  this.lineItem?.productId == undefined
                    ? ''
                    : this.lineItem.productId,
                  this.storeInfoState?.storeInfo?.categories
                ) || '',
              price: this.lineItem?.unitPrice || 0,
              quantity: 1,
              product_name: this.lineItem?.productName || '',
              currency: 'USD',
              order_method: this.cartState?.cart?.handoffMode || '',
              special_request: [],
              store_name:
                this.storeInfoState?.storeInfo?.storeDetails.name || '',
              value: this.lineItem?.unitPrice || 0
            }
          ]
        }
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  public async removeItem() {
    this.isRemoveButtonClicked = true;
    // fire GA event
    this.logGAEvent();
    await this.cartService.removeItem(
      this.cartId || '',
      this.lineItem?.lineItemId || ''
    );
    this.isRemoveButtonClicked = false;
    this.modalService.dismissAll();
  }

  openRemoveItemModal() {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  editItem() {
    const { categorySlug, productSlug, itemSlug } = this.getSlug(
      this.lineItem?.productId
    );

    //saving item state
    this.asyncDispatcher.dispatch(
      StoreInfoFeature.actions.setState({
        selectedItem: {
          ...(this.lineItem as unknown as MenuItem),
          availableInSchedule: true,
          name: this.lineItem?.productName,
          id: this.lineItem?.productId,
          price: this.lineItem?.unitPrice
        } as unknown as MenuItem
      })
    );

    const locationSlug = this.storeInfoState?.storeInfo?.storeDetails.slug;
    const url = itemSlug
      ? `/location/${locationSlug}/menu/${categorySlug}/${productSlug}/${itemSlug}`
      : `/location/${locationSlug}/menu/${categorySlug}/${productSlug}`;
    this.router.navigate([url], {
      queryParams: {
        lineItemId: this.lineItem?.lineItemId
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((s) => {
        this.storeInfoState = s;
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartState = state;
        /** stop increase/decrease spinner loading when
         cartstate loading is false due to any errors or whatsoever  */
        if (!this.cartState.isLoading) {
          this.isIncreaseLoading = false;
          this.isDecreaseLoading = false;
        }
      })
    );
  }

  private changeQuantity(newQuantity: number) {
    this.quantityChanged.emit({
      cartId: this.cartId ?? '',
      lineItemId: this.lineItem?.lineItemId ?? '',
      quantity: newQuantity
    });
  }

  private getSlug(selectedId?: string) {
    const slugs: Partial<Slugs> = {};

    this.storeInfoState?.storeInfo?.categories.forEach((category) => {
      category.products.forEach((product) => {
        const { item, itemGroup } = product;
        if (item !== null) {
          if (item?.id === selectedId) {
            slugs['categorySlug'] = category?.slug;
            slugs['productSlug'] = product?.slug;
          }
        }

        if (itemGroup !== null) {
          const itemGrpItem = find(itemGroup?.items, ['id', selectedId]);
          if (itemGrpItem) {
            slugs['categorySlug'] = category?.slug;
            slugs['productSlug'] = product?.slug;
            slugs['itemSlug'] = itemGrpItem?.slug;
          }
        }
      });
    });

    return slugs;
  }

  public lineItemHasInvalidModifiers(): boolean {
    if (this.lineItem?.status === 'invalid') {
      if (this.lineItem?.modifierGroups.length === 0) {
        return false;
      }
      const lineItemModifierGroups = this.lineItem.modifierGroups;
      return lineItemModifierGroups.some((lineItemModifierGroup) => {
        return lineItemModifierGroup.modifiers.some((lineItemModifier) => {
          // level 1 modifier status
          if (lineItemModifier.status === 'invalid') {
            return true;
          }
          return lineItemModifier.modifierGroups.some((modifierGroup) => {
            // level 2 modifier status
            modifierGroup.modifiers.some((modifier) => modifier.status === 'invalid')
          })
        })
      })
    }
    return false;
  }
}
