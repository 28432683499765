import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  shareReplay,
  Subscription,
  tap
} from 'rxjs';

import { PathParams } from '../../../../ecomm/constants/params';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataRestaurantFromMenuItem
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { MenuItem, StoreInfo } from '../../../../ecomm/types/store-info.types';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import {
  BreadCrumbType,
  createBreadCrumbClickHandler,
  PartialOutageModalComponent
} from '../../../common';
import { PDPRouteSlugs } from '../../../location-menu';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { UNKNOWN_ERROR } from '../../../../ecomm/utils/throw-error';
import { Store } from '@ngrx/store';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';

@Component({
  selector: 'wri-connected-global-product-details',
  template: `
    <wri-feature-flags [with]="['enableGlobalMenu']">
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>
      <wri-partial-outage-modal></wri-partial-outage-modal>
      <wri-global-item-details
        [selectedItem]="selectedItem"
        [breadcrumbs]="breadcrumbs$ | async"
      >
      </wri-global-item-details>
    </wri-feature-flags>
  `
})
export class ConnectedGlobalProductDetailsComponent implements OnInit, OnDestroy {
  isLoading = false;
  public storeInfo: StoreInfo | null = null;
  public selectedItem?: MenuItem | null;
  public categoryName$: Observable<string | null> | undefined;
  public breadcrumbs$: Observable<BreadCrumbType[]> | undefined;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  public routeSlugs$: Observable<Partial<PDPRouteSlugs>> =
    this.route.paramMap.pipe(
      map((paramMap) => ({
        categorySlug: paramMap.get(PathParams.categorySlug) ?? undefined,
        productSlug: paramMap.get(PathParams.productSlug) ?? undefined,
        itemSlug: paramMap.get(PathParams.itemSlug) ?? undefined
      })),
      shareReplay(1)
    );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private redirectService: RedirectService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LEGACY_SEO_SERVICE) private seoService: ILegacySeoService,
    private notificationService: NotificationService,
    private store: Store
  ) {}

  async ngOnInit() {
    this.subscribeToRegionalConfigState();
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async navigateBasedOnFeatureFlag(configState: RegionalConfigurationFeatureState) {
    this.isLoading = true;

    //redirect to legacy
    if (!configState?.featureFlags.enableGlobalMenu) {
      this.redirectService.redirectToLegacy('menu');
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const gms =
      (configState?.regionalConfigurationOptions as any)?.global_menu_slug ??
      '';
    if (gms.value) {
      this.storeInfo =
        await this.storeInfoWorkflowService.getStoreInfoBySlugWithoutSavingState(
          gms.value,
          'carryout'
        );
      this.categoryName$ = combineLatest([this.routeSlugs$]).pipe(
        map(([slugs]) => {
          if (!slugs.categorySlug) return null;
          const categories = this.storeInfo?.categories ?? [];
          const selectedCategory = categories.find(
            (c) => c.slug === slugs.categorySlug
          );
          this.selectedItem = this.getSelectedItem(
            slugs,
            selectedCategory?.name ?? ''
          );
          // navigate to global menu if item not found
          if (this.selectedItem === undefined) this.goToMenu();
          return selectedCategory?.name ?? '';
        }),
        tap((categoryName) => {
          const hidden = this.storeInfo?.categories
            .filter((c) => c.slug === categoryName)
            .map((c) => c.metadata?.['display'] === 'hidden');
          if (hidden) {
            this.seoService.setRobotsNoIndexNoFollow();
          }
        }),
        shareReplay(1)
      );

      this.breadcrumbs$ = combineLatest([
        this.routeSlugs$,
        this.categoryName$
      ]).pipe(
        map(([slugs, categoryName]) => {
          const url = '/menu';
          return [
            {
              display: 'wri-arrow-back',
              displayType: 'icon',
              displaySeparator: false,
              onClick: createBreadCrumbClickHandler(
                this.router,
                url,
                slugs.categorySlug ?? ''
              )
            },
            {
              display: 'Menu',
              displayType: 'label',
              displaySeparator: true,
              onClick: createBreadCrumbClickHandler(this.router, url)
            },
            {
              display: categoryName,
              displayType: 'label',
              displaySeparator: false,
              onClick: createBreadCrumbClickHandler(
                this.router,
                url,
                slugs.categorySlug ?? ''
              )
            }
          ] as BreadCrumbType[];
        }),
        shareReplay(1)
      );
    } else this.notificationService.showError(UNKNOWN_ERROR);
    this.isLoading = false;
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
        this.navigateBasedOnFeatureFlag(state)
      })
    );
  }

  private getSelectedItem(
    slugs: Partial<PDPRouteSlugs>,
    categoryName: string
  ): MenuItem | null | undefined {
    const categories = this.storeInfo?.categories ?? [];
    const selectedCategory = categories.find(
      (c) => c.slug === slugs.categorySlug
    );
    const products = selectedCategory?.products ?? [];
    const selectedProduct = products.find((c) => c.slug === slugs.productSlug);
    if (selectedProduct) {
      const { item, itemGroup } = selectedProduct;
      if (item) this.selectedItem = item;
      if (itemGroup) {
        const items = selectedProduct?.itemGroup?.items ?? [];
        const selectedItem = items.find((c) => c.slug === slugs.itemSlug);
        this.selectedItem = selectedItem;
      }
    }
    this.addMetadata(
      selectedCategory?.name,
      this.selectedItem?.name,
      this.selectedItem?.images[0].uri
    );
    this.addStructuredData(categoryName, this.selectedItem);
    return this.selectedItem;
  }

  addMetadata(categoryName?: string, itemName?: string, image?: string) {
    if (categoryName && itemName && image) {
      this.seoService.setMetaData({
        title: `${categoryName} - ${itemName} - Wingstop Menu - Wings Restaurant | Wingstop`,
        description: `Order Wingstop's ${itemName} menu.`,
        image
      });
    }
  }

  private goToMenu = () => {
    const url = '/menu';
    this.router.navigate([url]);
  };

  private addStructuredData(
    categoryName: string,
    selectedItem?: MenuItem | null
  ) {
    if (selectedItem) {
      this.seoService.addStructuredData([
        structuredDataRestaurantFromMenuItem(
          this.document,
          selectedItem,
          categoryName
        ),
        structuredDataBreadcrumbList([
          {
            name: 'Home',
            url: this.document.location.origin
          },
          {
            name: 'Menu',
            url: `${this.document.location.origin}/menu`
          },
          {
            name: selectedItem.name,
            url: this.document.location.href
          }
        ])
      ]);
    }
  }
}
