import { Component, Input } from '@angular/core';

export type Image = {
  uri: string;
  type: string;
};

@Component({
  selector: 'wri-flavor-modal-item',
  templateUrl: './flavor-modal-item.component.html',
  styleUrls: ['./flavor-modal-item.component.scss']
})
export class FlavorModalItemComponent {
  @Input() id: string | undefined;
  @Input() name: string | undefined;
  @Input() images: Image[] | undefined;
  @Input() heatScale: number | undefined;
  @Input() description: string | undefined;
  @Input() rubType: string | undefined;
  @Input() isNew: boolean | undefined;
  @Input() isPopular: boolean | undefined;
  @Input() showDivider = true;
  @Input() calorieRange: string | undefined;
}
