import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handoffModeLabel'
})
export class HandoffModeLabelPipe implements PipeTransform {
  transform(handoffMode: string): string {
    return handoffMode.toLowerCase() === 'delivery' ? 'delivery' : 'carryout';
  }
}
