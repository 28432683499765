import { Injectable } from '@angular/core';
import { environment } from '@wingstop/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapProviderService {
  /**
   * init google map lib
   * @returns
   */
  public initMapP(): Promise<boolean> {
    return new Promise((resolve) => {
      const foundScript = document.getElementById('gmapScript');
      if (!foundScript) {
        const mapInitCallback = document.createElement('script');
        mapInitCallback.type = 'text/javascript';
        mapInitCallback.id = 'gmapScriptCallback';
        mapInitCallback.innerHTML = `window.initMap = function() {};`;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'gmapScript';
        s.src = `https://maps.googleapis.com/maps/api/js?key=${environment.gmapKey}&callback=initMap`;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(mapInitCallback);
        head.appendChild(s);

        // more sure way to wait for dom obj than the above timeout
        const id = setInterval(() => {
          if (
            typeof (window as any).google !== 'undefined' &&
            typeof (window as any).google.maps !== 'undefined'
          ) {
            clearInterval(id);
            if (environment.envName !== 'prod') {
              console.log('Added Google Maps script to header');
            }
            resolve(true);
          }
        }, 200);
      } else {
        if (environment.envName !== 'prod') {
          console.log('Google Maps script already loaded');
        }
        resolve(true);
      }
    });
  }

  // Observable Approach
  public initMap(): Observable<boolean> {
    return new Observable((observer) => {
      const foundScript = document.getElementById('gmapScript');
      if (!foundScript) {
        const mapInitCallback = document.createElement('script');
        mapInitCallback.type = 'text/javascript';
        mapInitCallback.id = 'gmapScriptCallback';
        mapInitCallback.innerHTML = `window.initMap = function() {};`;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'gmapScript';
        s.src = `https://maps.googleapis.com/maps/api/js?key=${environment.gmapKey}&callback=initMap`;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(mapInitCallback);
        head.appendChild(s);

        const id = setInterval(() => {
          if (
            typeof (window as any).google !== 'undefined' &&
            typeof (window as any).google.maps !== 'undefined'
          ) {
            clearInterval(id);
            if (environment.envName !== 'prod') {
              console.log('Added Google Maps script to header');
            }
            observer.next(true);
            observer.complete();
          }
        }, 200);
      } else {
        if (environment.envName !== 'prod') {
          console.log('Google Maps script already loaded');
        }
        observer.next(true);
        observer.complete();
      }
    });
  }
}
