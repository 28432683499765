import { Component, Input } from '@angular/core';

@Component({
  selector: 'wri-icon',
  template: ` <ng-container *ngIf="isToggleable; else withoutFocusOutline">
      <i
        wriFocusOutline
        data-testid="wri-icon"
        [class.wri-icon]="true"
        [ngClass]="icon"
        [attr.tabindex]="tabindex"
        [attr.aria-label]="ariaLabel"
        [attr.aria-pressed]="ariaPressed"
      ></i>
    </ng-container>
    <ng-template #withoutFocusOutline>
      <i
        data-testid="wri-icon"
        [class.wri-icon]="true"
        [ngClass]="icon"
        [attr.aria-label]="ariaLabel"
        [attr.aria-pressed]="ariaPressed"
      ></i>
    </ng-template>`,
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() public icon?: string;
  @Input() public tabindex = 0;
  @Input() public ariaLabel?: string;
  @Input() public ariaPressed?: boolean;
  @Input() public isToggleable?: boolean;
}
