import {
  ComponentRef,
  Directive,
  HostListener,
  Input,
  ViewContainerRef
} from '@angular/core';
import { PasswordRequirementsComponent } from '../components';

@Directive({
  selector: '[wriPasswordRequirements]'
})
export class PasswordRequirementsDirective {
  @Input() value = '';
  @Input() passwordRequirements = [];

  private componentRef: ComponentRef<PasswordRequirementsComponent> | null =
    null;

  constructor(private viewContainerRef: ViewContainerRef) {}

  checkPassword() {
    if (this.componentRef?.instance) {
      const instance = this.componentRef
        .instance as PasswordRequirementsComponent;
      instance.passwordRequirements = this.passwordRequirements;
      instance.enteredPassword = this.value;
    }
  }

  @HostListener('input', ['$event.target'])
  onInput(): void {
    this.checkPassword();
  }

  @HostListener('focusin', ['$event.target.value'])
  onMouseEnter(): void {
    if (!this.componentRef) {
      this.componentRef = this.viewContainerRef.createComponent(
        PasswordRequirementsComponent
      );
    }
    this.checkPassword();
  }

  @HostListener('focusout')
  onMouseLeave(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
