import { FactoryProvider, InjectionToken, Injector } from '@angular/core';

import { CONFIG } from '../config/config.provider';
import { SCRIPT_LOADER } from '../script-loader/script-loader.provider';

type BrainTreeFactory<TOPTIONS, TINSTANCE> = {
  create: (options: TOPTIONS) => Promise<TINSTANCE>;
};

export type BrainTreeClientFactoryOptions = {
  authorization: string;
  merchantAccountId?: string;
};
export type BrainTreeClient = unknown;
export type BrainTreeClientFactory = BrainTreeFactory<
  BrainTreeClientFactoryOptions,
  BrainTreeClient
>;

export type BrainTreeDataCollectorFactoryOptions = {
  client: BrainTreeClient;
};
export type BrainTreeDataCollector = {
  rawDeviceData: { correlation_id: string };
};

export type BrainTreeDataCollectorFactory = BrainTreeFactory<
  BrainTreeDataCollectorFactoryOptions,
  BrainTreeDataCollector
>;

export type BrainTreeVenmoFactoryOptions = {
  client: BrainTreeClient;
  allowDesktop: boolean;
  paymentMethodUsage: string;
  allowNewBrowserTab: boolean;
  requireManualReturn: boolean;
};

export type BrainTreeVenmoTokenizedData = {
  nonce: string;
  type: string;
  details: {
    username: string;
    paymentContextId: string;
    payerInfo: {
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email: string;
      externalId: string;
      userName: string;
    };
  };
};

export type BrainTreeVenmo = {
  isBrowserSupported: () => boolean;
  tokenize: () => Promise<BrainTreeVenmoTokenizedData>;
};

export type BrainTreeVenmoFactory = BrainTreeFactory<
  BrainTreeVenmoFactoryOptions,
  BrainTreeVenmo
>;

export type BrainTreePaypalFactoryOptions = {
  client: BrainTreeClient;
};

export type PaypalLoadSDKOptions = {
  currency: string;
  vault: boolean;
};

export type PaypalCreatePaymentOptions = {
  flow: string;
};

export type BrainTreePaypalTokenizedData = {
  details: {
    payerId: string;
  };
  nonce: string;
};

export type BrainTreePaypal = {
  loadPayPalSDK: (opts: PaypalLoadSDKOptions) => Promise<BrainTreePaypal>;
  createPayment: (opts: PaypalCreatePaymentOptions) => Promise<unknown>;
  tokenizePayment: (data: unknown) => Promise<BrainTreePaypalTokenizedData>;
};

export type BrainTreePaypalFactory = BrainTreeFactory<
  BrainTreePaypalFactoryOptions,
  BrainTreePaypal
>;

export type BrainTreeSDK = {
  client: BrainTreeClientFactory;
  dataCollector: BrainTreeDataCollectorFactory;
  venmo: BrainTreeVenmoFactory;
  paypalCheckout: BrainTreePaypalFactory;
};

declare let braintree: BrainTreeSDK;
export type BrainTreeProvidedType = Promise<BrainTreeSDK | undefined>;

export const brainTreeProvider = async (
  injector: Injector
): BrainTreeProvidedType => {
  const _scriptLoader = injector.get(SCRIPT_LOADER);
  const _config = injector.get(CONFIG);

  return Promise.allSettled(_config.brainTree.urls.map(_scriptLoader))
    .then(() => {
      return braintree as BrainTreeSDK;
    })
    .catch(() => {
      return undefined;
    });
};

export const BRAIN_TREE = new InjectionToken<BrainTreeProvidedType>(
  'BRAIN_TREE'
);

export class BrainTreeProvider {
  public static get = (): FactoryProvider => ({
    provide: BRAIN_TREE,
    deps: [Injector],
    useFactory: brainTreeProvider
  });
}

export type PaypalAction = typeof Promise;

// PAYPAL
export type PaypalSDK = {
  FUNDING: {
    PAYPAL: number;
  };
  Buttons: (opts: {
    fundingSource: number;
    style: Record<string, unknown>;
    createBillingAgreement: () => Promise<unknown>;
    onApprove: (data: unknown, actions: PaypalAction) => Promise<unknown>;
    onClick: (data: unknown, actions: PaypalAction) => Promise<unknown>;
    onError: (err: unknown) => Promise<unknown>;
  }) => { render: (id: string) => Promise<unknown> };
};
