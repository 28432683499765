import { Pipe, PipeTransform } from '@angular/core';

import { MenuModifier } from '../../../ecomm/types/store-info.types';

export type FlavorModifierGroupElement = {
  id: string;
  name: string;
  images: FlavorModGroupImageData[];
  isDefault: boolean;
  price: number;
  isNew?: boolean;
  isPopular?: boolean;
  rubType?: string;
  quantity?: number;
};

export type FlavorModGroupImageData = {
  uri: string;
  type: string;
};

@Pipe({
  name: 'modifierToFlavorModifier'
})
export class ModifierToFlavorModifierPipe implements PipeTransform {
  transform(
    input: MenuModifier | undefined
  ): FlavorModifierGroupElement | undefined {
    if (input) {
      return { ...input, rubType: input?.metadata?.['rub-type'] ?? 'wet' };
    }
    return undefined;
  }
}
