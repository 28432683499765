import { Inject, Injectable } from '@angular/core';
import {
  map,
  Observable,
  Observer,
  Subject,
  of,
  NEVER,
  timeout,
  interval,
  buffer,
  concatMap,
  filter
} from 'rxjs';
import {
  EcommAPIConfig,
  ECOMM_API_CONFIG
} from '../config/ecomm-config.provider';
import { WebSocketProviderType, WEB_SOCKET } from './web-socket.provider';

@Injectable({ providedIn: 'root' })
export class WebSocketClient<ActionType, EventType> {
  constructor(
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    @Inject(WEB_SOCKET) private webSocketProvider: WebSocketProviderType
  ) {}

  connect(): [() => void, Subject<ActionType>, Observable<EventType>] {
    const ws = this.webSocketProvider.create(this.config.webSocketUrl);
    const actions$: Subject<ActionType> = new Subject();
    const subscription = actions$
      .pipe(
        buffer(
          interval(200).pipe(
            map(() => ws.readyState === ws.OPEN),
            filter(Boolean)
          )
        ),
        concatMap((actions) => of(...actions))
      )
      .subscribe((action) => {
        ws.send(JSON.stringify(action));
      });

    const responses$ = new Observable((obs: Observer<MessageEvent<string>>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    }).pipe(
      map((event) => {
        return JSON.parse(event.data);
      })
    );

    return [
      () => {
        if (!subscription.closed) {
          subscription.unsubscribe();
        }
        if (ws.readyState === ws.OPEN) {
          ws.close();
        }
      },
      actions$,
      responses$
    ];
  }

  publish(
    action: ActionType,
    manualFetch: () => Observable<EventType> = () => NEVER,
    timeoutMS = 75_000
  ): [() => void, Observable<EventType>] {
    const [closeFn, actions$, responeses$] = this.connect();
    actions$.next(action);
    return [
      closeFn,
      responeses$.pipe(
        timeout({
          each: timeoutMS,
          with: manualFetch
        })
      )
    ];
  }
}
