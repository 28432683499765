import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  shareReplay,
  Subscription,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ACTIVATED_ROUTE_SNAPSHOT,
  BreadCrumbType,
  createBreadCrumbClickHandler,
  PartialOutageModalComponent,
  ProductDisplayPageService
} from '../../../common';
import { Store } from '@ngrx/store';
import {
  MenuItem,
  MenuItemGroup,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { PathParams } from '../../../../ecomm/constants/params';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { RegionalConfigurationFeature, RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';

export type PDPRouteSlugs = {
  categorySlug: string;
  productSlug: string;
  itemSlug?: string;
};

@Component({
  selector: 'wri-connected-product-details',
  templateUrl: './connected-product-details.component.html'
})
export class ConnectedProductDetailsComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  public selectedItem?: MenuItem | null;
  private fireGaEvent? = true;
  public routeSlugs$: Observable<Partial<PDPRouteSlugs>> =
    this.route.paramMap.pipe(
      map((paramMap) => ({
        categorySlug: paramMap.get(PathParams.categorySlug) ?? undefined,
        productSlug: paramMap.get(PathParams.productSlug) ?? undefined,
        itemSlug: paramMap.get(PathParams.itemSlug) ?? undefined
      })),
      shareReplay(1)
    );
  public storeInfoState$: Observable<StoreInfoFeatureState> = this.store
    .select(StoreInfoFeature.selectStoreInfoState)
    .pipe(filter<StoreInfoFeatureState>(Boolean), shareReplay(1));
  public cartState$: Observable<CartFeatureState> = this.store
    .select(CartFeature.selectCartState)
    .pipe(filter<CartFeatureState>(Boolean), shareReplay(1));
  public categoryName$: Observable<string | null> = combineLatest([
    this.routeSlugs$,
    this.storeInfoState$
  ]).pipe(
    map(([slugs, storeInfoState]) => {
      if (!slugs.categorySlug) return null;
      const categories = storeInfoState?.storeInfo?.categories ?? [];
      const selectedCategory = categories.find(
        (c) => c.slug === slugs.categorySlug
      );
      return selectedCategory?.name ?? '';
    }),
    shareReplay(1)
  );
  public breadcrumbs$ = combineLatest([
    this.routeSlugs$,
    this.categoryName$,
    this.storeInfoState$
  ]).pipe(
    map(([slugs, categoryName, storeInfoState]) => {
      if (
        !slugs.categorySlug ||
        !categoryName ||
        !storeInfoState?.storeInfo?.storeDetails
      ) {
        return [];
      }
      const url = `/location/${storeInfoState.storeInfo?.storeDetails?.slug}/menu`;
      return [
        {
          display: 'wri-arrow-back',
          displayType: 'icon',
          displaySeparator: false,
          onClick: createBreadCrumbClickHandler(
            this.router,
            url,
            slugs.categorySlug ?? ''
          )
        },
        {
          display: 'Menu',
          displayType: 'label',
          displaySeparator: true,
          onClick: createBreadCrumbClickHandler(this.router, url)
        },
        {
          display: categoryName,
          displayType: 'label',
          displaySeparator: false,
          onClick: createBreadCrumbClickHandler(
            this.router,
            url,
            slugs.categorySlug ?? ''
          )
        }
      ] as BreadCrumbType[];
    }),
    shareReplay(1)
  );
  public isLoading$ = new BehaviorSubject(true);
  private subscription = new Subscription();
  public cartStateAfterAddOrUpdate = false;
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private analyticsService: AnalyticsService,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: ProductDisplayPageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.storeInfoState$
        .pipe(withLatestFrom(this.categoryName$))
        .subscribe(([storeInfo, categoryName]) => {
          this.selectedItem = storeInfo.selectedItem;
          if (storeInfo.selectedItem) {
            this.setSeoMetaData(storeInfo.selectedItem);
            if (this.fireGaEvent) {
              this.logGaEvent(storeInfo.selectedItem, categoryName ?? null);
              this.fireGaEvent = false;
            }
          }
          this.setRobotsNoIndexNoFollow(
            storeInfo.storeInfo,
            categoryName ?? ''
          );
        })
    );

    this.subscription.add(
      this.route.data
        .pipe(
          filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
          tap(() => this.isLoading$.next(true)),
          switchMap((data) =>
            this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT])
          )
        )
        .subscribe(() => {
          this.isLoading$.next(false);
        })
    );
    this.subscribeToCartState();
    this.subscribeToRegionalConfigState();
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartStateAfterAddOrUpdate = state.isLoading;
      })
    );
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private logGaEvent(
    selectedItem: MenuItem | MenuItemGroup | null,
    categorySlug: string | null
  ) {
    if (!selectedItem) {
      return;
    }
    try {
      this.analyticsService.logGaEvent({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_name: selectedItem.name,
              item_id: selectedItem.id,
              price: (selectedItem as MenuItem).price ?? 0,
              item_category: categorySlug ?? 'unknown',
              quantity: 1
            }
          ]
        }
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  private setRobotsNoIndexNoFollow(
    storeInfo: StoreInfo | null,
    categorySlug: string
  ) {
    const hidden = storeInfo?.categories
      .filter((c) => c.slug === categorySlug)
      .map((c) => c.metadata?.['display'] === 'hidden');
    if (hidden) {
      this.seoService.setRobotsNoIndexNoFollow();
    }
  }

  private setSeoMetaData(selectedItem: MenuItem | null) {
    const productName = selectedItem?.name ?? '';
    this.seoService.setRobotsNoIndexNoFollow();
    this.seoService.setMetaData({
      title: `Order ${productName} | Wingstop`,
      description: `Add a ${productName} to your Wingstop order, available for pickup or delivery! ${selectedItem?.description}`
    });
  }
}
