import { ValidatorFn } from '@angular/forms';
import * as EmailValidator from 'email-validator';

export function emailValidator(): ValidatorFn {
  return (control) => {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    const emailValidateResult = EmailValidator.validate(
      control.value as string
    );
    if (!emailValidateResult) return { pattern: true };

    return null;
  };
}
