/* #region OfferDetails */
import * as t from 'io-ts';

import { ecommApiResponse, optional } from './common.types';

/* #region OfferDetailsImage */
export const OfferDetailsImageDto = t.intersection(
  [ 
    t.type(
      {
        id: t.string,
        type: t.string,
        uri: t.string,
        alternateText: t.string
      },
      'OfferDetailsImageDtoRequired'
    ),
    t.partial({}, 'OfferDetailsImageDtoOptional')
  ],
  'OfferDetailsImageDto'
);

export type OfferDetailsImageDto = t.TypeOf<typeof OfferDetailsImageDto>;
export type OfferDetailsImage = OfferDetailsImageDto;
/* #endregion */

export const OfferDetailsDto = t.intersection([
  t.type(
    {
      id: t.string,
      code: t.string,
      customerDescription: t.string,
      name: t.string,
    },
    'OfferDetailsDtoRequired'
  ),
  t.partial(
    {
      storeId: optional(t.string),
      legalTerms: optional(t.string),
      images: optional(t.array(OfferDetailsImageDto)),
      promotionalCriteria: optional(t.string),
      primaryButtonText: optional(t.string),
      primaryButtonUrl: optional(t.string),
      secondaryButtonText: optional(t.string),
      secondaryButtonUrl: optional(t.string),
    },
    'OfferDetailsDtoOptional'
  )
]);
export type OfferDetailsDto = t.TypeOf<typeof OfferDetailsDto>;
export type OfferDetails = OfferDetailsDto;
/* #endregion */

/* #region OfferDetailsResponse */
export const OfferDetailsResponse = ecommApiResponse(OfferDetailsDto);
export type OfferDetailsResponse = t.TypeOf<typeof OfferDetailsResponse>;
/* #endregion */
