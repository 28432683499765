import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { StoreDetailsHandoffMode } from '../../../../../ecomm/types/store-info.types';
import { CheckboxComponent } from '../../../../common';
import {
  PaymentInfo,
  PaymentMethodLike
} from '../payment-method/payment-method.types';

@Component({
  selector: 'wri-in-store-payment-method',
  templateUrl: './in-store-payment-method.component.html',
  styleUrls: ['./in-store-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InStorePaymentMethodComponent)
    }
  ]
})
export class InStorePaymentMethodComponent
  extends CheckboxComponent
  implements PaymentMethodLike
{
  @Input()
  totalPrice!: number;

  @Input()
  isPayInStorePaymentSupported = false

  @Input()
  selectedHandoffModeDetails: StoreDetailsHandoffMode | null = null

  @Input()
  cartSubTotal: number | null = null

  @Input()
  paymentType = '';

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  getPaymentInfo(): Observable<PaymentInfo> {
    return of({
      billingMethod: 'payInStore'
    });
  }
}
