<div class="wri-empty-my-addresses-wrapper">
  <div class="wri-my-addresses-wrapper icon-round spacing-bottom">
    <wri-icon icon="wri-location"></wri-icon>
  </div>

  <span class="my-addresses-empty-text" data-testid="my-addresses-empty-text"
    >No Saved Addresses</span
  >
  <span class="no-my-addresses-text" data-testid="no-my-addresses-text"
    >You do not have any saved addresses. You can add an address when you order
    delivery.</span
  >
  <button
    wriFocusOutline
    data-testid="start-order"
    [ngClass]="'wri-btn wri-btn-primary'"
    aria-label="Start Order"
    (click)="goToOrderPage()"
  >
    Start Order
  </button>
</div>
