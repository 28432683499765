import { PathReporter } from 'io-ts/PathReporter';
import * as _ from 'lodash/fp';
import * as t from 'io-ts';
import { Observable, of } from 'rxjs';
import * as rx from 'rxjs/operators';

import {
  EcommAPIError,
  EcommAPIResponse,
  EcommWebSocketResponse,
  HttpAPICustomErrorHandler,
  HttpError,
  PreviouslyCaught
} from '../types/common.types';
import { MaybeResponse } from '../types/maybe-response';
import { left } from 'fp-ts/lib/Either';

export const UNKNOWN_ERROR =
  'We ran into an unexpected error. Please try again.';
export const HTTP_ERROR_PATH = ['error'];

export const log = _.pipe(JSON.stringify, console.error);

export const handleDecodeError =
  <T>(type?: string, defaultErrorMessage: string = UNKNOWN_ERROR) =>
  (decodeErr: t.Errors): MaybeResponse<T> => {
    const errorMessages = PathReporter.report(left(decodeErr));
    
    const errorTable = errorMessages
      .map((msg, index) => {
        return `Error ${index + 1}: ${msg}`;
      })
      .join('\n');

    console.log(`Validation errors:\n${errorTable}`);
    return { type, error: defaultErrorMessage };
  };

export const handleValidatedResponse =
  <T>(enableDevLogs: boolean, defaultErrorMessage: string = UNKNOWN_ERROR) =>
  (
    res: EcommAPIResponse<T> | EcommWebSocketResponse<string, T>
  ): MaybeResponse<T> => {
    const data: T | undefined = res.data ?? undefined;
    const type: string | undefined =
      (res as unknown as Record<string, string>)['type'] ?? undefined;
    const error: string | undefined = res.error
      ? _.cond<EcommAPIError, string | undefined>([
          [
            _.constant(enableDevLogs),
            (err) =>
              `${err.message || defaultErrorMessage} | ${
                err.customerFacingMessage || defaultErrorMessage
              }`
          ],
          [
            _.stubTrue,
            (err) => err.customerFacingMessage || defaultErrorMessage
          ]
        ])(res.error)
      : undefined;
    const errorCode: string | undefined = res.error?.code ?? undefined;
    return { type, data, error, errorCode };
  };

export const handleAPIErrorResponse =
  <T>(
    customErrorHandlers: HttpAPICustomErrorHandler<T>[],
    enableDevLogs: boolean,
    defaultErrorMessage: string = UNKNOWN_ERROR
  ) =>
  (
    res: HttpError<EcommAPIResponse<T>>
  ): Observable<PreviouslyCaught<MaybeResponse<T>>> => {
    return of(res).pipe(
      rx.exhaustMap(
        _.cond<HttpError<EcommAPIResponse<T>>, Observable<MaybeResponse<T>>>([
          ...customErrorHandlers,
          [
            _.stubTrue,
            _.pipe(
              _.get(HTTP_ERROR_PATH),
              handleValidatedResponse(enableDevLogs, defaultErrorMessage),
              (data) => ({ ...data, status: res.status }),
              of
            )
          ]
        ])
      ),
      rx.map(PreviouslyCaught.map)
    );
  };
