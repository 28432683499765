<div class="wri-pdp-wrapper" *ngIf="selectedItem">
  <wri-breadcrumb [breadcrumbs]="breadcrumbs"></wri-breadcrumb>
  <div class="details-container item-details">
    <div class="item-image">
      <img
        *ngIf="!isCartStateLoading"
        data-testid="item-details-img"
        class="item-img"
        aria-live="polite"
        alt="{{
          selectedItem.images && selectedItem.images.length > 0
            ? selectedItem.name
            : 'placeholder-image'
        }}"
        srcset="{{ selectedItem.images[0]?.uri | imageResolution: 'srcset':'pdp-item': '/assets/images/logo-green-product.png'}}"
        src="{{
          selectedItem.images[0]?.uri
            | imageResolution
              : 'src'
              : 'pdp-item'
              : '/assets/images/logo-green-product.png'
        }}"
        onerror="this.onerror=null;this.src='/assets/images/logo-green-product.png';this.srcset='/assets/images/logo-green-product.png'"
        role="img"
      />
    </div>
    <span
      wriFocusOutline
      data-testid="item-details-name"
      class="item-name"
      aria-live="polite"
    >
      {{ selectedItem.name }}
    </span>
    <span
      wriFocusOutline
      data-testid="item-details-price"
      class="item-price"
      aria-live="polite"
    >
      <span *ngIf="selectedItem.price !== 0.0">{{
        selectedItem.price | currency
        }}</span>
      <ng-container
        *ngIf="
          !(
            selectedItem.minCalories === null &&
            selectedItem.maxCalories === null
          )
        "
      >
        <span class="item-calories">
          {{ returnCalorieRange(selectedItem) }}
        </span>
      </ng-container>
      <span
        data-testid="high-sodium"
        *ngIf="selectedItem?.highSodium && !highSodiumDisplay?.label"
      >
        <ng-container *ngTemplateOutlet="highSodiumDisplayTemplate"></ng-container>
      </span>
    </span>
    <span
      wriFocusOutline
      data-testid="high-sodium-with-label"
      class="high-sodium-with-label"
      aria-live="polite"
    >
      <ng-container
        *ngIf="selectedItem?.highSodium && highSodiumDisplay?.label then highSodiumDisplayTemplate"></ng-container>
    </span>
    <span
      wriFocusOutline
      data-testid="item-details-desc"
      class="item-description"
      aria-live="polite"
    >
      {{ selectedItem.description }}
    </span>
    <!-- Display button only for items w/no modifier groups -->
    <div class="desktop-only add-to-bag-wpr" id="primary-btn-container">
      <div class="quantity-container">
        <a
          wriFocusOutline
          class="wri-quantity-btn"
          role="button"
          [ngClass]="{'disabled-btn' : quantity <= 1 }"
          (click)="changeItemQuantity(-1, 'minus')"
          aria-label="Decrease quantity"
          data-testid="decrease-btn"
        >
          <wri-icon class="quantity-icon" icon="wri-minus"></wri-icon>
        </a>
        <input
          type="tel"
          min="0"
          onkeypress="return (event.charCode !== 8 && event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57))"
          [attr.data-testid]="selectedItem.id | prepend : 'quantity-'"
          [attr.value]="quantity"
          [attr.aria-label]="quantity"
          class="quantity-text"
          [(ngModel)]="quantity"
          (blur)="modifyQuantity($event.target.value)"
        />
        <a
          wriFocusOutline
          class="wri-quantity-btn"
          role="button"
          [ngClass]="{'disabled-btn' : quantity >= 9 }"
          (click)="changeItemQuantity(1, 'add')"
          aria-label="Increase quantity"
          data-testid="increase-btn"
        >
          <wri-icon class="quantity-icon" icon="wri-plus"></wri-icon>
        </a>
      </div>

      <button
        [disabled]="isCartStateLoading"
        class="add-to-bag wri-btn wri-btn-primary"
        [attr.aria-label]="(itemPrice | currency) + ' ' + addOrUpdateItem()"
        (click)="addOrUpdateCartAPI(selectedItem.id)"
        data-testid="add-to-bag-btn"
      >
        <ng-container *ngIf="!isAddOrUpdateButtonClicked">
          {{ addOrUpdateItem() }}
          <span class="basket-price"
          >{{ getTotalItemPrice() | currency }}
          </span>
        </ng-container>
        <div *ngIf="isAddOrUpdateButtonClicked" class="spinner-wrapper-overlay">
          <wri-spinner spinnerColor="white"></wri-spinner>
        </div>
      </button>
    </div>
    <div class="mobile-only sticky-footer">
      <div class="quantity-container">
        <a
          wriFocusOutline
          class="wri-quantity-btn"
          role="button"
          [ngClass]="{'disabled-btn' : quantity <= 1 }"
          (click)="changeItemQuantity(-1, 'minus')"
          aria-label="Decrease quantity"
          data-testid="decrease-btn"
        >
          <wri-icon class="quantity-icon" icon="wri-minus"></wri-icon>
        </a>
        <input
          type="tel"
          min="0"
          onkeypress="return (event.charCode !== 8 && event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57))"
          [attr.data-testid]="selectedItem.id | prepend : 'quantity-'"
          [attr.value]="quantity"
          [attr.aria-label]="quantity"
          class="quantity-text"
          [(ngModel)]="quantity"
          (blur)="modifyQuantity($event.target.value)"
        />
        <a
          wriFocusOutline
          class="wri-quantity-btn"
          role="button"
          [ngClass]="{'disabled-btn' : quantity >= 9 }"
          (click)="changeItemQuantity(1, 'add')"
          aria-label="Increase quantity"
          data-testid="increase-btn"
        >
          <wri-icon class="quantity-icon" icon="wri-plus"></wri-icon>
        </a>
      </div>

      <button
        [disabled]="isCartStateLoading"
        [attr.aria-label]="(itemPrice | currency) + ' ' + addOrUpdateItem()"
        class="add-to-bag wri-btn wri-btn-primary"
        (click)="addOrUpdateCartAPI(selectedItem.id)"
        data-testid="add-to-bag-btn"
      >
        <ng-container *ngIf="!isAddOrUpdateButtonClicked">
          {{ addOrUpdateItem() }}
          <span class="basket-price"
          >{{ getTotalItemPrice() | currency}}
          </span>
        </ng-container>
        <div *ngIf="isAddOrUpdateButtonClicked" class="spinner-wrapper-overlay">
          <wri-spinner spinnerColor="white"></wri-spinner>
        </div>
      </button>
    </div>

    <!--upsell items -->
    <div *ngIf="filterUpsells().length > 0" class="item-upsell-item">
      <wri-upsell-items
        [upsells]="selectedItem?.upsells"
        [categorySlug]="categorySlug"
        [cart]="cart"
      ></wri-upsell-items>
    </div>
  </div>
</div>

<div
  class="item-mod-group"
  *ngIf="
    selectedItem?.modifierGroups &&
    filterModGroupsByAvailability(selectedItem?.modifierGroups).length > 0
  "
>
  <wri-mod-groups
    [modifierGroups]="
      filterModGroupsByAvailability(selectedItem?.modifierGroups)
        | sort : 'asc' : 'sortOrder'
    "
    (valuesChanged)="handleProductWithModifiersData($event, selectedItem)"
    [selectedLineItem]="getSelectedLineItem()"
    [startPDPValidations]="startPDPValidations"
    (failedModifierGroups)="handleFailedModifierGroups($event)"
    (resetPDPValidationsEmit)="resetPDPValidations()"
    [itemQuantity]="quantity"
  ></wri-mod-groups>
</div>

<ng-container *ngIf="storeInfo?.storeDetails.locationDisclaimers?.length > 0">
  <div class="disclaimers-wrapper">
    <div class="disclaimers-inner-wrapper">
      <ng-container
        *ngFor="let disclaimer of storeInfo?.storeDetails.locationDisclaimers"
      >
        <wri-markdown
          class="disclaimer-text"
          [raw]="disclaimer?.message"
        ></wri-markdown>
      </ng-container>
    </div>
  </div>
</ng-container>

<div *ngIf="selectedItem" id="secondary-btn-container" class="secondary-btn-container">
  <div class="wri-pdp-wrapper secondary-btn-wrapper secondary-btn">
    <div class="quantity-container">
      <a
        wriFocusOutline
        class="wri-quantity-btn"
        role="button"
        [ngClass]="{'disabled-btn' : quantity <= 1 }"
        (click)="changeItemQuantity(-1, 'minus')"
        aria-label="Decrease quantity"
        data-testid="decrease-btn"
      >
        <wri-icon class="quantity-icon" icon="wri-minus"></wri-icon>
      </a>
      <input
        type="tel"
        min="0"
        onkeypress="return (event.charCode !== 8 && event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57))"
        [attr.data-testid]="selectedItem.id | prepend : 'quantity-'"
        [attr.value]="quantity"
        [attr.aria-label]="quantity"
        class="quantity-text"
        [(ngModel)]="quantity"
        (blur)="modifyQuantity($event.target.value)"
      />
      <a
        wriFocusOutline
        class="wri-quantity-btn"
        role="button"
        [ngClass]="{'disabled-btn' : quantity >= 9 }"
        (click)="changeItemQuantity(1, 'add')"
        aria-label="Increase quantity"
        data-testid="increase-btn"
      >
        <wri-icon class="quantity-icon" icon="wri-plus"></wri-icon>
      </a>
    </div>

    <button
      [disabled]="isCartStateLoading"
      class="add-to-bag wri-btn wri-btn-primary"
      data-testid="add-to-bag-btn"
      [attr.aria-label]="(itemPrice | currency) + ' ' + addOrUpdateItem()"
      (click)="addOrUpdateCartAPI(selectedItem.id)"
    >
      <ng-container *ngIf="!isAddOrUpdateButtonClicked">
        {{ addOrUpdateItem() }}
        <span class="basket-price"
        >{{ getTotalItemPrice() | currency}}
        </span>
      </ng-container>
      <div *ngIf="isAddOrUpdateButtonClicked" class="spinner-wrapper-overlay">
        <wri-spinner spinnerColor="white"></wri-spinner>
      </div>
    </button>
  </div>
</div>

<ng-template #commonModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss" [closeIcon]="false">
    <div class="wri-modal__wrapper">
      <div class="wri-modal__title">{{ getModalMessages() }}</div>
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="item-not-available-modal-goToMenu"
          class="wri-btn wri-btn-primary"
          aria-label="GoToMenu"
          (click)="unavailableItemClick()"
        >
          {{ getUnavailableItemText() }}
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>

<ng-template #highSodiumDisplayTemplate>
  <img
    data-testid="high-sodium-display-img"
    class="high-sodium-display-img"
    aria-live="polite"
    alt="{{ highSodiumDisplay?.altText || 'Sodium warning icon' }}"
    src="{{ highSodiumDisplay?.imageUrl || 'https://cdn.bfldr.com/NDQASMJ1/as/cvqcrpvtk5b3ptmzp99p8knz/wri-sodium?auto=webp&format=png' }}"
    onerror="this.onerror=null;this.src='https://cdn.bfldr.com/NDQASMJ1/as/cvqcrpvtk5b3ptmzp99p8knz/wri-sodium?auto=webp&format=png';"
    role="img"
  />
  <span *ngIf="highSodiumDisplay?.label"> {{ highSodiumDisplay?.label }} </span>
</ng-template>
