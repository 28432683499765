import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  CustomerVaultedCards,
  VaultedCreditCards,
  VaultedGiftCards
} from '../../../../ecomm/types/payment.types';
import { RemovePaymentMethodComponent } from './remove-payment-method-modal/remove-payment-method-modal.component';
import { RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';

export type AllVaultedCards = VaultedGiftCards & VaultedCreditCards;

@Component({
  selector: 'wri-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnChanges {
  @Input()
  public vaultedCards: CustomerVaultedCards | undefined | null;

  @Input()
  public regionalConfigState: RegionalConfigurationFeatureState | null = null;

  areVaultedGiftCardsViewEnabled: boolean | undefined;
  areVaultedCreditCardsViewEnabled: boolean | undefined;

  constructor(
    private modalService: NgbModal,
    private featureFlagService: FeatureFlagService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const { regionalConfigState } = changes;
    if (regionalConfigState) {
      this.areVaultedGiftCardsViewEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedGiftCardView',
          'feature_enable_vaulted_gift_card_view'
        );
      this.areVaultedCreditCardsViewEnabled =
        await this.featureFlagService.getFeatureFlagValue(
          'enableVaultedCreditCardView',
          'feature_enable_vaulted_credit_card_view'
        );
    }
  }

  public areGiftCardsVaulted(): boolean | undefined {
    return (
      this.vaultedCards?.vaultedGiftCard &&
      this.vaultedCards.vaultedGiftCard.length > 0
    );
  }

  public areCreditCardsVaulted(): boolean | undefined {
    return (
      this.vaultedCards?.vaultedCreditCard &&
      this.vaultedCards.vaultedCreditCard.length > 0
    );
  }

  public areVaultedCardsAvailable(): boolean {
    if (
      this.areVaultedGiftCardsViewEnabled &&
      this.areVaultedCreditCardsViewEnabled &&
      this.vaultedCards &&
      this.vaultedCards?.vaultedCreditCard.length === 0 &&
      this.vaultedCards.vaultedGiftCard.length === 0
    ) {
      return false;
    }
    return true;
  }

  async openDeleteConfirmationModal(delData: {
    deleteCreditCard: boolean;
    data: AllVaultedCards;
  }) {
    const { data, deleteCreditCard } = delData;
    const modalRef = this.modalService.open(RemovePaymentMethodComponent, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });

    modalRef.componentInstance.modalData = delData;

    const values = await modalRef.result;
    if (values) {
      const { isDeleted } = values;
      if (isDeleted) {
        this.vaultedCards = {
          vaultedCreditCard: deleteCreditCard
            ? this.filterCreditCardData(
                this.vaultedCards?.vaultedCreditCard,
                data
              )
            : this.vaultedCards?.vaultedCreditCard || [],
          vaultedGiftCard: !deleteCreditCard
            ? this.filterGiftCardData(this.vaultedCards?.vaultedGiftCard, data)
            : this.vaultedCards?.vaultedGiftCard || []
        };
      }
    }
  }

  filterCreditCardData(
    cards: VaultedCreditCards[] | undefined,
    selectedCard: AllVaultedCards
  ) {
    return cards?.filter((card) => card.id !== selectedCard.id) || [];
  }

  filterGiftCardData(
    cards: VaultedGiftCards[] | undefined,
    selectedCard: AllVaultedCards
  ) {
    return (
      cards?.filter(
        (card) => card.vaultedAccountId !== selectedCard.vaultedAccountId
      ) || []
    );
  }
}
