import { MenuCategory } from '../../../ecomm/types/store-info.types';

export const getCategoryFromItemId = (
  itemId: string,
  menuCategories: MenuCategory[] | undefined
): string | undefined => {
  const category = menuCategories?.find((c) => {
    return c.products.find((itemOrGroup) => {
      return (
        (itemOrGroup.item?.id && itemOrGroup.item?.id === itemId) ||
        itemOrGroup.itemGroup?.items.find((i) => i.id === itemId)
      );
    });
  });

  return category?.name;
};

export const buildPdpSlugs = (
  itemSlug: string,
  menuCategories: MenuCategory[] | undefined
): string => {
  let pdpSlugs = '';
  menuCategories?.map((category) => {
    category.products.map((product) => {
      if (product.item && product.item?.slug === itemSlug) {
        pdpSlugs = `${category.slug}/${product.item.slug}`;
      }
      if (product.itemGroup?.items && product.itemGroup.items.length > 0) {
        const item = product.itemGroup?.items.find((f) => f.slug === itemSlug);
        if (item) pdpSlugs = `${category.slug}/${product.slug}/${item?.slug}`;
      }
    });
  });

  return pdpSlugs;
};
