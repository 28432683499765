import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, from, NEVER, of, switchMap, tap } from 'rxjs';
import { LocationDetailsFeature } from '../../../ecomm/store/features/location-details';
import { OrderFeature } from '../../../ecomm/store/features/order';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { Order } from '../../../ecomm/types/order.types';
import { LocationResponseData } from '../../../ecomm/types/search-location.types';

import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { AuthService } from '../../../ecomm/utils/auth/auth.service';
import { CurrentUrlService } from '../../../ecomm/utils/current-url/current-url.service';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { StoreSnapshotService } from '../../../ecomm/utils/store-snapshot/store-snapshot.service';
import { CustomerWorkflowService } from '../../../ecomm/workflows/customer/customer-workflow.service';
import { OrderWorkflowService } from '../../../ecomm/workflows/order/order-workflow.service';
import { SearchLocationWorkflowService } from '../../../ecomm/workflows/search-location/search-location-workflow.service';
import { StoreInfoWorkflowService } from '../../../ecomm/workflows/store-info/store-info-workflow.service';
import { BasePageService } from './base-page-service';

@Injectable()
export class ConfirmationPageService extends BasePageService {
  constructor(
    private locationDetailsWorkflowService: SearchLocationWorkflowService,
    private orderService: OrderWorkflowService,
    private currentUrlService: CurrentUrlService,
    private storeSnapshotService: StoreSnapshotService,
    private authService: AuthService,
    private storeInfoService: StoreInfoWorkflowService,
    @Inject(AsynchronousDispatcher)
    asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    redirectService: RedirectService,
    store: Store,
    userAccountService: CustomerWorkflowService
  ) {
    super(redirectService, store, userAccountService, asynchronusDispatcher);
  }

  loadData$() {
    return from(this.orderService.getOrder(true)).pipe(
      switchMap((order) => {
        if (order === null) {
          return of(null);
        }
        return from(
          this.locationDetailsWorkflowService.getLocationById({
            locationId: order.cart?.locationId ?? ''
          })
        )
      }),

      switchMap(() => this.authService.getCurrentAuth()),
      switchMap((auth) =>
        combineLatest([
          of(auth.isAuthenticated),
          this.storeSnapshotService.get()
        ])
      ),
      tap(([isAuthenticated, state]) =>
        this.navigateTo(
          OrderFeature.selectLastSessionOrder(state),
          LocationDetailsFeature.selectLocationDetails(state),
          isAuthenticated
        )
      )
    );
  }

  timeout$ = () => NEVER;

  isInInvalidState(): boolean {
    return false;
  }

  private navigateTo(
    lastSessionOrder: Order | null | undefined,
    locationDetails: LocationResponseData | null | undefined,
    isAuthenticated: boolean
  ): void {
    if (locationDetails === null || lastSessionOrder === null) {
      if (isAuthenticated) {
        this.currentUrlService.goToRecentOrder();
      } else {
        this.currentUrlService.goToHomePage();
      }
    }
  }
}
