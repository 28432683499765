<div class="my-addresses-wrapper">
  <span class="my-addresses-title">My Addresses</span>
  <div class="my-addresses-with-empty-wrapper">
    <ng-container
      *ngIf="deliveryAddresses?.length > 0; else noDeliveryAddressesContainer"
    >
      <div *ngFor="let deliveryAddress of deliveryAddresses">
        <div class="my-addresses-addresses-wrapper">
          <div>
            <div class="street-address" data-testid="street-address">
              {{ deliveryAddress?.streetAddress }}
            </div>
            <div
              class="secondary-street-address"
              *ngIf="deliveryAddress?.secondaryStreetAddress"
            >
              {{ deliveryAddress?.secondaryStreetAddress }}
            </div>
            <div class="address-details" data-testid="address-details">
              {{ deliveryAddress.locality }}, {{ deliveryAddress.region }}
              {{ deliveryAddress.postalCode }}
            </div>
          </div>
          <div>
            <a
              wriFocusOutline
              tabindex="0"
              (click)="openDeleteConfirmationModal({ data: deliveryAddress })"
              aria-label="Delete"
            >
              <wri-icon class="delete-btn" icon="wri-delete"></wri-icon>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noDeliveryAddressesContainer>
      <wri-no-my-addresses />
    </ng-template>
  </div>
</div>
