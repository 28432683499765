import { createFeature, createReducer } from '@ngrx/store';

export type CacheLoadedFeatureState = { cacheLoaded: boolean };

const initialCacheLoadedFeatureState: CacheLoadedFeatureState = {
  cacheLoaded: false
};

const CacheLoadedFeatureKey = 'cacheLoaded';

const CacheLoadedReducer = createReducer(initialCacheLoadedFeatureState);

const CacheLoadedFeature = createFeature({
  name: CacheLoadedFeatureKey,
  reducer: CacheLoadedReducer
});

export default {
  ...CacheLoadedFeature,
  initialState: initialCacheLoadedFeatureState
};
