import { InjectionToken, ValueProvider } from '@angular/core';

export const WINDOW = new InjectionToken<typeof window>(
  'To inject the Window object into components & services'
);

export class WindowProvider {
  public static get = (): ValueProvider => ({
    provide: WINDOW,
    useValue: window
  });
}
