<div class="location-name" data-testid="location-name">
  <span> {{ locationData?.name }} </span>
</div>
<div class="location-address" data-testid="location-address">
  {{ locationData?.streetAddress }}, {{ locationData?.locality }},
  {{ locationData?.region }} {{ locationData?.postalCode }}
</div>
<div class="location-grid-wpr">
  <wri-todays-hours
    [businessDay]="locationData?.businessDay"
    [hours]="locationData?.storeHours"
  ></wri-todays-hours>
  <div class="location-phone-number">
    <wri-phone-number
      [phoneNumber]="locationData?.phoneNumber"
    ></wri-phone-number>
  </div>
</div>
<div class="wri-card-btn-wrapper">
  <button
    (click)="emit()"
    class="wri-btn wri-btn-primary"
    aria-label="Apply Now"
    data-testid="apply-now-btn"
  >
    Apply Now
  </button>
</div>
