import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  ILegacyAnalyticsService,
  LEGACY_ANALYTICS_SERVICE
} from '../../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  MenuItem,
  MenuModifier
} from '../../../../../ecomm/types/store-info.types';
import { getCalorieRange } from '../../../../../ecomm/utils/calorie-range';
import {
  FlavorSpiceHeading,
  FlavorSpiceHeadingColors
} from '../../../../common/types/modifiers';
import { FlavorModalComponent } from '../../flavor-modal/flavor-modal.component';

@Component({
  selector: 'wri-choose-flavor-spice-group',
  templateUrl: './choose-flavor-spice-group.component.html',
  styleUrls: ['../choose-flavor-modal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ChooseFlavorSpiceGroupComponent)
    }
  ]
})
export class ChooseFlavorSpiceGroupComponent {
  selectedAddOnFlavor: MenuModifier = {} as MenuModifier;
  @Input() flavors: MenuModifier[] = [];
  @Input() iconColor: string | undefined;
  @Input() heading: string | undefined;
  @Input() type: string | undefined;
  @Input() limited: MenuModifier[] = [];
  @Input() hot: MenuModifier[] = [];
  @Input() medium: MenuModifier[] = [];
  @Input() mild: MenuModifier[] = [];
  @Input() editFlavor: MenuModifier | undefined;

  @Output() valuesChanged = new EventEmitter<{
    selectedAddOnFlavor: MenuModifier;
    editFlavor: MenuModifier | undefined;
  }>();

  readonly flavorSpiceHeading = FlavorSpiceHeading;
  readonly flavorSpiceHeadingColors = FlavorSpiceHeadingColors;
  /** Implement ControlValueAccessor */
  disabled = false;
  touched = false;
  @ViewChild(FlavorModalComponent)
  private flavorModal!: FlavorModalComponent;

  /** Implement ControlValueAccessor */
  set value(value: MenuModifier) {
    this.onChange(value);
  }

  constructor(
    @Inject(LEGACY_ANALYTICS_SERVICE)
    private analyticsService: ILegacyAnalyticsService
  ) {}

  selectedSpiceHeading() {
    this.flavorModal.openFlavorModal();
  }

  onChange: (v: MenuModifier) => void = () => void 0;
  onTouched: () => void = () => void 0;

  isItemChecked(modifierItemId: string): boolean {
    if (this.editFlavor) {
      this.selectedAddOnFlavor = this.editFlavor;
    }
    return this.selectedAddOnFlavor.id === modifierItemId;
  }

  onChangeAddOnFlavor(checked: boolean, modifierData: MenuModifier) {
    this.editFlavor = undefined;
    if (checked) {
      this.selectedAddOnFlavor = modifierData;
      this.editFlavor = modifierData;
      // Log GA event
      this.analyticsService.logGaEvent({
        event: 'choose_options',
        item_name: modifierData.name,
        count: 1
      });
    }
    this.onTouched();
    this.onChange(this.selectedAddOnFlavor);
    this.valuesChanged.emit({
      selectedAddOnFlavor: this.selectedAddOnFlavor,
      editFlavor: this.editFlavor
    });
  }

  writeValue(newValue: MenuModifier): void {
    this.value = newValue;
  }

  returnCalorieRange(modifierData: MenuModifier | MenuItem): string | number {
    return getCalorieRange(modifierData);
  }

  registerOnChange(
    onChange: (v: MenuModifier) => MenuModifier | undefined
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
